<template>
  <div class="collapsable__item">
    <div class="collapsable__item__header">
      <h6 @click="toggleSection">
        {{ $t(metadata.name) }}
        <Icon
          class="caret"
          name="caret-up"
          family="fas"
          :class="{ 'caret--inverted': !isExpanded }"
        />
      </h6>
      <div class="info">
        <Icon
          v-tooltip="$t(metadata.description)"
          name="info-circle"
          family="fas"
          class="tip"
        />
        <v-popover
          v-if="getMetadataSetType === 'custom' && !readOnly"
          class="delete"
        >
          <button>
            <Icon name="times" family="fas" />
          </button>
          <Alert
            slot="popover"
            button-type="danger"
            :buttons="metadataRemoveConfirmationButton"
          >
            <span role="alert">
              {{
                $t(
                  'Are you really sure you want to remove this metadata set definition from the given File Object? All associated metadata values will be removed. The operation cannot be reversed.'
                )
              }}
            </span>
          </Alert>
        </v-popover>
      </div>
    </div>
    <div v-if="isExpanded" class="collapsable__item__body">
      <div
        v-for="(field, fieldidx) in metadata.attributes"
        :key="field.index"
        class="form-group"
      >
        <label>
          {{ field.name }}<strong>{{ field.required === 1 ? '*' : '' }}</strong>
          <Icon
            v-if="field.description"
            v-tooltip="$t(field.description)"
            name="info-circle"
            family="fas"
            class="info"
          />
        </label>
        <component
          :is="`${getFieldDatatypeText(field.datatype)}Type`"
          :field="field"
          :metadata="metadata"
          :read-only="readOnly"
          @onFieldChange="onFieldChange"
          @input="(e) => onDataInput(e, fieldidx)"
        />
      </div>
      <div v-if="!hideHeader" class="mt-4 text-right">
        <button
          v-if="metadata.write === 1"
          class="ml-2 btn btn-primary"
          type="submit"
          :disabled="!isChanged"
          @click.prevent="saveAttributes"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import _ from 'lodash';
import Alert from 'common/components/Alert';

import arrayType from './DataTypes/Array.vue';
import booleanType from './DataTypes/Boolean';
import dateType from './DataTypes/Date';
import decimalType from './DataTypes/Decimal';
import enumType from './DataTypes/Enum';
import intType from './DataTypes/Int';
import kvpType from './DataTypes/Kvp';
import textType from './DataTypes/Text';

export default {
  components: {
    Icon,
    Alert,
    arrayType,
    booleanType,
    dateType,
    decimalType,
    enumType,
    intType,
    kvpType,
    textType,
  },
  props: {
    metadata: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fullpath: {
      type: String,
      required: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    foo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: true,
      changedFields: {},
    };
  },
  computed: {
    getMetadataSetType() {
      const metadataSetTypes = {
        1: 'built_in',
        2: 'default',
        3: 'custom',
      };
      return metadataSetTypes[this.metadata && this.metadata.settype] || '';
    },
    isChanged() {
      return Object.keys(this.changedFields).some(
        (fieldId) => this.changedFields[fieldId]
      );
    },
    metadataRemoveConfirmationButton() {
      return [
        {
          label: 'Remove',
          callback: () => {
            this.deleteMetadata();
          },
        },
        {
          label: 'Cancel',
          outline: true,
          callback: function () {},
        },
      ];
    },
  },
  methods: {
    onFieldChange({ id, changed }) {
      this.$set(this.changedFields, id, changed);
    },
    onDataInput(field, attidx) {
      let metadata = _.cloneDeep(this.metadata);
      metadata.attributes.splice(attidx, 1, field);
      metadata[`attribute${attidx}_value`] = field.value;
      this.$emit('input', metadata);
    },
    toggleSection() {
      this.isExpanded = !this.isExpanded;

      if (this.isExpanded) {
        this.$nextTick(() => {
          let firstInput = document.querySelectorAll('.meta-input')[0];
          firstInput && firstInput.focus();
        });
      }
    },
    async deleteMetadata() {
      this.$emit('deleteMetadata');
    },
    saveAttributes() {
      this.$emit('saveAttributes');
    },
    getFieldDatatypeText(type) {
      const dataTypes = {
        1: 'text',
        2: 'int',
        3: 'decimal',
        4: 'boolean',
        5: 'date',
        6: 'enum',
        7: 'array',
        8: 'array-objects',
        9: 'kvp',
      };
      return dataTypes[type] || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.metadata-tab {
  .form-group.first {
    padding: 16px 0 0 0;

    .set-empty {
      font-size: 13px;
      display: block;
      color: var(--text-light);
    }

    label {
      font-size: 14px;
    }
  }

  button.btn {
    min-width: unset;
  }
}
.fas {
  color: var(--text-dark);
}

.fa-caret-up {
  vertical-align: middle;
}
.fa-caret-down {
  vertical-align: middle;
}

.mx-datepicker::v-deep {
  .mx-input:disabled,
  .mx-input.disabled {
    color: #666;
  }
}
</style>
