import QRCode from 'qrcode';

export const downloadQRCode = (text, filename = 'qrcode') => {
  const canvas = document.createElement('canvas');

  QRCode.toCanvas(canvas, text, (error) => {
    if (error) console.error(error);

    const link = document.createElement('a');

    link.download = filename;

    link.href = canvas.toDataURL();

    link.click();

    URL.revokeObjectURL(link); // free memory after download is complete
  });
};
