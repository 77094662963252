<template>
  <div class="zip-viewer-explorer-header__cta">
    <div class="explorer-cta">
      <VPopover
        v-if="canUpload"
        offset="5"
        popover-class="dropdown"
        class="sample"
        container="body"
        :open="showTooltip"
        :auto-hide="true"
        @hide="toggleTooltip(false)"
      >
        <button class="btn btn-primary" @click="toggleTooltip(true)">
          <Icon name="fas fa-plus-circle" family="fas" />
          {{ $t('file_list.header.actions.folders_operations_button') }}
        </button>
        <div slot="popover">
          <div>
            <!-- region Upload Files -->
            <div class="dropdown-item" @click="selectFile">
              <Icon
                name="upload"
                :aria-label="$t('file_list.header.actions.upload_file_button')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.header.actions.upload_file_button') }}
                <span class="shortkey float-right text-capitalize"> U </span>
              </span>
            </div>
            <!-- endregion -->

            <!-- region Upload Folder -->
            <div class="dropdown-item" @click="selectFolder">
              <Icon
                name="folder-open"
                :aria-label="$t('file_list.header.actions.upload_folder')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.header.actions.upload_folder') }}
              </span>
            </div>
            <!-- endregion -->

            <!-- region Create Folder -->
            <div class="dropdown-item" @click="toggleNewFolderInput">
              <Icon
                name="folder-plus"
                :aria-label="$t('file_list.header.actions.new_folder')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.header.actions.new_folder') }}
                <span class="shortkey float-right text-capitalize">
                  Alt + N
                </span>
              </span>
            </div>
            <!-- endregion -->
          </div>
        </div>
      </VPopover>
      <button
        v-else-if="canDownload"
        class="btn btn-primary"
        @click="$emit('download-zip-file')"
      >
        <Icon name="fas fa-plus-download" family="fas" />
        {{ $t('file_list.header.actions.download_zip') }}
      </button>
      <input
        ref="fileInput"
        type="file"
        hidden
        multiple
        @click="clearInput"
        @change="uploadItem($event, 'file')"
      />
      <input
        ref="folderInput"
        type="file"
        hidden
        multiple
        directory
        webkitdirectory
        @click="clearInput"
        @change="uploadItem($event, 'folder')"
      />
    </div>
    <ZipViewerActionButton @click="toggleMobileMenu" />
    <ZipViewerMobileMenu
      :open="mobileMenuVisible"
      :z-index="9999999"
      @close="toggleMobileMenu"
    >
      <template #body>
        <ZipViewerMobileMenuActionList
          :params="{ canUpload, canDownload }"
          :actions="newFileActions"
          @trigger-action="triggerAction"
        />
      </template>
    </ZipViewerMobileMenu>
  </div>
</template>

<script>
import Icon from '../../Icon';
import { VPopover } from 'v-tooltip';
import ZipViewerActionButton from '../ZipViewerActionButton';
import ZipViewerMobileMenu from '../ZipViewerMobileMenu';
import ZipViewerMobileMenuActionList from '../ZipViewerMobileMenuActionList';
import newFileActions from '../Constants/newFileActions';

export default {
  name: 'ZipViewerHeaderActions',
  components: {
    Icon,
    VPopover,
    ZipViewerActionButton,
    ZipViewerMobileMenu,
    ZipViewerMobileMenuActionList,
  },
  props: {
    canUpload: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTooltip: false,
      mobileMenuVisible: false,
      newFileActions,
    };
  },
  methods: {
    toggleTooltip(show) {
      this.showTooltip = show;
    },

    toggleNewFolderInput() {
      this.toggleTooltip(false);
      this.$emit('toggle-new-folder-input', true);
    },

    selectFile() {
      this.toggleTooltip(false);
      this.$refs.fileInput.click();
    },

    selectFolder() {
      this.toggleTooltip(false);
      this.$refs.folderInput.click();
    },

    clearInput(e) {
      e.target.value = null;
    },

    async uploadItem(event) {
      this.$emit('upload-item', event.target.files);
    },

    toggleMobileMenu() {
      this.mobileMenuVisible = !this.mobileMenuVisible;
    },

    triggerAction(action) {
      if (action === 'upload-file') {
        this.$refs.fileInput.click();
      } else if (action === 'upload-folder') {
        this.$refs.folderInput.click();
      } else if (action === 'toggle-new-folder-input') {
        this.toggleNewFolderInput();
      }
      this.mobileMenuVisible = false;
    },
  },
};
</script>
