<template>
  <div
    class="zip-viewer-file-list__body-row"
    :class="{
      'zip-viewer-file-list__body-row--hover': contextMenuOpen,
    }"
    :style="{
      opacity: isDeleting ? 0 : 1,
    }"
  >
    <!-- region Icon Column -->
    <div
      class="zip-viewer-file-list__body-column zip-viewer-file-list__body-column--icon"
    >
      <Icon
        v-if="isFolder || isNewFolder"
        name="folder"
        :aria-label="$t('folder')"
        class="file-icon file-icon--folder"
        family="fas"
      />
      <FileIcon v-else :name="item.name" :aria-label="item.name" />
    </div>
    <!-- endregion -->

    <!-- region Name Column -->
    <div
      class="zip-viewer-file-list__body-column zip-viewer-file-list__body-column--name"
    >
      <!-- New Folder Input -->
      <span v-if="isNewFolder" class="input-group-sm">
        <input
          ref="newFolderInputDesktop"
          v-model="newFolderName"
          type="text"
          class="form-control zip-viewer-file-list__new-folder-input"
          :disabled="isCreatingNewFolder"
          @keyup.enter="createNewFolder"
          @keyup.escape="createNewFolder"
          @blur="createNewFolder"
        />
      </span>

      <!-- Item Name -->
      <span
        v-else
        class="zip-viewer-file-list__body-cell zip-viewer-file-list__body-cell--name"
        @click="openItem()"
      >
        {{ item.name }}
      </span>
    </div>
    <!-- endregion -->

    <!-- region Modified Date Column -->
    <div
      class="zip-viewer-file-list__body-column zip-viewer-file-list__body-column--modified"
    >
      <span
        v-if="!isNewFolder"
        class="zip-viewer-file-list__body-cell zip-viewer-file-list__body-cell--modified"
      >
        {{ formatDateTime(item.timestamp) }}
      </span>
      <ZipViewerFileListActions
        v-if="!isNewFolder"
        :can-upload="canUpload"
        :can-download="canDownload"
        :is-folder="isFolder"
        @toggle-context-menu="toggleContextMenu"
        @browse="openItem"
        @preview-item="$emit('preview-item', item)"
        @download-item="$emit('download-item', item)"
        @delete-item="deleteItem"
      />
    </div>
    <!-- endregion -->

    <!-- region Size Column -->
    <div
      class="zip-viewer-file-list__body-column zip-viewer-file-list__body-column--size"
    >
      <span
        v-if="!isNewFolder && !isFolder"
        class="zip-viewer-file-list__body-cell zip-viewer-file-list__body-cell--size"
      >
        {{ humanFileSize(item.fullsize) }}
      </span>
    </div>
    <!-- endregion -->

    <!-- region Mobile -->
    <div
      class="zip-viewer-file-list__body-column zip-viewer-file-list__body-column--mobile"
      @click="openItem()"
    >
      <!-- New Folder Input -->
      <span v-if="isNewFolder" class="input-group-sm">
        <input
          ref="newFolderInputMobile"
          v-model="newFolderName"
          type="text"
          class="form-control zip-viewer-file-list__new-folder-input"
          :disabled="isCreatingNewFolder"
          @keyup.enter="createNewFolder"
          @keyup.escape="createNewFolder"
          @blur="createNewFolder"
        />
      </span>
      <span
        v-else
        class="zip-viewer-file-list__body-cell zip-viewer-file-list__body-cell--mobile"
      >
        {{ item.name }} <br />
        <span>
          <template v-if="!isFolder">
            {{ humanFileSize(item.fullsize) }} -
          </template>
          {{ formatDateTime(item.timestamp) }}
        </span>
      </span>
      <span
        class="zip-viewer-file-list__body-cell zip-viewer-file-list__body-cell--mobile-options"
        @click.stop="$emit('toggle-mobile-menu', item)"
      >
        <Icon name="ellipsis-v" family="far" />
      </span>
    </div>
    <!-- endregion -->
  </div>
</template>
<script>
import Icon from '../../Icon';
import FileIcon from '../../FileIcon';
import zipViewerMixin from '../zipViewerMixin';
import ZipViewerFileListActions from './ZipViewerFileListActions';
import _ from 'lodash';

export default {
  name: 'ZipViewerFileListItem',
  components: { Icon, FileIcon, ZipViewerFileListActions },
  mixins: [zipViewerMixin],
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    isNewFolder: {
      type: Boolean,
      default: false,
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newFolderName: '',
      contextMenuOpen: false,
      isDeleting: false,
      isCreatingNewFolder: false,
    };
  },
  computed: {
    isFolder() {
      return this.item && this.item.type === 'dir';
    },
  },
  watch: {
    item() {
      if (this.isDeleting) {
        this.isDeleting = false;
      }
    },
  },
  mounted() {
    if (this.isNewFolder) {
      this.$nextTick(() => {
        this.$refs.newFolderInputDesktop.focus();
        this.$refs.newFolderInputMobile.focus();
      });
    }
  },
  methods: {
    openItem() {
      if (this.isFolder) {
        this.$emit('browse', this.item.fullentrypath);
      } else {
        this.$emit('preview-item', this.item);
      }
    },

    // Uses throttling to avoid keyup.enter and blur events from firing at the same time
    createNewFolder: _.throttle(
      function (event) {
        const isEscape = event.keyCode === 27;

        this.isCreatingNewFolder = true;
        this.$emit('create-new-folder', isEscape ? '' : this.newFolderName);
      },
      100,
      { leading: true, trailing: false }
    ),

    toggleContextMenu(open) {
      this.contextMenuOpen = open;
    },

    deleteItem() {
      this.isDeleting = true;
      this.$emit('delete-item', this.item);
    },
  },
};
</script>
