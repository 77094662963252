<template>
  <div class="form-group col-sm">
    <InputBox
      :id="'addEmails'"
      :label="label"
      :placeholder="$t('Enter comma separated emails')"
      :focus="false"
      v-model="form.invitedEmails"
      @input="handleEmails"
      :errors="{}"
      class="px-0"
    />
    <ul class="list-group" v-if="type === 'list'">
      <li
        class="list-group-item"
        v-for="selUser in users"
        :key="selUser.username"
      >
        <div class="d-flex align-items-center">
          <Avatar
            :user="selUser.username"
            size="share"
          />
          <div class="col-md-10">
            <h6>{{ selUser.displayname }}</h6>
            {{ selUser.emailid }}
          </div>
          <button
            class="float-right btn btn-sm btn-icon fa fa-times text-muted"
          ></button>
        </div>
      </li>
      <li
        class="list-group-item"
        style="height: 10rem"
        v-if="users.length == 0"
      >
        <p>No user invited.</p>
      </li>
    </ul>

    <div class="userlist-tag" v-else-if="type === 'tag'">
      <div
        class="badge badge-secondary"
        v-for="selUser in users"
        :key="selUser.username"
      >
        <Avatar
          :user="selUser.username"
          style="height: 19px; width: 19px; display: inline;"
        />

        <span class="username">{{ selUser.emailid }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import serverURL from 'common/filecloud/properties/serverURL';
import InputBox from 'common/components/InputBox';
import Avatar from 'common/components/Avatar';

export default {
  data() {
    return {
      query: '',
    };
  },
  methods: {
    getProfileImage(username) {
      return serverURL.domainURL + '/core/getprofileimage?username=' + username;
    },
    handleEmails(value) {
      if (this.form.activeTab == 'existing') {
        this.form.existingEmail = value;
      }
      if (this.form.activeTab == 'new') {
        this.form.newEmail = value;
      }
    },
  },
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    callbackHit: {
      type: Function,
      default: function () {},
      required: false,
    },
    callbackRemove: {
      type: Function,
      default: function () {},
      required: false,
    },
    users: {
      type: Array,
      default: () => [],
      required: false,
    },
    autoFill: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String,
      default: 'list',
      required: false,
    },
    form: {
      type: Object,
    },
  },
  mounted() {},
  components: {
    InputBox,
    Avatar,
  },
};
</script>
