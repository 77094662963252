<template>
  <DialogModal
    class="mini-admin-reset-password-modal"
    :stick-top="true"
    :visible="visible"
    :title="$t('Reset password')"
    :contain-form="true"
    :buttons="[
      {
        label: $t('Close'),
        outline: true,
        callback: function () {
          close();
        },
      },
      {
        label: $t('Save'),
        callback: function () {
          handleSave();
        },
      },
    ]"
    @close="close"
  >
    <Loader :loading="isLoading" full />

    <form @submit.prevent="handleSave">
      <div class="reset-password">
        <transition name="fade">
          <InfoBox v-if="errorMessage != ''"> {{ errorMessage }} </InfoBox>
        </transition>

        <InputBox
          id="password"
          v-model="password.value"
          :type="password.type"
          :label="$t('New password')"
          :appends="passwordEye"
          :errors="errors"
          focus
        />

        <InputBox
          id="passwordconfirm"
          v-model="confirmPassword.value"
          :type="confirmPassword.type"
          :label="$t('Confirm password')"
          :appends="confirmPasswordEye"
          :errors="errors"
        />
      </div>
    </form>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal.vue';
import InputBox from 'common/components/InputBox.vue';
import Loader from 'common/components/Loader.vue';
import InfoBox from 'common/components/InfoBox.vue';

export default {
  components: {
    DialogModal,
    InputBox,
    Loader,
    InfoBox,
  },

  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      errorMessage: '',
      password: {
        value: '',
        type: 'password',
      },
      confirmPassword: {
        value: '',
        type: 'password',
      },
    };
  },
  computed: {
    passwordEye() {
      return [
        {
          icon: this.password.type == 'password' ? 'eye' : 'eye-slash',
          iconBold: true,
          action: () => this.togglePasswordType(this.password),
        },
      ];
    },
    confirmPasswordEye() {
      return [
        {
          icon: this.confirmPassword.type == 'password' ? 'eye' : 'eye-slash',
          iconBold: true,
          action: () => this.togglePasswordType(this.confirmPassword),
        },
      ];
    },
    username() {
      return this.$store.state.miniAdmin.resetPasswordModal.username;
    },
    isLoading() {
      return this.$store.state.loading['miniAdmin/resetPassword'];
    },
  },
  watch: {
    visible(isVisible) {
      if(isVisible) {

        // reset data
        this.errors = {};
        this.errorMessage = '';

        this.password = {
          value: '',
          type: 'password',
        };
        
        this.confirmPassword = {
          value: '',
          type: 'password',
        };
        
      }
    }
  },
  methods: {
    async handleSave() {
      this.errors = {};
      this.errorMessage = '';

      const res = await this.$store.dispatch('miniAdmin/resetPassword', {
        profile: this.username,
        password: this.password.value,
        passwordconfirm: this.confirmPassword.value,
      });

      if (!res.ok) {
        if (typeof res.error !== 'string') {
          this.errors = res.error;
        } else {
          this.errorMessage = res.error;
        }
      }
    },
    togglePasswordType(password) {
      password.type = password.type === 'password' ? 'text' : 'password';
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.mini-admin-reset-password-modal {
  &.dialog-modal-overlay {
    z-index: 10002;

    .dialog-modal-outer {
      max-width: 470px !important;

      form {
        div.reset-password {
          h5 {
            font-size: 16px;
            font-weight: bold;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            color: var(--text-muted);
          }
        }
      }
    }
  }
}
</style>
