<template>
  <div class="user-group">
    <Loader v-if="loading" :loading="loading" full />
    <div v-else class="tab-wrapper">
      <div class="tab-header">
        <div
          class="tab-header-title"
          tabindex="0"
          :class="{ active: (activeTab === 'users' || activeInheritedTab==='users') }"
          @click="loadAcls()"
          v-a11ybutton="()=>loadAcls()"
        >{{ $t('Users') }}</div>
        <div
          class="tab-header-title"
          tabindex="0"
          :class="{ active: (activeTab === 'group' || activeInheritedTab==='group') }"
          ref="groupTab"
          @click="getGroupAcls()"
          v-a11ybutton="()=>getGroupAcls()"
        >{{ $t('Groups') }}</div>
      </div>
      <div v-if="activeTab === 'users' || activeInheritedTab==='users'" class="tab-content overflow-y">
        <div class="d-flex justify-content-end" v-if="!inherited">
          <button class="btn btn-sm btn-primary invite-user" @click="showAddUser">{{ $t('Add User') }}</button>
        </div>
        <div v-if="!loading" class="flex-table user-group-table mt-2">
          <securityPermissionTable
            label="Users"
            :items="usersWithPermissions"
            type="user"
            :path="path"
            :inherited="inherited"
            @updatePerms="updatePerms"
            @deleteAcl="removeAcl"
          />
        </div>
      </div>
      <div v-if="activeTab === 'group' || activeInheritedTab==='group' " class="tab-content overflow-y">
        <div class="d-flex justify-content-end" v-if="!inherited">
          <button
            class="btn btn-primary invite-user mb-2"
            @click="showAddGroup"
          >{{ $t('Add Group') }}</button>
        </div>
        <div v-if="!loading">
          <securityPermissionTable
            label="Groups"
            :items="groupsWithPermissions"
            type="group"
            :path="path"
            :inherited="inherited"
            @updatePerms="updatePerms"
            @deleteAcl="removeAcl"
          />
        </div>
      </div>
    </div>
    <div class="hidden d-none">
      <InviteUser
        :context="addGroupUserContext"
        v-if="showAddUserModal"
        @hideInviteUser="closeModalUser"
        :show-modal="showAddUserModal"
      />
      <AddGroup
        :context="addGroupUserContext"
        v-if="showAddGroupModal"
        @hideInviteGroup="closeModalGroup"
        :show-modal="showAddGroupModal"
      />
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import SecurityRow from '../Security/SecurityRow';
import Loader from 'common/components/Loader';
import securityPermissionTable from './securityPermissionTable';
import AddGroup from '../Modals/AddGroup';
import InviteUser from '../Modals/InviteUser';


export default {
  name: 'securityUserGroup',
  components: {
    Icon,
    SecurityRow,
    securityPermissionTable,
    Loader,
    AddGroup,
    InviteUser,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    inherited: {
      type: Boolean,
      default: false
    },
    aclMetaProp:{
      default: () => {},
      type: Object
    }
  },
  data() {
    return {
      activeTab: 'users',
      showInviteUserDialog: false,
      aclMeta: [],
      users: [],
      groups: [],
      filter: 'metaonly',
      showAddUserModal: false,
      showAddGroupModal: false,
      message: '',
      loading: false,
    };
  },
  computed: {
    groupsWithPermissions() {
      if(!Array.isArray(this.groups)) return []
      return this.groups.map((group) => {
        let _group = {};
        _group.name = group.groupname;
        _group.groupid = group.groupid;
        _group.read = group.perm.includes('R') && group.flag==='allow';
        _group.write = group.perm.includes('W') && group.flag==='allow';
        _group.share = group.perm.includes('S') && group.flag==='allow';
        _group.allowmanage = group.perm.includes('M') && group.flag==='allow';
        _group.disallowdelete = !group.perm.includes('D') && group.flag==='allow';
        return _group;
      });
    },
    usersWithPermissions() {
      if(!Array.isArray(this.users)) return [];
      return this.users.map((user) => {
        let _user = {};
        _user.name = user.user;
        if(user.perm){
        _user.read = user.perm.includes('R') && user.flag==='allow';
        _user.write = user.perm.includes('W') && user.flag==='allow';
        _user.share = user.perm.includes('S') && user.flag==='allow';
        _user.allowmanage = user.perm.includes('M') && user.flag==='allow';
        _user.disallowdelete = !user.perm.includes('D') && user.flag==='allow';
      }
        return _user;
      });
    },
    addGroupUserContext(){
      return {
          context:"security",
          path: this.path
      }
    }
  },
  created() {
    this.loadAcls();
  },
  methods: {
    updatePerms(payload) {
      if (payload.type === 'user' && this.users.length) {
        this.users = this.users.map((user) => {
          if (user.user == payload.value) {
            user.perm = payload.perm;
          }
          return user;
        });
      }
      if (payload.type === 'group' && this.groups.length) {
        this.groups = this.groups.map((group) => {
          if (group.groupid == payload.value) {
            group = { ...group, ...payload };
          }
          return group;
        });
      }
    },
    async getGroupAcls(){
      this.$refs.groupTab.click();
       let payload = {
        path: this.path,
        listInherited: this.inherited ? 1 : 0,
        filter: 'groups'
      };
      const groupAclsForPath = await this.$store.dispatch(
        'files/getAclForPath',
        payload
      );
      this.activeTab = 'group';
      this.activeInheritedTab = 'group';
      if (
        !groupAclsForPath ||
        !groupAclsForPath.ok ||
        !groupAclsForPath.data ||
        !(groupAclsForPath.data.ace || groupAclsForPath.data.inherited_ace)
      ) {
        this.groups = [];
      } else {
        let _data = this.inherited ? groupAclsForPath.data.inherited_ace : groupAclsForPath.data.ace
        _data = Array.isArray(_data) ? _data : [_data];
        this.groups = _data;
          
      }
    },
    closeModalUser: function () {
      this.showAddUserModal = false;
      this.loadAcls();
    },
    closeModalGroup: function () {
      this.showAddGroupModal = false;
      this.getGroupAcls();
    },
    showAddUser: function () {
      this.showAddUserModal = true;
    },
    showAddGroup: function () {
      this.showAddGroupModal = true;
    },
    async userGroupInAcl(type = 'users'){
      let payload = {
        path: this.path,
        listInherited: this.inherited ? 1 : 0,
        filter: type
      };
      const userAclsForPath = await this.$store.dispatch(
        'files/getAclForPath',
        payload
      );

      if (
        !userAclsForPath ||
        !userAclsForPath.ok ||
        !userAclsForPath.data ||
        !(userAclsForPath.data.ace || userAclsForPath.data.inherited_ace)
      ) {
        return [];
      }
      let _data = this.inherited ? userAclsForPath.data.inherited_ace : userAclsForPath.data.ace
      _data = Array.isArray(_data) ? _data : [_data];
      return _data;
    },
    async loadAcls() {
      this.loading = true;
      // Get ACL meta
      this.aclMeta = this.aclMetaProp;
      this.activeTab = 'users';
      this.activeInheritedTab = 'users';
      this.users = await this.userGroupInAcl();
      this.loading = false;
    },
    async removeAcl(payload) {
      if (payload.type === 'user') this.users.splice(payload.index, 1);
      else this.groups.splice(payload.index, 1);

      let response = await this.$store.dispatch('files/deleteAclEntryForPath', {
        path: this.path,
        value: payload.value,
        type: payload.type,
      });

      if (!response.ok) {
        await this.loadAcls();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .user-group-table {
   margin-top:0;
  .row-heading,
  .row-content {
    .name {
      width: 180px;
    }
    > div.view {
      width: calc((100% - 180px - 20px) / 5);
    }
  }

  .row-content > span {
    width: calc((100% - 180px - 20px) / 5);
  }
}
</style>
