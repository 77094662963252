<template>
  <div class="fc-explorer--header colored-border zip-viewer-explorer-header">
    <ZipViewerBreadcrumb
      :root="fileName"
      :path="subFolder"
      @browse="(folderName) => browse({ path: folderName })"
    />
    <div class="nav-body">
      <ZipViewerHeaderTitle
        :item-name="currentDir"
        :items-count="itemsCount"
        :is-encrypted="!!isEncrypted"
      />
      <ZipViewerHeaderActions
        :can-upload="canUpload"
        :can-download="canDownload"
        @toggle-new-folder-input="toggleNewFolderInput"
        @upload-item="uploadItem"
        @download-zip-file="downloadZipFile(rootFile.item)"
      />
    </div>
    <DialogModal
      v-if="fileReplacementConfirmationDialogVisible"
      class="dialog-modal-overlay dialog-modal-form timed-fade stick-top"
      size="small"
      z-index="1000000"
      :buttons="actionButtons"
      :class="[
        { 'dialog-modal-visible': fileReplacementConfirmationDialogVisible },
      ]"
      :title="$t('zip_preview.file_replacement.modal_title')"
      :visible="fileReplacementConfirmationDialogVisible"
      @close="toggleFileReplacementConfirmationDialog(false)"
      @keyup="$emit('keyup', $event)"
    >
      {{ $t('zip_preview.file_replacement.modal_headline') }}
      <br />
      {{ $t('zip_preview.file_replacement.modal_description') }}
    </DialogModal>
  </div>
</template>

<script>
import ZipViewerBreadcrumb from './Header/ZipViewerBreadcrumb';
import ZipViewerHeaderTitle from './Header/ZipViewerHeaderTitle';
import ZipViewerHeaderActions from './Header/ZipViewerHeaderActions';
import { mapState, mapGetters, mapActions } from 'vuex';
import DialogModal from '../DialogModal';

export default {
  name: 'ZipViewerHeader',
  components: {
    DialogModal,
    ZipViewerBreadcrumb,
    ZipViewerHeaderTitle,
    ZipViewerHeaderActions,
  },
  data() {
    return {
      fileReplacementConfirmationDialogVisible: false,
      itemToReplace: null,
    };
  },
  computed: {
    ...mapGetters('zipViewer', {
      currentPath: 'currentPath',
      currentDir: 'currentDir',
      subFolder: 'subFolder',
      fileName: 'fileName',
      itemsCount: 'itemsCount',
      isEncrypted: 'isEncrypted',
      canUpload: 'canUpload',
      canDownload: 'canDownload',
    }),
    ...mapState('zipViewer', {
      items: 'items',
      rootFile: 'rootFile',
    }),
    actionButtons() {
      return [
        {
          label: this.$t('zip_preview.file_replacement.confirm_button'),
          callback: () => {
            this.toggleFileReplacementConfirmationDialog(false);
            this.dispatchUploadItem(this.itemsToUpload);
          },
          focus: true,
          danger: true,
        },
        {
          label: this.$t('zip_preview.file_replacement.cancel_button'),
          callback: () => this.toggleFileReplacementConfirmationDialog(false),
          outline: true,
        },
      ];
    },
  },
  methods: {
    ...mapActions('zipViewer', {
      toggleNewFolderInput: 'toggleNewFolderInput',
      dispatchUploadItem: 'uploadItem',
      browse: 'browse',
    }),
    ...mapActions('files', {
      downloadZipFile: 'download',
    }),
    toggleFileReplacementConfirmationDialog(show) {
      this.fileReplacementConfirmationDialogVisible = show;
    },
    fileAlreadyExists(name) {
      return this.items.some((item) => item.name === name);
    },
    uploadItem(items) {
      let fileAlreadyExists = false;
      for (const item of items) {
        if (this.fileAlreadyExists(item.name)) {
          this.itemsToUpload = items;
          this.toggleFileReplacementConfirmationDialog(true);
          fileAlreadyExists = true;
          break;
        }
      }
      if (!fileAlreadyExists) {
        this.dispatchUploadItem(items);
      }
    },
  },
};
</script>
