<template>
  <select class="LanguageSwitcher" name="language" @change="changeLanguage" :aria-label="$t('Language')">
    <option
      v-for="lang in supportedLanguages"
      :key="lang.name"
      :selected="lang.currentLang"
      :class="{ 'is-selected': isCurrentLanguage(lang.name) }"
      :value="lang.name"
    >
      {{ lang.name }}
    </option>
  </select>
</template>
<script>
import { i18n, loadLanguageAsync } from '../../i18n.js';

export default {
  computed: {
    supportedLanguages() {
      return this.$store.state.core.languages;
    },
  },
  methods: {
    async changeLanguage(e) {
      localStorage.removeItem('currentLang');
      localStorage.removeItem('translations');
      await loadLanguageAsync(e.target.value);
      localStorage.setItem('logincurrentLang', 1);
    },
    isCurrentLanguage(lang) {
      return lang === i18n.locale;
    },
  },
};
</script>

<style scoped>
select {
  height: 17px;
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  border: none;
  width: 17%;
  -moz-text-align-last: center;
  text-align-last: center;
}
</style>
