<template>
  <div>
    <Loader v-if="loading" :loading="loading" full />
    <table v-else class="table table-dialog-modal table--versions">
      <thead>
        <tr>
          <th scope="col">{{ $t('Details') }}</th>
          <th scope="col">{{ $t('Size') }}</th>
          <th scope="col">{{ $t('Created on') }}</th>
          <th scope="col">{{ $t('Who') }}</th>
          <th class="centered actions-cell" scope="col">{{ $t('Actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(version, index) of versions" :key="index">
          <th scope="row" class="w-50">
            <strong>{{
              $t('Version') + ' ' + (versions.length - index)
            }}</strong>
            {{ index === 0 ? ` (${$t('Current Version')})` : '' }}
            <br />
            <span v-if="version.versionedon && version.versionedon.length > 0">
              <span class="small-grayed">
                <strong>{{ $t('Versioned On') }}: </strong>
                <span
                  ><DateDisplay :date="Date.parse(version.versionedon) / 1000"
                /></span>
              </span>
              <br />
            </span>
            <span
              v-if="version.checksum && version.checksum.length > 0"
              v-clipboard:copy="version.checksum"
              class="small-grayed d-block w-75"
            >
              <strong>{{ $t('Checksum') }}: </strong>
              <span>
                {{ version.checksum }}
                <Icon
                  v-tooltip="$t('Copy')"
                  v-clipboard:copy="version.checksum"
                  name="copy"
                  family="fal"
                  class="ml-2 cursor-pointer"
                />
              </span>
            </span>
          </th>
          <td>{{ version.size }}</td>
          <td>
            <span v-if="version.createdon && version.createdon.length > 0"
              ><DateDisplay :date="Date.parse(version.createdon.replace(/-/g, ' ')) / 1000"
            /></span>
            <span v-else>-</span>
          </td>
          <td>{{ version.createdby.length > 0 ? version.createdby : '-' }}</td>
          <td v-if="index !== 0" class="centered">
            <button
              v-tooltip="$t('Download Version')"
              class="btn btn-sm btn-icon action-btn"
              @click="downloadFile(version)"
            >
              <Icon name="download" family="fal" />
            </button>

            <v-popover
              v-if="index > 0"
              v-tooltip="$t('Delete version')"
              class="action-btn"
            >
              <button class="btn btn-sm btn-icon action-btn">
                <Icon name="trash-alt" family="fal" />
              </button>
              <Alert
                slot="popover"
                button-type="danger"
                :buttons="[
                  {
                    label: $t('Ok'),
                    callback: function () {
                      deleteVersion(version);
                    },
                  },
                  { label: `Cancel`, outline: true, callback: function () {} },
                ]"
                ><span role="alert">{{
                  $t('Delete the selected version of the file?')
                }}</span></Alert
              >
            </v-popover>

            <v-popover
              v-if="index > 0"
              v-tooltip="$t('Make as current version')"
              class="action-btn"
            >
              <button class="btn btn-sm btn-icon action-btn">
                <Icon name="bolt" family="fal" />
              </button>
              <Alert
                slot="popover"
                :buttons="[
                  {
                    label: $t('Ok'),
                    callback: function () {
                      changeLiveVersion(version);
                    },
                  },
                  { label: `Cancel`, outline: true, callback: function () {} },
                ]"
              >
                <span role="alert">
                  {{
                    $t(
                      'Selected version will be made live. Note that the current version will be versioned or removed depending upon settings. Continue?'
                    )
                  }}
                </span>
              </Alert>
            </v-popover>
          </td>
          <td v-else class="centered">-</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Vue from 'vue';
import Icon from 'common/components/Icon';
import Alert from 'common/components/Alert';
import DateDisplay from 'common/components/DateDisplay';
import Loader from 'common/components/Loader';
import { handleArrayResponse } from '../../utils/dataUtils';

import VueClipboard from 'vue-clipboard2';

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

export default {
  components: {
    Icon,
    Alert,
    Loader,
    DateDisplay,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      versions: [],
    };
  },
  computed: {
    loading() {
      return (
        this.$store.state.loading['files/versions'] ||
        this.$store.state.loading['files/makeVersionLive'] ||
        this.$store.state.loading['files/deleteVersion']
      );
    },
  },
  mounted() {
    this.getVersions();
  },
  methods: {
    async getVersions() {
      const payload = {
        file: this.item,
        checksum: 1,
      };
      const {
        ok,
        data: { version },
      } = await this.$store.dispatch('files/versions', payload);
      if (ok) {
        this.versions = new Array();
        const arrVersions = handleArrayResponse(version);
        arrVersions.forEach((versionEl) => {
          if (typeof versionEl.versionedon === 'object')
            this.versions.unshift(versionEl);
          else this.versions.push(versionEl);
        });
      }
    },
    async downloadFile(version) {
      let response;
      try {
        response = await this.$store.dispatch('files/downloadVersioned', {
          ...this.item,
          fileid: version.fileid,
          filename: version.filename,
        });
      } catch (e) {
        response = e;
      }
      if (typeof response !== 'undefined' && !response.ok) {
        window.response = response;
        let errorMsg =
          response.message &&
          response.message.data.message.includes('!DOCTYPE html')
            ? this.$t('Download failed')
            : response.message.data.message;
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Error') +
            '</b><p role="alert">' +
            errorMsg +
            '</p>',
          type: 'error',
        });
      } else {
        this.$root.$emit(
          'announceScreenReader',
          this.$t('Download has started')
        );
      }
    },

    async changeLiveVersion(version) {
      let versionToDelete = version;
      versionToDelete['dirpath'] = this.item['dirpath'];
      versionToDelete['name'] = this.item['name'];
      const response = await this.$store.dispatch(
        'files/makeVersionLive',
        versionToDelete
      );
      if (response.ok) {
        this.$toast.open({
          message: `<b> ${this.$t('Success')} </b><p role="alert"> ${this.$t(
            'Version changed successfully.'
          )} </p>`,
          type: 'success',
        });
        this.getVersions();
      } else {
        this.$toast.open({
          message: `<b> ${this.$t('Error')} </b><p role="alert"> ${
            response.error
          } </p>`,
          type: 'error',
        });
      }
    },

    async deleteVersion(version) {
      let versionToDelete = version;
      versionToDelete['dirpath'] = this.item['dirpath'];
      versionToDelete['name'] = version['filename'];
      const response = await this.$store.dispatch(
        'files/deleteVersion',
        versionToDelete
      );

      if (response.ok) {
        this.$toast.open({
          message: `<b> ${this.$t('Success')} </b><p role="alert"> ${this.$t(
            'Version deleted successfully.'
          )} </p>`,
          type: 'success',
        });
        this.getVersions();
      } else {
        this.$toast.open({
          message: `<b> ${this.$t('Error')} </b><p role="alert"> ${
            response.error
          } </p>`,
          type: 'error',
        });
      }
    },
    splitDate(input) {
      return input.replace(' ', '<br>');
    },
    versionLabel(version) {
      let versionNumber = Number(version.split(' ')[1]);
      if (versionNumber === 1) {
        return this.$t('Current Version');
      } else {
        return `${this.$t('Version')} ${versionNumber - 1}`;
      }
    },
  },
};
</script>
