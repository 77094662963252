<template>
  <div
    role="row"
    class="file-list-header"
    :class="{
      'file-list-header--fixed': sticky && !isMobile && !isTablet,
    }"
  >
    <div
      v-a11ybutton="onSelectAll"
      class="col-checkbox"
      :class="{
        'col-checkbox--grid': getIsGrid,
      }"
      tabindex="0"
      role="cell"
      @click="onSelectAll"
    >
      <Checkbox
        id="select-all"
        tabindex="-1"
        :checked="
          selectedFiles.length > 0 &&
          selectedFiles.length === actualFiles.length
        "
        :label="$t('Select all')"
      />
      <label v-if="getIsGrid" class="select-all-label">
        {{ $t('Select All') }}
      </label>
    </div>
    <div
      id="genfilename"
      role="columnheader"
      class="col-title header"
      aria-label="File or Folder name"
      :class="{
        'col-title--grid': getIsGrid,
        'col-10': isMobile || isTablet,
      }"
    >
      <span v-if="!getIsGrid" @click="onSort('name', $event)">
        {{ $t('Name') }}
        <button class="btn btn-link p-0 mb-2">
          <span class="invisible">Enter to sort by Name</span>
          <Icon
            :aria-label="$t('Enter to sort by Name')"
            tabindex="0"
            role="button"
            on-key-down="onSort('name', $event)"
            name="chevron-up"
            class="sortable"
            :class="{
              desc: sort && sort.type === -1,
              hidden: sort && sort.field !== 'name',
            }"
          />
        </button>
      </span>
      <span
        class="filter"
        :class="{ 'col-9': isMobile || isTablet, 'filter--grid': getIsGrid }"
      >
        <form autocomplete="off">
          <InputBox
            id="filter-files"
            :readonly="loading"
            :value="filterValue"
            class="filter-items"
            :placeholder="$t('Filter Items')"
            :errors="errors"
            :prepends="filterInputPrepends"
            form-size="sm"
            @input="onFilter"
          />
        </form>
      </span>
    </div>

    <!-- region Sorting Options -->

    <!-- region GridView -->
    <div v-if="getIsGrid" class="d-none d-sm-flex align-items-center">
      <span class="mr-3">{{ $t('Sort') }}</span>
      <v-select
        id="sort-files"
        :options="sortingOptions"
        :errors="errors"
        :value="dropdownSort"
        label="field"
        form-size="sm"
        @input="onDropdownSort"
      >
        <template #selected-option="option">
          <i
            class="inline-icon far"
            :class="[
              option.order === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down',
            ]"
          />&nbsp;
          {{ upperFirst(option.field) }}
        </template>
        <template #option="option">
          <i
            class="inline-icon far"
            :class="getSortIconClass(option.field)"
          />&nbsp;
          {{ upperFirst(option.field) }}
        </template>
      </v-select>
    </div>
    <!-- endregion GridView -->

    <!-- region ListView -->
    <template v-else>
      <div
        id="genmodifieddate"
        role="columnheader"
        class="d-none d-sm-flex align-items-center"
        @click="onSort('date', $event)"
      >
        {{ $t('Modified') }}
        <button
          tabindex="0"
          role="button"
          onKeyDown="onSort('date', $event)"
          class="btn btn-link p-0 mb-1"
        >
          <Icon
            :aria-label="$t('Press enter to sort by modified date')"
            name="chevron-up"
            class="sortable"
            :class="{
              desc: sort && sort.type === -1,
              hidden: sort && sort.field !== 'date',
            }"
          />
        </button>
      </div>
      <div
        id="gensize"
        class="d-none d-md-block"
        role="columnheader"
        @click="onSort('size', $event)"
      >
        {{ $t('Size') }}
        <button
          tabindex="0"
          :aria-label="$t('Press enter to sort by size')"
          role="button"
          onKeyDown="onSort('size', $event)"
          class="btn btn-link p-0 mb-2"
        >
          <Icon
            name="chevron-up"
            class="sortable"
            :class="{
              desc: sort && sort.type === -1,
              hidden: sort && sort.field !== 'size',
            }"
          />
        </button>
      </div>
    </template>
    <!-- endregion ListView -->
    <!-- endregion -->
    <div
      v-if="!msteams"
      v-shortkey="['v']"
      class="explorer-layout"
      role="cell"
      @shortkey="() => toggleLayoutDropdown()"
    >
      <DropDownList
        ref="layoutDropdown"
        :trigger-btn="getLayout.name"
        trigger-icon-family="fas"
        trigger-btn-class="more-options"
        label-aria="Press to toggle list/grid view"
        :items="layoutButtons"
        @triggerDDAction="onToggleGrid"
      />
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import FileListHeaderMixin from '@/components/FileList/FileListHeaders/FileListHeaderMixin';
import { mapGetters, mapState } from 'vuex';
import DropDownList from 'common/components/DropDownList';
import layoutButtons from 'constants/layoutButtons';
import _ from 'lodash';

export default {
  name: 'FileListHeader',
  components: {
    Icon,
    DropDownList,
  },
  mixins: [FileListHeaderMixin],
  props: {
    currentPath: {
      type: String,
      default: '',
    },
    sort: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => {
    return {
      sortingOptions: [
        {
          field: 'name',
          order: 'asc',
        },
        {
          field: 'size',
          order: 'asc',
        },
        {
          field: 'date',
          order: 'asc',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('files', ['getLayout', 'getIsGrid']),
    ...mapState('auth', ['msteams']),
    layoutButtons() {
      return layoutButtons;
    },
    dropdownSort() {
      return {
        field: this.sort.field,
        order: this.sort.type === -1 ? 'desc' : 'asc',
      };
    },
    loading() {
      return this.$store.state.loading['files/list'];
    },
    filterInputPrepends() {
      return [
        {
          icon: this.loading ? 'spinner' : 'filter',
          ...(this.loading && { animation: 'spin' }),
        },
      ];
    },
  },
  mounted() {
    this.sortingOptions = _.map(this.sortingOptions, (o) => {
      if (o.field === this.sort.field) {
        o.order = this.sort.order === 1 ? 'asc' : 'desc';
      }
      return o;
    });
  },
  methods: {
    onDropdownSort(option) {
      const order = option.order === 'asc' ? 1 : -1;
      this.$emit('onSort', option.field, order, null);
      this.sortingOptions = _.map(this.sortingOptions, (o) => {
        if (o.field === option.field) {
          o.order = option.order === 'asc' ? 'desc' : 'asc';
        }
        return o;
      });
    },
    getSortIconClass(field) {
      if (this.sort.field === field) {
        return this.sort.type === -1 ? 'fa-arrow-up' : 'fa-arrow-down';
      } else {
        return 'fa-arrow-up';
      }
    },
    onSort(key, e) {
      this.$emit('onSort', key, null, e);
    },
    onToggleGrid(type) {
      this.$store.dispatch('files/setLayoutType', type);
    },
    toggleLayoutDropdown() {
      this.$refs.layoutDropdown.$refs.trigger.click();
    },
    upperFirst: _.upperFirst,
  },
};
</script>
