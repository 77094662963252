<template>
  <div class="dropdown-item--colors-mock">
    <div
      :style="!isTablet && !isMobile ? containerTransform : ''"
      class="dropdown-item--colors-container"
    >
      <div
        v-for="color in colorTags"
        :key="color.value"
        v-a11ybutton="() => onItemContextClick(color)"
        class="dropdown-item--colors-box"
        :style="`background: ${color.value}`"
        tabindex="0"
        @click="onItemContextClick(color)"
        @mouseover="iconName = color.value === currentColor ? 'times' : 'check'"
        @mouseleave="iconName = 'check'"
      >
        <Icon
          v-if="color.value === currentColor"
          family="fas"
          :name="iconName"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    child: {
      type: Object,
      default: {},
    },
    currentColor: {
      type: String,
      default: '',
    },
    yPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      iconName: 'check',
      hoverIcon: false,
    };
  },
  computed: {
    colorTags() {
      return this.$store.state.core.colortags;
    },
    containerTransform() {
      // if not a dir, it is a file that can initiate a workflow/send for approval
      // and will include more options
      const ITEMS_AFTER_COLOR_TAG = this.child.item.type != 'dir' ? 2 : 0;
      const position = this.yPosition + 34 + ITEMS_AFTER_COLOR_TAG * 38;

      return {
        transform: `translateY(-${position}px)`,
      };
    },
  },
  methods: {
    onItemContextClick(color) {
      const action =
        color.value === this.currentColor ? 'removeMetaColor' : 'metaColor';
      this.$emit('onItemContextClick', action, this.child, color);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item--colors {
  &-mock {
    position: absolute;
    right: 0;
  }
  &-container {
    margin-left: 5px;
    display: flex;
    padding: 8px;
    background: var(--bg-light);
    position: fixed;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.26);
    border: none !important;
    border-radius: 4px;
    background-color: var(--bg-light) !important;
    &:before {
      content: ' ';
      position: absolute;
      top: 50%;
      right: 100%; /* To the left of the tooltip */
      margin-top: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent var(--bg-light) transparent transparent;
      box-shadow: transparent 0 0 4px var(--shadow-color) transparent
        transparent;
    }
  }
  &-box {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary--foreground);
    height: 25px;
    width: 25px;
    border-radius: 13px;
    border: 2px solid transparent;
    margin-left: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
