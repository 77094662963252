var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"alert-dialog"},[_c('div',{staticClass:"alert-dialog-content"},[_vm._t("default"),(_vm.buttons)?_c('div',{staticClass:"alert-dialog-buttons"},_vm._l((_vm.buttons),function(button,index){return _c('button',{directives:[{name:"close-popover",rawName:"v-close-popover"}],key:index,staticClass:"btn wide",class:[
          {
            'btn--block': _vm.buttons.length > 2,
            [`btn-outline-` + _vm.buttonType]: button.outline,
            [`btn-` + _vm.buttonType]: button.outline != true,
            [`btn-focus-${index}`]: true,
          },
          button.customClass,
        ],attrs:{"disabled":button.disabled},on:{"click":function($event){return _vm.callback($event, button.callback ? button.callback : null)}}},[_vm._v(" "+_vm._s(button.label)+" ")])}),0):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }