<template>
  <nav
    v-shortkey="{ next: ['arrowright'], prev: ['arrowleft'], close: ['esc'] }"
    class="navbar navbar-expand-lg file-preview-header"
    @shortkey="onShortcut"
  >
    <!-- region Navigation Title -->
    <a v-if="file && file.name" tabindex="0" class="navbar-title">
      <FileIcon :name="file.name" :item="file" :format="fileFormat" />
      <div class="navbar-title-file">{{ file.name }}</div>
    </a>
    <!-- endregion -->

    <portal-target name="nested-file-list-header--img" />
    <portal-target name="nested-file-list-header--pdf" />

    <!-- region Navigation Buttons -->
    <NavbarIcon
      v-if="!isSingleFileView"
      icon="chevron-left"
      :tooltip="$t('zip_preview.nested_preview.header.previous_file')"
      :disabled="!hasPrevious"
      @click="prev"
    />
    <NavbarIcon
      v-if="!isSingleFileView"
      icon="chevron-right"
      :tooltip="$t('zip_preview.nested_preview.header.next_file')"
      :disabled="!hasNext"
      @click="next"
    />

    <NavbarIcon
      icon="download"
      :tooltip="$t('zip_preview.nested_preview.header.download')"
      :disabled="!canDownload"
      @click="download"
    />
    <NavbarIcon
      icon="times"
      :tooltip="$t('zip_preview.nested_preview.header.close')"
      @click="close"
    />
  </nav>
</template>

<script>
import { getFileType } from 'common/utils/files.js';
import FileIcon from 'common/components/FileIcon';
import NavbarIcon from './NavbarIcon';
import { isMobileDevice } from 'common/utils/responsive.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    FileIcon,
    NavbarIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    isSingleFileView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('zipViewer', ['canDownload']),
    isLoading() {
      return false;
    },
    fileFormat() {
      return getFileType(this.file.ext || this.file.name);
    },
    isPdfToolsView() {
      return (
        this.file && (this.fileFormat === 'pdf' || this.fileFormat === 'ai')
      );
    },
    canRotate() {
      return this.fileFormat === 'img';
    },
    isMobileDevice,
  },
  methods: {
    onShortcut(e) {
      switch (e.srcKey) {
        case 'next':
          this.next();
          break;
        case 'prev':
          this.prev();
          break;
        case 'close':
          this.close();
          break;
        default:
      }
    },
    close() {
      this.$emit('close');
    },
    download() {
      this.$emit('download');
    },
    onRotate(direction) {
      this.$emit('onRotate', direction);
    },
    prev() {
      this.$emit('prev');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>
