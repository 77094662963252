<template>
  <div class="col-md-12 p-0 m-0">
    <Header :path="path" />
    <Comments key="sidebarKey"> </Comments>
  </div>
</template>

<script>
import Comments from '../Comments/Comments';
import Icon from 'common/components/Icon';
import Header from './Header'

export default {
  components: {
    Comments,
    Icon,
    Header
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  created() {
    this.$store.dispatch('files/getCommentsForComponent');
  },
  computed: {
    sidebarKey() {
      return this.$store.state.files.sidebarKey;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.permissions-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-medium);
}
.light-blue {
  color: rgb(0,122,204);
  padding-left:5px;
}
</style>
