<template>
  <div class="folder-title text-truncate">
    <h1>
      {{ itemName }}
      <Icon v-if="isEncrypted" name="fas fa-lock" family="fas" />
    </h1>
    <div class="small-text current-folder-summary d-none d-md-block">
      {{ $tc('file_list.header.items_count', itemsCount) }}
    </div>
  </div>
</template>

<script>
import Icon from '../../Icon';
import ZipViewerMixin from '../zipViewerMixin';
export default {
  name: 'ZipViewerHeaderTitle',
  components: {
    Icon,
  },
  mixins: [ZipViewerMixin],
  props: {
    itemName: {
      type: String,
      required: true,
    },
    itemsCount: {
      type: Number,
      default: 0,
    },
    isEncrypted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
