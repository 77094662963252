<template>
  <div class="container col-md-11 pl-4">
    <span>
      {{
        $t(
          'Check effective permissions for any user when this path is shared with them. Note: Share permissions are not considered here. If share permissions are more restrictive, those will be applied.'
        )
      }}
    </span>
    <div class="input-group pt-2">
      <div class="input-group-prepend"></div>
      <InputBox
        :id="'emailid'"
        v-model="emailid"
        :placeholder="$t('Enter user email id')"
        :focus="true"
        :errors="errors"
        class="px-0"
        :prepends="[{ icon: 'at', iconBold: true }]"
      />
    </div>
    <div>
      <button class="btn btn-primary" @click="getEffectiveAcl">
        {{ $t('Check user access') }}
      </button>
    </div>
    <div class="result-effective-acl pt-4 mt-4 pb-4" v-if="perms">
      {{ $t('Permissions available for user: ') }}
      <ul class="list-group">
        <li class="list-group-item">
          <Icon v-if="perms.read" family="fal" name="check" />
          <Icon v-else family="fal" name="times" />
          {{ $t('Read Permission') }}
        </li>
        <li class="list-group-item">
          <Icon v-if="perms.write" family="fal" name="check" />
          <Icon v-else family="fal" name="times" />
          {{ $t('Write Permission') }}
        </li>
        <li class="list-group-item">
          <Icon v-if="perms.delete" family="fal" name="check" />
          <Icon v-else family="fal" name="times" />
          {{ $t('Delete Permission') }}
        </li>
        <li class="list-group-item">
          <Icon v-if="perms.share" family="fal" name="check" />
          <Icon v-else family="fal" name="times" />
          {{ $t('Share Permission') }}
        </li>
        <li class="list-group-item">
          <Icon v-if="perms.manage" family="fal" name="check" />
          <Icon v-else family="fal" name="times" />
          {{ $t('Manage Permission') }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import InputBox from 'common/components/InputBox';
import Icon from 'common/components/Icon.vue';
export default {
  components: {
    InputBox,
    Icon,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      emailid: '',
      permMessage: '',
      perms: null,
      errors: {},
    };
  },
  methods: {
    async getEffectiveAcl() {
      const payload = {
        path: this.path,
        emailid: this.emailid,
      };

      const response = await this.$store.dispatch(
        'files/getEffectiveAclEntryForPath',
        payload
      );

      if (response.ok) {
        this.errors = {};

        if (!response.data.perm) {
          this.perms = null;
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              this.$t(response.data.meta.message) +
              '</p>',
            type: 'warning',
          });
          return;
        }

        var _perms = {};
        for (const perm in response.data.perm) {
          _perms[perm] = response.data.perm[perm] === 1;
        }
        this.perms = _perms;
      } else {
        this.errors = response.error;
      }
    },
  },
};
</script>
<style scoped>
.fa-check {
  color: green;
}
.fa-times {
  color: red;
}
.list-group-item{
  background: transparent;
  border-bottom: 1px solid var(--border-color);
}
</style>
