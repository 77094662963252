<template>
  <DialogModal
    ref="addGroup"
    :stick-top="false"
    :title="$t('Add Group')"
    :visible="isShown"
    :size="'small'"
    :class-name="'group-dialog'"
    :buttons="buttonArray"
    @close="hideModal"
  >
    <FilterInput
      icon="search"
      class="mb-3"
      :disabled="isLoading"
      :placeholder="$t('Filter Groups by Name')"
      @onFilter="onFilter"
    />

    <div class="form-control form-control--select">
      <Loader v-if="isLoading" :loading="isLoading" full />

      <select
        id="groupsList"
        v-model="groupName"
        :size="isMobile || isTablet ? 1 : 6"
      >
        <option
          v-for="group in groups"
          :key="group.id"
          :value="group.groupname"
          :class="{ active: selectedGroup == group.groupid }"
        >
          {{ group.groupname }}
        </option>
      </select>
    </div>
    <div v-if="selectedGroup && selectedGroup.everyonegroup" class="mt-1">
      <Icon class="fa-info-box mr-1 text-fc-blue" name="exclamation-circle" />
      <span class="text-fc-blue font-weight-bold">EVERYONE</span>
      <span class="text-fc-blue"> group includes all users.</span>
    </div>

    <Paginator
      v-if="showPaginator"
      :total-items="totalGroups"
      :items-per-page="itemsPerPage"
      :current-page="currentPage"
      @onPageChange="onPageChage"
    />
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import FilterInput from 'common/components/FilterInput';
import Paginator from 'common/components/Paginator';
import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    DialogModal,
    FilterInput,
    Paginator,
    Loader,
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    showModal: {
      default: false,
      type: Boolean,
    },
    context: {
      type: Object,
      default: () => {
        return {
          context: 'share',
          shareid: '',
        };
      },
    },
  },
  data() {
    return {
      isShown: this.showModal,
      groups: [],
      selectedGroup: {},
      totalGroups: undefined,
      groupName: '',
      filterTerm: '',
      itemsPerPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    file() {
      return this.$store.state.share.shareFile;
    },
    buttonArray() {
      let btnArray = [
        {
          label: this.$t('Add'),
          callback: () => this.addGroupstoShare(),
        },
        {
          label: this.$t('Cancel'),
          outline: true,
          callback: () => this.hideModal(),
        },
      ];
      return btnArray;
    },
    showPaginator() {
      return this.totalGroups && this.totalGroups > this.itemsPerPage;
    },
    isLoading() {
      return this.$store.state.loading['share/searchGroups'];
    },
  },
  watch: {
    groupName(newstate) {
      this.selectedGroup = this.groups.find((group) => {
        return group.groupname === newstate;
      });
    },
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    hideModal() {
      this.$refs.addGroup.$el.style.visibility = 'hidden';
      this.isShown = false;
      this.$emit('hideInviteGroup');
    },
    async addGroupstoShare() {
      if (this.context.context === 'share') {
        let response = await this.$store.dispatch('share/addGroupToShare', {
          groupid: this.selectedGroup.groupid,
          shareid: this.context.shareid,
        });
        if (!response.ok) {
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              response.error +
              '</p>',
            type: 'error',
          });
        }
      } else if (this.context.context === 'security') {
        let response = await this.$store.dispatch('files/addAclEntryForPath', {
          path: this.context.path,
          type: 'group',
          value: this.selectedGroup.groupid,
          perm: 'RWDSM',
          flag: 'allow',
        });

        if (!response.ok) {
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              response.error +
              '</p>',
            type: 'error',
          });
        }
      }
      this.hideModal();
    },
    async getGroups() {
      const payload = {
        filter: this.filterTerm,
        start: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.currentPage * this.itemsPerPage,
      };

      let response = await this.$store.dispatch('share/searchGroups', payload);
      let groups = response.data.group;
      if (groups && groups.length == undefined) {
        groups = [groups];
      }
      this.groups = groups;
      this.groupName = this.groups[0].groupname;
      this.selectedGroup = this.groups[0];
      this.totalGroups = response.data.meta.total;
    },
    onPageChage(newPage) {
      this.currentPage = newPage;
      this.getGroups();
    },
    onFilter(filterTerm) {
      this.filterTerm = filterTerm;
      this.currentPage = 1;
      this.getGroups();
    },
  },
};
</script>
<style lang="scss" scoped>
.text-black {
  color: var(--text-dark);
}
@media screen and (min-width: 1200px) {
  #groupsList {
    height: 230px;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-light);
    padding: 0;
    overflow: overlay;

    &::-webkit-scrollbar {
      width: 5px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #ccc;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  select option {
    height: 32px;
    padding-top: 8px;
    padding-left: 18px;
    font-size: 13px;
    font-weight: 500;
    color: var(--text-dark);
  }
}

.text-fc-blue {
  color: var(--text-dark-blue);
}

*:focus {
  outline: none !important;
  box-shadow: none;
}

::v-deep .dialog-modal-body {
  padding: 32px 28px;
}
::v-deep .btn {
  min-width: 94px;
  font-size: 13px;
}
</style>
