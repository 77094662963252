<template>
  <div class="subdropdown">
    <div
      :style="!isTablet && !isMobile ? containerTransform : ''"
      class="subdropdown-container"
    >
      <div ref="dropdown" class="dropdown-wrapper">
        <div
          v-for="item in fileInitiatedWorkflows"
          :key="item.id"
          class="dropdown-item"
          tabindex="0"
          @click="startWorkflow(item)"
        >
          <FileIcon
            ref="fileIcon"
            class="wf-initiation-icon"
            format="workflow"
            :icon-src="getIconPath(item)"
            :size="20"
          />
          <span class="popover-text"
            >{{
              $t(typeof item.name === 'string' ? item.name : item.name(target))
            }}
            <span
              v-if="item.shortKey"
              class="shortkey float-right text-capitalize"
            >
              {{ item.shortKey }}</span
            >
          </span>
        </div>
        <div v-if="loading" class="wf-menu-loader">
          <Icon family="fal" name="spinner" animation="spin" />
          {{ $t('Loading Workflows') }}
        </div>
        <div v-else-if="!fileInitiatedWorkflows.length" class="no-items">
          <span class="m-auto">{{ $t(message) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from 'common/utils/constants';
import Icon from 'common/components/Icon';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import serverURL from 'common/filecloud/properties/serverURL';
import FileIcon from 'common/components/FileIcon';

export default {
  components: {
    Icon,
    FileIcon,
  },
  mixins: [responsivenessMixin],
  props: {
    child: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentColor: {
      type: String,
      default: '',
    },
    yPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      message: 'No workflows available',
      fileInitiatedWorkflows: [],
    };
  },
  computed: {
    colorTags() {
      return this.$store.state.core.colortags;
    },
    containerTransform() {
      const EMPTY_ELEMENT_HEIGHT = 34;
      const WRAPPER_PADDING = 16;
      let transform = EMPTY_ELEMENT_HEIGHT + WRAPPER_PADDING + this.yPosition;
      if (this.fileInitiatedWorkflows.length) {
        const ELEMENT_HEIGHT = 39.18;
        transform += (this.fileInitiatedWorkflows.length - 1) * ELEMENT_HEIGHT;
      }
      return {
        transform: `translateY(-${transform}px)`,
      };
    },
    iconPath: function () {
      const { icon } = this.workflow;
      return serverURL.domainURL + icon;
    },
  },
  mounted() {
    this.getFileInitiatedWorkflows();
  },
  methods: {
    getIconPath(workflow) {
      const { icon } = workflow;
      return serverURL.domainURL + icon;
    },
    async getFileInitiatedWorkflows() {
      this.loading = true;
      const response = await this.$store.dispatch(
        'workflows/getWorkflowsResponse',
        { triggertype: 'USER_INITIATED_FILE_TRIGGER', limit: 50 },
        HTTP.USE_JSON
      );

      if (response.status === 200) {
        this.fileInitiatedWorkflows =
          (response.data && response.data.workflow) || [];
      } else {
        this.message = 'Error getting workflows';
      }

      this.loading = false;
    },
    async startWorkflow(item) {
      const response = await this.$store.dispatch('workflows/startWorkflow', {
        id: item._id,
        trigger_data: { FULL_PATH_PARAMETER: this.child.item.path },
      });

      if (response.status === 200) {
        this.$toast.open({
          message: `<b>${this.$t('Success')}</b><p role="alert">${this.$t(
            'Workflow started successfully!'
          )}</p>`,
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Failure')}</b><p role="alert">${this.$t(
            'Error while starting workflow.'
          )}</p>`,
          type: 'error',
        });
      }
    },
  },
};
</script>
