<template>
  <div :class="{ [`is-invalid`]: errors[id] }">
    <label v-if="label" :for="id">{{ label }}{{ required ? '*' : '' }}</label>
    <vue-tel-input
      ref="phoneInput"
      :value="value"
      :input-options="inputOptions"
      :auto-format="autoFormat"
      :valid-characters-only="validCharactersOnly"
      default-country="US"
      @input="onInput"
      @country-changed="onCountryChanged"
      @open="isCountrySelectionOpen = true"
      @close="isCountrySelectionOpen = false"
    >
      <template v-if="activeCountry" slot="arrow-icon">
        <span v-if="showCountryCode && activeCountry" class="pr-1"
          >+{{ activeCountry.dialCode }}</span
        >
        <Icon v-if="!isCountrySelectionOpen" family="fas" name="caret-down" />
        <Icon v-else family="fas" name="caret-up" />
      </template>
    </vue-tel-input>
    <small
      v-if="errors[id]"
      class="form-text text-danger"
      v-html="errors[id].message"
    />
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import { v4 as uuid } from 'uuid';
export default {
  components: {
    Icon,
  },
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    value: {
      type: String,
      default: '',
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: uuid(),
    },
    autoFormat: {
      type: Boolean,
      default: false,
    },
    validCharactersOnly: {
      type: Boolean,
      default: false,
    },
    showCountryCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCountrySelectionOpen: false,
      phone: '',
      activeCountry: {},
    };
  },
  computed: {
    inputOptions() {
      return {
        placeholder: this.placeholder,
        id: this.id,
      };
    },
  },
  methods: {
    onInput(e) {
      this.phone = e;
      this.emit();
    },
    onCountryChanged(country) {
      this.activeCountry = country;
      this.emit();
    },
    emit() {
      this.$emit('input', this.phone);
    },
  },
};
</script>
<style>
.is-invalid .vue-tel-input {
  border-color: #dc3545 !important;
}
</style>
