<template>
  <div>
    <div
      v-if="fileType === 'video'"
      v-shortkey="{ toggle: ['space'], mute: ['m'] }"
      class="file-preview-item file-preview-item--video"
      :class="{ ['file-preview-item--loaded']: !isLoading }"
      @shortkey="onShortcut"
    >
      <Loader v-if="isLoading" />
      <video
        v-else
        :oncontextmenu="file.candownload ? '' : 'return false'"
        ref="video"
        preload="metadata"
        controls
        :src="blob"
      ></video>
    </div>

    <div
      class="file-preview-item file-preview-item--default mt-5"
      :class="{ 'pb-0': hideDownloadButton }"
    >
      <div class="file-preview-item-info">
        <Icon
          v-if="file.type === 'dir' || file.type === 'folder'"
          name="folder"
          family="fas"
          class="file-icon"
        />

        <FileIcon
          v-else-if="fileType !== 'video'"
          :format="file.format"
          :item="file"
        />
        <h4>{{ file.name }}</h4>
        <p v-if="file.size && file.type !== 'dir' && file.type !== 'folder'">
          {{ file.size }}
        </p>
        <div v-tooltip="disableDownloadText">
          <a
            v-if="fileType === 'video' && file.type !== 'folder'"
            class="btn btn-primary mr-1"
            :class="{
              'mt-4': !file.size,
              disabled: disableDownload || !file.candownload,
            }"
            @click="toggle"
            ><Icon family="fas" name="play" /> Play</a
          >
          <a
            v-if="
              file.type !== 'dir' &&
              file.type !== 'folder' &&
              !hideDownloadButton
            "
            class="btn btn-primary"
            :class="{
              'mt-4': !file.size,
              disabled: disableDownload || !file.candownload,
            }"
            @click="$emit('download')"
            ><Icon family="fas" name="download" /> Download File</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileIcon from '../FileIcon';
import Icon from '../Icon';
import { getFileType } from 'common/utils/files';
import Loader from 'common/assets/loader.svg';

export default {
  components: {
    FileIcon,
    Icon,
    Loader,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    hideDownloadButton: {
      type: Boolean,
      default: false,
    },
    disableDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      blob: null,
    };
  },
  computed: {
    fileType() {
      return getFileType(this.file.ext);
    },
    disableDownloadText() {
      return this.disableDownload || !this.file.candownload
        ? this.disableDownloadQuoute
        : '';
    },
    disableDownloadQuoute() {
      return this.file.candownload
        ? this.$t(
            'Sharing of a single file during trial is disabled due to potential misuse for phishing. It will be fully available after purchase.'
          )
        : this.$t('This share is view only');
    },
  },
  beforeDestroy() {
    this.$refs.video?.removeEventListener(
      'loadeddata',
      this.onLoadData.bind(this)
    );
  },
  mounted() {
    if (this.fileType === 'video') {
      this.loadBlob();
      this.onLoadData();
    }
  },
  methods: {
    async loadBlob() {
      this.isLoading = true;
      this.blob = await this.$store.dispatch('files/url', this.file);
    },
    onLoadData() {
      this.isLoading = false;
    },
    // play / pause content
    toggle() {
      const media = this.$refs.video;
      if (media.paused) {
        media.play();
      } else {
        media.pause();
      }
    },
    // mute / unmute content
    mute() {
      const media = this.$refs.video;
      media.muted = !media.muted;
    },
    onShortcut(e) {
      switch (e.srcKey) {
        case 'toggle':
          this.toggle();
          break;
        case 'mute':
          this.mute();
          break;
        default:
      }
    },
  },
};
</script>
