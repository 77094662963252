var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"previewWrapper",staticClass:"file-preview",class:{
    ['file-preview--active']: _vm.isActive,
    ['file-preview--no-print']: _vm.currentFile && !_vm.currentFile.candownload,
  }},[(_vm.isActive)?_c('nav',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ next: ['arrowright'], prev: ['arrowleft'], close: ['esc'] }),expression:"{ next: ['arrowright'], prev: ['arrowleft'], close: ['esc'] }"}],staticClass:"navbar navbar-expand-lg file-preview-header",on:{"shortkey":_vm.onShortcut}},[(_vm.currentFile && _vm.currentFile.name)?_c('a',{staticClass:"navbar-title",attrs:{"tabindex":"0"}},[_c('FileIcon',{attrs:{"name":_vm.currentFile.name,"item":_vm.currentFile,"format":_vm.fileFormat}}),_c('div',{staticClass:"navbar-title-file"},[_vm._v(_vm._s(_vm.currentFile.name))])],1):_vm._e(),(_vm.canEditFile)?_c('div',[(_vm.editorClientLength > 1)?_c('VPopover',[_c('button',{staticClass:"btn btn-sm btn-primary mr-3",attrs:{"disabled":!_vm.currentFile.candownload}},[_c('Icon',{attrs:{"name":"edit","family":"fas"}}),_vm._v(" Open With "),_c('Icon',{staticClass:"ml-2",attrs:{"name":"chevron-down","family":"fas"}})],1),_c('Alert',{staticClass:"p-0 webedit-popover",attrs:{"slot":"popover","button-type":"primary"},slot:"popover"},[_c('div',{staticClass:"list-group list-group-flush cursor-pointer"},[(
                _vm.isWebEditEnabled &&
                _vm.canEditFileType(
                  _vm.fileExtension,
                  'desktop',
                  _vm.allowQuickEditAll
                ) &&
                _vm.currentFile.showquickedit &&
                !(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.fileExtension))
              )?_c('span',{staticClass:"list-group-item list-group-item-action text-left",on:{"click":() => _vm.runQuickEdit(_vm.currentFile.path)}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"fc-desktop-icon pr-1"}),_c('span',[_vm._v(_vm._s(_vm.desktopEditorClientName))])])]):_vm._e(),(
                _vm.isWOPIEditEnabled && 
                _vm.canEditFileType(_vm.fileExtension, 'web') && 
                !(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.fileExtension))
              )?_c('span',{staticClass:"list-group-item list-group-item-action text-left",on:{"click":_vm.onEditFile}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('Icon',{staticClass:"pr-1",attrs:{"name":"edit","family":"fal"}}),_c('span',[_vm._v("Edit Online")])],1)]):_vm._e(),(
                _vm.isWOPIEditEnabled &&
                _vm.canEditFileType(_vm.currentFile.ext, 'google') &&
                _vm.isGoogleDocsEnabled
              )?_c('span',{staticClass:"list-group-item list-group-item-action text-left",on:{"click":_vm.openInGoogleDocs}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{class:_vm.googleAppsClient.iconClass}),_c('span',[_vm._v(_vm._s(_vm.googleAppsClient.label))])])]):_vm._e(),(
                _vm.isWOPIEditEnabled &&
                _vm.WOPIClient &&
                _vm.canEditFileType(_vm.fileExtension, _vm.WOPIClient.key)
              )?_c('span',{staticClass:"list-group-item list-group-item-action text-left",on:{"click":_vm.onEdit}},[_c('span',{staticClass:"d-flex align-items-center"},[_c('div',{class:_vm.WOPIClient.iconClass}),_vm._v(" "+_vm._s(_vm.WOPIClient.label)+" ")])]):_vm._e()])])],1):(
          _vm.isWebEditEnabled &&
          _vm.canEditFileType(_vm.fileExtension, 'desktop', _vm.allowQuickEditAll) &&
          _vm.currentFile.showquickedit &&
          !(_vm.customization.ALLOWQUICKEDITALL && _vm.isExtensionBlackListed(_vm.fileExtension))
        )?_c('button',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(() => _vm.runQuickEdit(_vm.currentFile.path)),expression:"() => runQuickEdit(currentFile.path)"}],staticClass:"btn btn-sm btn-primary d-flex align-items-center mr-3",attrs:{"tabindex":"0"},on:{"click":() => _vm.runQuickEdit(_vm.currentFile.path)}},[_c('div',{staticClass:"fc-desktop-icon pr-2"}),_vm._v(" Open in "+_vm._s(_vm.desktopEditorClientName)+" ")]):(
          _vm.canEditFileType(_vm.fileExtension, 'web') && _vm.currentFile.candownload
        )?_c('button',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.onEditFile),expression:"onEditFile"}],staticClass:"btn btn-sm btn-primary d-flex align-items-center",attrs:{"tabindex":"0"},on:{"click":_vm.onEditFile}},[_c('Icon',{staticClass:"pr-2",attrs:{"name":"edit","family":"fas"}}),_vm._v(" Edit Online ")],1):(
          _vm.isWOPIEditEnabled &&
          _vm.WOPIClient &&
          _vm.canEditFileType(_vm.fileExtension, _vm.WOPIClient.key)
        )?_c('button',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.onEdit),expression:"onEdit"}],staticClass:"btn btn-sm btn-primary d-flex align-items-center",attrs:{"tabindex":"0"},on:{"click":_vm.onEdit}},[_c('div',{class:_vm.WOPIClient.iconClass}),_vm._v(" "+_vm._s(_vm.WOPIClient.fullLabel)+" ")]):(
          _vm.isWOPIEditEnabled &&
          _vm.isGoogleDocsEnabled &&
          _vm.canEditFileType(_vm.currentFile.ext, 'google')
        )?_c('button',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.openInGoogleDocs),expression:"openInGoogleDocs"}],staticClass:"btn btn-sm btn-primary d-flex align-items-center",attrs:{"tabindex":"0"},on:{"click":_vm.openInGoogleDocs}},[_c('div',{class:_vm.googleAppsClient.iconClass}),_vm._v(" "+_vm._s(_vm.googleAppsClient.fullLabel)+" ")]):_vm._e()],1):_vm._e(),(_vm.isPdfToolsView)?_c('PDFTools',{attrs:{"is-expanded":_vm.isPDFExpanded,"current-zoom":_vm.currentPdfZoom,"can-print":_vm.currentFile.candownload,"ready":_vm.pdfReady},on:{"print":_vm.onPrintFile}}):_vm._e(),(_vm.fileFormat === 'img')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Rotate Right',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Rotate Right',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(
        () => {
          _vm.onRotate('right');
        }
      ),expression:"\n        () => {\n          onRotate('right');\n        }\n      "}],staticClass:"navbar-icon",class:{ disabled: _vm.isLoading },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.onRotate('right')}}},[_c('Icon',{attrs:{"family":"fas","name":"redo-alt"}})],1):_vm._e(),(_vm.fileFormat === 'img')?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Rotate Left',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Rotate Left',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(
        () => {
          _vm.onRotate('left');
        }
      ),expression:"\n        () => {\n          onRotate('left');\n        }\n      "}],staticClass:"navbar-icon",class:{ disabled: _vm.isLoading },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.onRotate('left')}}},[_c('Icon',{attrs:{"family":"fas","name":"undo-alt"}})],1):_vm._e(),(_vm.index !== null)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Previous file',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Previous file',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.prev),expression:"prev"}],staticClass:"navbar-icon",class:{ disabled: _vm.index === 0, 'ml-auto': !_vm.isMobileDevice },attrs:{"tabindex":"0"},on:{"click":_vm.prev}},[_c('Icon',{attrs:{"family":"fas","name":"chevron-left"}})],1):_vm._e(),(_vm.index !== null)?_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Next file',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Next file',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.next),expression:"next"}],staticClass:"navbar-icon",class:{ disabled: _vm.index === _vm.files.length - 1 },attrs:{"tabindex":"0"},on:{"click":_vm.next}},[_c('Icon',{attrs:{"family":"fas","name":"chevron-right"}})],1):_vm._e(),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Download file',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Download file',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.onDownload),expression:"onDownload"}],staticClass:"navbar-icon",class:{
        disabled: _vm.isDownloadHidden,
      },attrs:{"tabindex":"0"},on:{"click":_vm.onDownload}},[_c('Icon',{attrs:{"family":"fas","name":"download"}})],1),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        delay: { show: 1000, hide: 0 },
        offset: -5,
        content: 'Close file preview',
      }),expression:"{\n        delay: { show: 1000, hide: 0 },\n        offset: -5,\n        content: 'Close file preview',\n      }"},{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.close),expression:"close"}],staticClass:"navbar-icon",staticStyle:{"font-size":"150%"},attrs:{"tabindex":"0"},on:{"click":_vm.close}},[_c('Icon',{attrs:{"family":"far","name":"times"}})],1)],1):_vm._e(),(!_vm.isError && _vm.isLoading)?_c('div',{staticClass:"file-preview-portal"},[_c('LoadingView')],1):(_vm.isError)?_c('div',{staticClass:"file-preview-portal d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"file-preview-portal--error",domProps:{"innerHTML":_vm._s(_vm.errorMsg)}})]):(_vm.isActive)?_c('div',{staticClass:"file-preview-portal",class:{ 'file-preview-portal--zip-viewer': _vm.isPublic },style:([_vm.currentView === 'zip' && { height: _vm.auto }])},[(_vm.isActive)?_c(_vm.currentView,{ref:"viewerComponent",tag:"component",attrs:{"file":_vm.currentFile,"edit":_vm.edit,"pdf-expanded":_vm.isPDFExpanded,"zoom":_vm.currentPdfZoom,"is-public-share-view":_vm.isPublic},on:{"download":_vm.onDownload,"edit":_vm.onEdit,"fallback":_vm.onFallback,"pdfReady":_vm.makePDFReady,"close":_vm.close}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }