/**
 * Add a11y class event listener
 */
export function addA11yClassListener() {
  document.body.addEventListener('keydown', function (event) {
    const key = event.code || event.keyCode;
    if (key === 'Tab' || key === 9) {
      document.body.classList.add('using-keyboard');
    }
  });
  document.body.addEventListener('mousedown', function () {
    document.body.classList.remove('using-keyboard');
  });
}

/**
 * Set focus to an element
 * @param {Element} element
 */
export function setFocus(element) {
  if (!element) return;
  setTimeout(() => {
    element.focus();
  }, 200);
}

/**
 * Handle button press using space or enter
 * @param {Function} bindingValue - function to be called
 * @param {Boolean} isNative - indicator if the function should pass de event back
 */
function a11yButtonPress(bindingValue, isNative) {
  // Return curried named function
  return function a11yHandler(event) {
    const key = event.code || event.keyCode;
    if (key === 'Enter' || key === 'Space' || key === 13 || key === 32) {
      // stop propagation only if Enter or Space
      event.stopPropagation();
      event.preventDefault();
      // Call function from bindingValue
      if (isNative) bindingValue(event);
      else bindingValue();
    }
  };
}

/**
 * Return the object to mount a11y button press handler directive
 */
export const a11yButtonPressDirective = {
  bind: function (el, binding, vnode) {
    if (!binding.value instanceof Function) return;
    const isNative = binding.modifiers.native;
    const keyPressHandler = a11yButtonPress(binding.value, isNative);
    // store function ref to be able to remove listener
    el.a11yButtonPressRef = keyPressHandler;
    el.addEventListener('keypress', keyPressHandler);
  },
  unbind: function (el, binding, vnode) {
    if (!el) return;
    // get function reference from element
    const keyPressHandler = el.a11yButtonPressRef;
    // remove event listener
    el.removeEventListener('keypress', keyPressHandler);
    // remove ref after removing the listener
    el.a11yButtonPressRef = null;
  },
};
