<template>
  <label class="fc-radio" :class="{ ['fc-radio--disabled']: disabled }">
    {{ label }}
    <input
      type="radio"
      :value="value"
      :name="name"
      :id="id"
      :checked="checked"
      :disabled="disabled"
      v-model="radioButtonValue"
    />
    <span class="checkmark"></span>
  </label>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    value: {
      required: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    radioValue: {
      type: String || Number,
      required: true,
    },
  },
  computed: {
    radioButtonValue: {
      get: function () {
        return this.radioValue;
      },
      set: function () {
        this.$emit('input', this.radioValue);
        this.$emit('change', this.radioValue);
      },
    },
  },
};
</script>
