<template>
  <span v-if="actionClassifier(activity.actioncode)"
    >{{ $t(actionStory(activity)[0]) }} <strong>{{ $t(activity.misc) }}</strong>
    {{ $t(actionStory(activity)[1]) }}
  </span>
  <span v-else>
    {{ $t(actions[activity.actioncode]) }}
  </span>
</template>

<script>
import actions from '../../constants/shareHistoryActions';

export default {
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions: {},
    };
  },
  created() {
    this.actions = actions;
  },
  methods: {
    actionStory(activity) {
      let spliMisc = activity.misc === 'you' ? ' user{misc}' : '{misc}';
      return actions[activity.actioncode].split(spliMisc);
    },
    actionClassifier(code) {
      return actions[code].includes('{misc}');
    },
  },
};
</script>
