<template>
  <div
    v-if="!isPicker"
    class="col-properties col-info"
    :class="{
      'col-properties--mobile': isMobile || isTablet,
      'd-none': hasCustomList && !isMobile && !isTablet,
      'd-block': hasCustomList && hasSearchResults,
    }"
    @contextmenu.prevent="contextMenu"
    @click="expand"
  >
    <ul v-if="canShowProps(item)" class="properties-row" role="cell">
      <li class="main-icon">
        <Icon name="info-circle" family="fas" />
      </li>
      <li
        v-if="lockedInfo(item)"
        v-tooltip="lockedRead(item)"
        class="main-icon active"
      >
        <Icon name="lock" family="fas" />
      </li>
      <li
        v-if="item.canmanageshare"
        v-tooltip="item.canmanageshare ? $t('Managed Share') : ''"
        class="roll-over"
        :class="{ active: item.canmanageshare }"
      >
        <Icon name="share-alt-square" family="fas" />
      </li>
      <li
        v-else
        v-tooltip="$t(getShareInfo)"
        class="roll-over"
        :class="{ active: typeof item.isshared === 'string' }"
      >
        <Icon name="share-alt" family="fas" />
      </li>
      <li v-tooltip="tagged" class="roll-over" :class="{ active: item.tagged }">
        <Icon name="tag" family="fas" />
      </li>
      <li
        v-tooltip="
          item.hasnotificationsrule ? $t('Notification rule added') : ''
        "
        class="roll-over"
        :class="{ active: item.hasnotificationsrule }"
      >
        <Icon name="bell" family="fas" />
      </li>
      <li
        v-tooltip="comment"
        class="roll-over"
        :class="{ active: item.commented }"
      >
        <Icon name="comment" family="fas" />
      </li>
      <li
        v-tooltip="lockedRead(item)"
        class="roll-over"
        :class="{ active: item.locked }"
      >
        <Icon name="lock" family="fas" />
      </li>
    </ul>

    <div
      v-else-if="isMobile || (isTablet && !isPicker)"
      class="file-details cursor-pointer"
      @click="$emit('openSidebarForFile')"
    >
      <Icon name="info-circle" family="fas" />
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import { mapGetters } from 'vuex';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import lockReadMixin from 'mixins/lockReadMixin';

export default {
  components: {
    Icon,
  },
  mixins: [responsivenessMixin, lockReadMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    disableHoverContext: {
      type: Boolean,
      default: false,
    },
    getShareInfo: {
      type: String,
      default: 'false',
    },
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    comment() {
      return this.item.commented
        ? this.$tc(
            'Item has {count} comment | Item has {count} comments',
            this.item.commented
          )
        : '';
    },
    tagged() {
      if (this.item.tagged)
        return `${this.$t('Item has')} ${this.item.tagged} ${this.$t(
          'metadata sets'
        )}`;
      return '';
    },
  },
  methods: {
    canShowProps(item) {
      return (
        (typeof item.isshared === 'string' ||
          item.tagged ||
          item.hasnotificationsrule ||
          item.commented ||
          item.locked ||
          item.canmanageshare) &&
        !this.isMobile &&
        !this.isTablet &&
        !this.disableHoverContext
      );
    },
    lockedInfo({ zerotrust, locked }) {
      return zerotrust && locked ? locked : false;
    },
    expand(e) {
      this.$emit('expand', e);
    },
    contextMenu(e) {
      this.hasFocus = true;
      this.$emit('context-menu', { e, entry: this });
    },
  },
};
</script>
