<template>
  <div class="file-list-loader">
    <ContentLoader
      :height="33"
      :width="isCustomList ? 400 : 1000"
      :speed="1"
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
    >
      <rect x="56" y="2" rx="4" ry="4" width="30" height="30" />
      <rect x="100" y="12" rx="6" ry="6" width="150" height="12" />
      <rect x="420" y="15" rx="3" ry="3" width="250" height="6" />
    </ContentLoader>

    <ContentLoader
      :height="33"
      :width="isCustomList ? 400 : 1000"
      :speed="1"
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
    >
      <rect x="56" y="2" rx="4" ry="4" width="30" height="30" />
      <rect x="100" y="12" rx="6" ry="6" width="100" height="12" />
      <rect x="420" y="15" rx="3" ry="3" width="300" height="6" />
    </ContentLoader>

    <ContentLoader
      :height="33"
      :width="isCustomList ? 400 : 1000"
      :speed="1"
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
    >
      <rect x="56" y="2" rx="4" ry="4" width="30" height="30" />
      <rect x="100" y="12" rx="6" ry="6" width="90" height="12" />
      <rect x="420" y="15" rx="3" ry="3" width="150" height="6" />
    </ContentLoader>

    <ContentLoader
      v-if="!isMobileDevice"
      :height="33"
      :width="isCustomList ? 400 : 1000"
      :speed="1"
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
    >
      <rect x="56" y="2" rx="4" ry="4" width="30" height="30" />
      <rect x="100" y="12" rx="6" ry="6" width="200" height="12" />
      <rect x="420" y="15" rx="3" ry="3" width="250" height="6" />
    </ContentLoader>
  </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';
import { isMobileDevice } from '../utils/responsive.js';

export default {
  components: {
    ContentLoader,
  },
  props: {
    isCustomList: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileDevice,
  },
};
</script>
