<template>
  <div
    class="share-tab-content share-history"
    :class="{ ['share-history--empty']: shareHistory.length === 0 }"
  >
    <Loader :loading="loading" full />
    <div v-if="shareHistory.length === 0">
      {{ loading ? $t('Loading history') : $t('No Share history') }}
    </div>
    <div v-else>
      <shareHistoryRecord
        v-for="(history, id) in shareHistory"
        :key="id"
        :record="history"
      />
    </div>
  </div>
</template>
<script>
import Loader from 'common/components/Loader';
import shareHistoryRecord from './shareHistoryRecord';
export default {
  components: {
    Loader,
    shareHistoryRecord,
  },
  props: {
    shareid: {
      type: String,
      default: '',
    },
  },
  computed: {
    loading() {
      return this.$store.state.loading['share/getShareActivity'];
    },
    shareHistory() {
      return this.$store.state.share.shareHistory;
    },
  },
  mounted() {
    this.$store.dispatch('share/getShareActivity', { shareid: this.shareid });
  },
};
</script>
