export default [
  // Default icons
  {
    name: 'Upload File',
    action: 'openUpload',
    shortKey: 'u',
    icon: 'upload',
  },
  {
    name: 'Upload Folder',
    action: 'openFolderUpload',
    icon: 'folder-open',
    disabled: ({ isMobile }) => isMobile,
  },
  {
    name: 'New Folder',
    action: 'openNewFolder',
    shortKey: 'alt + n',
    icon: 'folder-plus',
    conditionalSeperator: false,
    id: 'new-file-actions-new-folder',
  },
  {
    name: 'New Zip File',
    action: 'openNewZipFile',
    conditionalSeperator: ({ customization }) =>
      customization.ENABLENEWDOCUMENTS,
    icon: 'file-archive',
    hidden: ({ isNetworkShare, isPublicShareView, meta }) => {
      return (
        !isNetworkShare &&
        (isPublicShareView || !meta || (meta && meta.showzipfolders !== 1))
      );
    },
  },
  {
    name: 'New Word Document',
    action: 'openNewItem',
    params: {
      suffix: 'docx',
      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'file-word',
  },
  {
    name: 'New Presentation',
    action: 'openNewItem',
    params: {
      suffix: 'pptx',
      mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'file-powerpoint',
  },
  {
    name: 'New Spreadsheet',
    action: 'openNewItem',
    params: {
      suffix: 'xlsx',
      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'file-excel',
  },
  {
    name: 'New Text Document',
    action: 'openNewItem',
    params: {
      suffix: 'txt',
      mime: 'text/plain',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'file-alt',
  },
  {
    name: 'New Markdown File',
    action: 'openNewItem',
    params: {
      suffix: 'md',
      mime: 'text/plain',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'markdown',
    iconFamily: 'fab',
  },
  {
    name: 'New Folder Readme',
    action: 'openNewItem',
    params: {
      suffix: 'README.md',
      mime: 'text/plain',
    },
    hidden: ({ customization, isPublicShareView }) =>
      !customization.ENABLENEWDOCUMENTS ||
      isPublicShareView,
    icon: 'readme',
    iconFamily: 'fab',
  },
  {
    name: 'Properties',
    action: 'openPropertiesModal',
    hidden: ({
      systemStatus,
      isPublicShareView,
      fromAddButton,
      isLimitedUser,
    }) =>
      !systemStatus.CLOUDUSERSTATUS ||
      isPublicShareView ||
      fromAddButton ||
      isLimitedUser,
    icon: 'book',
  },
];
