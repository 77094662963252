import Vue from 'vue';
import { handleArrayResponse } from '../../utils/dataUtils';

const getDefaultState = () => {
  return {
    groups: [],
    totalGroups: 0,
    selectedGroupId: null,
    groupAdmins: [],
    groupMembers: [],
    searchedGroupMembers: [],
    selectedGroup: [],
    totalGroupMembers: null,
    allGroupMembers: [],
    groupModal: {
      show: false,
      groupname: '',
    },
    groupAddModal: {
      show: false,
    },
  };
};

export default {
  namespaced: true,
  state: getDefaultState,
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    openGroupModal(state, setVisible) {
      state.groupModal.show = setVisible;
    },
    openGroupAddModal(state, setVisible) {
      state.groupAddModal.show = setVisible;
    },
  },
  actions: {
    async setSelectedGroup(context, payload) {
      await context.commit('set', {
        key: 'selectedGroup',
        value: payload,
      });
    },
    async showGroupModal(context, payload) {
      await context.commit('openGroupModal', true);
      await context.commit('set', {
        key: 'selectedGroupId',
        value: payload.groupid,
      });
      await context.commit('set', {
        key: 'selectedGroupName',
        value: payload.groupname,
      });
    },
    async hideGroupModal(context) {
      await context.commit('openGroupModal', false);
    },
    async showGroupAddModal(context) {
      await context.commit('openGroupAddModal', true);
    },
    async hideGroupAddModal(context) {
      await context.commit('openGroupAddModal', false);
    },

    /**
     * Get groups list
     * ListFilterParams args
     * @param {Object} payload - getgroups api params
     * {
     * ->addOptionalParam('start', Valid::INTEGER)
     * ->addOptionalParam('limit', Valid::INTEGER)
     * ->addOptionalParam('externalin', Valid::VALID_STRING, [Filter::SANITIZE_STRING, Filter::TRIM])
     * ->addOptionalParam('policyidnin', Valid::VALID_STRING, [Filter::SANITIZE_STRING, Filter::TRIM])
     * ->addOptionalParam('username', Valid::VALID_STRING, [Filter::SANITIZE_STRING, Filter::TRIM])
     * ->addOptionalParam('filter', Valid::VALID_STRING, [Filter::SANITIZE_STRING, Filter::TRIM])
     * }
     */
    async getGroups(context, payload) {
      const response = await this.state.core.client.get('core/groups', payload);

      if (response.ok) {
        const agroups = response.data.group;
        const arr = handleArrayResponse(agroups);

        await context.commit('set', {
          key: 'groups',
          value: arr,
        });

        await context.commit('set', {
          key: 'totalGroups',
          value: response.data.meta.total,
        });
      }
    },
    async addGroup(context, payload) {
      const response = await this.state.core.client.post('core/addgroup', {
        groupname: payload.groupName,
      });

      return response?.data;
    },
    async deleteGroup(context, payload) {
      const response = await this.state.core.client.post(
        'core/deletegroup',
        payload
      );

      return response.ok ? response?.data : false;
    },
    async addGroupMember(context, payload) {
      const response = await this.state.core.client.post(
        'core/addgroupmember',
        {
          groupid: payload.groupid,
          username: payload.username,
        }
      );

      return response.ok ? response?.data : false;
    },
    async deleteGroupMember(context, payload) {
      const response = await this.state.core.client.post(
        'core/deletegroupmember',
        {
          groupid: payload.groupid,
          username: payload.username,
        }
      );

      return response.ok ? response?.data : false;
    },
    async getGroupMembers(context, payload) {
      const response = await this.state.core.client.post(
        'core/searchprofiles',
        payload
      );

      if (!response.ok) {
        return false;
      }

      const members = handleArrayResponse(response.data?.profile);

      const mappedMembers = members.map((item) => {
        return {
          displayname: item.displayname,
          username: item.username,
          email: item.email,
        };
      });

      const memberList = mappedMembers;

      if (payload.providefilter === true) {
        await context.commit('set', {
          key: 'allGroupMembers',
          value: memberList,
        });
      } else {
        await context.commit('set', {
          key: 'groupMembers',
          value: memberList,
        });
        await context.commit('set', {
          key: 'totalGroupMembers',
          value: response.data.meta.total,
        });
      }

      return memberList;
    },
    async searchPossibleGroupMembers(context, payload) {
      const response = await this.state.core.client.post(
        'core/searchprofiles',
        payload
      );

      const aProfiles = handleArrayResponse(response.data?.profile);

      if (!response.ok) {
        return false;
      }

      await context.commit('set', {
        key: 'searchedGroupMembers',
        value: aProfiles,
      });

      if (response.meta) {
        await context.commit('set', {
          key: 'totalGroupMembers',
          value: response.meta.total,
        });
      }

      return response.data;
    },
    async filterGroupMembers(context, payload) {
      const response = await this.state.core.client.post(
        'core/searchprofiles',
        {
          filter: payload?.filter ?? '',
          start: payload.start,
          limit: payload.limit,
          context: 'usergroup',
        }
      );

      const profiles = handleArrayResponse(response.data.profile);

      return response.ok ? profiles : false;
    },
  },
};
