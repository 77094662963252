<template>
  <div class="activity-tab w-100">
    <Header v-if="currentFile && !showGlobalActivities" :path="path" />
    <ul class="activity px-3">
      <li
        v-for="(activity, index) of activities"
        :key="index"
        class="activity-item"
        :class="{
          'with-header':
            getActivityHeader(activity, index).length > 0 &&
            !showGlobalActivities,
        }"
      >
        <div v-if="activity">
          <div
            v-if="
              getActivityHeader(activity, index).length > 0 &&
              !showGlobalActivities
            "
            class="activity-item-header"
          >
            <span>{{ getActivityHeader(activity, index) }}</span>
          </div>
          <div class="activity-item-body">
            <div class="activity-item-avatar-wrapper">
              <Avatar
                class="comment-profile-image"
                :user="activity.who"
                :same-user="user.profile === activity.who"
                :label="$t(`{userName}'s Avatar`, { userName: activity.who })"
              />
            </div>

            <div class="activity-area">
              <span
                v-if="isValidUsernameType(activity)"
                class="activity-item-who"
                >{{
                  activity.who === user.peerid
                    ? $t('You')
                    : activity.who.toString()
                }}</span
              >
              <span v-else class="activity-item-who">{{ $t('Unknown') }}</span>
              <span class="activity-item-action">
                <Action :activity="activity" />
                <span v-if="activity.actioncode === 20">
                  <a @click="goToFile(activity)">
                    {{ getPath('source', activity.path, activity.misc) }}
                  </a>
                  {{ $t('To') }}
                  <a @click="goToFile(activity)">
                    {{ getPath('destination', activity.path, activity.misc) }}
                  </a>
                </span>
                <span v-else>
                  <a @click="goToFile(activity)">
                    {{ activityPath(activity.path) }}
                  </a>
                </span>
              </span>
              <span v-if="!showGlobalActivities" class="activity-item-when">
                <DateDisplay :date="activity.whents"
              /></span>
              <span v-else class="activity-item-when">{{
                dateFromNow(activity.whents)
              }}</span>
            </div>
            <v-popover class="activity-item-details">
              <div class="btn" aria-hidden="true" @click="loadMap(index)">
                <Icon name="info-circle" family="fas" class="tooltip-target" />
              </div>
              <template slot="popover" class="p-3">
                <MapView
                  ref="mapComponent"
                  :activity="activity"
                  :action-code="actions"
                />
              </template>
            </v-popover>
          </div>
        </div>
      </li>
      <p
        v-if="!activities || activities.length == 0"
        class="text-center file-title mt-3"
      >
        {{ $t('No activities found') }}
      </p>
    </ul>
  </div>
</template>

<script>
import 'common/assets/jvectormap/jquery-jvectormap-2.0.3.css';
import Icon from 'common/components/Icon';
import MapView from './MapView';
import { loadScript } from 'common/utils/files';
import serverURL from 'common/filecloud/properties/serverURL';
import Header from './Header';
import dayjs from 'dayjs';
import Avatar from 'common/components/Avatar';
import { navigateToEncodedURL } from 'common/utils/URLUtils';
import actions from '../../constants/shareHistoryActions';
import Action from '../Partials/ShareActions';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import DateDisplay from 'common/components/DateDisplay';
import relativeDateMixin from '@/mixins/relativeDateMixin';
dayjs.extend(relativeTime);

export default {
  components: {
    Icon,
    MapView,
    Header,
    Avatar,
    Action,
    DateDisplay,
  },
  mixins: [relativeDateMixin],
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showGlobalActivities: true,
      actions: {},
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    activities() {
      if (this.$route.name !== 'files') {
        return this.getActivityStream();
      } else {
        return this.getGlobalActivityStream();
      }
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.actions = actions;
  },
  mounted() {
    this.$store.dispatch('files/getActivities');

    if (!this.isLimitedUser) {
      this.loadMapScript();
      if (this.$route.name !== 'files') {
        this.showGlobalActivities =
          this.currentFile && this.currentFile.type === 'dir' ? true : false;
        this.getActivityStream();
      } else {
        this.showGlobalActivities = true;
        this.getGlobalActivityStream();
      }
    }
  },
  methods: {
    isValidUsernameType(activity) {
      return (
        typeof activity.who === 'string' ||
        typeof activity.who === 'number' ||
        (typeof activity.who === 'object' && !_.isEmpty(activity.who))
      );
    },
    activityPath(file) {
      if (!this.showGlobalActivities) {
        let str = file.split('/');
        str = str.splice(1, str.length);
        str = str.join('/');
        return str;
      } else {
        return file;
      }
    },
    async loadMapScript() {
      // loading jquery and map scripts after popover renders
      if (!window.jQuery) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/jquery.min.js`
        );
      }

      if (!window.jQuery || !window.jQuery.prototype.vectorMap) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/vectormap/jquery-jvectormap-2.0.5.min.js`
        );
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/vectormap/jquery-jvectormap-world-mill-en.js`
        );
      }
    },
    loadMap(index) {
      let el = document.querySelectorAll('.jvectormap-container');

      if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
          el[i].parentNode.removeChild(el[i]);
        }
      }

      this.$refs.mapComponent[index].drawMap(index);
    },
    async goToFile(activity) {
      let filePath = activity.dirpath ? activity.dirpath : activity.parent;
      let fileName = activity.name
        ? activity.name
        : activity.path.replace(/^.*[\\\/]/, '');

      if (/[.]/.exec(fileName)) {
        const item = await this.$store.dispatch(
          'files/getSingleFileInfo',
          decodeURIComponent(`${filePath}/${fileName}`)
        );

        this.$preview.open(item.data.entry);
      } else {
        const expPath = this.showGlobalActivities
          ? '/expl-tabl./'
          : '/expl-tabl.';

        const activityPath = activity.path.replace(
          'My Files',
          this.user.peerid
        );
        navigateToEncodedURL(this.$router, activityPath, expPath);
      }
    },
    getActivityStream() {
      this.showGlobalActivities = false;
      const activities =
        this.$store.state.files.loadedActivitiessForSelectedItem;
      if (activities && activities.activitystreamrecord instanceof Array) {
        return activities.activitystreamrecord;
      } else {
        return activities && activities.activitystreamrecord
          ? [activities.activitystreamrecord]
          : null;
      }
    },
    getGlobalActivityStream() {
      this.showGlobalActivities = true;
      const activities = this.$store.state.files.loadedGlobalActivities;
      if (activities?.notificationstream instanceof Array) {
        return activities?.notificationstream;
      } else {
        return activities?.activitystreamrecord
          ? [activities.activitystreamrecord]
          : null;
      }
    },
    getProfileImage(username) {
      return serverURL.domainURL + '/core/getprofileimage?username=' + username;
    },
    getActivityHeader(activity, index) {
      if (activity) {
        let secondsAgo = Math.round(Date.now() / 1000) - activity.whents;
        let when = this.$t('Later than a year ago');
        if (secondsAgo <= 365 * 2 * 86400) {
          when = this.$t('Last Year');
        }
        if (secondsAgo <= 365 * 86400) {
          when = this.$t('This Year');
        }
        if (secondsAgo <= 30 * 86400) {
          when = this.$t('This Month');
        }
        if (secondsAgo <= 7 * 86400) {
          when = this.$t('This Week');
        }
        if (secondsAgo <= 2 * 86400) {
          when = this.$t('Yesterday');
        }
        if (secondsAgo < 86400) {
          when = this.$t('Today');
        }
        if (index > 0) {
          if (this.getActivityHeader(this.activities[index - 1]) != when) {
            return when;
          } else {
            return '';
          }
        } else {
          return when;
        }
      } else return '';
    },
    getPath(pathType, path, misc) {
      if (this.$route.name !== 'files') {
        return pathType === 'source' ? misc : path;
      } else {
        return pathType === 'source' ? path : misc;
      }
    },
  },
};
</script>
