export function systemStatus(systemStatusData) {
  const systemProps = {};
  systemProps.ENABLEWEBEDIT = systemStatusData.enablewebedit;
  systemProps.UIALLOWCHANGE = systemStatusData.uiallowchange;
  systemProps.DISABLEMETADATA = systemStatusData.disablemetadata;
  systemProps.DISABLELOCK = systemStatusData.disablelock == 1;
  systemProps.GUESTUSER = systemStatusData.clouduserstatus == 0;
  systemProps.LIMITEDUSER = systemStatusData.clouduserstatus == 3;
  systemProps.FULLUSER = systemStatusData.clouduserstatus == 1;
  systemProps.DISABLEACTIVITY = systemStatusData.disableactivity;
  systemProps.SHAREMODE = systemStatusData.sharemode;
  systemProps.EXACT_EMAIL_SEARCH_IMPLICIT_INVITE =
    systemStatusData.cansearchotherusers == 2;
  systemProps.EXACT_EMAIL_SEARCH_EXPLICIT_INVITE =
    systemStatusData.cansearchotherusers == 3;
  systemProps.ALLOWBACKUP = systemStatusData.allowbackup;
  systemProps.BACKUPPATH = systemStatusData.mediasyncstorepath;
  systemProps.CLEARRECYCLEBIN = systemStatusData.clearrecyclebin == 1;
  systemProps.NETWORKFOLDERNAME = systemStatusData.networkfoldername;
  systemProps.MINIADMINENABLED = systemStatusData.miniadminenabled;
  systemProps.TOURENABLED = systemStatusData.interactivetourenabled;
  systemProps.HIDESHAREDIRECTLINKOPTION =
    systemStatusData.hidesharedirectlinkoption;
  systemProps.AUTOMATION_WORKFLOWS = systemStatusData.automationworkflows == 1;
  systemProps.ENFORCEEMAILLOGIN = systemStatusData.enforceemaillogin == 1;
  systemProps.CREATEACCOUNTONSHAREINVITE = systemStatusData.createaccountonshareinvite == 1;
  systemProps.SHAREPWDATTACHBYDEFAULT =
    systemStatusData.sharepwdattachbydefault == 1;

  if (systemStatusData.samlidplogouturl) {
    systemProps.SAMLIDPLOGOUTURL = systemStatusData.samlidplogouturl;
  }
  if (systemStatusData.appversion) {
    systemProps.APPVERSION = systemStatusData.appversion;
  }
  if (systemStatusData.createaccountonshareinvite) {
    systemProps.ALLOWACCOUNTCREATEONINVITE =
      systemStatusData.createaccountonshareinvite;
  }
  if (systemStatusData.teamfolderaccount) {
    systemProps.TEAMFOLDERUSERNAME = systemStatusData.teamfolderaccount;
  }
  if (systemStatusData.manageshareenabled) {
    systemProps.MANAGESHAREENABLED = systemStatusData.manageshareenabled;
  }
  if (systemStatusData.recaptchakey) {
    systemProps.RECAPTCHA_KEY = systemStatusData.recaptchakey;
  }
  if (systemStatusData.captchaenabled) {
    systemProps.CAPTCHA_ENABLED = systemStatusData.captchaenabled;
  }
  if (systemStatusData.disablegettingstarteduser) {
    systemProps.DISABLEUSERGETTINGSTARTED =
      systemStatusData.disablegettingstarteduser;
  }
  if (systemStatusData.smsforshareinvite) {
    systemProps.SMSFORSHAREINVITE = systemStatusData.smsforshareinvite;
  }
  if (systemStatusData.showdialog) {
    systemProps.SHOWDIALOG = systemStatusData.showdialog;
  }
  if (systemStatusData.switchtoadmin) {
    systemProps.SWITCHTOADMIN = systemStatusData.switchtoadmin;
  }
  if (systemStatusData.sharecount) {
    systemProps.SHARECOUNT = systemStatusData.sharecount;
  }
  if (systemStatusData.retentionenabled) {
    systemProps.RETENTIONENABLED = systemStatusData.retentionenabled;
  }
  if (systemStatusData.clouduserstatus) {
    systemProps.CLOUDUSERSTATUS = systemStatusData.clouduserstatus;
  }
  if (systemStatusData.cancreateusergroup) {
    systemProps.CANCREATEUSERGROUP = systemStatusData.cancreateusergroup == 1;
  }
  if (systemStatusData.canlistusergroup) {
    systemProps.CANLISTUSERGROUP = systemStatusData.canlistusergroup == 1;
  }
  if (systemStatusData.isgroupadmin) {
    systemProps.ISGROUPADMIN = systemStatusData.isgroupadmin == 1;
  }
  if (systemStatusData.candeleteusergroup) {
    systemProps.CANDELETEUSERGROUP = systemStatusData.candeleteusergroup == 1;
  }
  return systemProps;
}
