var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-overlay timed-fade dialog-modal-table dialog-modal-visible stick-top big animate-timed-fade shared-by-me-wrapper locked-files-wrapper dialog-modal-top",attrs:{"stick-top":true,"visible":true,"class-name":"none","title":_vm.$t(`Manage Secure Docs`),"buttons":[
    {
      label: 'OK',
      outline: true,
      callback: () => this.$emit('close'),
    },
  ]},on:{"close":function($event){return _vm.$emit('close')}}},[_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}),_c('div',[_c('div',{staticClass:"table-wrapper-border"},[_c('table',{staticClass:"table table--properties"},[_c('caption',[_vm._v(" "+_vm._s(_vm.$t('Manage Secure Docs'))+" ")]),_c('thead',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Path'))+" "),_c('span',{staticClass:"filter ml-0"},[_c('InputBox',{staticClass:"filter-items mb-0",attrs:{"id":'filter-files',"placeholder":_vm.$t('Filter Items'),"prepends":[{ icon: 'filter' }],"form-size":"sm"},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}})],1)]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Enable Screenshot/ Screenshare (Windows Only)'))+" ")]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Secure view')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Allow print')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Access count')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Allow acces times')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Actions')))])]),_c('tbody',[(!_vm.loading && _vm.paginatedUserDRMAccessFiles.length === 0)?_c('tr',[_c('td',{staticClass:"centered p-5",attrs:{"colspan":"7"}},[_c('EmptyListingInfo',{attrs:{"icon-name":"shield-check","text":"No Manage Secure Docs"}})],1)]):_vm._e(),_vm._l((_vm.paginatedUserDRMAccessFiles),function(file,k){return _c('DRMFileListItem',{key:k,attrs:{"item":file}})})],2)])]),(_vm.totalItems / _vm.itemsPerPage > 1)?_c('Paginator',{attrs:{"current-page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"total-items":_vm.totalItems},on:{"onPageChange":_vm.onPageChage}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }