<template>
  <DialogModal
    :visible="true"
    color="gray"
		:title="$t('Notification Settings for') + ' ' + path"
    position="top"
    :buttons="[
      {
        label: `${$t('Save')}`,
        callback: function () {
          save();
        },
      },
      {
        label: `${$t('Cancel')}`,
        outline: true,
        callback: function () {
          closeModal();
        },
      },
    ]"
    @close="closeModal"
  >
    <div class="settings-page">
    <NotificationSets
      :title="'Use default notification settings'"
      ref="notifications"
      @closeModal="closeModal"
      :path="path"/>
    </div>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import NotificationSets from '../Notifications/NotificationSets';

export default {
  components: {
    DialogModal,
    NotificationSets,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    save(){
      this.$refs.notifications.updateUserSettings();
    }
  },
};
</script>
<style lang="scss" scoped>
.settings-page .notifications-settings {
  padding: 0px
}
</style>

