<template>
  <div
    v-if="isRunning"
    v-shortkey="{ close: ['esc'] }"
    class="credits"
    @shortkey="onShortcut"
  >
    <img ref="img" src="vendor/credits/aurora.jpg" />
    <a class="close" @click="close"><Icon name="times" /></a>
    <div class="credits-text">
      <h4>{{ $t('Made with ❤️ by all of us at FileCloud from') }}</h4>
      <div v-for="country in countries" class="credits-text-section">
        <p>{{ country }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      isRunning: false,
      timeout: null,
      countries: [
        'Algeria',
        'Argentina',
        'Austria',
        'Bolivia',
        'Brazil',
        'Croatia',
        'France',
        'Germany',
        'Guatemala',
        'Honduras',
        'Hungary',
        'India',
        'Ireland',
        'Philippines',
        'Poland',
        'Portugal',
        'Singapore',
        'Spain',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        '...',
        'and many more',
      ],
    };
  },
  mounted() {
    this.$credits.subscription.$on('open', this.start.bind(this));
  },
  destroyed() {
    this.$credits.subscription.$off('open', this.start.bind(this));
  },
  methods: {
    start() {
      clearTimeout(this.timeout);

      this.isRunning = true;

      // 50s timeout
      this.timeout = setTimeout(() => {
        this.close();
      }, 40000);
    },
    close() {
      clearTimeout(this.timeout);
      this.isRunning = false;
    },
    onShortcut(e) {
      if (e.srcKey == 'close') {
        this.close();
      }
    },
  },
};
</script>
