const file = [
  {
    label: 'path equals',
    ruleString: '_file.path',
    type: 'File',
    example: '/myuser/mydir/myfile.pdf',
    sample: "_file.path == '/myuser/mydir/myfile.pdf'",
    description: 'Returns the path that was accessed.',
  },
  {
    label: 'path starts with',
    ruleString: '_file.pathStartsWith(start)',
    type: 'File',
    example: '/myuser/mydir',
    sample: "_file.pathStartsWith('/myuser/mydir')",
    description:
      'Returns true when the path that has been accessed starts with the given `start` parameter.',
  },
  {
    label: 'extension is',
    ruleString: '_file.ext',
    type: 'File',
    example: 'pdf',
    sample: "_file.ext == 'pdf'",
    description:
      'Returns true when the file extension that has been accessed equals to `extension` parameter.',
  },
  {
    label: 'path contains',
    ruleString: '_file.pathContains(text)',
    type: 'File',
    example: '/myuser/mydir',
    sample: "_file.pathContains('/myuser/mydir')",
    description:
      'Returns true when the path that has been accessed contains with the given `text` parameter.',
  },
  {
    label: 'path matches',
    ruleString: "_file.pathMatches('*sometext*')",
    type: 'File',
    example: '/myuser/mydir',
    sample: "_file.pathMatches('/myuser/mydir')",
    description:
      'Returns true when the path matches the given `pattern` parameter. Wildcards are supported: `*` for any sequence of characters and `#` for a single character',
  },
  {
    label: 'filename contains',
    ruleString: '_file.fileNameContains(text)',
    type: 'File',
    example: 'sometext',
    sample: "_file.fileNameContains('sometext')",
    description:
      'Returns true when the file name contains the given `text` parameter.',
  },
];
const metadata = [
  {
    label: 'exists',
    ruleString: '_metadata.exists(metadataValue)',
    type: 'Metadata',
    example: 'cce.pii',
    sample: "_metadata.exists('cce.pii')",
    description:
      'Checks if the path, or one of its children, have the given metadata attribute set. The metadata attribute must be provided using the `metadataSet.attribute` notation.',
  },
  {
    label: 'exists all',
    ruleString: '_metadata.existsAll(metadataValue)',
    type: 'Metadata',
    example: 'cce.pii',
    sample: "_metadata.existsAll('cce.pii')",
    description:
      'Checks if the path, or all of its children, have the given metadata attribute set. The metadata attribute must be provided using the `metadataSet.attribute` notation.',
  },
  {
    label: 'exists with value',
    ruleString: '_metadata.existsWithValue(metadataValue, value)',
    type: 'Metadata',
    example: 'content.category,confidential',
    sample: "_metadata.existsWithValue('content.category', 'confidential')",
    description:
      'This function is similar to the _metadata.exists(metadataValue) function, but it checks if the metadata attribute (first parameter) exists, and if its value is equal to a given value (second parameter).',
  },
  {
    label: 'exists with value in array',
    ruleString: '_metadata.existsWithValueInArray(metadataValue, value)',
    type: 'Metadata',
    example: 'content.categories,pii',
    sample: "_metadata.existsWithValueInArray('content.categories', 'pii')",
    description:
      'This function is similar to the `existsWithValue` function, but checks whether an array metadata attribute contains the specified value',
  },
  {
    label: 'exists with condition',
    ruleString: '_metadata.existsWithCondition(metadataValue, operator, value)',
    type: 'Metadata',
    example: 'content.Risk Level,>,6',
    sample: "_metadata.existsWithCondition('content.Risk Level', '>', 6)",
    description:
      'This function is similar to the `existsWithValue` function, but it takes a operator parameter (second) that will be used to compare the metadata attribute value (first parameter) with the provided value (third parameter). The available operators are: `==` (equals), `!=` or `<>` (not equal), `>` (greater than), `<` (less than), `>=` and `<=`. The sample checks if the risk level of a document is greater than 6.',
  },
];
const request = [
  {
    label: 'ip equals',
    ruleString: '_request.remoteIp',
    type: 'Request',
    example: '43.12.45.78',
    sample: "_request.remoteIp == '43.12.45.78'",
    description: 'Returns the IP address that was used to execute the action.',
  },
  {
    label: 'is admin login',
    ruleString: '_request.isAdminLogin',
    type: 'Request',
    example: 'true',
    sample: '_request.isAdminLogin',
    description:
      'Returns true for admin login request. (only applicable to login rules)',
  },
  {
    label: 'ip is between',
    ruleString: '_request.inIpv4Range(lowIp, highIp)',
    type: 'Request',
    example: '138.204.26.1,138.204.26.254',
    sample: "_request.inIpv4Range('138.204.26.1', '138.204.26.254')",
    description:
      'Checks if the IP address that was used to execute the action is part of a given IP range, represented by limits of the range (given with the parameters).',
  },
  {
    label: 'ip is in range',
    ruleString: '_request.inIpV4CidrRange(cidrRange)',
    type: 'Request',
    example: '10.2.0.0/16',
    sample: "_request.inIpV4CidrRange('10.2.0.0/16')",
    description:
      'Checks if the IP address used to execute the action matches the given CIDR range.',
  },
  {
    label: 'user agent equals',
    ruleString: '_request.agent',
    type: 'Request',
    example: 'Unknown',
    sample: "_request.agent == 'Unknown'",
    description:
      "Returns the user agent that was used to execute the action. The possible values are: 'Cloud Drive', 'Cloud Sync', 'Unknown', 'Web browser', 'Android', 'iOS', 'MS Outlook' and 'MS Office'.",
  },
  {
    label: 'country equals',
    ruleString: '_request.remoteCountryCode',
    type: 'Request',
    example: 'US',
    sample: "_request.remoteCountryCode == 'US'",
    description:
      'Returns the two-character uppercase ISO country code. Returns "Unknown" if country could not be determined.<br> Requires GeoIP to be set up and enabled.',
  },
];
const share = [
  {
    label: 'has users from domain',
    ruleString: '_share.hasUsersFromDomain(domains)',
    type: 'Share',
    example: 'gmail.com',
    sample: "_share.hasUsersFromDomain('gmail.com')",
    description:
      "Checks if the allowed users list has any users with an email domain that MATCHES any of the given domains. The 'domains' parameter can be a single domain, or a comma-separated domains list. In the provided sample, the expression will return true if any user with a gmail email is included as allowed user (directly or through a group). This method is only applicable for DENY rules",
  },
  {
    label: 'only allowed emails',
    ruleString: '_share.onlyAllowedEmails(emails)',
    type: 'Share',
    example: 'user1@mycompany.com',
    sample:
      "_share.onlyAllowedEmails('*@mycompany.com', 'otheraddress@otherdomain.com')",
    description:
      "Checks if the allowed users list have any users with an email that DOESN'T MATCH any of the given emails in the list. <br> The list of emails is a single address, or a comma-separated address list. <br>For allowing domains, a wildcard is accepted, for example *@domain.com.<br>  Wildcard is only accepted before the domain, followed by an @, as in the example. <br> In the example, the expression only returns 'true' if all user emails have the mycompany.com domain or a different username and different domain in the email address (differentusername@differentdomain.com).<br> This method is only applicable for ALLOW rules.",
    },
  {
    label: 'is public',
    ruleString: '_share.public',
    type: 'Share',
    example: 'true',
    sample: '_share.public == true',
    description: 'Returns true or false if the share is public or not',
  },
  {
    label: 'only users from domain',
    ruleString: '_share.onlyUsersFromDomain(domains)',
    type: 'Share',
    example: 'gmail.com',
    sample: "_share.onlyUsersFromDomain('gmail.com')",
    description:
      "Checks if the allowed users list have any users with an email that DOESN'T MATCH any of the given domains. The 'domains' parameter can be a single domain, or a comma-separated domains list. In the provided sample, the expression only returns true if all users have their emails in the `mycompany.com` domain. This method is only applicable for ALLOW rules",
  },
  {
    label: 'share path equals',
    ruleString: '_share.path',
    type: 'Share',
    example: '/myuser/mydir/myfile.pdf',
    sample: "_share.path == '/myuser/mydir/myfile.pdf'",
    description: 'Returns the path of the share',
  },
  {
    label: 'share path contains',
    ruleString: '_share.pathContains(text)',
    type: 'Share',
    example: 'sometext',
    sample: "_share.pathContains('sometext')",
    description:
      'Returns true when the shared path contains the given `text` parameter.',
  },
  {
    label: 'share path starts with',
    ruleString: '_share.pathStartsWith(start)',
    type: 'Share',
    example: '/myuser/mydir',
    sample: "_share.pathStartsWith('/myuser/mydir')",
    description:
      'Returns true when the shared path starts with the given `start` parameter.',
  },
  {
    label: 'share path matches',
    ruleString: '_share.pathMatches(pattern)',
    type: 'Share',
    example: '*sometext*',
    sample: "_share.pathMatches('*sometext*')",
    description:
      'Returns true when the shared path matches the given `pattern` parameter. Wildcards are supported: `*` for any sequence of characters and `#` for a single character',
  },
  {
    label: 'group is in share allowed groups',
    ruleString: '_share.allowedGroups',
    type: 'Share',
    example: 'EVERYONE',
    sample: "'EVERYONE' in _share.allowedGroups",
    description: 'Return a list of the allowed groups of the share.',
  },
  {
    label: 'user is in share allowed users',
    ruleString: '_share.allowedUsers',
    type: 'Share',
    example: 'john.snow@mail.com',
    sample: "'john.snow@mail.com' in _share.allowedUsers",
    description:
      'Returns a list of the allowed users of the share (including the users in allowed groups). The list contains the users email addresses',
  },
];
const user = [
  {
    label: 'email equals',
    ruleString: '_user.email',
    type: 'User',
    example: 'john.snow@mail.com',
    sample: "_user.email == 'john.snow@mail.com'",
    description: 'Returns the email of the user trying to execute an action.',
  },
  {
    label: 'is master admin',
    ruleString: '_user.isMasterAdmin',
    type: 'User',
    example: 'true',
    sample: "_user.isMasterAdmin == 'true'",
    description: 'Returns true for admin login request.',
  },
  {
    label: 'is email in domain',
    ruleString: '_user.isEmailInDomain(domainsToCheck)',
    type: 'User',
    example: 'example.com,mail.com',
    sample: "_user.isEmailInDomain('example.com', 'mail.com')",
    description:
      "Check if a user's email id matches a given list of domains. The 'domainsToCheck' parameter can be a single domain, or a comma-separated domains list.",
  },
  {
    label: 'in group',
    ruleString: '_user.inGroup(groupName)',
    type: 'User',
    example: 'managers',
    sample: "!_user.inGroup('managers')",
    description: 'Checks if a user is part of a given group',
  },
  {
    label: 'username equals',
    ruleString: '_user.username',
    type: 'User',
    example: 'personongrata',
    sample: "_user.username == 'personanongrata'",
    description: 'Returns the name of the user trying to execute an action.',
  },
  {
    label: 'user type equals',
    ruleString: '_user.userType',
    type: 'User',
    example: 'Guest Access',
    sample: "_user.userType == 'Guest Access'",
    description:
      "Returns the type of the user that is trying to execute the action. The available types are: 'Full Access', 'Limited Access', 'Guest Access'",
  },
];

export const ruleTypes = {
  operands: ['File', 'Request', 'Metadata', 'User', 'Share'],
  operators: [...file, ...user, ...metadata, ...request, ...share],
  inputType: 'text',
  ruleString: 'text-field',
};

export const specialParsingRules = [
  '_file.size',
  '_user.last_login',
  '_user.quota_usage_gb',
  '_startedby.last_login',
  '_startedby.quota_usage_gb',
];

export const ruleInType = ['_share.allowedUsers', '_share.allowedGroups'];

export const flagRules = [
  '_share.public',
  '_share.is_dir',
  '_file.is_dir',
  '_user.isMasterAdmin',
  '_request.isAdminLogin',
];

export const QBRegex = {
  operatorTypeEx: /_(.*?)\./,
  ruleStringEx: /(_\S+)[ |\)]/,
  comparationEx: /[<>==]/,
  specialValueEx: /([<|=\(| ])[^\)]*/,
  equationValueEx: /'(.*)'/,
  methodValueEx: /\(.*\)/,
  labelEx: /(_.+?)[\(| |\)]/,
  splitEx: / \|| &/gi,
  regex: /\((.*?)\)/,
  removeUnwantedWhitespacesEx: /\(\s+\(/gi,
  removeUnwantedWhitespacesTwoEx: /\)\s+\)/gi,
};
