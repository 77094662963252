import router from '../../../router.js';
import store from '../../../store/index.js';
import confetti from 'canvas-confetti';

export default {
  title: 'Folder creation',
  isCompleted: false,
  id: 'onboarding.file-basics.folder-creation',
  steps: [
    {
      id: 'onboarding.file-basics.folder-creation.navigate-my-files',
      description: 'Navigate to "My Files"',
      shape: 'circle',
      element:
        '#folderSummary > div.folder-list.folder-list--spaced.row > div:nth-child(1) > a',

      onStepStart: () => {
        const name = router.history.current.name;
        if (name === 'files-sub') {
          store.dispatch('onboarding/nextStep');
        } else if (name !== 'files') {
          //page that has left sidebar
          store.commit('onboarding/updateStep', {
            id: 'onboarding.file-basics.folder-creation.navigate-my-files',
            element:
              '.sidebar-sticky > .scrollable-area > .page_wrap:first-child .droppable > .tree-menu',
            shape: 'rectangle',
          });
        }
      },
      trigger: {
        triggerOn: 'files/set',
        onTrigger: function (payload) {
          const { path } = payload;
          const user = store.state.auth.user;

          return path === `/${user.peerid}`;
        },
      },
    },
    {
      id: 'onboarding.file-basics.folder-creation.chose-operation',
      description: 'Choose the "New Folder" operation',
      shape: 'rectangle',
      element: '#fcExplorerHeader > .nav-body > .explorer-cta .trigger > .btn',
      trigger: {
        triggerOn: 'click',
        onTrigger: function () {
          return true;
        },
      },
    },
    {
      id: 'onboarding.file-basics.folder-creation.dropdown-selection',
      descriptionAlt: 'Choose the "New Folder" operation',
      shape: 'tooltip',
      animation: 0,
      element: 'div.new-file-actions-new-folder',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.file-basics.folder-creation.folder-name',
      description: 'Enter a name and press Enter',
      shape: 'tooltip',
      element:
        '.file-list-body.file-list--new-folder .file-list-item.file-list-item--file .form-control.rename-input',
      trigger: {
        triggerOn: 'files/setRoot',
        onTrigger: function (payload) {
          const { key, value } = payload;
          const { activity } = value;

          return key === 'updateFile' && activity === 'added';
        },
      },
    },
  ],
  onTopicCompleted() {
    store.commit('onboarding/set', {
      type: 'isExpanded',
      status: true,
    });

    confetti({
      particleCount: 100,
      zIndex: 9999999,
      origin: { x: 0.9, y: 1 },
    });
  },
};
