<template>
  <transition
    enter-active-class="animate__animated animate__slideInUp"
    leave-active-class="animate__animated animate__slideOutDown"
  >
    <div
      v-show="mobileMenu.open"
      class="mobile-menu"
      :class="{ 'mobile-menu--active': mobileMenu.open }"
    >
      <Loader v-if="loading" :loading="loading" full />
      <div v-else>
        <div v-if="selectedFile !== null" class="header">
          <Icon
            v-if="selectedFile[0].type === 'dir' && selectedFile.length <= 1"
            class="menufile-icon menufile-icon--folder"
            family="fas"
            name="folder"
          />
          <FileIcon
            v-else-if="
              selectedFile[0].type !== 'dir' && selectedFile.length <= 1
            "
            class="menufile-icon"
            :name="selectedFile[0].name"
            :item="selectedFile[0]"
            :format="selectedFile[0].format"
            :path="selectedFile[0].path"
          />

          <FileIcon
            v-else
            :name="'multifiles'"
            :format="'multifiles'"
            class="menufile-icon"
          />

          <div class="menufile-details">
            <h3
              class="name"
              :class="{ 'name--multifiles': selectedFile.length > 1 }"
            >
              {{
                selectedFile.length <= 1
                  ? selectedFile[0].name
                  : $tc('{count} files selected', selectedFile.length)
              }}
            </h3>
            <div v-if="selectedFile.length <= 1" class="details">
              <span v-if="!selectedFile[0].shareid">
                {{
                  selectedFile[0].type === 'dir'
                    ? ''
                    : selectedFile[0].size + ' - '
                }}
                {{
                  hasModificationDate
                    ? $t('Modified ') + selectedFile[0].modified
                    : 'Unmodified'
                }}
              </span>
              <span v-else>
                {{ selectedFile[0].lastaccess }}
                {{
                  selectedFile[0].recentactivitycount > 0
                    ? $tc(
                        '{count} recent activity | {count} recents activities',
                        selectedFile[0].recentactivitycount
                      )
                    : $t('no recent activity')
                }}
              </span>
            </div>
          </div>
          <div class="right-buttons">
            <button
              v-if="selectedFile.length === 1"
              class="btn btn--info"
              @click="toggleStarred(selectedFile[0])"
            >
              <Icon name="star" :family="mobileMenu.favorite ? 'fas' : 'fal'" />
            </button>
            <button class="btn btn--info" @click="toggleSidebar">
              <Icon name="info-circle" family="fas" />
            </button>
            <button class="btn btn--close" @click="close">
              <Icon name="times" />
            </button>
          </div>
        </div>
        <div ref="mobileMenuBody" class="body">
          <div
            v-for="(item, id) in mobileMenu.actions"
            v-if="
              !(
                (mobileMenu.target &&
                  item.disabled &&
                  item.disabled(
                    selectedFile.length === 1
                      ? {
                          item: mobileMenu.item,
                          selected: mobileMenu.item,
                          meta: mobileMenu.target.meta,
                          isInRecycleFolder,
                          isLimitedUser,
                          isPublicShareView,
                          customization,
                          systemStatus,
                          isLockedByOthers: isLockedByOthers(mobileMenu.item),
                          isImplicitlyLocked: isImplicitlyLocked(
                            mobileMenu.item
                          ),
                          isAuthenticated: user.authenticated,
                          isDRMExportEnabled:
                            fullSystemStatus['isdrmenabled'] === 1,
                        }
                      : {
                          item: selectedFile,
                          selected: mobileMenu.item,
                          isQuickEditEnabled,
                          isContext: true,
                          downloadable: true,
                          isDRMExportEnabled:
                            fullSystemStatus['isdrmenabled'] === 1,
                        }
                  )) ||
                (item.hidden &&
                  item.hidden(
                    selectedFile.length === 1
                      ? {
                          item: mobileMenu.item,
                          selected: mobileMenu.item,
                          meta: mobileMenu.target.meta,
                          isInRecycleFolder,
                          isLimitedUser,
                          isPublicShareView,
                          metaSets,
                          customization,
                          systemStatus,
                          isLockedByOthers: isLockedByOthers(mobileMenu.item),
                          isImplicitlyLocked: isImplicitlyLocked(
                            mobileMenu.item
                          ),
                          isAuthenticated: user.authenticated,
                          msteams: msTeams,
                          hasSearchResults: mobileMenu.target.hasSearchResults,
                        }
                      : {
                          item: selectedFile,
                          selected: mobileMenu.item,
                          meta: mobileMenu.target.meta,
                          isQuickEditEnabled,
                          isContext: true,
                          downloadable: true,
                          msteams: msTeams,
                        }
                  ))
              )
            "
            :key="id"
            class="menu-item"
            :class="{
              'menu-item--separator': item.seperator,
              'menu-item--collapsable': item.color,
              'menu-item--collapsable-openned': isChoosingColor && item.color,
            }"
            tabindex="0"
            @click="triggerAction(item.action, selectedFile)"
          >
            <div class="icon">
              <Icon
                v-if="item.icon"
                :name="item.icon"
                :family="item.iconFamily || 'fal'"
              />
            </div>
            <span class="title">
              {{
                $t(
                  typeof item.name === 'string'
                    ? item.name
                    : item.name(mobileMenu.target)
                )
              }}
            </span>
            <div v-if="item.color" class="collapse-toggle">
              <Icon name="chevron-down" />
            </div>
            <div class="collapse-content">
              <ColorTag
                :child="mobileMenu.component"
                :current-color="mobileMenu.item.colortag"
                @onItemContextClick="triggerAction"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import _ from 'lodash';
import Loader from 'common/components/Loader';
import ColorTag from '../FileList/partials/ColorTag';
import { mapGetters } from 'vuex';

export default {
  name: 'MobileMenu',
  components: {
    Icon,
    FileIcon,
    ColorTag,
    Loader,
  },
  props: {
    isPublicShareView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChoosingColor: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    mobileMenu() {
      return this.$store.getters['files/getMobileMenuState']();
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    selectedFile() {
      if (Array.isArray(this.mobileMenu.item)) {
        return this.mobileMenu.item.length > 0 ? this.mobileMenu.item : null;
      } else {
        return this.mobileMenu.item ? [this.mobileMenu.item] : null;
      }
    },
    hasModificationDate() {
      return (
        !_.isEmpty(this.selectedFile[0].modified) &&
        typeof this.selectedFile[0].modified !== 'object'
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
    metaSets() {
      const mSets = this.$store.state.core.metaSets;
      return typeof mSets === 'object' && mSets !== null ? mSets.write : false;
    },
    isInRecycleFolder() {
      return this.$route.fullPath.indexOf('recyclebin') > -1;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    isQuickEditEnabled() {
      return this.customization.enabledQuickEdit;
    },
  },
  mounted() {
    this.$root.$on('overlayClicked', () => this.close);
  },
  methods: {
    triggerAction(action, file, color = null) {
      if (action != 'metaColor' && action != 'removeMetaColor') {
        this.close();

        if (!this.mobileMenu.isRoot) {
          this.mobileMenu.component[action].call();
        } else {
          if (action === 'downloadFolder') {
            this.mobileMenu.component[action].call();
          }
          this.mobileMenu.component.onClickSelectedFiles(action);
        }
      } else {
        if (
          event.target.classList.contains('menu-item') ||
          event.target.classList.contains('title') ||
          event.target.classList.contains('inline-icon')
        )
          this.isChoosingColor = !this.isChoosingColor;

        if (color) this.mobileMenu.component[action](color);

        setTimeout(() => {
          if (this.isChoosingColor) {
            const menuBody = this.$refs.mobileMenuBody;
            menuBody.scrollTop = menuBody.scrollHeight;
          }
        }, 10);
      }
    },
    async toggleSidebar() {
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: false,
      });
      this.$store.commit('files/setSidebar', {
        key: 'open',
        value: true,
      });

      this.$store.commit('files/hideMobileOverlay');
    },
    close() {
      this.isChoosingColor = false;

      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: false,
      });

      this.$store.commit('files/hideMobileOverlay');
    },
    isLockedByOthers(item) {
      return item.locked && item.lockuserid !== this.user.profile;
    },
    isImplicitlyLocked(item) {
      return item.implicitlock;
    },
    async toggleStarred(item) {
      this.loading = true;
      await this.mobileMenu.component.toggleStarred(
        item,
        this.mobileMenu.favorite
      );
      this.loading = false;
    },
  },
};
</script>
