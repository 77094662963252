<template>
  <table class="table-simplified table--variables">
    <thead>
      <tr>
        <th scope="col" class="expand">{{ $t('Name') }}</th>
        <th scope="col">{{ $t('Type') }}</th>
        <th scope="col">{{ $t('Value') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in currentArray" :key="index">
        <th scope="row" class="expand">
          <span class="text-truncate d-block">{{
            $t(item.DISPLAY_NAME_PARAMETER)
          }}</span>
        </th>
        <td>{{ $t(item.DATA_TYPE_PARAMETER) }}</td>
        <td class="value">
          <span class="text-truncate d-block">{{
            $t(item.VALUE_PARAMETER)
          }}</span>
        </td>
        <td class="actions">
          <button
            v-if="
              (fieldType !== 'integer' && fieldType !== 'email') ||
              item.DATA_TYPE_PARAMETER === fieldType
            "
            class="btn btn-sm btn-outline-primary action"
            @click="$emit('addVar', item)"
          >
            Insert
          </button>
          <span
            v-if="current === 'variables'"
            class="action"
            @click="$emit('editVar', index, item)"
          >
            <Icon family="fal" name="edit" />
          </span>
          <span
            v-if="current === 'variables'"
            class="action"
            @click="$emit('deleteVar', index)"
          >
            <Icon family="fal" name="trash-alt" />
          </span>
          <!-- <span class="action">
            <Icon family="fas" name="ellipsis-h" />
          </span> -->
        </td>
      </tr>
      <tr v-if="currentArray.length === 0" class="empty">
        <td colspan="3">{{ $t('No variable found') }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'WorkflowVariablesTable',
  components: {
    Icon,
  },
  props: {
    current: {
      type: String,
      default: 'variables',
    },
    fieldType: {
      type: String,
      default: 'string',
    },
  },
  computed: {
    currentArray() {
      return this[this.current];
    },
    variables() {
      return this.$store.state.workflows.currentWorkflow.variables;
    },
    context() {
      return this.$store.state.workflows.currentWorkflow.properties.context;
    },
    event() {
      let properties = [];
      let storeProperties =
        this.$store.state.workflows.currentWorkflow.properties.objects;

      for (let key of Object.keys(storeProperties)) {
        storeProperties[key].forEach((item) => {
          properties.push(item);
        });
      }

      return properties;
    },
  },
};
</script>
