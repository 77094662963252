export const doNotRedirectAfterAuth = [
  'expl-tabl.',
  'sets-acct.',
  'sets-devi.',
  'sets-gnrl.',
  'sets-noti.',
  'shrd-mgmt.',
  'srch-tabl.',
  'webeditor',
  'workflow',
  'actions',
];
