<template>
  <tr
    ref="item"
    :effect-allowed="['link']"
    class="file-list-item file-list-item--file"
  >
    <td class="col-title drm-files file-path pl-4" @click="expand">
      {{ item.path }}
    </td>
    <td class="centered">
      <Checkbox
        class="m-auto"
        v-tooltip="
          item.allow_screen_share == 1
            ? $t('Enable screen shot protection')
            : $t('Disable screen shot protection')
        "
        :label="
          item.allow_screen_share == 1
            ? $t('Enable screen shot protection')
            : $t('Disable screen shot protection')
        "
        :checked="item.allow_screen_share == 1"
        @input="updatePermission(item, 'screenshare')"
      />
    </td>
    <td class="thin centered">
      <Checkbox
        class="m-auto"
        v-tooltip="
          item.allow_secure_view == 1
            ? $t('Disable secure view')
            : $t('Enable secure view')
        "
        :label="
          item.allow_secure_view == 1
            ? $t('Disable secure view')
            : $t('Enable secure view')
        "
        :checked="item.allow_secure_view == 1"
        @input="updatePermission(item, 'secureview')"
      />
    </td>
    <td class="thin centered">
      <Checkbox
        class="m-auto"
        v-tooltip="
          item.allow_print == 1 ? $t('Disable print') : $t('Enable print')
        "
        :label="
          item.allow_print == 1 ? $t('Disable print') : $t('Enable print')
        "
        :checked="item.allow_print == 1"
        @input="updatePermission(item, 'print')"
      />
    </td>
    <td class="thin centered">
      {{ item.access_count }}
    </td>
    <td v-if="isEditingMaxAccessCount" class="thin centered">
      <input
        ref="maxaccessInput"
        v-model="newMaxCount"
        :aria-label="$t('Max access count')"
        class="form-control rename-input"
        type="text"
        @keyup.enter="updatePermission(item, 'maxaccesscount')"
        @keyup.escape="onCancelMaxAccessEdit"
        @blur="updatePermission(item, 'maxaccesscount')"
      />
    </td>
    <td
      v-else
      v-tooltip="$t('Click to edit max access count')"
      class="centered"
      @click="onMaxAccessEdit"
    >
      <span class="editable-click">
        {{
          item.max_access_count == -1 ? $t('Unlimited') : item.max_access_count
        }}</span
      >
    </td>
    <td class="centered">
      <v-popover>
        <button
          v-tooltip="$t('Revoke Secure Access')"
          :aria-label="$t('Revoke Secure Access')"
          class="btn btn-sm btn-icon small-grayed"
          list-only
        >
          <Icon name="times" family="fas" />
        </button>
        <Alert
          slot="popover"
          button-type="danger"
          :buttons="[
            {
              label: `Revoke`,
              callback: function () {
                revoke(item._id);
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            {{
              $t(
                'Are you sure you want to revoke secure access for the selection'
              )
            }}
            <em>{{ item.path }}</em>
            ?
          </span>
        </Alert>
      </v-popover>
    </td>
  </tr>
</template>
<script>
import Icon from 'common/components/Icon';
import Themed from './../Themed';
import Alert from 'common/components/Alert';
import Checkbox from 'common/components/Checkbox';

export default {
  components: {
    Themed,
    Icon,
    Alert,
    Checkbox,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      secfRevokeSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('DRM access revoked successfully.') +
          '</p>',
        type: 'success',
      },
      secfRevokeError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to revoke DRM access') +
          '</p>',
        type: 'warning',
      },
      secfConfigSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('DRM access permissions updated successfully.') +
          '</p>',
        type: 'success',
      },
      secfConfigError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to update DRM access permissions.') +
          '</p>',
        type: 'warning',
      },
      isEditingMaxAccessCount: false,
      newMaxCount: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    thumb() {
      return '';
    },
  },
  mounted() {
    this.$root.$on('drmAccessModalClosed', () => {
      this.updatePermission(this.item, 'maxaccesscount');
    });
  },
  destroyed() {
    this.$root.$off('drmAccessModalClosed');
  },
  methods: {
    async onMaxAccessEdit() {
      this.isEditingMaxAccessCount = true;
      this.$root.$emit(
        'isEditingDRMMaxAccessField',
        this.isEditingMaxAccessCount
      );
      this.newMaxCount = this.item.max_access_count;
      this.$nextTick(() => {
        this.$refs.maxaccessInput.focus();
        this.$refs.maxaccessInput.select();
      });
    },
    async onCancelMaxAccessEdit() {
      this.isEditingMaxAccessCount = false;
      this.$root.$emit(
        'isEditingDRMMaxAccessField',
        this.isEditingMaxAccessCount
      );
    },
    async updatePermission(item, permission) {
      this.onCancelMaxAccessEdit();
      let payload = {
        drmid: item._id,
        allow_print: item.allow_print,
        allow_screen_share: item.allow_screen_share,
        enable_secure_view: item.allow_secure_view,
        max_access_count: item.max_access_count,
      };
      switch (permission) {
        case 'print':
          payload.allow_print = item.allow_print ? 0 : 1;
          break;
        case 'screenshare':
          payload.allow_screen_share = item.allow_screen_share ? 0 : 1;
          break;
        case 'secureview':
          payload.allow_secure_view = item.allow_secure_view ? 0 : 1;
          break;
        case 'maxaccesscount':
          payload.max_access_count = this.newMaxCount;
          break;
      }
      const response = await this.$store.dispatch(
        'files/configureDRMAccess',
        payload
      );
      if (response.ok) {
        this.$toast.open(this.secfConfigSuccess);
      } else {
        this.$toast.open(this.secfConfigError);
      }
    },
    async revoke(fileid) {
      const response = await this.$store.dispatch('files/revokeDRMAccess', {
        drmid: fileid,
      });
      if (response.ok) {
        this.$toast.open(this.secfRevokeSuccess);
      } else {
        this.$toast.open(this.secfRevokeError);
      }
    },
    triggerAction(action) {
      this[action]();
    },
    select() {},
    expand() {},
  },
};
</script>
<style lang="scss" scoped>
.popover {
  opacity: 1;
}
.fa-times {
  cursor: pointer;
}
.editable-click {
  border-bottom: dashed 1px #08c;
  cursor: pointer;
}
td {
  word-break: break-all;
  width: 180px;
}
.file-path {
  width: 400px;
}
table.table tbody tr td {
  word-break: break-all;
  font-size: 0.9rem !important;
  font-weight: 400;
  border-bottom: 0;
  &.thin {
    width: 100px;
  }
}
table.table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
</style>
