<template>
  <div class="container p-0 m-0 background-white">
    <div class="notification-header pt-2 pr-2 col-12">
      <span class="notification-header-text pt-2">
        {{ $t('Notifications') }}
      </span>
    </div>
    <div
      v-if="meta && meta.total !== 0"
      class="notification-header-sub row p-0 m-0 pt-2 pr-0 mr-0 col-12"
    >
      <div v-if="unreadCount > 0" class="row col-12">
        <span class="pt-2 p-0 col-1 ml-2 pl-2 pr-0 mr-0">
          <Icon name="bell" class="fas fa-bell bell-icon ml-1 pl-1 p-0 m-0" />
        </span>
        <span
          class="col-5 p-0 m-0 notification-header-sub-text vertical-align pt-2 ml-1 pl-0"
        >
          {{ unreadCount }} {{ notificationText }}
        </span>
        <span class="col-5 m-0 p-0 pt-2 ml-3 pl-1 float-right">
          <a
            class="btn-mark-all-as-read float-right p-0 m-0 pr-0 mr-0 ml-2"
            @click="markAllRead"
          >
            {{ $t('Mark all as read') }}
          </a>
        </span>
      </div>
      <span
        v-else
        class="col-6 notification-header-sub-text pt-2 p-0 m-0 align-middle"
      >
        <Icon
          name="bell-slash"
          class="fas fa-bell-slash bell-slash-icon align-middle pl-1 p-0 mr-1"
        />
        {{ $t('No new notifications') }}
      </span>
    </div>
    <div v-if="loading" class="loader">
      <Loader full :loading="loading" />
    </div>
    <div v-else class="notification-list notification-container">
      <template v-for="notification in notifications">
        <ActionNotification
          v-if="notification.is_action"
          :key="notification._id"
          :notification="notification"
          @updateList="onUpdateList"
          @closeNotifications="$emit('closeNotifications')"
        />
        <Notification
          v-else
          :key="notification.toastrid"
          :notification="notification"
          @closeNotifications="$emit('closeNotifications')"
        />
      </template>
    </div>
    <div class="notification-footer pt-1 pr-2 col-12">
      <span class="float-left">
        {{ statusLastLoaded }}
      </span>
      <span class="float-right">
        <button
          v-tooltip="
            muted ? $t('Unmute Notifications') : $t('Mute Notifications')
          "
          class="btn pl-1 pr-1 pt-2 shadow-none"
          :aria-label="
            muted ? $t('Unmute Notifications') : $t('Mute Notifications')
          "
          @click="mute"
        >
          <Icon
            v-if="muted"
            name="volume-mute"
            class="fas font-weight-normal mute"
          />
          <Icon v-else name="volume" class="fas font-weight-normal mute" />
        </button>
      </span>
      <span class="mr-1 float-right">
        <button
          v-tooltip="$t('Sync')"
          class="btn shadow-none"
          :aria-label="$t('Sync')"
          @click="() => getToasts() && getActions()"
        >
          <Icon name="sync" class="fas font-weight-normal" />
        </button>
      </span>
      <span v-if="showToastAlerts" class="mr-1 float-left"> </span>
    </div>
  </div>
</template>
<script>
import Notification from './Notification';
import ActionNotification from './ActionNotification';
import Icon from 'common/components/Icon';
import Loader from 'common/components/FileListLoader';
import { createFocusTrap } from 'focus-trap';
import { throttle } from 'lodash';

export default {
  components: {
    ActionNotification,
    Notification,
    Icon,
    Loader,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      start: 0,
      limit: 10,
      empty: false,
      loading: false,
      muted: false,
      statusLastLoaded: this.$t('Updated just now'),
      lastUnreadToast: null,
      focusTrap: null,
    };
  },
  computed: {
    toasts() {
      const toasts = this.$store.state.core.toasts.toast;

      if (typeof toasts === 'undefined') {
        return Array.of({
          alreadyread: 1,
          message: 'No pending notifications',
          toastrid: 0,
        });
      }

      return !!toasts && toasts.constructor === Array
        ? toasts
        : Array.of(toasts);
    },

    notifications() {
      let toasts = this.toasts;
      const actions = this.$store.state.actions.actions.inbox
        .filter((action) => !action.is_read)
        .map((action) => {
          return { ...action, is_action: true };
        });

      return toasts.concat(actions).sort((a, b) => {
        const aTimeStamp = a.is_action ? a.created_timestamp : a.when;
        const bTimeStamp = b.is_action ? b.created_timestamp : b.when;

        const aDate = new Date(aTimeStamp);
        const bDate = new Date(bTimeStamp);

        if (aDate.getTime() > bDate.getTime()) {
          return -2;
        }

        if (aDate.getTime() < bDate.getTime()) {
          return 2;
        }

        // If they are the same, put the action before
        if (a.is_action) {
          return -1;
        }

        return 0;
      });
    },
    notificationText() {
      return this.unreadCount === 1
        ? this.$t(' new notification')
        : this.$t(' new notifications');
    },
    unreadCount() {
      // Due to scoping this needs to be
      // handled this way (reduce takes in arrow functions)
      const toasts = this.toasts;
      let unreadCount = 0;

      if (typeof toasts[0] === 'undefined') {
        return 0;
      }

      let index = 0;
      for (index; index < toasts.length; index++) {
        const aToast = toasts[index];
        const alreadyRead = aToast.alreadyread;
        unreadCount += alreadyRead === 0 ? 1 : 0;
      }
      return unreadCount;
    },
    meta() {
      return this.$store.state.core.toasts.meta;
    },
    showToastAlerts() {
      let lastUploadTime = this.$store.state.files.lastUploadTime;
      if (lastUploadTime == 0) {
        this.throttledRefreshToastsAndAlertUser(0);
      } else {
        this.scheduleToastRefreshInterval(lastUploadTime);
      }

      return false;
    },
  },
  watch: {
    isVisible(visible) {
      if (visible) {
        this.handleFocusTrapInit();
        this.getToasts();
        this.getActions();
        this.statusLastLoaded = this.$store.state.core.statusLastLoaded;
      } else {
        if (this.focusTrap) this.focusTrap.deactivate();
      }
    },
  },
  methods: {
    async mute() {
      this.muted = this.muted ? false : true;
    },
    async markAllRead() {
      this.loading = true;
      // Same scoping problem as mentioned above
      let toastIds = '';
      const toasts = this.toasts;
      let index = 0;
      for (index; index < toasts.length; index++) {
        const aToast = toasts[index];
        const toastrid = aToast.toastrid;
        toastIds += toastrid + '_';
      }

      await this.$store.dispatch('core/ackToasts', {
        acktoastids: toastIds.slice(0, -1),
      });
      await this.$store.dispatch('core/getToasts');

      this.loading = false;
    },
    async getToasts() {
      this.loading = true;

      await this.$store.dispatch('core/getToasts');

      this.loading = false;
    },
    async getActions() {
      this.loading = true;

      await this.$store.dispatch('actions/getActions', {
        type: 'inbox',
        showAll: false,
      });

      this.loading = false;
    },
    onUpdateList() {
      this.getToasts();
      this.getActions();
    },
    async scheduleToastRefreshInterval(lastUploadTime) {
      clearInterval(this.quickRefreshTimer);
      this.quickRefreshCount = 0;

      //set 10sec interval that executes 5 times
      this.quickRefreshTimer = setInterval(() => {
        this.quickRefreshCount += 1;
        if (this.quickRefreshCount > 5) {
          clearInterval(this.quickRefreshTimer);
          this.quickRefreshCount = 0;
          return;
        }
        this.throttledRefreshToastsAndAlertUser(lastUploadTime);
      }, 10000);

      //start one rightaway
      this.throttledRefreshToastsAndAlertUser(lastUploadTime);
    },
    async refreshToastsAndAlertUser(lastUploadTime) {
      if (lastUploadTime > 0) {
        //only after an upload happened
        await this.$store.dispatch('core/getToasts');
        await this.$store.dispatch('actions/getActions', {
          type: 'inbox',
          showAll: false,
        });
      }

      const toasts = this.toasts;
      if (typeof toasts[0] === 'undefined') {
        return;
      }

      let index = 0;
      let alertUser = false;
      for (index; index < toasts.length; index++) {
        const aToast = toasts[index];
        const alreadyRead = aToast.alreadyread;
        if (alreadyRead === 0) {
          //first time seeing unread toast or new unread toast
          if (
            this.lastUnreadToast == null ||
            aToast.when.localeCompare(this.lastUnreadToast.when) > 0
          ) {
            this.lastUnreadToast = aToast;
            alertUser = true;
            break;
          }
        }
      }

      //show alerts only after an upload has happened
      if (lastUploadTime > 0 && alertUser) {
        let message = this.lastUnreadToast.message;
        let title =
          this.lastUnreadToast.when +
          ': ' +
          this.$t('You have') +
          ' ' +
          this.unreadCount +
          ' ' +
          this.$t('unread notifications');
        this.$toast.open({
          message: '<b>' + title + '</b><p role="alert">' + message + '</p>',
          type: 'info',
        });
      }
    },
    throttledRefreshToastsAndAlertUser: throttle(function (lastUploadTime) {
      this.refreshToastsAndAlertUser(lastUploadTime);
    }, 3000),
    handleFocusTrapInit() {
      // timeout needed due to v-popover delay
      setTimeout(() => {
        if (!this.focusTrap) {
          this.focusTrap = createFocusTrap(this.$el, {
            allowOutsideClick: true,
            escapeDeactivates: false,
          });
        } else {
          this.focusTrap.updateContainerElements(this.$el);
        }
        this.focusTrap.activate();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

// avoid overscroll to the divs behind of it
.container > * {
  overscroll-behavior: contain;
}

.notification-container {
  max-height: 351px;
  overflow-x: hidden;
}
.loader {
  height: 351px;
  opacity: 1;
}
.notification-header {
  font-size: 14px; /* Style for "Rectangle" */
  height: 45px;
  border-radius: 4px 4px 0 0;
  background-color: var(--fc-blue);
  display: flex;
}
.notification-footer {
  font-size: 14px; /* Style for "Rectangle" */
  height: 51px;
  border-radius: 0 0 4px 4px;
  background-color: var(--bg-light);
  border-top: solid 1px var(--border-color);
  color: var(--text-dark-blue);
  .fa-volume-mute {
    font-size: 20px;
  }
}
.notification-header-text {
  /* Style for "You have 2" */
  color: #ffffff;
  font-size: 13px;
  line-height: 0.7rem;
  font-weight: 600;
}
.notification-list {
  font-size: 14px;
  overflow: auto;
  overflow-x: hidden;
}
.background-white {
  background-color: var(--bg-light);
}
.btn {
  color: var(--text-dark);
}
.btn-mark-all-as-read {
  /* Style for "Rectangle" */
  height: 29px;
  border-radius: 3px;
  font-size: 12px;
  padding: 2px;
  font-weight: 500;
  color: var(--fc-blue);
}
.dark-mode .btn-mark-all-as-read {
  color: var(--text-dark-blue);
  &:hover {
    color: var(--text-dark);
  }
}
.notification-header-sub {
  color: var(--text-dark-blue) !important;
  height: 50px;
  border-bottom: solid 1px var(--border-color);
}
.notification-header-sub-text {
  color: var(--text-dark-blue) !important;
  font-weight: 700;
  font-size: 13px;
}
.mute {
  font-size: 20px;
  width: 20px;
}

.bell-icon {
  color: #b8b8b8;
  font-size: 21px;
}

.bell-slash-icon {
  color: #c7c7c7ad;
  width: 30px;
  font-size: 21px;
}
</style>
