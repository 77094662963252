<template>
  <DialogModal
    class="mini-admin-add-new-user-modal"
    :stick-top="true"
    :visible="visible"
    :title="$t('Add new user')"
    :contain-form="true"
    :buttons="[
      {
        label: $t('Close'),
        outline: true,
        callback: function () {
          close();
        },
      },
      {
        label: $t('Add User'),
        callback: function () {
          inviteUser();
        },
      },
    ]"
    @close="close"
    @keyup.escape="close"
  >
    <Loader :loading="isLoading" full />

    <form>
      <div class="invite-user">
        <transition name="fade">
          <InfoBox v-if="errorMessage != ''"> {{ errorMessage }} </InfoBox>
        </transition>

        <InputBox
          id="username"
          v-model="form.username"
          :label="$t('Username')"
          :placeholder="$t('Type an username')"
          :errors="errors"
          focus
        />

        <InputBox
          id="email"
          v-model="form.email"
          :label="$t('Email')"
          :placeholder="$t('Type an email')"
          :errors="errors"
        />
      </div>
    </form>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal.vue';
import InputBox from 'common/components/InputBox.vue';
import Loader from 'common/components/Loader.vue';
import InfoBox from 'common/components/InfoBox.vue';

export default {
  components: {
    DialogModal,
    InputBox,
    Loader,
    InfoBox,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      errorMessage: '',
      form: {
        email: '',
        username: '',
      },
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.loading['miniAdmin/inviteUser'];
    },
  },
  watch: {
    visible(isVisible) {
      if (isVisible) {
        // reset data
        this.errors = {};
        this.errorMessage = '';

        this.form = {
          email: '',
          username: '',
        };
      }
    },
  },
  methods: {
    close() {
      this.$store.dispatch('miniAdmin/showAddUserModal', false);
    },
    async inviteUser() {
      this.errors = {};
      this.errorMessage = '';

      const res = await this.$store.dispatch('miniAdmin/inviteUser', {
        status: 1,
        ...this.form,
      });

      if (!res.ok) {
        if (typeof res.error !== 'string') {
          this.errors = res.error;
        } else {
          this.errorMessage = res.error;
        }
      } else {
        this.$toast.open({
          message: this.$t('Successfully invited the user'),
        });
        this.refreshStats();
      }
    },
    async refreshStats() {
      await this.$store.dispatch('miniAdmin/refreshStats');
    },
  },
};
</script>

<style lang="scss">
.mini-admin-add-new-user-modal {
  &.dialog-modal-overlay {
    z-index: 10002;

    .dialog-modal-outer {
      max-width: 470px !important;

      form {
        div.invite-user {
          h5 {
            font-size: 16px;
            font-weight: bold;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            color: var(--text-muted);
          }
        }
      }
    }
  }
}
</style>
