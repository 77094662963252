<template>
  <div class="zip-viewer-password-prompt">
    <FileIcon name="zip" class="zip-viewer-password-prompt__icon" />
    <DialogModal
      v-if="open"
      class="dialog-modal-overlay stick-top dialog-modal-form timed-fade"
      size="small"
      z-index="999999"
      :buttons="actionButtons"
      :class="[{ 'dialog-modal-visible': open }]"
      :stick-top="false"
      :title="$t('zip_preview.encrypted_zip.modal_title')"
      :visible="open"
      @close="cancel"
      @keyup="$emit('keyup', $event)"
    >
      <form autocomplete="off" @submit.prevent="openZipFile">
        <section>
          <div class="row">
            <div class="col-md-12">
              <div>
                <InputBox
                  id="password"
                  v-model="password.value"
                  class="p-0"
                  ondrop="return false;"
                  onpaste="return false;"
                  :focus="true"
                  :appends="passwordAppends(password)"
                  :aria-label="
                    $t('zip_preview.encrypted_zip.password_placeholder')
                  "
                  :auto-complete="false"
                  :label="$t('zip_preview.encrypted_zip.password_placeholder')"
                  :placeholder="
                    $t('zip_preview.encrypted_zip.password_placeholder')
                  "
                  :type="password.type"
                  :errors="error"
                />
              </div>
            </div>
          </div>
        </section>
        <p v-if="error" class="text-danger m-0 p-0">{{ $tc(error) }}</p>
      </form>
    </DialogModal>
  </div>
</template>

<script>
import DialogModal from '../DialogModal';
import InputBox from '../InputBox';
import FileIcon from '../FileIcon';

export default {
  name: 'ZipViewerPasswordModal',
  components: {
    DialogModal,
    InputBox,
    FileIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      password: {
        value: '',
        type: 'password',
      },
    };
  },
  computed: {
    actionButtons() {
      return [
        {
          label: this.$t('zip_preview.encrypted_zip.cancel_button'),
          outline: true,
          callback: this.cancel,
        },
        {
          label: this.$t('zip_preview.encrypted_zip.confirm_button'),
          disabled: !this.canSubmit,
          callback: this.openZipFile,
          primary: true,
        },
      ];
    },
    canSubmit() {
      return this.password.value.length > 0;
    },
  },
  watch: {
    'password.value': function (password) {
      if (password.length > 0) {
        this.clearAlert();
      }
    },
    error: function (error) {
      if (error.password) {
        this.password.value = '';
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('onClose');
    },
    openZipFile() {
      if (this.canSubmit) {
        this.$emit('onOpen', this.password.value);
      } else {
        this.$emit('update:error', {
          password: { message: 'Password is Empty' },
        });
      }
    },
    passwordAppends(input) {
      return [
        {
          icon: input.type === 'password' ? 'eye' : 'eye-slash',
          iconBold: true,
          action: () => this.togglePasswordType(input),
        },
      ];
    },
    togglePasswordType(input) {
      input.type = input.type === 'password' ? 'text' : 'password';
    },
    clearAlert() {
      this.$emit('update:error', {});
    },
  },
};
</script>
