var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{attrs:{"stick-top":true,"visible":true,"title":_vm.$t(`Filecloud Shortcut keys`),"class-name":'shortcut-dialog',"buttons":[
    {
      label: this.$t('All shortcuts'),
      link: true,
      outline: false,
      left: true,
      hasConfirm: false,
      callback: () => _vm.goToDocs(),
    },
    {
      label: 'OK',
      outline: true,
      callback: () => {
        this.$emit('close');
      },
    },
  ]},on:{"close":function($event){return _vm.$emit('close')}}},[_c('InputBox',{staticClass:"search-shortcut",attrs:{"placeholder":_vm.searchPlaceholder,"aria-label":_vm.searchPlaceholder,"auto-complete":false,"prepends":_vm.searchPrepends,"form-size":"sm"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}}),_c('div',{staticClass:"shortcut-wrapper"},_vm._l((_vm.filteredList),function(item,id){return _c('div',{key:id,staticClass:"shortcut"},[_c('div',{staticClass:"keys"},[_vm._v(" "+_vm._s(item.key)+" ")]),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(item.value)+" ")])])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }