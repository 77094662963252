<template>
  <div>
    <input
      v-model="field.value"
      :disabled="metadata.write !== 1 || readOnly"
      type="text"
      class="meta-input form-control form-control-sm mb-2"
      :required="metadata.required === 1"
      @input="onFieldChange()"
    />
  </div>
</template>

<script>
import dataTypeMixin from './_dataTypeMixin';

export default {
  mixins: [dataTypeMixin],
  methods: {
    onFieldChange() {
      let changed = this.field.value != this.field.oldValue;
      this.$emit('onFieldChange', {
        id: this.field.attributeid,
        changed,
        name: 'text',
      });
    },
  },
};
</script>
