<template>
  <button
    id="refresh"
    title="refresh button"
    class="action-btn zip-viewer-action-button"
    :style="{ bottom: `${bottom}px` }"
    @click="$emit('click')"
  >
    <Icon :name="iconName" />
  </button>
</template>

<script>
import { mapState } from 'vuex';
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    iconName: {
      type: String,
      default: 'plus',
    },
  },
  computed: {
    ...mapState('files', {
      transactionsBar: 'transactionsBar',
    }),
    bottom() {
      return this.transactionsBar.visible ? 90 : 40;
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btn {
  background-color: var(--fc-blue);
  box-shadow: 0 0 15px 0 rgba(black, 0.6);
  border: 0;
  border-radius: 100%;
  color: #fff;
  height: 60px;
  width: 60px;
  position: fixed;
  right: 20px;
  z-index: 9997;
  bottom: 40px;

  &:hover,
  &:active {
    animation: pulse; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 0.5s;
  }
}
</style>
