<template>
  <div></div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import _ from 'lodash';
import QueryBuilderChildren from './QueryBuilderChildren.vue';
import Vue from 'vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    QueryBuilderChildren,
  },

  props: {
    ruleTypes: Object,
    type: {
      type: String,
      default: 'query-builder-group',
    },
    query: Object,
    rules: Array,
    index: Number,
    maxDepth: Number,
    depth: Number,
    labels: Object,
  },

  data() {
    return {
      selectedRule: this.ruleTypes.operators[0],
    };
  },

  methods: {
    ruleById(ruleId) {
      var rule = null;

      this.ruleTypes.operators.forEach(function (value) {
        if (value.id === ruleId) {
          rule = value;
          return false;
        }
      });

      return rule;
    },

    addRule() {
      let updated_query = _.cloneDeep(this.query);
      let child = {
        type: 'query-builder-rule',
        query: {
          logicalOperator: this.labels.matchTypes[0].id,
          operator: this.ruleTypes.operators[0],
          operand: this.ruleTypes.operands[0],
          value: null,
          negation: false,
        },
      };
      updated_query.children.push(child);
      this.$emit('update:query', updated_query);
    },

    addGroup() {
      let updated_query = _.cloneDeep(this.query);
      if (this.depth < this.maxDepth) {
        updated_query.children.push({
          type: 'query-builder-group',
          logicalOperator: this.labels.matchTypes[0].id,
          query: {
            children: [],
            logicalOperator: '&&',
          },
        });
        this.$emit('update:query', updated_query);
      }
    },

    remove() {
      this.$emit('child-deletion-requested', this.index);
      Vue.nextTick(() => {
        this.$emit('change');
      });
    },

    removeChild(index) {
      let updated_query = _.cloneDeep(this.query);
      updated_query.children.splice(index, 1);
      this.$emit('update:query', updated_query);
      Vue.nextTick(() => {
        this.$emit('change');
      });
    },
  },
};
</script>
