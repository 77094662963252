<template>
  <div
    :class="{
      [`transactions-container--visible`]: bar.visible,
      [`transactions-container--expanded`]: bar.expanded,
    }"
  >
    <div class="transactions-wrapper">
      <div
        class="transactions-header"
        :class="{
          [`transactions-header--minified`]: bar.visible && !bar.expanded,
        }"
        @click="toggleMinification($event, true)"
      >
        <div v-if="runningTransactions.total.length > 0" class="spinner-border">
          <span class="sr-only">{{ $t('Loading...') }}</span>
        </div>
        <span
          v-if="runningTransactions.total.length === 0"
          class="transactions-header-title"
        >
          {{ $t('File Operations') }}
          <Icon
            v-if="usingAdBlocker"
            v-tooltip="{
              content: $t(
                'Looks like you are using an adblocker. Please disable it as it might interfere with file operations.'
              ),
              classes: ['blocker-warning-tooltip'],
            }"
            family="fas"
            class="blocker-warning"
            name="exclamation-triangle"
          />
        </span>
        <ul
          v-else-if="runningTransactions.total.length === 1 && !bar.expanded"
          class="transactions-list transactions-header-running"
          :set="(transaction = runningTransactions.total[0])"
        >
          <component
            :is="`${camelCase(transaction.type)}Transaction`"
            :current-path="currentPath"
            :transaction="transaction"
          />
        </ul>
        <span v-else class="transactions-header-title">
          {{
            $tc(
              'Processing {count} item | Processing {count} items',
              runningTransactions.total.length
            )
          }}
          <a v-if="!bar.expanded && !isMobile" class="ml-2">{{ $t('View') }}</a>
        </span>

        <div class="transactions-header-actions">
          <button
            :aria-label="
              bar.expanded
                ? $t('Minimize file operations')
                : $t('Expand file operations')
            "
            class="btn btn-icon btn-minimize shadow-none"
            :class="{ expanded: !bar.expanded }"
            @click="toggleMinification"
          >
            <Icon family="fas" :name="bar.expanded ? 'minus' : 'caret-up'" />
          </button>
          <button
            class="btn btn-icon btn-close shadow-none"
            :class="{ expanded: !bar.expanded }"
            :aria-label="
              $t(
                'Close file operations. Use P key to open/close file operations dialog'
              )
            "
            @click="toggle"
          >
            <Icon family="fas" name="times" />
          </button>
        </div>
        <div
          v-if="bar.expanded && !isMobile"
          class="transactions-header-section"
        >
          <ul>
            <li :class="{ active: bar.currentSection === 'upload' }">
              <a href="#" @click="switchSection('upload')"
                ><Icon family="fas" name="upload" /> {{ $t('Upload') }}
                <span
                  v-if="transactions.upload && transactions.uploadTotal > 0"
                >
                  {{ totalTransactions }}
                </span>
              </a>
            </li>
            <li :class="{ active: bar.currentSection === 'others' }">
              <a href="#" @click="switchSection('others')"
                ><Icon family="fas" name="file-alt" /> {{ $t('Other') }}
                <span v-if="transactions.others && transactions.othersTotal > 0"
                  >({{
                    transactions.othersTotal -
                    runningTransactions.others.length
                  }}/{{ transactions.othersTotal }})</span
                ></a
              >
            </li>
          </ul>
          <div class="form-group form-group--left-icon">
            <Icon family="fal" name="filter" />
            <input
              v-model="query"
              type="text"
              class="form-control form-control-sm"
              :placeholder="$t('Filter items')"
              :aria-label="$t('Filter items')"
            />
          </div>
        </div>
        <div
          v-if="bar.expanded && !isMobile"
          class="transactions-header-section"
        >
          <span
            v-if="query === '' && bar.currentSection === 'upload'"
            class="transactions-header-status"
            role="alert"
          >
            {{
              $tc(
                'Uploading {count} item | Uploading {count} items',
                runningTransactions.upload.length
              )
            }}
          </span>
          <span
            v-else-if="query === ''"
            class="transactions-header-status"
            role="status"
          >
            {{
              $tc(
                'Processing {count} item | Processing {count} items',
                runningTransactions[bar.currentSection]
                  ? runningTransactions[bar.currentSection].length
                  : 0
              )
            }}
          </span>
          <span
            v-else-if="transactions[bar.currentSection]"
            class="transactions-header-status"
          >
            {{ transactions[bar.currentSection].length }}
            {{ $t('Results for') }} "{{ query }}"
          </span>
          <span v-else class="transactions-header-status">
            "{{ $t('No results for') }} "{{ query }}"
          </span>
          <div class="form-group">
            <select
              v-model="filter"
              class="form-control form-control-sm"
              :aria-label="$t('Filter by')"
            >
              <option value="all">{{ $t('Show all') }}</option>
              <option value="processing">{{ $t('Show processing') }}</option>
              <option value="failed">{{ $t('Show failed') }}</option>
            </select>
          </div>
          <div class="ml-1">
            <button
              v-if="runningTransactions.upload.length > 0"
              class="btn btn-light btn-transaction-all"
              @click="cancelAllTransactions"
            >
              <Icon family="fas" name="ban" />
              {{ $t('Stop All') }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="bar.expanded" class="transactions-body">
        <div
          v-if="
            !transactions[bar.currentSection] ||
            (transactions[bar.currentSection] &&
              transactions[bar.currentSection].length === 0)
          "
          class="empty-box"
        >
          <Icon family="fas" name="file-alt" />
          <span>{{ $t('No file operations found') }}</span>
        </div>
        <ul v-else class="transactions-list">
          <RecycleScroller
            v-if="transactions[bar.currentSection].length"
            v-slot="{ item: transaction }"
            class="scroller-with-filter"
            key-field="id"
            :items="transactions[bar.currentSection]"
            :item-size="itemSize"
          >
            <component
              :is="`${camelCase(transaction.type)}Transaction`"
              :current-path="currentPath"
              :transaction="transaction"
            >
              <div v-if="transaction.item">
                <button
                  v-if="!isMobile"
                  v-tooltip="$t('Open Folder')"
                  class="btn btn-icon shadow-none"
                  :aria-label="$t('Open folder')"
                  @click="
                    browse(
                      transaction.targetPath
                        ? transaction.targetPath
                        : transaction.dirpath
                    )
                  "
                >
                  <Icon name="folder-open" family="fal" />
                  <Icon class="icon-overlay" name="folder-open" family="fas" />
                </button>

                <button
                  v-tooltip="$t('Preview')"
                  class="btn btn-icon shadow-none"
                  :aria-label="$t('Preview item')"
                  @click="preview(transaction)"
                >
                  <Icon family="fal" name="eye" />
                  <Icon class="icon-overlay" name="eye" family="fas" />
                </button>

                <button
                  v-if="isOffice(transaction.item.name) && !isMobile"
                  v-tooltip="
                    canEdit
                      ? customization.WOPICLIENT === 'CODE' ||
                        customization.WOPICLIENT === 'ONLYOFFICE' ||
                        !canEditFileType(transaction.item.ext, 'office')
                        ? $t('Edit Online')
                        : `<i class='office-icon'></i> ${$t(
                            'Open in Office Online'
                          )}`
                      : $t('Web Edit is disabled')
                  "
                  class="btn btn-icon shadow-none"
                  :disabled="!canEdit"
                  :aria-label="
                    canEdit
                      ? customization.WOPICLIENT === 'CODE' ||
                        customization.WOPICLIENT === 'ONLYOFFICE' ||
                        !canEditFileType(transaction.item.ext, 'office')
                        ? $t('Edit Online')
                        : `<i class='office-icon'></i> ${$t(
                            'Open in Office Online'
                          )}`
                      : $t('Web Edit is disabled')
                  "
                  @click="quickEditOffice(transaction.item)"
                >
                  <Icon family="fal" name="edit" />
                  <Icon class="icon-overlay" name="edit" family="fas" />
                </button>
                <button
                  v-else-if="!isMobile"
                  v-tooltip="
                    canEdit && isEditable(transaction.item.name)
                      ? $t('Edit Online')
                      : $t('Web Edit is disabled')
                  "
                  :aria-label="
                    canEdit && isEditable(transaction.item.name)
                      ? $t('Edit Online')
                      : $t('Web Edit is disabled')
                  "
                  class="btn btn-icon shadow-none"
                  :disabled="!isEditable(transaction.item.name)"
                  @click="quickEditFile(transaction.item)"
                >
                  <Icon family="fal" name="edit" />
                  <Icon class="icon-overlay" name="edit" family="fas" />
                </button>

                <button
                  v-tooltip="$t('Share')"
                  :aria-label="$t('Share item')"
                  class="btn btn-icon shadow-none"
                  :disabled="!transaction.item.isshareable"
                  @click="shareItem(transaction.item)"
                >
                  <Icon family="fal" name="share-alt" />
                  <Icon class="icon-overlay" name="share-alt" family="fas" />
                </button>

                <v-popover
                  @show="
                    () => {
                      isDeleteAlertEnabled = true;
                    }
                  "
                  @hide="
                    () => {
                      isDeleteAlertEnabled = false;
                    }
                  "
                >
                  <button
                    v-tooltip="$t('Delete')"
                    class="btn btn-icon shadow-none"
                    :aria-label="$t('Delete item')"
                    :disabled="!transaction.item.canupload"
                    @click="() => {}"
                  >
                    <Icon family="fal" name="trash-alt" />
                    <Icon class="icon-overlay" name="trash-alt" family="fas" />
                  </button>

                  <Alert
                    slot="popover"
                    button-type="danger"
                    :visible="isDeleteAlertEnabled"
                    :buttons="[
                      {
                        label: `Delete`,
                        callback: function () {
                          doDeleteFile(transaction);
                        },
                      },
                      {
                        label: `Cancel`,
                        outline: true,
                        callback: function () {},
                      },
                    ]"
                  >
                    <span role="alert">
                      {{
                        $t('Are you sure you want to delete {fileName}?', {
                          fileName: transaction.item.name,
                        })
                      }}
                    </span>
                  </Alert>
                </v-popover>

                <button
                  v-if="isMobile || isTablet"
                  v-tooltip="$t('More')"
                  :aria-label="$t('More')"
                  class="btn btn-sm btn-icon"
                  @click="openMobileMenu(transaction.item)"
                >
                  <Icon name="ellipsis-v" family="fas" />
                </button>
              </div>
              <div>
                <button
                  v-for="action in transaction.actions"
                  :key="action.name"
                  v-tooltip="$t(action.name)"
                  class="btn btn-icon shadow-none"
                  :aria-label="$t(action.label)"
                  @click="action.callback()"
                >
                  <Icon family="fal" :name="action.icon" />
                  <Icon class="icon-overlay" :name="action.icon" family="fas" />
                </button>
              </div>

            </component>
          </RecycleScroller>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import fileActions from '../constants/fileActions';
import transactionsMixin from '../mixins/transactionsMixin';

export default {
  mixins: [transactionsMixin],
  mounted() {
    this.setTransactions();
  },
  data() {
    return {
      currentPath: `/${this.$router.history.current.params.pathMatch}`,
      fileActions,
      isDeleteAlertEnabled: false,
      itemSize: 62,
    };
  },
  computed: {
    usingAdBlocker() {
      return this.$store.state.core.usingAdBlocker;
    },
    totalTransactions() {
      let total = 0;
      if (this.filter === 'failed') {
        total = this.transactions.upload.filter(
          (transaction) => transaction.status === 'failed'
        ).length;
      } else {
        total =
          this.transactions.uploadTotal -
          this.runningTransactions.upload.length;
      }
      return `${total} / ${this.transactions.uploadTotal}`;
    },
  },
  watch: {
    // listen to history
    $route(to) {
      this.currentPath = `/${to.params.pathMatch}`;
    },
  },
};
</script>
