<template>
  <div
    ref="previewWrapper"
    :class="{
      ['file-preview--active']: isActive,
    }"
    class="file-preview file-preview--nested"
  >
    <FilePreviewHeader
      v-if="isActive"
      :file="currentFile"
      :has-next="hasNext"
      :has-previous="hasPrevious"
      :is-single-file-view="isSingleFileView"
      @close="onClose"
      @next="onNextItem"
      @prev="onPreviousItem"
      @download="onDownload"
    />
    <FilePreviewBody :file="currentFile" @download="onDownload" />
  </div>
</template>

<script>
import { createFocusTrap } from 'focus-trap';

import FilePreviewHeader from './FilePreviewHeader.vue';
import FilePreviewBody from './FilePreviewBody.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    FilePreviewHeader,
    FilePreviewBody,
  },
  data() {
    return {
      isActive: false,
      items: null,
      singleItem: {},
      index: 0,
    };
  },
  computed: {
    currentFile() {
      if (this.index !== null) {
        return this.items && this.items[this.index]
          ? this.items[this.index]
          : null;
      } else {
        return this.singleItem;
      }
    },
    isSingleFileView() {
      return this.index === null;
    },
    hasNext() {
      return !this.isSingleFileView && this.index < this.items?.length - 1;
    },
    hasPrevious() {
      return !this.isSingleFileView && this.index > 0;
    },
  },
  mounted() {
    this.$zippreview.subscription.$on('preview', this.preview.bind(this));
  },
  destroyed() {
    this.$zippreview.subscription.$off('preview', this.preview.bind(this));
  },
  methods: {
    ...mapActions('zipViewer', {
      dispatchDownloadItem: 'downloadItem',
    }),
    preview(items, index) {
      if (Array.isArray(items)) {
        this.items = items;
        this.index = index;
        this.singleItem = {};
      } else if (typeof items === 'object') {
        this.singleItem = items;
        this.index = null;
      }
      this.isActive = true;
      this.handleFocusTrapInit();
    },
    reset() {
      this.isActive = false;
      this.items = null;
      this.singleItem = {};
      this.index = 0;
    },
    onClose() {
      this.reset();
      this.focusTrap.deactivate();
    },
    onDownload() {
      this.dispatchDownloadItem({ item: this.currentFile });
    },
    onPreviousItem() {
      if (this.hasPrevious) {
        this.index = this.index - 1;
      }
    },
    onNextItem() {
      if (this.hasNext) {
        this.index = this.index + 1;
      }
    },
    handleFocusTrapInit() {
      this.$nextTick(() => {
        try {
          if (!this.focusTrap) {
            this.focusTrap = createFocusTrap(this.$el, {
              allowOutsideClick: true,
              escapeDeactivates: false,
            });
          } else {
            this.focusTrap.updateContainerElements(this.$el);
          }
          this.focusTrap.activate();
        } catch (e) {
          console.log('failed to create focus trap');
        }
      });
    },
  },
};
</script>
