import Vue from 'vue';
import Vuex from 'vuex';
import tourTopics from '../../tour/index.js';
import _ from 'lodash';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    isExpanded: true,
    isOnboardingAccepted: localStorage.getItem('onboarding-accept')
      ? true
      : false,
    triggers: {},
    tourTopics: [],
    currentSectionIndex: -1,
    currentTopicIndex: -1,
    currentStepIndex: -1,
    ready: false
  },
  mutations: {
    set: (state, payload) => {
      const { type, status } = payload;

      if (type && typeof status !== undefined) {
        Vue.set(state, type, status);
      }
    },
    
    setCompleted: (state, steps) => {
            
      Object.entries(state.tourTopics).forEach(([sectionKey, section]) => {
        Object.entries(section.topics).forEach(([topicKey, topic]) => {          
          // if is completed
          if(steps.indexOf(topic.id) > -1) {
            
            Vue.set(
              state.tourTopics[sectionKey].topics[
                topicKey
              ],
              'isCompleted',
              true
            );
            
          }
        })
      })
      
    },
 
    startStep: (state, payload) => {
      const { sectionIndex, topicIndex, stepIndex } = payload;

      Vue.set(state, 'currentSectionIndex', sectionIndex);
      Vue.set(state, 'currentTopicIndex', topicIndex);
      Vue.set(state, 'currentStepIndex', stepIndex);

      const section = state.tourTopics[sectionIndex];
      if (!section) return;

      const topic = section.topics[topicIndex];
      if (!topic) return;

      const step = topic.steps[stepIndex];
      if (!step) return;

      if (stepIndex === 0) {

        if (topic.onTopicStarted) {
          const exitStep = topic.onTopicStarted();
          if (exitStep) return;
        }
      }

      //registry trigger
      if (step.trigger && step.trigger.triggerOn && step.trigger.onTrigger) {
        Vue.set(state.triggers, step.trigger.triggerOn, step.trigger.onTrigger);
      }

      //trigger start callback from step
      if (step.onStepStart) step.onStepStart();
    },
    nextStep: (state, payload) => {
      const { step, topic } = payload;
      const isLastStep = state.currentStepIndex + 1 === topic.steps.length;

      //complete callback
      if (step.onStepCompleted) step.onStepCompleted();

      //unregistry trigger
      if (
        step.trigger &&
        step.trigger.triggerOn &&
        state.triggers[step.trigger.triggerOn]
      ) {
        Vue.delete(state.triggers, step.trigger.triggerOn);
      }

      //mark step as completed
      Vue.set(
        state.tourTopics[state.currentSectionIndex].topics[
          state.currentTopicIndex
        ].steps[state.currentStepIndex],
        'isCompleted',
        true
      );

      if (isLastStep) {
        
        //complete topic and start next topic/section
        if (topic.onTopicCompleted) topic.onTopicCompleted();

        Vue.set(
          state.tourTopics[state.currentSectionIndex].topics[
            state.currentTopicIndex
          ],
          'isCompleted',
          true
        );

        Vue.set(state, 'currentSectionIndex', -1);
        Vue.set(state, 'currentTopicIndex', -1);
        Vue.set(state, 'currentStepIndex', -1);

        //TODO - START NEXT TOPIC OR SECTION
      } else {
        //start next step
        Vue.set(state, 'currentStepIndex', state.currentStepIndex + 1);

        const nextStep = topic.steps[state.currentStepIndex];

        //registry trigger
        if (
          nextStep.trigger &&
          nextStep.trigger.triggerOn &&
          nextStep.trigger.onTrigger
        ) {
          Vue.set(
            state.triggers,
            nextStep.trigger.triggerOn,
            nextStep.trigger.onTrigger
          );
        }

        //trigger start callback from step
        if (nextStep.onnextStepStart) step.onStepStart();
      }
    },

    updateStep: (state, payload) => {
      const { id } = payload;
      const [onboarding, sectionName, topicName] = id.split('.');
      const sectionId = [onboarding, sectionName].join('.');
      const topicId = [onboarding, sectionName, topicName].join('.');
      const stepId = id;

      const sectionIndex = _.findIndex(state.tourTopics, { id: sectionId });
      if (sectionIndex === -1) return;
      const section = state.tourTopics[sectionIndex];

      const topicIndex = _.findIndex(section.topics, { id: topicId });
      if (topicIndex === -1) return;
      const topic = section.topics[topicIndex];

      const stepIndex = _.findIndex(topic.steps, { id: stepId });
      if (stepIndex === -1) return;
      let step = topic.steps[stepIndex];

      step = { ...step, ...payload };

      Vue.set(
        state.tourTopics[sectionIndex].topics[topicIndex].steps,
        stepIndex,
        step
      );
    },

    goToStep: (state, payload) => {
      const { id } = payload;
      const [onboarding, sectionName, topicName] = id.split('.');
      const sectionId = [onboarding, sectionName].join('.');
      const topicId = [onboarding, sectionName, topicName].join('.');
      const stepId = id;

      const sectionIndex = _.findIndex(state.tourTopics, { id: sectionId });
      if (sectionIndex === -1) return;
      const section = state.tourTopics[sectionIndex];

      const topicIndex = _.findIndex(section.topics, { id: topicId });
      if (topicIndex === -1) return;
      const topic = section.topics[topicIndex];

      const stepIndex = _.findIndex(topic.steps, { id: stepId });
      if (stepIndex === -1) return;
      const step = topic.steps[stepIndex];

      Vue.set(state, 'currentSectionIndex', sectionIndex);
      Vue.set(state, 'currentTopicIndex', topicIndex);
      Vue.set(state, 'currentStepIndex', stepIndex);

      if (step.trigger && step.trigger.triggerOn && step.trigger.onTrigger) {
        Vue.set(state.triggers, step.trigger.triggerOn, step.trigger.onTrigger);
      }

      if (step.onStepStart) step.onStepStart();
    },
    
    cancelStep: (state, payload) => {
      
      Vue.set(state, 'currentSectionIndex', -1);
      Vue.set(state, 'currentTopicIndex', -1);
      Vue.set(state, 'currentStepIndex', -1);
      
    },
  },
  actions: {
    
    init(context) {
      
      try {
        
        const { customization, systemstatus } = this.state.core;
                
        const enabledTopics = tourTopics.sections.filter((item) => {
          return !item.disabled || !item.disabled({ customization, systemstatus });
        })
        
        context.commit('set', {
          type: 'tourTopics',
          status: enabledTopics
        });
        
        const { INTRO_TOUR } = this.state.core.customization;
        
        if(INTRO_TOUR && INTRO_TOUR.completed && INTRO_TOUR.completed.length > 0) {
          context.commit('setCompleted', INTRO_TOUR.completed);
        }
        
      } catch (e) {
        console.error("Failed to load topics for intro", e);
      }

    },
    
    async set(context, payload) {
      
      let { INTRO_TOUR } = this.state.core.customization;
      INTRO_TOUR = _.pick(INTRO_TOUR, [ 'isHidden', 'isExpanded', 'isOnboardingAccepted', 'completed' ])      
            
      // set local
      this.commit('core/setCustomization', {
        key: 'INTRO_TOUR',
        value: {
          ...INTRO_TOUR,
          ...payload
        },
      });

      this.dispatch('core/setCustomizations', {
        name: 'INTRO_TOUR',
        value: {
          ...INTRO_TOUR,
          ...payload
        },
        remoteOnly: true
      });
            
    },
    
    openTour(context) {
      context.dispatch('set', {
        isHidden: false,
        isExpanded: true
      })
    },

    continueTour(context) {
      //get from API which section/topics are completed
      const firstUnconpletedSection = 0;
      const firstUncompletedTopic = 0;

      context.commit('startStep', {
        sectionIndex: firstUnconpletedSection,
        topicIndex: firstUncompletedTopic,
        stepIndex: 0,
      });
    },

    startTopic(context, payload) {
      const { section, topic } = payload;

      const sectionIndex = _.findIndex(context.state.tourTopics, {
        id: section.id,
      });
      if (sectionIndex === -1) return;

      const topicIndex = _.findIndex(
        context.state.tourTopics[sectionIndex].topics,
        { id: topic.id }
      );
      if (topicIndex === -1) return;
      
      context.dispatch('set', {
        isHidden: false,
        isExpanded: false 
      })
      
      context.commit('cancelStep', false);
      
      context.commit('startStep', {
        sectionIndex,
        topicIndex,
        stepIndex: 0,
      });
    },
    
    skipTopic(context, payload) {
      const { section, topic } = payload;
      context.dispatch('cancelTopic', false);
      
      const { INTRO_TOUR } = this.state.core.customization;

      let completed = [];
      if(INTRO_TOUR.completed && INTRO_TOUR.completed.indexOf && INTRO_TOUR.completed.indexOf(topic.id) === -1) {
        completed = [ ...INTRO_TOUR.completed, topic.id ];
      } else if(INTRO_TOUR.completed && INTRO_TOUR.completed.indexOf && INTRO_TOUR.completed.indexOf(topic.id) > -1) {
        completed = [ ...INTRO_TOUR.completed ];
      } else if(!INTRO_TOUR.completed) {
        completed = [ topic.id ];
      }
      
      context.dispatch('set', {
        completed
      })
      
      context.commit('setCompleted', [ topic.id ])
      
    },
    
    cancelTopic(context, shouldSet = true) {
      context.commit('cancelStep');
      if(shouldSet) {
        context.dispatch('set', { isExpanded: true });
      }
    },

    onboardingTrigger(context, mutation) {
      const { type, payload } = mutation;

      if (!type || !context.state.triggers[type]) return;

      let isStepCompleted = context.state.triggers[type](payload);

      if (isStepCompleted) {
        context.dispatch('nextStep');
      }
    },

    nextStep(context) {
      const topic = context.getters.getCurrentTopic;
      const step = context.getters.getCurrentStep;

      // if step is completed
      if(context.state.currentStepIndex + 1 === topic.steps.length) {
        
        const { INTRO_TOUR } = this.state.core.customization;

        let completed = [];
        if(INTRO_TOUR.completed && INTRO_TOUR.completed.indexOf && INTRO_TOUR.completed.indexOf(topic.id) === -1) {
          completed = [ ...INTRO_TOUR.completed, topic.id ];
        } else if(INTRO_TOUR.completed && INTRO_TOUR.completed.indexOf && INTRO_TOUR.completed.indexOf(topic.id) > -1) {
          completed = [ ...INTRO_TOUR.completed ];
        } else if(!INTRO_TOUR.completed) {
          completed = [ topic.id ];
        }
        
        context.dispatch('set', {
          completed,
          isHidden: false,
          isExpanded: true
        })
      }
      
      context.commit('nextStep', { topic, step });

    },

    updateStep(context, payload) {
      context.commit('updateStep', payload);
    },
  },
  getters: {
    getTopic: (state) => (topicId) => {
      return _.find(state.tourTopics.topics, { id: topicId });
    },
    getCurrentSection: (state) => {
      const section = state.tourTopics[state.currentSectionIndex];
      return section;
    },
    getCurrentTopic: (state) => {
      const section = state.tourTopics[state.currentSectionIndex];
      if (!section) return;

      return section.topics[state.currentTopicIndex];
    },
    getCurrentStep: (state) => {
      const section = state.tourTopics[state.currentSectionIndex];
      if (!section) return;

      const topic = section.topics[state.currentTopicIndex];
      if (!topic) return;

      return topic.steps[state.currentStepIndex];
    },
    getPreviousStep: (state) => {
      const section = state.tourTopics[state.currentSectionIndex];
      if (!section) return;

      const topic = section.topics[state.currentTopicIndex];
      if (!topic) return;

      return topic.steps[state.currentStepIndex - 1];
    },
    getWizardProgress: (state) => {
      let topicsCount = 0;
      let topicsCompleted = 0;
      
      state.tourTopics.forEach((section) => {
        topicsCount += section.topics.length;
        section.topics.forEach((topic) => {
          if (topic.isCompleted) topicsCompleted++;
        });
      });

      if (topicsCount === 0) return 1;
      if (topicsCompleted === 0) return 0;
      return topicsCompleted / topicsCount;
    },
  },
};
