Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};


// smoothly scroll window to coordinate
export function scrollTo(to, duration, callback) {

    // clear old timeout
    clearTimeout(window.scrollTimeout);

    const element = document.scrollingElement;
    const start = (element && element.scrollTop) || window.pageYOffset,
        change = to - start,
        increment = 20;
    let currentTime = 0;

    const animateScroll = function(){
        currentTime += increment;
        const val = Math.easeInOutQuad(currentTime, start, change, duration);
        window.scrollTo(0, val);
        if(currentTime < duration) {
            window.scrollTimeout = window.setTimeout(animateScroll, increment);
        }
        if(currentTime === duration) {
          callback();
        }
    };
    animateScroll();
}
