<template>
  <VPopover
    ref="popover"
    :popover-class="popoverClass"
    :class="'sample'"
    :container="container"
    :placement="placement"
    :offset="offset"
    :open="open"
    @show="
      () => {
        this.isOpen = true;
        $emit('show', true);
      }
    "
    @hide="
      () => {
        this.isOpen = false;
        $emit('hide', true);
      }
    "
    @keydown.space.native="handleKeydownOpen"
    @keydown.enter.native="handleKeydownOpen"
  >
    <slot>
      <div ref="trigger">
        <Icon
          :name="triggerBtn"
          :family="triggerIconFamily"
          :class="triggerBtnClass"
          :aria-label="labelAria"
          tabindex="0"
          role="button"
          @keydown.space.native="handleKeydownOpen"
          @keydown.enter.native="handleKeydownOpen"
          @focus.native="$emit('focus')"
          @blur.native="$emit('blur')"
        />
      </div>
    </slot>
    <template slot="popover">
      <div ref="dropdown" class="dropdown-wrapper">
        <div v-for="(item, index) in listItems">
          <div v-if="item.isTitle === true" class="dropdown-item-title">
            {{ item.name }}
          </div>
          <div
            v-else-if="
              !((target && item.hidden && item.hidden(target)) || item.isTitle)
            "
            :key="index"
            class="dropdown-item"
            role="button"
            tabindex="0"
            :class="{
              'has-seperator':
                item.seperator === true ||
                (item.conditionalSeperator &&
                  item.conditionalSeperator(target)),
              'dropdown-item--disabled':
                target && item.disabled && item.disabled(target),
              [`dropdown-item-focus-${index}`]: true,
              [item.id]: item.id,
            }"
            @click="triggerAction(item.action, item[paramName])"
            @keydown.space.prevent="triggerAction(item.action, item[paramName])"
            @keydown.enter.prevent="triggerAction(item.action, item[paramName])"
          >
            <Icon
              v-if="item.icon"
              :name="item.icon"
              :family="item.iconFamily || 'fal'"
            />
            <span class="popover-text"
              >{{
                $t(
                  typeof item.name === 'string' ? item.name : item.name(target)
                )
              }}
              <span
                v-if="item.shortKey"
                class="shortkey float-right text-capitalize"
              >
                {{ item.shortKey }}</span
              >
            </span>
            <div
              v-if="item.counter"
              class="dropdown-item__counter badge badge-primary"
            >
              {{ item.counter }}
            </div>
          </div>
        </div>
        <slot name="after"></slot>
      </div>
    </template>
  </VPopover>
</template>

<script>
import { VPopover } from 'v-tooltip';
import Icon from './Icon';
import { createFocusTrap } from 'focus-trap';
import { isMobileDevice } from '../utils/responsive.js';

export default {
  name: 'DropDownList',
  components: {
    VPopover,
    Icon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    target: {
      type: Object,
      default: () => {},
    },
    offset: {
      type: Number,
      default: 3,
    },
    placement: {
      type: String,
      default: 'bottom',
    },
    labelAria: {
      type: String,
      default: '',
    },
    paramName: {
      type: String,
      default: '',
    },
    actionName: {
      type: String,
      default: '',
    },
    hasCustomList: {
      type: Boolean,
      default: false,
      required: false,
    },
    open: {
      type: Boolean,
      default: false,
      required: false,
    },
    triggerBtn: {
      type: String,
      default: 'plus',
    },
    dropdownClass: {
      type: String,
      default: '',
    },
    triggerBtnClass: {
      type: String,
      default: 'trigger-btn',
    },
    triggerIconFamily: {
      type: String,
      default: 'fas',
    },
    container: {
      type: String,
      default: 'body',
    },
  },
  data() {
    return {
      isOpen: false,
      focusTrap: null,
    };
  },
  beforeDestroy() {
    if (this.focusTrap) this.focusTrap.deactivate();
  },
  computed: {
    listItems() {
      return this.items.filter((item) => {
        return this.hasCustomList ? !item.listOnly : item;
      });
    },
    popoverClass() {
      return 'dropdown ' + this.dropdownClass;
    },
    isMobileDevice,
  },
  watch: {
    isOpen(oppened) {
      this.$emit('change', oppened);
      if (oppened && !this.isMobileDevice) {
        this.$emit('change', true);
        this.handleFocusTrapInit();
      } else {
        if (this.focusTrap) this.focusTrap.deactivate();
      }
    },
  },
  methods: {
    triggerAction(action, param) {
      this.$refs.popover.hide();

      if (this.actionName) {
        this.$emit('triggerDDAction', this.actionName, param);
      } else {
        this.$emit('triggerDDAction', action, param);
      }
    },
    handleFocusTrapInit() {
      // timeout needed due to v-popover delay
      setTimeout(() => {
        if (!this.focusTrap) {
          const initialFocus = this.listItems.length
            ? '.dropdown-wrapper [class*="dropdown-item-focus-"]'
            : undefined;

          this.focusTrap = createFocusTrap(this.$refs.dropdown, {
            allowOutsideClick: true,
            escapeDeactivates: false,
            returnFocusOnDeactivate: false,
            initialFocus: initialFocus,
          });
        } else {
          this.focusTrap.updateContainerElements(this.$refs.dropdown);
        }
        this.focusTrap.activate();
      }, 400);
    },
    handleKeydownOpen(event) {
      event.stopPropagation();
      event.preventDefault();
      event.target.click();
    },
  },
};
</script>
