var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-list-header",class:{
    'file-list-header--fixed': _vm.sticky && !_vm.isMobile && !_vm.isTablet,
  },attrs:{"role":"row"}},[_c('div',{directives:[{name:"a11ybutton",rawName:"v-a11ybutton",value:(_vm.onSelectAll),expression:"onSelectAll"}],staticClass:"col-checkbox",class:{
      'col-checkbox--grid': _vm.getIsGrid,
    },attrs:{"tabindex":"0","role":"cell"},on:{"click":_vm.onSelectAll}},[_c('Checkbox',{attrs:{"id":"select-all","tabindex":"-1","checked":_vm.selectedFiles.length > 0 &&
        _vm.selectedFiles.length === _vm.actualFiles.length,"label":_vm.$t('Select all')}}),(_vm.getIsGrid)?_c('label',{staticClass:"select-all-label"},[_vm._v(" "+_vm._s(_vm.$t('Select All'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-title header",class:{
      'col-title--grid': _vm.getIsGrid,
      'col-10': _vm.isMobile || _vm.isTablet,
    },attrs:{"id":"genfilename","role":"columnheader","aria-label":"File or Folder name"}},[(!_vm.getIsGrid)?_c('span',{on:{"click":function($event){return _vm.onSort('name', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" "),_c('button',{staticClass:"btn btn-link p-0 mb-2"},[_c('span',{staticClass:"invisible"},[_vm._v("Enter to sort by Name")]),_c('Icon',{staticClass:"sortable",class:{
            desc: _vm.sort && _vm.sort.type === -1,
            hidden: _vm.sort && _vm.sort.field !== 'name',
          },attrs:{"aria-label":_vm.$t('Enter to sort by Name'),"tabindex":"0","role":"button","on-key-down":"onSort('name', $event)","name":"chevron-up"}})],1)]):_vm._e(),_c('span',{staticClass:"filter",class:{ 'col-9': _vm.isMobile || _vm.isTablet, 'filter--grid': _vm.getIsGrid }},[_c('form',{attrs:{"autocomplete":"off"}},[_c('InputBox',{staticClass:"filter-items",attrs:{"id":"filter-files","readonly":_vm.loading,"value":_vm.filterValue,"placeholder":_vm.$t('Filter Items'),"errors":_vm.errors,"prepends":_vm.filterInputPrepends,"form-size":"sm"},on:{"input":_vm.onFilter}})],1)])]),(_vm.getIsGrid)?_c('div',{staticClass:"d-none d-sm-flex align-items-center"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm.$t('Sort')))]),_c('v-select',{attrs:{"id":"sort-files","options":_vm.sortingOptions,"errors":_vm.errors,"value":_vm.dropdownSort,"label":"field","form-size":"sm"},on:{"input":_vm.onDropdownSort},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_c('i',{staticClass:"inline-icon far",class:[
            option.order === 'asc' ? 'fa-arrow-up' : 'fa-arrow-down',
          ]}),_vm._v("  "+_vm._s(_vm.upperFirst(option.field))+" ")]}},{key:"option",fn:function(option){return [_c('i',{staticClass:"inline-icon far",class:_vm.getSortIconClass(option.field)}),_vm._v("  "+_vm._s(_vm.upperFirst(option.field))+" ")]}}],null,false,2488484189)})],1):[_c('div',{staticClass:"d-none d-sm-flex align-items-center",attrs:{"id":"genmodifieddate","role":"columnheader"},on:{"click":function($event){return _vm.onSort('date', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('Modified'))+" "),_c('button',{staticClass:"btn btn-link p-0 mb-1",attrs:{"tabindex":"0","role":"button","onKeyDown":"onSort('date', $event)"}},[_c('Icon',{staticClass:"sortable",class:{
            desc: _vm.sort && _vm.sort.type === -1,
            hidden: _vm.sort && _vm.sort.field !== 'date',
          },attrs:{"aria-label":_vm.$t('Press enter to sort by modified date'),"name":"chevron-up"}})],1)]),_c('div',{staticClass:"d-none d-md-block",attrs:{"id":"gensize","role":"columnheader"},on:{"click":function($event){return _vm.onSort('size', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('Size'))+" "),_c('button',{staticClass:"btn btn-link p-0 mb-2",attrs:{"tabindex":"0","aria-label":_vm.$t('Press enter to sort by size'),"role":"button","onKeyDown":"onSort('size', $event)"}},[_c('Icon',{staticClass:"sortable",class:{
            desc: _vm.sort && _vm.sort.type === -1,
            hidden: _vm.sort && _vm.sort.field !== 'size',
          },attrs:{"name":"chevron-up"}})],1)])],(!_vm.msteams)?_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['v']),expression:"['v']"}],staticClass:"explorer-layout",attrs:{"role":"cell"},on:{"shortkey":() => _vm.toggleLayoutDropdown()}},[_c('DropDownList',{ref:"layoutDropdown",attrs:{"trigger-btn":_vm.getLayout.name,"trigger-icon-family":"fas","trigger-btn-class":"more-options","label-aria":"Press to toggle list/grid view","items":_vm.layoutButtons},on:{"triggerDDAction":_vm.onToggleGrid}})],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }