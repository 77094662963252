<template>
  <DialogModal
    :visible="isOpen"
    :title="
      isFile
        ? $t('share.modal.rename.file_title', { name: item.name })
        : $t('share.modal.rename.folder_title', { name: item.name })
    "
    color="gray"
    :stick-top="false"
    :buttons="[
      {
        label: $t('general.Ok'),
        callback: function () {
          closeModal();
        },
      },
      {
        label: $t('general.Cancel'),
        outline: true,
        callback: function () {
          isOpen = false;
        },
      },
    ]"
    @close="isOpen = false"
  >
    <div>
      <h4 v-if="isFile">{{ $t('share.modal.rename.warning_file_title') }}</h4>
      <h4 v-else>{{ $t('share.modal.rename.warning_folder_title') }}</h4>
      {{
        isFile
          ? $t('share.modal.rename.warning_file_description')
          : $t('share.modal.rename.warning_folder_description')
      }}
      <p class="d-flex mt-3">
        <Checkbox
          v-model="renameShare"
          class="mr-2"
          :label="
            isFile
              ? $t('share.modal.rename.match_file_name_checkbox_label')
              : $t('share.modal.rename.match_folder_name_checkbox_label')
          "
        />
        {{
          isFile
            ? $t('share.modal.rename.match_file_name_checkbox_label')
            : $t('share.modal.rename.match_folder_name_checkbox_label')
        }}
      </p>
    </div>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import Checkbox from 'common/components/Checkbox';

export default {
  components: {
    DialogModal,
    Checkbox,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      renameShare: false,
      item: {},
      source: null,
    };
  },
  computed: {
    shareName() {
      return this.$store.state.share.shareProperties.sharename;
    },
    itemType() {
      return this.item.type == 'dir' ? 'folder' : 'file';
    },
    isFile() {
      return this.item.type === 'file';
    },
  },
  methods: {
    async open(item, source = null) {
      this.isOpen = true;
      this.item = item;
      this.source = source;
    },
    async closeModal() {
      const item = this.item;
      const renameShare = this.renameShare;
      const source = this.source;
      this.isOpen = false;
      this.$emit('renameFeedback', {
        item,
        renameShare,
        renameItem: true,
        source,
      });
    },
  },
};
</script>
