<template>
  <Themed background-color text-color>
    <div class="flex-table user-group-table">
      <permissionTableHeading
        :label="label"
        :is-share-context="isShareContext"
        :is-security-context="isSecurityContext"
      />
      <div v-if="!items || items.length == 0" class="py-2 text-center">
        {{ $t('No entries') }}
      </div>
      <div v-for="(user, index) in items" :key="user.name" class="row-content">
        <div v-tooltip="user.name || user.groupname" class="name text-truncate">
          {{ user.name || user.groupname }}
        </div>
        <span
          v-if="isShareContext"
          v-tooltip="
            disabledObject.view &&
            disabledObject.view.disabled &&
            $t(disabledObject.view.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('View')"
            :checked="user.read"
            :disabled="
              readonly || (disabledObject.view && disabledObject.view.disabled)
            "
            @input="updatePermission(user, 'view', index)"
          />
        </span>

        <span
          v-if="isSecurityContext"
          v-tooltip="
            disabledObject.read &&
            disabledObject.read.disabled &&
            $t(disabledObject.read.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Read')"
            :checked="user.read"
            :disabled="
              readonly || (disabledObject.read && disabledObject.read.disabled)
            "
            @input="updatePermission(user, 'read', index)"
          />
        </span>
        <span
          v-if="isSecurityContext"
          v-tooltip="
            disabledObject.write &&
            disabledObject.write.disabled &&
            $t(disabledObject.write.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Write')"
            :checked="user.write"
            :disabled="
              readonly ||
              (disabledObject.write && disabledObject.write.disabled)
            "
            @input="updatePermission(user, 'write', index)"
          />
        </span>

        <span
          v-if="isShareContext"
          v-tooltip="
            disabledObject.download &&
            disabledObject.download.disabled &&
            $t(disabledObject.download.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Download')"
            :checked="user.download"
            :disabled="
              readonly ||
              (disabledObject.download && disabledObject.download.disabled)
            "
            @input="updatePermission(user, 'download', index)"
          />
        </span>
        <!--Upload and write use 'write' -->
        <span
          v-if="isShareContext"
          v-tooltip="
            disabledObject.upload &&
            disabledObject.upload.disabled &&
            $t(disabledObject.upload.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Upload')"
            :checked="user.write"
            :disabled="
              readonly ||
              (disabledObject.upload && disabledObject.upload.disabled)
            "
            @input="updatePermission(user, 'write', index)"
          />
        </span>
        <span
          v-if="isShareContext || isSecurityContext"
          v-tooltip="
            ((disabledObject.share && disabledObject.share.disabled) ||
              user.status == 3) &&
            $t(disabledObject.share.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Share')"
            :checked="user.share"
            :disabled="
              (disabledObject.share && disabledObject.share.disabled) ||
              user.status == 3 ||
              readonly
            "
            @input="updatePermission(user, 'share', index)"
          />
        </span>

        <span
          v-if="isShareContext"
          v-tooltip="
            ((disabledObject.sync && disabledObject.sync.disabled) ||
              user.status == 3) &&
            $t(
              disabledObject.sync.label ||
                'Permission not allowed for external user'
            )
          "
        >
          <Checkbox
            :aria-label="$t('Sync')"
            :checked="user.sync"
            :disabled="
              (disabledObject.sync && disabledObject.sync.disabled) ||
              user.status == 3 ||
              readonly
            "
            @input="updatePermission(user, 'sync', index)"
          />
        </span>
        <span
          v-if="isShareContext"
          v-tooltip="
            disabledObject.delete &&
            disabledObject.delete.disabled &&
            $t(disabledObject.delete.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Delete')"
            :checked="
              context.isSingleFileShare
                ? false
                : user.write && !user.disallowdelete
            "
            :disabled="
              readonly ||
              (disabledObject.delete && disabledObject.delete.disabled)
            "
            @input="updatePermission(user, 'disallowdelete', index)"
          />
        </span>
        <span
          v-if="isSecurityContext"
          v-tooltip="
            disabledObject.delete &&
            disabledObject.delete.disabled &&
            $t(disabledObject.delete.label || '')
          "
        >
          <Checkbox
            :aria-label="$t('Delete')"
            :checked="user.write && !user.disallowdelete"
            :disabled="
              readonly ||
              (disabledObject.delete && disabledObject.delete.disabled)
            "
            @input="updatePermission(user, 'disallowdelete', index)"
          />
        </span>

        <span
          v-if="isShareContext || isSecurityContext"
          v-tooltip="
            ((disabledObject.manage && disabledObject.manage.disabled) ||
              user.status == 3) &&
            $t(
              disabledObject.manage.label ||
                'Permission not allowed for external user'
            )
          "
        >
          <Checkbox
            :aria-label="$t('Manage')"
            :checked="user.allowmanage"
            :disabled="
              (disabledObject.manage && disabledObject.manage.disabled) ||
              user.status == 3 ||
              readonly
            "
            @input="updatePermission(user, 'allowmanage', index)"
          />
        </span>
        <span
          v-if="!readonly"
          v-tooltip="
            disabledObject.remove &&
            disabledObject.remove.disabled &&
            $t(disabledObject.remove.label || '')
          "
          class="remove-row"
        >
          <a
            v-if="disabledObject.remove && disabledObject.remove.disabled"
            class="btn btn-sm btn-icon fa fa-times px-1 p-0"
            :aria-label="$t('Remove')"
          />
          <v-popover v-else>
            <a
              class="btn btn-sm btn-icon fa fa-times px-1 p-0"
              :aria-label="$t('Remove')"
            />
            <Alert
              slot="popover"
              button-type="danger"
              :buttons="[
                {
                  label: $t('OK'),
                  callback: () =>
                    disabledObject.remove && disabledObject.remove.disabled
                      ? () => {}
                      : updatePermission(user, 'delete', index),
                },
                {
                  label: $t('Cancel'),
                  outline: true,
                  callback: () => {},
                },
              ]"
            >
              <span role="alert">
                {{ $t('Are you sure you want to delete the Permissions ?') }}
              </span>
            </Alert>
          </v-popover>
        </span>
      </div>
    </div>
  </Themed>
</template>

<script>
import Themed from '../Themed';
import Checkbox from 'common/components/Checkbox';
import Alert from 'common/components/Alert';
import permissionTableHeading from '../Partials/permissionTableHeading';

export default {
  name: 'PermissionTable',
  components: {
    permissionTableHeading,
    Themed,
    Checkbox,
    Alert,
  },
  props: {
    label: String,
    items: {
      type: Array,
      default: () => [],
    },
    updatePermissionFn: {
      type: Function,
      default: () => {},
    },
    context: {
      type: Object,
      default: () => {
        return {
          context: 'share',
          isSingleFileShare: false,
        };
      },
    },
    disabledObject: {
      type: Object,
      default: () => {
        return {
          view: {
            label: 'View permission cannot be removed',
            disabled: true,
          },
          download: {
            label: '',
            disabled: false,
          },
          upload: {
            label: '',
            disabled: false,
          },
          share: {
            label: '',
            disabled: false,
          },
          sync: {
            label: '',
            disabled: false,
          },
          delete: {
            label: '',
            disabled: false,
          },
          manage: {
            label: '',
            disabled: false,
          },
          remove: {
            label: '',
            disabled: false,
          },
        };
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isShareContext() {
      return this.context.context === 'share';
    },
    isSecurityContext() {
      return this.context.context === 'security';
    },
    isDir() {
      return this.$store.state.share.shareProperties.isdir;
    },
  },
  methods: {
    updatePermission(value, permission, index) {
      this.updatePermissionFn(value, permission, index);
    },
  },
};
</script>
