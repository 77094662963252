<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
  <div class="tree-menu" :class="!depth ? 'root-tree' : ''">
    <div class="label-wrapper">
      <span class="d-none">{{ updated }}</span>
      <FolderNavigation
        @click.native="toggleChildren($event)"
        :style="indent"
        :title="label"
        :level="depth"
        :file-type="type"
        :expanded="expanded"
        :path-folder="path"
        :path-parent="dirPath"
        :show-icons="showIcon"
        :loading="loading"
        :icon-color="dotColor"
        :icon="icon"
        :simpleLink="simpleLink"
        :onlyToggle="onlyToggle"
        :item="item"
      />
    </div>

    <div
      class="droppable"
      :class="{ 'droppable--active': isDragging && label === 'All Files' }"
    >
      <tree-menu
        v-if="showChildren"
        v-for="node in nodes"
        :key="node.id"
        :nodes="node.nodes"
        :item="node"
        :label="node.label"
        :dot-color="node.iconColor"
        :show-icon="node.showIcons"
        :type="node.type"
        :path="node.path"
        :icon="
          label == 'Starred' && node.type == 'dir'
            ? 'folder text-primary ml-5m'
            : ''
        "
        :dir-path="node.dirpath"
        :depth="depth + 1"
        :selected="node.selected"
        :checkScroll="checkScrollNest"
        @expandHandler="checkScrollNest"
      />
    </div>
  </div>
</template>

<script>
import FolderNavigation from './../components/FolderNavigation';

export default {
  name: 'TreeMenu',
  components: {
    FolderNavigation,
  },
  props: {
    nodes: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: '',
    },
    kind: {
      type: String,
      default: '',
    },
    depth: {
      type: Number,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    dotColor: {
      type: String,
      default: '',
    },
    dirPath: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: '',
    },
    checkScroll: {
      type: Function,
    },
    simpleLink: {
      type: String,
      default: null,
    },
    onlyToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: this.expanded,
      loading: false,
    };
  },
  computed: {
    iconClasses() {
      return {
        'fa-plus-square-o': !this.showChildren,
        'fa-minus-square-o': this.showChildren,
      };
    },
    labelClasses() {
      return { 'has-children': this.nodes };
    },
    indent() {
      const isRtl = this.$store.state.core.useRtl;
      const padding = isRtl ? 'padding-right' : 'padding-left';
      return { [padding]: `${15 * this.depth}px` };
    },
    async updated() {
      let moved = this.$store.state.files.updateFile;
      if (moved && moved.dirpath + '/' == this.dirPath && moved.activity) {
        let modname = this.path.split(/[/]+/).pop();
        if (moved.activity == 'added' || modname == moved.name) {
          this.$parent.loading = true;
          const response = await this.$store.dispatch(
            'files/allFiles',
            this.$parent.path
          );
          if (response.ok) {
            let result = response.data.entry;
            this.$parent.nodes = [];
            this.$parent.loading = false;
            if (result) {
              if (result.length == undefined) {
                result = [result];
              }
              if (result instanceof Array) {
                result.forEach((el) => {
                  this.dataRecursive(this.$parent, el);
                });
              }
            }
          }
          this.$emit('expandHandler');
        }
      }
      return '';
    },
    isDragging() {
      return this.$store.state.files.isDraggingFile;
    },
    draggable() {},
  },
  methods: {
    async toggleChildren(event) {
      if (
        event.target.className.indexOf('inline-icon') > -1 ||
        this.isRecentStarred()
      ) {
        this.showChildren = !this.showChildren;
        this.expanded = !this.expanded;
        this.checkScroll(event);
        if (this.showChildren) {
          this.loading = true;
          if (this.kind == 'recent') {
            await this.getRecentFiles();
          } else if (this.kind == 'starred') {
            await this.getFavoritesList();
          } else if (this.kind == 'shared') {
            await this.getShares();
          } else {
            await this.getAllFiles();
          }
        }
        //recalculated height
        this.checkScroll(event);
      }
      this.loading = false;
    },
    async getAllFiles() {
      const response = await this.$store.dispatch('files/allFiles', this.path);
      if (response.ok) {
        let result = response.data.entry;
        if (result && this.nodes.length == 0) {
          if (result.length == undefined) {
            result = [result];
          }
          if (result instanceof Array) {
            result.forEach((el) => {
              this.dataRecursive(this, el);
            });
          }
        }
      }
    },
    async getRecentFiles() {
      const response = await this.$store.dispatch('files/favoritesInNamedList');
      if (response.ok) {
        let result = response.data.entry;
        if (result && this.nodes.length == 0) {
          if (result instanceof Array) {
            result.forEach((el) => {
              this.dataRecursive(this, el, true);
            });
          } else {
            this.dataRecursive(this, result, true);
          }
        }
      }
    },
    async getFavoritesList() {
      const response = await this.$store.dispatch(
        'files/favoritesInNamedList',
        'default'
      );
      if (response.ok) {
        let result = response.data.entry;
        if (result && this.nodes.length == 0) {
          if (result instanceof Array) {
            result.forEach((el) => {
              this.dataRecursive(this, el, true);
            });
          } else {
            this.dataRecursive(this, result, true);
          }
        }
      }
    },
    async getShares() {
      const response = await this.$store.dispatch('files/shares');
      if (response.ok) {
        let result = response.data.share;
        if (result && this.nodes.length == 0) {
          if (result instanceof Array) {
            result.forEach((el) => {
              this.dataShare(this, el, true);
            });
          } else {
            this.dataShare(this, result, true);
          }
        }
      }
    },
    checkScrollNest(event) {
      this.$emit('expandHandler');
    },
    //TODO: refactor
    //        tickChildren(ev) {
    //            console.log(ev);
    //            this.selected = !this.selected;
    //            this.tickRecursive(this);
    //        },
    //        tickRecursive(node) {
    //            if (node.nodes && node.nodes.length)
    //                node.nodes.forEach(x => {
    //                    x.selected = this.selected;
    //                    this.tickRecursive(x);
    //                });
    //        },
    dataShare(node, el, showIcons) {
      let file = {};
      file.label = el.sharedobjname + '';
      file.path = el.sharelocation;
      file.type = el.isdir ? 'dir' : 'file';
      file.showIcons = showIcons;
      file.nodes = [];
      node.nodes.push(file);
    },
    dataRecursive(node, el, showIcons) {
      let file = { ...el };
      file.label = el.name + '';
      file.path = el.path;
      file.dirpath = el.dirpath;
      file.type = el.type;
      file.showIcons = showIcons;
      file.nodes = [];
      node.nodes.push(file);
    },
    updateData(node, el) {
      node.label = el.name + '';
      node.path = el.path;
      node.dirpath = el.dirpath;
      node.type = el.type;
    },
    isRecentStarred() {
      return (
        this.label == this.$t('Recent Documents') ||
        this.label == this.$t('Starred')
      );
    },
  },
};
</script>
<style lang="scss" scoped>
// TODO : move tree view to common
.tree-menu {
  .label-wrapper {
    .has-children {
      cursor: pointer;
    }
  }
}
</style>
