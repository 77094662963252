<template>
  <DialogModal
    :visible="isOpen"
    :title="$t('Clear Incomplete Files')"
    class="small"
    position="top"
    :buttons="[
      {
        label: `Remove`,
        outline: false,
        callback: function () {
          onClear();
        },
      },
      {
        label: `Close`,
        outline: true,
        callback: function () {
          closeModal();
        },
      },
    ]"
    @close="closeModal"
  >
    <p>{{ $t('Remove all incomplete file uploads?') }}</p>
    <div
      v-tooltip.bottom-start="{
        content: $t(
          'Select check box to remove files that are currently being uploaded'
        ),
        classes: ['clear-incomplete', 'intransit-tooltip'],
      }"
      class="checkbox-container"
    >
      <Checkbox
        :value="includeInTransit"
        name="includeInTransit"
        :label="$t('Include in-transit')"
        @change="includeInTransit = !includeInTransit"
      />
      <label
        for="includeInTransit"
        class="ml-2 mb-0 label"
        @click="includeInTransit = !includeInTransit"
      >
        {{ $t('Include in-transit') }}
      </label>
    </div>
    <div v-if="isMobile" class="in-transit-mobile-description">
      {{
        $t('Select check box to remove files that are currently being uploaded')
      }}
    </div>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import Checkbox from 'common/components/Checkbox';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    DialogModal,
    Checkbox,
  },
  mixins: [responsivenessMixin],
  data() {
    return {
      isOpen: false,
      includeInTransit: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.includeInTransit = false;
    },
    onClear() {
      this.$emit('clearIncomplete', this.includeInTransit);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  align-items: center;
  .label {
    cursor: pointer;
  }
}
</style>

<style lang="scss">
.clear-incomplete.intransit-tooltip {
  .tooltip-arrow {
    left: 64px !important;
  }
}
.in-transit-mobile-description {
  margin-top: 10px;
  font-size: x-small;
  color: var(--text-dark-blue);
}
</style>
