<template>
  <tr>
    <td class="file-path">{{ file.lockpath }}</td>
    <td class="centered">
      {{ file.lockreadlock ? $t('No') : $t('Yes') }}
    </td>
    <td class="centered">
      <DateDisplay v-if="lockexpiration" :date="date" only-date />
      <span v-else>{{ $t('Never Expires') }}</span>
    </td>
    <td class="centered">
      <v-popover
        @update:open="
          (isOpen) => {
            isUnlockAlertOpen = isOpen;
          }
        "
      >
        <button
          v-tooltip="$t('Remove Lock')"
          :aria-label="$t('Remove Lock')"
          class="btn btn-sm btn-icon"
          list-only
        >
          <Icon name="unlock" family="fas" />
        </button>
        <Alert
          slot="popover"
          :visible="isUnlockAlertOpen"
          button-type="danger"
          :buttons="[
            {
              label: `Unlock`,
              callback: function () {
                unlock(file.lockpath);
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            {{ $t('Are you sure you want to unlock') + ' ' }}
            {{ file.lockpath }} ?
          </span>
        </Alert>
      </v-popover>
    </td>
  </tr>
</template>
<script>
import fileListItemSharedMixin from '../../mixins/fileListItemSharedMixin';
import DateDisplay from 'common/components/DateDisplay';

export default {
  components: {
    DateDisplay,
  },
  mixins: [fileListItemSharedMixin],
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      toastLockSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Lock removed successfully.') +
          '</p>',
        type: 'success',
      },
      toastLockError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to remove Lock') +
          '</p>',
        type: 'error',
      },
      isUnlockAlertOpen: false,
    };
  },
  computed: {
    lockexpiration() {
      return typeof this.file.lockexpiration === 'string';
    },
    date() {
      if (this.lockexpiration) {
        return this.file.lockexpirationts;
      }
      return '';
    },
  },

  methods: {
    async unlock(path) {
      const response = await this.$store.dispatch('files/unlock', { path });
      if (response.ok) {
        let userid = this.user.profile;
        await this.$store.dispatch('files/lockedFiles', { userid });
        this.$toast.open(this.toastLockSuccess);
      } else {
        this.$toast.open(this.toastLockError);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  word-break: break-all;
  width: 200px;
}
.file-path {
  width: 400px;
}
table.table tbody tr td.centered button {
  color: var(--text-medium);
}
</style>
