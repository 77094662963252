import Vue from 'vue';
import Router from 'vue-router';
import { decodeAll, encodeURL, decodeURL } from 'common/utils/URLUtils';

const Auth = () => import(/* webpackChunkName: "Auth" */ './pages/Auth.vue');

Vue.use(Router);

const router = new Router({
  //  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'auth',
      component: Auth,
    },
    {
      path: '/login',
      name: 'login',
      component: Auth,
    },
    {
      path: '/newAccount',
      name: 'newAccount',
      component: Auth,
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: Auth,
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: Auth,
    },
    {
      path: '/forceResetPassword',
      name: 'forceResetPassword',
      component: Auth,
    },
    {
      path: '/dashboard',
      name: 'home',
      meta: { authenticated: true },
      component: () => import('./pages/Dashboard.vue'),
    },
    {
      path: '/expl-tabl.',
      name: 'files',
      meta: { authenticated: true },
      component: () => import('./pages/FilesExplorerHome.vue'),
    },
    {
      path: '/expl-tabl./*',
      name: 'files-sub',
      meta: { authenticated: true },
      component: () => import('./pages/Files.vue'),
    },
    {
      path: '/srch-tabl.',
      name: 'searchResults',
      meta: { authenticated: true },
      component: () => import('./pages/SearchResults.vue'),
    },
    {
      path: '/webeditor',
      name: 'webeditor',
      meta: { authenticated: true },
      component: () => import('./pages/Editor.vue'),
    },
    {
      path: '/workflows',
      name: 'workflow',
      meta: { authenticated: true },
      component: () => import('./pages/Workflow/Workflows.vue'),
    },
    {
      path: '/workflows/:uuid',
      name: 'workflows-sub',
      meta: { authenticated: true },
      component: () => import('./pages/Workflow/WorkflowEditor.vue'),
    },
    {
      path: '/workflows/:uuid/instances',
      name: 'workflow-instances',
      meta: { authenticated: true },
      component: () => import('./pages/Workflow/WorkflowInstances.vue'),
    },
    {
      path: '/workflows/:uuid/instances/:instanceid',
      name: 'workflow-instance-detail',
      meta: { authenticated: true },
      component: () => import('./pages/Workflow/WorkflowInstanceDetail.vue'),
    },
    {
      path: '/actions',
      name: 'actions-page',
      meta: { authenticated: true },
      component: () => import('./pages/Actions/Actions.vue'),
    },
    {
      path: '/actions/:actionid',
      name: 'actions-detail-page',
      meta: { authenticated: true },
      component: () => import('./pages/Actions/ActionDetail.vue'),
    },
    {
      path: '/shrd-mgmt.',
      name: 'shared-files',
      meta: { authenticated: true },
      component: () => import('./pages/SharedFiles.vue'),
    },

    {
      path: '/sets-gnrl.*',
      name: 'settings-page',
      meta: { authenticated: true },
      props: { tab: 'general' },
      component: () => import('./pages/SettingsPage.vue'),
    },
    {
      path: '/sets-acct.*',
      redirect: '/sets-gnrl.',
    },
    {
      path: '/sets-devi.*',
      name: 'settings-page-devices',
      meta: { authenticated: true },
      props: { tab: 'devices' },
      component: () => import('./pages/SettingsPage.vue'),
    },
    {
      path: '/sets-noti.*',
      name: 'settings-page-notifications',
      meta: { authenticated: true },
      props: { tab: 'notifications' },
      component: () => import('./pages/SettingsPage.vue'),
    },
    {
      path: '/tos',
      name: 'termsOfService',
      component: () => import('./pages/TermsOfService.vue'),
    },
    {
      path: '/favs-tabl.',
      redirect: '/expl-tabl.',
    },
  ],
});

window.onpopstate = function () {
  if (location.hash && location.hash !== '#/' && !window.ignoreNextPopstate) {
    window.ignoreNextPopstate = true;
    window.location.href = location;
    return;
  }
  window.ignoreNextPopstate = false;
};

router.beforeEach((to, from, next) => {
  // if the path contains a + the url is from old UI
  // needs to be decoded/reencoded
  if (to.path.includes('+') || to.path.includes('%2F')) {
    // decode using specific function
    const decodedURL = decodeAll(to.path);
    next(encodeURL(decodedURL));
  }
  // if part of the path is recognized as hash, a hashtag sign was inserted
  // try to redirect to the right encoded url
  else if (to.hash) {
    // decode first to avoid double encoding
    const decodedHash = decodeURL(to.hash);
    next(to.path + encodeURL(decodedHash));
  } else if (!from.name) {
    // if coming from an unknown route, check the mode to stop the redirection
    const queryString = window.location.search;
    if (queryString.includes('mode=upload')) {
      return;
    }
    next();
  } else next();
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    let hash = window.location.hash.slice(1);
    let mode = window.location.search.indexOf('mode=upload');

    // remove extra slash if present to make url compatible with Old UI
    if (hash && '/' !== hash && mode == -1 && hash.charAt(0) == '/') {
      history.replaceState({}, window.location.hash, '#' + hash.slice(1));
    }
  });
});

export default router;
