<template>
  <div
    ref="advancedSerachContent"
    class="container advanced-search p-0 scrollable-area"
    tabindex="-1"
    @keydown.esc="$emit('hide')"
  >
    <div class="advanced-search-header col-md-12 pl-4 pt-2 p-0 m-0">
      {{ $t('Advanced Search') }}
    </div>
    <div class="advanced-search-body">
      <div
        v-if="metadataSearchSetLoading || !isMetadataModalOpen"
        class="container p-0 pl-4 pr-4 pb-0 mb-0"
        :class="{ 'pt-4': !isMobile }"
      >
        <label for="searchTerm" class="search-annotation float-left mb-2">{{
          $t('Search Term')
        }}</label>
        <br />
        <input
          id="searchTerm"
          ref="searchTerm"
          v-model="searchTerm"
          type="text"
          class="form-control search-term"
          :placeholder="$t('Enter search pattern')"
          @keyup.enter="emitSearchEvent"
          @input="$emit('updateSearchBar', { searchterm: searchTerm })"
        />
      </div>
      <div class="container pt-3 pl-4 pb-0 mb-0">
        <span
          class="search-annotation col-md-12 m-0 p-0"
          :class="{ 'float-left': !isMobile }"
          >{{ $t('Scope') }}</span
        >
        <br />
        <fieldset
          class="col-md-12"
          :class="{ 'row pl-4 advanced-search-option-row': !isMobile }"
        >
          <legend>{{ $t('Scope') }}</legend>
          <div class="form-check form-radio">
            <input
              id="searchScopeAll"
              v-model="searchScope"
              type="radio"
              name="scopeOpt"
              class="form-check-input"
              value="0"
              checked="checked"
            />
            <label
              for="searchScopeAll"
              class="form-check-label search-label"
              @click="searchScope = 0"
            >
              {{ $t('All') }}</label
            >
          </div>
          <div class="form-check form-radio">
            <input
              id="searchScopeName"
              v-model="searchScope"
              type="radio"
              name="scopeOpt"
              class="form-check-input active"
              value="1"
            />
            <label
              for="searchScopeName"
              class="form-check-label search-label"
              @click="searchScope = 1"
              >{{ $t('Name Only') }}
            </label>
          </div>
          <div class="form-check form-radio">
            <input
              id="searchScopeContent"
              v-model="searchScope"
              type="radio"
              name="scopeOpt"
              class="form-check-input"
              value="2"
            />
            <label
              for="searchScopeContent"
              class="form-check-label search-label"
              @click="searchScope = 2"
              >{{ $t('Content Only') }}
            </label>
          </div>
          <div class="form-check form-radio">
            <input
              id="searchScopeMetadata"
              v-model="searchScope"
              type="radio"
              name="scopeOpt"
              class="form-check-input"
              value="3"
              :disabled="searchPathSingleFileView !== ''"
              @click.prevent="openMetadataSearch"
            />
            <label
              for="searchScopeMetadata"
              class="form-check-label search-label"
              @click="openMetadataSearch"
              >{{ $t('Metadata') }}
            </label>
          </div>
        </fieldset>
      </div>
      <div
        v-if="isMetadataModalOpen"
        class="metadata-search-body pb-0 mb-0"
        :class="{ 'p-4': !metadataSearchSetLoading }"
      >
        <MetadataSearch
          ref="metadataSearchModal"
          :loading="metadataSearchSetLoading"
          :visible="isMetadataModalOpen"
          class="high-z"
          @applyMetadataConditions="applyMetadataConditionsToQuery"
          @applyAnyColorConditions="applyAnyColorConditionsToQuery"
        />
      </div>
      <div
        v-if="metadataSearchSetLoading || !isMetadataModalOpen"
        class="container pt-2 pb-0 mb-0 pl-4 pr-4"
      >
        <span
          class="search-annotation col-md-12 m-0 p-0"
          :class="{ 'float-left': !isMobile }"
        >
          {{ $t('Search Type') }}
        </span>
        <br />
        <fieldset class="col-md-12" :class="{ 'row pl-4': !isMobile }">
          <legend>{{ $t('Search Type') }}</legend>
          <div class="form-check form-radio">
            <input
              id="searchTypeAll"
              v-model="searchResultType"
              type="radio"
              name="type"
              class="form-check-input"
              value=""
              checked
            />
            <label
              for="searchTypeAll"
              class="form-check-label search-label"
              @click="searchResultType = ''"
            >
              {{ $t('All Folders and Files') }}</label
            >
          </div>
          <div class="form-check form-radio">
            <input
              id="searchTypeFolders"
              v-model="searchResultType"
              type="radio"
              name="type"
              class="form-check-input"
              value="dir"
            />
            <label
              for="searchTypeFolders"
              class="form-check-label search-label"
              @click="searchResultType = 'dir'"
              >{{ $t('Folders Only') }}
            </label>
          </div>
          <div class="form-check form-radio">
            <input
              id="searchTypeFiles"
              v-model="searchResultType"
              type="radio"
              name="type"
              class="form-check-input"
              value="file"
            />
            <label
              for="searchTypeFiles"
              class="form-check-label search-label"
              @click="searchResultType = 'file'"
              >{{ $t('Files Only') }}
            </label>
          </div>
        </fieldset>
      </div>
      <div class="container p-2 pl-4 pb-0 mb-0">
        <span
          class="search-annotation col-md-12 m-0 p-0"
          :class="{ 'float-left': !isMobile }"
        >
          {{ $t('Path Selection') }}
        </span>
        <br />
        <fieldset class="col-md-12" :class="{ 'row pl-4 pr-4': !isMobile }">
          <legend>{{ $t('Path Selection') }}</legend>
          <div
            class="form-check form-radio"
            :class="{ 'float-left': !isMobile }"
          >
            <input
              id="useSelectedPathAll"
              v-model="useSelectedPath"
              type="radio"
              name="path"
              class="form-check-input"
              value="0"
              :checked="searchPathSingleFileView === ''"
              :disabled="searchPathSingleFileView !== ''"
              @click="clearLoc"
            />
            <label
              for="useSelectedPathAll"
              class="form-check-label search-label"
              @click="useSelectedPath = 0"
            >
              {{ $t('All') }}</label
            >
          </div>
          <div
            class="form-check form-radio"
            :class="{ 'float-left': !isMobile }"
          >
            <input
              id="useSelectedPath"
              v-model="useSelectedPath"
              type="radio"
              name="path"
              class="form-check-input"
              value="1"
              :checked="searchPathSingleFileView !== ''"
              :disabled="searchPathSingleFileView !== ''"
            />
            <label
              for="useSelectedPath"
              class="form-check-label search-label"
              @click="useSelectedPath = 1"
              >{{ $t('Selected') }}
            </label>
          </div>
        </fieldset>

        <div
          v-if="useSelectedPath == 1 || !isMobile"
          class="container pt-2 p-0 m-0 pb-0 mb-0"
        >
          <label
            for="path-folder-input"
            class="search-annotation col-md-12 m-0 p-0"
            :class="{ 'float-left': !isMobile }"
          >
            {{ $t('Selected Path') }}
          </label>
          <br />
          <fieldset class="row col-md-12 m-0 p-0 pr-4">
            <legend>{{ $t('Selected Path') }}</legend>
            <div class="input-group pt-2 m-0 p-0">
              <input
                id="path-folder-input"
                v-model="searchLoc"
                class="form-control py-2 border-right-0 search-term pr-1 search-loc"
                type="search"
                :placeholder="$t('Select Folder')"
                :disabled="searchPathSingleFileView !== ''"
              />
              <span
                tabindex="0"
                class="input-group-append cursor-pointer"
                :title="$t('Open path selector')"
                @click="openPathSelector"
                @keydown.enter.prevent="openPathSelector"
                @keydown.space.prevent="openPathSelector"
              >
                <div class="input-group-text picker-bar">
                  <em class="fa fa-folder-open"></em>
                </div>
              </span>
            </div>
          </fieldset>
        </div>
        <div class="container p-0 pt-4 mb-0">
          <label
            for="modifiedDateStart"
            class="search-annotation col-md-12 m-0 p-0"
            :class="{ 'float-left': !isMobile }"
          >
            {{ $t('Modified Date') }}</label
          >
          <br />
          <fieldset class="row col-md-12 m-0 p-0 pr-4">
            <legend>{{ $t('Modified Date') }}</legend>
            <div class="pt-2 m-0 p-0 col-md-6 pr-1 text-left">
              <label for="from-date-input">{{ $t('From Date') }}</label>
              <DatePicker
                v-model="selectedDateStart"
                custom-input="from-date-input"
                only-date
                no-min-date
              />
            </div>
            <div class="pt-2 m-0 p-0 col-md-6 pr-1 text-left">
              <label for="to-date-input">{{ $t('To Date') }}</label>
              <DatePicker
                v-model="selectedDateStop"
                custom-input="to-date-input"
                only-date
                type="date"
                :format="dateFormat"
                :title="$t('Select Date To')"
                :placeholder="dateFormat"
                :start-date="selectedDateStart"
                :disabled="selectedDateStart === ''"
              />
            </div>
          </fieldset>
        </div>
        <div class="container p-0 pl-1 pt-4 pb-0 mb-0">
          <span
            class="search-annotation col-md-12 m-0 p-0"
            :class="{ 'float-left': !isMobile }"
          >
            {{ $t('Results Count') }}</span
          >
          <br />
          <fieldset class="col-md-12" :class="{ 'row pl-4': !isMobile }">
            <legend>{{ $t('Results Count') }}</legend>
            <div
              class="form-check form-radio"
              :class="{ 'float-left': !isMobile }"
            >
              <input
                id="resultsCountLimited"
                v-model="maxSearchEntries"
                type="radio"
                name="count"
                class="form-check-input"
                value="50"
              />
              <label
                for="resultsCountLimited"
                class="form-check-label search-label"
                @click="maxSearchEntries = 50"
              >
                {{ $t('Limit to 50') }}</label
              >
            </div>
            <div
              class="form-check form-radio"
              :class="{ 'float-left': !isMobile }"
            >
              <input
                id="resultsCountAll"
                v-model="maxSearchEntries"
                type="radio"
                name="count"
                class="form-check-input"
                value="-1"
                checked
              />
              <label
                for="resultsCountAll"
                class="form-check-label search-label"
                @click="maxSearchEntries = -1"
                >{{ $t('All') }}
              </label>
            </div>
          </fieldset>
        </div>
        <div class="container p-0 pl-1 pt-0 pb-0 mb-0">
          <br />
          <div class="col-md-12" :class="{ 'row pl-4 pt-0 pb-2': !isMobile }">
            <div
              class="form-check form-radio"
              :class="{ 'float-left': !isMobile }"
            >
              <input
                id="useCache"
                v-model="useCache"
                type="checkbox"
                class="form-check-input"
                @click="saveCacheFlag"
              />
              <label class="form-check-label search-label" for="useCache">
                {{ $t('Use Cached Results') }}</label
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="advanced-search-footer col-md-12 pl-4 pt-2 p-0 m-0 d-block p-3"
      >
        <div class="errormsg">
          <span
            class="errorMessageClass float-left pt-2"
            id="search-error"
            role="status"
            aria-live="polite"
            >{{ errorMessage }}</span
          >
        </div>
        <button
          class="btn btn-primary button mr-2 btn-advanced-search"
          aria-describedby="search-error"
          @click="emitSearchEvent"
        >
          {{ $t('Search') }}
        </button>
        <button
          v-close-popover
          class="btn btn-outline-primary button ml-auto btn-advanced-cancel"
          @click="cancelSearch"
        >
          {{ resetSearch }}
        </button>
      </div>
    </div>
    <FilePickerModal
      ref="filePickerModal"
      :visible="isFilePickerOpen"
      class="high-z"
      @hidepicker="hideFilePicker"
    />
  </div>
</template>
<script>
import FilePickerModal from '../Modals/FilePicker';
import DatePicker from 'common/components/NewDatePicker';
import MetadataSearch from './Metadata';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import _ from 'lodash';
import { mapState } from 'vuex';
import { createFocusTrap } from 'focus-trap';

export default {
  components: {
    DatePicker,
    FilePickerModal,
    MetadataSearch,
  },
  mixins: [responsivenessMixin],
  props: {
    inputSearchTerm: {
      type: String,
      default: '',
    },
    searchPathSingleFileView: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      defauly: false,
    },
  },
  data() {
    return {
      mode: 'single',
      selectedDateStart: '',
      selectedDateStop: '',
      searchTerm: '',
      searchLoc: '',
      searchScope: 0,
      searchResultType: '',
      useSelectedPath: this.searchPathSingleFileView !== '' ? 1 : 0,
      maxSearchEntries: -1,
      errorMessage: '',
      errorMessageClass: 'info',
      openPickerStart: false,
      openPickerStop: false,
      useCache: true,
      isFilePickerOpen: false,
      metadataSearchSetLoading: false,
      metadataConditions: {},
      useAnyColor: 0,
      tempSearhcTerm: null,
      popoverSetup: {
        visibility: 'click',
      },
      focusTrap: null,
      modifiedFromOpen: false,
      modifiedToOpen: false,
      filetype: 0,
    };
  },
  computed: {
    ...mapState('files', ['searchInProgress']),
    isMetadataModalOpen() {
      return this.searchScope === 3;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    resetSearch() {
      return this.searchInProgress
        ? this.$t('Cancel search')
        : this.$t('Clear');
    },
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
    dateFormat() {
      const dateF =
        this.systemStatus.dateformat ||
        this.systemStatus.defaultdateformat ||
        'MM/DD/YYYY';

      return dateF.toUpperCase();
    },
  },
  watch: {
    searchScope: function (newval, oldValue) {
      this.searchScope = newval;
      if (this.searchScope == 3) {
        this.tempSearhcTerm = this.searchTerm;
        this.searchTerm = ''; // clear search term if it is metadata search
        this.$emit('updateSearchBar', { searchterm: this.searchTerm });
      } else if (oldValue == 3) {
        this.searchTerm = this.tempSearhcTerm;
        this.$emit('updateSearchBar', { searchterm: this.searchTerm });
      }
    },
    inputSearchTerm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.updateSearchTerm(newValue);
      },
    },
    sidebar: {
      deep: true,
      handler(newval) {
        if (this.$route.name === 'files-sub') {
          this.searchLoc = newval.path;
        }
      },
    },
    isFilePickerOpen: function (state) {
      // changed to false = it was closed
      if (state) {
        this.focusTrap.pause();
        this.$emit('filePickerOpened', true);
      } else {
        this.focusTrap.unpause();
        this.$emit('filePickerOpened', false);
      }
    },
    isOpen(oppened) {
      if (oppened) {
        this.handleFocusTrapInit();
      } else {
        if (this.focusTrap) this.focusTrap.deactivate();
      }
    },
    searchLoc() {
      this.useSelectedPath = this.searchLoc ? 1 : 0;
    },
    selectedDateStart(newVal, oldVal) {
      if (newVal !== oldVal) this.selectedDateStop = '';
    },
  },
  mounted() {
    const useCacheResults = localStorage.getItem('usecache');

    if (useCacheResults === null || useCacheResults == 1) {
      this.useCache = 1;
    } else {
      this.useCache = 0;
    }

    if (this.searchPathSingleFileView !== '') {
      this.useSelectedPath = 1;
      this.searchLoc = this.searchPathSingleFileView.split('?search')[0];
    }
  },
  methods: {
    lang() {
      return document.documentElement.lang;
    },
    saveCacheFlag() {
      localStorage.setItem('usecache', !this.useCache ? '1' : '0');
    },
    updateSearchTerm(searchterm) {
      this.searchTerm = searchterm;
    },
    openMetadataSearch() {
      // Avoid multiple calls on click if already open
      if (!this.isMetadataModalOpen) {
        this.searchScope = 3;
        this.loadSetsForSearch();
      }
    },
    async loadSetsForSearch() {
      this.metadataSearchSetLoading = true;
      await this.$store.dispatch(
        'files/getMetaDataSetsForSearch',
        this.searchLoc
      );
      this.metadataSearchSetLoading = false;
    },
    async applyMetadataConditionsToQuery(metadataConditionsPassed) {
      this.metadataConditions = metadataConditionsPassed;
    },
    applyAnyColorConditionsToQuery(useAnyPassed) {
      this.useAnyColor = useAnyPassed;
    },
    async openPathSelector() {
      if (this.searchPathSingleFileView === '') {
        this.isFilePickerOpen = true;
        this.useSelectedPath = 1;
        this.$refs.filePickerModal.select(this.searchLoc, (path) => {
          this.searchLoc = path;
        });
      }
    },
    async emitSearchEvent() {
      this.loading = true;

      if (this.isMetadataModalOpen && _.isEmpty(this.metadataConditions)) {
        this.errorMessage = this.$t(
          'Metadata search requires you to add conditions'
        );
        return false;
      }

      if (this.searchTerm.length < 2 && this.searchScope !== 3) {
        this.errorMessage = this.$t(
          'Search term must be at least 2 characters'
        );
        return false;
      } else {
        this.errorMessage = this.$t('Search in progress');
      }

      if (this.isMetadataModalOpen) {
        this.searchResultType = 0;
      }

      let payload = {
        searchstring: this.searchTerm,
        searchloc: this.useSelectedPath === 1 ? this.searchLoc : '',
        minsize: 0,
        maxsize: 0,
        modifiedstart: this.selectedDateStart,
        modifiedend: this.selectedDateStop,
        searchscope: this.searchScope,
        searchresulttype: this.searchResultType,
        searchattr_total: 0,
        sortby: 'DATE',
        sortdirection: -1,
        start: 0,
        size: 100,
        refresh: !this.useCache ? 1 : 0,
        filetype: this.filetype,
        maxsearchentries: this.maxSearchEntries,
        colortagany: this.useAnyColor,
      };

      if (!_.isEmpty(this.metadataConditions)) {
        payload.searchattr_total = this.metadataConditions.length;
        let index = 0;
        this.metadataConditions.forEach((element) => {
          payload[`searchattr${index}_value`] = element.value;
          payload[`searchattr${index}_type`] = element.type;
          payload[`searchattr${index}_id`] = element.attributeid;
          index++;
        });
      }

      this.$emit('searchEvent', payload);

      this.loading = false;
      this.errorMessage = '';
    },
    async cancelSearch() {
      this.$emit('cancelSearch');
      this.searchTerm = '';
      this.searchScope = 0;
      this.searchLoc = '';
      this.useSelectedPath = 0;
      this.selectedDateStart = '';
      this.selectedDateStop = '';
      this.$emit('updateSearchBar', { searchterm: '' });
    },
    clearLoc() {
      this.searchLoc = '';
    },
    hideFilePicker() {
      setTimeout(() => {
        this.isFilePickerOpen = false;
      }, 100);
    },
    handleFocusTrapInit() {
      // timeout needed due to v-popover delay
      setTimeout(() => {
        if (!this.focusTrap) {
          this.focusTrap = createFocusTrap(this.$refs.advancedSerachContent, {
            allowOutsideClick: true,
            escapeDeactivates: false,
            initialFocus: this.$refs.searchTerm,
          });
        } else {
          this.focusTrap.updateContainerElements(
            this.$refs.advancedSerachContent
          );
        }
        this.focusTrap.activate();
      }, 200);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.advanced-search {
  /* Style for "Rectangle" */
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 2px var(--shadow-color);
  overflow-y: hidden;
  position: relative;

  @media (min-width: 768px) {
    width: 505px;
    height: unset;
    max-height: calc(100vh - 60px); // same as body + header
    border-radius: 4px;
    background-color: var(--bg-light);
  }

  @media (max-width: 767px) {
    transform: none;
  }
}
.advanced-search-body {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 105px);
  padding-bottom: 0px !important;

  @media (max-width: 767px) {
    text-align: left;
    max-height: calc(100vh - 45px - 65px);
  }
}
.advanced-search-header {
  /* Style for "Rectangle" */
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;

  @media (min-width: 768px) {
    border-radius: 4px 4px 0 0;
    background-color: var(--color-primary);
    color: var(--color-primary--foreground);
    font-size: 13px;
  }
}
.search-annotation {
  /* Style for "Search Ter" */
  color: var(--text-mid-dark);
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  height: 20px;
  text-align: left;
}
.search-label {
  /* Style for "All" */
  color: var(--text-medium);
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  padding-top: 1px;
}
.advanced-search-footer {
  /* Style for "Rectangle" */
  border-radius: 0 0 4px 4px;
  background-color: var(--bg-mid-light);
  text-align: right;

  @media (max-width: 767px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.btn-advanced-search {
  /* Style for "Rectangle"
    border-radius: 3px;
    border: 1px solid #016aae;
    color:white;
    background-color: #016aae;*/
  font-size: 13.3333px !important;
  font-weight: 400;
}
.btn-advanced-cancel {
  /* Style for "Cancel"
    color: #016aae;
    font-weight: 600;
    border:2px solid; */
  font-size: 13.3333px;
  font-weight: 600;
}
.form-check-input {
  cursor: pointer;

  &:disabled {
    ~ .form-check-label {
      pointer-events: none;
    }
  }
}
.form-radio {
  display: block;
  cursor: pointer;
  margin: 0;
  padding: 1em;
  margin-right: 15px;

  @media (max-width: 767px) {
    padding: 0.5em;
    width: 100%;
  }
}
.advanced-search-option-row {
  min-height: 27px;
  padding-right: 0;
}
.picker {
  border-right: 0px;
  font-size: 13px;
}
.picker-bar {
  color: var(--text-mid-dark);
  background-color: var(--bg-light);
  border-color: var(--border-color-light);
}
.calendar-icon {
  color: var(--text-mid-dark);
  border-left: 0 !important;
  background-color: var(--bg-light);
  border-color: var(--border-color-light);
  height: 34px;
}
.search-term {
  font-size: 13px;
}
.vc-border-new {
  border: 1px solid;
  border-right: 0 !important;
  font-size: 13px;
}
.search-loc {
  :hover {
    background: red;
  }
}
.high-z {
  z-index: 777777 !important;
}
</style>
