<template>
  <Themed background-color text-color>
    <div
      class="comment pt-2 pr-0 m-0"
      @mouseover="onMouseOver(commentData.who)"
      @mouseout="onMouseOut"
    >
      <div class="col-12 pt-1 m-0 p-0">
        <div class="col-1 mr-2 m-0 p-0 float-left">
          <Avatar
            class="comment-profile-image p-0 m-0 mt-1"
            :user="commentData.who"
            :label="$t(`{userName}'s Avatar`, { userName: commentData.who })"
          />
        </div>
        <div class="col-6 p-0 m-0 float-left">
          <p class="p-0 m-0 comment-who">
            {{ commentData.who === user.peerid ? $t('You') : commentData.who }}
          </p>
          <small class="comment-date p-0 m-0">{{
            timeFormatted(commentData.whents)
          }}</small>
        </div>
        <div class="col-4 p-0 m-0 float-right text-right">
          <button
            v-if="showEditRemove"
            v-tooltip="$t('Remove comment')"
            :aria-label="$t('Remove comment')"
            class="btn btn-default m-1 p-0"
            @click="remove"
          >
            <Icon
              name="trash-alt"
              family="fas"
              class="text-muted"
              style="font-weight: 100"
            />
          </button>
        </div>
      </div>

      <br />
      <div
        v-if="!editMode"
        class="col-12 p-0 m-0 pl-4 pt-1 mt-4 comment-text"
        v-html="formattedComment"
      />
      <CommentInput
        v-if="editMode"
        :edit-mode="true"
        :comment-data="commentData"
        @disableCommentEdit="disableEdit"
      />
      <br />
    </div>
    <div class="col-12 p-0 m-0">
      <hr class="comment-hr p-0 m-0" />
    </div>
  </Themed>
</template>

<script>
import Themed from '../Themed';
import Icon from 'common/components/Icon';
import CommentInput from './CommentInput';
import serverURL from 'common/filecloud/properties/serverURL';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Avatar from 'common/components/Avatar';
dayjs.extend(relativeTime);

export default {
  name: 'Comments',
  components: {
    Themed,
    Icon,
    CommentInput,
    Avatar,
  },
  props: {
    commentData: {
      type: Object,
      default: () => {},
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditRemove: false,
    };
  },
  computed: {
    selectedFullpath() {
      return this.$store.state.files.currentFile.path || '';
    },
    getProfileImage() {
      return (
        serverURL.domainURL +
        '/core/getprofileimage?username' +
        this.commentData.who
      );
    },
    user() {
      return this.$store.state.auth.user;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    timeFormat() {
      return this.systemStatus.timeformat
        ? this.systemStatus.timeformat
        : this.systemStatus.defaulttimeformat;
    },
    formattedComment() {
      let text = decodeURIComponent(escape(this.commentData.text));
      const pattern = /@(?=.{3,20}(?:\s|$))[a-z][a-z0-9]+(?:[._][a-z0-9]+)?/gi;
      text = text.replace(pattern, '<strong>$&</strong>');
      return text;
    },
  },
  methods: {
    onMouseOver(authorUsername) {
      if (authorUsername === this.$store.state.auth.user.peerid) {
        this.showEditRemove = true;
      }
    },
    onMouseOut() {
      //      setTimeout(function () { this.showEditRemove = false; }.bind(this), 3000)
    },
    disableEdit() {
      this.editMode = false;
      this.$emit('disableEditMode');
    },
    async edit() {
      if (!this.editMode) {
        if (this.$store.state.auth.user.peerid === this.commentData.who) {
          this.editMode = true;
        }
      } else {
        this.editMode = false;
      }
    },
    async remove() {
      if (this.$store.state.auth.user.peerid === this.commentData.who) {
        await this.$store.dispatch('files/removeComment', {
          id: this.commentData.id,
          fullpath: this.selectedFullpath,
        });
      } else {
        const toast = {
          message:
            '<b>' +
            this.$t('Failure') +
            '</b><p role="alert">' +
            this.$t('Cannot remove or edit comments of different users') +
            '</p>',
          type: 'warning',
        };
        this.$emit('sentToast', toast);
      }
    },
    timeFormatted(when) {
      // return dayjs.unix(when).format(this.timeFormat);
      return dayjs.unix(when).fromNow();
    },
  },
};
</script>
<style scoped>
.comment-text {
  overflow-wrap: break-word;
}
</style>
