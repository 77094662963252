import _ from 'lodash';

/**
 * Get left offset of element
 *
 * @param {HTMLElement} el - Element to get left offset
 * @returns - Object with left offset of element
 */
export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + rect.width / 2,
  };
}

/**
 * Get bounding client rect
 *
 * @param {HTMLElement} el  - Element to get
 * @returns - Element client rect
 */
export function getClientRect(el) {
  if (!el) return;
  return el.getBoundingClientRect();
}

/**
 * Get properties for calculations of conditional children and parent distance
 *
 * @param {HTMLElement} el - Element that is a child of conditional node
 * @returns - Object with sizes
 */
export function getConditionalChildProps(el) {
  const closestEl = el.closest('.conditional-child');

  if (!closestEl) return;

  const parent = closestEl.parentNode;
  const rect = closestEl.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  return {
    bottom: rect.bottom,
    width: rect.width,
    parentWidth: parentRect.width,
    childIndex: Array.prototype.indexOf.call(parent.children, closestEl),
  };
}

/**
 * Get a decision node width and height
 * @returns - Object with width and height
 */
export function getDecisionNodeSizes() {
  const rect = document
    .querySelector('.workflow-node--decision')
    .getBoundingClientRect();
  return { width: rect.width, height: rect.height };
}

/**
 * Get the width of a plus node
 * @returns - a number of width
 */
export function getPlusNodeWidth() {
  const rect = document
    .querySelector('.workflow-node--plus')
    .getBoundingClientRect();
  return rect.width;
}

/**
 * Encode step parameters as html entities
 *
 * @param workflow  - Workflow object
 * @returns - workflow object with params converted to html entities
 */
export function escapeWorkflowParams(workflow) {
  return {
    ...workflow,
    steps: workflow.steps.map(step => {
      return {
        ...step,
        params: {
          ...step.params,
          VALUE_PARAMETER: typeof(step.params.VALUE_PARAMETER) === 'string' ? _.escape(step.params.VALUE_PARAMETER) : step.params.VALUE_PARAMETER,
        }
      };
    }),
  };
}

/**
 * Decode step parameters from html entities
 *
 * @param workflow  - Workflow object with params converted as html entities
 * @returns - workflow object
 */
 export function unescapeWorkflowParams(workflow) {
  return {
    ...workflow,
    steps: workflow.steps.map(step => {
      return {
        ...step,
        params: {
          ...step.params,
          VALUE_PARAMETER: typeof(step.params.VALUE_PARAMETER) === 'string' ? _.unescape(step.params.VALUE_PARAMETER) : step.params.VALUE_PARAMETER,
        }
      };
    }),
  };
}
