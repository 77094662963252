<template>
  <DialogModal
    :visible="true"
    color="gray"
    :title="$t('Alert')"
    position="top"
    :size="'xsmall'"
    :buttons="[
      {
        label: `${$t('Share')}`,
        callback: function () {
          share();
        },
      },
      {
        label: `${$t('Cancel')}`,
        outline: true,
        callback: function () {
          closeModal();
        },
      },
    ]"
    @close="closeModal"
  >
    <p class="mb-0">
      {{ $t('Are you sure you want to share a root folder?') }}
    </p>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';

export default {
  components: {
    DialogModal,
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    share() {
      this.$emit('share');
      this.closeModal();
    },
  },
};
</script>
