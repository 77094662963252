<template>
  <div class="workflow-sidebar workflow-sidebar--node">
    <Loader v-if="loading" :loading="loading && !nodeFilled" full />
    <div v-else>
      <div class="node-heading">
        <h3 v-if="!editingNodeName">
          {{ node.name }}
          <button class="btn btn-outline" @click="editingNodeName = true">
            <Icon name="edit" family="far" />
          </button>
        </h3>
        <div v-else class="form-group">
          <label for="nodeName" class="form-label">
            {{ $t('Node name') }}
            <span class="text-danger">*</span>
          </label>
          <input id="nodeName" v-model="node.name" class="form-control mb-3" />
        </div>

        <p v-if="!editingNodeDescription">
          {{ node.description }}
          <button
            class="btn btn-outline"
            @click="editingNodeDescription = true"
          >
            <Icon name="edit" family="far" />
          </button>
        </p>
        <div v-else class="form-group">
          <label for="nodeDesc" class="form-label">
            {{ $t('Node description') }}
            <span class="text-danger">*</span>
          </label>
          <textarea
            id="nodeDesc"
            v-model="node.description"
            class="form-control"
          />
        </div>
      </div>

      <form class="form">
        <div v-if="node.type !== nodeTypes.conditional">
          <div
            v-if="hasUsersAndGroupsApproval"
            class="mb-1 form-group"
            :class="{
              'is-invalid':
                getFieldError('APPROVER_EMAILS_PARAMETER') !== undefined ||
                getParamError('APPROVER_EMAILS_PARAMETER') ||
                getFieldError('APPROVER_GROUPS_PARAMETER') !== undefined ||
                getParamError('APPROVER_GROUPS_PARAMETER'),
            }"
          >
            <label class="form-label">
              {{ $t('workflow.node_params.email_approvals_title') }}
              <span class="text-danger">*</span>
            </label>
            <small>{{
              $t('workflow.node_params.email_approvals_description')
            }}</small>
            <div class="p-4 border rounded">
              <label class="form-label pt-0">
                <Icon family="fas" name="user" />
                {{ $t('workflow.node_params.email_approvals_users') }}
              </label>
              <small>{{
                $t('workflow.node_params.email_approvals_user_description')
              }}</small>
              <FcUsersParam
                id="userSearch"
                v-model="query"
                :users="filteredUsers"
                @addEmail="addEmail('APPROVER_EMAILS_PARAMETER', $event)"
              />
              <ApproversList :value="approvers">
                <template v-slot:default="{ data, index }">
                  <span>{{ data.emailid }}</span>
                  <button
                    v-tooltip="$t('Remove')"
                    :aria-label="$t('Remove')"
                    class="fas fa-times-circle"
                    @click.prevent="
                      removeEmail('APPROVER_EMAILS_PARAMETER', index, $event)
                    "
                  />
                </template>
              </ApproversList>

              <label class="form-label">
                <Icon family="fas" name="users" />
                {{ $t('workflow.node_params.email_approvals_groups') }}
              </label>
              <small>{{
                $t('workflow.node_params.email_approvals_groups_description')
              }}</small>
              <FcGroupsParam
                id="userSearch"
                v-model="groupsQuery"
                :groups="filteredGroups"
                @addGroup="
                  (group) => addGroup('APPROVER_GROUPS_PARAMETER', group)
                "
              />
              <ApproversList :value="groupApprovers">
                <template v-slot:default="{ data, index }">
                  <span>{{ data.groupname }}</span>
                  <button
                    v-tooltip="$t('Remove')"
                    :aria-label="$t('Remove')"
                    class="fas fa-times-circle"
                    @click.prevent="
                      removeGroup('APPROVER_GROUPS_PARAMETER', index, $event)
                    "
                  />
                </template>
              </ApproversList>
            </div>
          </div>
          <div
            v-for="(param, index) in supportedParams"
            :key="index"
            class="mb-1 form-group"
            :class="{
              'is-invalid':
                getFieldError(param.type) !== undefined ||
                getParamError(param.type),
            }"
          >
            <label :for="'param' + index" class="form-label">
              {{ param.name }}
              <span class="text-danger">{{ param.required ? '*' : '' }}</span>
            </label>
            <small v-if="param.description">{{ param.description }}</small>
            <div
              v-if="param['param_type'] === 'enum'"
              class="form-control form-control--select"
            >
              <select
                v-if="
                  node.type === nodeTriggers.fileEvent ||
                  node.type === nodeTriggers.shareEvent
                "
                :id="'param' + index"
                v-model="node.params[param.type]"
                :required="param.required"
              >
                <option value="" disabled selected>Please select</option>
                <option
                  v-for="(group, paramIdx) in paramsGroups"
                  :key="paramIdx"
                  :value="group.value"
                >
                  {{ group.label }}
                </option>
              </select>
              <select
                v-else
                :id="'param' + index"
                v-model="node.params[param.type]"
                :required="param.required"
              >
                <option value="" disabled selected>
                  {{ $t('Please select') }}
                </option>
                <option
                  v-for="(val, valIdx) in param.values"
                  :key="valIdx"
                  :value="valIdx"
                >
                  {{ $t(val) }}
                </option>
              </select>
            </div>
            <div v-if="param['param_type'] === 'variables'">
              <div
                class="form-control form-control-sm form-control--select with-var"
              >
                <select
                  :id="'param' + index"
                  v-model="node.params[param.type]"
                  :required="param.required"
                >
                  <option value="" disabled selected>Please select</option>
                  <option
                    v-for="(val, varidx) in allVarsNames"
                    :key="varidx"
                    :value="val.VARIABLE_NAME_PARAMETER"
                  >
                    {{ val.DISPLAY_NAME_PARAMETER }}
                  </option>
                </select>
              </div>
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(
                    param.type,
                    param['param_type'],
                    $event,
                    'variables'
                  )
                "
              >
                +
              </button>
            </div>
            <div v-if="param['param_type'] === 'string'">
              <Field
                :key="updated"
                :data-type="param.type"
                :value.sync="node.params[param.type]"
                :active="addingVariableToField === param.type"
                :required="param.required"
                class="with-var"
              />
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(param.type, param['param_type'], $event)
                "
              >
                +
              </button>
            </div>
            <div v-if="param['param_type'] === 'fc_users'">
              <FcUsersParam
                id="userSearch"
                v-model="query"
                :users="filteredUsers"
                @addEmail="addEmail(param.type, $event)"
              />

              <div class="mb-2">
                <span
                  v-for="email in emailToArray(param.type)"
                  :key="email.idx"
                  v-tooltip="email.text"
                  class="badge badge-secondary"
                >
                  <span>{{ email.text }}</span>
                  <button
                    v-tooltip="$t('Remove email')"
                    :aria-label="$t('Remove email')"
                    class="fas fa-times-circle"
                    @click.prevent="removeEmail(param.type, email.idx, $event)"
                  />
                </span>
              </div>
            </div>
            <div v-if="param['param_type'] === 'email'">
              <div class="input-group with-var">
                <input
                  v-model="node.editing[param.type]"
                  type="text"
                  class="form-control"
                  :required="param.required"
                  @keydown.enter.prevent="addEmail(param.type)"
                  @input="clearParamError($event, param.type)"
                  @blur="addEmail(param.type)"
                />
              </div>
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(param.type, param['param_type'], $event)
                "
              >
                +
              </button>
              <small :key="updated" class="error mt-1">
                {{ getParamError(param.type) }}
              </small>
              <div class="mt-2 mb-2">
                <span
                  v-for="email in emailToArray(param.type)"
                  :key="email.idx"
                  v-tooltip="email.text"
                  class="badge badge-secondary"
                >
                  <span>{{ email.text }}</span>
                  <button
                    v-tooltip="$t('Remove email')"
                    :aria-label="$t('Remove email')"
                    class="fas fa-times-circle"
                    @click.prevent="removeEmail(param.type, email.idx)"
                  />
                </span>
              </div>
            </div>
            <div v-if="param['param_type'] === 'text'">
              <Editor
                v-model="node.params[param.type]"
                :active="addingVariableToField === param.type"
                :type="param.type"
                :required="param.required"
                wrap-toolbar
                class="editor with-var"
              />
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(param.type, param['param_type'], $event)
                "
              >
                +
              </button>
            </div>
            <div v-if="param['param_type'] === 'path'">
              <div class="input-group with-var">
                <Field
                  :key="updated"
                  :data-type="param.type"
                  :value.sync="node.params[param.type]"
                  :active="addingVariableToField === param.type"
                  :required="param.required"
                />
                <span
                  tabindex="0"
                  class="input-group-append cursor-pointer"
                  :title="$t('Open path selector')"
                  @click="openPathSelector(param.type)"
                  @keydown.enter.prevent="openPathSelector(param.type)"
                  @keydown.space.prevent="openPathSelector(param.type)"
                >
                  <span class="input-group-text picker-bar">
                    <em class="fa fa-folder-open"></em>
                  </span>
                </span>
              </div>
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(param.type, param['param_type'], $event)
                "
              >
                +
              </button>
            </div>
            <div v-if="param['param_type'] === 'datetime'" class="input-group">
              <DatePicker
                :value="node.params[param.type]"
                server-format-type
                class="form-control"
                type="datetime"
                :custom-input="param.type"
                :placeholder="$t(dateTimeFormat)"
                :required="param.required"
                :disabled="
                  param.type === nodeTypesParams.endDateTime
                    ? isPickerDisabled
                    : false
                "
                
                @clear="clearDateInput(param.type)"
              />
            </div>
            <div v-if="param['param_type'] === 'boolean'" class="input-group">
              <Checkbox
                v-model="node.params[param.type]"
                :checked="node.params[param.type]"
              />
            </div>
            <div v-if="param['param_type'] === 'integer'">
              <Field
                :key="updated"
                :data-type="param.type"
                :value.sync="node.params[param.type]"
                :active="addingVariableToField === param.type"
                :required="param.required"
                class="with-var"
                only-int
              />
              <button
                v-tooltip="$t('Insert a variable')"
                class="btn btn-light"
                @click.prevent="
                  openVariables(param.type, param['param_type'], $event)
                "
              >
                +
              </button>
            </div>
            <small v-if="getFieldError(param.type) !== undefined" class="error">
              {{ getFieldError(param.type) }}
            </small>
          </div>
        </div>
        <div v-else>
          <QueryBuilder
            :key="node.uid"
            v-model="node.params['CONDITION_PARAMETER']"
            :rule-types="conditionalRules"
            @change="saveNodeInfo(false)"
          />
        </div>
        <div
          v-if="node.type !== nodeTypes.conditional"
          class="mt-4 text-center"
        >
          <button
            class="btn btn-outline-primary"
            @click.prevent="cancelNodeSelection"
          >
            Cancel
          </button>
          <button
            class="ml-2 btn btn-primary"
            :disabled="
              (!changed || !nodeFilled) &&
              node.type !== nodeTypes.waitForApproval
            "
            type="submit"
            @click.prevent="saveNodeInfo"
          >
            Save
          </button>
        </div>
      </form>
    </div>
    <transition
      enter-active-class="animate__animated animate__slideInRight"
      leave-active-class="animate__animated animate__slideOutRight"
    >
      <WorkflowVariables
        v-show="isVariablesOpened"
        ref="variablesSidebar"
        :field-type="addingVariableType"
        :is-condition="node && node.type === nodeTypes.conditional"
        @closeVariables="closeVariables"
        @addVar="addVariable"
      />
    </transition>
    <FilePickerModal
      ref="filePickerModal"
      class="high-z"
      :visible="isFilePickerOpen"
      :keep-selection="true"
      @hidepicker="hideFilePicker"
    />
    <Alert
      v-if="showUnsavedAlert"
      class="unsaved-changes"
      :buttons="[
        {
          label: this.$t('Discard'),
          outline: true,
          callback: () => {
            showUnsavedAlert = false;
            cancelNodeSelection();
          },
        },
        {
          label: this.$t('Save'),
          callback: () => {
            showUnsavedAlert = false;
            saveNodeInfo();
          },
        },
      ]"
      >{{
        $t('You have unsaved changes. Do you want to save or discard them?')
      }}</Alert
    >
  </div>
</template>

<script>
import Alert from 'common/components/Alert';
import Loader from 'common/components/Loader';
import Checkbox from 'common/components/Checkbox';
import FilePickerModal from '@/components/Modals/FilePicker';
import WorkflowVariables from '@/components/FileSidebar/WorkflowVariables';
import Field from '@/components/ContentEditable/Field';
import Editor from 'common/components/Editor';
import Icon from 'common/components/Icon';
import { isValidEmail } from 'common/utils/forminput';
import Avatar from 'common/components/Avatar';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import QueryBuilder from 'common/components/QueryBuilder/QueryBuilder';
import FcUsersParam from 'common/components/Workflow/ParamType/FcUsers';
import FcGroupsParam from 'common/components/Workflow/ParamType/FcGroups';
import ApproversList from 'common/components/Workflow/ApproversList';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
import { mapState } from 'vuex';

import {
  fileEventParamsGroups,
  shareEventParamsGroups,
  nodeTypes,
  nodeTriggers,
  nodeTypesParams,
  periodicTriggerOptions,
} from '@/constants/workflows';
import DatePicker from 'common/components/NewDatePicker';
import { cloneDeep } from 'lodash';
import Vue from 'vue';
import wysiwyg from 'vue-wysiwyg';
import _ from 'underscore';
import dayjs from 'dayjs';

export default {
  name: 'WorkflowNodeParams',
  components: {
    Alert,
    Avatar,
    QueryBuilder,
    Icon,
    Loader,
    DatePicker,
    FilePickerModal,
    WorkflowVariables,
    VueTypeaheadBootstrap,
    Field,
    Editor,
    Checkbox,
    FcUsersParam,
    FcGroupsParam,
    ApproversList,
  },
  data() {
    return {
      loading: true,
      node: null,
      compareNode: null,
      isFilePickerOpen: false,
      isVariablesOpened: false,
      editingNodeName: false,
      editingNodeDescription: false,
      addingVariableToField: null,
      addingVariableType: null,
      currentFieldText: '',
      enumVal: '',
      fileEventParamsGroups,
      shareEventParamsGroups,
      nodeTypes,
      nodeTriggers,
      nodeTypesParams,
      periodicTriggerOptions,
      updated: 0,
      fieldErrors: [],
      nodeFilled: false,
      showUnsavedAlert: false,
      users: [],
      approvers: [],
      groupApprovers: [],
      query: '',
      groupsQuery: '',
      groups: [],
      dayjs,
    };
  },
  computed: {
    ...mapState('core', ['systemstatus']),
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
    selectedNode() {
      return { ...this.sidebar.lastSelected };
    },
    steps() {
      return this.$store.state.workflows.steps;
    },
    triggers() {
      return this.$store.state.workflows.triggers;
    },
    filteredGroups() {
      return this.groups.filter(
        (group) =>
          !this.groupApprovers.some(
            (approver) => approver.emailid === group.emailid
          )
      );
    },
    filteredUsers() {
      return this.users.filter(
        (user) =>
          !this.approvers.some((approver) => approver.emailid === user.emailid)
      );
    },
    paramsGroups() {
      let params = [];

      switch (this.node.type) {
        case this.nodeTriggers.fileEvent:
          params = fileEventParamsGroups;
          break;
        case this.nodeTriggers.shareEvent:
          params = shareEventParamsGroups;
          break;
      }

      return params;
    },
    changed() {
      return JSON.stringify(this.node) !== JSON.stringify(this.compareNode);
    },
    allVarsNames() {
      return [...this.variables];
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      if (this.propDateFormat) return this.propDateFormat;

      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'DD-MM-YYYY';
      }
      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    timeFormat() {
      if (this.onlyDate) return '';

      return (
        this.propTimeFormat ||
        this.systemStatus.timeformat ||
        this.systemStatus.defaulttimeformat ||
        'HH:mm'
      );
    },
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat;
    },
    conditionalRules() {
      const operations =
        this.$store.state.workflows.currentWorkflow['supported_operations'];

      // create a compatible object from stored variables to merge with supported operations
      const variablesObj = {
        name: '_var',
        operations: this.variables.reduce((prev, curr) => {
          const key = curr.VARIABLE_NAME_PARAMETER.replace('_var.', '');
          const desc = curr.DISPLAY_NAME_PARAMETER;
          const sample = `${curr.VARIABLE_NAME_PARAMETER} == '${this.$t(
            'value'
          )}'`;

          return {
            ...prev,
            [key]: {
              desc,
              sample,
            },
          };
        }, {}),
      };

      const varIndex = operations.findIndex((item) => item.name === '_var');

      // Replace variables supported operations with variables from store
      // operations[varIndex] = variablesObj;

      const operands = [];
      const operators = [];
      operations.forEach((operation) => {
        let name = operation.name;
        name = name.replace('_', '');
        name = name.charAt(0).toUpperCase() + name.slice(1);
        operands.push(name);

        for (let key of Object.keys(operation.operations)) {
          operators.push({
            ruleString: operation.name + '.' + key,
            label: operation.operations[key].label,
            example: operation.operations[key].example,
            description: operation.operations[key].desc,
            type: name,
          });
        }
      });

      return {
        operands: operands,
        operators: operators,
        inputType: 'text',
        ruleString: 'text-field',
      };
    },

    operations() {
      let operationsArr = [];
      let storeOperations =
        this.$store.state.workflows.currentWorkflow['supported_operations'];

      storeOperations.forEach((item) => {
        for (let key of Object.keys(item.operations)) {
          operationsArr.push({
            op: `${item.name}.${key}`,
            description: item.operations[key].desc,
            sample: item.operations[key].sample,
          });
        }
      });

      return operationsArr;
    },

    properties() {
      return this.$store.state.workflows.currentWorkflow['properties'];
    },

    variables() {
      return this.$store.state.workflows.currentWorkflow.variables;
    },
    context() {
      return this.properties.context;
    },
    event() {
      let properties = [];
      let storeProperties = this.properties.objects;

      for (let key of Object.keys(storeProperties)) {
        storeProperties[key].forEach((item) => {
          properties.push(item);
        });
      }

      return properties;
    },
    isPickerDisabled() {
      if (this.node.type === this.nodeTriggers.timeBased) {
        let selectedPeriodic = this.node['supported_params'].find(
          (param) => param.type === this.nodeTypesParams.periodic
        );

        let val =
          this.node.params[this.nodeTypesParams.periodic] !== ''
            ? selectedPeriodic.values[
                this.node.params[this.nodeTypesParams.periodic]
              ]
            : null;

        return val === this.periodicTriggerOptions.once;
      } else {
        return false;
      }
    },
    hasUsersAndGroupsApproval() {
      return (
        this.node.supported_params.filter(
          (param) =>
            param.type === 'APPROVER_EMAILS_PARAMETER' ||
            param.type === 'APPROVER_GROUPS_PARAMETER'
        ).length == 2
      );
    },
    supportedParams() {
      if (this.hasUsersAndGroupsApproval) {
        return this.node.supported_params.filter(
          (param) =>
            param.type !== 'APPROVER_EMAILS_PARAMETER' &&
            param.type !== 'APPROVER_GROUPS_PARAMETER'
        );
      }
      return this.node['supported_params'];
    },
  },
  watch: {
    selectedNode(newstate) {
      this.node = newstate;
      this.fillNodeInfo();
    },
    query: _.debounce(function (newQuery) {
      if (newQuery) {
        this.searchUsers(newQuery);
      }
    }, 150),
    groupsQuery: _.debounce(function (newQuery) {
      if (newQuery) {
        this.searchGroups(newQuery);
      }
    }, 150),
    changed(newstate) {
      this.$store.commit('workflows/setUnsavedChanges', {
        value: newstate,
      });
    },
  },
  mounted() {
    this.node = this.selectedNode;
    this.$root.$on(
      'showUnsavedNodeChangesAlert',
      () => (this.showUnsavedAlert = true)
    );
    this.fillNodeInfo();
  },
  created() {
    Vue.use(wysiwyg, {
      hideModules: { image: true },
    });
  },
  destroyed() {
    this.$root.$off('showUnsavedNodeChangesAlert');
  },
  methods: {
    async fillNodeInfo() {
      this.loading = true;

      const node = this.node;

      if (!this.triggers || this.triggers.length === 0) {
        await this.$store.dispatch('workflows/getWorkflowTriggers');
      }

      const foundNodeData =
        this.node.nodeType === 'StartNode'
          ? this.triggers.find((n) => {
              return n.type === node.type;
            })
          : this.steps.find((n) => {
              return n.type === node.type;
            });

      this.node.supported_params = foundNodeData.supported_params;
      this.node.editing = {};
      this.node.error = {};

      if (this.node.nodeType === 'StartNode') {
        this.node.supported_operations = foundNodeData.supported_operations;
        await this.$store.dispatch(
          'workflows/saveTriggerSupportedOperations',
          this.node.supported_operations
        );
      }

      this.node.supported_params.forEach((paramToAdd) => {
        const found = Object.getOwnPropertyNames(this.node.params).find(
          (paramToFind) => {
            return paramToFind === paramToAdd.type;
          }
        );

        this.node.editing[paramToAdd.type] = '';
        this.node.error[paramToAdd.type] = '';

        if (!found) {
          if (paramToAdd.param_type === 'boolean') {
            this.node.params[paramToAdd.type] = false;
          } else {
            this.node.params[paramToAdd.type] = '';
          }
        } else {
          if (
            paramToAdd.param_type === 'string' ||
            paramToAdd.param_type === 'path' ||
            paramToAdd.param_type === 'integer' ||
            paramToAdd.param_type === 'text'
          ) {
            this.renderVariables(found);
          } else if (paramToAdd.param_type === 'fc_users') {
            let arr = this.node.params[paramToAdd.type].split(',');
            arr.forEach((item) => {
              if (item) {
                this.approvers.push({ emailid: item });
              }
            });
          } else if (paramToAdd.param_type === 'fc_groups') {
            let arr = this.node.params[paramToAdd.type].split(',');
            arr.forEach((item) => {
              if (item) {
                this.groupApprovers.push({ groupname: item });
              }
            });
          } else if (paramToAdd.param_type === 'boolean') {
            if (this.node.params[paramToAdd.type] === '') {
              this.node.params[paramToAdd.type] = false;
            }
          }
        }
      });

      setTimeout(() => {
        this.compareNode = cloneDeep(this.node);
        this.nodeFilled = true;
      }, 500);

      this.loading = false;
    },
    async openPathSelector(type) {
      this.isFilePickerOpen = true;
      this.useSelectedPath = 1;
      const basePath = '';
      this.$refs.filePickerModal.select(basePath, (path) => {
        this.node.params[type] = path;
      });
    },
    hideFilePicker() {
      setTimeout(() => {
        this.isFilePickerOpen = false;
      }, 100);
    },
    addParam(event, type) {
      let curValues = this.node.params[type].split(',');
      curValues.push(event);
      this.node.params[type] = curValues.join();
      this.enumVal = '';
    },
    cancelNodeSelection() {
      this.$store.commit('files/setSidebar', {
        key: 'open',
        value: true,
      });
      this.$store.commit('files/setSidebar', {
        key: 'selected',
        value: [],
      });
      this.$store.commit('files/addToStore', {
        key: 'currentFile',
        value: null,
      });
      this.$store.commit('files/setSidebar', {
        key: 'lastSelected',
        value: null,
      });
      this.$store.commit('files/setSidebar', {
        key: 'current',
        value: 'workflow-components',
      });
      this.$store.commit('workflows/setUnsavedChanges', {
        value: false,
      });
      this.$root.$emit('selectStoredNode');
    },
    async saveNodeInfo(unselectNode = true) {
      this.fieldErrors = [];

      if (this.hasUsersAndGroupsApproval) {
        // At least one of them should be filled
        if (
          this.node.params['APPROVER_EMAILS_PARAMETER'].length === 0 &&
          this.node.editing['APPROVER_EMAILS_PARAMETER'].length === 0 &&
          this.node.params['APPROVER_GROUPS_PARAMETER'].length === 0
        ) {
          this.fieldErrors.push({
            type: 'APPROVER_EMAILS_PARAMETER',
            error: this.$t('This field is required'),
          });
          this.fieldErrors.push({
            type: 'APPROVER_GROUPS_PARAMETER',
            error: this.$t('This field is required'),
          });
        }
      }

      this.supportedParams.forEach((n) => {
        if (
          (n.required &&
            this.node.params[n.type].length === 0 &&
            n.param_type !== 'email') ||
          (n.required &&
            this.node.params[n.type].length === 0 &&
            n.param_type === 'email' &&
            this.node.editing[n.type].length === 0)
        ) {
          this.fieldErrors.push({
            type: n.type,
            error: this.$t('This field is required'),
          });
        }
      });

      this.node['supported_params'].forEach((n) => {
        if (
          this.node.editing &&
          this.node.editing[n.type] &&
          this.node.editing[n.type].length > 0
        ) {
          const res = this.addEmail(n.type);

          if (!res) {
            this.fieldErrors.push({
              type: n.type,
              error: this.$t('Please use valid email address'),
            });
          }
        }
      });

      if (this.fieldErrors.length === 0) {
        const nodePayload = {
          name: this.node.name,
          description: this.node.description,
          params: await this.sanatizeParams(),
        };

        await this.$store.dispatch('workflows/saveNodeInfo', {
          uid: this.node.uid,
          nodePayload: nodePayload,
        });

        this.$store.commit('workflows/setUnsavedChanges', {
          value: false,
        });

        this.compareNode = cloneDeep(this.node);

        if (unselectNode) {
          this.cancelNodeSelection();
        } else {
          this.$store.commit('workflows/setUnsavedChanges', {
            value: false,
          });
          this.$root.$emit('selectStoredNode');
        }
      }
    },
    getFieldError(field) {
      const err = this.fieldErrors.find((error) => error.type === field);
      return err ? err.error : undefined;
    },
    clearFieldError(field) {
      this.fieldErrors = this.fieldErrors.filter(
        (error) => error.type !== field
      );
    },
    openVariables(field, type, event = null, defaultTab = null) {
      if (
        (event && (!event.pointerType || event.pointerType.length > 0)) ||
        event === null
      ) {
        this.isVariablesOpened = true;
        this.addingVariableToField = field;
        this.addingVariableType = type;

        this.$nextTick(() => {
          if (defaultTab !== null) {
            this.$refs.variablesSidebar.currentTab = defaultTab;
          }
        });
      }
    },
    closeVariables() {
      this.isVariablesOpened = false;
      this.addingVariableToField = null;
    },
    addVariable(variable) {
      if (this.addingVariableType !== 'email') {
        if (variable.op === undefined) {
          this.node.params[this.addingVariableToField] =
            this.node.params[this.addingVariableToField] +
            `<span
                  class="variable"
                  data-variable-name="^${variable.VARIABLE_NAME_PARAMETER}^"
                  data-variable-value="${variable.VALUE_PARAMETER}"
                  contentEditable="false"
              >
                  ${variable.DISPLAY_NAME_PARAMETER}
              </span>`;
        } else {
          this.node.params[this.addingVariableToField] =
            this.node.params[this.addingVariableToField] + `${variable.op}`;
        }

        setTimeout(() => {
          const contentEditables = document.querySelectorAll(
            'div[contenteditable]'
          );

          contentEditables.forEach((field) => {
            let tempBreak = document.createElement('br');
            field.insertAdjacentElement('beforeend', tempBreak);
          });
        }, 500);
      } else {
        let currentEmails = this.node.params[this.addingVariableToField];

        this.clearFieldError(this.addingVariableToField);
        this.node.error[this.addingVariableToField] = '';

        currentEmails = currentEmails.length
          ? `${currentEmails},^${variable.VARIABLE_NAME_PARAMETER}^`
          : `^${variable.VARIABLE_NAME_PARAMETER}^`;
        this.node.params[this.addingVariableToField] = currentEmails;
      }

      this.updated++;
    },
    async renderVariables(type) {
      const pattern = /\^(.*?)\^/g;
      const tags = this.node.params[type].toString().match(pattern);

      if (tags) {
        for (const [i] of tags.entries()) {
          let varName = tags[i].replace(/\^/g, '');
          const varData = await this.$store.dispatch(
            'workflows/getVariableByVarName',
            varName
          );

          // if already processed, just return
          // TODO: improve state management to avoid such comparison
          if (this.node.params[type].includes('class="variable"')) return;

          this.node.params[type] = this.node.params[type].replace(
            tags[i],
            `<span
                  class="variable"
                  data-variable-name="^${varData.VARIABLE_NAME_PARAMETER}^"
                  data-variable-value="${varData.VALUE_PARAMETER}"
                  contentEditable="false"
              >
                  ${varData.DISPLAY_NAME_PARAMETER}
              </span>`
          );

          this.updated++;
        }
      }
    },
    async sanatizeParams() {
      let unchanged = { ...this.node.params };
      let changed = { ...this.node.params };

      const contentEditables = document.querySelectorAll(
        'div[contenteditable]'
      );

      contentEditables.forEach((field) => {
        let fields = field.querySelectorAll('span.variable');

        if (fields.length > 0) {
          fields.forEach((item) => {
            item.replaceWith(item.dataset.variableName);
          });
        }

        changed[field.dataset.type] = field.innerText
          .replace(/\s+/g, ' ')
          .trim();
      });

      changed.VALUE_PARAMETER =
        typeof changed.VALUE_PARAMETER === 'string'
          ? _.escape(changed.VALUE_PARAMETER)
          : changed.VALUE_PARAMETER;
      this.node.params = unchanged;
      this.updated++;

      return changed;
    },
    getParamError(param) {
      return this.node.error[param];
    },
    clearParamError(event, param) {
      this.node.editing[param] = event.target.value;
      if (this.getFieldError(param)) {
        this.clearFieldError(param);
      }
      if (this.getParamError(param) !== '') {
        this.node.error[param] = '';
        this.updated++;
      }
    },
    addGroup(param, group = null) {
      if (!group) return;
      let currentGroups = this.node.params[param];

      this.groupApprovers.push(group);

      currentGroups = currentGroups?.length
        ? `${currentGroups},${group.groupname}`
        : group.groupname;

      this.node.params[param] = currentGroups;
      this.groupsQuery = '';

      if (!group.membercount) {
        this.$toast.open({
          message:
            '<p role="alert">' +
            (this.node.type === this.nodeTypes.waitForApproval
              ? this.$t(
                  'workflow.node_params.email_approvals_groups_empty_wait_file_approval_add_empty_group'
                )
              : this.$t(
                  'workflow.node_params.email_approvals_groups_empty_wait_share_approval_add_empty_group'
                )) +
            '</p>',
          type: 'warning',
        });
      }
    },
    addEmail(param, event = null) {
      if (!event) {
        if (this.node.editing[param].length > 6) {
          let currentEmails = this.node.params[param];
          let emailsArr = this.node.editing[param].split(',');
          let invalidEmail = [];
          let validEmail = [];

          for (let i = 0; i < emailsArr.length; i++) {
            if (!isValidEmail(emailsArr[i])) {
              invalidEmail.push(emailsArr[i]);
            }

            if (isValidEmail(emailsArr[i])) {
              validEmail.push(emailsArr[i]);
              emailsArr.splice(i, 1);
              this.node.editing[param] = emailsArr.join(',');
            }
          }

          if (invalidEmail.length > 0) {
            this.node.error[param] =
              this.$t('Invalid email: ') + this.node.editing[param];
          }

          let emails = validEmail.filter((el) => el !== '').join(',');

          currentEmails = currentEmails.length
            ? `${currentEmails},${emails}`
            : emails;

          this.node.params[param] = currentEmails;

          if (invalidEmail.length === 0) {
            this.node.editing[param] = '';
            this.updated++;
            return true;
          } else {
            return false;
          }
        } else if (this.node.editing[param].length > 0) {
          this.fieldErrors.push({
            type: param,
            error: this.$t('Please use valid email address'),
          });
        }
      } else {
        if (event.emailid) {
          let currentEmails = this.node.params[param];
          this.approvers.push(event);

          currentEmails = currentEmails.length
            ? `${currentEmails},${event.emailid}`
            : event.emailid;

          this.node.params[param] = currentEmails;
          this.query = '';
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    },
    emailToArray(param) {
      if (this.node.params[param].length) {
        let arr = this.node.params[param].split(',');
        let outArr = [];

        arr.forEach((item, index) => {
          if (item.length > 0) {
            outArr.push({
              text: item,
              idx: index,
            });
          }
        });

        return outArr;
      } else {
        return;
      }
    },
    removeGroup(param, idx, event = null) {
      if ((event && event.pointerType.length > 0) || event === null) {
        let arr = this.node.params[param].split(',');
        this.groupApprovers = this.groupApprovers.filter(
          (item) => item.groupname !== arr[idx]
        );
        arr.splice(idx, 1);
        this.node.params[param] = arr.join(',');
      }
    },
    removeEmail(param, idx, event = null) {
      if ((event && event.pointerType.length > 0) || event === null) {
        let arr = this.node.params[param].split(',');
        this.approvers = this.approvers.filter(
          (item) => item.emailid !== arr[idx]
        );
        arr.splice(idx, 1);
        this.node.params[param] = arr.join(',');
      }
    },
    async searchGroups(query) {
      const response = await this.$store.dispatch('share/searchGroups', {
        filter: query,
      });

      let groups = response.data.group;
      if (groups && groups.length == undefined) {
        groups = [groups];
      }

      this.groups = !groups ? [] : groups;
    },
    async searchUsers(newQuery) {
      if (
        (this.systemstatus.EXACT_EMAIL_SEARCH_IMPLICIT_INVITE ||
          this.systemstatus.EXACT_EMAIL_SEARCH_EXPLICIT_INVITE) &&
        !isValidEmail(newQuery)
      ) {
        return;
      } else {
        const response = await this.$store.dispatch('share/searchUsers', {
          filter: newQuery,
        });

        let users = response.data.profile;
        if (users && users.length === undefined) {
          users = [users];
        }
        if (!users) {
          users = [];
        }
        users = users.map((user) => {
          if (typeof user.displayname !== 'string')
            user.displayname = user.username;
          return user;
        });
        this.users = users;
      }
    },
    clearDateInput(type) {
      this.node.params[type] = '';
    },
  },
};
</script>
