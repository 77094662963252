import SearchCredits from './components/SearchCredits';

const Plugin = {
  install(Vue, { i18n }) {
    this.event = new Vue();
    Vue.prototype.$credits = {
      get subscription() {
        return Plugin.event;
      },

      // trigger preview
      open(items, index) {
        Plugin.event.$emit('open');
      },
    };
    // create div in body
    const div = document.createElement('div');
    document.body.appendChild(div);

    // mount component
    new Vue({
      //parent: root,
      i18n,
      render: (h) => h(SearchCredits),
    }).$mount(div);
  },
};

export default Plugin;