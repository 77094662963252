var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rule-actions form-inline"},[_c('div',{staticClass:"form-group"},[_c('div',{staticStyle:{"position":"relative","align-self":"start"}},[_c('button',{staticClass:"btn btn-primary btn-sm mt-2 round-add-btn",attrs:{"type":"button"},on:{"click":_vm.showButtons}},[_c('Icon',{attrs:{"name":"plus-circle","family":"fas"}}),_vm._v(" ADD ")],1),_c('transition',{attrs:{"name":"fade-up"}},[(_vm.addButtons)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => (_vm.addButtons = false)),expression:"() => (addButtons = false)"}],staticClass:"buttons-container p-3"},[_c('div',{staticClass:"mb-2"},[_c('span',{staticClass:"p-1",attrs:{"type":"button"},on:{"click":function($event){_vm.addRule();
                _vm.showButtons();}}},[_c('Icon',{attrs:{"name":"traffic-light-go","family":"fal"}}),_vm._v(" "+_vm._s(_vm.labels.addRule)+" ")],1)]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: !_vm.depth
                ? _vm.$t(`You can create up to 4 levels of groups`)
                : '',
            }),expression:"{\n              content: !depth\n                ? $t(`You can create up to 4 levels of groups`)\n                : '',\n            }"}],staticClass:"pointer"},[_c('span',{staticClass:"p-1",class:{ disabled: !_vm.depth },attrs:{"type":"button"},on:{"click":function($event){_vm.addGroup();
                _vm.showButtons();}}},[_c('Icon',{attrs:{"name":"layer-group","family":"fal"}}),_vm._v(" "+_vm._s(_vm.labels.addGroup)+" ")],1)])]):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }