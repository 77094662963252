// amount of files per page
export const itemsPerPage = 50;

// average item height
export const averageItemHeight = 56;

export const navbarHeight = 60;

// average page height
export const averagePageHeight = itemsPerPage * averageItemHeight;

// average grid item height
export const averageGridItemHeight = 170;

// average grid item width
export const averageGridItemWidth = 170;

// timeout to automatically expand the item in ms
export const automaticExpandTimeout = 500;

// file chunk size in mb
export const chunkSize = 20;

// priority in the transactions list
export const transactionStatusPriority = {
  processing: 0,
  preparing: 0,
  failed: 0,
  completed: 0,
  cancelled: 0,
};

// Threshold for enabling compact mode, where the ContextMenu shows only the three dots
export const filelistMinimumWidth = 460;

export const filelistScrollBuffer =
  126 + // 64 + 62: Stick top from filelist header
  60 + // Filelist header height
  averageItemHeight * 5; // enought buffer for 5 next items

export const listScrollBuffer = 500; // enought margin for most of the cases. The default for the lib is 200.
