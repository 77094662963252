import * as helpers from '../components/ZipViewer/zipViewerHelpers';
import FileOperations from '../components/ZipViewer/FileOperations';
import _ from 'lodash';

const fileOperations = new FileOperations();

const getDefaultState = () => {
  return {
    rootFile: null,
    meta: null,
    items: null,
    newFolderInputVisible: false,
    password: null,
    isEncrypted: false,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    setRootFile(state, rootFile) {
      state.rootFile = rootFile;
    },

    setMeta(state, meta) {
      state.meta = meta;
    },

    setItems(state, items) {
      state.items = items;
    },

    setNewFolderInputVisible(state, show) {
      state.newFolderInputVisible = show;
    },

    setIsEncrypted(state, isEncrypted) {
      state.isEncrypted = isEncrypted;
    },

    resetState(state) {
      Object.assign(state, getDefaultState());
    },

    setPassword(state, password) {
      state.password = password;
    },
  },
  actions: {
    setZipPreviewOpen({ dispatch }, status) {
      dispatch('files/setZipPreviewOpen', status, { root: true });
    },

    initFileOperations(context, { preview, toast }) {
      fileOperations.context = context;
      fileOperations.preview = preview;
      fileOperations.toast = toast;
    },

    toggleTransactionContainer({ commit }, show) {
      commit(
        'files/setTransactionsBar',
        {
          key: 'expanded',
          value: show,
        },
        { root: true }
      );
      commit(
        'files/setTransactionsBar',
        {
          key: 'visible',
          value: show,
        },
        { root: true }
      );
    },

    setItems({ commit }, items) {
      commit('setItems', items);
    },

    resetState({ commit }) {
      commit('resetState');
    },

    toggleNewFolderInput({ commit }, show) {
      commit('setNewFolderInputVisible', show);
    },

    setPassword({ rootGetters, dispatch, commit }, { path, password }) {
      if (rootGetters['core/getZipFolderPasswordSession']) {
        dispatch(
          'files/addZipFolderPassword',
          {
            path,
            password,
          },
          { root: true }
        );
      }
      commit('setPassword', password);
    },

    sortItems({ state, commit }, sort) {
      let field = sort.field === 'modified' ? 'timestamp' : sort.field;
      field = sort.field === 'size' ? 'fullsize' : field;
      const sortedItems = _.orderBy(state.items, field, sort.type);
      commit('setItems', sortedItems);
    },

    setZipFileInfo({ commit }, { file, info }) {
      commit('setIsEncrypted', info.isencrypted);
      commit('setRootFile', {
        item: { ...file },
        info: { ...info },
      });
    },

    async fetchZipFileInfo(context, file) {
      return await fileOperations.fetchZipFileInfo(file);
    },

    async browse(context, { path, filter } = { path: null, filter: null }) {
      return await fileOperations.browse(path, filter);
    },

    async loadZipFileInfo(context, file) {
      await fileOperations.loadZipFileInfo(file);
    },

    async createNewFolder(context, folderName) {
      await fileOperations.createFolder(folderName);
    },

    async downloadItem(context, { item, path = null }) {
      await fileOperations.downloadItem(item, path);
    },

    async deleteItem(context, { item, path = null }) {
      await fileOperations.deleteItem(item, path);
    },

    async uploadItem(context, items) {
      await fileOperations.uploadItems(items);
      document.querySelector('body').style.overflowY = 'hidden';
    },

    async blob({ state, getters }, file) {
      const rootPath = getters.rootFilePath;
      return await this.state.core.client.getBlob(
        'core/ziparchivepreviewfile',
        {
          path: rootPath,
          name: file.fullentrypath,
          password: state.password,
        },
        null,
        'POST'
      );
    },
  },
  getters: {
    rootFilePath: (state) => {
      if (state.rootFile) {
        return state.rootFile?.item.fullfilename;
      }
      return null;
    },

    fileName: (state) => {
      if (state.rootFile) {
        return state.rootFile?.item.name;
      } else {
        return null;
      }
    },

    itemsCount(state) {
      if (!state.items) {
        return null;
      }
      return state.items.length;
    },

    isEncrypted(state) {
      if (!state.rootFile) {
        return null;
      }
      return state.rootFile.info.isencrypted;
    },

    filePath(state) {
      if (!state.rootFile) {
        return null;
      }
      return state.rootFile?.item.path;
    },

    currentPath(state, getters) {
      if (state.meta) {
        return helpers.joinPaths([
          getters.fileName,
          state.meta.parentPath,
          state.meta.realpath,
        ]);
      } else {
        return getters.fileName;
      }
    },

    currentDir(state, getters) {
      if (!state.meta) {
        return null;
      }
      return helpers.getCurrentDir(getters.currentPath);
    },

    subFolder(state, getters) {
      if (!state.meta) {
        return null;
      }
      const regex = getters.fileName + '/';
      return getters.currentPath.replace(regex, '');
    },

    canUpload(state) {
      if (!state.meta) {
        return null;
      }
      return !!state.meta.canupload;
    },

    canDownload(state) {
      if (!state.meta) {
        return null;
      }
      return !!state.meta.candownload;
    },

    isSharedView(state) {
      if (!state.rootFile) {
        return null;
      }
      return state.rootFile?.item.fullfilename.startsWith('/SHARED');
    },

    isTransactionContainerVisible(state, getters, rootState) {
      return rootState.files.transactionsBar.visible;
    },
  },
};
