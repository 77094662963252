import { v4 as uuid } from 'uuid';

export default {
  namespaced: true,
  state: {
    files: new Worker(new URL('../../workers/files.worker.js', import.meta.url)),
  },
  actions: {
    // init and bind events
    init({ state }) {
      state.files.addEventListener('message', (event) => {
        const { type, data, commit } = event.data;
        if (!type) return;
        if (commit) {
          this.commit(type, data);
        } else {
          this.dispatch(type, data);
        }
      });
    },
    // call function on worker
    call({ state }, { type, data }) {
      return new Promise((resolve, reject) => {
        const [worker, method] = type.split('/');

        if (state[worker]) {
          const id = uuid();

          const onMessage = ({ data }) => {
            if (data.id === id) {
              resolve(data);

              // remove listener
              state[worker].removeEventListener('message', onMessage);
            }
          };

          state[worker].addEventListener('message', onMessage);

          state[worker].postMessage({ id, type: method, data });
        } else {
          reject(`Worker ${worker} not loaded.`);
        }
      });
    },
  },
};
