<template>
  <transition
    enter-active-class="animate__animated animate__fadeInDown"
    leave-active-class="animate__animated animate__fadeOutUp"
  >
    <div v-if="searchbar" class="mobile_searchbar">
      <SearchBar id="mSearch" @searchEventCompleted="close" />
    </div>
  </transition>
</template>

<script>
import SearchBar from '../SearchBar';

export default {
  components: {
    SearchBar,
  },
  computed: {
    searchbar() {
      return this.$store.state.files.showMobileSearchbar;
    },
  },
  methods: {
    close() {
      this.$store.commit('files/hideMobileSearch');
      this.$store.commit('files/hideMobileOverlay');
    },
  },
};
</script>
