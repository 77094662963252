import { isMobileDevice } from 'common/utils/responsive.js';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('core', ['isMobile', 'isTablet', 'isSmallScreen']),

    isMobileDevice,
  },
};
