<template>
  <div class="file-preview-item file-preview-item--office">
    <Loader v-if="isLoading" />
    <div id="document-wrapper" ref="wrapper" class="viewer-js" />
  </div>
</template>

<script>
import Loader from 'common/assets/loader.svg';
import { loadScript, getFileType } from 'common/utils/files';
import { mapActions } from 'vuex';
export default {
  components: {
    Loader,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instance: null,
      render: null,
      isLoading: false,
      isParsing: false,
    };
  },
  computed: {
    vendorMap() {
      return {
        word: this.loadWordLibrary,
        excel: this.loadExcelLibrary,
        powerpoint: this.loadPowerpointLibrary,
      };
    },
  },
  watch: {
    file: {
      handler() {
        this.load();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('zipViewer', {
      getBlob: 'blob',
    }),
    async load() {
      try {
        this.isLoading = true;

        if (this.instance) {
          this.instance.destroy();
        }
        this.render = null;

        const request = await this.getBlob(this.file);
        if (!request.ok) {
          throw request.error;
        }

        this.blob = request.data;
        this.blob.name = this.blob.fileName = this.file.name;

        await this.loadJquery();

        const fileType = getFileType(this.file.name);

        if (this.vendorMap[fileType]) {
          await this.vendorMap[fileType]();
        } else {
          throw request.error;
        }

        this.isParsing = true;
        this.instance.parse(
          this.blob,
          this.onParseDocument,
          this.onParseDocumentFail
        );

        // timeout for parsing
        setTimeout(() => {
          if (this.isParsing) {
            this.$emit('fallback', {
              view: 'DefaultView',
              error: this.$t('zip_preview.nested_preview.errors.timeout'),
            });
          }
        }, 1000);
      } catch (error) {
        this.$emit('fallback', {
          view: 'DefaultView',
          error:
            error ||
            this.$t('zip_preview.nested_preview.errors.unexpected_error'),
        });
      }
    },
    onParseDocument() {
      try {
        const element = window.jQuery('#document-wrapper')[0];
        window.jQuery(element).css('height', 'calc(100% - 65px)');
        this.render(element);
        this.isLoading = false;
        this.isParsing = false;
      } catch (error) {
        this.$emit('fallback', {
          view: 'DefaultView',
          error:
            error ||
            this.$t('zip_preview.nested_preview.errors.unexpected_error'),
        });
      }
    },
    onParseDocumentFail(error) {
      this.$emit('fallback', {
        view: 'DefaultView',
        error:
          error || this.$t('zip_preview.nested_preview.errors.failed_to_parse'),
      });
    },
    onRenderFail(error) {
      this.$emit('fallback', {
        view: 'DefaultView',
        error:
          error ||
          this.$t('zip_preview.nested_preview.errors.failed_to_render'),
      });
    },
    async loadJquery() {
      if (!window.jQuery) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/jquery.min.js`
        );
      }
    },
    async loadWordLibrary() {
      if (!window.docxJS) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/docxjs/DocxJS.bundle.min.js`
        );
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/docxjs/DocxUiLoader.js`
        );
      }

      this.instance = window.docxJS = window.createDocxJS
        ? window.createDocxJS()
        : new window.DocxJS();
      this.render = window.docxAfterRender;
    },
    async loadExcelLibrary() {
      if (!window.cellJS) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/celljs/CellJS.bundle.min.js`
        );
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/celljs/CellUiLoader.js`
        );
      }

      this.instance = window.cellJS = window.createCellJS
        ? window.createCellJS()
        : new window.CellJS();
      this.render = window.cellAfterRender;
    },
    async loadPowerpointLibrary() {
      if (!window.slideJS) {
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/slidejs/SlideJS.bundle.min.js`
        );
        await loadScript(
          `${
            process.env.NODE_ENV === 'production' ? '/ui/core' : ''
          }/vendor/slidejs/SlideUiLoader.js`
        );
      }

      this.instance = window.slideJS = window.createSlideJS
        ? window.createSlideJS()
        : new window.SlideJS();
      this.render = window.slideAfterRender;
    },
  },
};
</script>
