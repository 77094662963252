<template>
  <table class="table-simplified">
    <thead>
      <tr>
        <th scope="col" class="expand">{{ $t('Operation') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in operations" :key="index">
        <th scope="row" class="full pt-2 pb-2">
          {{ $t(item.op) }}
          <small>{{ $t(item.description) }}</small>
          <small class="mt-2">
            <strong>Sample:</strong> {{ $t(item.sample) }}
          </small>
        </th>
        <td class="actions">
          <button
            class="btn btn-sm btn-outline-primary action"
            @click="$emit('addVar', item)"
          >
            Insert
          </button>
          <span class="action">
            <Icon family="fas" name="ellipsis-h" />
          </span>
        </td>
      </tr>
      <tr v-if="operations.length === 0" class="empty">
        <td colspan="3">{{ $t('No variable found') }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'WorkflowOperationsTable',
  components: {
    Icon,
  },
  computed: {
    operations() {
      let operationsArr = [];
      let storeOperations = this.$store.state.workflows.currentWorkflow[
        'supported_operations'
      ];

      storeOperations.forEach((item) => {
        for (let key of Object.keys(item.operations)) {
          operationsArr.push({
            op: `${item.name}.${key}`,
            description: item.operations[key].desc,
            sample: item.operations[key].sample,
          });
        }
      });

      return operationsArr;
    },
  },
};
</script>
