import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  methods: {
    dateFromNow(when) {
      const date = dayjs.unix(when).fromNow();
      const [number, ...string] = date.split(' ');
      if (number && string.length === 2) {
        return number + ' ' + this.$t(string.join(' '));
      }
      return date;
    },
  },
};
