export default [
  {
    name: 'List',
    icon: 'list',
    iconFamily: 'fas',
    action: 'list',
  },
  {
    name: 'Small Grid',
    icon: 'th',
    iconFamily: 'fas',
    action: 'grid',
  },
  {
    name: 'Medium Grid',
    icon: 'grip-horizontal',
    iconFamily: 'fas',
    action: 'm-grid',
  },
  {
    name: 'Large Grid',
    icon: 'th-large',
    iconFamily: 'fas',
    action: 'l-grid',
  },
];
