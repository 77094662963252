<template>
  <li class="transactions-list-item">
    <div class="transaction-icon">
      <Icon family="fas" name="download" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon
          v-if="transaction.status === 'failed'"
          family="fas"
          name="times"
        />
        <Icon
          v-else-if="transaction.status === 'cancelled'"
          family="fas"
          name="ban"
        />
        <Icon v-else family="fas" name="check" />
      </div>
    </div>
    <div class="transaction-info">
      <span class="transaction-info-title">
        {{
          transaction.items.length === 1
            ? transaction.items[0].name
            : $tc(
                '{count} item from {dir} | {count} items from {dir}',
                transaction.items.length,
                { count: transaction.items.length, dir: transaction.name }
              )
        }}
        <small v-if="transaction.status === 'preparing'" role="status">
          {{
            $t('Fetching {count}/{total} items', {
              count: transaction.loadedItems,
              total: transaction.items.length,
            })
          }}
        </small>
        <small v-else-if="transaction.status === 'processing'" role="status">
          Downloading {{ bytesToSize(transaction.loadedSize) }} -
          {{ bytesToSpeed(transaction.speed) }}</small
        >
      </span>
      <div v-if="transaction.status === 'preparing'" class="progress">
        <div
          class="progress-bar"
          :style="{ width: `${progress}%` }"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Preparing files...')"
        ></div>
      </div>
      <div v-else-if="transaction.status === 'processing'" class="progress">
        <div
          class="progress-bar w-10 indeterminate"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Downloading files...')"
        ></div>
      </div>
      <span
        v-else-if="transaction.status === 'cancelled'"
        class="description"
        role="alert"
      >
        {{ $t('You have cancelled this operation') }}
      </span>
      <span
        v-else-if="transaction.status === 'failed'"
        class="description"
        role="alert"
      >
        {{ transaction.message }}
      </span>
      <span v-else-if="transaction.message" class="description" role="status">
        {{ $t('The download will start soon') }}
      </span>
    </div>
    <div class="transaction-actions">
      <button
        v-if="
          transaction.status === 'preparing' ||
          transaction.status === 'processing'
        "
        v-tooltip="{ delay: 1000, content: 'Cancel operation' }"
        class="btn btn-icon"
        :aria-label="$t('Cancel operation')"
        @click="cancel"
      >
        <Icon family="fas" name="ban" />
        <Icon class="icon-overlay" family="fas" name="ban" />
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';
import { bytesToSize, bytesToSpeed } from 'common/utils/files';

export default {
  components: {
    Icon,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    progress() {
      const progress = Math.ceil(
        (this.transaction.loadedItems * 100) / this.transaction.items.length
      );
      return progress >= 100 ? 100 : progress;
    },
  },
  methods: {
    cancel() {
      this.$store.commit('files/setTransaction', {
        id: this.transaction.id,
        status: 'cancelled',
      });
    },
    bytesToSize,
    bytesToSpeed,
  },
};
</script>
