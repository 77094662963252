var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-sidebar-header"},[_c('div',{staticClass:"file-sidebar-header-icon",class:{ 'w-100': _vm.sidebar.selected.length > 1 }},[(_vm.sidebar.selected.length <= 1)?_c('div',[(_vm.currentFile.type === 'file')?_c('FileIcon',{staticClass:"img-fluid file-sidebar-header-typefile",attrs:{"name":_vm.currentFile.name,"item":_vm.currentFile,"path":_vm.currentFile.path,"format":_vm.currentFile.format,"size":_vm.thumbSize}}):(_vm.isTeamFolderRoot)?_c('TeamFolders',{staticClass:"mb-2"}):(_vm.isNetworkShareRoot)?_c('NetworkFolders',{staticClass:"mb-2"}):(_vm.isSharedWithMeRoot)?_c('SharedFolders',{staticClass:"mb-2"}):(
          _vm.meta &&
          _vm.currentFile.dirpath === '/SHARED' &&
          _vm.meta.parentpath === '/SHARED' &&
          _vm.meta.isroot === 1
        )?_c('Icon',{attrs:{"family":"fas","name":"user"}}):(
          !_vm.currentFile.type ||
          _vm.currentFile.type == 'dir' ||
          _vm.currentFile.type == 'folder'
        )?_c('Icon',{attrs:{"family":"fas","name":"folder"}}):_vm._e()],1):_c('div',{staticClass:"multifiles"},[_c('FileIcon',{staticClass:"img-fluid file-sidebar-header-typefile pb-0",attrs:{"name":"multifiles","format":"multifiles","size":_vm.thumbSize}})],1)]),(_vm.sidebar.selected.length <= 1)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
      typeof _vm.currentFile.name == 'string'
        ? _vm.sanitizeFileName(_vm.currentFile.name)
        : ''
    ),expression:"\n      typeof currentFile.name == 'string'\n        ? sanitizeFileName(currentFile.name)\n        : ''\n    "}],staticClass:"file-sidebar-header-title text-truncate mt-1"},[(_vm.isTeamFolderRoot)?_c('span',[_vm._v(_vm._s(_vm.$t('Team Folders')))]):(_vm.isNetworkShareRoot)?_c('span',[_vm._v(_vm._s(_vm.$t('Network Shares')))]):(_vm.isSharedWithMeRoot)?_c('span',[_vm._v(_vm._s(_vm.$t('Shared with Me')))]):_c('span',[_vm._v(_vm._s(_vm.currentFile.name))])]):_c('p',{staticClass:"file-sidebar-header-title text-truncate mt-2"},[_c('span',[_vm._v(_vm._s(_vm.sidebar.selected.length)+" files selected")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }