<template>
  <div class="tree-menu">
    <div class="label-wrapper label-wrapper-notice tree-menu">
      <div class="nav-item-expandable nav-link row" @click="expand()">
        <Icon :name="icon" family="fas" class="icon" />
        <span v-a11ybutton="expand" class="text-truncate title" tabindex="0">{{
          $t(label)
        }}</span>
        <Icon
          :name="showNotices ? 'caret-up' : 'caret-down'"
          family="fas"
          class="icon chevron"
        />
      </div>
      <div v-if="showNotices" class="notice-container row ml-3 p-2">
        <span>
          <a :href="currentNotice.link" target="_blank">
            {{ $t(currentNotice.title) }}
            {{ $t(currentNotice.description) }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import Parser from 'rss-parser';
import serverURL from 'common/filecloud/properties/serverURL';
import vueinterval from 'vue-interval/dist/VueInterval.common';

export default {
  name: 'NoticeView',
  components: {
    Icon,
  },
  mixins: [vueinterval],
  props: {
    label: {
      default: 'Notice',
      type: String,
    },
    icon: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showNotices: true,
      limit: 5,
      name: 'Notices',
      parser: new Parser({
        headers: {
          'User-Agent': navigator.userAgent,
        },
      }),
      notices: {},
      noticeId: 0,
      renderedNotice: {},
    };
  },
  computed: {
    iconClasses() {
      return {
        'fa-plus-square-o': !this.showChildren,
        'fa-minus-square-o': this.showChildren,
      };
    },
    labelClasses() {
      return { 'has-children': this.nodes };
    },
    indent() {
      return { transform: `translate(${this.depth * 15}px)` };
    },
    feedUrl() {
      return serverURL.domainURL + '/core/getrssfeed';
    },
    noticesCount() {
      return this.notices.length;
    },
    currentNotice() {
      if (JSON.stringify(this.notices) !== '{}') {
        return this.notices[this.randomId];
      } else return this.emptyNotice;
    },
    randomId() {
      return Math.floor(Math.random() * this.notices.length);
    },
    emptyNotice() {
      return {
        title: this.$t('No notices available.'),
        description: this.$t('There are no news in your RSS feed'),
      };
    },
  },
  created() {
    this.loadNotices();
  },
  methods: {
    async INTERVAL__300e4$myUpdateMethod() {
      let response = await this.loadNotices();
      this.notices = response;
    },
    async loadNotices() {
      let feed = await this.parser.parseURL(this.feedUrl);
      let count = 1;

      const returnItems = feed.items.map(function (element) {
        var obj = Object.assign({}, element);
        obj.key = count++;
        return obj;
      });

      this.notices = returnItems;
      return returnItems;
    },
    expand() {
      this.showNotices = this.showNotices ? false : true;
    },
  },
};
</script>
<style lang="scss" scoped>
.nav-chevron {
  font-size: 10px;
  min-width: 0.6rem;
  padding-right: 5px;
}
.notices-container {
  width: 100%;
}
.notice-container {
  /* Style for "This is a" */
  color: var(--text-medium);
  font-size: 11px;
  font-weight: 500;
  line-height: 20px;
  padding-right: 40px;
  max-width: 300px;
  display: block;
  overflow-y: auto;
  a {
    color: var(--text-medium);
  }
  a:hover {
    color: var(--color-primary) !important;
  }
}
.label-wrapper-notice {
  font-size: 14px;
  font-weight: 600;
}
// TODO : move tree view to common
.tree-menu {
  .label-wrapper {
    cursor: pointer;
  }
}
.icon {
  color: var(--text-light);
  font-size: 1rem;
  min-width: 1.5rem;
}
.chevron {
  color: var(--text-dark);
  margin-left: 5px;
}
.title {
  font-weight: 600;
  max-width: 90%;
  color: var(--text-dark);
}
</style>
