import { sanitizeFileName } from './files';

// Maximum number of chunks to keep in local storage
const MAX_NUMBER_OF_CHUNKS = 500;

// Key to store the chunks in local storage
const LOCAL_STORAGE_KEY = 'file-chunks';

/**
 * Class responsible for handling file chunks.
 */
class FileChunks {
  constructor() {
    this.fileChunks = this.getFileChunks();
  }

  /**
   * Get the list of chunks from local storage, if it's empty return a new Map object.
   * @returns {Map<String, Object>} The list of chunks.
   */
  getFileChunks() {
    if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
      return new Map(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)));
    }
    return new Map();
  }

  /**
   * Get the last uploaded chunk for a file.
   * @param {string} fileId - File ID.
   * @returns {number} The last uploaded chunk.
   */
  getLastUploadedChunk(fileId) {
    return this.fileChunks.get(fileId)?.lastUploadedChunk || 0;
  }

  /**
   * Add a new chunk to the list of chunks.
   * if the chunk already exists it will be updated.
   * if the list of chunks is full it will delete the oldest chunk.
   * @param {string} fileId - File ID.
   * @param {number} chunkNumber - Last uploaded chunk.
   */
  setLastUploadedChunk(fileId, chunkNumber) {
    if (
      this.fileChunks.size >= MAX_NUMBER_OF_CHUNKS &&
      !this.fileChunks.has(fileId)
    ) {
      // delete the oldest chunk if MAX_NUMBER_OF_CHUNKS has been reached.
      this.deleteOldestChunk();
    }
    const chunk = {
      lastUploadedChunk: chunkNumber,
      uploadStartDate: Date.now(),
    };
    this.fileChunks.set(fileId, chunk);
    this.persist();
  }

  /**
   * Delete chunk for a file.
   * @param {string} fileId - File ID.
   */
  delete(fileId) {
    this.fileChunks.delete(fileId);
    this.persist();
  }

  /**
   * Delete the oldest chunk.
   * Since maps keep the insertion order, the first element will be the oldest.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
   */
  deleteOldestChunk() {
    const [firstKey] = this.fileChunks.keys();
    this.fileChunks.delete(firstKey);
  }

  /**
   * Delete all expired chunks.
   * @param {number} fileMaxAge - Number of days to consider the file as expired.
   */
  cleanupExpired(fileMaxAge) {
    const now = Date.now();
    const fileMaxAgeMilliseconds = fileMaxAge * 1000 * 60 * 60 * 24;
    this.fileChunks.forEach((chunk, key) => {
      if (chunk.uploadStartDate + fileMaxAgeMilliseconds < now) {
        this.fileChunks.delete(key);
      }
    });
    this.persist();
  }

  /**
   * Delete all chunks from local storage.
   */
  deleteAllChunks() {
    this.fileChunks.clear();
    this.persist();
  }

  /**
   * Generate a unique file ID based on the file metadata and the path.
   * @param {string} path - Uploaded path.
   * @param {File} file - File object.
   * @returns {string} File ID.
   */
  generateId(path, file) {
    const { name, lastModified, size } = file.data;
    const { extension, fileName } = this.splitFileNameExtension(name);
    const cleanFileName = sanitizeFileName(fileName);
    return `${path}/${cleanFileName}-${extension}-${lastModified}-${size}`;
  }

  /**
   * Split the file name and extension.
   * @param {string} fileName - File name.
   * @returns {{extension: string, fileName: string}} File name and extension.
   */
  splitFileNameExtension(fileName) {
    const fileNameSplit = fileName.split('.');
    // if the file name has no extension, the last element of the array will be the file name
    if (fileNameSplit.length === 1) {
      return {
        extension: '',
        fileName: fileNameSplit[0],
      };
    }
    const extension = fileNameSplit.pop();
    const fileNameWithoutExtension = fileNameSplit.join('.');
    return {
      extension,
      fileName: fileNameWithoutExtension,
    };
  }

  /**
   * Persist the list of chunks in local storage.
   */
  persist() {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify(Array.from(this.fileChunks.entries()))
    );
  }
}

export default FileChunks;
