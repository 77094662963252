<template>
  <a
    href="https://www.filecloud.com/eula/"
    class="px-2 text-primary"
  >
    <span class="dot"></span>
    {{ $t('Terms of Service') }}
  </a>
</template>

<script>
export default {
  name: 'TOSLink',
};
</script>

<style lang="sass" scoped>
.dot
  height: 4px
  width: 4px
  background-color: var(--color-primary)
  border-radius: 50%
  display: inline-block
  margin-left: 1px
  position: relative
  right: 2px
  top: -2px
</style>