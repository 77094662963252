var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"file-path"},[_vm._v(_vm._s(_vm.file.lockpath))]),_c('td',{staticClass:"centered"},[_vm._v(" "+_vm._s(_vm.file.lockreadlock ? _vm.$t('No') : _vm.$t('Yes'))+" ")]),_c('td',{staticClass:"centered"},[(_vm.lockexpiration)?_c('DateDisplay',{attrs:{"date":_vm.date,"only-date":""}}):_c('span',[_vm._v(_vm._s(_vm.$t('Never Expires')))])],1),_c('td',{staticClass:"centered"},[_c('v-popover',{on:{"update:open":(isOpen) => {
          _vm.isUnlockAlertOpen = isOpen;
        }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Remove Lock')),expression:"$t('Remove Lock')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Remove Lock'),"list-only":""}},[_c('Icon',{attrs:{"name":"unlock","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","visible":_vm.isUnlockAlertOpen,"button-type":"danger","buttons":[
          {
            label: `Unlock`,
            callback: function () {
              _vm.unlock(_vm.file.lockpath);
            },
          },
          { label: `Cancel`, outline: true, callback: function () {} },
        ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to unlock') + ' ')+" "+_vm._s(_vm.file.lockpath)+" ? ")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }