import _ from 'lodash';
export function customization(customdata = {}) {
  const customizations = { ...customdata };
  customizations.LOGINLOGO = customdata.loginlogo;
  customizations.DARKLOGO = customdata.maindarklogo;
  customizations.TRIALMODE = customdata.TRIALMODE;
  customizations.HOSTEDCLOUD = customdata.HOSTEDCLOUD;
  customizations.LOGINBG = customdata.loginbg;
  customizations.MAINBG = customdata.mainlogo;
  customizations.SHOWMORE = customdata.SHOWMORE;
  customizations.DISABLENEWSFEED = customdata.DISABLENEWSFEED;
  customizations.SIDEPANEL_LEFT_COLLAPSE =
    customdata.SIDEPANEL_LEFT_COLLAPSE == 1;
  customizations.SIDEPANEL_RIGHT_COLLAPSE =
    customdata.SIDEPANEL_RIGHT_COLLAPSE == 1;
  customizations.SIDEPANEL_EXPLORER_COLLAPSE =
    customdata.SIDEPANEL_EXPLORER_COLLAPSE == 1;
  customizations.USERLOGINCUSTOMOPTIONLABEL =
    customdata.USERLOGINCUSTOMOPTIONLABEL;
  customizations.USERLOGINCUSTOMOPTIONURL = customdata.USERLOGINCUSTOMOPTIONURL;
  customizations.ADDITIONALLOGINURLLINKS = customdata.ADDITIONALLOGINURLLINKS;
  customizations.RESTRICTSHARENAMECHANGE = customdata.RESTRICTSHARENAMECHANGE;
  customizations.WINDOW_TITLE = _.get(customdata, 'WINDOWTITLE', 'FileCloud');
  customizations.PRODUCT_NAME = _.get(customdata, 'PRODUCT_NAME', 'FileCloud');
  customizations.HIGH_CONTRAST_MODE = customdata.HIGH_CONTRAST_MODE == 1;
  customizations.HIDE_POWERED_BY = !!_.get(
    customdata,
    'OEM_DISABLE_OPTION1',
    false
  );
  customizations.SHOWINSTALLDESKTOPAPPS = !!_.get(
    customdata,
    'SHOWINSTALLDESKTOPAPPS',
    false
  );
  customizations.SHOWINSTALLMOBILEAPPS = !!_.get(
    customdata,
    'SHOWINSTALLMOBILEAPPS',
    false
  );
  customizations.SINGLEFILESHARE_FULLSCREEN_PREVIEW = !!_.get(
    customdata,
    'SINGLEFILESHARE_FULLSCREEN_PREVIEW',
    false
  );

  customizations.DESKTOPURL = _.get(
    customdata,
    'DESKTOPURL',
    'https://www.filecloud.com/additional-downloads/#sync'
  );
  if (typeof customizations.DESKTOPURL !== 'string') {
    customizations.DESKTOPURL =
      'https://www.filecloud.com/additional-downloads/#sync';
  }

  customizations.MOBILEURL = _.get(
    customdata,
    'MOBILEURL',
    'https://www.filecloud.com/additional-downloads/#mobile'
  );
  if (typeof customizations.MOBILEURL !== 'string') {
    customizations.MOBILEURL =
      'https://www.filecloud.com/additional-downloads/#mobile';
  }

  customizations.ADDINURL = _.get(
    customdata,
    'ADDINURL',
    'https://www.filecloud.com/additional-downloads/#plugins'
  );
  customizations.ENABLEJSPREVIEW = _.get(customdata, 'ENABLEJSPREVIEW', false);
  customizations.ENABLEDOCPREVIEW = customdata.ENABLEDOCPREVIEW == 1;
  customizations.ENABLEFCCONVERTERPREVIEW = customdata.ENABLEFCCONVERTERPREVIEW == 1;

  if (typeof customizations.ADDINURL !== 'string') {
    customizations.ADDINURL =
      'https://www.filecloud.com/additional-downloads/#plugins';
  }

  if (typeof customizations.INTRO_TOUR === 'string') {
    customizations.INTRO_TOUR = JSON.parse(customizations.INTRO_TOUR);
  } else {
    customizations.INTRO_TOUR = {};
  }

  if (
    !customizations.INITIALHISTORYSTRING ||
    typeof customizations.INITIALHISTORYSTRING !== 'string'
  ) {
    customizations.INITIALHISTORYSTRING = undefined;
  }

  if (typeof customizations.WINDOWTITLE !== 'string') {
    customizations.WINDOWTITLE = 'FileCloud';
  }

  customizations.showSSO = function () {
    return customdata.SHOWSSO;
  };
  customizations.showNewAccount = function () {
    return customdata.SHOWNEWACCOUNT;
  };
  customizations.isCommunityEdition = function () {
    return customdata.communityedition;
  };
  customizations.enabledWebEdit = function () {
    return customdata.ENABLEWEBEDIT;
  };
  customizations.enabledQuickEdit = function () {
    return customdata.ALLOWQUICKEDITALL;
  };
  customizations.getPhoneNumberHint = function () {
    return customdata.phonenumberhint;
  };
  return customizations;
}
