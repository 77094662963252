<template>
  <LeftTabbedModal
    v-shortkey="['esc']"
    class-name="mini-admin"
    name="mini-admin"
    :is-open="isModalOpen"
    :is-mobile-menu-open="isMobileMenuOpen"
    @close="handleClose"
    @toggleMobileMenu="(val) => (isMobileMenuOpen = val)"
    @shortkey="handleClose"
  >
    <template #title>
      <div class="d-flex align-items-center mini-admin__title">
        <h4 v-html="tabTitle" />
        <span
          v-if="isTrial && currentTab === 'general'"
          class="trial-badge"
          :class="{ ['expired']: isTrial && isLicenceExpired }"
        >
          {{ $t('Trial') }}
        </span>
        <a v-if="fullLink" target="_blank" :href="fullLink.link">{{
          fullLink.label
        }}</a>
      </div>
    </template>

    <template #tabs>
      <div class="position-relative w-100 h-100">
        <ul id="miniadmin-navigation">
          <li class="item-title">{{ $t('Mini Admin').toUpperCase() }}</li>
          <li
            :class="{ active: currentTab === 'general' }"
            @click="switchTab('general')"
          >
            <Icon family="fas" name="th-large" />
            <span>{{ $t('General') }}</span>
          </li>

          <li
            id="miniadmin-users-link"
            :class="{ active: currentTab === 'users' }"
            @click="switchTab('users')"
          >
            <Icon family="fas" name="users" />
            <span>{{ $t('Users') }}</span>
          </li>

          <li
            :class="{ active: currentTab === 'customization' }"
            @click="switchTab('customization')"
          >
            <Icon family="fas" name="palette" />
            <span>{{ $t('Customization') }}</span>
          </li>

          <li
            :class="{ active: currentTab === 'billing' }"
            @click="
              openURI(
                'https://portal.getfilecloud.com/ui/user/index.html#/billing'
              )
            "
          >
            <Icon family="fas" name="receipt" />
            <span>{{ $t('Billing') }}</span>
          </li>

          <li class="item-title">{{ $t('Full Admin').toUpperCase() }}</li>

          <li @click="openURI('/ui/admin/index.html#dash.')">
            <Icon family="fas" name="user-crown" />
            <span>{{ $t('Switch to Admin Portal') }}</span>
          </li>
        </ul>

        <a href="#" class="need__help">
          <Icon
            family="fad"
            name="user-headset"
            style="
              --fa-primary-color: var(--text-muted);
              --fa-secondary-color: var(--color-primary);
              --fa-primary-opacity: 1;
              --fa-secondary-opacity: 1;
            "
          />

          <div
            class="d-flex flex-column"
            @click="
              openURI(
                'https://www.filecloud.com/supportdocs/display/cloud/Contact+FileCloud+Support'
              )
            "
          >
            <h5>{{ $t('Need help?') }}</h5>
            <span>{{ $t('Contact support') }}</span>
          </div>
        </a>
      </div>
    </template>

    <template #subheader>
      <portal-target name="modal-subheader" />
    </template>

    <template #body>
      <transition mode="out-in" name="fade">
        <component :is="currentTabComponent" @switchTab="switchTab" />
      </transition>

      <MiniAdminAddUser :visible="isAddUserModalOpen" />
    </template>
  </LeftTabbedModal>
</template>

<script>
import _ from 'lodash';
import Loader from 'common/components/Loader.vue';
import Icon from 'common/components/Icon.vue';
import LeftTabbedModal from '@/components/LeftTabbedModal';
import MiniAdminAddUser from '@/components/Modals/MiniAdminAddUser.vue';

import Users from '@/components/MiniAdmin/Users.vue';
import Customization from '@/components/MiniAdmin/Customization.vue';
import General from '@/components/MiniAdmin/General.vue';

export default {
  name: 'MiniAdmin',
  components: {
    LeftTabbedModal,
    Icon,
    Loader,
    MiniAdminAddUser,
  },
  data() {
    return {
      isMobileMenuOpen: false,
    };
  },
  computed: {
    currentTab() {
      return this.$store.state.miniAdmin.miniAdminModal.tab;
    },
    currentTabComponent() {
      switch (this.currentTab) {
        case 'users':
          return Users;
        case 'customization':
          return Customization;
        default:
          return General;
      }
    },
    tabTitle() {
      return this.currentTab !== 'general'
        ? this.$t(_.startCase(this.currentTab))
        : this.isCloud
        ? this.$t('FileCloud Online')
        : this.$t('FileCloud On-Premise');
    },
    fullLink() {
      const data = {
        users: {
          label: this.$t('Go to Full User Management'),
          link: '/ui/admin/index.html#user.',
        },
        customization: {
          label: this.$t('Go to Full Customization'),
          link: '/ui/admin/index.html#cust.',
        },
      };

      return data[this.currentTab];
    },
    isTrial() {
      return !!this.$store.state.core.customization['TRIALMODE'];
    },
    stats() {
      return this.$store.state.miniAdmin.stats;
    },
    isLicenceExpired() {
      return this.stats && this.stats.license
        ? this.stats.license.daysremaining > 0
          ? false
          : true
        : false;
    },
    isCloud() {
      return !!this.$store.state.core.customization['HOSTEDCLOUD'];
    },
    isModalOpen() {
      return this.$store.state.miniAdmin.miniAdminModal.show;
    },
    isAddUserModalOpen() {
      return this.$store.state.miniAdmin.addUserModal.show;
    },
  },
  methods: {
    switchTab(tab) {
      this.isMobileMenuOpen = false;
      this.$store.dispatch('miniAdmin/setCurrentTab', tab);
    },
    handleSwitchToAdmin() {
      window.location.href = '#';
    },
    handleClose() {
      this.$store.dispatch('miniAdmin/showMiniAdminModal', { visible: false });
    },
    openURI(uri) {
      window.open(uri, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.mini-admin__title {
  max-width: 100%;
  min-width: 0;

  h4 {
    text-transform: capitalize;
  }

  span.trial-badge {
    display: flex;
    text-transform: lowercase;
    align-items: center;
    justify-content: center;
    color: var(--color-primary--foreground);
    font-weight: 500;
    padding: 7px 10px;
    background: var(--color-primary);
    border-radius: 29px;
    height: 11px;
    margin-left: 9px;
    font-size: 9px;

    &.expired {
      background: var(--color-danger);
    }
  }

  a {
    font-size: 12px;
    margin-left: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.need__help {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 15px;
  position: absolute;
  bottom: 0;
  text-decoration: none;
  background: var(--bg-light);
  border: 1px solid var(--border-color);
  border-radius: 3px;

  i {
    font-size: 28px;
  }

  div {
    margin-left: 12px;

    h5 {
      color: var(--text-medium);
      font-size: 12px;
      font-weight: 600;
      margin: 0;
    }

    span {
      color: var(--text-muted);
      font-size: 10px;
      font-weight: 500;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
