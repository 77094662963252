<template>
  <div class="row-heading">
    <div class="name">
      {{ $t(label) }}
    </div>
    <div v-for="(perm, key) in permissions" :key="key" class="view">
      <div><Icon family="fas" :name="perm.name" /></div>
      <span>{{ $t(perm.label) }}</span>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    isShareContext: {
      type: Boolean,
      default: true,
    },
    isSecurityContext: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allPermissions: [
        {
          label: 'View',
          name: 'eye',
          test: this.isShareContext,
        },
        {
          label: 'Read',
          name: 'eye',
          test: this.isSecurityContext,
        },
        {
          label: 'Write',
          name: 'pencil',
          test: this.isSecurityContext,
        },
        {
          label: 'Download',
          name: 'download',
          test: this.isShareContext,
        },
        {
          label: 'Upload',
          name: 'upload',
          test: this.isShareContext,
        },
        {
          label: 'Share',
          name: 'share-alt',
          test: this.isShareContext || this.isSecurityContext,
        },
        {
          label: 'Sync',
          name: 'sync',
          test: this.isShareContext,
        },
        {
          label: 'Delete',
          name: 'trash',
          test: this.isShareContext || this.isSecurityContext,
        },
        {
          label: 'Manage',
          name: 'cog',
          test: this.isShareContext || this.isSecurityContext,
        },
      ],
    };
  },
  computed: {
    permissions() {
      return this.allPermissions.filter((perm) => perm.test);
    },
  },
};
</script>
