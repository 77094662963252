import Vue from 'vue';
import Vuex from 'vuex';
import { Themes } from '../../constants/themes';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    themeScheme: 'default',
  },
  mutations: {
    setThemeScheme(state, scheme) {
      Vue.set(state, 'themeScheme', scheme);
    },
  },
  getters: {
    themeScheme: (state) => {
      return { ...Themes['default'], ...Themes[state.themeScheme] };
    },
  },
};
