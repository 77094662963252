<template>
  <div class="container snippet-search p-0">
    <div class="snippet-search-element col-md-12 m-0 row" @click="showResults">
      <div class="col p-0 m-0 col-7 pt-2">
        <span class="search-pretext"> {{ $t('Search for ') }} </span>
        <span class="search-term">"{{ searchString }}"</span>
      </div>
      <div class="col col-5 p-0 m-0 pt-2">
        <span class="search-term-show-results" @click="goToSearchResults">
          {{ $t('Show Results') }}</span
        >
      </div>
    </div>

    <div
      v-if="previousSearchTerm !== ''"
      class="snippet-search-element col-12 p-0 m-0 row"
      @click="showPrevious"
    >
      <div class="col col-1 pt-2 colored-beige">
        <ThinSearchIcon class="filled" />
      </div>
      <div class="col col-10 p-0 m-0 pl-1">
        <div class="col search-showprevious-text m-0 p-0 pl-2">
          {{ $t('Show Previous Search Results') }}
        </div>
        <div class="col previous-search-term m-0 p-0 pl-2">
          {{ $t('Searched for') }} <strong>"{{ previousSearchTerm }}"</strong>
        </div>
      </div>
    </div>

    <div class="snippet-search-element col-12 m-0 row" @click="showAdvanced">
      <div class="col-1 p-0 col pt-2">
        <LookingGlassIcon class="filled" />
      </div>
      <div class="col-10 col p-0 m-0 pt-2 pl-1">
        <span class="advanced-search col-md-12 pl-0">{{
          $t('Advanced Search')
        }}</span>
      </div>
    </div>
    <div
      v-if="searchFromCurrentLocation"
      class="snippet-search-element col-12 m-0 row"
      @click="searchGlobal"
    >
      <div class="col-1 p-0 col pt-2">
        <GlobalSearch class="filled" />
      </div>
      <div class="col-10 col p-0 m-0 pt-2 pl-1">
        <span class="advanced-search col-md-12 pl-0">{{
          $t('Show Global Search Results')
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import ThinSearchIcon from 'common/assets/thinsearchicon.svg';
import LookingGlassIcon from 'common/assets/lookingglassicon.svg';
import GlobalSearch from 'common/assets/globalsearchicon.svg';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  components: {
    ThinSearchIcon,
    LookingGlassIcon,
    GlobalSearch,
  },
  props: {
    searchInProgress: {
      type: Boolean,
      default: false,
    },
    searchTerm: {
      type: String,
      required: true,
      default: '',
    },
    previousSearchTerm: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('core', ['startSearchInCurrentlocation']),
    hasSearched() {
      return !_.isEmpty(this.$store.state.files.searchQuery);
    },
    searchFromCurrentLocation() {
      return this.startSearchInCurrentlocation && this.$route.name !== 'files';
    },
    searchString() {
      // only show snippet for 2 chars or more
      if (this.searchTerm.length >= 2) {
        return this.searchTerm;
      }

      return '';
    },
  },
  methods: {
    goToSearchResults() {
      this.$router.push('/srch-tabl.');
    },
    showResults() {
      if (this.searchInProgress) return;
      this.$emit('showResults');
    },
    showPrevious() {
      if (this.searchInProgress) return;
      this.$emit('showPrevious', { searchterm: this.previousSearchTerm });
    },
    showAdvanced() {
      this.$emit('showAdvanced');
    },
    searchGlobal() {
      this.$emit('searchGlobal');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

.snippet-search {
  /* Style for "Rectangle" */
  width: 309px;
  box-shadow: 0 2px 2px var(--shadow-color);
  border-radius: 4px;
  border: solid 1px var(--border-color);
  background-color: var(--bg-light);
  font-size: 13px;

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: 400px;
  }
}
.snippet-search-element {
  /* Style for "Rectangle" */
  height: 44px;
  border-radius: 4px 4px 0 0;
  background-color: var(--bg-light);
  font-size: 14px;
  color: var(--text-mid-dark);
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  border-bottom: solid 1px var(--border-color);
  cursor: pointer;
  overflow-y: hidden;
  .col {
    padding-top: 10px;
  }

  .search-term-show-results {
    float: right;
    /* Style for "Show Resul" */
    color: var(--color-primary);
    font-size: 12px;
    font-weight: 400;
  }

  .search-pretext {
    /* Style for "Search for" */
    color: var(--text-mid-dark);
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 13px;
  }

  .search-term {
    font-size: 13px;
    color: var(--text-mid-dark);
    font-weight: 700;
  }
  .search-showprevious-text {
    /* Style for "Show Previ" */
    color: var(--text-mid-dark);
    font-size: 14px;
    font-weight: 400;
    height: 20px;
  }
  .previous-search-term {
    /* Style for "Searched f" */
    font-size: 11px;
    height: 20px;
    padding-top: -10px;
    font-weight: 400;
    /* Text style for "Searched f" */
    color: var(--text-light);
    b {
      color: var(--text-mid-dark);
      font-weight: 500;
    }
  }
  .advanced-search {
    /* Style for "Advanced S" */
    color: var(--text-mid-dark);
    font-size: 14px;
    font-weight: 400;
  }
}
.snippet-search-body {
  color: white;
}

.filled {
  fill: var(--text-light);
}
</style>
