<template>
  <div @click="$emit('editing')">
    {{ $t('if') }}
    <span v-if="query.negation">{{ $t('not') }} </span>
    <b>{{ query.operand }}</b>
    {{ query.selectedOperand }}
    <span v-if="query.operator.label">{{ query.operator.label }}</span>
    <span v-else>{{ query.operator }}</span>
    {{ query.selectedOperator }}
    <b>{{ query.value }}</b>
  </div>
</template>

<script>
export default {
  name: 'QueryDispaly',
  props: ['query'],
};
</script>
