import styled from 'vue-styled-components';
const themedProps = {
  textColor: Boolean,
  backgroundColor: Boolean,
  primaryAsBackground: Boolean,
  primaryAsText: Boolean,
};

const ThemedDiv = styled('div', themedProps)`
  color: ${(props) =>
    props.textColor
      ? (props.primaryAsText
          ? props.theme.primaryColor
          : props.theme.textColor) + ' !important'
      : 'inherit'};
  background-color: ${(props) =>
    props.backgroundColor
      ? (props.primaryAsBackground
          ? props.theme.primaryColor
          : props.theme.backgroundColor) + ' !important'
      : 'inherit'};
  transition: color 0.2s, background-color 0.2s;
`;

export default ThemedDiv;
