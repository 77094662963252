<template>
  <div
    ref="previewWrapper"
    :class="{
      ['file-preview--active']: isActive,
      ['file-preview--no-print']: currentFile && !currentFile.candownload,
    }"
    class="file-preview"
  >
    <!-- region Navigation -->
    <nav
      v-if="isActive"
      v-shortkey="{ next: ['arrowright'], prev: ['arrowleft'], close: ['esc'] }"
      class="navbar navbar-expand-lg file-preview-header"
      @shortkey="onShortcut"
    >
      <!-- region Navigation Title -->
      <a
        v-if="currentFile && currentFile.name"
        tabindex="0"
        class="navbar-title"
      >
        <FileIcon
          :name="currentFile.name"
          :item="currentFile"
          :format="fileFormat"
        />
        <div class="navbar-title-file">{{ currentFile.name }}</div>
      </a>
      <!-- endregion -->

      <!-- region Editor Clients -->
      <div v-if="canEditFile">
        <VPopover v-if="editorClientLength > 1">
          <button
            class="btn btn-sm btn-primary mr-3"
            :disabled="!currentFile.candownload"
          >
            <Icon name="edit" family="fas" />
            Open With
            <Icon name="chevron-down" family="fas" class="ml-2" />
          </button>
          <Alert
            slot="popover"
            button-type="primary"
            class="p-0 webedit-popover"
          >
            <div class="list-group list-group-flush cursor-pointer">
              <!-- Desktop -->
              <span
                v-if="
                  isWebEditEnabled &&
                  canEditFileType(
                    fileExtension,
                    'desktop',
                    allowQuickEditAll
                  ) &&
                  currentFile.showquickedit &&
                  !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(fileExtension))
                "
                class="list-group-item list-group-item-action text-left"
                @click="() => runQuickEdit(currentFile.path)"
              >
                <span class="d-flex align-items-center">
                  <div class="fc-desktop-icon pr-1" />
                  <span>{{ desktopEditorClientName }}</span>
                </span>
              </span>

              <!-- Web Edit -->
              <span
                v-if="
                  isWOPIEditEnabled && 
                  canEditFileType(fileExtension, 'web') && 
                  !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(fileExtension))
                "
                class="list-group-item list-group-item-action text-left"
                @click="onEditFile"
              >
                <span class="d-flex align-items-center">
                  <Icon name="edit" family="fal" class="pr-1" />
                  <span>Edit Online</span>
                </span>
              </span>

              <!-- Google Apps -->
              <span
                v-if="
                  isWOPIEditEnabled &&
                  canEditFileType(currentFile.ext, 'google') &&
                  isGoogleDocsEnabled
                "
                class="list-group-item list-group-item-action text-left"
                @click="openInGoogleDocs"
              >
                <span class="d-flex align-items-center">
                  <div :class="googleAppsClient.iconClass" />
                  <span>{{ googleAppsClient.label }}</span>
                </span>
              </span>

              <!-- WOPI -->
              <span
                v-if="
                  isWOPIEditEnabled &&
                  WOPIClient &&
                  canEditFileType(fileExtension, WOPIClient.key)
                "
                class="list-group-item list-group-item-action text-left"
                @click="onEdit"
              >
                <span class="d-flex align-items-center">
                  <div :class="WOPIClient.iconClass" />
                  {{ WOPIClient.label }}
                </span>
              </span>
            </div>
          </Alert>
        </VPopover>

        <!-- Desktop Editor -->
        <button
          v-else-if="
            isWebEditEnabled &&
            canEditFileType(fileExtension, 'desktop', allowQuickEditAll) &&
            currentFile.showquickedit &&
            !(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(fileExtension))
          "
          v-a11ybutton="() => runQuickEdit(currentFile.path)"
          class="btn btn-sm btn-primary d-flex align-items-center mr-3"
          tabindex="0"
          @click="() => runQuickEdit(currentFile.path)"
        >
          <div class="fc-desktop-icon pr-2" />
          Open in {{ desktopEditorClientName }}
        </button>

        <!-- Web edit -->
        <button
          v-else-if="
            canEditFileType(fileExtension, 'web') && currentFile.candownload
          "
          v-a11ybutton="onEditFile"
          class="btn btn-sm btn-primary d-flex align-items-center"
          tabindex="0"
          @click="onEditFile"
        >
          <Icon name="edit" family="fas" class="pr-2" />
          Edit Online
        </button>

        <!-- WOPI CLIENT -->
        <button
          v-else-if="
            isWOPIEditEnabled &&
            WOPIClient &&
            canEditFileType(fileExtension, WOPIClient.key)
          "
          v-a11ybutton="onEdit"
          class="btn btn-sm btn-primary d-flex align-items-center"
          tabindex="0"
          @click="onEdit"
        >
          <div :class="WOPIClient.iconClass" />
          {{ WOPIClient.fullLabel }}
        </button>

        <!-- Google Docs -->
        <button
          v-else-if="
            isWOPIEditEnabled &&
            isGoogleDocsEnabled &&
            canEditFileType(currentFile.ext, 'google')
          "
          v-a11ybutton="openInGoogleDocs"
          class="btn btn-sm btn-primary d-flex align-items-center"
          tabindex="0"
          @click="openInGoogleDocs"
        >
          <div :class="googleAppsClient.iconClass" />
          {{ googleAppsClient.fullLabel }}
        </button>
      </div>
      <!-- endregion -->

      <!-- region PDF Tools -->
      <PDFTools
        v-if="isPdfToolsView"
        :is-expanded="isPDFExpanded"
        :current-zoom="currentPdfZoom"
        :can-print="currentFile.candownload"
        :ready="pdfReady"
        @print="onPrintFile"
      />
      <!-- endregion -->

      <!-- region Navigation Buttons -->
      <a
        v-if="fileFormat === 'img'"
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Rotate Right',
        }"
        v-a11ybutton="
          () => {
            onRotate('right');
          }
        "
        tabindex="0"
        class="navbar-icon"
        :class="{ disabled: isLoading }"
        @click="onRotate('right')"
      >
        <Icon family="fas" name="redo-alt" />
      </a>
      <a
        v-if="fileFormat === 'img'"
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Rotate Left',
        }"
        v-a11ybutton="
          () => {
            onRotate('left');
          }
        "
        tabindex="0"
        class="navbar-icon"
        :class="{ disabled: isLoading }"
        @click="onRotate('left')"
      >
        <Icon family="fas" name="undo-alt" />
      </a>
      <a
        v-if="index !== null"
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Previous file',
        }"
        v-a11ybutton="prev"
        tabindex="0"
        class="navbar-icon"
        :class="{ disabled: index === 0, 'ml-auto': !isMobileDevice }"
        @click="prev"
      >
        <Icon family="fas" name="chevron-left" />
      </a>
      <a
        v-if="index !== null"
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Next file',
        }"
        v-a11ybutton="next"
        tabindex="0"
        class="navbar-icon"
        :class="{ disabled: index === files.length - 1 }"
        @click="next"
      >
        <Icon family="fas" name="chevron-right" />
      </a>
      <a
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Download file',
        }"
        v-a11ybutton="onDownload"
        tabindex="0"
        class="navbar-icon"
        :class="{
          disabled: isDownloadHidden,
        }"
        @click="onDownload"
      >
        <Icon family="fas" name="download" />
      </a>
      <a
        v-tooltip="{
          delay: { show: 1000, hide: 0 },
          offset: -5,
          content: 'Close file preview',
        }"
        v-a11ybutton="close"
        tabindex="0"
        class="navbar-icon"
        style="font-size: 150%"
        @click="close"
      >
        <Icon family="far" name="times" />
      </a>
      <!-- endregion -->
    </nav>
    <!-- endregion -->

    <!-- region Loading View -->
    <div v-if="!isError && isLoading" class="file-preview-portal">
      <LoadingView />
    </div>
    <!-- endregion -->

    <!-- region Error View -->
    <div
      v-else-if="isError"
      class="file-preview-portal d-flex justify-content-center align-items-center"
    >
      <div class="file-preview-portal--error" v-html="errorMsg" />
    </div>
    <!-- endregion -->

    <!-- region Component View -->
    <div
      v-else-if="isActive"
      class="file-preview-portal"
      :class="{ 'file-preview-portal--zip-viewer': isPublic }"
      :style="[currentView === 'zip' && { height: auto }]"
    >
      <component
        :is="currentView"
        v-if="isActive"
        ref="viewerComponent"
        :file="currentFile"
        :edit="edit"
        :pdf-expanded="isPDFExpanded"
        :zoom="currentPdfZoom"
        :is-public-share-view="isPublic"
        @download="onDownload"
        @edit="onEdit"
        @fallback="onFallback"
        @pdfReady="makePDFReady"
        @close="close"
      />
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import FileIcon from './FileIcon.vue';
import Icon from './Icon.vue';

import _ from 'lodash';
import { createFocusTrap } from 'focus-trap';
import { mapGetters } from 'vuex';
import { encodeAll } from 'common/utils/URLUtils';

import OfficeView from './FilePreview/Office.vue';
import DefaultView from './FilePreview/Default.vue';
import CadView from './FilePreview/Cad.vue';
// import EMLView from './FilePreview/EML.vue'; // commented due to the issue with safari browsers
import LoadingView from './FilePreview/Loading.vue';
import DocConvertView from './FilePreview/DocConvert.vue';
import ZipView from './FilePreview/Zip.vue';

import PDFTools from './FilePreview/PDF/Toolbar';

import { getFileType, getParams, canEditFileType, isExtensionBlackListed } from '../utils/files.js';

import OfficeIcon from '../assets/office.svg';

import { isMobileDevice } from '../utils/responsive.js';
import { VPopover } from 'v-tooltip';
import Alert from './Alert';

export default {
  components: {
    FileIcon,
    OfficeIcon,
    Icon,
    PDFTools,
    // file views
    DefaultView,
    CadView,
    // EMLView, // commented due to the issue with safari browsers
    LoadingView,
    OfficeView,
    DocConvertView,
    VPopover,
    Alert,
    ZipView,
  },
  data() {
    return {
      isError: false,
      isLoading: true,
      errorMsg: '',
      isActive: false,
      items: null,
      singleItem: {},
      index: 0,
      fallback: null,
      edit: false,
      isPDFExpanded: false,
      currentPdfZoom: 0,
      pdfReady: false,
      shiftPreview: false,
      focusTrap: null,
      secureFolderPass: null,
    };
  },
  computed: {
    ...mapGetters('core', [
      'WOPIClient',
      'isGoogleDocsEnabled',
      'getGoogleDocFormatInfo',
      'isWOPIEditEnabled',
      'isWebEditEnabled',
      'getEditorClientName',
      'allowQuickEditAll',
    ]),
    desktopEditorClientName() {
      return this.getEditorClientName(this.currentFile);
    },
    isPdfToolsView() {
      return (
        this.currentFile &&
        (this.fileFormat === 'pdf' || this.fileFormat === 'ai')
      );
    },
    currentFile() {
      if (this.index !== null) {
        return this.files && this.files[this.index]
          ? this.files[this.index]
          : null;
      } else {
        return this.singleItem;
      }
    },
    googleAppsClient() {
      return this.getGoogleDocFormatInfo(this.fileFormat);
    },
    isPublic() {
      const { mode } = getParams();
      return (
        mode && (mode === 'public' || mode === 'single' || mode === 'upload')
      );
    },
    fileFormat() {
      return getFileType(this.currentFile.ext || this.currentFile.name);
    },
    fileExtension() {
      let regex = new RegExp('[^.]+$');
      return this.currentFile.name.match(regex)[0];
    },
    files() {
      return typeof this.items === 'string'
        ? this.$store.getters['files/getFilesInPath'](this.items)
        : this.items;
    },
    user() {
      return this.$store.state.auth.user;
    },
    encryptedFolderPasswords() {
      return this.$store.state.files.encryptedFolderPasswords;
    },
    currentView() {
      if (!this.currentFile) return;

      if (this.fallback && this.items !== null) return this.fallback;

      const isJSPreviewEnabled = this.customization.ENABLEJSPREVIEW;

      if (this.fileFormat) {
        // Handle "Disable Music Playback" from customizations
        if (
          this.fileFormat === 'audio' &&
          this.systemStatus.disablemusic == 1
        ) {
          return 'DefaultView';
        }
        if (
          ['cad'].indexOf(this.fileFormat) > -1 &&
          this.customization.ENABLECADVIEWER &&
          !this.isPublic
        ) {
          return 'CadView';
        }

        if (['zip'].indexOf(this.fileFormat) > -1) {
          return 'ZipView';
        }

        // if (['eml'].indexOf(this.fileFormat) > -1) { // commented due to the issue with safari browsers
        //   return 'EMLView';
        // }

        if (isJSPreviewEnabled && this.currentFile.candownload) {
          // office files
          if (
            ['excel', 'word', 'powerpoint', 'visio'].indexOf(this.fileFormat) >
              -1 &&
            this.customization.ENABLEDOCPREVIEW
          ) {
            return this.shiftPreview ? 'DocConvertView' : 'OfficeView';
          }

          if (
            (this.fileFormat === 'pdf' || this.fileFormat === 'ai') &&
            this.customization.ENABLEDOCPREVIEW
          ) {
            return this.shiftPreview
              ? 'DocConvertView'
              : () => import('./FilePreview/PDF/Viewer.vue');
          }

          // media files
          if (['md'].indexOf(this.fileExtension) > -1) {
            return () =>
              import(`./FilePreview/${_.capitalize(this.fileExtension)}.vue`);
          } else if (
            ['img', 'audio', 'video', 'txt'].indexOf(this.fileFormat) > -1
          ) {
            return () =>
              import(`./FilePreview/${_.capitalize(this.fileFormat)}.vue`);
          } else if (['dicom'].indexOf(this.fileFormat) > -1) {
            return () =>
              import(`./FilePreview/${_.capitalize(this.fileExtension)}.vue`);
          }
        } else {
          if (this.customization.ENABLEDOCPREVIEW) {
            if (['excel', 'word', 'powerpoint'].indexOf(this.fileFormat) > -1) {
              return this.shiftPreview ? 'DocConvertView' : 'OfficeView';
            }
            if (
              this.fileFormat === 'pdf' ||
              this.fileFormat === 'ai' ||
              ['txt', 'md'].indexOf(this.fileFormat) > -1
            ) {
              return 'DocConvertView';
            }
            if (['img', 'audio', 'video'].indexOf(this.fileFormat) > -1) {
              return () =>
                import(`./FilePreview/${_.capitalize(this.fileFormat)}.vue`);
            }
            if (['txt', 'md'].indexOf(this.fileFormat) > -1) {
              return 'DocConvertView';
            }
          }
          if (['img', 'audio', 'video'].indexOf(this.fileFormat) > -1) {
            return () =>
              import(`./FilePreview/${_.capitalize(this.fileFormat)}.vue`);
          }
        }
        // default view
        return 'DefaultView';
      } else {
        this.$store.dispatch('files/loadSection', {
          index: this.currentFile.id,
          path: this.items,
        });

        // default view
        return 'LoadingView';
      }
    },
    isOffice() {
      if (!this.currentFile || !this.fileFormat) return false;
      const officeFileTypes = ['word', 'powerpoint', 'excel'];
      return officeFileTypes.indexOf(this.fileFormat) > -1;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    isInRecycleFolder() {
      return this.$route.fullPath.indexOf('recyclebin') > -1;
    },
    isImplicitlyLocked() {
      return this.currentFile.implicitlock;
    },
    isLockedByOthers() {
      return !!(
        this.currentFile.locked &&
        this.currentFile.lockuserid !== this.user.profile
      );
    },
    canEditFile() {
      return (
        !this.communityEdition &&
        !this.isInRecycleFolder &&
        (this.isWOPIEditEnabled || this.isWebEditEnabled) &&
        this.systemStatus.enablewebedit &&
        !(this.isLockedByOthers && !this.isImplicitlyLocked) &&
        this.currentFile.candownload &&
        this.currentFile.canupload &&
        this.currentFile.type === 'file' &&
        ((this.WOPIClient &&
          this.canEditFileType(this.currentFile.ext, this.WOPIClient.key)) ||
          (this.isWebEditEnabled &&
            this.canEditFileType(
              this.currentFile.ext,
              'desktop',
              this.allowQuickEditAll
            ) &&
            this.currentFile.showquickedit) ||
          (canEditFileType(this.currentFile.ext, 'google') &&
            this.isGoogleDocsEnabled &&
            this.googleAppsClient) ||
          this.canEditFileType(this.currentFile.ext, 'web'))
      );
    },
    editorClientLength() {
      //this.isWebEditEnabled
      let clients = 0;

      // WOPI
      if (
        this.isWOPIEditEnabled &&
        this.WOPIClient &&
        canEditFileType(this.currentFile.ext, this.WOPIClient.key)
      )
        clients++;

      //Google Apps
      if (
        this.isGoogleDocsEnabled &&
        this.canEditFileType(this.currentFile.ext, 'google') &&
        this.googleAppsClient &&
        this.isWOPIEditEnabled
      )
        clients++;

      //Web Edit
      if (
        this.canEditFileType(this.currentFile.ext, 'web') &&
        this.isWOPIEditEnabled
      )
        clients++;

      //Desktop edit
      if (
        this.isWebEditEnabled &&
        this.canEditFileType(
          this.currentFile.ext,
          'desktop',
          this.allowQuickEditAll
        )
      )
        clients++;

      return clients;
    },
    communityEdition() {
      return this.customization && this.customization.isCommunityEdition();
    },
    isDownloadHidden() {
      const currentFile = this.currentFile;
      if (
        currentFile.type === 'dir' &&
        this.customization.DISABLEFOLDERDOWNLOAD === 1
      )
        return false;

      return !(
        currentFile &&
        currentFile.candownload &&
        !currentFile.lockreadlock
      );
    },
    isMobileDevice,
  },
  watch: {
    // allow or disallow downloading / printing via context menu
    currentFile(file) {
      if (file?.candownload) {
        this.$refs.previewWrapper.removeEventListener(
          'contextmenu',
          this.blockDefault
        );
      } else {
        this.$refs.previewWrapper.addEventListener(
          'contextmenu',
          this.blockDefault
        );
      }
    },
  },
  mounted() {
    this.$preview.subscription.$on('preview', this.preview.bind(this));
  },
  destroyed() {
    this.$preview.subscription.$off('preview', this.preview.bind(this));
    document.getElementsByTagName('body')[0].style.overflowY = 'auto';
  },
  methods: {
    isExtensionBlackListed,
    async runQuickEdit(path) {
      this.$toast.open({
        message:
          '<b>' +
          'Editing requires Desktop Edit App' +
          '</b><p role="alert">' +
          (this.customization.PRODUCT_NAME === 'FileCloud'
            ? 'Ensure FileCloud Desktop Edit is running to quickly edit files'
            : 'Ensure desktop edit app is running to quickly edit files') +
          '</p>',
        type: 'info',
      });
      const filePath =
        _.isObject(path) && path.showquickedit === 1 ? path.fullfilename : path;

      setTimeout(function () {
        window.open(
          `http://127.0.0.1:34320/webedit?html=1&path=${encodeAll(filePath)}`,
          'Quick Edit',
          'width=500,height=600'
        );
      }, 1500);
    },
    async dlpPreCheck() {
      const self = this;
      this.currentFile.dlpCheck = true;
      const url = await this.$store.dispatch('files/url', this.currentFile);
      delete this.currentFile.dlpCheck;
      if (
        self.currentFile.lockreadlock &&
        self.user.profile !== self.currentFile.lockuserid
      ) {
        self.errorMsg = `Preview Failed: <br>  <i class="inline-icon fas fa-lock"></i> ${self.failMsg(
          'Download blocked by'
        )} ${self.currentFile.lockuserid}`;
      } else if (url === false) {
        self.errorMsg = `Preview Failed: ${self.failMsg(
          'download permission is not enabled'
        )}`;
      } else {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 2 && this.status == 200) {
            xhttp.abort();
            self.isLoading = false;
          } else if (this.readyState == 4 && this.status >= 400) {
            self.isLoading = false;
            self.errorMsg = `Preview Failed: ${self.failMsg(
              xhttp.responseText
            )}`;
          }
        };
        xhttp.open('GET', url, true);
        xhttp.send();
      }
    },
    blockDefault(e) {
      e.preventDefault();
    },
    failMsg(msg) {
      this.isError = true;
      this.isLoading = false;
      if (this.currentFile.candownload) {
        return msg;
      } else {
        return '<h6>The share configuration does not allow preview - download permission is not enabled</h6>';
      }
    },
    async preview(items, index, shift) {
      this.shiftPreview = shift;
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      this.isLoading = true;

      this.fallback = null;

      if (typeof items === 'string') {
        this.items = items;
        this.index = index;
      } else if (Array.isArray(items)) {
        this.items = items;

        this.index = index;

        for (let i = 0; i < items.length; i++) {
          if (!items[i].path && items[i].sharelocation.length > 0) {
            items[i].path = items[i].sharelocation;
          }
        }
      } else if (typeof items === 'object') {
        this.singleItem = items;
        this.index = null;
      }
      await this.dlpPreCheck();
      this.isActive = true;
      // mobile devices shouldn't have focus trap.
      if (!this.isMobileDevice) {
        this.handleFocusTrapInit();
      }
    },
    onDownload() {
      this.$store.dispatch('files/download', this.currentFile);
    },
    openInGoogleDocs() {
      this.onEdit({ googleEdit: true });
    },
    onEdit(params) {
      let hasError = false;

      if (this.currentFile.canupload !== 1 && this.$route.name !== 'files') {
        // if excel and readonly
        if (this.currentFile.format == 'excel') {
          hasError = true;
        }
        // if powerpoint, locked by others and readonly
        const isLockedByOthers =
          this.currentFile.locked &&
          this.currentFile.lockuserid !== this.$store.state.auth.user.profile;
        if (this.currentFile.format === 'powerpoint' && isLockedByOthers) {
          hasError = true;
        }
      }

      if (hasError) {
        const title = 'Error';
        const message = "We're sorry. We couldn't lock this file for editing.";
        this.$toast.open({
          message: `<b>${title}</b><p>${message}</p>`,
          type: 'warning',
        });
      } else {
        this.currentFile.googleEdit = params?.googleEdit || false;
        this.$store.dispatch('files/wopiEdit', this.currentFile);
        delete this.currentFile.googleEdit;
      }
    },
    onFallback(view) {
      this.fallback = view;
    },

    onShortcut(e) {
      switch (e.srcKey) {
        case 'next':
          this.next();
          break;
        case 'prev':
          this.prev();
          break;
        case 'close':
          this.close();
          break;
        default:
      }
    },
    onEditFile() {
      if (canEditFileType(this.currentFile.ext, 'onlyoffice')) {
        this.onEdit();
        return;
      }
      this.$router.push({
        name: 'webeditor',
        query: { filepath: this.currentFile.path, fromhome: false },
      });

      this.isActive = false;
    },
    canEditFileType,
    refresh() {
      this.isActive = false;
      this.isError = false;
      this.dlpPreCheck();
      setTimeout(() => {
        this.isActive = true;
      }, 0);
    },
    next() {
      if (this.index < this.files.length - 1) {
        this.index = this.index + 1;
        this.refresh();
      }
    },
    prev() {
      if (this.index > 0) {
        this.index = this.index - 1;
        this.refresh();
      }
    },
    close() {
      this.isError = false;
      this.isLoading = true;
      this.errorMsg = '';
      this.isActive = false;
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
      if (this.focusTrap) this.focusTrap.deactivate();
    },
    togglePdfExpand() {
      this.isPDFExpanded = !this.isPDFExpanded;
    },
    onPrintFile() {
      this.$refs.viewerComponent.print();
    },
    makePDFReady() {
      this.pdfReady = true;
    },
    async onRotate(direction) {
      this.isLoading = true;
      const degrees = direction === 'right' ? -90 : 90;
      await this.$store.dispatch('files/rotateImage', {
        name: this.currentFile.path,
        angle: degrees,
      });

      this.isLoading = false;
    },

    handleFocusTrapInit() {
      this.$nextTick(() => {
        try {
          if (!this.focusTrap) {
            this.focusTrap = createFocusTrap(this.$el, {
              allowOutsideClick: true,
              escapeDeactivates: false,
            });
          } else {
            this.focusTrap.updateContainerElements(this.$el);
          }
          this.focusTrap.activate();
        } catch (e) {}
      });
    },
  },
};
</script>
