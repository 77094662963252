<template>
  <transition
    enter-active-class="animate__animated animate__slideInUp"
    leave-active-class="animate__animated animate__slideOutDown"
  >
    <div
      v-show="newFileMenu.open"
      class="mobile-menu mobile-menu--newFile"
      :class="{ 'mobile-menu--active': newFileMenu.open }"
    >
      <div class="body">
        <div
          v-for="(item, id) in newFileMenu.actions"
          v-if="
            !(
              newFileMenu.target &&
              item.disabled &&
              item.disabled(newFileMenu.target)
            )
          "
          :key="id"
          class="menu-item"
          :class="{ 'menu-item--separator': item.seperator }"
          @click="triggerAction(item.action, item.params)"
        >
          <div class="icon">
            <Icon
              v-if="item.icon"
              :name="item.icon"
              :family="item.iconFamily || 'fal'"
            />
          </div>
          <span class="title"
            >{{
              $t(
                typeof item.name === 'string'
                  ? item.name
                  : item.name(newFileMenu.target)
              )
            }}
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'NewFileMenu',
  components: {
    Icon,
  },
  data() {
    return {};
  },
  computed: {
    newFileMenu() {
      return this.$store.getters['files/getNewFileMenuState']();
    },
  },
  mounted() {
    this.$root.$on('overlayClicked', () => this.close);
  },
  methods: {
    triggerAction(action, param) {
      this.newFileMenu.component.triggerDDAction(action, param);
      this.close();
    },
    close() {
      this.$store.commit('files/setNewFileMenu', {
        key: 'open',
        value: false,
      });

      this.$store.commit('files/hideMobileOverlay');
    },
  },
};
</script>
