<template>
  <div class="file-preview-item file-preview-item--office">
    <iframe class="wopi-preview" :src="url" :name="frameName"></iframe>
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
    },
  },
  data() {
    return {
      instance: null,
      render: null,
      blob: null,
      url: null,
    };
  },
  watch: {
    file: function() {
      this.load();
    },
  },
  computed: {
    customization() {
      return this.$store.state.core.customization;
    },
    frameName() {
      return this.file &&
        ((this.file.disallowpreviewdownload != undefined && !this.file.disallowpreviewdownload) || this.file.candownload)
        ? ''
        : 'readonly';
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.url = await this.$store.dispatch(
        'files/getDocConvertPreviewURL',
        this.file
      );
      return;
    },
  },
  components: {},
};
</script>
