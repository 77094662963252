<template>
  <div class="paginator pb-2">
    <span
      class="paginator_icon"
      :class="{ disabled: currentPage === 1 }"
      @click="onPageChange(1)"
    >
      <Icon name="chevron-double-left"
    /></span>
    <span
      class="paginator_icon"
      :class="{ disabled: currentPage === 1 }"
      @click="onPageChange(currentPage - 1)"
    >
      <Icon name="chevron-left"
    /></span>
    <span class="paginator_text d-inline-block">Page</span>
    <InputBox
      v-model="typedPage"
      type="text"
      class="paginator_input"
      autocomplete="off"
      @keyup.enter="onPageTyped"
      @blur="onPageTyped"
    />
    <span class="paginator_text d-inline-block">of {{ totalPages }}</span>
    <span
      class="paginator_icon"
      :class="{ disabled: currentPage === totalPages }"
      @click="onPageChange(currentPage + 1)"
    >
      <Icon name="chevron-right"
    /></span>
    <span
      class="paginator_icon"
      :class="{ disabled: currentPage === totalPages }"
      @click="onPageChange(totalPages)"
    >
      <Icon name="chevron-double-right"
    /></span>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import InputBox from 'common/components/InputBox';

export default {
  components: {
    Icon,
    InputBox,
  },
  props: {
    totalItems: { type: Number, required: true },
    itemsPerPage: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },
  data() {
    return {
      typedPage: this.currentPage,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  watch: {
    currentPage(page) {
      this.typedPage = page;
    },
  },
  methods: {
    onPageChange(newPage) {
      if (
        newPage !== this.currentPage &&
        newPage <= this.totalPages &&
        newPage >= 1
      ) {
        this.$emit('onPageChange', newPage);
        this.typedPage = newPage;
      } else {
        this.typedPage = this.currentPage;
      }
    },
    onPageTyped() {
      if (!isNaN(this.typedPage)) {
        this.onPageChange(Number(this.typedPage));
      } else {
        this.typedPage = this.currentPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paginator {
  align-items: center;
  color: var(--text-dark);
  display: flex;
  flex-shrink: 0;
  font-size: 10px;
  justify-content: center;
  padding-top: 20px;
  width: 100%;

  &_icon {
    cursor: pointer;
    padding: 5px 10px;

    &.disabled {
      color: var(--text-ultra-light);
      cursor: unset;
    }
  }

  &_input {
    flex-grow: 0;
    font-size: 10px;
    margin: 0 10px;
    min-width: 32px;
    max-width: 120px;
    padding: 0;

    ::v-deep .input-group {
      border-radius: 2px;

      input {
        font-size: 10px;
        height: 20px;
        padding: 0 6px;
        text-align: center;
        width: 20px;
      }
    }
  }
}
</style>
