<template>
  <DialogModal
    class="dialog-modal-table animate-timed-fade locked-files-wrapper"
    :stick-top="true"
    :visible="true"
    :title="$t(`Locked Files and Folders`)"
    :buttons="[
      {
        label: 'OK',
        outline: true,
        callback: () => onClose(),
      },
    ]"
    @close="onClose()"
  >
    <Loader :loading="loading" full />
    <div>
      <div class="table-wrapper-border">
        <table class="table table--properties">
          <caption>
            {{
              $t('Locked Files and Folders')
            }}
          </caption>
          <thead>
            <th scope="col">
              {{ $t('Path') }}
              <span class="filter">
                <InputBox
                  :id="'filter-files'"
                  v-model="filterValue"
                  :placeholder="$t('Filter Items')"
                  class="filter-items ml-3 mb-0"
                  :prepends="[{ icon: 'filter' }]"
                  form-size="sm"
                />
              </span>
            </th>
            <th class="centered" scope="col">{{ $t('Read Permitted') }}</th>
            <th class="centered" scope="col">{{ $t('Expiration') }}</th>
            <th class="centered" scope="col">{{ $t('Actions') }}</th>
          </thead>
          <tbody>
            <tr
              v-if="!loading && paginatedLockedFiles.length === 0"
              class="border-0"
            >
              <td colspan="4" class="centered p-5">
                <EmptyListingInfo icon-name="lock" text="No Locked Files" />
              </td>
            </tr>
            <LockedFileItem
              v-for="file in paginatedLockedFiles"
              :key="file.lockrid"
              :file="file"
            />
          </tbody>
        </table>
      </div>

      <Paginator
        v-if="totalItems / itemsPerPage > 1"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        :total-items="totalItems"
        @onPageChange="onPageChage"
      />
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import Paginator from 'common/components/Paginator';
import LockedFileItem from '../FileList/FileListItemLocked.vue';
import Loader from 'common/components/Loader';
import InputBox from 'common/components/InputBox';
import EmptyListingInfo from 'common/components/EmptyListingInfo';
import { mapState } from 'vuex';

export default {
  components: {
    DialogModal,
    Loader,
    Paginator,
    EmptyListingInfo,
    InputBox,
    LockedFileItem,
  },
  data() {
    return {
      filterValue: '',
      itemsPerPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapState('files', ['lockedFiles']),
    loading() {
      return (
        this.$store.state.loading['files/lockedFiles'] ||
        this.$store.state.loading['files/unlock'] ||
        false
      );
    },
    computedLockedFiles() {
      if (this.filterValue == '') return this.lockedFiles;
      return this.lockedFiles.filter(({ lockpath }) => {
        return lockpath.toLowerCase().includes(this.filterValue.toLowerCase());
      });
    },
    paginatedLockedFiles() {
      const initialIndex = (this.currentPage - 1) * this.itemsPerPage;
      const lastIndex = this.currentPage * this.itemsPerPage;
      const type = typeof this.computedLockedFiles === 'object';
      return type
        ? this.computedLockedFiles.slice(initialIndex, lastIndex)
        : [this.computedLockedFiles];
    },
    totalItems() {
      return this.computedLockedFiles.length;
    },
  },
  mounted() {
    const userid = this.$store.state.auth.user.profile;
    this.$store.dispatch('files/lockedFiles', { userid });
  },
  methods: {
    onPageChage(newPage) {
      this.currentPage = newPage;
    },
    onClose() {
      this.$emit('close');
      this.$root.$emit('refreshFileList');
    },
  },
};
</script>
