<template>
  <Themed
    text-color
    primary-as-text
    as="i"
    class="inline-icon"
    :class="[family, `fa-${name}`]"
  ></Themed>
</template>
<script>
import Themed from './../Themed';
export default {
  components: {
    Themed,
  },
  props: {
    name: {
      type: String,
      default: 'none',
    },
    family: {
      type: String,
      default: 'far',
      validator: function (value) {
        return ['far', 'fas', 'fal', 'fab', 'fad'].indexOf(value) !== -1;
      },
    },
  },
};
</script>
