<template>
  <div class="vue-query-builder">
    <slot v-bind="vqbProps">
      <QueryBuilderGroup
        v-bind="vqbProps"
        :query.sync="query"
        @change="$emit('change')"
      />
    </slot>
  </div>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import QueryBuilderGroup from './layouts/Group.vue';
import _ from 'lodash';

var defaultLabels = {
  matchType: 'Operator',
  matchTypes: [
    { id: '&&', label: 'AND' },
    { id: '||', label: 'OR' },
  ],
  addRule: 'New Rule',
  removeRule: '&times;',
  addGroup: 'New Rule Group',
  removeGroup: '&times;',
  textInputPlaceholder: 'value',
};

export default {
  name: 'VueQueryBuilder',

  components: {
    QueryBuilderGroup,
  },

  props: {
    labels: {
      type: Object,
      default() {
        return defaultLabels;
      },
    },
    ruleTypes: Object,
    maxDepth: {
      type: Number,
      default: 5,
      validator: function (value) {
        return value >= 1;
      },
    },
    value: Object,
  },

  data() {
    return {
      query: {
        children: [],
      },
    };
  },

  computed: {
    mergedLabels() {
      return Object.assign({}, defaultLabels, this.labels);
    },

    vqbProps() {
      return {
        index: 0,
        depth: 1,
        maxDepth: this.maxDepth,
        ruleTypes: this.ruleTypes,
        labels: this.mergedLabels,
      };
    },
  },

  mounted() {
    this.$watch(
      'query',
      (newQuery) => {
        if (JSON.stringify(newQuery) !== JSON.stringify(this.value)) {
          this.$emit('input', _.cloneDeep(newQuery));
        }
      },
      {
        deep: true,
      }
    );

    this.$watch(
      'value',
      (newValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(this.query)) {
          this.query = _.cloneDeep(newValue);
        }
      },
      {
        deep: true,
      }
    );

    if (typeof this.$options.propsData.value !== 'undefined') {
      this.query = Object.assign(this.query, this.$options.propsData.value);
    }
  },
};
</script>
