export const groupsQueryOptions = {
  sortBy: {
    NAME: 'groupname',
    USERCOUNT: 'membercount',
  },
  order: {
    ASC: 1,
    DESC: -1,
  },
};

export const groupModalTabs = {
  LISTGROUP: 'GroupModalListGroupsTab',
  MEMBERS: 'GroupModalMembersTab',
};
