import Checkbox from 'common/components/Checkbox';
import InputBox from 'common/components/InputBox';

export default {
  components: {
    Checkbox,
    InputBox,
  },
  props: {
    isPublicShareView: {
      type: Boolean,
    },
    isMobile: {
      type: Boolean,
    },
    isTablet: {
      type: Boolean,
    },
    selectedFiles: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      default: () => {
        return {};
      },
    },
    actualFiles: {
      type: Array,
      default: () => [],
    },
    filterValue: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      sticky: {
        type: Boolean,
        default: false,
      },
    };
  },
  watch: {
    sticky: function (newVal) {
      if (newVal === true) {
        let firstHeaderHeight;
        let secondHeaderHeight = document.getElementById('fcExplorerHeader')
          .offsetHeight;

        if (this.isPublicShareView) {
          firstHeaderHeight = document.getElementById('filePreviewHeader')
            .offsetHeight;
        } else {
          firstHeaderHeight = document.getElementById('navbar').offsetHeight;
          secondHeaderHeight += -1;
        }

        this.headerOffset = firstHeaderHeight + secondHeaderHeight + 'px';
      } else {
        this.headerOffset = null;
      }
    },
  },
  methods: {
    onFilter(value) {
      this.$emit('onFilter', value);
    },
    onSelectAll() {
      this.$emit('onSelectAll');
    },
  },
};
