<template>
  <div
    ref="item"
    v-click-outside="() => (hasFocus = false)"
    class="file-list-item file-list-item--file"
    role="row"
    :data-path="item.path"
    :class="{
      'file-list-item--selected': isSelected,
      'file-list-item--focused': hasFocus,
      'file-list-item--compact': isCompactMode,
      'selection-made': hasSelection,
      'file-list-item--highlight': hasHighlight,
      'no-hover': isPicker,
      'pointer-none': disableHover,
    }"
  >
    <!-- select checkbox-->
    <div
      v-if="!isPicker && !isMobile && !isTablet"
      ref="selectCheckbox"
      tabindex="0"
      class="col-checkbox"
      @click="select"
      @keydown.space.stop.prevent="select"
      @keydown.enter.stop.prevent="previewFile"
    >
      <Checkbox
        :checked="isSelected"
        :label="$t('Press tab for file operations on') + ' ' + item.name"
        tabindex="-1"
        role="cell"
      />
    </div>
    <!-- /select checkbox-->
    <!-- Icon an name o file-->
    <div
      class="col-title"
      role="gridcell"
      :aria-label="item.name"
      :class="{
        'col-title-wider': isPicker,
        'col-title--mobile': isMobile || isTablet,
        'col-title--highlight': hasHighlight,
      }"
      @click="expand"
    >
      <div
        v-if="(isMobile || isTablet) && isSelected"
        class="col-checkbox"
        @click="select"
      >
        <Checkbox :checked="isSelected" :label="$t('Select')" />
      </div>
      <Icon
        v-if="item.type === 'dir' && !isSelected"
        class="file-icon file-icon--folder"
        :subicon="itemIcon(item)"
        family="fas"
        :name="
          isSharedWithMeHome ? 'user' : isBackupFolder ? 'archive' : 'folder'
        "
      />
      <FileIcon
        v-else-if="!isSelected"
        ref="fileIcon"
        :name="item.name"
        :item="item"
        :format="item.format"
        :path="item.path"
        :subicon="itemIcon(item)"
        :size="thumbSize"
      />
      <div
        ref="titlendetails"
        v-tooltip="isEllipsisActive ? item.name : ''"
        v-a11ybutton="previewFile"
        role="row"
        class="title-n-details"
        :tabindex="isPicker ? '0' : '-1'"
      >
        <Themed
          ref="file-title"
          as="h4"
          text-colorr
          @click.stop="previewFile"
        >
          <span role="gridcell" :aria-label="item.name">{{ item.name }}</span>
          <FileListItemColorDot :color="item.colortag" />
          <small class="d-md-none">
            {{ item.size && item.size.length > 0 ? item.size + ' - ' : '' }}
            {{ mobileDateFormat }}
          </small>
        </Themed>
        <div
          v-if="hasHighlight"
          class="file-list-item--highlight pt-4 w-100 text-truncate"
          v-html="item.highlight"
        />
        <div class="file-list-item-details">
          <span
            v-if="typeof item.size === 'string'"
            role="gridcell"
            :aria-label="'file size:' + item.size"
            class="file-list-item-details-size"
            >{{ item.size }} {{ ' - ' }}
          </span>
          <span
            v-if="typeof item.modified === 'string'"
            role="gridcell"
            :aria-label="'Last modified: ' + hijriDate(item)"
            class="file-list-item-details-modified"
          >
          </span>
        </div>
      </div>
    </div>
    <!-- /Icon an name o file-->
    <!-- Last Modify info -->
    <div
      v-if="!hasCustomList && typeof item.modified == 'string' && !isMobile"
      class="col-info m-text d-flex"
      role="gridcell"
      :aria-label="'Last modified: ' + hijriDate(item)"
      :class="{
        'recent-mod': recentlyModified(item.modified),
      }"
      @click="expand"
    >
      <h4>
        <DateDisplay :date="hijriDate(item)" class="d-inline" />
        <span class="recent-dot"></span>
        <small
          v-if="item.lastmodifiedby && typeof item.lastmodifiedby === 'string'"
          class="small-text"
          >{{ $t('by') }}
          {{
            item.lastmodifiedby === user.peerid
              ? $t('you')
              : item.lastmodifiedby
          }}</small
        >
      </h4>
    </div>
    <!-- /Last Modify info -->
  </div>
</template>
<script>
import fileListItemMixin from '../../mixins/fileListItemMixin';
import lockReadMixin from '../../mixins/lockReadMixin';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import Themed from '../Themed';
import FileListItemColorDot from './FileListItemColorDot';
import LockIcon from './partials/LockIcon';
import fileActions from '../../constants/fileActions';
import InputBox from 'common/components/InputBox';
import DateDisplay from 'common/components/DateDisplay';
import GoogleSheets from 'common/assets/icons/fcgoogle-sheets.svg';
import GoogleDocs from 'common/assets/icons/fcgoogle-docs.svg';
import GoogleSlides from 'common/assets/icons/fcgoogle-slides.svg';
import OfficeOnline from 'common/assets/office.svg';

import Checkbox from 'common/components/Checkbox.vue';
import FileIcon from 'common/components/FileIcon';
import Icon from 'common/components/Icon';
import { sizes } from 'constants/sizes';
import 'vue-context/dist/css/vue-context.css';
import { getFileType } from 'common/utils/files';
import { WOPIClients, GoogleDocsFormats } from '../../constants/WOPI.js';

import { VPopover } from 'v-tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
dayjs.extend(relativeTime);

export default {
  components: {
    LockIcon,
    Checkbox,
    FileIcon,
    Icon,
    Themed,
    FileListItemColorDot,
    InputBox,
    VPopover,
    DateDisplay,
    GoogleSheets,
    GoogleDocs,
    GoogleSlides,
    OfficeOnline,
  },
  mixins: [fileListItemMixin, responsivenessMixin, lockReadMixin],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: Boolean,
    // currently selected items
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    isBackupFolderSibling: {
      type: Boolean,
      default: false,
    },
    disableHover: {
      type: Boolean,
      default: false,
    },
    isCompactMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInRecycleFolder: false,
      hasFocus: false,
      directLink: '',
      counter: 0,
      showDelConf: false,
      isEllipsisActive: false,
      fileActions,
      encryptedFolderPassword: '',
      renameShare: false,
      passwordType: 'password',
      isSharingRootFolder: false,
      currentEncryptedFolderOp: 'preview',
      renameFromContext: false,
      renameItem: false,
      renameShareOut: false,
      isParentValidEncryptedFolder: false,
      WOPIClients,
      GoogleDocsFormats,
    };
  },
  computed: {
    ...mapGetters('core', [
      'isLockDisabled',
      'WOPIClient',
      'isGoogleDocsEnabled',
      'isWOPIEditEnabled',
      'isWebEditEnabled',
      'getEditorClientName',
      'isLimitedUser',
      'allowQuickEditAll',
    ]),

    highlightText() {
      return this.item.highlight
        .replace(/<hlt[^>]*>/g, '<strong>')
        .replace(/<\/hlt>/g, '</strong>');
    },
    hasHighlight() {
      return !_.isEmpty(this.item.highlight);
    },
    user() {
      return this.$store.state.auth.user;
    },
    parentPath() {
      let dirpath =
        this.item.type === 'dir' ? this.item.path : this.item.dirpath;

      if (!this.isPublicShare && !this.isSharedWithMe) {
        return this.item.type === 'dir'
          ? dirpath
          : dirpath.substring(0, dirpath.length - 1);
      } else {
        return this.isPublicShare ? this.path : this.item.path;
      }
    },
    isSelected() {
      return _.findIndex(this.selection, { id: this.item.id }) > -1;
    },
    thumb() {
      return this.item.format && this.item.format === 'img' ? 'THUMB' : null;
    },
    thumbSize() {
      return sizes.listThumb;
    },
    itemsSelected() {
      return this.selection ? this.selection.length : 0;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    metaKey() {
      return window.navigator.platform === 'MacIntel' ? 'Cmd' : 'Ctrl';
    },
    dateFormat() {
      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'MMM DD, YYYY';
      }

      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    timeFormat() {
      return this.systemStatus.timeformat
        ? this.systemStatus.timeformat
        : this.systemStatus.defaulttimeformat
        ? this.systemStatus.defaulttimeformat
        : 'HH:mm:ss';
    },
    mobileDateFormat() {
      if (this.item.modifiedepochutc > 0) {
        return dayjs(this.item.modifiedepochutc * 1000).fromNow();
      } else return '';
    },
    backupPath() {
      return this.$store.state.core.systemstatus.BACKUPPATH;
    },
    isBackupFolder() {
      if (this.isBackupFolderSibling || this.hasCustomList)
        return this.item.fullfilename === this.backupPath;
      return false;
    },
    isSharedWithMeHome() {
      return this.$route.fullPath === '/expl-tabl./SHARED';
    },
    path() {
      const { pathMatch: path } = this.$router.history.current.params;
      return `/${path ? path : this.user.peerid}`;
    },
    isSharedWithMe() {
      return this.path.indexOf('/SHARED') > -1;
    },
  },
  watch: {
    'item.name'() {
      this.$nextTick(() => {
        this.isEllipsisActive = this.evaluateIsEllipsisActive();
      });
    },
    $route(to) {
      const nextPath = `/${to.params.pathMatch}`;
      this.isInRecycleFolder = nextPath.indexOf('recyclebin') > -1;
    },
  },
  mounted() {
    this.isInRecycleFolder = this.$route.fullPath.indexOf('recyclebin') > -1;

    this.isEllipsisActive = this.evaluateIsEllipsisActive();
  },
  methods: {
    ...mapActions('files', [
      'openDeleteConfirmationModal',
      'closeDeleteConfirmationModal',
    ]),
    itemIcon({ locked, drmwebviewer }) {
      const drmIcon = drmwebviewer ? (locked ? 'DrmLockedIcon' : 'Drm') : '';
      const lockedIcon = locked ? 'fas fa-lock' : '';
      const icon = drmIcon || lockedIcon;
      return icon;
    },
    hijriDate({ modifiedepochutc, modifiediso }) {
      return !this.isPublicShare && typeof modifiedepochutc == 'number'
        ? modifiedepochutc
        : Date.parse(modifiediso) / 1000;
    },
    itemFormat(ext) {
      return getFileType(ext);
    },
    separatePaths(item) {
      return item.path.split('/').filter((e) => e !== '');
    },
    setFocus(focused) {
      this.hasFocus = focused;
    },
    select(e) {
      const { ctrlKey, shiftKey, metaKey } = e;
      this.$emit('selected', { item: this.item, ctrlKey, shiftKey, metaKey });
    },
    preview(e) {
      this.select(e);
    },
    async browse() {
      this.$emit(
        'browsed',
        this.item.type !== 'dir' ? this.item.dirpath : this.item
      );
    },
    async previewFile(event) {
      const isShiftPressed = event && event.shiftKey;

      if (this.item.type === 'dir') {
        await this.browse();
      } else {
        this.preview({
          shift: isShiftPressed,
        });
      }
    },
    evaluateIsEllipsisActive() {
      let element = this.$refs['file-title'].$el;
      return element.clientWidth < element.scrollWidth;
    },
  },
};
</script>
<style lang="scss" scoped>
.file-list-item.file-list-item--file {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}
</style>
<style>
.m-text {
  clear: both;
  white-space: nowrap;
}
.popover {
  opacity: 1;
}
.small-chevron {
  font-size: 10px;
}
.pointer-link {
  cursor: pointer !important;
}
.item-list--icon {
  position: relative;
}
.hover .item-list--icon {
  display: none;
}
</style>
