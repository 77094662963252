<template>
  <Themed background-color text-color>
    <div class="container mb-5 comment p-0">
      <div v-if="fullPath !== ''" class="col-md-12 p-0 m-0 mt-4">
        <h5 class="comment-header" style="font-weight: bold">
          {{ $t(headerText) }}
        </h5>
      </div>
      <div v-if="fullPath !== ''">
        <Mentionable
          :keys="['@']"
          :items="mentions"
          offset="6"
          class="col-md-12 m-0 p-0"
          :class="'test'"
          :show-default-avatar="true"
          @open="getMentionables($event)"
          @search="getMentionables($event)"
        >
          <textarea
            ref="mentionableTextarea"
            v-model="text"
            class="col-md-12 mb-2 form-control comment-input"
            :class="{ ['is-invalid']: isOverflowingCharLimit }"
            rows="3"
            :placeholder="
              $t(
                readonly
                  ? 'Comment addition not allowed for read only share!'
                  : 'What\'s on your mind?'
              )
            "
            :aria-label="$t('What\'s on your mind?')"
            :disabled="readonly"
            @keypress.enter.prevent.exact="addComment"
            @keypress.shift.enter.exact="addNewLine"
          />
          <small
            class="input-length-counter"
            :class="{
              ['input-length-counter--invalid']: isOverflowingCharLimit,
            }"
            >{{ text.length }}/{{ charLimit }}</small
          >
          <template #no-result>
            <div class="dim">
              {{ loading ? $t('Loading...') : $t('No results') }}
            </div>
          </template>
          <template #item-@="{ item }">
            <div class="mention">
              <Avatar class="avatar" :user="item.name" />
              <span class="username"> @{{ item.name }} </span>
              <span
                v-if="typeof item.displayname === 'string'"
                class="displayname"
              >
                {{ item.displayname }}
              </span>
            </div>
          </template>
        </Mentionable>
        <div class="col-md-12 m-0 p-0">
          <button
            class="float-left btn btn-primary btn-sm px-4 mt-2"
            :disabled="!canSubmit"
            @click="addComment"
          >
            {{ $t(submitMessage) }}
          </button>
          <a
            v-if="editMode"
            class="mt-1 float-right btn btn-sm btn-link"
            @click="disableEditMode"
          >
            {{ $t('Cancel') }}
          </a>
        </div>
      </div>
      <div v-else-if="shareId !== ''"></div>
      <div v-else>
        <p class="mt-4">
          {{ $t('Please select an item to add comments') }}
        </p>
      </div>
    </div>
  </Themed>
</template>

<script>
import Themed from '../Themed';
import { Mentionable } from 'vue-mention';
import Avatar from 'common/components/Avatar';
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'CommentInput',
  components: {
    Themed,
    Mentionable,
    Avatar,
  },
  props: {
    commentData: {
      default: () => {},
      type: Object,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    charLimit: {
      type: Number,
      default: 256,
    },
  },
  data() {
    return {
      text: '',
      mention: '',
      mentions: [],
      submitMessage: this.$t('Add'),
      isloading: false,
      headerText: this.$t('Add Comment'),
      loading: false,
    };
  },
  computed: {
    ...mapGetters('files', ['getPathMeta']),
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    isFile() {
      return 'file' === this.currentFile.type ? 1 : 0;
    },
    parent() {
      return this.isCurrentItemCurrentFolder
        ? this.meta.parentpath
        : this.currentFile.dirpath;
    },
    fullPath() {
      return this.currentFile.path || '';
    },
    shareId() {
      return this.currentFile.shareid || '';
    },
    isOverflowingCharLimit() {
      return this.text.length > this.charLimit;
    },
    canSubmit() {
      return !this.isOverflowingCharLimit && !this.readonly;
    },
    meta() {
      return this.isCurrentItemCurrentFolder
        ? this.getPathMeta(this.currentFile.path)
        : {};
    },
    readonly() {
      return this.isCurrentItemCurrentFolder
        ? !this.meta?.canupload
        : !this.currentFile?.canupload;
    },
    isCurrentItemCurrentFolder() {
      // the currentItem doesn't have all proprieties when selection is empty
      // (currentItem is equal to currentFolder in browser), so we have to use the folder meta
      return this.currentFile?.id === undefined && !this.isFile;
    },
  },
  mounted() {
    if (this.editMode) {
      this.text = decodeURIComponent(this.commentData.text);
      this.submitMessage = this.$t('Update');
      this.headerText = '';
    }
    if (this.$route.name !== 'webeditor') {
      this.$refs.mentionableTextarea.focus();
    }
  },
  methods: {
    disableEditMode() {
      this.$emit('disableCommentEdit', 'true');
    },
    async addComment(e) {
      if (!this.canSubmit) return;
      this.loading = true;
      e.preventDefault();

      if (!this.editMode && this.text !== '') {
        const result = await this.$store.dispatch('files/addComment', {
          text: unescape(encodeURIComponent(this.text)),
          fullpath: this.fullPath,
          isfile: this.isFile,
          parent: this.parent,
          origpath: this.fullPath,
        });

        let toast;

        if (result.ok) {
          this.$emit('addedComment');

          toast = {
            message: `<b>${this.$t('Success')}</b><p role="alert">${this.$t(
              'Comment added successfully'
            )}</p>`,
            type: 'success',
          };
          this.text = '';

          this.cleanText();
          await this.$store.dispatch('files/getCommentsForComponent', {
            id: this.commentData ? this.commentData.id : null,
            fullpath: this.selectedFullpath,
          });
        } else {
          const message =
            result.data && result.data.message
              ? result.data.message
              : this.$t('Something went wrong');
          toast = {
            message: `<b>${this.$t('Error')}</b><p role="alert">${message}</p>`,
            type: 'error',
          };
        }

        this.$emit('sentToast', toast);
      }

      this.loading = false;
    },
    async getMentionables(event) {
      if (event.length >= 2) {
        this.loading = true;
        const response = await this.$store.dispatch('files/getMentionables', {
          filter: event,
        });
        this.loading = false;
        if (response.ok) {
          let arr = [];

          if (!_.isEmpty(response.data)) {
            if (response.data.mentionable instanceof Array) {
              arr = response.data.mentionable;
            } else if (response.data.mentionable !== 'undefined') {
              arr.push(response.data.mentionable);
            }

            this.mentions = arr.map(function (item) {
              let mention = Object.assign({}, item);
              if (item.type === 'group') {
                mention.value = item.displayname;
              } else if (item.type === 'user') {
                mention.value = item.name;
              }
              return mention;
            });

            return this.mentions;
          } else {
            this.mentions = [];
            return [];
          }
        } else {
          this.mentions = [];
          return [];
        }
      } else {
        this.mentions = [];
        return [];
      }
    },
    cleanText() {
      this.$nextTick(() => {
        this.text = '';
        this.$refs.mentionableTextarea.value = '';
      });
    },
    addNewLine() {
      const editor = this.$refs.mentionableTextarea;

      editor.setRangeText('\r\n');
      editor.selectionStart = editor.selectionEnd + 1;
      editor.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
.dim {
  font-size: 14px;
  padding: 8px 10px;
}

.preview {
  font-family: monospace;
  white-space: pre-wrap;
  margin-top: 12px;
  padding: 12px;
  background: #f8f8f8 !important;
  border-radius: 6px;
}

.tooltip.vue-popover-theme.popover.open {
  border-radius: 5px;
}

.comment-input {
  height: 5rem;
  border-radius: 3px;
  background-color: var(--bg-light);
  padding: 3px 0 0 6px;
  &::placeholder {
    font-size: 14px;
    font-weight: 500;
  }
  &::-webkit-input-placeholder {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-length-counter {
  float: right;
  font-size: 12px;
  color: var(--text-muted);

  &--invalid {
    color: #dc3545;
  }
}
</style>
