var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"settings-page-children notifications-settings"},[_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}),_c('div',{staticClass:"radio-wrapper"},[_c('Radiobox',{attrs:{"name":"notifiType","radio-value":"default","label":_vm.$t(_vm.title)},on:{"change":_vm.updateNotification},model:{value:(_vm.form.notificationType),callback:function ($$v) {_vm.$set(_vm.form, "notificationType", $$v)},expression:"form.notificationType"}}),_c('Radiobox',{attrs:{"disabled":!_vm.form.allowOverride,"name":"notifiType","radio-value":"own","label":_vm.$t('Use my own notification settings')},on:{"change":_vm.updateNotification},model:{value:(_vm.form.notificationType),callback:function ($$v) {_vm.$set(_vm.form, "notificationType", $$v)},expression:"form.notificationType"}})],1),(_vm.form.enableCustomNotifications)?_c('div',[_c('div',{staticClass:"checkbox-wrapper d-flex align-items-center pt-2",class:{
        'pl-5': !_vm.isMobile && !_vm.isTablet,
        'pl-4': _vm.isTablet,
      }},[_c('Checkbox',{model:{value:(_vm.form.enableNotifications),callback:function ($$v) {_vm.$set(_vm.form, "enableNotifications", $$v)},expression:"form.enableNotifications"}}),_c('span',[_vm._v(_vm._s(_vm.$t('Send Notifications')))]),(_vm.form.frequencyMode)?_c('v-select',{staticClass:"frequency",class:{ ['disabled-block']: !_vm.form.enableNotifications },attrs:{"reduce":(frequency) => frequency.id,"label":"label","options":_vm.frequency},model:{value:(_vm.form.frequencyMode),callback:function ($$v) {_vm.$set(_vm.form, "frequencyMode", $$v)},expression:"form.frequencyMode"}}):_vm._e(),(_vm.form.frequencyMode == 'custom')?_c('div',{staticClass:"ml-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.frequencyMinutes),expression:"form.frequencyMinutes"}],staticClass:"form-control form-control-sm",attrs:{"type":"number","min":_vm.form.minimumFrequencyMinutes},domProps:{"value":(_vm.form.frequencyMinutes)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "frequencyMinutes", $event.target.value)}}})]):_vm._e()],1),_c('div',{staticClass:"notification-options mt-3",class:{
        'disabled-block': !_vm.form.enableNotifications,
        'ml-5 mb-4': !_vm.isMobile && !_vm.isTablet,
        'ml-4 mb-4': _vm.isTablet,
      }},[_c('p',{staticClass:"strong"},[_vm._v(" "+_vm._s(_vm.$t('Send Notifications on:'))+" ")]),(_vm.notifyOptions.length)?_c('div',{staticClass:"options-wrapper"},_vm._l((_vm.notifyOptions),function(option,id){return _c('div',{key:id,staticClass:"notify-single-option"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('Checkbox',{staticClass:"mr-2",attrs:{"label":_vm.$t(option.text),"description":_vm.$t(option.subtext)},model:{value:(_vm.form[option.model]),callback:function ($$v) {_vm.$set(_vm.form, option.model, $$v)},expression:"form[option.model]"}}),_c('label',[_vm._v(_vm._s(_vm.$t(option.text)))])],1),_c('div',{staticClass:"sub-text"},[_vm._v(" "+_vm._s(_vm.$t(option.subtext))+" ")])])}),0):_vm._e()])]):_vm._e(),(!_vm.path)?_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('button',{staticClass:"btn wide btn-primary btn-save",on:{"click":_vm.updateUserSettings}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }