var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"mini-admin-reset-password-modal",attrs:{"stick-top":true,"visible":_vm.visible,"title":_vm.$t('Reset password'),"contain-form":true,"buttons":[
    {
      label: _vm.$t('Close'),
      outline: true,
      callback: function () {
        _vm.close();
      },
    },
    {
      label: _vm.$t('Save'),
      callback: function () {
        _vm.handleSave();
      },
    },
  ]},on:{"close":_vm.close}},[_c('Loader',{attrs:{"loading":_vm.isLoading,"full":""}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSave.apply(null, arguments)}}},[_c('div',{staticClass:"reset-password"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.errorMessage != '')?_c('InfoBox',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_c('InputBox',{attrs:{"id":"password","type":_vm.password.type,"label":_vm.$t('New password'),"appends":_vm.passwordEye,"errors":_vm.errors,"focus":""},model:{value:(_vm.password.value),callback:function ($$v) {_vm.$set(_vm.password, "value", $$v)},expression:"password.value"}}),_c('InputBox',{attrs:{"id":"passwordconfirm","type":_vm.confirmPassword.type,"label":_vm.$t('Confirm password'),"appends":_vm.confirmPasswordEye,"errors":_vm.errors},model:{value:(_vm.confirmPassword.value),callback:function ($$v) {_vm.$set(_vm.confirmPassword, "value", $$v)},expression:"confirmPassword.value"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }