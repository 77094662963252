var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{class:'dialog-modal-form',staticStyle:{"z-index":"9999"},attrs:{"visible":true,"title":_vm.$t('Confirm'),"color":"grey","size":'small',"position":"top","buttons":[
    {
      label: `OK`,
      callback: () => this.allow(),
    },
    { label: `Cancel`, outline: true, callback: () => this.cancel() },
  ]},on:{"close":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t( 'Do you want to remove share permissions assigned to specific users and groups and allow share access to everyone? ' ))+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }