<template>
  <div
    ref="item"
    v-click-outside="() => (hasFocus = false)"
    class="file-list-item file-list-item--file"
    role="row"
    :data-path="item.path"
    :class="{
      'file-list-item--selected': isSelected,
      'file-list-item--dragging': draggingOver,
      'file-list-item--focused': hasFocus,
      'file-list-item--expanded': expanded,
      'selection-made': hasSelection,
      'file-list-item--deleting': isDeleting,
      'file-list-item--search': hasSearchResults,
      'file-list-item--highlight': hasHighlight,
      'file-list-item--kb-focused': isKbFocused,
      'no-hover': isPicker,
      'pointer-none': disableHover,
    }"
  >
    <!-- region File Star -->
    <FileItemStarAdd
      :item="item"
      :has-search-results="hasSearchResults"
      :is-picker="isPicker"
      :selection="selection"
      :is-public-share="isPublicShare"
      @onDragEnd="onDragEnd"
      @toggleStarred="toggleStarred"
    />
    <!-- endregion -->

    <!-- region Desktop File Selection Checkbox -->
    <div
      v-show="!isPicker && !isMobile && !isTablet"
      ref="selectCheckbox"
      tabindex="0"
      class="col-checkbox col-checkbox--desktop"
      @contextmenu.prevent="contextMenu"
      @click="select"
      @keydown.space.stop.prevent="select"
      @keydown.enter.stop.prevent="previewFile"
    >
      <Checkbox
        :checked="isSelected"
        :label="$t('Press tab for file operations on') + ' ' + item.name"
        tabindex="-1"
      />
    </div>
    <!-- endregion -->

    <!-- region Icon and File Name -->
    <div
      class="col-title"
      role="gridcell"
      aria-labelledby="genfilename"
      :class="{
        'col-title-wider': isPicker,
        'col-title--mobile': isMobile || isTablet,
        'col-title--highlight': hasHighlight,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      <Drop
        class="col-title-droppable"
        @drop="onDropFile"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
      >
        <!-- region Mobile File Selection Checkbox -->
        <div
          v-if="(isMobile || isTablet) && isSelected"
          class="col-checkbox"
          @contextmenu.prevent="contextMenu"
          @click="select"
        >
          <Checkbox :checked="isSelected" :label="$t('Select')" />
        </div>
        <!-- endregion -->

        <!-- region File/Directory Icon -->
        <Icon
          v-if="
            (item.type === 'dir' && getIsGrid) ||
            (item.type === 'dir' && !getIsGrid && !isSelected)
          "
          class="file-icon file-icon--folder inline-icon--scaled"
          :class="{ 'file-icon--user': isSharedWithMe }"
          :subicon="itemIcon(item)"
          family="fas"
          :name="
            isSharedWithMeHome ? 'user' : isBackupFolder ? 'archive' : 'folder'
          "
        />
        <FileIcon
          v-else-if="getIsGrid || (!isSelected && !getIsGrid)"
          ref="fileIcon"
          :name="item.name"
          :item="item"
          :format="item.format"
          :path="item.path"
          :subicon="itemIcon(item)"
          :size="thumbSize"
        />
        <!-- endregion -->

        <!-- region Rename Input -->
        <div
          v-show="isRenaming"
          ref="titlendetails"
          v-tooltip="isEllipsisActive ? item.name : ''"
          class="title-n-details"
          :tabindex="isPicker && isKbFocused ? '0' : '-1'"
          role="table"
        >
          <input
            ref="filenameInput"
            v-model="newName"
            :aria-label="$t('New file name')"
            type="text"
            class="form-control rename-input"
            @blur="onRename"
            @keyup.escape="onCancelRename"
            @keyup.enter="onRename"
          />
        </div>
        <!-- endregion -->

        <!-- region File Name -->
        <div
          ref="titlendetails"
          v-tooltip="isEllipsisActive ? item.name : ''"
          v-a11ybutton="previewFile"
          class="title-n-details"
          :tabindex="isPicker && isKbFocused ? '0' : '-1'"
          role="table"
        >

          <!-- region File Name -->
          <Themed
            v-if="!isRenaming"
            ref="file-title"
            as="h4"
            text-colorr
            role="row"
            @click.stop="previewFile"
          >
            <span role="gridcell" aria-labelledby="genfilename">{{
              item.name
            }}</span>
            <FileListItemColorDot :color="item.colortag" />
            <small v-show="!getIsGrid" class="d-md-none">
              {{ item.size && item.size.length > 0 ? item.size + ' - ' : '' }}
              {{ mobileDateFormat }}
            </small>
          </Themed>
          <!-- endregion -->

          <!-- region Detail Information -->
          <div class="file-list-item-details" role="row">
            <span
              v-show="typeof item.size === 'string'"
              role="gridcell"
              aria-labelledby="gensize"
              class="file-list-item-details-size file-list-item-details-size--grid"
              >{{ item.size }} {{ getIsGrid ? '' : ' - ' }}
            </span>
            <!-- @note This span doesn't have any content -->
            <span
              v-show="typeof item.modified === 'string'"
              role="gridcell"
              aria-labelledby="genmodifieddate"
              class="file-list-item-details-modified"
            >
            </span>
          </div>
          <!-- endregion -->
        </div>

        <!-- endregion -->
      </Drop>
    </div>
    <!-- endregion -->

    <!-- region Context Menu -->
    <FileItemOptionsContextMenu
      v-show="hoverMenu"
      :item="item"
      :has-custom-list="hasCustomList"
      :from-home="fromHome"
      :doc-preview="docPreview"
      :is-read-locked="!!isReadLocked"
      :is-in-recycle-folder="isInRecycleFolder"
      :is-share-disabled="isShareDisabled"
      :has-direct-link="hasDirectLink"
      :is-public-share="isPublicShare"
      :is-locked-by-others="isLockedByOthers"
      :is-lock-disabled="isLockDisabled"
      :is-web-edit-enabled="isWebEditEnabled"
      :can-edit-file-type="canEditFileType"
      :is-w-o-p-i-edit-enabled="isWOPIEditEnabled"
      :desktop-editor-client-name="desktopEditorClientName"
      :editor-client-length="editorClientLength"
      :is-deleting="isDeleting"
      :is-shared-with-me="isSharedWithMe"
      :direct-link="directLink"
      @lockFile="lockFile"
      @previewFile="previewFile"
      @browse="browse"
      @downloadFile="downloadFile"
      @copyFile="copyFile"
      @moveFile="moveFile"
      @toggleMoreOptions="toggleMoreOptions"
      @shareFile="shareFile"
      @triggerAction="triggerAction"
      @changeFocus="changeFocus"
      @runQuickEdit="runQuickEdit"
      @doDeleteFile="doDeleteFile"
      @unlockFile="unlockFile"
      @quickEditFile="quickEditFile"
      @fetchDirectLink="fetchDirectLink"
      @restoreFile="restoreFile"
    />
    <!-- endregion -->

    <!-- region Info Icons -->
    <FileItemInfoIcons
      :item="item"
      :has-search-results="hasSearchResults"
      :is-picker="isPicker"
      :selection="selection"
      :is-public-share="isPublicShare"
      :get-share-info="$t(getShareInfo())"
      @onDragEnd="onDragEnd"
      @toggleStarred="toggleStarred"
      @expand="expand"
    />
    <!-- endregion -->

    <!-- region Mobile More Options -->
    <div
      v-show="(isMobile || isTablet) && !isPicker"
      class="col-more-options--mobile cursor-pointer"
      role="group"
      @click="openMobileMenu"
    >
      <Icon name="ellipsis-v" class="more-options" />
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import FileItemMixin from './FileItemMixin';
export default {
  mixins: [FileItemMixin],
};
</script>
