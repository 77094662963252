<template>
  <div role="row" class="zip-viewer-file-list__header">
    <div class="zip-viewer-file-list__header-row">
      <!-- region Icon Column -->
      <div
        role="columnheader"
        class="zip-viewer-file-list__header-column zip-viewer-file-list__header-column--icon"
      >
        &nbsp;
      </div>
      <!-- endregion -->

      <!-- region Name Column -->
      <div
        role="columnheader"
        class="zip-viewer-file-list__header-column zip-viewer-file-list__header-column--name"
        @click="sortItems('name')"
      >
        <span>
          {{ $t('file_list.header.name') }}
          <button class="btn btn-link p-0 mb-2">
            <Icon
              :aria-label="$t('file_list.header.name_aria_label')"
              tabindex="0"
              role="button"
              on-key-down="onSort('name', $event)"
              name="chevron-up"
              class="zip-viewer-file-list__sort-icon"
              :class="sortClass('name')"
            />
          </button>
        </span>
        <span class="filter">
          <form autocomplete="off" @click.stop>
            <InputBox
              id="filter-files"
              v-model="filter"
              class="filter-items"
              :placeholder="$t('file_list.header.filter_items_placeholder')"
              :prepends="[{ icon: 'filter' }]"
              form-size="sm"
              @input="filterItems"
            />
          </form>
        </span>
      </div>
      <!-- endregion -->

      <!-- region Modified Date Column -->
      <div
        role="columnheader"
        class="zip-viewer-file-list__header-column zip-viewer-file-list__header-column--modified"
        @click="sortItems('modified')"
      >
        <span>
          {{ $t('file_list.header.modified') }}
          <button
            tabindex="0"
            role="button"
            onKeyDown="onSort('date', $event)"
            class="btn btn-link p-0 mb-2"
          >
            <Icon
              :aria-label="$t('file_list.header.modified_aria_label')"
              name="chevron-up"
              class="zip-viewer-file-list__sort-icon"
              :class="sortClass('modified')"
            />
          </button>
        </span>
      </div>
      <!-- endregion -->

      <!-- region Size Column -->
      <div
        id="gensize"
        class="zip-viewer-file-list__header-column zip-viewer-file-list__header-column--size"
        role="columnheader"
        @click="sortItems('size')"
      >
        <span>
          {{ $t('file_list.header.size') }}
          <button
            tabindex="0"
            :aria-label="$t('file_list.header.size_aria_label')"
            role="button"
            onKeyDown="onSort('size', $event)"
            class="btn btn-link p-0 mb-2"
          >
            <Icon
              name="chevron-up"
              class="zip-viewer-file-list__sort-icon"
              :class="sortClass('size')"
            />
          </button>
        </span>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import InputBox from '../../InputBox';
import Icon from '../../Icon';
import _ from 'lodash';

export default {
  name: 'ZipViewerFileListHeader',
  components: { InputBox, Icon },
  data() {
    return {
      sort: null,
      filter: '',
    };
  },
  methods: {
    sortItems(field) {
      if (!this.sort || this.sort.field !== field) {
        this.sort = { field, type: 'asc' };
      } else {
        this.sort.type = this.sort.type === 'asc' ? 'desc' : 'asc';
      }
      this.$emit('sort-items', this.sort);
    },

    sortClass(field) {
      return {
        'zip-viewer-file-list__sort-icon--desc':
          this.sort && this.sort.type === 'desc',
        'zip-viewer-file-list__sort-icon--hidden':
          !this.sort || this.sort.field !== field,
      };
    },

    filterItems: _.debounce(function () {
      this.$emit('filter-items', this.filter);
    }, 500),
  },
};
</script>
