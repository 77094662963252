<template>
  <div class="file-list-header">
    <div class="col-checkbox" />
    <div
      class="col-title header d-flex align-items-baseline picker"
      :class="{
        'col-10': isMobile,
      }"
    >
      <span @click="onSort('name', $event)" style="min-width: 65px">
        {{ $t('Name') }}
        <Icon
          name="chevron-up"
          class="sortable"
          :class="{
            desc: sort && sort.type === -1,
            hidden: sort && sort.field !== 'name',
          }"
        />
      </span>
      <span
        v-if="meta && !meta.isroot"
        class="filter"
        :class="{ 'col-10': isMobile }"
      >
        <InputBox
          id="filter-files"
          v-model="filterValue"
          class="filter-items mb-0"
          :placeholder="$t('Filter Items')"
          :prepends="[{ icon: 'filter' }]"
          form-size="sm"
          @input="onFilter"
        />
      </span>
    </div>
    <div
      v-if="!isMobile"
      class="d-sm-flex align-items-center"
      @click="onSort('date', $event)"
    >
      {{ $t('Modified') }}
      <Icon
        name="chevron-up"
        class="sortable"
        :class="{
          desc: sort && sort.type === -1,
          hidden: sort && sort.field !== 'date',
        }"
      />
    </div>
  </div>
</template>
<script>
import Icon from '../Icon';
import InputBox from 'common/components/InputBox.vue';

export default {
  components: {
    Icon,
    InputBox,
  },
  props: {
    sort: {
      type: Object,
      default: () => {
        return {};
      },
    },
    meta: {
      type: Object,
      default: () => {
        return {};
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
    },
  },
  data() {
    return {
      filterValue: '',
      timeout: null,
      hoverTimeout: null,
      counter: 0,
    };
  },
  computed: {
    isSelected() {
      return this.selection.indexOf(this.item.name) > -1;
    },
  },
  methods: {
    onFilter(value) {
      this.$emit('onFilter', value);
    },
    onSort(key, e) {
      this.$emit('onSort', key, e);
    },
  },
};
</script>
<style lang="scss" scoped>
div.file-list .file-list-header .col-title.header.picker {
  max-width: calc(100% - 40px);
  flex: 0 0 calc(70% - 40px);
}
</style>
