<template>
  <div ref="container" class="form-group col-md-12 p-0 m-0 row">
    <div class="col-md-8 p-0 m-0 float-left">
      <label class="font-size-bigger col-md-12 p-0 m-0">
        <div
          v-if="!hasCondition"
          class="font-weight-light col-md-12 float-left p-0 m-0"
        >
          <span class="font-weight-light">
            {{ $t('Select an attribute') }}
          </span>
          <input
            type="text"
            disabled
            class="meta-input form-control form-control-sm"
            :placeholder="$t('Select metadata attribute')"
          />
        </div>
        <span v-else class="font-weight-light col-md-12 p-0 m-0">
          {{ $t('Attribute: ') }}
        </span>
        <span>
          {{ condition.name }}
        </span>
        <Icon
          v-if="hasCondition"
          v-tooltip="$t(condition.description)"
          name="info-circle"
          family="fas"
          class="info"
        />
      </label>
      <div v-if="getFieldDatatypeText(condition.type) === 'text'">
        <input
          v-model="field.value"
          type="text"
          class="meta-input form-control form-control-sm mb-2"
          @keydown.enter="addMetadataCondition()"
          @focus="$emit('clearErrors')"
        />
      </div>
      <div v-if="getFieldDatatypeText(condition.type) === 'enum'">
        <div class="form-control form-control--select form-control-sm">
          <select v-model="field.value" @change="addMetadataCondition()">
            <option
              v-for="set in enumToArray()"
              :key="set.idx"
              :value="set.text"
            >
              {{ set.text }}
            </option>
          </select>
        </div>
      </div>
      <div
        v-if="getFieldDatatypeText(condition.type) === 'boolean'"
        class="pt-2"
      >
        <Checkbox
          v-model="field.value"
          :checked="field.value"
          @change="$emit('clearErrors')"
        />
      </div>
      <div
        v-if="getFieldDatatypeText(condition.type) === 'date'"
        class="input-group"
      >
        <DatePicker
          v-model="field.value"
          type="date"
          format="MM/DD/YYYY"
          :append-to-body="false"
          :placeholder="$t('MM/DD/YYYY')"
          @change="$emit('clearErrors')"
        />
      </div>
      <div v-if="getFieldDatatypeText(condition.type) === 'int'">
        <input
          v-model="field.value"
          type="number"
          class="form-control meta-input form-control-sm mb-2"
          @focus="$emit('clearErrors')"
          @keydown.enter="addMetadataCondition()"
        />
      </div>
      <div v-if="getFieldDatatypeText(condition.type) === 'decimal'">
        <input
          v-model="field.value"
          type="number"
          class="form-control meta-input form-control-sm mb-2"
          @focus="$emit('clearErrors')"
          @keydown.enter="addMetadataCondition()"
        />
      </div>
      <div v-if="getFieldDatatypeText(condition.type) === 'color'">
        <div class="form-control form-control--select form-control-sm">
          <select v-model="field.value" @change="addMetadataCondition()">
            <option v-for="set in colors" :key="set.value" :value="set.value">
              {{ set.label }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="getFieldDatatypeText(condition.type) === 'array'">
        <input
          v-model="field.value"
          type="text"
          class="form-control meta-input form-control-sm mb-2"
          @focus="$emit('clearErrors')"
          @keydown.enter="addMetadataCondition()"
        />
        <span
          v-for="tag in tagToArray()"
          :key="tag.idx"
          class="badge badge-secondary"
        >
          {{ tag.text }}
          <button
            v-if="condition.write === 1"
            class="fas fa-times-circle"
            @click="removeTag(tag.idx)"
          />
        </span>
      </div>
    </div>
    <div
      v-if="getFieldDatatypeText(condition.type) !== 'color'"
      class="col-md-4 float-right p-0 m-0 pt-1 mt-1 pl-2"
    >
      <button
        class="btn btn-primary wide btn-block"
        :disabled="
          !hasCondition || field.value === null || field.value === undefined
        "
        @click="addMetadataCondition"
      >
        {{ $t('Add condition') }}
      </button>
    </div>
    <div
      v-else
      v-tooltip="
        $t(
          'Allow to select multiple colors, search any files of selected colors'
        )
      "
      class="col-md-4 float-left p-0 m-0 pt-3 mt-2 pl-2"
    >
      <input
        id="useAny"
        v-model="useAny"
        type="checkbox"
        class="form-check-input"
        @change="anyKeyChecked"
      />
      <label
        class="form-check-label search-label"
        style="font-size: 13px"
        for="useAny"
      >
        {{ $t('Any') }}</label
      >
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon.vue';
import DatePicker from 'common/components/NewDatePicker';
import Checkbox from 'common/components/Checkbox';
import _ from 'lodash';
import dayjs from 'dayjs';

export default {
  components: {
    Icon,
    DatePicker,
    Checkbox,
  },
  props: {
    condition: {
      type: Object,
      default: () => {},
    },
    currentSet: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      field: {
        value: null,
      },
      useAny: false,
      updated: 0,
    };
  },
  computed: {
    colors() {
      return this.$store.state.core.colortags;
    },
    hasCondition() {
      return !_.isEmpty(this.condition);
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
  },
  mounted() {
    this.field = this.condition;
  },
  methods: {
    async addMetadataCondition() {
      if (this.condition.type === 5) {
        this.field.value = dayjs(this.field.value).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      if (this.condition.type === 4) {
        this.field.value = (!!this.field.value).toString();
      }

      let payload = {
        value: this.field.value.toString(),
        name: this.condition.name,
        set: this.currentSet.name,
        type: this.condition.type,
        attribute: this.condition.name,
        attributeid: this.condition.attributeid,
      };

      this.$emit('addMetadataCondition', payload);
      this.field.value = null;

      setTimeout(function () {}, 400);
    },
    anyKeyChecked() {
      this.$emit('anyKeyChecked', this.useAny ? 1 : 0);
    },
    getFieldDatatypeText(type) {
      let text = '';
      switch (type) {
        case 1:
          text = 'text';
          break;
        case 2:
          text = 'int';
          break;
        case 3:
          text = 'decimal';
          break;
        case 4:
          text = 'boolean';
          break;
        case 5:
          text = 'date';
          break;
        case 6:
          text = 'enum';
          break;
        case 7:
          text = 'array';
          break;
        case 8:
          text = 'array-objects';
          break;
        case 9:
          text = 'color';
          break;
        default:
          break;
      }
      return text;
    },

    tagToArray() {
      if (!_.isEmpty(this.field.value) && this.field.value.length) {
        let arr = this.field.value.split(',');
        let outArr = [];
        let index = 0;

        arr.forEach((item) => {
          outArr.push({
            text: item,
            idx: index,
          });
          index++;
        });

        return outArr;
      } else {
        return;
      }
    },
    enumToArray() {
      if (_.isEmpty(this.condition.enumvalues)) {
        return [];
      }

      let arr = this.condition.enumvalues.split(',');
      let outArr = [];
      let index = 0;

      arr.forEach((item) => {
        outArr.push({
          text: item,
          idx: index,
        });
        index++;
      });

      return outArr;
    },

    addTag(event) {
      event.preventDefault();

      if (this.field.value.length) {
        this.field.value = this.field.value + ', ' + this.field.editing;
      } else {
        this.field.value = this.field.editing;
      }
      this.field.editing = '';

      this.updated++;
    },

    removeTag(tagidx) {
      let arr = this.field.value.split(', ');
      arr.splice(tagidx, 1);
      let str = arr.join(', ');
      this.field.value = str;
      this.updated++;
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  font-size: 13px;
}
.high-z {
  z-index: 10001;
}
.wide {
  font-size: 12px;
  font-weight: 400;
  height: 31px;
  margin-top: 11px;
  float: right;
}
.font-size-bigger {
  font-size: 13px;
  text-align: left;
}
.meta-input {
  height: 31px;
}
.height-250 {
  height: 250px;
}
.height-31 {
  height: 32px;
  padding: 2px;
  padding-left: 5px;
}
</style>
