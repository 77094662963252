<template>
  <div>
    <DialogModal ref="uploadForm"
      :stick-top="false"
      :title="dialogTitle"
      :visible="isShown"
      :size="'small'"
      :class-name="'upload-dialog'"
      :buttons="buttonArray"
      @close="hideModal"
    >
      <div class="mt-2">
        <textarea readonly rows="15" cols="46" v-model="content" disabled=""></textarea> 
      <p class="small">{{$t('Use the HTML snippet to embed in your own websites')}}</p>
       </div>
    </DialogModal>
  </div>
</template>

<script>
import DialogModal from 'common/components/DialogModal';

export default {
  components: {
    DialogModal,
  },
  props: {
    showModal: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
        isShown: this.showModal,
        content: ""
    };
  },
  computed: {
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    buttonArray() {
      let btnArray = [
        {
          label: this.$t('Close'),
          callback: () => this.hideModal()
        }
      ];
      return btnArray;
    },
    dialogTitle() {
      return this.$t('Sample Upload Form');
    },
  },
  mounted() {
     this.getSampleAnonymousUploadForm();
  },
  methods: {
    hideModal() {
      this.$refs.uploadForm.$el.style.visibility = 'hidden';
      this.isShown = false;
      this.$emit('hideUploadForm');
    },
    async getSampleAnonymousUploadForm(){
      let response = await this.$store.dispatch('share/getSampleAnonymousUploadForm',this.shareProperties.shareid);
      this.content = response.data;
    }
  }
};
</script>
<style scoped>
    .text-black{
        color: #002b36;
    }
</style>