<template>
  <div
    class="file-list-header shared-by-me-header"
    :class="{
      'file-list-header--fixed': sticky && !isMobile && !isTablet,
    }"
  >
    <div
      v-a11ybutton="onSelectAll"
      class="col-checkbox"
      tabindex="0"
      @click="$emit('onSelectAll')"
    >
      <Checkbox
        tabindex="-1"
        :checked="
          selectedFiles.length > 0 &&
          selectedFiles.length === actualFiles.length
        "
        :label="$t('Select all')"
      />
    </div>
    <div class="col-title header shared-by-me">
      {{ $t('Name') }}
      <span class="filter ml-2" :class="{ 'col-9': isMobile || isTablet }">
        <InputBox
          id="filter-files"
          :value="filterValue"
          class="filter-items"
          :placeholder="$t('Filter Items')"
          :errors="errors"
          :prepends="[{ icon: 'filter' }]"
          form-size="sm"
          @input="onFilter"
        />
      </span>
    </div>
    <div v-if="!isMobile && !isTablet" class="text-center">
      {{ $t('Last Access') }}
    </div>
    <div v-if="!isMobile && !isTablet" class="text-center">
      {{ $t('Creation Date') }}
    </div>
    <div v-if="!isMobile && !isTablet" class="text-center">
      {{ $t('Activities in the last 30 days') }}
    </div>
    <div v-if="!isMobile && !isTablet" class="text-center">
      {{ $t('Owner') }}
    </div>
  </div>
</template>

<script>
import FileListHeaderMixin from '@/components/FileList/FileListHeaders/FileListHeaderMixin';

export default {
  name: 'SharedByMeHeader',
  mixins: [FileListHeaderMixin],
};
</script>
