var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"transactions-list-item"},[_c('div',{staticClass:"transaction-icon"},[_c('Icon',{attrs:{"family":"fas","name":"long-arrow-right"}}),(
        _vm.transaction.status !== 'processing' &&
        _vm.transaction.status !== 'preparing'
      )?_c('div',{staticClass:"transaction-icon-status",class:{
        [`transaction-icon-status--error`]: _vm.transaction.status === 'failed',
        [`transaction-icon-status--cancelled`]:
          _vm.transaction.status === 'cancelled',
      }},[(_vm.transaction.status === 'failed')?_c('Icon',{attrs:{"family":"fas","name":"times"}}):(_vm.transaction.status === 'cancelled')?_c('Icon',{attrs:{"family":"fas","name":"ban"}}):_c('Icon',{attrs:{"family":"fas","name":"check"}})],1):_vm._e()],1),_c('div',{staticClass:"transaction-info"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltipContent }),expression:"{ content: tooltipContent }"}],staticClass:"transaction-info-title"},[_vm._v(" "+_vm._s(_vm.currentPath !== _vm.transaction.dirpath ? `${_vm.transaction.dirpath}/` : '')+_vm._s(_vm.transaction.name)+" "),(_vm.transaction.status === 'processing')?_c('small',{attrs:{"role":"status"}},[_vm._v(_vm._s(_vm.transaction.undo ? _vm.$t('Undoing Move') : _vm.$t('Moving to') + ' ' + _vm.transaction.to))]):_c('small')]),(_vm.transaction.status === 'preparing')?_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t('Preparing to move'))+" ")]):(_vm.transaction.status === 'processing')?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar w-10 indeterminate",attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100","aria-valuetext":_vm.$t('Moving files...')}})]):(_vm.transaction.message)?_c('span',{staticClass:"description text-truncate",attrs:{"role":"status"}},[_vm._v(_vm._s(_vm.transaction.message))]):(_vm.transaction.status === 'cancelled')?_c('span',{staticClass:"description",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('You have cancelled this operation'))+" ")]):_c('span',{staticClass:"description text-truncate",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Successfully moved to') + ' ' + _vm.transaction.to)+" ")])]),(_vm.transaction.status === 'completed')?_c('div',{staticClass:"transaction-actions"},[_vm._t("default")],2):_c('div',{staticClass:"transaction-actions"},[(
        _vm.transaction.status !== 'cancelled' &&
        _vm.transaction.status !== 'failed' &&
        _vm.transaction.status !== 'completed'
      )?_c('button',{staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Cancel moving files')},on:{"click":_vm.cancel}},[_c('Icon',{attrs:{"family":"fas","name":"ban"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"ban"}})],1):_vm._e(),(_vm.transaction.status === 'failed')?_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"aria-label":_vm.$t('Overwrite files files')},on:{"click":_vm.overwrite}},[_vm._v(" "+_vm._s(_vm.$t('Overwrite'))+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }