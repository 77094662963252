<template>
  <DialogModal
    class="dialog-modal-table animate-timed-fade locked-files-wrapper"
    :stick-top="true"
    :visible="true"
    class-name="none"
    :title="$t(`Folder Permissions`)"
    :buttons="[
      {
        label: 'OK',
        outline: true,
        callback: () => this.$emit('close'),
      },
    ]"
    @close="$emit('close')"
  >
    <Loader :loading="loading" full />
    <div>
      <div class="table-wrapper-border">
        <table class="table table--properties">
          <caption>
            {{
              $t('Locked Files and Folders')
            }}
          </caption>
          <thead v-if="!loading && paginatedAclFiles.length != 0">
            <th scope="col" class="">
              {{ $t('Path') }}
            </th>
            <th class="centered normal" scope="col">
              {{ $t('Details') }}
            </th>
            <th class="centered thin" scope="col">
              {{ $t('Inherit') }}
            </th>
            <th class="centered" scope="col">{{ $t('Actions') }}</th>
          </thead>
          <tbody>
            <tr
              v-if="!loading && paginatedAclFiles.length === 0"
              class="border-0"
            >
              <td colspan="4" class="centered p-5">
                <EmptyListingInfo
                  icon-name="folder-open"
                  text="No Folder Permissions"
                />
              </td>
            </tr>
            <FolderPermissions
              v-for="file in paginatedAclFiles"
              :key="file.lockrid"
              :item="file"
              @close="refresh"
            />
          </tbody>
        </table>
      </div>

      <Paginator
        v-if="totalItems / itemsPerPage > 1"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        :total-items="totalItems"
        @onPageChange="onPageChage"
      />
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import Paginator from 'common/components/Paginator';
import Loader from 'common/components/Loader';
import EmptyListingInfo from 'common/components/EmptyListingInfo';
import FolderPermissions from './FoldersPermissionModal/FolderPermissions';
import { mapGetters } from 'vuex';

export default {
  components: {
    DialogModal,
    Paginator,
    FolderPermissions,
    Loader,
    EmptyListingInfo,
  },
  data() {
    return {
      itemsPerPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters('auth', ['userid']),
    loading() {
      return this.$store.state.loading['files/getAllAcls'] || false;
    },
    aclFiles() {
      return this.$store.state.files.aclFiles;
    },
    paginatedAclFiles() {
      const initialIndex = (this.currentPage - 1) * this.itemsPerPage;
      const lastIndex = this.currentPage * this.itemsPerPage;
      let type = typeof this.aclFiles === 'object';
      return type
        ? this.aclFiles.slice(initialIndex, lastIndex)
        : [this.aclFiles];
    },
    totalItems() {
      return this.aclFiles.length;
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      const userid = this.userid;
      this.$store.dispatch('files/getAllAcls', { userid });
    },
    onPageChage(newPage) {
      this.currentPage = newPage;
    },
    async onRemove() {
      const userid = this.userid;
      await this.$store.dispatch('files/getAllAcls', { userid });
      this.$emit('deletedPermission');
    },
  },
};
</script>
