<template>
  <div class="subdropdown">
    <div
      :style="!isTablet && !isMobile ? containerTransform : ''"
      class="subdropdown-container"
    >
      <div ref="dropdown" class="dropdown-wrapper">
        <div
          v-for="(item, id) in filteredContextActions"
          :key="id"
          v-close-popover
          tabindex="0"
          class="dropdown-item"
          :class="{
            'has-seperator': item.seperator === true,
            'dropdown-item--disabled':
              child &&
              item.disabled &&
              item.disabled({
                item: child.item,
                isInRecycleFolder,
                isPublicShareView,
                customization,
                systemStatus,
                isLockDisabled,
                isLockedByOthers: isLockedByOthers(child.item),
                isImplicitlyLocked: isImplicitlyLocked(child.item),
                isDRMExportEnabled: fullSystemStatus['isdrmenabled'] === 1,
                isAuthenticated: user.authenticated,
              }),
          }"
        >
          <div @click="$emit('onItemContextClick', item.action, child)">
            <div
              v-if="
                item.iconClass &&
                item.iconClass({ customization, item: child.item })
              "
              class="dropdown-item-icon"
              :class="item.iconClass({ customization, item: child.item })"
            ></div>
            <Icon
              v-else-if="item.icon"
              :name="item.icon"
              :family="item.iconFamily || 'fal'"
            />
            <span v-else-if="item.customIcon">
              <WorkflowIcon class="custom-icon" />
            </span>
            <span class="popover-text"
              >{{
                $t(
                  typeof item.name === 'string'
                    ? item.name
                    : item.name({
                        item: child.item,
                        isInRecycleFolder,
                        isPublicShareView,
                        customization,
                        systemStatus,
                        isLockedByOthers: isLockedByOthers(child.item),
                        isImplicitlyLocked: isImplicitlyLocked(child.item),
                        isAuthenticated: user.authenticated,
                        ...(editorsClient &&
                          editorsClient[child.item.ext] && {
                            editor: editorsClient[child.item.ext],
                          }),
                      })
                )
              }}
              <span
                v-if="item.shortKey"
                class="shortkey float-right text-capitalize"
              >
                {{ item.shortKey }}</span
              >
            </span>
            <span
              v-if="child.item.colortag && item.icon === 'tag'"
              class="float-right text-capitalize"
            >
              <FileListItemColorDot :color="child.item.colortag" />
            </span>
            <Icon
              v-if="item.showArrow"
              name="chevron-right"
              family="far"
              class="dropdown-item--arrow"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import { canEditFileType, getFileType } from 'common/utils/files';

import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    child: {
      type: Object,
      default: () => {
        return {};
      },
    },
    currentColor: {
      type: String,
      default: '',
    },
    yPosition: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      required: true,
    },
    filteredContextActions: {
      type: Array,
      default: () => [],
    },
    isInRecycleFolder: {
      type: Boolean,
      default: false,
    },
    isPublicShareView: {
      type: Boolean,
      default: false,
    },
    isLimitedUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters('core', [
      'WOPIClient',
      'isGoogleDocsEnabled',
      'getGoogleDocFormatInfo',
      'isWOPIEditEnabled',
      'isLockDisabled',
    ]),
    user() {
      return this.$store.state.auth.user;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    containerTransform() {
      const listHeight = this.$parent.$el.scrollHeight;
      const ITEMS_BEFORE_EDIT = 2;
      const position = listHeight + this.yPosition - ITEMS_BEFORE_EDIT * 34;

      return {
        transform: `translateY(-${position}px)`,
      };
    },
    editorsClient() {
      return this.$store.state.core.editorsClient;
    },
  },
  mounted() {},
  methods: {
    canEditFileType,
    getFileType,
    isLockedByOthers(item) {
      return item.locked && item.lockuserid !== this.user.profile;
    },
    isImplicitlyLocked(item) {
      return item.implicitlock;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  width: max-content;
  .dropdown-item {
    font-size: 13px;
    line-height: 16px;
    .dropdown-item-icon {
      height: 16px;
      margin-right: 12px;
      width: 16px;
      display: inline-block;
    }
  }
}
</style>
