var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"file-list-header"},[_c('div',{staticClass:"col-checkbox"}),_c('div',{staticClass:"col-title header d-flex align-items-baseline picker",class:{
      'col-10': _vm.isMobile,
    }},[_c('span',{staticStyle:{"min-width":"65px"},on:{"click":function($event){return _vm.onSort('name', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('Name'))+" "),_c('Icon',{staticClass:"sortable",class:{
          desc: _vm.sort && _vm.sort.type === -1,
          hidden: _vm.sort && _vm.sort.field !== 'name',
        },attrs:{"name":"chevron-up"}})],1),(_vm.meta && !_vm.meta.isroot)?_c('span',{staticClass:"filter",class:{ 'col-10': _vm.isMobile }},[_c('InputBox',{staticClass:"filter-items mb-0",attrs:{"id":"filter-files","placeholder":_vm.$t('Filter Items'),"prepends":[{ icon: 'filter' }],"form-size":"sm"},on:{"input":_vm.onFilter},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}})],1):_vm._e()]),(!_vm.isMobile)?_c('div',{staticClass:"d-sm-flex align-items-center",on:{"click":function($event){return _vm.onSort('date', $event)}}},[_vm._v(" "+_vm._s(_vm.$t('Modified'))+" "),_c('Icon',{staticClass:"sortable",class:{
        desc: _vm.sort && _vm.sort.type === -1,
        hidden: _vm.sort && _vm.sort.field !== 'date',
      },attrs:{"name":"chevron-up"}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }