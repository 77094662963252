<template>
  <div>{{ dateToDisplay(date) }}</div>
</template>

<script>
import dateFormatsMixin from 'common/mixins/dateTimeFormatMixin';
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const invalidDates = ['Invalid Date', 'Invalid date', NaN];

export default {
  mixins: [dateFormatsMixin],

  props: {
    date: Number || String,
    onlyDate: Boolean,
  },
  methods: {
    dateToDisplay(when) {
      const date =
        this.getCalendar === 'hijri'
          ? this.hijri(when * 1000)
          : dayjs.unix(when).format(this.dateFormat + ' ' + this.timeFormat);
      return invalidDates.includes(date) ? '-' : date;
    },
  },
};
</script>
