<template>
  <Drop
    drop-effect="copy"
    :class="{ [`is-dragging`]: isDragActive, 'page-wrapper': !hideNavigation }"
    @drop="onDropFile"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
  >
    <div
      v-if="isDroppable"
      class="droppable-wrapper droppable-wrapper--fullscreen"
    >
      <div class="droppable-box"></div>
    </div>

    <FilePickerModal v-if="isDroppable" ref="filePickerModal" />

    <MiniAdmin />

    <DesktopNavbar
      v-if="!isMobile && !isTablet"
      :hide-nav="hideNavigation"
      @toggleShowShortcutsModal="toggleShowShortcutsModal"
      @toggleLockedFilesModal="toggleLockedFilesModal"
      @showDRMAccessFilesModal="showDRMAccessFilesModal"
      @showFolderPermissionsModal="showFolderPermissionsModal"
      @toggleGroupManageModal="toggleGroupManageModal(true)"
    />

    <MobileNavbar
      v-else
      :hide-nav="hideNavigation"
      @openMobileNavigation="openMobileNavigation"
      @toggleShowShortcutsModal="toggleShowShortcutsModal"
      @toggleLockedFilesModal="toggleLockedFilesModal"
      @showDRMAccessFilesModal="showDRMAccessFilesModal"
      @showFolderPermissionsModal="showFolderPermissionsModal"
    />

    <div
      v-shortkey="shortkeys"
      :class="{ 'container-fluid': !isMobile && !isTablet }"
      @shortkey="onShortcut"
    >
      <div :class="{ row: !isMobile && !isTablet }">
        <Multipane v-if="!hideNavigation" layout="vertical" style="width: 100%">
          <div class="sidebar timed-slither">
            <a
              v-a11ybutton="toggleNavigationBar"
              class="sidebar-toggle"
              :class="{ closed: !navigationSidebar.open }"
              tabindex="0"
              :aria-label="
                navigationSidebar.open
                  ? $t('Collapse sidebar')
                  : $t('Expand navigation sidebar')
              "
              @click="toggleNavigationBar"
            >
              <Icon
                :name="
                  navigationSidebar.open ? 'arrow-to-left' : 'arrow-to-right'
                "
                family="fas"
              />
            </a>
            <NavigationBarOpenned
              v-if="!isMobile && !isTablet && navigationSidebar.open"
              ref="navigationBar"
            />
            <NavigationBarClosed
              v-else-if="!isMobile && !isTablet && !navigationSidebar.open"
              ref="navigationBarClosed"
            />
          </div>

          <MultipaneResizer></MultipaneResizer>

          <div
            :style="{ flexGrow: 1 }"
            class="main-content-wrapper"
            :class="{ ['activity-bar-active']: sidebar.open }"
          >
            <main
              role="main"
              aria-label="Main view"
              class="main-content ml-sm-auto px-0"
              id="main-section"
            >
              <router-view></router-view>
              <portal
                :to="
                  zipPreviewOpen
                    ? 'transaction-container-zip'
                    : 'transaction-container'
                "
              >
                <!--
                  IMPORTANT: Transaction container was included inside a portal because we need to
                  render it outside the main wrapper to avoid z-index issues when showing the zip file preview.
                  Also transactions container cannot be instantiated twice because some of it's internal functions
                  might be throttled which will cause the second instance to not work properly.
                -->
                <TransactionsContainer />
              </portal>
              <portal-target
                name="transaction-container"
                class="transactions-container"
                :class="{
                  [`transactions-container--visible`]: transactionsBar.visible,
                  [`transactions-container--expanded`]:
                    transactionsBar.expanded,
                }"
              />
            </main>

            <FileSidebar
              v-if="
                !$route.name.includes('settings-page') &&
                !isShareWithMePage &&
                !isNetworkPage &&
                !isWorkflowsPage &&
                !isWorkflowsInstancesPage &&
                !isActionsPageOrSubpage &&
                !isSearchResultsPage
              "
              :is-animated="true"
            />
          </div>
        </Multipane>
        <!--LOGIN MAIN-->
        <main
          v-if="hideNavigation"
          role="main"
          aria-label="Login view"
          class="ml-sm-auto px-0 col-md-12 col-lg-12"
        >
          <transition name="slither">
            <router-view></router-view>
          </transition>
        </main>
      </div>
    </div>
    <ShortCutsHelp
      v-if="showShortCutsModal"
      @close="toggleShowShortcutsModal(false)"
    />

    <LockedFiles
      v-if="!isLimitedUser && showLockedFiles"
      @close="toggleLockedFilesModal(false)"
    />
    <FolderPermissions
      v-if="!isLimitedUser && showFolderPermissions"
      ref="folderPermissionsModal"
      @close="toggleFolderPermissionsModal(false)"
      @deletedPermission="reloadPerms"
    />
    <DRMAccessFilesListModal
      v-if="!isLimitedUser && showDRMAccessFilesModalVal"
      @close="toggleDRMAccessFilesModal(false)"
    />

    <GroupManage
      v-if="groupModalOpen"
      :key="modalKey"
      :visible="groupModalOpen"
      @close="toggleGroupManageModal(false)"
    />

    <LogoutModal />

    <NotificationModal
      v-if="notificationDialog.visibility"
      :path="notificationDialog.path"
      @close="notificationDialog.visibility = false"
    />
    <DRMExportReponseDialog
      v-if="showDRMExportResponseDialog"
      @openDRMModal="toggleDRMAccessFilesModal"
    />
    <ShareDialog
      v-if="showShareDialog"
      :readonly="isShareDialogReadonly"
      :shareSettingNotAllowed="isShareSettingChangeNotAllowed"
    />
    <ShareLinkEmail v-if="showShareLinkEmail" />

    <DialogModal
      :visible="deleteConfirmationModal.show"
      :title="$t('Delete')"
      :buttons="deleteConfirmationModal.buttons"
      class="small"
      position="top"
      @close="closeDeleteConfirmationModal"
    >
      {{ $t('Are you sure you want to delete the selected items?') }}
    </DialogModal>

    <!-- mobile components -->
    <MobileSearch v-if="isMobile || isTablet" ref="mobileSearch" />
    <FileMenu v-if="isMobile || isTablet" ref="mobileMenu" />
    <NewFileMenu v-if="isMobile || isTablet" ref="mobileNewFileMenu" />

    <MobileNavigation v-if="isMobile || isTablet" ref="mobileNavigation" />

    <ScreenReaderAnnouncement />

    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-if="showMobileOverlay && (isMobile || isTablet)"
        class="mobile__overlay"
        @click="overlayClicked"
      />
    </transition>
    <portal-target
      name="transaction-container-zip"
      class="transactions-container transactions-container--zip"
      :class="{
        [`transactions-container--visible`]: transactionsBar.visible,
        [`transactions-container--expanded`]: transactionsBar.expanded,
      }"
    />
  </Drop>
</template>

<script>
import { Multipane, MultipaneResizer } from 'vue-multipane';
import FileSidebar from './../components/FileSidebar';
import TransactionsContainer from './../components/TransactionsContainer';
import { globalShortcuts } from '../constants/shortcuts';
import ShortCutsHelp from '../components/Modals/ShortcutsHelp';
import LockedFiles from '../components/Modals/LockedFiles';
import GroupManage from '../components/Modals/GroupModal/GroupModal';
import FolderPermissions from '../components/Modals/FolderPermissions';
import DRMAccessFilesListModal from '../components/Modals/DRMAccessFilesListModal';
import NotificationModal from '../components/Modals/NotificationModal';
import DRMExportReponseDialog from '../components/Modals/DRMExportResponseModal';
import ShareDialog from '../components/Shares/shareModalSimple';
import DialogModal from 'common/components/DialogModal';
//import Onboarding from '../components/Onboarding/Onboarding.vue';
// import TourInvitation from '../components/Modals/TourInvitation.vue';
import ShareLinkEmail from '../components/Shares/shareLinkEmail';
import vueinterval from 'vue-interval/dist/VueInterval.common';
import DesktopNavbar from '../components/Partials/Navbar';
import ScreenReaderAnnouncement from '../components/Partials/ScreenReaderAnnouncement';
import NavigationBarOpenned from '../components/Navigation/NavigationBarOpenned';
import NavigationBarClosed from '../components/Navigation/NavigationBarClosed';
import FileMenu from '../components/Mobile/FileMenu';
import NewFileMenu from '../components/Mobile/NewFileMenu';
import MobileNavigation from '../components/Mobile/Navigation';
import MobileNavbar from '../components/Mobile/Navbar';
import MobileSearch from '../components/Mobile/Search';
import FilePickerModal from '../components/Modals/FilePicker';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import Vue from 'vue';
import FilePreview from 'common/components/FilePreview/plugin.js';
import store from '../store';
import Icon from 'common/components/Icon';
import router from '../router';
import { Drop } from 'vue-drag-drop';
import MiniAdmin from '@/components/Modals/MiniAdmin';
import { mapActions, mapGetters, mapState } from 'vuex';
import { i18n } from '@/i18n';
import LogoutModal from '../components/Modals/LogoutModal.vue';

export default {
  name: 'MainLayout',
  components: {
    MiniAdmin,
    Multipane,
    MultipaneResizer,
    FileSidebar,
    TransactionsContainer,
    ShortCutsHelp,
    ShareDialog,
    ShareLinkEmail,
    LockedFiles,
    FolderPermissions,
    DRMAccessFilesListModal,
    DRMExportReponseDialog,
    FilePickerModal,
    Drop,
    DesktopNavbar,
    FileMenu,
    NewFileMenu,
    MobileNavbar,
    Icon,
    MobileNavigation,
    MobileSearch,
    NavigationBarOpenned,
    NavigationBarClosed,
    NotificationModal,
    ScreenReaderAnnouncement,
    GroupManage,
    DialogModal,
    LogoutModal,
  },
  mixins: [vueinterval, responsivenessMixin],
  data() {
    return {
      recentFiles: null,
      favoritesList: null,
      shares: null,
      hideNavigation: false,
      images: require.context('../assets/', false, /\.png$/),
      showLockedFiles: false,
      showFolderPermissions: false,
      showDRMAccessFilesModalVal: false,
      showShortCutsModal: false,
      isDragActive: false,
      groupModalOpen: false,
      notificationDialog: {
        path: '',
        visibility: false,
      },
      dragCounter: 0,
      modalKey: 0,
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    ...mapState('files', [
      'zipPreviewOpen',
      'transactionsBar',
      'deleteConfirmationModal',
    ]),
    isDroppable() {
      return this.$route.name !== 'files-sub';
    },
    shortkeys() {
      const isTourRunning = this.$store.getters['onboarding/getCurrentStep'];

      return !isTourRunning ? globalShortcuts : {};
    },
    core() {
      return this.$store.state.core;
    },
    auth() {
      return this.$store.state.auth;
    },
    currentFolder() {
      const str = this.$route.path;
      return /[^/]*$/.exec(str)[0];
    },
    customization() {
      return this.$store.state.core.customization;
    },
    communityEdition() {
      return this.customization && this.customization.isCommunityEdition();
    },
    getCommentsForSelection() {
      return this.$store.state.files.getSelectedFileComments;
    },
    sidebarKey() {
      return this.$store.state.files.sidebarKey;
    },
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
    showShareDialog() {
      return this.$store.state.share.shareDialogOpen;
    },
    isShareDialogReadonly() {
      return this.$store.state.share.shareDialogReadonly;
    },
    isShareSettingChangeNotAllowed() {
      return this.$store.state.share.shareDialogDisallowChange;
    },
    showShareLinkEmail() {
      return this.$store.state.share.emailShareLinkDialogOpen;
    },
    showDRMExportResponseDialog() {
      return this.$store.state.files.drmExportResponseDialogOpen;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    navigationSidebar() {
      return this.$store.state.core.navigationSidebar;
    },
    showMobileOverlay() {
      return this.$store.state.files.showMobileOverlay;
    },
    isShareWithMePage() {
      return this.currentFolder === 'SHARED';
    },
    isSearchResultsPage() {
      return this.currentFolder === 'srch-tabl.';
    },
    isWorkflowsPage() {
      return this.$route.name === 'workflow';
    },
    isWorkflowsInstancesPage() {
      return (
        this.$route.name === 'workflow-instances' ||
        this.$route.name === 'workflow-instance-detail'
      );
    },
    isActionsPageOrSubpage() {
      return this.$route.name.indexOf('actions') > -1;
    },
    isNetworkPage() {
      return this.currentFolder === 'EXTERNAL';
    },
    operationsBar() {
      return this.$store.state.files.transactionsBar;
    },
    isOnboardingAccepted() {
      return this.$store.state.onboarding.isOnboardingAccepted;
    },
  },
  watch: {
    $route(to, from) {
      if (
        to.name === 'webeditor' ||
        to.path === '/' ||
        to.name === 'workflows-sub'
      ) {
        this.hideNavigation = true;
        document.body.style.overflowY = 'hidden';
      } else {
        this.hideNavigation = false;
        document.body.style.overflowY = 'auto';
      }

      // close operations bar if expanded
      if (this.operationsBar && this.operationsBar.expanded) {
        this.$store.commit('files/setTransactionsBar', {
          key: 'expanded',
          value: false,
        });
      }
    },
    showMobileOverlay(newstate, oldstate) {
      document.getElementsByTagName('body')[0].style.overflowY = newstate
        ? 'hidden'
        : 'auto';
    },
  },
  created() {
    Vue.use(FilePreview, {
      store,
      router,
      i18n,
    });
  },
  mounted() {
    if (
      this.$route.path === '/' ||
      this.$route.name === 'webeditor' ||
      this.$route.name === 'workflows-sub'
    ) {
      this.hideNavigation = true;
    }

    if (this.$route.name !== 'home') {
      this.$store.dispatch('auth/readyState');
    }

    if (this.isMobile || this.isTablet) {
      this.$store.commit('files/setSidebar', { key: 'open', value: false });
    }
    this.$root.$on('pathRuleNotification', (file) => {
      this.notificationDialog = {
        path: file,
        visibility: true,
      };
    });

    document.documentElement.style.setProperty('--animate-duration', '300ms');
  },
  methods: {
    ...mapActions('files', [
      'openDeleteConfirmationModal',
      'closeDeleteConfirmationModal',
    ]),
    toggleNavigationBar() {
      this.$store.dispatch('core/setCustomizations', {
        name: 'SIDEPANEL_LEFT_COLLAPSE',
        value: this.navigationSidebar.open ? 1 : 0,
      });
      this.$store.commit('core/toggleNavigationSidebar');
    },
    toggleShowShortcutsModal(value) {
      this.showShortCutsModal = value != undefined ? value : true;
    },
    toggleLockedFilesModal(value) {
      this.showLockedFiles = value != undefined ? value : true;
    },
    toggleFolderPermissionsModal(value) {
      this.showFolderPermissions = value != undefined ? value : true;
    },
    toggleDRMAccessFilesModal(value) {
      this.showDRMAccessFilesModalVal = value != undefined ? value : true;
    },

    async reloadPerms() {
      // @todo: move this to mixins
      let userid = this.$store.state.auth.user.profile;
      await this.$store.dispatch('files/getAllAcls', { userid });
    },
    async showDRMAccessFilesModal() {
      if (!this.isLimitedUser) {
        await this.$store.dispatch('files/getDRMFilesList');
        this.toggleDRMAccessFilesModal(true);
      }
    },
    showFolderPermissionsModal() {
      this.showFolderPermissions = true;
    },
    async toggleGroupManageModal(value) {
      this.groupModalOpen = value;
      this.modalKey += 1;
    },
    onShortcut(key) {
      switch (key.srcKey) {
        case 'toggle-sidebar':
          if (this.$refs.navigationBar) {
            this.$refs.navigationBar.toggleNavigationBar();
          } else {
            this.$refs.navigationBarClosed.toggleNavigationBar();
          }
          break;

        case 'toggle-progress':
          if (this.$refs.navigationBar) {
            this.$refs.navigationBar.toggleTransactions();
          } else {
            this.$refs.navigationBarClosed.toggleTransactions();
          }
          break;

        case 'toggle-shortcut-help':
          this.toggleShowShortcutsModal(true);
          break;

        case 'history-next':
          this.$router.go(1);
          break;

        case 'history-before':
          this.$router.go(-1);
          break;
      }
    },
    async onDropFile(ext, e) {
      if (!this.isDroppable) return;

      e.preventDefault();
      e.stopPropagation();

      if (ext) return;

      let droppedItems = e.dataTransfer.items;

      if (droppedItems.length > 0) {
        // convert from DataTransferItem to FileEntry or FolderEntry in order to persist items
        let items = [];
        for (var i = 0; i < droppedItems.length; i++) {
          let entry = droppedItems[i].webkitGetAsEntry();
          if (entry) {
            items.push(entry);
          }
        }

        if (items.length > 0) {
          this.$refs.filePickerModal.open('upload', '', (path) => {
            this.$store.dispatch('files/upload', {
              items: items,
              path: path,
            });
          });
        }
      }

      this.dragCounter = 0;
      this.isDragActive = false;
    },
    onDragEnter(ext) {
      if (ext || !this.isDroppable) return;

      this.dragCounter++;
      this.isDragActive = true;

      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: false,
      });
    },
    onDragLeave(ext) {
      if (ext || !this.isDroppable) return;

      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.isDragActive = false;
      }
    },
    openMobileNavigation() {
      this.$refs.mobileNavigation.open();
    },
    overlayClicked() {
      if (this.isMobile && !this.isTablet) {
        this.$refs.mobileNewFileMenu.close();
      }

      this.$refs.mobileMenu.close();
      this.$refs.mobileNavigation.close();
      this.$refs.mobileSearch.close();
      this.$root.$emit('overlayClicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile__overlay {
  background-color: rgba($color: #000, $alpha: 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
.sidebar-container > div {
  position: relative;
  z-index: 1;
}
.sidebar {
  z-index: 2;
}
.sidebar-toggle {
  z-index: 107;
  &.closed {
    right: -28px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>
