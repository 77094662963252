<template>
  <div class="settings-page-children notifications-settings">
    <Loader :loading="loading" full />
    <div class="radio-wrapper">
      <Radiobox
        v-model="form.notificationType"
        name="notifiType"
        radio-value="default"
        :label="$t(title)"
        @change="updateNotification"
      />
      <Radiobox
        v-model="form.notificationType"
        :disabled="!form.allowOverride"
        name="notifiType"
        radio-value="own"
        :label="$t('Use my own notification settings')"
        @change="updateNotification"
      />
    </div>
    <div v-if="form.enableCustomNotifications">
      <div
        class="checkbox-wrapper d-flex align-items-center pt-2"
        :class="{
          'pl-5': !isMobile && !isTablet,
          'pl-4': isTablet,
        }"
      >
        <Checkbox v-model="form.enableNotifications" />
        <span>{{ $t('Send Notifications') }}</span>
        <v-select
          v-if="form.frequencyMode"
          v-model="form.frequencyMode"
          :reduce="(frequency) => frequency.id"
          label="label"
          class="frequency"
          :class="{ ['disabled-block']: !form.enableNotifications }"
          :options="frequency"
        />
        <div v-if="form.frequencyMode == 'custom'" class="ml-4">
          <input
            v-model="form.frequencyMinutes"
            type="number"
            class="form-control form-control-sm"
            :min="form.minimumFrequencyMinutes"
          />
        </div>
      </div>

      <div
        :class="{
          'disabled-block': !form.enableNotifications,
          'ml-5 mb-4': !isMobile && !isTablet,
          'ml-4 mb-4': isTablet,
        }"
        class="notification-options mt-3"
      >
        <p class="strong">
          {{ $t('Send Notifications on:') }}
        </p>
        <div v-if="notifyOptions.length" class="options-wrapper">
          <div
            v-for="(option, id) in notifyOptions"
            :key="id"
            class="notify-single-option"
          >
            <div class="d-flex align-items-center">
              <Checkbox
                v-model="form[option.model]"
                :label="$t(option.text)"
                :description="$t(option.subtext)"
                class="mr-2"
              />
              <label>{{ $t(option.text) }}</label>
            </div>
            <div class="sub-text">
              {{ $t(option.subtext) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!path" class="d-flex justify-content-end align-items-center">
      <button class="btn wide btn-primary btn-save" @click="updateUserSettings">
        {{ $t('Save') }}
      </button>
    </div>
  </div>
</template>

<script>
import Radiobox from 'common/components/Radiobox';
import Checkbox from 'common/components/Checkbox';
import Loader from 'common/components/Loader';
import Options from '../../constants/notificationOptions';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Radiobox,
    Checkbox,
    Loader,
  },
  mixins: [responsivenessMixin],
  props: {
    path: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: 'Use default notification settings (as configured by Admin)',
    },
  },
  data() {
    return {
      notifyOptions: Options,
      form: {},
    };
  },
  computed: {
    frequency() {
      return [
        { id: 'instant', label: 'instant' },
        { id: 'weekly', label: 'weekly' },
        { id: 'daily', label: 'daily' },
        { id: 'custom', label: 'Custom' },
      ].map(({ id, label }) => {
        return { label: this.$t(label), id: id };
      });
    },
    loading() {
      return (
        this.$store.state.loading['core/getUserSettings'] ||
        this.$store.state.loading['core/updateUserSettings']
      );
    },
    notifySettings() {
      return this.path
        ? this.$store.state.core.filePathRules.rule
        : this.$store.state.core.notifySettings;
    },
  },
  async mounted() {
    if (this.path)
      await this.$store.dispatch('core/getRulesForPath', { path: this.path });
    else await this.$store.dispatch('core/getUserSettings');
    this.form = {
      enableCustomNotifications: undefined,
      ...this.notifySettings,
      enableNotifications: !this.notifySettings.disableAllNotifications,
      notificationType: this.notifySettings.enableCustomNotifications
        ? 'own'
        : 'default',
    };
  },
  methods: {
    fillUp() {
      this.notifyOptions.map((key) => {
        if (!this.form.hasOwnProperty(key.model)) this.form[key.model] = 0;
      });
    },
    booleanMap(v) {
      Object.keys(v).map((key) => {
        if (typeof v[key] === 'boolean') v[key] = v[key] ? 1 : 0;
      });
      this.fillUp();
    },
    updateNotification(value) {
      this.form.enableCustomNotifications = value === 'own' ? true : false;
    },
    async updateUserSettings() {
      this.form.disableAllNotifications = !this.form.enableNotifications;
      this.booleanMap(this.form);
      const APIcall = this.path // API chooser based if it is for general of file settings
        ? 'core/updateFileSettings'
        : 'core/updateUserSettings';
      const response = await this.$store.dispatch(APIcall, this.form);

      this.openToast(response.ok, 'Notification Settings');
      this.$store.dispatch('files/notificationInfoForFile', this.path);
    },
    close() {
      this.$emit('closeModal');
    },
    openToast(success, type, extraInfo = '') {
      let toast;
      if (success) {
        this.close();
        toast = {
          message: `<b>${this.$t('Success')}</b>
            <p role="alert">${type} ${this.$t(
            'updated successfully.'
          )} ${extraInfo}</p>`,
          type: 'success',
        };
      } else {
        toast = {
          message: `<b>${this.$t('Error')}</b>
            <p role="alert">${this.$t('Unable to update')}</p>`,
          type: 'warning',
        };
      }
      this.$toast.open(toast);
    },
  },
};
</script>
