export default {
  computed: {
    errorsObj() {
      let errors;
      if (typeof this.errors === 'object') {
        errors = this.errors;
        Object.keys(errors).forEach((key) => {
          errors[key].message = this.$t(errors[key].message);
        });
      } else {
        errors = { form: this.$t(this.errors) };
      }

      return errors;
    },
  },
};
