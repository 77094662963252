<template>
  <div class="file-preview-item file-preview-item--default">
    <Loader />
  </div>
</template>

<script>

import Loader from 'common/assets/loader.svg';

export default {
  props: {
    file: {
      type: Object
    }
  },
  components: {
    Loader,
  }
}
</script>
