<template>
  <div class="file-sidebar-header">
    <div
      class="file-sidebar-header-icon"
      :class="{ 'w-100': sidebar.selected.length > 1 }"
    >
      <div v-if="sidebar.selected.length <= 1">
        <FileIcon
          v-if="currentFile.type === 'file'"
          :name="currentFile.name"
          :item="currentFile"
          :path="currentFile.path"
          :format="currentFile.format"
          :size="thumbSize"
          class="img-fluid file-sidebar-header-typefile"
        />
        <TeamFolders v-else-if="isTeamFolderRoot" class="mb-2" />
        <NetworkFolders v-else-if="isNetworkShareRoot" class="mb-2" />
        <SharedFolders v-else-if="isSharedWithMeRoot" class="mb-2" />
        <Icon
          v-else-if="
            meta &&
            currentFile.dirpath === '/SHARED' &&
            meta.parentpath === '/SHARED' &&
            meta.isroot === 1
          "
          family="fas"
          name="user"
        />
        <Icon
          v-else-if="
            !currentFile.type ||
            currentFile.type == 'dir' ||
            currentFile.type == 'folder'
          "
          family="fas"
          name="folder"
        />
      </div>
      <div v-else class="multifiles">
        <FileIcon
          name="multifiles"
          format="multifiles"
          :size="thumbSize"
          class="img-fluid file-sidebar-header-typefile pb-0"
        />
      </div>
    </div>
    <p
      v-if="sidebar.selected.length <= 1"
      v-tooltip="
        typeof currentFile.name == 'string'
          ? sanitizeFileName(currentFile.name)
          : ''
      "
      class="file-sidebar-header-title text-truncate mt-1"
    >
      <span v-if="isTeamFolderRoot">{{ $t('Team Folders') }}</span>
      <span v-else-if="isNetworkShareRoot">{{ $t('Network Shares') }}</span>
      <span v-else-if="isSharedWithMeRoot">{{ $t('Shared with Me') }}</span>
      <span v-else>{{ currentFile.name }}</span>
    </p>
    <p v-else class="file-sidebar-header-title text-truncate mt-2">
      <span>{{ sidebar.selected.length }} files selected</span>
    </p>
  </div>
</template>

<script>
import FileIcon from 'common/components/FileIcon';
import Icon from 'common/components/Icon';
import { sanitizeFileName } from 'common/utils/files';
import TeamFolders from '@/assets/files/team-folders.svg';
import NetworkFolders from '@/assets/files/network-folders.svg';
import SharedFolders from '@/assets/files/shared-with-me.svg';
import { sizes } from '@/constants/sizes';

export default {
  components: {
    FileIcon,
    Icon,
    TeamFolders,
    NetworkFolders,
    SharedFolders,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    meta() {
      return this.$store.getters['files/getPathMeta'](this.path);
    },
    isTeamFolderRoot() {
      return this.meta && this.meta.teamfolderroot;
    },
    isNetworkShareRoot() {
      return this.meta && this.meta.isroot && this.meta.realpath == '/EXTERNAL';
    },
    isSharedWithMeRoot() {
      return this.meta && this.meta.isroot && this.meta.realpath == '/SHARED';
    },
    thumbSize() {
      return sizes.listThumb;
    },
    sidebar() {
      return this.$store.getters['files/getSidebarState']();
    },
  },
  methods: {
    sanitizeFileName,
  },
};
</script>

<style lang="scss">
@import 'common/scss/_colors.scss';
.file-sidebar-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0 12px 0;

  &-typefile {
    transform: scale(2);
    padding-bottom: 27px;
    .file-format {
      top: 2.8rem !important;
    }
  }

  &-icon {
    color: var(--color-primary);
    font-size: 4rem;
    max-height: 5rem;
  }

  &-title {
    color: var(--text-mid-dark);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    max-width: 90%;
  }
}
</style>
