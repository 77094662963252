<template>
  <div>
    <Checkbox
      v-model="field.value"
      :disabled="metadata.write !== 1 || readOnly"
      :checked="field.value"
      @change="onFieldChange"
    />
  </div>
</template>

<script>
import Checkbox from 'common/components/Checkbox';
import dataTypeMixin from './_dataTypeMixin';

export default {
  components: {
    Checkbox,
  },
  mixins: [dataTypeMixin],
};
</script>
