<template>
  <permissionTable
    :label="label"
    :items="items"
    :update-permission-fn="updatePermission"
    :disabled-object="disabledObject"
    :context="tableContext"
  />
</template>

<script>
import Themed from '../Themed';
import Checkbox from 'common/components/Checkbox';
import Icon from 'common/components/Icon';
import permissionTable from '../Tables/permissionTable';

export default {
  name: 'SecurityPermissionTable',
  components: {
    Themed,
    Checkbox,
    Icon,
    permissionTable,
  },
  props: {
    label: String,
    type: {
      type: String,
      validator: function (value) {
        return ['group', 'user'].indexOf(value) !== -1;
      },
    },
    items: {
      type: Array,
      default: () => [],
    },
    path: {
      type: String,
      default: '',
    },
    inherited: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableContext: {
        context: 'security',
      },
    };
  },
  computed: {
    disabledObject() {
      if (this.inherited) {
        let _disabled = {
          read: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
          write: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
          share: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
          delete: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
          manage: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
          remove: {
            label: 'Permissions inherited from parent folder',
            disabled: true,
          },
        };

        return _disabled;
      }
      return {
        read: {
          label: '',
          disabled: false,
        },
      };
    },
  },
  methods: {
    getPerms(groupUser) {
      if (!groupUser) return '';
      let perms = `${groupUser.read ? 'R' : ''}${groupUser.write ? 'W' : ''}${
        groupUser.share ? 'S' : ''
      }${groupUser.allowmanage ? 'M' : ''}${
        groupUser.disallowdelete ? '' : 'D'
      }`;
      return perms;
    },
    async updatePermission(value, permission, index) {
      if (this.inherited) return;
      switch (permission) {
        case 'read':
          value.read = !value.read;
          if (!value.read) {
            value = {
              ...value,
              write: false,
              manage: false,
              share: false,
              disallowdelete: true,
              allowmanage: false,
            };
          }
          let payload = {
            path: this.path,
            type: this.type,
            value: this.type === 'user' ? value.name : value.groupid,
            perm: !value.read ? 'RWDSM' : 'R',
            flag: !value.read ? 'deny' : 'allow',
          };
          this.$store.dispatch('files/addAclEntryForPath', payload);
          payload.perm = !value.read ? '' : 'R';
          this.$emit('updatePerms', payload);
          return;
        case 'write':
          value.write = !value.write;
          if (!value.write) {
            value = {
              ...value,
              manage: false,
              share: false,
              disallowdelete: true,
              allowmanage: false,
            };
          } else {
            value = {
              ...value,
              read: true,
            };
          }
          break;
        case 'share':
          value.share = !value.share;
          if (value.share) {
            value = {
              ...value,
              read: true,
              write: true,
            };
          } else {
            value = {
              ...value,
              allowmanage: false,
            };
          }
          break;
        case 'disallowdelete':
          value.disallowdelete = !value.disallowdelete;
          if (!value.disallowdelete) {
            value = {
              ...value,
              read: true,
              write: true,
            };
          } else {
            value = {
              ...value,
              allowmanage: false,
            };
          }
          break;
        case 'allowmanage':
          value.allowmanage = !value.allowmanage;
          break;
      }
      if (permission === 'delete') {
        this.$emit('deleteAcl', {
          value: this.type === 'user' ? value.name : value.groupid,
          type: this.type,
          index: index,
        });
      } else {
        let payload = {
          path: this.path,
          type: this.type,
          value: this.type === 'user' ? value.name : value.groupid,
          perm: this.getPerms(value),
          flag: 'allow',
        };
        this.$emit('updatePerms', payload);
        this.$store.dispatch('files/addAclEntryForPath', payload);
      }
    },
  },
};
</script>
