export const Themes = {
  default: {
    primaryColor: '#007acc',
    backgroundColor: '#ffffff',
    textColor: '#333333',
  },
  red: {
    primaryColor: '#ce0000',
  },
  dark: {
    backgroundColor: '#282828',
    textColor: '#eeeeee',
  },
  crazy: {
    textColor: '#ff0000',
    backgroundColor: '#0000ff',
    primaryColor: '#00ff00',
  },
};

export default Themes;
