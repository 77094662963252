var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"transactions-list-item"},[_c('div',{staticClass:"transaction-icon"},[_c('Icon',{attrs:{"family":"fas","name":"download"}}),(
        _vm.transaction.status !== 'processing' &&
        _vm.transaction.status !== 'preparing'
      )?_c('div',{staticClass:"transaction-icon-status",class:{
        [`transaction-icon-status--error`]: _vm.transaction.status === 'failed',
        [`transaction-icon-status--cancelled`]:
          _vm.transaction.status === 'cancelled',
      }},[(_vm.transaction.status === 'failed')?_c('Icon',{attrs:{"family":"fas","name":"times"}}):(_vm.transaction.status === 'cancelled')?_c('Icon',{attrs:{"family":"fas","name":"ban"}}):_c('Icon',{attrs:{"family":"fas","name":"check"}})],1):_vm._e()],1),_c('div',{staticClass:"transaction-info"},[_c('span',{staticClass:"transaction-info-title"},[_vm._v(" "+_vm._s(_vm.transaction.item.name)+" "),(_vm.transaction.status === 'processing')?_c('small',[_vm._v(" Downloading "+_vm._s(_vm.bytesToSize(_vm.transaction.loadedSize))+" / "+_vm._s(_vm.bytesToSize(_vm.transaction.totalSize))+" - "+_vm._s(_vm.bytesToSpeed(_vm.transaction.speed))+" ")]):_vm._e()]),(_vm.transaction.status === 'processing')?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",style:({ width: `${_vm.progress}%` }),attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100","aria-valuetext":_vm.$t('Downloading file...')}})]):(_vm.transaction.status === 'cancelled')?_c('span',{staticClass:"description",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('You have cancelled this operation'))+" ")]):(_vm.transaction.message)?_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.transaction.message)+" ")]):(_vm.transaction.status === 'preparing')?_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t('Preparing to download'))+" ")]):_vm._e()]),_c('div',{staticClass:"transaction-actions"},[(
        _vm.transaction.status === 'preparing' ||
        _vm.transaction.status === 'processing'
      )?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ delay: 1000, content: 'Cancel operation' }),expression:"{ delay: 1000, content: 'Cancel operation' }"}],staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Cancel operation')},on:{"click":_vm.cancel}},[_c('Icon',{attrs:{"family":"fas","name":"ban"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"ban"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }