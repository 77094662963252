import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
import moment from 'moment-hijri';

export default {
  props: {
    propDateFormat: {
      type: String,
      default: undefined,
    },
    propTimeFormat: {
      type: String,
      default: undefined,
    },
    propCalendar: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    getCalendar() {
      if (this.$store.state.core == undefined) {
        return '';
      }
      return this.systemStatus.calendartype;
    },
    systemStatus() {
      if (this.$store.state.core == undefined) {
        return '';
      }
      return this.$store.state.core.fullSystemStatus;
    },
    calendarType() {
      return this.propCalendar || this.getCalendar;
    },
    dateFormat() {
      if (this.propDateFormat) return this.propDateFormat;

      if (typeof this.systemStatus.dateformat === 'undefined') {
        // Default date format
        return 'DD-MM-YYYY';
      }
      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    hijriDateFormat() {
      const date = this.dateFormat;
      return date
        .toUpperCase()
        .replace('DD', 'iDD')
        .replace('MM', 'iMM')
        .replace('YY', 'iYY');
    },
    timeFormat() {
      if (this.onlyDate) return '';

      return (
        this.propTimeFormat ||
        this.systemStatus.timeformat ||
        this.systemStatus.defaulttimeformat ||
        'HH:mm'
      );
    },
    hijriTimeFormat() {
      if (this.timeFormat == 'h:mm') return 'h:mm a';
      return this.timeFormat;
    },
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat;
    },
  },
  methods: {
    dateToDisplay(when) {
      return this.calendarType === 'hijri'
        ? this.hijri(when)
        : dayjs.unix(when).format(this.dateTimeFormat);
    },
    hijri(date) {
      const newDate = new moment(date)
        .locale('en')
        .format(this.hijriDateFormat + ' ' + this.hijriTimeFormat);
      return this.translateHijriAmPm(newDate);
    },
    translateHijriAmPm(time) {
      return this.hijriTimeFormat == 'h:mm a'
        ? time.replace('am', 'ص').replace('pm', 'م')
        : time;
    },
  },
};
