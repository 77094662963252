<template>
  <div>
    <Loader v-if="loading" :loading="loading" full />
    <table class="table table--properties">
      <caption>
        {{
          caption
        }}
      </caption>
      <thead>
        <th scope="col">{{ $t('Entry') }}</th>
        <th scope="col">{{ $t('File Size') }}</th>
        <th scope="col">{{ $t('File Count') }}</th>
        <th class="centered" scope="col">{{ $t('Folder Count') }}</th>
        <th class="centered" scope="col">{{ $t('Actions') }}</th>
      </thead>
      <tbody>
        <tr>
          <th scope="row" class="title">{{ $t('Live') }}</th>
          <td>
            {{
              $tc('{count} File | {count} Files', currentData.livefilessizeonly)
            }}
            <br />
            {{
              $tc(
                '{count} Thumb | {count} Thumbs',
                currentData.livethumbssizeonly
              )
            }}
            <br />
            {{ $tc('{count} Total', currentData.livesize) }}
          </td>
          <td>
            {{ $tc('{count} File | {count} Files', currentData.livefilesonly) }}
            <br />
            {{
              $tc('{count} Thumb | {count} Thumbs', currentData.livethumbsonly)
            }}
            <br />
            {{ $tc('{count} Total', currentData.livefiles) }}
          </td>
          <td class="centered">{{ currentData.livefolders }}</td>
          <td></td>
        </tr>
        <tr>
          <th scope="row" class="title">{{ $t('Previous Versions') }}</th>
          <td>{{ currentData.versionedsize }}</td>
          <td>{{ currentData.versionedfiles }}</td>
          <td class="centered">-</td>
          <td class="centered">
            <v-popover v-if="currentData.versionedfiles > 0" class="action-btn">
              <button>
                <Icon name="times" family="fas" />
              </button>
              <Alert
                slot="popover"
                button-type="danger"
                :buttons="[
                  {
                    label: `Clear`,
                    callback: function () {
                      clearVersionedFiles();
                    },
                  },
                  { label: `Cancel`, outline: true, callback: function () {} },
                ]"
              >
                <span role="alert">
                  {{
                    $t(`Are you sure you want to clear the previous versions?`)
                  }}
                </span>
              </Alert>
            </v-popover>
          </td>
        </tr>
        <tr>
          <th scope="row" class="title">
            {{ $t('Partial Incomplete Uploads') }}
          </th>
          <td>{{ currentData.incompletesize }}</td>
          <td>{{ currentData.incompletefiles }}</td>
          <td class="centered">-</td>
          <td></td>
        </tr>
        <tr v-if="isRoot">
          <th scope="row" class="title">{{ $t('Deleted Files') }}</th>
          <td>{{ currentData.recyclesize }}</td>
          <td>{{ currentData.recyclefiles }}</td>
          <td class="centered">{{ currentData.recyclefolders }}</td>
          <td class="centered">
            <button class="action-btn" href="#" @click="goToRecyclebin">
              <Icon name="eye" family="fas" />
            </button>
            <v-popover
              v-if="currentData.recyclefiles > 0"
              class="action-btn"
              :class="{ disabled: systemStatus.clearrecyclebin === 0 }"
            >
              <button>
                <Icon name="times" family="fas" />
              </button>
              <Alert
                slot="popover"
                button-type="danger"
                :buttons="[
                  {
                    label: `Clear`,
                    callback: function () {
                      clearRecyclebin();
                    },
                  },
                  { label: `Cancel`, outline: true, callback: function () {} },
                ]"
              >
                <span role="alert">
                  {{ $t(`Are you sure you want to clear the deleted files?`) }}
                </span>
              </Alert>
            </v-popover>
          </td>
        </tr>
        <tr>
          <th scope="row" class="title">{{ $t('Total') }}</th>
          <td>{{ currentData.totalsize }}</td>
          <td>{{ currentData.totalfiles }}</td>
          <td class="centered">{{ currentData.totalfolders }}</td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import Alert from 'common/components/Alert';
import Loader from 'common/components/Loader';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Alert,
    Loader,
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    path: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
    caption: {
      type: String,
      default: '',
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      isOpen: false,
      currentData: {},
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    globalSummary() {
      return this.$store.state.core.usageSummary;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
  },
  mounted() {
    this.mount();
  },
  methods: {
    async mount() {
      this.loading = true;
      this.currentData = {};
      if (this.isRoot) {
        await this.$store.dispatch('core/getProfileSummary');
        this.currentData = this.globalSummary;
        this.loading = false;
      } else {
        this.currentData = await this.$store.dispatch(
          'files/getFolderProperties',
          { path: this.path }
        );
        this.currentData = this.currentData.data.usage;
        this.loading = false;
      }
    },
    async clearVersionedFiles() {
      this.loading = true;
      const response = await this.$store.dispatch('files/deleteAllVersions');
      if (response.ok) {
        const message = response.data.message.length
          ? response.data.message
          : 'Deleted files cleared successfully';
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Success') +
            '</b><p role="alert">' +
            this.$t(message) +
            '</p>',
          type: 'success',
        });
      }
      await this.$store.dispatch('core/getProfileSummary');
      this.mount();
    },
    async clearRecyclebin() {
      this.loading = true;
      const response = await this.$store.dispatch('files/emptyRecycleBin');
      if (response.ok) {
        const message = response.data.message.length
          ? response.data.message
          : 'All versions deleted successfully';
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Success') +
            '</b><p role="alert">' +
            this.$t(message) +
            '</p>',
          type: 'success',
        });
      }
      await this.$store.dispatch('core/getProfileSummary');
      this.mount();
    },
    goToRecyclebin() {
      this.$emit('closeModal');
      this.$nextTick(() => {
        this.$root.$emit('browseToRecycleBin');
      });
    },
  },
};
</script>
