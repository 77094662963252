<template>
  <div class="user-group">
    <div class="tab-wrapper">
      <div class="tab-header">
        <div
          v-a11ybutton="() => (activeTab = 'users')"
          class="tab-header-title"
          tabindex="0"
          :class="{ active: activeTab === 'users' }"
          @click="activeTab = 'users'"
        >
          {{ $t('Users') }}
          <span v-if="users.length > 0"> ({{ users.length }})</span>
        </div>
        <div
          v-if="!shareProperties.disallowprivatesharestogroups"
          v-a11ybutton="() => groupTab()"
          class="tab-header-title"
          tabindex="0"
          :class="{ active: activeTab === 'group' }"
          @click="groupTab()"
        >
          {{ $t('Groups') }}
          <span v-if="groups.length > 0">({{ groups.length }})</span>
        </div>
      </div>
      <div v-if="activeTab === 'users'" class="tab-content">
        <div
          v-if="!readonly || shareSettingNotAllowed"
          class="d-flex justify-content-end align-items-center"
        >
          <button
            class="btn btn-primary invite-user btn-sm mr-2"
            @click="showInviteUserDialog = true"
          >
            <Icon name="user-plus" />
            {{ $t('Invite Users') }}
          </button>

          <DropDownList
            v-tooltip="$t('More')"
            trigger-btn="ellipsis-v"
            trigger-icon-family="far"
            trigger-btn-class="more-options"
            :title="$t('More')"
            :items="addUserOptions"
            @triggerDDAction="onClickAddUserMore"
          />
        </div>
        <sharePermissionTable
          :readonly="readonly && !shareSettingNotAllowed"
          :items="users"
          :label="'User'"
          :share-id="shareid"
          @updatePerms="updatePerms"
          @alterPermissions="alterPermissions"
          @updatePermsError="usersInShare"
        />
      </div>
      <div v-if="activeTab === 'group'" class="tab-content">
        <div v-if="!readonly  || shareSettingNotAllowed" class="d-flex justify-content-end">
          <button
            class="btn btn-primary invite-user btn-sm"
            @click="showInviteGroupDialog = true"
          >
            <Icon name="users" />
            {{ $t('Add Group') }}
          </button>
        </div>
        <sharePermissionTable
          :readonly="readonly && !shareSettingNotAllowed"
          :items="groups"
          :label="'Group'"
          :share-id="shareid"
          @updatePerms="updatePerms"
          @alterPermissions="alterPermissions"
          @updatePermsError="groupsInShare"
        />
      </div>
    </div>
    <!-- Modals here -->
    <InviteUser
      v-if="showInviteUserDialog"
      :context="addGroupUserContext"
      :show-modal="showInviteUserDialog"
      @hideInviteUser="hideInviteUser"
    />
    <AddGroup
      v-if="showInviteGroupDialog"
      :context="addGroupUserContext"
      :show-modal="showInviteGroupDialog"
      @hideInviteGroup="hideInviteGroup"
    />
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import InviteUser from '../Modals/InviteUser';
import AddGroup from '../Modals/AddGroup';
import sharePermissionTable from '../Shares/sharePermissionTable';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import DropDownList from 'common/components/DropDownList';

export default {
  components: {
    Icon,
    InviteUser,
    AddGroup,
    sharePermissionTable,
    DropDownList,
  },
  mixins: [responsivenessMixin],
  props: {
    shareid: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    shareSettingNotAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'users',
      showInviteUserDialog: false,
      showInviteGroupDialog: false,
      users: [],
      groups: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading['share/getUsersInShare'];
    },
    exportedUsersToAdd() {
      const exportedUserList = this.$store.state.share?.exportedUsers || [];
      const usersInShare = this.$store.state.share?.usersInShare || [];
      const exportedUsersToAdd = exportedUserList.filter(
        (user) => !usersInShare.some((userInshare) => userInshare.name === user)
      );
      return exportedUsersToAdd;
    },
    addUserOptions() {
      const addUserOptions = [
        {
          name: this.$t('Copy Users'),
          icon: 'id-card',
          action: 'copyUsers',
        },
      ];

      if (this.showPasteUserButton) {
        addUserOptions.push({
          name: this.$t('Paste Users'),
          icon: 'paste',
          action: 'pasteUsers',
        });
      }

      return addUserOptions;
    },
    showPasteUserButton() {
      const exportedUserList = this.$store.state.share?.exportedUsers || [];
      return exportedUserList.length;
    },
    exportedUsersLocation() {
      return this.$store.state.share?.exportedUsersLocation;
    },
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    addGroupUserContext() {
      return {
        context: 'share',
        shareid: this.shareid,
      };
    },
  },
  mounted() {
    this.usersInShare();
    this.groupsInShare();
  },
  methods: {
    groupTab() {
      this.groupsInShare();
      this.activeTab = 'group';
    },
    async usersInShare() {
      this.users = await this.$store.dispatch('share/getUsersInShare', {
        shareid: this.shareid,
      });
    },
    async groupsInShare() {
      this.groups = await this.$store.dispatch('share/getGroupsInShare', {
        shareid: this.shareid,
      });
    },
    hideInviteUser() {
      this.usersInShare();
      this.showInviteUserDialog = false;
    },
    hideInviteGroup() {
      this.groupsInShare();
      this.showInviteGroupDialog = false;
    },
    updatePerms(payload) {
      let { userid, groupid } = payload;
      let key = Object.keys(payload)[2];
      let value = payload[key];
      if (this.users.length) {
        this.users = this.users.map((user) => {
          if (user.name == userid) {
            user[key] = value;
          }
          return user;
        });
      }
      if (this.groups.length) {
        this.groups = this.groups.map((user) => {
          if (user.groupid == groupid) {
            user[key] = value;
          }
          return user;
        });
      }
    },
    alterPermissions(payload) {
      let { userid, groupid } = payload;
      if (this.users.length) {
        this.users = this.users.map((user) => {
          if (user.name == userid) {
            user = { ...user, ...payload };
          }
          return user;
        });
      }
      if (this.groups.length) {
        this.groups = this.groups.map((user) => {
          if (user.groupid == groupid) {
            user = { ...user, ...payload };
          }
          return user;
        });
      }
    },
    async onClickAddUserMore(action) {
      switch (action) {
        case 'copyUsers':
          await this.$store.dispatch('share/exportShareUsers');
          this.$toast.open({
            message: `<b>${this.$t('Copy Users')}</b><p role="alert">${this.$t(
              'Users successfully copied.'
            )}
          </p>`,
            type: 'success',
          });
          break;

        case 'pasteUsers':
          if (this.exportedUsersToAdd.length) {
            // if has users copied yet not in this share
            const response = await this.$store.dispatch(
              'share/importShareUsers',
              this.shareid
            );

            if (response.data.result === 0) {
              this.$toast.open({
                message: `<b>${this.$t('Error')}</b><p role="alert"> ${this.$t(
                  response.data.message
                )}</p>`,
                type: 'error',
              });
            } else {
              this.$toast.open({
                message: `<b>${this.$t(
                  'Paste Users'
                )}</b><p role="alert">${this.$t('Users successfully pasted.')}
          </p>`,
                type: 'success',
              });
            }
            // update user list
            this.usersInShare();
          } else {
            // If there is no user to add (users already in this share)
            this.$toast.open({
              message: `<b>${this.$t(
                'Paste Users'
              )}</b><p role="alert">${this.$t(
                'All copied users are already in this share.'
              )} </p>`,
              type: 'warning',
            });
          }

          break;

        default:
          break;
      }
    },
  },
};
</script>
<style>
.user-group .overflow-y {
  overflow-y: auto;
}

.tab-content {
  overflow: hidden;
  width: 100%;
}
</style>
