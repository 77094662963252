<template>
  <DialogModal
    :visible="isOpen"
    :title="$t('Lock selected file?')"
    :size="'small'"
    :class="'lock-modal'"
    :buttons="[
      {
        label: `${$t('OK')}`,
        callback: doLockFile,
      },
      {
        label: `${$t('Cancel')}`,
        outline: true,
        callback: function () {
          isOpen = false;
        },
      },
    ]"
    @close="closeModal"
  >
    <Loader :loading="loading || !loaded" full />
    <div v-if="isOpen && loaded">
      <h4>{{ $t('You are about to lock the selected file') }}</h4>
      <p class="strong lock-description">
        {{
          $t(
            'Locking indicates that you are actively working on this file and prevents others from modifying it.'
          )
        }}
      </p>
      <p class="d-flex align-items-center">
        <Checkbox v-model="form.readlock" class="mr-2" />
        {{ $t('Prevent others from downloading this file while locked') }}
      </p>
      <hr />
      <p class="expire-label">{{ $t('Expires') }}</p>
      <div class="item-value">
        <Radiobox
          v-model="form.willExpire"
          name="expires"
          radio-value="never-expires"
          :label="$t('Never')"
        />
        <Radiobox
          v-model="form.willExpire"
          name="expires"
          radio-value="expires"
          :label="$t('On')"
        />
        <div v-if="form.willExpire !== 'never-expires'" class="date-picker">
          <DatePicker v-model="form.datePickerModel" server-format-type />
        </div>
      </div>
    </div>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import Loader from 'common/components/Loader';
import Checkbox from 'common/components/Checkbox';
import Radiobox from 'common/components/Radiobox';
import dayjs from 'dayjs';
import DatePicker from 'common/components/NewDatePicker.vue';

export default {
  components: {
    DialogModal,
    Loader,
    Checkbox,
    Radiobox,
    DatePicker,
  },
  data() {
    return {
      isOpen: false,
      loaded: false,
      sample: '',
      form: {},
      toastLockSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Locked the file successfully.') +
          '</p>',
        type: 'success',
      },
      toastLockFail: {
        message:
          '<b>' +
          this.$t('Warning') +
          '</b><p role="alert">' +
          this.$t('Unable to lock the file.') +
          '</p>',
        type: 'warning',
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading['files/lockFile'];
    },
    lockedFiles() {
      return this.$store.state.files.lockedFiles;
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      const { defaultdateformat, dateformat } = this.systemStatus;

      const dateF = dateformat || defaultdateformat || 'MM/DD/YYYY';

      return dateF.toUpperCase();
    },
    timeFormat() {
      const { defaulttimeformat, timeformat } = this.systemStatus;

      return timeformat || defaulttimeformat || 'HH:mm';
    },
    now() {
      const d = new Date();
      return dayjs(d).format(this.dateTimeFormat);
    },
    dateTimeFormat() {
      return this.dateFormat + ' ' + this.timeFormat;
    },
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();

      return date < today;
    },
    async doLockFile() {
      let dateFormat =
        this.form.willExpire === 'never-expires'
          ? null
          : this.form.datePickerModel;

      const payload = Object.assign(
        {},
        { path: this.form.path },
        dateFormat === null ? dateFormat : { expiration: dateFormat },
        !this.form.readlock ? null : { readlock: 1 }
      );

      let response = await this.$store.dispatch('files/lockFile', payload);
      if (response.ok) {
        if (typeof response.error == 'string') {
          let toastLockError = {
            message:
              '<b>' +
              this.$t('Warning') +
              '</b><p role="alert">' +
              this.$t(response.error) +
              '</p>',
            type: 'warning',
          };
          this.$toast.open(toastLockError);
        } else {
          this.$toast.open(this.toastLockSuccess);
        }
        this.isOpen = false;
        this.$emit('refresh');
      } else {
        this.$toast.open(this.toastLockFail);
        this.isOpen = false;
      }
    },
    open(item) {
      this.form = {
        path: item.fullfilename,
        readlock: false,
        willExpire: 'never-expires',
        datePickerModel: '',
      };
      this.isOpen = true;
      this.loaded = true;
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>
