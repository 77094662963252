<template>
  <div
    class="container p-0 m-0 row bottom-border notification-item"
    tabindex="0"
    :aria-labelledby="'user' + notification.toastrid"
    :aria-describedby="'message' + notification.toastrid"
  >
    <div
      v-if="notification.toastrid !== 0"
      :class="classReadOrUnread"
      class="col-12 p-0 m-0 pb-3 pt-2 pl-1"
    >
      <div class="col-11 m-0 p-0 pl-2 float-left">
        <div class="col-12 pt-1 m-0 p-0">
          <div class="col-1 m-0 p-0 float-left">
            <Avatar
              class="comment-profile-image p-0 m-0 mt-1"
              :user="notification.creater"
              :label="
                $t(`{userName}'s Avatar`, {
                  userName: notification.createrdisplayname,
                })
              "
            />
          </div>
          <div class="col-5 m-0 pl-2 float-left">
            <span
              :id="'user' + notification.toastrid"
              class="notification-username float-left"
              >{{ notification.createrdisplayname }}</span
            >
          </div>
          <div class="notification-date col-5 p-0 m-0 float-right">
            <small class="pr-0 float-right">{{
              new Date(notification.whents * 1000) | fromNow
            }}</small>
          </div>
        </div>
        <div class="notification-content col-12 p-0 m-0 pl-4 row">
          <div class="col-10">
            <span
              :id="'message' + notification.toastrid"
              v-tooltip="notification.message"
              class="notification-what two-lines-truncated"
              >{{ notification.message }}</span
            >
            <span
              v-if="typeof notification.path == 'string'"
              class="notification-path pt-1"
            >
              <Icon name="folder" family="fas" class="dir-icon pr-1" />
              <div class="text-break">
                <a @click="browse">{{ notification.dirpath }}</a>
              </div>
            </span>
          </div>
          <div v-if="typeof notification.actionid === 'string'" class="col-12">
            <button
              class="btn btn-primary btn-action float-right fix-20px"
              :aria-label="$t('Open')"
              @click="goToAction"
            >
              {{ $t('Open') }}
            </button>
          </div>
          <div class="col-1 float-right pr-0 mr-0">
            <FileIcon
              v-if="typeof notification.path === 'string'"
              :name="notification.path"
              class="fas file-notif-icon mt-3 pr-2"
            />
          </div>
        </div>
      </div>
      <div class="col-1 float-right pr-3 pl-2 h-100">
        <span v-if="unread && typeof notification.actionid !== 'string'">
          <a class="dot vertical-center pt-4" @click="ackToast">
            <Icon name="circle" class="fas" />
          </a>
        </span>
      </div>
    </div>
    <div
      v-else-if="notification.toastrid === 0"
      class="col-12 p-0 m-0 no-notifications pt-2 mt-2 pl-4"
    >
      <div class="col-11 ml-4 p-0 pt-2 pl-4 m-0 bell-container row float-left">
        <Icon
          name="bell-slash"
          class="fas fa-2x vertical-center d-block p-4 m-4"
        />
      </div>
      <br />
      <div class="col-11 m-0 p-0 d-block row mt-4 pt-4 pl-0 ml-0 row">
        {{ $t('No notifications') }}
      </div>
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import serverURL from 'common/filecloud/properties/serverURL';
import Avatar from 'common/components/Avatar';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

export default {
  components: {
    Icon,
    FileIcon,
    Avatar,
  },
  filters: {
    fromNow(value) {
      return dayjs(value).fromNow();
    },
  },
  props: {
    notification: {
      type: Object,
      default: function () {
        return { alreadyread: 1, message: 'No notifications', toastrid: 0 };
      },
    },
  },
  computed: {
    getProfileImage() {
      return (
        serverURL.domainURL +
        '/core/getprofileimage?username=' +
        this.notification.creater
      );
    },
    unread() {
      return this.notification.alreadyread === 0;
    },
    classReadOrUnread() {
      if (this.unread) {
        return 'notification-unread';
      }
      return 'notification-read';
    },
  },
  methods: {
    browse() {
      this.$router.push('/expl-tabl.' + this.notification.dirpath);
    },
    async ackToast() {
      await this.$store.dispatch('core/ackToasts', {
        acktoastids: this.notification.toastrid,
      });
      await this.$store.dispatch('core/getToasts', {});
    },
    goToAction() {
      this.$emit('closeNotifications');
      this.$router.push('/actions/' + this.notification.actionid);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.file-notif-icon {
  transform: scale(1.7);
  top: 1rem;
  right: 2rem;
}
.bottom-border {
  border-bottom: solid 1px var(--border-color);
  background-color: var(--bg-light);
}
.dir-icon {
  /* Style for "Path" */
  height: 20px;
  font-size: 13px;
  padding-top: 3px;
  color: var(--text-light);
}
.notification-unread {
  background-color: var(--bg-mid-light);
}
.notification-read {
  background-color: var(--bg-light);
}
.dot {
  color: var(--text-light);
  display: block;
  font-size: 8px;
}
.notification-date {
  /* Style for "Yesterday" */
  height: 24px;
  color: var(--text-light);
  font-size: 11px;
  font-weight: 500;
}
.notification-what {
  font-size: 13px;
  color: var(--text-dark-blue);
  text-align: left;
  font-weight: 500;
}
.notification-filetype {
  font-size: 14px;
}
.notification-username {
  /* Style for "John Updat" */
  color: var(--text-dark-blue);
  font-size: 13px;
  font-weight: 600;
  padding-left: 0px;
  text-align: left;
}
.notification-content {
  /* Text style for "Updated th" */
  font-weight: 500;
  width: 100%;
}
.notification-path {
  /* Style for "/SHARED/ma" */
  color: var(--text-light);
  font-size: 11px;
  font-weight: 500;
  display: flex;
  text-align: left;
}
.no-notifications {
  color: var(--text-dark-blue);
  text-align: center;
  height: 120px;
}
.bell-container {
  padding-left: 21% !important;
  color: #b8b8b8;
}
.two-lines-truncated {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.btn-action {
  height: 28px;
  padding: 2px 14px;
  font-size: 11px;
  margin-top: 20px;
  margin-right: 8px;
}

.fix-20px {
  margin-right: -20px;
}

.btn-link {
  font-size: 11px;
  padding-right: 0;
}
</style>
