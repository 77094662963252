/**
 * Get 2fa method from user token
 * @param token - String
 * @returns String containing method
 */
export const getTwoFaMethodFromToken = (token) => {
  if (token.startsWith('2fa_sms')) { return 'sms'; }
  if (token.startsWith('2fa_duo')) { return 'duo'; }
  if (token.startsWith('2fa_ga')) { return 'totp'; }
  
  return 'email';
}

/**
 * Check 2fa setup mode
 * @param token - String
 * @returns String containing method
 */
 export const isTwoFaSetupMode = (token) => {
   // We know its setup mode if this string is visible in the token
  return token.includes('_1_');
}