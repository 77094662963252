var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{class:'lock-modal',attrs:{"visible":_vm.isOpen,"title":_vm.$t('Lock selected file?'),"size":'small',"buttons":[
    {
      label: `${_vm.$t('OK')}`,
      callback: _vm.doLockFile,
    },
    {
      label: `${_vm.$t('Cancel')}`,
      outline: true,
      callback: function () {
        _vm.isOpen = false;
      },
    },
  ]},on:{"close":_vm.closeModal}},[_c('Loader',{attrs:{"loading":_vm.loading || !_vm.loaded,"full":""}}),(_vm.isOpen && _vm.loaded)?_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('You are about to lock the selected file')))]),_c('p',{staticClass:"strong lock-description"},[_vm._v(" "+_vm._s(_vm.$t( 'Locking indicates that you are actively working on this file and prevents others from modifying it.' ))+" ")]),_c('p',{staticClass:"d-flex align-items-center"},[_c('Checkbox',{staticClass:"mr-2",model:{value:(_vm.form.readlock),callback:function ($$v) {_vm.$set(_vm.form, "readlock", $$v)},expression:"form.readlock"}}),_vm._v(" "+_vm._s(_vm.$t('Prevent others from downloading this file while locked'))+" ")],1),_c('hr'),_c('p',{staticClass:"expire-label"},[_vm._v(_vm._s(_vm.$t('Expires')))]),_c('div',{staticClass:"item-value"},[_c('Radiobox',{attrs:{"name":"expires","radio-value":"never-expires","label":_vm.$t('Never')},model:{value:(_vm.form.willExpire),callback:function ($$v) {_vm.$set(_vm.form, "willExpire", $$v)},expression:"form.willExpire"}}),_c('Radiobox',{attrs:{"name":"expires","radio-value":"expires","label":_vm.$t('On')},model:{value:(_vm.form.willExpire),callback:function ($$v) {_vm.$set(_vm.form, "willExpire", $$v)},expression:"form.willExpire"}}),(_vm.form.willExpire !== 'never-expires')?_c('div',{staticClass:"date-picker"},[_c('DatePicker',{attrs:{"server-format-type":""},model:{value:(_vm.form.datePickerModel),callback:function ($$v) {_vm.$set(_vm.form, "datePickerModel", $$v)},expression:"form.datePickerModel"}})],1):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }