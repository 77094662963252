<template>
  <div
    :style="{ width: '265px', minWidth: '220px' }"
    class="sidebar timed-slither"
  >
    <nav ref="sidebarNav" class="sidebar-sticky">
      <div
        class="scrollable-area"
        :class="{ 'community-scroller': isCommunityEdition }"
      >
        <ul ref="sidebarUL" class="nav flex-column page_wrap">
          <TreeMenu
            :check-scroll="checkScroll"
            :nodes="allFilesTree.nodes"
            :depth="0"
            :expanded="true"
            :path="allFilesTree.path"
            :icon="'folder'"
            :label="allFilesTree.label"
            :simple-link="'expl-tabl.'"
            :selected="allFilesTree.selected"
          />

          <TreeMenu
            v-if="!isLimitedUser"
            :check-scroll="checkScroll"
            class="pt-2 recent-documents"
            :nodes="recentFilesTree.nodes"
            :depth="0"
            :path="recentFilesTree.path"
            :icon="'clock'"
            :label="recentFilesTree.label"
            :only-toggle="true"
            :kind="'recent'"
          />

          <TreeMenu
            v-if="!isLimitedUser"
            :check-scroll="checkScroll"
            class="pt-2"
            :nodes="starredFilesTree.nodes"
            :depth="0"
            :path="starredFilesTree.path"
            :icon="'star'"
            :label="starredFilesTree.label"
            :only-toggle="true"
            :kind="'starred'"
          />

          <div
            id="bottom_fade"
            class="sidebar-sticky-bottom"
            :style="fadeStyle"
          />
        </ul>
      </div>

      <router-link
        v-if="!isLimitedUser"
        :style="{ top: 0 }"
        :to="'/shrd-mgmt.'"
        class="tree-menu root-tree text-decoration-none pt-2"
        tabindex="-1"
      >
        <ul class="label-wrapper p-0 pt-2 label-wrapper--up">
          <li class="nav-item nav-item-expandable">
            <div class="nav-link active">
              <Icon name="share-alt" family="fas" />
              <span tabindex="0" class="text-truncate">{{
                $t('Shared by Me')
              }}</span>
            </div>
          </li>
        </ul>
      </router-link>

      <router-link
        v-if="!isLimitedUser && automationWorkflows"
        :style="{ top: 0 }"
        :to="'/workflows'"
        class="tree-menu root-tree text-decoration-none pt-2"
        tabindex="-1"
      >
        <ul class="label-wrapper p-0 pt-2 label-wrapper--up">
          <li class="nav-item nav-item-expandable">
            <div class="nav-link active">
              <WorkflowIcon class="inline-icon" />
              <span tabindex="0" class="text-truncate">{{
                $t('Workflows')
              }}</span>
            </div>
          </li>
        </ul>
      </router-link>
      <div
        v-a11ybutton="toggleTransactions"
        class="tree-menu root-tree"
        :style="{ top: 0 }"
        @click="toggleTransactions"
      >
        <ul class="label-wrapper p-0 m-0 label-wrapper--up">
          <li class="nav-item nav-item-expandable">
            <div class="nav-link active pt-0">
              <Icon
                v-if="runningTransactions.total.length === 0"
                name="file-alt"
                family="fas"
              />
              <div v-else class="spinner-border">
                <span class="sr-only">Loading...</span>
              </div>
              <span class="text-truncate" tabindex="0"
                >{{ $t('File Operations') }}
                <small v-if="runningTransactions.total.length > 0">
                  {{
                    $tc(
                      'Processing {count} item | Processing {count} items',
                      runningTransactions.total.length
                    )
                  }}
                </small>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </nav>
    <div
      class="sidebar-sticky-bottom"
      :class="{ 'community-footer': isCommunityEdition }"
    >
      <div class="container">
        <ul class="nav flex-column justify-content-end flex-grow-1">
          <li v-if="customization.DISABLENEWSFEED !== 1">
            <NoticeView label="Notice" :icon="'sticky-note'" />
          </li>
          <div v-if="showRecyclebin" class="root-tree">
            <div class="label-wrapper">
              <li
                class="nav-link tree-menu sidebar-item px-0"
                @click="goToRecyclebin"
              >
                <div class="label-wrapper">
                  <Icon name="trash-alt" class="menu-icon" />
                  <span
                    v-a11ybutton="goToRecyclebin"
                    class="item-menu-text text-truncate"
                    tabindex="0"
                    >{{ $t('Deleted Files') }}</span
                  >
                </div>
              </li>
            </div>
          </div>
          <div
            v-if="systemStatus.MINIADMINENABLED === 1"
            class="root-tree mini-admin-link"
          >
            <div class="label-wrapper">
              <li
                class="nav-link tree-menu sidebar-item px-0"
                @click="openAdminModal"
              >
                <div class="label-wrapper">
                  <Icon family="fas" name="user-crown" />
                  <span
                    v-a11ybutton="openAdminModal"
                    class="item-menu-text text-truncate"
                    tabindex="0"
                    >{{ $t('Admin') }}</span
                  >
                </div>
              </li>
            </div>
          </div>
        </ul>
        <div class="sidebar-sticky-bottom__footer pr-4">
          <template v-if="!customization.HIDE_POWERED_BY">
            <a
              v-if="!isCommunityEdition"
              class="text-muted"
              href="https://www.filecloud.com/"
              target="_blank"
              >{{ $t('Powered by FileCloud') }}</a
            >
            <a v-else href="https://ce.filecloud.com" target="_blank">
              <isCommunityEditionLogo class="ce-footer" />
            </a>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import isCommunityEditionLogo from 'common/assets/community-edition.svg';
import WorkflowIcon from 'common/assets/icons/workflow.svg';
import TreeMenu from '../TreeView';
import NoticeView from '../NoticeView';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    isCommunityEditionLogo,
    TreeMenu,
    NoticeView,
    WorkflowIcon,
  },
  data() {
    return {
      fadeStyle: {},
      allFilesColorsMap: {
        'My Files': '#a0e1e3',
        'Team Folders': '#00a1ff',
        'Network Shares': '#a77bff',
        'Shared with Me': '#abdf32',
      },
      allFilesTree: {
        draggableOptions: { group: 'share' },
        label: 'All Files',
        selected: false,
        path: '/',
        nodes: [],
      },
      recentFilesTree: {
        draggableOptions: { group: 'share' },
        label: 'Recent Documents',
        selected: false,
        nodes: [],
      },
      starredFilesTree: {
        draggableOptions: { group: 'share' },
        label: 'Starred',
        selected: false,
        nodes: [],
      },
      sharedFilesTree: {
        draggableOptions: { group: 'share' },
        label: 'Shared by Me',
        selected: false,
        nodes: [],
      },
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser', 'isCommunityEdition']),
    runningTransactions() {
      return this.$store.getters['files/getRunningTransactions'];
    },
    customization() {
      return this.$store.state.core.customization;
    },
    navigationSidebar() {
      return this.$store.state.core.navigationSidebar;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    automationWorkflows() {
      return this.systemStatus.AUTOMATION_WORKFLOWS;
    },
    operationsBar() {
      return this.$store.state.files.transactionsBar;
    },
    isWorkflowPage() {
      return this.$route.name === 'workflows-sub';
    },
    showRecyclebin() {
      return (
        (this.$store.state.files.filesMeta?.showrecyclebin === 1 ||
          this.$store.state.files.filesMeta?.showrecyclebin === undefined) &&
        this.fullSystemStatus['disablemyfiles'] === 0
      );
    },
  },
  mounted() {
    this.getAllFiles('');
  },
  methods: {
    checkScroll(event) {
      //this.heightFop();
    },
    async getAllFiles(name) {
      const response = await this.$store.dispatch('files/allFiles', name);
      if (response.ok) {
        let result = response.data.entry;
        if (result instanceof Array) {
          result.forEach((el) => {
            this.treeFolder(el);
          });
        } else {
          this.treeFolder(result);
        }
      }
    },
    treeFolder(result) {
      if (!result) return;
      let file = {};
      file.label = result.name + '';
      file.path = result.path;
      file.iconColor = this.allFilesColorsMap[result.name];
      file.type = result.type;
      file.nodes = [];
      this.allFilesTree.nodes.push(file);
    },
    toggleTransactions() {
      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: !this.operationsBar.visible,
      });
      this.$store.commit('files/setTransactionsBar', {
        key: 'visible',
        value: !this.operationsBar.visible,
      });
    },
    goToRecyclebin() {
      this.$root.$emit('browseToRecycleBin');
    },
    openAdminModal() {
      this.$store.dispatch('miniAdmin/showMiniAdminModal', { visible: true });
    },
  },
};
</script>

<style lang="scss" scoped>
.page_wrap {
  z-index: 1;
  margin: 0;
}
#bottom_fade {
  //bottom: 1rem;
  width: 99%;
  height: 1rem;
  background-image: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    var(--bg-medium) 100%
  );
}
.collapse-icon {
  color: var(--text-light) !important;
  &:hover {
    color: var(--text-dark) !important;
  }
}
.collapse-sidebar {
  left: 99%;
  border-radius: 0 0 6px;
  border: 1px solid #e7e7e7;
  background-color: #ffffff;
}
.minimized-bottom-items {
  max-width: 4%;
}
.item-menu-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.menu-icon {
  font-size: 1rem;
  min-width: 1.5rem;
  color: #333333;
}
.community-scroller {
  max-height: calc(100vh - 28rem) !important;
}
.ce-footer {
  height: 4.8rem !important;
}
</style>
