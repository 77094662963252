<template>
  <Themed text-color>
    <div class="p-0 m-0" style="width: 100%">
      <div class="col-md-12 p-0 m-0 pb-2">
        <CommentInput
          v-if="!editMode"
          ref="commentInput"
          @disableEditMode="disableEditMode"
          @addedComment="addedComment"
          @sentToast="sentToast"
        />
      </div>

      <div class="col-md-12 m-0 mt-2 p-0 row">
        <div class="col-md-4 m-0 p-0">
          <span class="comment-header">{{ $t('Comments') }}</span>
        </div>
        <div class="col-md-8 m-0 p-0">
          <hr class="align-middle p-0 m-0 mt-3" />
        </div>
      </div>

      <div v-if="loading" class="col-sm-12 comments-empty text-center">
        <Loader :loading="loading" />
      </div>
      <div
        v-else-if="!hasComments && !loading"
        class="col-sm-12 comments-empty"
      >
        <div class="message-icon col-md-12 p-0 m-0">
          <img
            class="img-fluid mx-auto d-block"
            :src="messageIconPath"
            :alt="$t('No comments yet!')"
          />
        </div>
        <div class="col-md-12 text-center p-0 m-0">
          <span class="comments-empty-text">{{ $t('No comments yet!') }}</span>
        </div>
      </div>

      <div v-else class="col-md-12 p-0 m-0">
        <Comment
          v-for="comment in comments"
          :key="comment.id"
          track-by="$index"
          :comment-data="comment"
          :edit-mode="editMode"
          @enableEditMode="enableEditMode"
          @disableEditMode="disableEditMode"
          @sentToast="sentToast"
        />
      </div>
    </div>
  </Themed>
</template>

<script>
import Themed from '../Themed';
import Comment from './Comment';
import CommentInput from './CommentInput';
import { getRecords } from 'common/utils/arrayUtils';
import Loader from 'common/components/Loader';

export default {
  name: 'Comments',
  components: {
    Themed,
    Loader,
    Comment,
    CommentInput,
  },
  data() {
    return {
      images: require.context('../../assets/', false, /\.png$/),
      editMode: false,
      toast: {},
    };
  },
  computed: {
    hasComments() {
      return this.comments !== undefined && this.comments[0] !== undefined;
    },
    messageIconPath() {
      return this.images('./nomessage.png');
    },
    comments() {
      return this.loadComments();
    },
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    loading() {
      return this.$store.state.loading['files/getComments'];
    },
  },
  mounted() {
    let comments =
      this.$store.state.files.loadedCommentsForSelectedItem.comment;
    if (!comments || comments.length == 0) {
      this.$store.dispatch('files/getCommentsForComponent');
    }
  },
  watch: {
    currentFile() {
      this.$store.dispatch('files/getCommentsForComponent');
    },
  },
  methods: {
    loadComments() {
      const commentData = this.$store.state.files.loadedCommentsForSelectedItem;
      let returnedComments = getRecords(commentData.comment);

      if (returnedComments[0] === undefined) {
        this.$root.$emit(
          'announceScreenReader',
          this.$t("There's no comment in this file")
        );
      } else {
        this.$root.$emit(
          'announceScreenReader',
          this.$tc(
            '{count} comment loaded successfully | {count} comments loaded successfully',
            returnedComments.length
          )
        );
      }

      return returnedComments !== undefined
        ? returnedComments.slice().reverse()
        : null;
    },
    sentToast(payload) {
      this.toast = payload;
      this.$toast.open(this.toast);
    },
    disableEditMode: function () {
      this.editMode = false;
    },
    enableEditMode: function () {
      //TODO: add check if user is owner of comment
      this.editMode = true;
    },
    async addedComment() {
      this.$refs.commentInput.value = '';
      this.$store.dispatch('files/getCommentsForComponent');
    },
  },
};
</script>
<style scoped>
.comments-empty {
  margin-top: 50px;
}
.comments-empty-text {
  font-size: 13px;
  color: var(--text-dark);
  margin-top: 10px;
}
</style>
<style>
.comments-empty .loader.active {
  justify-content: center;
}
</style>
