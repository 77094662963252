<template>
  <div
    ref="item"
    role="row"
    :transfer-data="item"
    :effect-allowed="['link']"
    class="file-list-item file-list-item--file"
    :class="{
      [`file-list-item--selected`]: isSelected,
      [`file-list-item--expanded`]: expanded,
      ['file-list-item--details']: details,
      ['selection-made']: hasSelection,
      ['file-list-item--deleting']: isDeleting,
      [`no-hover`]: isPicker,
    }"
  >
    <div v-if="!isPicker" class="drag-indication">
      <Icon name="grip-vertical" family="fas" />
    </div>

    <div
      v-if="!isPicker && !isMobile && !isTablet"
      class="col-checkbox"
      @click="select"
    >
      <Checkbox :checked="isSelected" />
    </div>

    <div class="col-title shared-by-me" role="gridcell" @click="expand">
      <div
        v-if="(isMobile || isTablet) && isSelected"
        class="col-checkbox"
        @contextmenu.prevent="contextMenu"
        @click="select"
      >
        <Checkbox :checked="isSelected" :label="$t('Select')" />
      </div>
      <Icon
        v-if="item.type === 'folder' && !isSelected"
        class="file-icon file-icon--folder"
        family="fas"
        name="folder"
      />
      <FileIcon
        v-else-if="!isSelected"
        :name="item.name.toString()"
        :item="item"
        :subicon="itemIcon(item)"
        :format="item.format"
        :thumb="thumb"
      />
      <div class="title-n-details">
        <Themed v-if="!isRenaming" as="h4" text-colorr @click.stop="titleClick">
          {{ item.sharedobjname }}
          <small class="d-xl-none">
            <DateDisplay :time="false" :date="item.lastaccessdts" />
            {{
              item.recentactivitycount > 0
                ? $tc(
                    '{count} recent activity | {count} recents activities',
                    item.recentactivitycount
                  )
                : $t('no recent activity')
            }}
          </small>
        </Themed>
        <div class="file-list-item-details d-block">
          <span
            v-if="typeof item.validityperiod === 'string'"
            class="file-list-item-details-size"
            :class="{
              ['text-danger']: new Date(item.validityperiod) < new Date(),
            }"
            >{{ $t('Expiry: ') }}
            <DateDisplay
              :time="false"
              :date="item.validityperiodts"
              style="display: inline"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!isPicker && !isMobile && !isTablet"
      class="file-list-actions"
      @click.stop
    >
      <div class="btn-group actuals" role="group">
        <button
          v-tooltip="$t('Manage Share')"
          class="btn btn-sm btn-icon"
          @click="shareItem"
        >
          <span class="invisible">{{ $t('Manage Share') }}</span>
          <Icon name="share-alt" family="fal" />
          <Icon class="icon-overlay" name="share-alt" family="fas" />
        </button>
        <button
          v-tooltip="$t('Copy link to clipboard')"
          class="btn btn-sm btn-icon"
          list-only
          @click="copyToClipBoard"
        >
          <span class="invisible">{{ $t('Copy link to clipboard') }}</span>
          <Icon name="clipboard-check" family="fal" />
          <Icon class="icon-overlay" name="clipboard-check" family="fas" />
        </button>
        <button
          v-tooltip="$t('Share Details')"
          class="btn btn-sm btn-icon"
          grid-only
          @click="toggleDetails"
        >
          <span class="invisible">{{ $t('Share Details') }}</span>
          <Icon name="info-circle" family="fal" />
          <Icon class="icon-overlay" name="info-circle" family="fas" />
        </button>
        <button
          v-tooltip="$t('Preview')"
          class="btn btn-sm btn-icon"
          @click="titleClick"
        >
          <span class="invisible">{{ $t('Preview') }}</span>
          <Icon name="eye" family="fal" />
          <Icon class="icon-overlay" name="eye" family="fas" />
        </button>
        <button
          v-tooltip="$t('Open link in new window')"
          class="btn btn-sm btn-icon"
          list-only
          @click="openLink"
        >
          <span class="invisible">{{ $t('Open link in new window') }}</span>
          <Icon name="external-link-square" family="fal" />
          <Icon class="icon-overlay" name="external-link-square" family="fas" />
        </button>
        <button
          v-if="!item.hidesendshareviaemail"
          v-tooltip="$t('Send email')"
          class="btn btn-sm btn-icon"
          list-only
          @click="emailItem"
        >
          <span class="invisible">{{ $t('Send email') }}</span>
          <Icon name="envelope" family="fal" />
          <Icon class="icon-overlay" name="envelope" family="fas" />
        </button>
        <v-popover>
          <button
            v-tooltip="$t('Remove Share')"
            class="btn btn-sm btn-icon"
            list-only
          >
            <span class="invisible">{{ $t('Remove Share') }}</span>
            <Icon name="times" family="fal" />
            <Icon class="icon-overlay" name="times" family="fas" />
          </button>
          <Alert
            slot="popover"
            button-type="danger"
            :buttons="[
              {
                label: `Remove`,
                callback: function () {
                  removeShare();
                },
              },
              { label: `Cancel`, outline: true, callback: function () {} },
            ]"
          >
            <span role="alert">
              {{ $t('Are you sure you want to remove the share of') }}
              {{ item.name }}?
            </span>
          </Alert>
        </v-popover>
      </div>
    </div>

    <div
      v-if="!isMobile && !isTablet"
      class="m-text col-info justify-content-center share-owner"
    >
      <DateDisplay :date="item.lastaccessdts" />
    </div>
    <div
      v-if="!isMobile && !isTablet"
      class="m-text col-info justify-content-center last-access"
    >
      <DateDisplay :date="item.createdts" />
    </div>
    <div
      v-if="!isMobile && !isTablet"
      class="m-text col-info justify-content-center last-30-activity"
    >
      {{ item.recentactivitycount }}
    </div>
    <div
      v-if="!isMobile && !isTablet"
      class="m-text col-info justify-content-center created-date"
    >
      {{ item.shareowner }}
    </div>
    <div
      v-if="isMobile || isTablet"
      class="col-properties col-properties--mobile col-info"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      <div class="file-details" @click="openSidebarForFile">
        <Icon name="info-circle" family="fas" />
      </div>
    </div>
    <div
      v-if="isMobile || isTablet"
      class="col-more-options--mobile"
      role="group"
      @click="openMobileMenu"
    >
      <Icon role="presentation" name="ellipsis-v" class="more-options" />
    </div>
  </div>
</template>
<script>
import fileListItemMixin from '../../mixins/fileListItemMixin';
import fileListItemSharedMixin from '../../mixins/fileListItemSharedMixin';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import DateDisplay from 'common/components/DateDisplay';
import Checkbox from 'common/components/Checkbox.vue';
import { mapGetters } from 'vuex';
import FileItemMixin from './FileListItem/FileItemMixin';

export default {
  components: {
    Checkbox,
    DateDisplay,
  },
  mixins: [fileListItemMixin, FileItemMixin, fileListItemSharedMixin, responsivenessMixin],
  data() {
    return {
      toastClipBoard: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Path copied successfully') +
          '.</p>',
        type: 'success',
      },
      toastRemoveShareSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Share removed successfully.') +
          '</p>',
        type: 'success',
      },
      toastRemoveShareError: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t('Unable to remove share') +
          '</p>',
        type: 'error',
      },
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    uploading() {
      return this.$store.state.loading['file.upload'];
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    dateFormat() {
      return this.systemStatus.dateformat
        ? this.systemStatus.dateformat.toUpperCase()
        : this.systemStatus.defaultdateformat.toUpperCase();
    },
    timeFormat() {
      return this.systemStatus.timeformat
        ? this.systemStatus.timeformat
        : this.systemStatus.defaulttimeformat;
    },
  },
  methods: {
    select(e) {
      if (e) {
        const { ctrlKey, shiftKey, metaKey } = e;
        this.$emit('selected', { item: this.item, ctrlKey, shiftKey, metaKey });
      } else {
        this.$emit('selected', { item: this.item });
      }
    },
    expand(e) {
      let isTargetTitle =
        e.target.className === 'col-title' ||
        e.target.className === 'col-title-droppable';

      const { ctrlKey, shiftKey, metaKey } = e;

      if (!this.isMobile && !this.isTablet) {
        if (!this.clickTimeout) {
          this.$emit('expanded', this.item);
          this.$emit(
            'selected',
            { item: this.item, ctrlKey, shiftKey, metaKey },
            isTargetTitle
          );

          // doubleclick tolerance
          this.clickTimeout = setTimeout(() => {
            this.clickTimeout = null;
          }, 250);
        } else {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
          this.previewFile();
        }
      } else {
        this.$emit('expanded', this.item);
        this.$emit(
          'selected',
          { item: this.item, ctrlKey, shiftKey, metaKey },
          isTargetTitle
        );
      }
    },
    preview(e) {
      if (!this.picker) {
        this.$emit('preview', this.item);
      } else {
        this.select(e);
      }
    },
    browse() {
      this.$emit('browsed', this.item);
    },
    titleClick() {
      if (this.item.type === 'folder') {
        this.browse();
      } else {
        this.preview();
      }
    },
    toggleDetails() {
      this.$store.state.files.sidebar.selected = [this.item];
      this.$store.state.files.sidebar.open = true;
      this.$store.state.files.currentFile = this.item;
    },
    deleteFile() {
      this.$emit('deleteFile', this.item);
    },
    downloadFile() {
      this.$emit('downloadFile', this.item);
    },
    lockFile() {
      this.$emit('lockFile', this.item);
    },
    moveFile() {
      this.$emit('moveFile', this.item);
    },

    // rename
    onClickRename() {
      this.isRenaming = true;
      this.newName = this.item.name;

      this.$nextTick(() => {
        this.$refs.filenameInput.focus();

        // find file . index
        const extIndex = this.item.name.lastIndexOf('.');

        if (extIndex > 0) {
          this.$refs.filenameInput.setSelectionRange(0, extIndex);
        } else {
          this.$refs.filenameInput.select();
        }
      });
    },

    async onCancelRename() {
      this.isRenaming = false;
    },

    async onRename() {
      if (!this.isRenaming) return;

      this.isRenaming = false;

      if (this.item.name !== this.newName) {
        await this.$store.dispatch('files/renameFile', {
          path: this.item.dirpath,
          id: this.item.id,
          name: this.item.name,
          newname: this.newName,
          item: this.item,
        });
      }
    },
    async removeShare() {
      if (this.isSelected) {
        this.select();
      }
      const response = await this.$store.dispatch(
        'share/removeShare',
        this.item.shareid
      );
      response.ok
        ? this.$toast.open(this.toastRemoveShareSuccess)
        : this.$toast.open(this.toastRemoveShareError);
      this.$store.dispatch('files/shares');
    },

    triggerToast(type, message) {
      this.$toast.open({
        type,
        message,
      });
    },
    canShowProps() {
      let item = this.item;
      return (
        typeof item.isshared === 'string' ||
        item.tagged ||
        item.hasnotificationsrule ||
        item.commented ||
        item.locked
      );
    },
    shareItem() {
      let file = this.item;
      file.fullfilename = file.sharelocation;
      this.$store.dispatch('share/openShare', file);
    },
    emailItem() {
      let file = this.item;
      this.$store.state.share.shareProperties = file;
      this.$store.state.share.emailShareLinkDialogOpen = true;
    },

    copyToClipBoard() {
      let item = this.item;
      let url = item.shareurl;
      let toasts = this.$toast;
      let toastClipBoard = this.toastClipBoard;
      this.$copyText(url).then(function () {
        toasts.open(toastClipBoard);
      });
    },
    openLink() {
      window.open(this.item.shareurl, '_blank');
    },
    openMobileMenu(e) {
      let item = this.item;
      let isInRecycleFolder = false;
      let limitedUser = this.isLimitedUser;
      let isPublicShare = false;
      let systemStatus = this.$store.state.core.systemstatus;
      let customization = this.$store.state.core.customization;

      let actions = [
        {
          name: 'Manage Share',
          icon: 'share-alt',
          action: 'shareItem',
        },
        {
          name: 'Copy link to clipboard',
          icon: 'clipboard-check',
          action: 'copyToClipBoard',
        },
        {
          name: 'Share Details',
          icon: 'info-circle',
          action: 'toggleDetails',
        },
        {
          name: 'Preview',
          icon: 'eye',
          action: 'Preview',
        },
        {
          name: 'Send email',
          icon: 'envelope',
          action: 'emailItem',
        },
        {
          name: 'Remove Share',
          icon: 'times',
          action: 'removeShare',
        },
      ];

      this.select(e);
      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: true,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'item',
        value: item,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'component',
        value: this,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'actions',
        value: actions,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'target',
        value: {
          item,
          isInRecycleFolder,
          isLimitedUser: limitedUser,
          isPublicShareView: isPublicShare,
          systemStatus: systemStatus,
          customization,
        },
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'isRoot',
        value: false,
      });
      this.$store.commit('files/showMobileOverlay');
    },
  },
};
</script>
<style scoped>
.m-text {
  clear: both;
  white-space: pre-line;
}
.popover {
  opacity: 1;
}
</style>
