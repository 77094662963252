import _ from 'lodash';

export const userRolesMap = {
  0: {
    name: 'Guest Access',
    show: true,
    requirements: {
      status: 0,
      adminstatus: 0
    }
  },
  1: {
    name: 'Full Access',
    show: true,
    requirements: {
      status: 1,
      adminstatus: 0
    }
  },
  2: {
    name: 'Disabled Access',
    show: true,
    requirements: {
      status: 2,
      adminstatus: 0
    }
  },
  3: {
    name: 'Limited Access',
    show: true,
    requirements: {
      status: 3,
      adminstatus: 0
    }
  },
  100: {
    name: 'Admin',
    show: false,
    requirements: {
      status: 1,
      adminstatus: 1
    }
  },
};

export const userRoles = [
  userRolesMap[0],
  userRolesMap[1],
  userRolesMap[2],
  userRolesMap[3],
  userRolesMap[100],
];

export const getUserRole = (user) => {
  
  // admin and not disabled
  if(user.adminstatus === 1 && user.status !== 2) {
      return userRolesMap[100];
  }
  
  return userRolesMap[user.status];
  
};
