import serverURL from 'common/filecloud/properties/serverURL';

export default {
  data() {
    return {
      images: require.context('../assets/', false),
    };
  },
  computed: {
    logo() {
      if (this.customization) {
        let logoColor = false;
        document.body.classList.forEach((o) => {
          if (o.includes('fc-dark')) logoColor = true;
        });
        let logoType = logoColor ? 'DARKLOGO' : 'LOGINLOGO';
        let logoFallback = logoColor ? 'maindark.png' : 'logo.png';
        return this.customization[logoType] &&
          typeof this.customization[logoType] == 'string'
          ? serverURL.domainURL + this.customization[logoType].replace('#', '&')
          : this.images(`./${logoFallback}`);
      }
      return '';
    },
  },
};
