<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="position-relative mt-2">
    <div v-if="depth != 1" class="vqb-group-heading">
      <div class="form-inline">
        <LogicalOperator
          v-if="query.logicalOperator && index != 0"
          v-model="query.logicalOperator"
          :labels="labels"
          @change="$emit('change')"
        />

        <button
          v-if="depth > 1"
          type="button"
          class="delete-group ml-auto"
          @click="remove"
        >
          <Icon name="trash-alt" family="fas" />
        </button>
      </div>
    </div>
    <div class="vqb-group card" :class="'depth-' + depth.toString()">
      <div class="vqb-group-body card-body p-2">
        <query-builder-children v-bind="$props" @change="$emit('change')" />
        <AddRuleGroup
          v-model="selectedRule"
          :rules="ruleTypes.operators"
          :depth="depth < maxDepth"
          :labels="labels"
          @addRule="addRule"
          @addGroup="addGroup"
        />
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderGroup from '../components/QueryBuilderGroup';
import QueryBuilderRule from './Rule.vue';
import AddRuleGroup from './partials/AddRuleGroup';
import Icon from 'common/components/Icon';
import LogicalOperator from './partials/LogicalOperator.vue';

export default {
  name: 'QueryBuilderGroup',

  components: {
    // eslint-disable-next-line vue/no-unused-components
    QueryBuilderRule: QueryBuilderRule,
    Icon,
    AddRuleGroup,
    LogicalOperator,
  },

  extends: QueryBuilderGroup,
  data() {
    return {
      addButtons: false,
    };
  },
  methods: {
    showButtons() {
      this.addButtons = !this.addButtons;
    },
  },
};
</script>

<style lang="scss">
.vue-query-builder .vqb-rule {
  margin-top: 15px;
  margin-bottom: 10px;
  background-color: var(--bg-mid-medium);
  padding: 15px;
}
.vqb-group-body {
  width: 100%;
}
.vqb-group {
  &.depth-2,
  &.depth-3,
  &.depth-4 {
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.17);
    border-radius: 0 4px 4px 0;
  }
  margin-bottom: 15px;
}

.vue-query-builder .vqb-group .round-btn {
  &:focus {
    border-color: var(--text-light) !important;
  }
}

.vue-query-builder .vqb-group.depth-2 {
  border-left: 3px solid #8aa69a;

  .round-btn {
    border-right: 5px solid #8aa69a;
    background: #8aa69a;
    &:focus {
      border-color: #8aa69a !important;
    }
  }
}

.vue-query-builder .vqb-group.depth-3 {
  border-left: 3px solid #a6968a;
  .round-btn {
    border-right: 5px solid #a6968a;

    background: #a6968a;
    &:focus {
      border-color: #a6968a !important;
    }
  }
}

.vue-query-builder .vqb-group.depth-4 {
  border-left: 3px solid #ff5722;
  .round-btn {
    border-right: 5px solid #ff5722;
    background: #ff5722;
    &:focus {
      border-color: #ff5722 !important;
    }
  }
}

.vue-query-builder .delete-group {
  color: var(--text-mid-dark);
  background: transparent;
  position: absolute;
  bottom: 16px;
  right: 16px;
  border: 0;
  z-index: 2;
  border-radius: 3px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

@media (min-width: 768px) {
  .vue-query-builder .vqb-rule.form-inline .form-group {
    display: block;
  }
}
.buttons-container {
  position: absolute;
  z-index: 1;
  top: 50px;
  background: var(--bg-light);
  border: 1px solid var(--border-color);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.0954982);
  border-radius: 6px;
}
.form-inline .form-group {
  flex-direction: column;
}
.vqb-group-heading *,
.vqb-group * {
  font-size: 13px;
}
</style>
