/**
 * server url for build
 * @type type
 */
let domain = (process.env.NODE_ENV === 'production') ? "" : "/api";
const serverURL = {
    domainURL : domain,
    sso: domain + "/auth/index.php",
    adminsso: domain + "/adminauth/index.php",
    webshareProURLBase: domain + "/app/websharepro/share?path=",
};

export default serverURL
