<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >
      <div
        v-show="open"
        class="zip-viewer-overlay"
        :style="{ zIndex: zIndex }"
        @click="$emit('close')"
      ></div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__slideInUp"
      leave-active-class="animate__animated animate__slideOutDown"
    >
      <div
        v-show="open"
        class="mobile-menu mobile-menu--active"
        :style="{ zIndex: zIndex + 1 }"
      >
        <div v-if="this.$slots.header" class="header">
          <slot name="header" />
        </div>
        <div class="body">
          <slot name="body" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ZipViewerMobileMenu',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 9999,
    },
  },
};
</script>
