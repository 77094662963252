<template>
  <div class="avatar-wrapper">
    <img
      :key="avatarVersion"
      :src="getUserAvatarURL"
      :alt="
        user
          ? $t(`{userName}'s Avatar'`, { userName: user.displayname })
          : $t('Your avatar')
      "
      class="avatar"
      :class="size"
      :aria-label="label"
    />
    <button
      v-if="editable && isMobileDevice"
      class="change-avatar"
      @click="uploadPicture"
    >
      <Icon name="pencil" />
    </button>
    <div
      v-if="editable && !isMobileDevice"
      v-a11ybutton="() => (avatarDropdownOpen = true)"
      class="editable-avatar"
      :class="{ focused: isFocused || avatarDropdownOpen }"
      tabindex="0"
      role="button"
      :aria-label="$t('You can change your avatar pressing enter key.')"
      @focus="isFocused = true"
      @blur="isFocused = false"
    >
      <DropDownList
        ref="avatarDropdown"
        class="btn"
        :trigger-btn="'ellipsis-h'"
        :trigger-icon-family="'fal'"
        :trigger-btn-class="'change-avatar'"
        :items="avatarActions"
        :offset="10"
        :placement="'right'"
        :dropdown-class="'avatar-edit'"
        :open="avatarDropdownOpen"
        @triggerDDAction="triggerAction"
        @focus="isFocused = true"
        @blur="isFocused = false"
        @hide="
          () => {
            avatarDropdownOpen = false;
            isFocused = false;
          }
        "
      />
    </div>
  </div>
</template>

<script>
import serverURL from '../filecloud/properties/serverURL';
import DropDownList from './DropDownList';
import Icon from 'common/components/Icon';
import { isMobileDevice } from '../utils/responsive.js';
import { mapState } from 'vuex';

export default {
  name: 'Avatar',
  components: {
    DropDownList,
    Icon,
  },
  props: {
    user: {
      default: '',
      type: [String, Number],
      required: false,
    },
    size: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    hasProfileImage: {
      type: Boolean,
      default: false,
    },
    unregisteredUser: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    sameUser: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarVersion: new Date().getTime(),
      avatarDropdownOpen: false,
      isFocused: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user.profile,
      profileImage: (state) => state.auth.profileImageVersion,
    }),
    getUserAvatarURL: function () {
      const serverUrl = `${serverURL.domainURL}/core/getprofileimage`;

      // if no username is provided, consider the logged-in user.
      const user = this.user.toString().toLowerCase() || this.currentUser || '';

      // if the user provided is the same as the logged-in user.
      // when no user is provided refer to the logged-in user.
      const version =
        this.currentUser === user || user === '' ? this.profileImage : '';

      if (this.unregisteredUser) {
        return serverUrl + '?username=';
      }

      if (user) {
        return (
          serverUrl + `?username=${user}` + (version ? `&v=${version}` : '')
        );
      } else {
        return serverUrl + (version ? `?v=${version}` : '');
      }
    },
    avatarActions() {
      const act = [
        {
          name: 'Upload New Photo',
          icon: 'camera-retro',
          action: 'uploadPicture',
        },
        {
          name: 'Remove Photo',
          icon: 'trash-alt',
          action: 'removePicture',
        },
      ];

      if (!this.hasProfileImage) {
        act.pop();
      }

      return act;
    },
    isMobileDevice,
  },
  mounted() {
    this.$root.$on('avatarUpdated', () => {
      this.avatarVersion = new Date().getTime();
    });
  },
  methods: {
    uploadPicture() {
      this.$emit('updatePic');
    },
    removePicture() {
      this.$emit('remove');
    },
    triggerAction(name) {
      this[name]();
    },
  },
};
</script>
