const ctrlCmd = window.navigator.platform === 'MacIntel' ? 'cmd' : 'ctrl';
export default [
  {
    name: 'Download',
    icon: 'download',
    action: 'download',
    shortKey: 'D',
    hidden: ({ selected, isContext }) => selected.length === 0 || !isContext,
    disabled: ({ selected, isContext, downloadable }) =>
      selected.length === 0 || !isContext || !downloadable,
  },
  {
    name: 'Download Folder',
    icon: 'download',
    action: 'downloadFolder',
    hidden: ({ meta, selected }) =>
      (meta && meta.candownload != 1) || selected.length,
    disabled: ({ meta, selected }) =>
      (meta && meta.candownload != 1) || selected.length,
  },
  {
    name: 'Delete',
    icon: 'trash-alt',
    action: 'delete',
    shortKey: 'del',
    hidden: ({ selected, isPublicShareView }) => selected.length === 0 || isPublicShareView,
    disabled: ({ downloadable, selected }) =>
      selected.length === 0 || !downloadable,
  },
  {
    name: 'Copy',
    icon: 'copy',
    action: 'copy',
    shortKey: `${ctrlCmd} + c`,
    hidden: ({ selected, isPublicShareView }) => selected.length === 0 || isPublicShareView,
    disabled: ({ downloadable, selected}) =>
      selected.length === 0 || !downloadable,
  },
  {
    name: 'Move',
    icon: 'long-arrow-right',
    action: 'move',
    shortKey: `${ctrlCmd} + x`,
    hidden: ({ selected, isPublicShareView }) => selected.length === 0 || isPublicShareView,
    disabled: ({ downloadable, selected}) =>
      selected.length === 0 || !downloadable,
  },
  /* // TODO: check with piotr the behaviour (bulk operation is not working)
  {
    name: 'Quick Edit',
    icon: 'edit',
    action: 'runQuickEdit',
    shortKey: ``,
    hidden: ({ selected, isQuickEditEnabled }) => selected.length === 0 || !isQuickEditEnabled
  },
  */
  {
    name: 'Export Secure Docs',
    icon: 'shield-check',
    action: 'requestForDRMFiles',
    hidden: ({ selected, isDRMExportHidden }) =>
      selected.length === 0 || isDRMExportHidden,
    disabled: ({ isDRMExportEnabled}) =>
      !isDRMExportEnabled,
    seperator: true,
  },
  {
    name: 'Share',
    icon: 'share-alt',
    action: 'shareFolder',
    hidden: ({ isContext, isMyFilesRoot }) => isContext || isMyFilesRoot,
    disabled: ({ isContext, selected, isLimitedUser }) =>
      isContext || selected.length > 1 || isLimitedUser,
  },
  {
    name: 'Deleted Files',
    icon: 'trash-alt',
    action: 'goToDeletedFiles',
    hidden: ({
      isContext,
      isNetworkShare,
      enableRecyclebinForNTShare,
      isSharedWithMe,
      deletable,
    }) =>
      isContext ||
      (isNetworkShare && enableRecyclebinForNTShare === 0) ||
      isSharedWithMe ||
      !deletable,
    disabled: ({
      isContext,
      isNetworkShare,
      enableRecyclebinForNTShare,
      isSharedWithMe,
      deletable,
    }) =>
      isContext ||
      (isNetworkShare && enableRecyclebinForNTShare === 0) ||
      isSharedWithMe ||
      !deletable,
  },
  {
    name: 'Share Options',
    icon: 'list-alt',
    action: 'shareOptions',
    hidden: ({ isShareOptionsShow }) => !isShareOptionsShow,
    disabled: ({ isContext }) => isContext,
  },
  {
    name: 'Properties',
    icon: 'book',
    action: 'properties',
    hidden: ({ isContext }) => isContext,
    disabled: ({ isContext, isLimitedUser }) => isContext || isLimitedUser,
  },
];
