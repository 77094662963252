<template>
  <permissionTable
    :label="label"
    :items="items"
    :update-permission-fn="updatePermission"
    :disabled-object="disabledObject"
    :context="tableContext"
    :readonly="readonly"
  />
</template>

<script>
import Themed from '../Themed';
import Checkbox from 'common/components/Checkbox';
import Icon from 'common/components/Icon';
import permissionTable from '../Tables/permissionTable';
import { mapGetters } from 'vuex';

export default {
  name: 'SharePermissionTable',
  components: {
    Themed,
    Checkbox,
    Icon,
    permissionTable,
  },
  props: {
    label: String,
    items: {
      type: Array,
      default: () => [],
    },
    shareId: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    isSingleFileShare() {
      return this.$store.state.share.shareFile.type === 'file';
    },
    isNetworkShare() {
      return (
        this.$store.state.share.shareFile.type === 'dir' &&
        this.$store.state.share.shareFile.path.startsWith('/EXTERNAL')
      );
    },
    tableContext() {
      return {
        context: 'share',
        isSingleFileShare: this.isSingleFileShare,
      };
    },
    disabledObject() {
      if (this.isLimitedUser)
        return {
          view: {
            label: 'View permission cannot be removed',
            disabled: true,
          },
          download: {
            label: '',
            disabled: false,
          },
          upload: {
            label: '',
            disabled: false,
          },
          share: {
            label: 'Permission denied',
            disabled: true,
          },
          sync: {
            label: 'Permission denied',
            disabled: true,
          },
          delete: {
            label: '',
            disabled: false,
          },
          manage: {
            label: 'Permission denied',
            disabled: true,
          },
        };

      const syncDisabled = this.isSingleFileShare || this.isNetworkShare;
      let syncLabel = '';

      if (syncDisabled) {
        syncLabel = this.isSingleFileShare
          ? 'Sync disabled for single file share'
          : 'Sync disabled for Network Shares';
      }

      return {
        view: {
          label: 'View permission cannot be removed',
          disabled: true,
        },
        sync: {
          label: syncLabel,
          disabled: syncDisabled,
        },
        manage: {
          label: this.isSingleFileShare
            ? 'Manage disabled for single file share'
            : '',
          disabled: this.isSingleFileShare,
        },
        share: {
          label: 'Permission not allowed for external user',
          disabled: false,
        },
        delete: {
          label: this.isSingleFileShare
            ? 'Delete disabled for single file share'
            : '',
          disabled: this.isSingleFileShare,
        },
      };
    },
  },
  methods: {
    async updatePermission(value, permission, index) {
      let payload = {
        shareid: this.shareId,
        userid: value.name,
        groupid: value.groupid,
      };
      let url = payload.userid == undefined ? 'Group' : 'User';
      switch (permission) {
        case 'download':
          payload.download = !value.download;
          if (!payload.download) {
            payload = {
              ...payload,
              share: false,
              sync: false,
              allowmanage: false,
            };
          }
          break;
        case 'write':
          payload.write = !value.write;
          if (!payload.write) {
            payload = {
              ...payload,
              share: false,
              sync: false,
              disallowdelete: this.isSingleFileShare,
              allowmanage: false,
            };
          }
          break;
        case 'share':
          payload.share = !value.share;
          if (payload.share) {
            payload.write = true;
            payload.download = true;
          } else {
            payload.allowmanage = false;
          }
          break;
        case 'sync':
          payload.sync = !value.sync;
          if (payload.sync) {
            payload.write = true;
            payload.download = true;
          } else {
            payload.allowmanage = false;
          }
          break;
        case 'disallowdelete':
          payload.disallowdelete = !value.disallowdelete;
          if (payload.disallowdelete) {
            payload.write = true;
          }
          break;
        case 'allowmanage':
          payload.allowmanage = !value.allowmanage;
          break;
      }
      if (permission == 'delete') {
        this.$store.dispatch('share/delete' + url + 'fromShare', payload);
        this.items.splice(index, 1);
      } else {
        this.$emit('alterPermissions', payload);
        this.$emit('updatePerms', payload);
        let response = await this.$store.dispatch(
          'share/set' + url + 'AccessforShare',
          payload
        );
        if (!response.ok) {
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              this.$t(response.error) +
              '</p>',
            type: 'warning',
          });
          this.$emit('updatePermsError');
          return;
        }
      }
    },
  },
};
</script>
