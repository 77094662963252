<template>
  <div>
    <DialogModal
      ref="addGroup"
      class="add-group-modal"
      :stick-top="false"
      :visible="visible"
      size="big"
      :title="dialogTitle"
      :buttons="modalButtons"
      @close="closeModal"
    >
      <div class="add-group-modal">
        <InfoBox
          v-if="message !== ''"
          :type="messageType"
          class="info-box mb-0"
        >
          {{ message }}
        </InfoBox>

        <hr v-if="message !== ''" class="mt-2 mx-3" />

        <InputBox
          v-model="groupname"
          :placeholder="newGroupNamePlaceholder"
          class="input"
        ></InputBox>
      </div>
    </DialogModal>
  </div>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import InputBox from 'common/components/InputBox';
import InfoBox from 'common/components/InfoBox';

export default {
  components: {
    DialogModal,
    InputBox,
    InfoBox,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      dialogTitle: this.$t('Add a Group'),
      newGroupNamePlaceholder: this.$t('New Group Name'),
      modalButtons: [
        {
          label: this.$t('Add Group'),
          outline: false,
          callback: () => this.addGroup(),
        },
        {
          label: this.$t('Close'),
          outline: true,
          callback: () => this.closeModal(),
        },
      ],
      groupname: '',
      messages: {
        initial: this.$t('Please name your group'),
        ok: this.$t('Group added'),
        error: this.$t(
          'Group name can only contain letters, numbers, spaces, periods, dashes and underscores'
        ),
        errorPermission: this.$t(
          'Group may already exist or user is not permitted to create groups'
        ),
      },
      messageType: 'info',
      message: '',
    };
  },
  methods: {
    mounted() {
      this.message = this.messages.initial;
    },
    async closeModal() {
      this.groupname = '';
      await this.$store.dispatch('groups/hideGroupAddModal');
      this.message = '';
    },
    async addGroup() {
      if (this.groupname === '') {
        this.message = this.messages.initial;
        this.messageType = 'alert';
        return;
      }

      const response = await this.$store.dispatch('groups/addGroup', {
        groupName: this.groupname,
      });

      if (response.result === 1) {
        this.$emit('groupAdded', { groupname: this.groupname });

        await this.closeModal();
        return;
      }

      this.message =
        response.message === 'bad request'
          ? this.messages.error
          : this.messages.errorPermission;

      this.messageType = 'alert';
    },
  },
};
</script>
<style lang="scss" scoped>
.add-group-modal {
  z-index: 100001;
}
::v-deep.dialog-modal-outer {
  width: 30vw;
  margin-top: -13%;
}
.info-box {
  width: 100%;
  width: 90%;
  margin-left: 25px;
}
.input {
  width: 100%;
}
</style>
