export const actions = {
  1: 'added',
  2: 'created',
  3: 'deleted',
  4: 'deleted',
  5: 'updated',
  6: 'downloaded',
  7: 'publicly shared',
  8: 'privately shared ',
  9: 'removed share for',
  10: 'renamed',
  11: 'locked',
  12: 'unlocked',
  13: 'viewed',
  14: 'emailed',
  15: 'attached metadata set definition',
  16: 'removed metadata set definition',
  17: 'saved metadata values for set ',
  18: 'removed user{misc}from share',
  19: 'removed group{misc}from share',
  20: 'move',
  21: 'added user{misc}to share',
  22: 'added group{misc}to share',
};

export default actions;
