<template>
  <div>
    <DialogModal
      class="dialog-modal-table group-modal"
      :visible="visible"
      size="big"
      :title="groupManagementTitle"
      :buttons="modalButtons"
      @close="closeModal"
    >
      <Loader v-if="isLoading" :loading="isLoading" full />
      <div class="group-modal-content">
        <div
          v-if="activeTab !== groupModalTabs.LISTGROUP"
          class="content-header"
        >
          <div class="content-header-title">
            <div v-if="groupName" class="group-name">
              <span class="group-name-label">{{
                this.$t('Manage Group Members for Group')
              }}</span>
              <p
                id="roleNameInput"
                type="text"
                class="group-name-input pl-1 group-name-label-self"
                aria-label="Group Name"
              >
                {{ groupName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <component
          :is="loader"
          v-if="loader"
          :key="componentKey"
          :tab="activeTab"
          :groups="groups"
          @showGroupTab="setActiveTab(groupModalTabs.MEMBERS)"
          @moveToGroupsTab="setActiveTab(groupModalTabs.LISTGROUP)"
          @showAddGroupModal="openAddGroupModal"
          @groupAdded="onGroupAdded"
          @groupRemoved="onGroupRemoved"
        />
      </div>
    </DialogModal>
    <div>
      <GroupAddModal
        :visible="showGroupAddModal"
        @close="closeAddGroupModal"
        @groupAdded="onGroupAdded"
      />
    </div>
  </div>
</template>
<script>
import { groupModalTabs } from '../../../constants/groups';

import DialogModal from 'common/components/DialogModal';
import Loader from 'common/components/Loader';
import SwitchToggle from 'common/components/SwitchToggle';
import InputBox from 'common/components/InputBox';
import GroupAddModal from '../GroupModal/GroupAddModal';

import groupsStoreModule from '../../../store/modules/groups';

export default {
  name: 'GroupModal',
  components: {
    DialogModal,
    InputBox,
    Loader,
    SwitchToggle,
    GroupAddModal,
  },
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      componentKey: 0,
      activeTabTitle: 'Groups',
      hasChanges: false,
      modalReady: false,
      groupEnabled: true,
      groupModalTabs: groupModalTabs,
      groupNameErrors: {},
      updategroupList: false,
      modalButtons: [
        {
          label: this.$t('Close'),
          callback: this.closeModal,
        },
      ],
      groups: [],
      activeTab: groupModalTabs.LISTGROUP,
      groupManagementTitle: this.$t('Manage User Groups'),
      showAddBtn: false,
    };
  },
  computed: {
    showGroupAddModal() {
      return this.$store.state.groups.groupAddModal.show;
    },
    totalGroups() {
      return this.$store.state.groups.groups.length;
    },
    activeTabComponentTitle() {
      return this.activeTab;
    },
    isLoading() {
      return !this.modalReady || this.$store.state.loading['groups/getGroups'];
    },
    lockedFiles() {
      return this.$store.state.files.lockedFiles;
    },
    groupId() {
      return this.$store.state.groups.selectedGroup.groupid;
    },
    loader() {
      if (!this.activeTab) {
        return null;
      }
      return () => import(`./${this.activeTab}.vue`);
    },
    currentGroupList() {
      return this.$store.state.groups.groups;
    },
    groupName() {
      return this.$store.state.groups.selectedGroup.groupname;
    },
  },
  created() {
    if (!this.$store.groups) {
      this.$store.registerModule('groups', groupsStoreModule);
    }
  },
  mounted() {
    const status = this.$store.state.core.fullSystemStatus;
    this.modalReady = true;
    if (status.cancreateusergroup == 1) {
      this.modalButtons.unshift({
        label: this.$t('Add Group'),
        callback: this.openAddGroupModal,
        outline: true,
      });
    }
  },
  destroyed() {
    this.$store.unregisterModule('groups');
  },
  methods: {
    async closeAddGroupModal() {
      await this.getGroups();
    },
    async openAddGroupModal() {
      await this.$store.dispatch('groups/showGroupAddModal');
    },
    async hideAddGroupModal() {
      await this.$store.dispatch('groups/hideGroupAddModal');
      this.modalKey += 1;
    },
    setActiveTab(tab) {
      const status = this.$store.state.core.fullSystemStatus;
      this.modalButtons = [
        {
          label: this.$t('Close'),
          callback: this.closeModal,
        },
      ];

      if (
        status.cancreateusergroup == 1 &&
        this.activeTab !== groupModalTabs.LISTGROUP
      ) {
        this.modalButtons.unshift({
          label: this.$t('Add Group'),
          callback: this.openAddGroupModal,
          outline: true,
        });
      }

      this.activeTab = tab;
      this.componentKey += 1;
    },
    async closeModal() {
      this.$emit('close');
    },
    async getGroups(aFilter = '', listall = false) {
      const payload = {
        filter: aFilter,
        start: 0,
        limit: 5,
      };
      await this.$store.dispatch('groups/getGroups', payload);
      await this.updateGroupList();
    },
    async updateGroupList() {
      this.groups = this.currentGroupList;
    },
    async onGroupAdded(payload) {
      // Timeout as it seems that group can be slow to reload after adding
      setTimeout(() => {}, 500);
      await this.getGroups(payload.groupname, true);
      this.$toast.open({
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="info">' +
          this.$t('user_groups.group_created', { name: payload.groupname }) +
          '</p>',
        type: 'success',
      });

      const newGroup = this.currentGroupList.filter(
        (item) => item.groupname == payload.groupname
      );

      await this.$store.dispatch('groups/setSelectedGroup', newGroup[0]);
      this.setActiveTab(groupModalTabs.MEMBERS);
    },
    async onGroupRemoved(payload) {
      this.$toast.open({
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="info">' +
          this.$t('user_groups.group_removed', { name: payload.groupname }) +
          '</p>',
        type: 'success',
      });
      await this.getGroups();
    },
  },
};
</script>

<style lang="scss" scoped>
.group-modal {
  ::v-deep.dialog-modal {
    z-index: 10000;
    &-header {
      .text-truncate.mr-4 {
        margin-left: 0;
      }
    }

    &-outer {
      width: 50vw;
    }

    &-footer {
      .btn-link.btn-link-danger {
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}
.group-name-label {
  font-weight: 400 !important;
}

.group-name-label-self {
  font-weight: 600 !important;
}
.group-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content-header {
  background: var(--bg-medium);
  flex: 0;
  padding-top: 35px;

  &-title {
    display: flex;
    padding: 35px;
    padding-top: 0;

    .group-name {
      align-items: center;
      display: flex;
      flex: 1;
      font-size: 14px;
      padding-right: 15px;

      &-label {
        font-weight: 600;
      }

      &-text {
        margin-left: 8px;
      }

      &-input {
        margin-bottom: 0;
        font-weight: 500;

        ::v-deep.form-text.text-danger {
          position: absolute;
        }
      }
    }

    .active-toggle {
      align-items: center;
      display: flex;
      font-size: 14px;

      &-label {
        font-weight: 600;
        margin-right: 20px;
      }
    }
  }

  .tab-header {
    margin-bottom: 0;
    padding-left: 35px;

    &-title:not(.active) {
      background: var(--bg-light);
    }

    &-title.disabled {
      background-color: var(--bg-medium);
      color: var(--text-light);
    }
  }
}

.content-body {
  flex: 1;
  justify-content: space-between;
  overflow: auto;
  vertical-align: bottom;
  overflow-y: hidden;
}

.center {
  text-align: center;
  margin: 5px;
  padding-top: 27px;
}
.vue-recycle-scroller .scroller-with-filter {
  height: 400px;
}
</style>
