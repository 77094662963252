var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-table animate-timed-fade locked-files-wrapper",attrs:{"stick-top":true,"visible":true,"class-name":"none","title":_vm.$t(`Folder Permissions`),"buttons":[
    {
      label: 'OK',
      outline: true,
      callback: () => this.$emit('close'),
    },
  ]},on:{"close":function($event){return _vm.$emit('close')}}},[_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}),_c('div',[_c('div',{staticClass:"table-wrapper-border"},[_c('table',{staticClass:"table table--properties"},[_c('caption',[_vm._v(" "+_vm._s(_vm.$t('Locked Files and Folders'))+" ")]),(!_vm.loading && _vm.paginatedAclFiles.length != 0)?_c('thead',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Path'))+" ")]),_c('th',{staticClass:"centered normal",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Details'))+" ")]),_c('th',{staticClass:"centered thin",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Inherit'))+" ")]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Actions')))])]):_vm._e(),_c('tbody',[(!_vm.loading && _vm.paginatedAclFiles.length === 0)?_c('tr',{staticClass:"border-0"},[_c('td',{staticClass:"centered p-5",attrs:{"colspan":"4"}},[_c('EmptyListingInfo',{attrs:{"icon-name":"folder-open","text":"No Folder Permissions"}})],1)]):_vm._e(),_vm._l((_vm.paginatedAclFiles),function(file){return _c('FolderPermissions',{key:file.lockrid,attrs:{"item":file},on:{"close":_vm.refresh}})})],2)])]),(_vm.totalItems / _vm.itemsPerPage > 1)?_c('Paginator',{attrs:{"current-page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"total-items":_vm.totalItems},on:{"onPageChange":_vm.onPageChage}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }