<template>
  <div class="w-100">
    <button
      type="button"
      class="mt-3 float-right btn btn-sm btn-primary"
      @click="$emit('editing')"
    >
      Save
    </button>
    <button
      type="button"
      class="mt-3 float-right btn btn-link btn-sm px-4"
      @click="$emit('cancel')"
    >
      Cancel
    </button>
  </div>
</template>

<script>
export default {
  name: 'BottomButtons',
  props: ['value', 'labels'],
};
</script>
