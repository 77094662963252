var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"transactions-list-item",class:{
    [`transaction-list-item--preparing`]: _vm.transaction.status === 'preparing',
  }},[_c('div',{staticClass:"transaction-icon"},[_c('FileIcon',{attrs:{"name":_vm.transaction.name}}),(
        _vm.transaction.status !== 'processing' &&
        _vm.transaction.status !== 'preparing'
      )?_c('div',{staticClass:"transaction-icon-status",class:{
        [`transaction-icon-status--error`]: _vm.transaction.status === 'failed',
        [`transaction-icon-status--cancelled`]:
          _vm.transaction.status === 'cancelled',
      }},[_c('Icon',{attrs:{"family":"fas","name":_vm.transState}})],1):_vm._e()],1),_c('div',{staticClass:"transaction-info"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltipContent }),expression:"{ content: tooltipContent }"}],staticClass:"transaction-info-title",class:{ 'text-truncate': _vm.isMobile }},[_vm._v(" "+_vm._s(_vm.currentPath !== _vm.transaction.dirpath && _vm.longView ? `${_vm.transaction.dirpath}/` : '')+" "),(_vm.currentPath !== _vm.transaction.dirpath)?_c('br',{staticClass:"d-lg-none"}):_vm._e(),_vm._v(" "+_vm._s(_vm.transaction.uploadpath ? `${_vm.transaction.uploadpath.replace(/^\/|\/$/g, '')}/` : '')+_vm._s(_vm.transaction.name)+" ")]),(_vm.transaction.status === 'preparing')?_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t('Preparing to upload')))]):(_vm.transaction.status === 'processing')?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar",class:{
          'progress-bar--network-error': _vm.transaction.networkError,
        },style:({ width: `${_vm.progress}%` }),attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100","aria-valuetext":_vm.$t('Uploading...')}})]):(_vm.transaction.message && _vm.transaction.status !== 'cancelled')?_c('span',{staticClass:"description text-truncate",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.transaction.message)+" ")]):_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(" "+_vm._s(_vm.$t('File upload cancelled'))+" ")])]),_c('div',{staticClass:"pl-4"},[(
          _vm.transaction.status === 'processing' &&
          !_vm.isMobile &&
          !_vm.transaction.networkError
        )?_c('small',{staticClass:"floated text-truncate"},[_vm._v(_vm._s(_vm.bytesToSize(_vm.transaction.sentSize))+" / "+_vm._s(_vm.bytesToSize(_vm.transaction.size))+" ("+_vm._s(_vm.progress)+"%)")]):(_vm.transaction.networkError && !_vm.isMobile)?_c('small',{staticClass:"floated text-truncate"},[_vm._v(" "+_vm._s(_vm.$t('No Network Connection'))+" ")]):(!_vm.isMobile)?_c('small',{class:{
          floated: !_vm.isMobile,
          blocked: _vm.isMobile,
          ['not-processing']: !_vm.isMobile,
        }},[_vm._v(_vm._s(_vm.bytesToSize(_vm.transaction.size)))]):_vm._e()]),(_vm.transaction.status === 'completed')?_c('div',{staticClass:"transaction-actions",attrs:{"role":"alert"}},[_vm._t("default")],2):_c('div',{staticClass:"transaction-actions"},[(
        _vm.transaction.status === 'cancelled' || _vm.transaction.status === 'failed'
      )?_c('button',{staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Retry upload')},on:{"click":_vm.retry}},[_c('Icon',{attrs:{"family":"fas","name":"redo"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"redo"}})],1):_c('button',{staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Cancel upload')},on:{"click":_vm.cancel}},[_c('Icon',{attrs:{"family":"fas","name":"ban"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"ban"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }