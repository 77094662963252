import Vue from 'vue';
import { HTTP } from 'common/utils/constants';

export default {
  namespaced: true,
  state: {
    actions: {
      inbox: [],
      outbox: [],
    },
    actionsUnread: 0,
    currentAction: {},
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    merge(state, { key, value }) {
      state[key] = { ...state[key], ...value };
    },
  },
  actions: {
    async respondAction(context, payload) {
      const response = await this.state.core.client.post(
        'core/respondaction',
        payload,
        {},
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        context.commit('set', {
          key: 'currentAction',
          value: response.data.action,
        });
      }

      return response;
    },
    async addActionComment(context, payload) {
      const response = await this.state.core.client.post(
        'core/addactioncomment',
        payload,
        {},
        HTTP.USE_JSON
      );

      if (response.status === 200) {
        context.commit('set', {
          key: 'currentAction',
          value: response.data.action,
        });
      }

      return response;
    },
    async archiveAction(context, id) {
      const response = await this.state.core.client.post('core/archiveaction', {
        id,
      });
      if (response.status === 200) {
        await context.dispatch('getActions');
      }
    },
    async removeActionFromList(context, id) {
      const filteredActions = context.state.actions.inbox.filter(
        (action) => action['_id'] != id
      );

      const newActions = { ...context.state.actions };

      newActions.inbox = filteredActions;

      await context.commit('set', {
        key: 'actions',
        value: newActions,
      });

      await context.commit('set', {
        key: 'actionsUnread',
        value: context.state.actionsUnread - 1,
      });
    },

    async getActions(context, { type, showAll }) {
      const response = await this.state.core.client.get(
        'core/getactions',
        {
          type,
          archived: showAll,
        },
        HTTP.USE_JSON
      );

      if (response.status === 200) {
        const actionData =
          response.data && response.data.action
            ? response.data.action
            : response.data;

        const newActions = { ...context.state.actions };

        newActions[type] = actionData;

        context.commit('set', {
          key: 'actions',
          value: newActions,
        });

        const actions = response.data;

        if (actions?.action && type === 'inbox') {
          let unreadActionsCount = 0;

          actions.action.forEach((action) => {
            unreadActionsCount += action.is_read ? 0 : 1;
          });

          await context.commit('set', {
            key: 'actionsUnread',
            value: unreadActionsCount,
          });
        }
      }
    },
    async getAction(context, id) {
      const response = await this.state.core.client.get(
        'core/getaction',
        id,
        HTTP.USE_JSON
      );

      context.commit('set', {
        key: 'currentAction',
        value: response.data.action,
      });
    },
    async getAvailableActions(context) {
      return this.state.core.client.get(
        'core/getavailableactions',
        {},
        HTTP.USE_JSON
      );
    },

    async sendFileForApproval(context, payload) {
      return this.state.core.client.post('core/sendfileforapproval', payload);
    },
  },
};
