<template>
  <component :is="loader" v-if="loader" :file="file" />
</template>
<script>
export default {
  name: 'DynamicSection',
  props: {
    file: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    loader() {
      if (!this.title) {
        return null;
      }
      return () => import(`./${this.title}`);
    },
  },
};
</script>
