import dayjs from 'dayjs';
import _ from 'lodash';

export function prepareMeta(meta) {
  _.forOwn(meta, (value, key) => {
    if (typeof value === 'object') {
      meta[key] = '';
    }
  });
  return meta;
}

export function prepareItems(entry) {
  if (!entry) {
    return [];
  }
  let items = entry?.length ? entry : [entry];
  items = items.filter((item) => item.name !== '.cloud');
  return items;
}

export function getCurrentDir(path) {
  if (path) {
    if (path.endsWith('/')) {
      path = path.slice(0, -1);
    }
    return path.split('/').slice(-1).pop();
  }
  return null;
}

export function splitPath(path, start = null) {
  let pathArray = stripTrailingSlash(path).split('/').slice(0);
  if (start) {
    pathArray = pathArray.slice(pathArray.indexOf(start));
  }

  return pathArray.reduce((acc, cur) => {
    acc.push({
      fullPath: acc.length ? `${acc[acc.length - 1].fullPath}/${cur}` : cur,
      path: cur,
    });
    return acc;
  }, []);
}

export function stripTrailingSlash(str) {
  return str.endsWith('/') ? str.slice(0, -1) : str;
}

export function joinPaths(pathArray) {
  return pathArray
    .filter((name) => name !== '')
    .join('/')
    .replace(/\/\//g, '/');
}

export function formatDateTime(timestamp) {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:hh A');
}
