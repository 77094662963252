var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":"animate__animated animate__slideInUp","leave-active-class":"animate__animated animate__slideOutDown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newFileMenu.open),expression:"newFileMenu.open"}],staticClass:"mobile-menu mobile-menu--newFile",class:{ 'mobile-menu--active': _vm.newFileMenu.open }},[_c('div',{staticClass:"body"},_vm._l((_vm.newFileMenu.actions),function(item,id){return (
          !(
            _vm.newFileMenu.target &&
            item.disabled &&
            item.disabled(_vm.newFileMenu.target)
          )
        )?_c('div',{key:id,staticClass:"menu-item",class:{ 'menu-item--separator': item.seperator },on:{"click":function($event){return _vm.triggerAction(item.action, item.params)}}},[_c('div',{staticClass:"icon"},[(item.icon)?_c('Icon',{attrs:{"name":item.icon,"family":item.iconFamily || 'fal'}}):_vm._e()],1),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t( typeof item.name === 'string' ? item.name : item.name(_vm.newFileMenu.target) ))+" ")])]):_vm._e()}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }