<template>
  <div>
    <Loader :loading="loading" full />
    <div
      class="container p-3 map-popover"
      :style="{ height: !activity.checksum ? '276px' : '320px' }"
    >
      <div class="float-left">
        <Avatar :user="activity.who" size="xs" />
      </div>
      <div class="text-left pl-5">
        <span class="activity-item-who">{{ activity.who }}</span>
        <span class="activity-item-action">{{
          $t(actionCode[activity.actioncode]) + $t(' this file')
        }}</span>
        <span
          v-if="activity.checksum && typeof activity.checksum === 'string'"
          class="activity-item-when pt-1 pb-1"
          @click="copyCode"
          >{{ $t('Checksum') + ' : ' + activity.checksum }}
          <Icon
            name="copy"
            family="fal"
            class="ml-2 cursor-pointer"
            v-tooltip="$t('Copy')"
            @click="copyCode"
          />
        </span>
        <span class="activity-item-when">
          <DateDisplay v-if="!relativeDate" :date="activity.whents" />
          <div v-else>{{ relativeDate }}</div>
        </span>
        <span class="activity-item-when"
          >{{ $t('via') }} {{ activity.how }}</span
        >
        <span class="activity-item-when pb-1"
          >{{ $t('from') }} {{ activity.ip }}</span
        >
        <div class="world-map"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import serverURL from 'common/filecloud/properties/serverURL';
import Avatar from 'common/components/Avatar';
import Icon from 'common/components/Icon';
import DateDisplay from 'common/components/DateDisplay';
export default {
  components: { Loader, Avatar, Icon, DateDisplay },
  props: {
    activity: {
      type: Object,
      default: () => {},
    },
    actionCode: {
      type: Object,
      default: () => {},
    },
    relativeDate: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: true,
      result: [],
    };
  },
  methods: {
    async drawMap(index) {
      this.result[index] = await this.$store.dispatch(
        'files/getGeoIPInfo',
        this.activity.ip
      );
      this.loading = false;
      this.vectorMap(this.result[index].data.result || {});
    },
    copyCode(e) {
      this.$copyText(this.activity.checksum);
    },
    vectorMap(result) {
      let el = document.querySelectorAll('.jvectormap-container');

      if (el.length > 0) {
        for (let i = 0; i < el.length; i++) {
          el[i].parentNode.removeChild(el[i]);
        }
      }

      window.jQuery('.world-map').vectorMap({
        map: 'world_mill_en',
        backgroundColor: '#fff',
        regionStyle: {
          initial: {
            fill: '#465c6a',
          },
          hover: {
            'fill-opacity': 1,
          },
        },
        labels: {
          markers: {
            render: function (index) {
              return result.result;
            },
          },
        },
        markerStyle: {
          initial: {
            fill: 'red',
            stroke: '#383f47',
          },
        },
        markers: [
          { latLng: [result.latitude, result.longitude], name: result.city },
        ],
        markerLabelStyle: {
          initial: {
            'font-size': '10',
            'font-weight': 'bold',
            cursor: 'default',
            fill: 'red',
            background: 'black',
          },
          hover: {
            cursor: 'pointer',
          },
        },
      });
    },
    getProfileImage(username) {
      return serverURL.domainURL + '/core/getprofileimage?username=' + username;
    },
  },
};
</script>
<style lang="scss" scoped>
.map-popover {
  /* Style for "Rectangle" */
  width: 275px;
  height: 276px;
  box-shadow: 0 0 4px var(--shadow-color);
  border-radius: 5px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-light);
}

.world-map {
  width: 100%;
  height: 150px;
  margin-left: -1rem;
}
</style>
