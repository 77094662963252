<template>
  <DialogModal
    :visible="isOpen"
    :title="title"
    class="dialog-modal-table"
    color="gray"
    position="top"
    :buttons="[
      {
        label: `Create CSV Folder Index`,
        outline: false,
        callback: function () {
          downloadFolderListing();
        },
      },
      {
        label: `Close`,
        outline: true,
        callback: function () {
          isOpen = false;
        },
      },
    ]"
    @close="closeModal"
  >
    <PropertiesTable
      :path="path"
      :path-name="pathName"
      :is-root="isRoot"
      :caption="title"
      @closeModal="closeModal"
    />
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import PropertiesTable from '../Tables/Properties';

export default {
  components: {
    DialogModal,
    PropertiesTable,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    pathName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      isRoot: false,
    };
  },
  computed: {
    title() {
      return this.isRoot
        ? this.$t('User Home Properties')
        : this.$t('Folder Properties');
    },
  },
  methods: {
    open() {
      this.isOpen = true;
      this.isRoot = this.pathName === 'My Files';
    },
    closeModal() {
      this.isOpen = false;
    },
    async downloadFolderListing() {
      await this.$store.dispatch('files/exportfilelistcsv', this.path);
    },
  },
};
</script>
