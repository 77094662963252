<template>
  <div class="workflow-sidebar">
    <label>{{ $t('Workflows') }}</label>
    <Loader v-if="loading" :loading="loading" full />
    <div v-else class="collapsable">
      <div
        v-for="(group, index) in groups"
        :key="index"
        class="collapsable__item"
      >
        <div class="collapsable__item__header">
          <h6 @click="toggleSection(index)">
            <Icon
              class="caret"
              name="caret-up"
              family="fas"
              :class="{ 'caret--inverted': !group.expanded }"
            />
            {{ group.name }}
          </h6>
        </div>
        <div v-if="group.expanded" class="collapsable__item__body">
          <div
            v-for="(item, itemidx) in group.items"
            :key="itemidx"
            class="item"
            :data-node="JSON.stringify(item)"
          >
            <span class="workflow-drag-handle">
              <span class="item__icon">
                <img class="icon" :src="iconPath(item.icon)" alt="" />
              </span>
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import Loader from 'common/components/Loader';
import serverURL from 'common/filecloud/properties/serverURL';
import { Draggable } from '@shopify/draggable';
import { workflowDropAreaGroup } from 'common/utils/constants';

export default {
  name: 'WorkflowComponents',
  components: {
    Icon,
    Loader,
  },
  data() {
    return {
      itemExpanded: false,
      loading: true,
      groups: [],
      draggable: null,
      currentDroppedNode: null,
    };
  },
  mounted() {
    this.getSteps();
  },
  updated() {
    this.$nextTick(() => {
      this.draggable = new Draggable(
        document.querySelectorAll('.collapsable__item__body'),
        {
          draggable: '.item',
          handle: '.workflow-drag-handle',
          dropzone: '.node-dropzone',
          mirror: {
            constrainDimensions: false,
          },
          delay: 150,
          distance: 0,
          tresholdDistance: 2,
          appendTo: 'body',
          scrollable: {
            speed: 20,
            sensitivity: 80,
          },
          plugins: [],
        }
      );

      this.draggable.on('drag:start', () => this.$emit('startDragging'));

      this.draggable.on('drag:move', (e) => {
        const element = e.sensorEvent.data.target;
        const isDropzone =
          element.getAttribute('data-node-group') === workflowDropAreaGroup;
        if (isDropzone) {
          const data = element.getAttribute('data-node');
          this.currentDroppedNode = data ? JSON.parse(data) : null;
        } else {
          this.currentDroppedNode = null;
        }
      });

      this.draggable.on('drag:stop', (e) => {
        this.$emit('stopDragging');
        const draggedNodeData = JSON.parse(
          e.data.source.getAttribute('data-node')
        );
        if (this.currentDroppedNode) {
          this.$root.$emit('addNewNode', {
            to: this.currentDroppedNode,
            new: draggedNodeData,
          });
        }
      });
    });
  },
  destroyed() {
    this.draggable.destroy();
  },
  methods: {
    async getSteps() {
      const steps = await this.$store.dispatch('workflows/getWorkflowSteps');
      await this.createStepsGroups(steps);
      this.loading = false;
    },
    async createStepsGroups(steps) {
      steps.forEach((step) => {
        let found = this.groups.find((group) => {
          return group.name === step.group;
        });

        if (!found) {
          const obj = {
            items: [step],
            name: step.group,
            expanded: true,
          };

          this.groups.push(obj);
        } else {
          this.groups[this.groups.indexOf(found)].items.push(step);
        }
      });
    },
    toggleSection(index) {
      this.groups[index].expanded = !this.groups[index].expanded;
    },
    iconPath(path) {
      return serverURL.domainURL + path;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  background-color: var(--bg-medium);
  border-radius: 3px;
  padding: 8px 22px;

  .workflow-drag-handle {
    cursor: grab;
  }

  &.draggable-mirror {
    cursor: grabbing;
    opacity: 1;
    background-color: var(--bg-medium);
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    z-index: 9999;
    border-radius: 3px;
    padding: 8px 22px;
  }
}
</style>
