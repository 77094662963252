<template>
  <li class="transactions-list-item">
    <div class="transaction-icon">
      <Icon family="fas" name="trash-alt" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon
          v-if="transaction.status === 'failed'"
          family="fas"
          name="times"
        />
        <Icon
          v-else-if="transaction.status === 'cancelled'"
          family="fas"
          name="ban"
        />
        <Icon v-else family="fas" name="check" />
      </div>
    </div>
    <div class="transaction-info">
      <span class="transaction-info-title">
        {{ transaction.name }}
        <small v-if="transaction.status === 'processing'" role="status">{{
          $t(transaction.undo ? 'Undoing Remove' : 'Removing')
        }}</small>
        <small v-else></small>
      </span>
      <span v-if="transaction.status === 'preparing'" class="description">
        {{ $t('Preparing to Remove Share') }}
      </span>
      <div v-else-if="transaction.status === 'processing'" class="progress">
        <div
          class="progress-bar w-10 indeterminate"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Removing share...')"
        ></div>
      </div>
      <span v-else-if="transaction.message" class="description" role="status">{{
        transaction.message
      }}</span>
      <span
        v-else-if="transaction.status === 'cancelled'"
        class="description"
        role="alert"
      >
        {{ $t('You have cancelled this operation') }}
      </span>
      <span v-else class="description" role="alert">{{
        $t('Share Successfully Removed')
      }}</span>
    </div>
    <div class="transaction-actions">
      <button
        v-if="transaction.status === 'completed' && !transaction.undo && false"
        v-tooltip="{ delay: 1000, content: 'Undo operation' }"
        class="btn btn-icon"
        :aria-label="$t('Undo operation')"
        @click="undo"
      >
        <Icon family="fas" name="undo" />
        <Icon class="icon-overlay" family="fas" name="undo" />
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  methods: {
    undo() {
      this.$store.dispatch('files/undoTransaction', {
        id: this.transaction.id,
      });
    },
  },
};
</script>
