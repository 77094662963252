<template>
  <DialogModal
    :visible="true"
    :title="$t('Send Share Link via Email') + ' :' + sharedFile"
    :class-name="'share-email'"
    :size="'small'"
    :stick-top="false"
    :buttons="buttonsArray"
    @close="close()"
  >
    <Loader :loading="loading" full />
    <form @submit="sendEmail">
      <InputBox
        :id="'name'"
        v-model="form.from"
        :label="$t('Your Name')"
        :errors="errors"
        :type="'text'"
        :disabled="true"
      />
      <InputBox
        :id="'fromemail'"
        v-model="form.replyto"
        :label="$t('Your Email')"
        :errors="errors"
        :type="'text'"
        :disabled="true"
      />
      <InputBox
        :id="'toemailid'"
        ref="toemailid"
        v-model="form.toemailid"
        :label="$t('To Email')"
        :placeholder="$t('Use comma(,) to separate multiple emails')"
        :type="'text'"
        :errors="errors"
        :focus="true"
      />
      <InputBox
        :id="'sharelink'"
        v-model="form.sharelink"
        :label="$t('Share Link')"
        :errors="errors"
        :disabled="true"
        :type="'text'"
      />
      <div
        v-if="isPasswordProtected"
        class="form-group col-sm d-flex align-items-center"
      >
        <Checkbox
          :id="'updateShare'"
          v-model="form.sharepassword"
          :label="$t('Attach Share password')"
          @input="
            (e) => {
              form.sharepassword = Number(e);
            }
          "
        />
        <label for="updateShare" class="ml-2 updateShareLabel">
          {{ $t('Attach Share password') }}
        </label>
      </div>
      <div
        :class="`form-group col-sm  ${errors['message'] ? 'has-error' : ''}`"
      >
        <label for="sharelink">{{ $t('Message') }}</label>
        <textarea v-model="form.message" class="form-control" />
        <small v-if="errors['message']" class="form-text text-danger">{{
          errors['message'].message
        }}</small>
      </div>
    </form>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import InputBox from 'common/components/InputBox';
import Checkbox from 'common/components/Checkbox';
import Loader from 'common/components/Loader';

export default {
  components: {
    DialogModal,
    InputBox,
    Checkbox,
    Loader,
  },
  data() {
    return {
      errors: {},
      form: {},
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading['share/sendEmail'];
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    sharedFile() {
      return (
        this.$store.state.share.shareProperties.name ||
        this.$store.state.share.shareProperties.sharedobjname
      );
    },
    isPasswordProtected() {
      return this.$store.state.share.shareProperties.ispublicsecure;
    },
    buttonsArray() {
      return [
        {
          label: this.$t('Close'),
          outline: true,
          callback: () => {
            this.close();
          },
        },
        {
          label: this.$t('Send Email'),
          callback: this.sendEmail,
        },
      ];
    },
  },
  watch: {
    'form.sharepassword'() {
      this.updateSharePassword();
    },
  },
  mounted() {
    this.form = {
      from: this.$store.state.auth.user.displayname,
      replyto: this.$store.state.core.fullSystemStatus.email,
      sharelink: this.$store.state.share.shareProperties.shareurl,
      sharelocation: this.$store.state.share.shareProperties.sharelocation,
      sharename: this.$store.state.share.shareProperties.sharename,
      publicshare: this.$store.state.share.shareProperties.shareid,
      toemailid: '',
      message: '',
      sharepassword: Number(
        this.isPasswordProtected && this.systemStatus.SHAREPWDATTACHBYDEFAULT
      ),
    };
    this.toEmailFocus();
  },

  methods: {
    toEmailFocus() {
      this.$refs['toemailid'].$refs['toemailid'].focus();
    },
    async sendEmail() {
      this.errors = {};
      let response = await this.$store.dispatch('share/sendEmail', this.form);
      if (response.ok) {
        if (response.data.emailstatus.status) {
          this.openToast(true, 'success');
          this.$store.state.share.emailShareLinkDialogOpen = false;
        } else {
          this.openToast(false, 'warning', response.data.emailstatus.reason);
          this.toEmailFocus();
        }
      } else {
        this.errors =
          typeof response.error === 'object'
            ? response.error
            : { form: { message: response.error } };
        this.openToast(false, 'warning');
      }
    },
    updateSharePassword() {
      let pwdIndication = this.$t('Password protected share\n');
      if (this.form.sharepassword) {
        this.form.message = pwdIndication + this.form.message;
      } else {
        this.form.message = this.form.message.replace(pwdIndication, '');
      }
    },
    close() {
      this.$store.state.share.emailShareLinkDialogOpen = false;
    },
    openToast(success, type, extraInfo = '') {
      let toast;
      if (success) {
        toast = {
          message: `<b>${this.$t('Success')}</b>
            <p role="alert">${this.$t(
              'Email Sent successfully.'
            )} ${extraInfo}</p>`,
          type: 'success',
        };
      } else {
        toast = {
          message: `<b>${this.$t('Error')}</b>
            <p role="alert">${this.$t(
              'Unable to send Email.'
            )}  ${extraInfo}</p>`,
          type: 'warning',
        };
      }
      this.$toast.open(toast);
    },
  },
};
</script>
