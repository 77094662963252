<template>
  <div class="w-100 details-sidebar">
    <div
      v-if="isSharedPage && !selected.length"
      class="file-title text-dark text-center text-truncate mr-0 mt-5 has-tooltip"
    >
      {{ $t('Please select a Share') }}
    </div>
    <div v-else-if="selected.length <= 1">
      <Header :path="path" />
      <div v-if="isSharedPage" class="sharedByMeDetail">
        <div class="title">
          {{ $t('Last Access') }}
        </div>
        <div class="detail">
          {{ currentFile.lastaccess }}
        </div>
        <hr />
        <div class="title">
          {{ $t('Path') }}
        </div>
        <div class="detail">
          <div ref="shareURL" @click="openLink">{{ currentFile.shareurl }}</div>
          <a @click="copyToClipBoard">{{ $t('Copy') }}</a>
        </div>
      </div>

      <div v-else class="pt-3">
        <ExpandSection title="Info" tab-name="Info" />
        <span
          v-if="!isTeamFolderRoot && !isNetworkShareRoot && !isSharedWithMeRoot"
        >
          <hr v-if="!isShareDisabled" class="mx-0" />
          <ExpandSection
            v-if="!isShareDisabled"
            title="Share"
            tab-name="Share"
          />
          <hr v-if="isDir" class="mx-0" />
          <ExpandSection v-if="isDir && !isLimitedUser" title="Versions" />
          <hr v-if="isRetentionEnabled" class="mx-0" />
          <ExpandSection
            v-if="isRetentionEnabled"
            title="Retention Policy"
            tab-name="Retention"
          />
          <hr class="mx-0" />
          <ExpandSection
            v-if="isPublicAndShare"
            title="Notifications"
            tab-name="Notifications"
          />
        </span>
      </div>
    </div>
    <div v-else>
      <div class="multifiles">
        <FileIcon
          :name="'multifiles'"
          :format="'multifiles'"
          class="img-fluid mt-4 py-4"
          style="transform: scale(2)"
        />
      </div>
      <p
        v-tooltip="selected.length + ' files selected'"
        class="file-title text-dark text-center text-truncate mr-0"
      >
        {{ selected.length }} files selected
      </p>
    </div>
  </div>
</template>

<script>
import FileIcon from 'common/components/FileIcon';
import ExpandSection from '../Details/ExpandSection';
import Header from './Header';
import { mapGetters } from 'vuex';

export default {
  components: {
    FileIcon,
    ExpandSection,
    Header,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    lastSelected: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      toastClipBoard: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Path copied successfully') +
          '.</p>',
        type: 'success',
      },
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    selectedItem() {
      return this.$store.state.files.selectedItem;
    },
    isSharedPage() {
      return this.$store.state.files.sharePageActive;
    },
    isDir() {
      return this.currentFile.type !== 'dir';
    },
    isPublicAndShare() {
      let str = this.$route.path;
      let currentFolder = /[^/]*$/.exec(str)[0];
      return !this.$store.state.auth.isPublic && currentFolder !== 'SHARED';
    },
    meta() {
      const aPath = this.selected.length
        ? this.selected[0].fullfilename
        : this.path;
      return this.$store.getters['files/getPathMeta'](aPath);
    },
    isTeamFolderRoot() {
      return this.meta && this.meta.isroot && this.meta.teamfolderroot;
    },
    isNetworkShareRoot() {
      return this.meta && this.meta.isroot && this.meta.realpath == '/EXTERNAL';
    },
    isSharedWithMeRoot() {
      return this.meta && this.meta.isroot && this.meta.realpath == '/SHARED';
    },
    isShareDisabled() {
      return this.$store.state.core.systemstatus.SHAREMODE === 3;
    },
    isRetentionEnabled() {
      return (
        !this.isLimitedUser &&
        this.$store.state.core.systemstatus.RETENTIONENABLED === 1
      );
    },
  },
  methods: {
    copyToClipBoard() {
      let url = this.$refs.shareURL;
      let range = document.createRange();
      range.selectNode(url);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      this.$toast.open(this.toastClipBoard);
    },
    openLink() {
      window.open(this.currentFile.shareurl, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.sharedByMeDetail {
  padding-top: 20px;
  .title {
    color: var(--text-dark);
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }
  .detail {
    color: var(--text-medium);
    font-size: 10px;
    font-weight: 500;
    padding: 5px 0;
    margin-bottom: 10px;
  }
  .file-title {
    color: var(--text-dark);
  }
}
</style>
