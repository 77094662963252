<template>
  <nav
    v-if="!hideNav"
    id="navbar"
    role="navigation"
    class="navbar navbar-main flex-md-nowrap p-0 timed-drop fixed-top"
  >
    <a
      v-a11ybutton="skipToMainContent"
      class="skip-nav"
      tabindex="0"
      @click="skipToMainContent"
    >
      {{ $t('Skip to main content') }}
    </a>
    <div>
      <a class="navbar-brand mr-0 pl-4" tabindex="-1">
        <div
          v-a11ybutton="gotoDashboard"
          class="nav__filecloud-logo"
          tabindex="0"
          @click="gotoDashboard"
        >
          <img :src="logo" :alt="customization.PRODUCT_NAME" height="35px" />
        </div>
      </a>
    </div>
    <div
      class="navbar flex-md m-0 p-0 row fixed float-right width-search justify-content-end"
    >
      <SearchBar class="float-right" />
      <v-popover
        :auto-hide="autoHideNotifications"
        popover-class="notif-popover z-index-333330"
        :aria-label="$t('Alerts and Notifications')"
        offset="16"
        class="bell-notif"
        role="button"
        :open="!notificationsClosed"
        @show="
          () => {
            notificationsClosed = false;
          }
        "
        @hide="
          () => {
            notificationsClosed = true;
          }
        "
      >
        <div
          class="tooltip-target"
          tabindex="0"
          @keydown.enter="
            () => {
              notificationsClosed = false;
            }
          "
        >
          <div class="icon-wrapper">
            <span v-if="hasNewToasts || hasNewActions" class="badge"></span>
            <Icon name="bell" class="fa notification-icon"></Icon>
          </div>
        </div>
        <Notifications
          slot="popover"
          class="tooltip-content notification-popover"
          :is-visible="!notificationsClosed"
          @closeNotifications="notifications"
        />
      </v-popover>
      <div class="pb-1 text-truncate text-right mt3p pr-3" role="group">
        <DropDownList
          dropdown-class="user-menu"
          :items="userActions"
          :target="{
            systemStatus,
            isHighContrast: customization.HIGH_CONTRAST_MODE,
            isDRMExportHidden: customization.HIDESECUREDOCS,
            isDRMExportDisabled: fullSystemStatus.isdrmenabled !== 1,
            isNoTechSupport: isNoTechSupport,
            isGroupAdmin: isGroupAdmin,
            actionsCount: hasNewActions,
            isFileCloud: customization.PRODUCT_NAME === 'FileCloud',
            isOnboardingActive: isOnboardingActive,
            isLimitedUser: isLimitedUser,
            isLockDisabled: isLockDisabled,
          }"
          @triggerDDAction="triggerAction"
        >
          <button
            class="btn btn-default shadow-none d-flex dropdown-btn menu-button"
          >
            <Avatar size="xs" />
            <span class="user-dropdown pl-2 pr-1">{{ user.displayname }} </span>
            <Icon name="caret-down" family="fas" class="text-black-5 pt-1" />
          </button>
        </DropDownList>
      </div>
    </div>
  </nav>
</template>

<script>
import Notifications from '../Notifications/Notifications';
import DropDownList from 'common/components/DropDownList';
import SearchBar from '../SearchBar';
import Icon from 'common/components/Icon';
import userActions from '../../constants/userActions';
import logo from '../../mixins/logo.js';
import Avatar from 'common/components/Avatar';

import { mapGetters } from 'vuex';

export default {
  components: {
    DropDownList,
    Notifications,
    SearchBar,
    Icon,
    Avatar,
  },
  mixins: [logo],
  props: {
    hideNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificationsClosed: true,
      actions: [],
      autoHideNotifications: true,
      userActions,
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser', 'isLockDisabled']),
    customization() {
      return this.$store.state.core.customization;
    },
    isNoTechSupport() {
      return typeof this.customization.PRODUCT_TECH_SUPPORT_URL !== 'string';
    },
    isGroupAdmin() {
      return (
        this.fullSystemStatus.canlistusergroup == 1 ||
        this.fullSystemStatus.cancreateusergroup == 1 ||
        this.fullSystemStatus.candeleteusergroup == 1 ||
        this.fullSystemStatus.isgroupadmin == 1
      );
    },
    isOnboardingActive() {
      return (
        this.systemStatus &&
        this.systemStatus.TOURENABLED &&
        this.customization &&
        this.customization.userCustomizationLoaded
      );
    },
    hasNewToasts() {
      return this.notificationCount > 0;
    },
    notificationCount() {
      const count = this.$store.state.core.toastsUnread;
      return count === 0 ? '' : count;
    },
    hasNewActions() {
      let unreadedActions = this.$store.state.actions.actionsUnread;
      this.userActions.map((el) => {
        if (el.action === 'manageActions') {
          el.counter = unreadedActions;
        }

        return el;
      });

      return unreadedActions;
    },
    appVersion() {
      return this.systemStatus.APPVERSION;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    user() {
      return this.$store.state.auth.user;
    },
    isInDashboard() {
      return this.$route.name === 'files';
    },
  },
  watch: {
    appVersion: function () {
      this.loadActions();
    },
  },
  mounted() {
    this.loadActions();
  },
  methods: {
    loadActions() {
      if (this.appVersion && this.customization.PRODUCT_NAME === 'FileCloud') {
        this.userActions.map((item) => {
          if (item.action === 'version') {
            item.isTitle = false;
            item.name = `v${this.appVersion} – ${this.$t("What's New?")}`;
            item.icon = 'gift';
          }
        });
      } else if (this.appVersion) {
        this.userActions.map((item) => {
          if (item.action === 'version') {
            item.isTitle = false;
            item.name = `v${this.appVersion}`;
            item.icon = 'gift';
            item.action = '';
          }
        });
      }
    },
    openTour() {
      this.$store.dispatch('onboarding/openTour');
    },
    async gotoDashboard() {
      if (!this.isInDashboard) {
        await this.$store.dispatch('auth/redirectToDashboard');
      }
    },
    notifications() {
      this.notificationsClosed = !this.notificationsClosed;
    },
    triggerAction(action) {
      this[action]();
    },
    version() {
      if (this.customization.PRODUCT_NAME === 'FileCloud') {
        const win = window.open(
          this.customization.PRODUCT_RELEASENOTES_URL,
          '_blank'
        );
        win.focus();
      } else return;
    },
    showFolderPermissionsModal() {
      this.$emit('showFolderPermissionsModal');
    },
    showDRMAccessFilesModal() {
      this.$emit('showDRMAccessFilesModal');
    },
    toggleLockedFilesModal() {
      this.$emit('toggleLockedFilesModal');
    },
    toggleGroupManageModal() {
      this.$emit('toggleGroupManageModal');
    },
    toggleShowShortcutsModal() {
      this.$emit('toggleShowShortcutsModal');
    },
    toggleTourInvitationModal() {
      this.$emit('toggleTourInvitationModal');
    },
    manageActions() {
      this.$router.push('/actions');
    },
    showSettings() {
      this.$router.push('/sets-gnrl.');
    },
    openSupportPage() {
      var win = window.open(
        this.customization.PRODUCT_TECH_SUPPORT_URL,
        '_blank'
      );
      win.focus();
    },
    switchToAdminPortal() {
      window.open('/ui/admin/index.html', '_blank');
    },
    logout() {
      this.$store.dispatch('auth/setShowLogoutModal', true);
    },
    async skipToMainContent() {
      document.activeElement.blur();

      if (!this.isInDashboard) {
        setTimeout(() => {
          let el = document.getElementById('main-section');
          el.focus();
          el.scrollIntoView();
          window.scrollBy(0, -64);
        }, 1000);
      } else {
        setTimeout(() => {
          let el = document.getElementById('folderSummary');
          el.focus();
          el.scrollIntoView();
          window.scrollBy(0, -64);
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-popover {
  width: 395px;
  box-shadow: 0 4px 10px var(--shadow-color);
  border-radius: 4px;
  background-color: var(--bg-light);
}
.badge {
  font-size: 0.25em;
  display: block;
  position: absolute;
  width: 2em;
  height: 2em;
  line-height: 2em;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  margin-left: 9.9px;
  background: #d30000;
}
.width-search {
  display: flex;
  flex-wrap: nowrap;

  @media screen and (min-width: 1024px) {
    min-width: 57rem;
  }
}
.text-black-5 {
  color: var(--text-medium);
}
.notification-icon {
  position: relative;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.user-dropdown {
  color: var(--text-dark);
  font-size: 15px;
  font-weight: 700;
  padding-top: 1px;
}
.bell-notif {
  cursor: pointer;
  margin: 0 10px 5px 0;
}
.mt3p {
  margin-top: 2px;
}
.menu-icon {
  font-size: 1rem;
  min-width: 1.5rem;
  color: #333333;
}
.feedback-txt {
  font-size: 13px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
}
</style>
