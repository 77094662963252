<template>
  <DialogModal
    class="
      dialog-modal-overlay
      timed-fade
      dialog-modal-table dialog-modal-visible
      stick-top
      big
      animate-timed-fade
      shared-by-me-wrapper
      locked-files-wrapper
      dialog-modal-top
    "
    :stick-top="true"
    :visible="true"
    class-name="none"
    :title="$t(`Manage Secure Docs`)"
    :buttons="[
      {
        label: 'OK',
        outline: true,
        callback: () => this.$emit('close'),
      },
    ]"
    @close="$emit('close')"
  >
    <Loader :loading="loading" full />
    <div>
      <div class="table-wrapper-border">
        <table class="table table--properties">
          <caption>
            {{
              $t('Manage Secure Docs')
            }}
          </caption>
          <thead>
            <th scope="col">
              {{ $t('Path') }}
              <span class="filter ml-0">
                <InputBox
                  :id="'filter-files'"
                  v-model="filterValue"
                  :placeholder="$t('Filter Items')"
                  class="filter-items mb-0"
                  :prepends="[{ icon: 'filter' }]"
                  form-size="sm"
                />
              </span>
            </th>
            <th class="centered" scope="col">
              {{ $t('Enable Screenshot/ Screenshare (Windows Only)') }}
            </th>
            <th class="centered" scope="col">{{ $t('Secure view') }}</th>
            <th class="centered" scope="col">{{ $t('Allow print') }}</th>
            <th class="centered" scope="col">{{ $t('Access count') }}</th>
            <th class="centered" scope="col">{{ $t('Allow acces times') }}</th>
            <th class="centered" scope="col">{{ $t('Actions') }}</th>
          </thead>
          <tbody>
            <tr v-if="!loading && paginatedUserDRMAccessFiles.length === 0">
              <td colspan="7" class="centered p-5">
                <EmptyListingInfo
                  icon-name="shield-check"
                  text="No Manage Secure Docs"
                />
              </td>
            </tr>
            <DRMFileListItem
              v-for="(file, k) in paginatedUserDRMAccessFiles"
              :key="k"
              :item="file"
            />
          </tbody>
        </table>
      </div>

      <Paginator
        v-if="totalItems / itemsPerPage > 1"
        :current-page="currentPage"
        :items-per-page="itemsPerPage"
        :total-items="totalItems"
        @onPageChange="onPageChage"
      />
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import Loader from 'common/components/Loader';
import EmptyListingInfo from 'common/components/EmptyListingInfo';
import DRMFileListItem from '../FileList/FileListDRMItem';
import InputBox from 'common/components/InputBox';
import Paginator from 'common/components/Paginator';
import { mapState } from 'vuex';

export default {
  components: {
    DialogModal,
    Paginator,
    DRMFileListItem,
    InputBox,
    EmptyListingInfo,
    Loader,
  },
  data() {
    return {
      sample: '',
      filterValue: '',
      itemsPerPage: 10,
      currentPage: 1,
      isEditingField: false,
    };
  },
  computed: {
    ...mapState('files', ['userDRMAccessFiles']),
    loading() {
      return this.$store.state.loading['files/getDRMFilesList'];
    },
    userDRMAccessFilesComputed() {
      if (this.filterValue == '') return this.userDRMAccessFiles;
      return this.userDRMAccessFiles.filter(({ path }) => {
        return path.toLowerCase().includes(this.filterValue.toLowerCase());
      });
    },
    isOpen() {
      return this.$store.state.files.drmListModalOpen;
    },
    paginatedUserDRMAccessFiles() {
      const initialIndex = (this.currentPage - 1) * this.itemsPerPage;
      const lastIndex = this.currentPage * this.itemsPerPage;
      let type = typeof this.userDRMAccessFilesComputed === 'object';
      return type
        ? this.userDRMAccessFilesComputed.slice(initialIndex, lastIndex)
        : [this.userDRMAccessFilesComputed];
    },
    totalItems() {
      return this.userDRMAccessFilesComputed?.length;
    },
  },
  mounted() {
    this.$root.$on(
      'isEditingDRMMaxAccessField',
      (state) => (this.isEditingField = state)
    );
  },
  destroyed() {
    this.$root.$off('isEditingDRMMaxAccessField');
  },
  methods: {
    onPageChage(newPage) {
      this.currentPage = newPage;
    },
    open() {
      this.$store.dispatch('files/openDRMListModal');
    },
    onFilter(value) {
      //TODO: add filter support
      this.$store.dispatch('files/getDRMFilesList', { filter: value });
    },
    closeModal() {
      this.$store.dispatch('files/closeDRMFileListDialog');
      if (this.isEditingField) {
        this.$root.$emit('drmAccessModalClosed');
      }
    },
  },
};
</script>

<style scoped>
  .dialog-modal-top {
    z-index: 10000;
  }
</style>