<template>
  <nav
    v-if="!hideNav"
    id="navbar"
    role="navigation"
    class="navbar navbar-main p-0"
  >
    <div class="navbar-toggler col-2 col-md-3">
      <button
        type="button"
        aria-label="open menu"
        @click="$emit('openMobileNavigation')"
      >
        <Icon name="bars" family="fas" />
      </button>
    </div>
    <div class="navbar-brand col-4 col-md-3">
      <img
        :src="logo"
        :alt="customization.PRODUCT_NAME"
        @click="gotoDashboard"
      />
    </div>
    <div class="navbar-buttons col-6 col-md-3">
      <button
        class="btn"
        type="button"
        aria-label="open search bar"
        @click="openSearchbar"
      >
        <Icon name="search" family="fas" />
      </button>
      <v-popover
        v-close-popover="notificationsClosed"
        popover-class="notif-popover"
        offset="16"
        class="btn bell-notif"
      >
        <div v-if="!msTeams" class="tooltip-target" @click="notifications">
          <div class="icon-wrapper">
            <span v-if="hasNewToasts" class="badge"></span>
            <Icon
              name="bell"
              class="fa icon-grey text-black-5 notification-icon"
            />
          </div>
        </div>
        <Notifications
          slot="popover"
          class="tooltip-content notification-popover"
        />
      </v-popover>
      <DropDownList
        :items="actions"
        :target="{
          systemStatus,
          isHighContrast: customization.HIGH_CONTRAST_MODE,
          isDRMExportHidden: customization.HIDESECUREDOCS,
          isDRMExportDisabled: !fullSystemStatus.isdrmenabled === 1,
          isNoTechSupport: isNoTechSupport,
          isMobileDevice: true,
          isFileCloud: customization.PRODUCT_NAME === 'FileCloud',
          isLockDisabled: isLockDisabled,
          isLimitedUser: isLimitedUser,
        }"
        class="btn bell-notif"
        @triggerDDAction="triggerAction"
      >
        <button class="btn btn-default d-flex dropdown-btn p-0">
          <Avatar size="xs" />
          <Icon name="caret-down" family="fas" class="text-black-5 pt-1 ml-2" />
        </button>
      </DropDownList>
    </div>
  </nav>
</template>

<script>
import Icon from 'common/components/Icon';
import Notifications from '../Notifications/Notifications';
import DropDownList from 'common/components/DropDownList';
import Avatar from 'common/components/Avatar';
import logo from '../../mixins/logo.js';
import userActions from '../../constants/userActions';

import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    Notifications,
    Avatar,
    DropDownList,
  },
  mixins: [logo],
  props: {
    hideNav: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notificationsClosed: true,
      actions: [],
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser', 'isLockDisabled']),
    customization() {
      return this.$store.state.core.customization;
    },
    hasNewToasts() {
      return this.notificationCount > 0;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
    isNoTechSupport() {
      return typeof this.customization.PRODUCT_TECH_SUPPORT_URL !== 'string';
    },
  },
  mounted() {
    this.loadActions();
  },
  methods: {
    loadActions() {
      this.actions = [];
      if (this.systemStatus?.appVersion) {
        this.actions.push({
          name: 'v' + this.systemStatus.appVersion,
          icon: 'certificate',
          action: 'version',
        });
      }
      this.actions.push(...userActions);
    },
    openTour() {
      this.$store.dispatch('onboarding/openTour');
    },
    gotoDashboard() {
      this.$router.push('/expl-tabl.');
    },
    notifications() {
      this.notificationsClosed = !this.notificationsClosed;
    },
    openSearchbar() {
      this.$store.commit('files/showMobileSearch');
      this.$store.commit('files/showMobileOverlay');
    },
    triggerAction(action) {
      this[action]();
    },
    version() {
      if (this.customization.PRODUCT_NAME === 'FileCloud') {
        const win = window.open(
          this.customization.PRODUCT_RELEASENOTES_URL,
          '_blank'
        );
        win.focus();
      } else return;
    },
    showFolderPermissionsModal() {
      this.$emit('showFolderPermissionsModal');
    },
    showDRMAccessFilesModal() {
      this.$emit('showDRMAccessFilesModal');
    },
    toggleLockedFilesModal() {
      this.$emit('toggleLockedFilesModal');
    },
    toggleShowShortcutsModal() {
      this.$emit('toggleShowShortcutsModal');
    },
    manageActions() {
      this.$router.push('/actions');
    },
    showSettings() {
      this.$router.push('/sets-gnrl.');
    },
    openSupportPage() {
      var win = window.open(
        this.customization.PRODUCT_TECH_SUPPORT_URL,
        '_blank'
      );
      win.focus();
    },
    switchToAdminPortal() {
      window.open('/ui/admin/index.html', '_blank');
    },
    logout() {
      this.$store.dispatch('auth/setShowLogoutModal', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-popover {
  box-shadow: 0 4px 10px var(--shadow-color);
  border-radius: 4px;
  background-color: var(--bg-light);
  width: 100vw;

  @media screen and (min-width: 768px) {
    width: 395px;
  }
}
</style>
