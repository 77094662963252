<template>
  <div class="file-preview-item file-preview-item--office">
    <Loader v-if="isLoading" />
    <iframe
      v-if="
        customization.ENABLEWOPIPREVIEW &&
        (customization.WOPICLIENT === 'OFFICEONLINE' || customization.WOPICLIENT === 'ONLYOFFICE' ||
          customization.WOPICLIENT === 'OFFICE')
      "
      class="wopi-preview"
      :src="url"
    />
    <div v-else id="document-wrapper" ref="wrapper" class="viewer-js" />
  </div>
</template>
<script>
import Loader from 'common/assets/loader.svg';

import { getFileExtension, getFileType, loadScript } from '../../utils/files';

export default {
  components: {
    Loader,
  },
  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: true,
      isParsing: true,
      instance: null,
      render: null,
      blob: null,
      url: null,
      devUrl: '/ui/core/index.html/',
    };
  },
  computed: {
    customization() {
      return this.$store.state.core.customization;
    },
    user() {
      return this.$store.state.auth.user;
    },
    fileFormat() {
      return getFileType(this.file.name);
    },
    enableJsPreview() {
      return this.customization.ENABLEJSPREVIEW;
    },
    enableWopiPreview() {
      return this.customization.ENABLEWOPIPREVIEW
    },
  },
  watch: {
    file: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      try {
        // fallback in case of 0b file
        if (
          typeof this.file.fullsize === 'number' &&
          this.file.fullsize === 0
        ) {
          this.$emit('fallback', 'DefaultView');
          return;
        }

        if (
          this.enableWopiPreview &&
          (this.customization.WOPICLIENT === 'OFFICEONLINE' || this.customization.WOPICLIENT === 'ONLYOFFICE' ||
            this.customization.WOPICLIENT === 'OFFICE' || this.customization.ENABLEGOOGLEDOCS == 1) 
        ) {
          this.url = await this.$store.dispatch(
            'files/getWopiPreview',
            this.file
          );
          if (!this.url) {
            this.$emit('fallback', this.customization.ENABLEFCCONVERTERPREVIEW ? 'DocConvertView' : 'DefaultView');
          }
          this.isLoading = false;
          return;
        }

        this.isLoading = true;
        this.isParsing = true;

        // unsuported file versions
        if (
          ['docx', 'xlsx', 'pptx', 'doc', 'xls', 'ppt', 'odt', 'odg', 'odp', 'ods'].indexOf(
            getFileExtension(this.file.name)
          ) === -1
        ) {
          this.$emit('fallback', 'DefaultView');
          return;
        }

        // destroy document preview instance
        if (this.instance) {
          this.instance.destroy();
        }
        
        this.render = null;

        // load file blob
        const blobResponse = await this.$store.dispatch('files/blob', this.file);
        if (!blobResponse.ok) {
          this.$emit('fallback', 'DefaultView');
          return;
        }
        this.blob = blobResponse.data;

        this.blob.name = this.blob.fileName = this.file.name;

        if (!window.jQuery) {
          await loadScript(
            `${
              process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
            }/vendor/jquery.min.js`
          );
        }
        if (this.enableJsPreview) {
          switch (this.fileFormat) {
            case 'word':
              // download resources if not present

              if (!window.docxJS) {
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/docxjs/DocxJS.bundle.min.js`
                );
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/docxjs/DocxUiLoader.js`
                );
              }

              this.instance = window.docxJS = window.createDocxJS
                ? window.createDocxJS()
                : new window.DocxJS();
              this.render = window.docxAfterRender;

              break;

            case 'excel':
              // download resources if not present
              if (!window.cellJS) {
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/celljs/CellJS.bundle.min.js`
                );
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/celljs/CellUiLoader.js`
                );
              }

              this.instance = window.cellJS = window.createCellJS
                ? window.createCellJS()
                : new window.CellJS();
              this.render = window.cellAfterRender;

              break;

            case 'powerpoint':
              // download resources if not present
              if (!window.slideJS) {
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/slidejs/SlideJS.bundle.min.js`
                );
                await loadScript(
                  `${
                    process.env.NODE_ENV === 'production' ? '/ui/core' : this.devUrl
                  }/vendor/slidejs/SlideUiLoader.js`
                );
              }

              this.instance = window.slideJS = window.createSlideJS
                ? window.createSlideJS()
                : new window.SlideJS();
              this.render = window.slideAfterRender;

              break;

            default:
          }
        } else {
          this.$emit('fallback', this.customization.ENABLEFCCONVERTERPREVIEW ? 'DocConvertView' : 'DefaultView');
        } 

        this.instance.parse(
          this.blob,
          this.onParseDocument,
          this.onParseDocumentFail
        );

        // timeout for parsing
        setTimeout(() => {
          if (this.isParsing) {
            this.$emit('fallback', this.customization.ENABLEFCCONVERTERPREVIEW ? 'DocConvertView' : 'DefaultView');
          }
        }, 1000);
      } catch (err) {
        this.$emit('fallback', this.customization.ENABLEFCCONVERTERPREVIEW ? 'DocConvertView' : 'DefaultView');
      }
    },
    onParseDocument() {
      this.isParsing = false;

      const element = window.jQuery('#document-wrapper')[0];
      window.jQuery(element).css('height', 'calc(100% - 65px)');

      this.render(element, this.onRenderDocument.bind(this));

      this.isLoading = false;
    },
    onRenderDocument() {},
    onParseDocumentFail() {
      this.$emit('fallback', 'DefaultView');
    },
  },
};
</script>
