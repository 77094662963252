export default [
  {
    name: 'Open',
    icon: 'eye',
    emit: 'browse',
    disabled: () => false,
    hidden: ({ item }) => item.type === 'file',
  },
  {
    name: 'Preview',
    icon: 'eye',
    emit: 'preview-item',
    disabled: () => false,
    hidden: ({ item }) => item.type === 'dir',
  },
  {
    name: 'Download',
    icon: 'download',
    emit: 'download-item',
    disabled: ({ canDownload, item }) => !canDownload || item.type === 'dir',
    hidden: () => false,
  },
  {
    name: 'Delete',
    icon: 'trash',
    emit: 'delete-item',
    disabled: ({ canUpload }) => !canUpload,
    hidden: () => false,
  },
];
