<template>
  <div class="screen-reader-text">
    <span v-if="visible" role="alert">{{ text }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: '',
      visible: false,
    };
  },
  mounted() {
    this.$root.$on('announceScreenReader', (text) => {
      this.announce(text);
    });
  },
  methods: {
    announce(text) {
      this.text = text;
      this.visible = true;

      setTimeout(() => {
        this.visible = false;
        this.text = '';
      }, 5000);
    },
  },
};
</script>
