import FileBasicsTopics from './topics/file-basics/index.js';
import ThemesTopics from './topics/themes/index';
import MiniAdminTopics from './topics/mini-admin/index';

export default {
  sections: [
    {
      id: 'onboarding.file-basics',
      title: 'File Basics',
      topics: FileBasicsTopics,
    },
    {
      id: 'onboarding.themes',
      title: 'Themes',
      topics: ThemesTopics,
    },
    {
      id: 'onboarding.miniadmin',
      disabled: ({ systemstatus }) => !systemstatus || systemstatus.MINIADMINENABLED !== 1,
      title: 'Mini Admin',
      topics: MiniAdminTopics,
    },
  ],
};
