<template>
  <div>
    <DialogModal
      ref="inviteUser"
      :stick-top="false"
      :title="dialogTitle"
      :visible="isShown"
      :size="'small'"
      :contain-form="isEditingEmail && !advancedEditingEmail"
      :class-name="'invite-dialog'"
      :buttons="buttonArray"
      footer-class="flex-wrap"
      :back-button="isEditingEmail"
      :back-button-callback="backCallback"
      :tabbed="advancedEditingEmail && isEditingEmail"
      initial-focus-element="#userSearch .form-control"
      @close="hideModal"
      @userFeedback="onUserFeedbackSubjectConfirm"
    >
      <Loader :loading="loading" full />
      <div v-if="shareProperties && !isEditingEmail" class="inviteUserWrapper">
        <h6>{{ $t('Add email address or account') }}</h6>
        <VueTypeaheadBootstrap
          id="userSearch"
          ref="userAutocomplete"
          v-model="query"
          class="mb-4"
          :data="users"
          :serializer="(item) => item.emailid || item.username"
          :show-all-results="true"
          :placeholder="$t('Search Users...')"
          @hit="getInvites($event)"
        >
          <template slot="append">
            <v-popover>
              <button
                ref="btnSearch"
                class="btn btn-primary btn-group fa fa-search search-btn tooltip-target"
              ></button>
              <Alert slot="popover" button-type="primary" :buttons="btns"
                ><p v-html="$t(message)"></p
              ></Alert>
            </v-popover>
          </template>
          <!--Begin custom suggestion slot-->
          <template
            slot="suggestion"
            slot-scope="{ data, htmlText }"
            class="disabled"
          >
            <div class="d-flex align-items-center">
              <div class="col-1 d-flex justfy-content-center mx-1 p-0">
                <Icon
                  v-if="data.new"
                  name="user-plus"
                  family="fas"
                  class="text-black fa-2x"
                />
                <Avatar v-else :user="data.username" size="share" />
              </div>

              <div
                :class="
                  systemStatus.CREATEACCOUNTONSHAREINVITE == 1
                    ? 'col-md-9'
                    : 'col-md-11'
                "
              >
                <span class="user-name" v-html="htmlText"></span><br />
                <small v-if="!data.new && data.username" class="text-muted">{{
                  data.displayname === data.username
                    ? data.displayname
                    : data.displayname + ' (' + data.username + ')'
                }}</small>
                <small v-else-if="data.new" class="text-black">
                  <span v-if="!systemStatus.CREATEACCOUNTONSHAREINVITE">
                    {{
                      $t(
                        'The creation of a new user account is not permitted while inviting a new user to access your share.'
                      ) +
                      ' ' +
                      $t(
                        'You may contact your administrator to permit new user account creation.'
                      )
                    }}
                  </span>
                  <span v-else
                    >{{
                      $t(
                        'For eligible email id, a new account will be created and an email will be sent to '
                      ) +
                      ' ' +
                      data.emailid +
                      $t(' with password to login.')
                    }}
                  </span>
                </small>
                <br />
                <small
                  v-if="data.phone && data.phone.length > 0"
                  class="text-dark"
                  >Phone: {{ data?.phone }}</small
                >
              </div>
              <span
                v-if="
                  !isMobile &&
                  !isTablet &&
                  systemStatus.CREATEACCOUNTONSHAREINVITE == 1
                "
                class="float-right btn btn-sm btn-outline-primary btn-block col-2"
              >
                {{ data.new ? $t('Invite') : $t('Add') }}
              </span>
            </div>
          </template>
        </VueTypeaheadBootstrap>
        <h6 class="pt-2">{{ $t('Invited Users') }}</h6>
        <ul class="list-group">
          <li
            v-for="(selUser, index) in userInvites"
            :key="selUser.emailid"
            class="list-group-item"
          >
            <div class="d-flex align-items-center">
              <Avatar
                :user="selUser.username"
                :unregistered-user="selUser.displayname === ''"
                size="share"
              />
              <div class="col-md-10 user-info-pop">
                <h6 class="pb-1">
                  {{ selUser.displayname || selUser.emailid }}
                  <v-popover
                    class="d-inline"
                    placement="right"
                    @update:open="
                      (isOpen) => {
                        userInfoVisible = isOpen;
                      }
                    "
                  >
                    <button ref="btnInfo" class="btn btn-sm py-0">
                      <Icon
                        name="info-circle"
                        family="fas"
                        class="tooltip-target text-muted"
                      />
                    </button>
                    <template slot="popover" class="p-3">
                      <UserInfo
                        :user="selUser"
                        @addToShareList="addToShareList"
                      />
                    </template>
                  </v-popover>
                </h6>
                <div v-if="selUser.displayname" class="user-email">
                  {{ selUser.emailid }}
                </div>
              </div>
              <button
                class="float-right btn btn-sm btn-icon fa fa-times text-muted ml-4 col-1"
                @click="removeUser(index)"
              ></button>
            </div>
          </li>
          <li
            v-if="userInvites.length == 0"
            class="list-group-item"
            style="height: 10rem"
          >
            <p>{{ $t('No user invited.') }}</p>
          </li>
        </ul>
      </div>
      <EditEmail
        v-else-if="isEditingEmail"
        :advanced-active="advancedEditingEmail"
        :advanced-email="advancedEmail"
        :users="userInvites"
        :form="form"
      />
      <template #left-button-hint>
        <div
          v-if="!isEditingEmail && context.context === 'share'"
          class="send-edit-email"
        >
          <p
            v-tooltip="
              disableShareKey === 2 && $t('Sending email is not allowed')
            "
            class="d-flex align-items-center p-0 m-0"
          >
            <Checkbox
              id="sendEmail"
              v-model="form.sendEmail"
              label="Send Email"
              :disabled="disableShareKey === 2"
              @change="updateSendEmail"
            ></Checkbox>
            <label class="text-nowrap px-2 my-0">{{ $t('Send Email') }}</label>
            <a
              v-if="disableShareKey === 0 && userInvites.length > 0 && enableShareEmailEdit"
              v-a11ybutton="startEditingEmail"
              class="px-2"
              tabindex="0"
              @click="startEditingEmail"
              >{{ $t('Edit') }}</a
            >
          </p>
        </div>
      </template>
    </DialogModal>
  </div>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import EditEmail from '../Shares/editEmail';
import UserInfo from '../Shares/UserInfo';
import Icon from 'common/components/Icon';
import _ from 'underscore';
import Loader from 'common/components/Loader';
import Alert from 'common/components/Alert';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Checkbox from 'common/components/Checkbox';
import Avatar from 'common/components/Avatar';
import { isValidEmail } from 'common/utils/forminput';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Loader,
    DialogModal,
    VueTypeaheadBootstrap,
    EditEmail,
    UserInfo,
    Icon,
    Alert,
    Checkbox,
    Avatar,
  },
  filters: {
    stringify(value) {
      return JSON.stringify(value, null, 2);
    },
  },
  mixins: [responsivenessMixin],
  props: {
    showModal: {
      default: false,
      type: Boolean,
    },
    context: {
      type: Object,
      default: () => {
        return {
          context: 'share',
          shareid: '',
          path: '',
        };
      },
    },
    shareId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: '',
      selectedUser: {},
      userInvites: [],
      isShown: this.showModal,
      users: [],
      isEditingEmail: false,
      advancedEditingEmail: false,
      advancedEmail: false, //if advance emails are required
      btns: [
        {
          label: this.$t('OK'),
          callback: function () {},
        },
      ],
      userInfoVisible: false,
      message: 'To add a new user, search using their email address',
      form: {
        subject: '',
        subjectExisting: '',
        subjectNew: '',
        subjectNewLimitedUsers: '',
        subjectExistingLimitedUsers: '',
        customMessage: '',
        content: '',
        contentNew: '',
        contentExistingLimitedUsers: '',
        contentNewLimitedUsers: '',
        sendEmail: '',
      },
    };
  },
  computed: {
    loading() {
      return (
        this.$store.state.loading['share/addUsersToShare'] ||
        (isValidEmail(this.query) &&
          this.$store.state.loading['share/searchUsers'])
      );
    },
    shareProperties() {
      return this.$store.state.share.shareProperties;
    },
    file() {
      return this.$store.state.share.shareFile;
    },
    buttonArray() {
      let btnArray = [];
      if (this.context.context === 'share') {
        if (this.isEditingEmail) {
          btnArray.push({
            label: this.advancedEditingEmail
              ? this.$t('Basic')
              : this.$t('Advanced'),
            outline: true,
            left: true,
            disabled: this.userInvites.length === 0,
            callback: () => this.advancedClick(),
          });
        }
        btnArray.push(
          {
            label: this.$t('Cancel'),
            outline: true,
            callback: () =>
              !this.isEditingEmail ? this.hideModal() : this.goBack(),
          },
          {
            label: !this.isEditingEmail
              ? this.$t('Add Users to this Share')
              : this.$t('OK'),
            disabled:
              !this.isEditingEmail && this.userInvites.length === 0
                ? true
                : false,
            callback: () =>
              !this.isEditingEmail ? this.addUserstoShare() : this.goBack(),
            primary: true,
            right: true,
            hasConfirm: this.form.subject === '' && this.isEditingEmail,
            confirmBtn1: this.$t('Save'),
            confirmBtn2: this.$t('Cancel'),
            confirmLabel: this.$t(
              'Subject is empty. Are you sure you want to save?'
            ),
          }
        );
      } else {
        btnArray.push({
          label: this.$t('Add Users'),
          disabled: this.userInvites.length === 0,
          callback: () => this.addUserstoShare(),
        });
      }
      return btnArray;
    },
    dialogTitle() {
      if (this.context.context === 'security') {
        return this.$t('Add User Permission');
      }
      return this.isEditingEmail
        ? this.$t('Customize Email')
        : this.$t('Invite users to this share');
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    enableShareEmailEdit() {
      return !this.$store.state.core.fullSystemStatus.shareemaileditdisabled;
    },
    disableShareKey() {
      return this.$store.state.core.customization.DISABLESHAREDIALOG;
    },
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - debounce the search to avoid hitting the API limits
    query: _.debounce(function (newQuery) {
      if (newQuery) {
        this.searchUsers(newQuery);
      }
    }, 150),
    userInfoVisible(newstate) {
      if (newstate) {
        this.$refs.inviteUser.pauseFocusTrap();
      } else {
        this.$refs.inviteUser.resumeFocusTrap();
      }
    },
  },
  mounted() {
    this.$refs.userAutocomplete.$refs.input.focus();
    this.form.sendEmail = this.disableShareKey !== 2;
  },
  methods: {
    onUserFeedbackSubjectConfirm(event) {
      if (event === 'save') this.goBack();
    },
    goBack() {
      this.isEditingEmail = false;
    },
    advancedClick() {
      this.advancedEmail = true;
      this.advancedEditingEmail = !this.advancedEditingEmail;
    },
    async searchUsers(newQuery) {
      let response = {};
      if (
        this.context.context === 'share' &&
        (this.systemStatus.EXACT_EMAIL_SEARCH_IMPLICIT_INVITE ||
          this.systemStatus.EXACT_EMAIL_SEARCH_EXPLICIT_INVITE) &&
        !isValidEmail(newQuery)
      ) {
        this.message = this.$t('A Valid Email Required');
        this.btns[0].label = this.$t('OK');
        this.users = [];
        return;
      }
      if (
        this.context.context === 'share' &&
        this.systemStatus.EXACT_EMAIL_SEARCH_IMPLICIT_INVITE &&
        isValidEmail(newQuery)
      ) {
        let selectedUser = {};
        selectedUser.emailid = this.query;
        selectedUser.displayname = '';
        selectedUser.phone = '';
        selectedUser.new = true;
        this.users = [];
        selectedUser.index = this.userInvites.length;
        this.users.push(selectedUser);
      } else {
        if (this.context.context === 'share')
          response = await this.$store.dispatch('share/searchUsers', {
            filter: newQuery,
            context: 'share',
            shareid: this.context.shareid,
          });
        if (this.context.context === 'security')
          response = await this.$store.dispatch('share/searchUsers', {
            filter: newQuery,
          });

        let users = response.data.profile;
        if (users && users.length == undefined) {
          users = [users];
        }
        if (!users) {
          users = [];
        }
        users = users.map((user) => {
          if (typeof user.displayname !== 'string')
            user.displayname = user.username;
          return user;
        });
        this.users = users;

        if (response.data.meta && response.data.meta.inviteallowed == 0) {
          //message auto translation
          this.message =
            response.data.meta.invitenotallowederror ||
            this.$t('Inviting new users is disabled.');
          this.btns[0].label = this.$t('OK');
        } else if (response.data.meta && response.data.meta.inviteallowed) {
          let selectedUser = {};
          selectedUser.emailid = this.query;
          selectedUser.displayname = '';
          selectedUser.phone = '';
          selectedUser.new = true;
          selectedUser.emailtemplate = response.data.meta.newuseremailtemplate;
          selectedUser.phoneRequired = response.data.meta.phonenumberrequired;
          selectedUser.nameRequired = response.data.meta.displaynamerequired;
          if (!this.users) {
            this.users = [];
          }
          selectedUser.index = this.userInvites.length;
          this.users.push(selectedUser);
          this.message = !this.systemStatus.CREATEACCOUNTONSHAREINVITE
            ? this.$t(
                'The creation of a new user account is not permitted while inviting a new user to access your share.'
              ) +
              ' ' +
              this.$t(
                'You may contact your administrator to permit new user account creation.'
              )
            : this.$t(
                'For eligible email id, a new account will be created and an email will be sent to '
              ) +
              ' ' +
              selectedUser.emailid +
              this.$t(' with password to login.');
          this.btns[0].label = this.$t('Invite');
          this.btns[0].callback = () => this.getInvites(selectedUser);
        }
      }
    },
    addToShareList(index) {
      this.$refs.btnInfo[index].click();
      this.query = '';
      this.$refs.userAutocomplete.inputValue = '';
      this.$refs.userAutocomplete.$refs.input.focus();
    },
    getInvites(selected) {
      let exists = false;
      for (let i = 0; i < this.userInvites.length; i++) {
        if (selected.emailid === this.userInvites[i].emailid) {
          exists = true;
        }
      }
      this.query = '';
      setTimeout(() => {
        if (!exists) {
          if (selected && selected.inviteallowed == 0) {
            this.message =
              selected.invitenotallowederror ||
              this.$t('Inviting this user is not allowed');
            this.btns[0].label = this.$t('OK');
            this.$refs.btnSearch.click();
          } else {
            this.userInvites.push(selected);
            this.message = this.$t(
              'To add a new user, search using their email address'
            );
            this.btns[0].label = this.$t('OK');
            this.btns[0].callback = function () {};
          }
        }
        this.query = '';
        this.$refs.userAutocomplete.inputValue = '';
        this.$refs.userAutocomplete.$refs.input.focus();
      }, 200);
    },
    startEditingEmail() {
      this.isLoading = true;
      this.isEditingEmail = true;
    },
    backCallback() {
      this.isLoading = true;
      this.isEditingEmail = false;
    },
    hideModal() {
      this.$refs.inviteUser.$el.style.visibility = 'hidden';
      this.isShown = false;
      this.$emit('hideInviteUser');
    },
    removeUser(index) {
      this.userInvites.splice(index, 1);
    },
    async addUserstoShare() {
      if (this.context.context === 'security') {
        let path = this.context.path;
        let store = this.$store;
        let self = this;
        for (const user of this.userInvites) {
          let response = await store.dispatch('files/addAclEntryForPath', {
            path: path,
            type: 'user',
            value: user.emailid,
            perm: 'RWDSM',
            flag: 'allow',
          });
          if (!response.ok) {
            self.$toast.open({
              message:
                '<b>' +
                'Error' +
                '</b><p role="alert">' +
                response.error +
                '</p>',
              type: 'error',
            });
          }
        }
        this.hideModal();
      } else if (this.context.context === 'share') {
        let userids = '';
        let self = this;
        for (const [index, user] of this.userInvites.entries()) {
          if (user.new) {
            let newUser = user.emailid;
            if (user.phoneRequired && !user.phone) {
              self.$refs.btnInfo[index].click();
              return;
            }
            if (user.nameRequired && !user.displayname) {
              self.$refs.btnInfo[index].click();
              return;
            }
            if (user.phone) {
              newUser += '|' + user.phone + '|';
            } else {
              newUser += '||';
            }
            if (user.displayname) {
              newUser += user.displayname;
            }
            userids += newUser + ',';
          } else {
            userids += user.emailid + ',';
          }
        }

        let payload = {
          shareid: this.context.shareid,
          users: userids,
          sendemail: this.form.sendEmail ? 1 : 0,
        };
        //basic email
        if (this.form.sendEmail) {
          if (this.form.subject) {
            payload.custom_subject = this.form.subject;
          }
          if (this.form.customMessage) {
            payload.custom_message = this.form.customMessage;
          }
          //advance email
          if (this.advancedEmail) {
            //advanced email -- addusertoshare
            if (this.form.subjectExisting) {
              payload.addusertoshare_subject = this.form.subjectExisting;
            }
            if (this.form.content) {
              payload.addusertoshare_body = this.form.content;
            }
            if (this.form.existingEmail) {
              payload.addusertoshare_emails = this.form.existingEmail;
            }
            payload.sendcopy = this.form.sendcopy ? 1 : 0;
            //advanced email -- addNEWusertoshare
            if (this.form.subjectNew) {
              payload.addnewusertoshare_subject = this.form.subjectNew;
            }
            if (this.form.contentNew) {
              payload.addnewusertoshare_body = this.form.contentNew;
            }
            if (this.form.newEmail) {
              payload.addnewusertoshare_emails = this.form.newEmail;
            }
            //advanced email -- addLIMITEDusertoshare
            if (this.form.subjectExistingLimitedUsers) {
              payload.addusertoshare_limited_subject =
                this.form.subjectExistingLimitedUsers;
            }
            if (this.form.contentExistingLimitedUsers) {
              payload.addusertoshare_limited_body =
                this.form.contentExistingLimitedUsers;
            }
            if (this.form.existingLimitedEmail) {
              payload.addusertoshare_emails = this.form.existingLimitedEmail;
            }
            //advanced email -- addNEWLIMITEDusertoshare
            if (this.form.subjectNewLimitedUsers) {
              payload.addnewusertoshare_limited_subject =
                this.form.subjectNewLimitedUsers;
            }
            if (this.form.contentNewLimitedUsers) {
              payload.addnewusertoshare_limited_body =
                this.form.contentNewLimitedUsers;
            }
            if (this.form.newLimitedEmail) {
              payload.addnewusertoshare_limited_emails =
                this.form.newLimitedEmail;
            }
          }
        }
        let response = await this.$store.dispatch(
          'share/addUsersToShare',
          payload
        );
        if (response.data.result == 0) {
          this.$toast.open({
            message:
              '<b>' +
              this.$t('Error') +
              '</b><p role="alert">' +
              this.$t(response.data.message) +
              '</p>',
            type: 'error',
          });
        } else {
          this.hideModal();
        }
      }
    },
    updateSendEmail() {
      // toggle send email
    },
  },
};
</script>
<style scoped>
.text-black {
  color: #002b36;
}
.user-email {
  margin-top: -14px;
}
.user-info-pop {
  margin-top: -3px;
}
.search-btn {
  height: 38px;
  border: none;
  border-radius: 0 3px 3px 0;
}
.list-group-item {
  background-color: var(--bg-light);
  border-color: var(--border-color);
  color: var(--text-dark-blue);
}
</style>
