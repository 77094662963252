var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subdropdown"},[_c('div',{staticClass:"subdropdown-container",style:(!_vm.isTablet && !_vm.isMobile ? _vm.containerTransform : '')},[_c('div',{ref:"dropdown",staticClass:"dropdown-wrapper"},_vm._l((_vm.filteredContextActions),function(item,id){return _c('div',{directives:[{name:"close-popover",rawName:"v-close-popover"}],key:id,staticClass:"dropdown-item",class:{
          'has-seperator': item.seperator === true,
          'dropdown-item--disabled':
            _vm.child &&
            item.disabled &&
            item.disabled({
              item: _vm.child.item,
              isInRecycleFolder: _vm.isInRecycleFolder,
              isPublicShareView: _vm.isPublicShareView,
              customization: _vm.customization,
              systemStatus: _vm.systemStatus,
              isLockDisabled: _vm.isLockDisabled,
              isLockedByOthers: _vm.isLockedByOthers(_vm.child.item),
              isImplicitlyLocked: _vm.isImplicitlyLocked(_vm.child.item),
              isDRMExportEnabled: _vm.fullSystemStatus['isdrmenabled'] === 1,
              isAuthenticated: _vm.user.authenticated,
            }),
        },attrs:{"tabindex":"0"}},[_c('div',{on:{"click":function($event){return _vm.$emit('onItemContextClick', item.action, _vm.child)}}},[(
              item.iconClass &&
              item.iconClass({ customization: _vm.customization, item: _vm.child.item })
            )?_c('div',{staticClass:"dropdown-item-icon",class:item.iconClass({ customization: _vm.customization, item: _vm.child.item })}):(item.icon)?_c('Icon',{attrs:{"name":item.icon,"family":item.iconFamily || 'fal'}}):(item.customIcon)?_c('span',[_c('WorkflowIcon',{staticClass:"custom-icon"})],1):_vm._e(),_c('span',{staticClass:"popover-text"},[_vm._v(_vm._s(_vm.$t( typeof item.name === 'string' ? item.name : item.name({ item: _vm.child.item, isInRecycleFolder: _vm.isInRecycleFolder, isPublicShareView: _vm.isPublicShareView, customization: _vm.customization, systemStatus: _vm.systemStatus, isLockedByOthers: _vm.isLockedByOthers(_vm.child.item), isImplicitlyLocked: _vm.isImplicitlyLocked(_vm.child.item), isAuthenticated: _vm.user.authenticated, ...(_vm.editorsClient && _vm.editorsClient[_vm.child.item.ext] && { editor: _vm.editorsClient[_vm.child.item.ext], }), }) ))+" "),(item.shortKey)?_c('span',{staticClass:"shortkey float-right text-capitalize"},[_vm._v(" "+_vm._s(item.shortKey))]):_vm._e()]),(_vm.child.item.colortag && item.icon === 'tag')?_c('span',{staticClass:"float-right text-capitalize"},[_c('FileListItemColorDot',{attrs:{"color":_vm.child.item.colortag}})],1):_vm._e(),(item.showArrow)?_c('Icon',{staticClass:"dropdown-item--arrow",attrs:{"name":"chevron-right","family":"far"}}):_vm._e()],1)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }