var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"animate-timed-fade logout-modal",attrs:{"stick-top":true,"visible":_vm.isOpen,"padding":false,"size":"xsmall","title":_vm.$t('logout.modal.title'),"buttons":[
    {
      label: _vm.$t('logout.modal.confirm_button'),
      callback: () => _vm.logout(),
    },
    {
      label: _vm.$t('logout.modal.cancel_button'),
      outline: true,
      callback: () => _vm.onClose(),
    },
  ]},on:{"close":function($event){return _vm.onClose()}}},[(_vm.isLoading)?_c('Loader',{attrs:{"full":"","loading":""}}):_vm._e(),_c('h6',[_vm._v(" "+_vm._s(_vm.$t('logout.modal.description'))+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }