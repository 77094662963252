<template>
  <div
    ref="editable"
    contenteditable="true"
    class="form-control content-editable"
    :class="{ active: active }"
    @keyup="input"
    @keydown="input"
    @focus="focus"
    @blur="blur"
    @paste="pastedText"
    v-html="valueText"
  />
</template>

<script>
import DOMPurify from 'dompurify';
import { escape } from 'lodash';

export default {
  name: 'ContentEditable',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    onlyInt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueText: '',
      focused: false,
    };
  },
  computed: {
    localValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit('update:value', newValue);
      },
    },
  },
  watch: {
    localValue(newVal) {
      if (!this.focused) {
        this.valueText = newVal;
      }
    },
  },
  mounted() {
    this.valueText = DOMPurify.sanitize(this.value, {
      ALLOWED_TAGS: ['b', 'i', 'u', 'em', 'strong', 'a', 'span'],
    });
  },
  methods: {
    input(event) {
      if (
        event.type === 'keyup' ||
        event.type === 'keydown' ||
        event.type === 'keypress'
      ) {
        if (this.onlyInt) {
          const keyCode = event.keyCode ? event.keyCode : event.which;

          if (
            (keyCode < 48 || keyCode > 57) &&
            (keyCode < 96 || keyCode > 105) &&
            keyCode !== 8 &&
            keyCode !== 46
          ) {
            event.preventDefault();
          } else {
            this.$emit('input', event.target.innerHTML);
            this.localValue = event.target.innerHTML;
          }
        } else {
          this.$emit('input', event.target.innerHTML);
          this.localValue = event.target.innerHTML;
        }
      }
    },
    focus() {
      this.focused = true;
    },
    blur() {
      this.focused = false;
    },
    pastedText(e) {
      e.preventDefault();
      const text = (e.originalEvent || e).clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, escape(text));
    },
  },
};
</script>
