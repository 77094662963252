<template>
  <div class="nav-item nav-item-expandable pt-0">
    <div
      v-a11ybutton="() => expand(getItemsCallback ? getItemsCallback : null)"
      class="expand-link"
      :class="{ 'pb-3': !itemExpanded }"
      role="button"
      :aria-expanded="itemExpanded"
      :aria-controls="tabName"
      tabindex="0"
      @click="expand(getItemsCallback ? getItemsCallback : null)"
    >
      {{ $t(title) }}<span class="sr-only">(current)</span>&nbsp;
      <em
        class="inline-icon caret fas"
        :class="!itemExpanded ? 'fa-caret-down' : 'fa-caret-up'"
      ></em>
    </div>
    <transition v-if="itemExpanded" name="fade">
      <DynamicSection
        :id="tabName"
        :file="currentFile"
        :title="title.replace(' ', '')"
      />
    </transition>
  </div>
</template>

<script>
import DynamicSection from './ExpandSection/DynamicSection';

export default {
  components: {
    DynamicSection,
  },
  props: {
    tabName: {
      type: String,
      default: 'Item',
    },
    title: {
      type: String,
      default: 'Item',
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    getItemsCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      currentItem: -1,
      itemExpanded: false,
      users: [],
      groups: [],
      groupInfo: false,
    };
  },
  computed: {
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
  },
  mounted() {
    this.itemExpanded = this.expanded;
  },
  methods: {
    expand(callback) {
      this.itemExpanded = !this.itemExpanded;
      if (this.itemExpanded === true && callback !== null) {
        callback();
      }
    },
  },
};
</script>
<style lang="scss">
@import 'common/scss/_colors.scss';
@import 'common/filecloud/scss/components/details.scss';
</style>
