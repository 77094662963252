<template>
  <tr>
    <td class="col-title drm-files file-path">
      {{ item.path }}
    </td>
    <td class="m-text centered">
      <span class="pr-1">{{ item.usercount }} {{ $t('Users') }}</span>
      <span>{{ item.groupcount }} {{ $t('Groups') }}</span>
    </td>
    <td class="m-text centered thin">
      {{ item.inherit ? $t('Yes') : $t('No') }}
    </td>
    <td class="d-flex m-text justify-content-center">
      <button
        v-tooltip="$t('Edit Permission')"
        :aria-label="$t('Edit Permission')"
        class="btn btn-sm btn-icon"
        list-only
        @click="editPermissions"
      >
        <Icon name="edit" family="fas" />
      </button>
      <v-popover
        @update:open="
          (isOpen) => {
            showRemoveAlert = isOpen;
          }
        "
      >
        <button
          v-tooltip="$t('Remove Permission')"
          :aria-label="$t('Remove Permission')"
          class="btn btn-sm btn-icon"
          list-only
        >
          <Icon name="times" family="fas" />
        </button>
        <Alert
          slot="popover"
          :visible="showRemoveAlert"
          button-type="danger"
          :buttons="[
            {
              label: `Remove`,
              callback: function () {
                revokePermission(item._id);
              },
            },
            { label: `Cancel`, outline: true, callback: function () {} },
          ]"
        >
          <span role="alert">
            {{ $t('Are you sure you want to delete the Permissions?') }}
            <em>{{ item.path }}</em>
            ?
          </span>
        </Alert>
      </v-popover>
    </td>

    <FolderSecurity
      ref="folderSecurityModal"
      :secured-directory-path="item.path"
      :main-screen-view="true"
      @close="$emit('close')"
    />
  </tr>
</template>
<script>
import Icon from 'common/components/Icon';
import Alert from 'common/components/Alert';
import FolderSecurity from '../../Security/FolderSecurity';
export default {
  components: {
    Icon,
    Alert,
    FolderSecurity,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      toastDeletedAcl: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Folder permissions deleted.') +
          '</p>',
        type: 'success',
      },
      toastDeletedAclFailure: {
        message:
          '<b>' +
          this.$t('Failure') +
          '</b><p role="alert">' +
          this.$t('Folder permissions could not be removed.') +
          '</p>',
        type: 'warning',
      },
      showRemoveAlert: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    this.$store.commit('files/toggleSidebar', false);
  },
  methods: {
    async revokePermission() {
      let response = await this.$store.dispatch('files/deleteAclForPath', {
        path: this.item.path,
      });

      if (response.ok) {
        this.$toast.open(this.toastDeletedAcl);
        this.$emit('deletedPermission');
      } else {
        this.$toast.open(this.toastDeletedAclFailure);
      }
    },
    async editPermissions() {
      this.$refs.folderSecurityModal.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.m-text {
  clear: both;
  white-space: nowrap;
}
.popover {
  opacity: 1;
}
.editable-click {
  border-bottom: dashed 1px #08c;
  cursor: pointer;
}
.file-path {
  width: 300px;
}
table.table tbody tr td {
  word-break: break-all;
  font-size: 0.9rem !important;
  font-weight: 400;
  border-bottom: 0;
  &.thin {
    width: 120px;
  }
}
table.table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
</style>
