<template>
  <div class="mb-2 approvers-list">
    <span
      v-for="(item, index) in value"
      :key="item.id"
      v-tooltip="item.text"
      class="badge badge-secondary"
    >
      <slot :data="item" :index="index">
        <span>{{ item.text }}</span>
        <button
          v-tooltip="$t('Remove')"
          :aria-label="$t('Remove')"
          class="fas fa-times-circle"
          @click.prevent="remove(item.id)"
        />
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'users',
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },
  },
};
</script>
