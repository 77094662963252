<template>
  <div>
    <div
      v-for="(item, id) in filteredContextActions"
      :key="id"
      v-close-popover
      v-a11ybutton="() => onItemContextClick(item.action, child)"
      tabindex="0"
      class="dropdown-item"
      :class="{
        'has-seperator': item.seperator === true,
        'dropdown-item--disabled':
          child &&
          item.disabled &&
          item.disabled(
            selectedFiles.length === 1
              ? {
                  item: child.item,
                  isInRecycleFolder,
                  isLimitedUser,
                  isPublicShareView,
                  customization,
                  systemStatus,
                  isLockDisabled,
                  isLockedByOthers: isLockedByOthers(child.item),
                  isImplicitlyLocked: isImplicitlyLocked(child.item),
                  isDRMExportEnabled: fullSystemStatus['isdrmenabled'] === 1,
                  isAuthenticated: user.authenticated,
                }
              : {
                  selected: selectedFiles.length,
                  isQuickEditEnabled,
                  isContext: true,
                  downloadable: selectedFilesDownloadable,
                  isDRMExportEnabled: fullSystemStatus['isdrmenabled'] === 1,
                }
          ),
      }"
      @focus="onMouseEnter(item)"
    >
      <div
        @mouseenter="onMouseEnter(item)"
        @click="onItemContextClick(item.action, child)"
      >
        <Icon
          v-if="item.icon"
          :name="item.icon"
          :family="item.iconFamily || 'fal'"
        />
        <span v-else-if="item.customIcon">
          <WorkflowIcon class="custom-icon" />
        </span>
        <span class="popover-text"
          >{{
            $t(
              typeof item.name === 'string'
                ? item.name
                : item.name(
                    selectedFiles.length === 1
                      ? {
                          item: child.item,
                          isInRecycleFolder,
                          isLimitedUser,
                          isPublicShareView,
                          customization,
                          systemStatus,
                          isLockedByOthers: isLockedByOthers(child.item),
                          isImplicitlyLocked: isImplicitlyLocked(child.item),
                          isAuthenticated: user.authenticated,
                          ...(editorsClient &&
                            editorsClient[child.item.ext] && {
                              editor: editorsClient[child.item.ext],
                            }),
                        }
                      : {
                          selected: selectedFiles.length,
                          isQuickEditEnabled,
                          isContext: true,
                          downloadable: selectedFilesDownloadable,
                        }
                  ),
              {
                ...(editorsClient &&
                  editorsClient[child.item.ext] && {
                    editor: editorsClient[child.item.ext],
                  }),
              }
            )
          }}
          <span
            v-if="item.shortKey"
            class="shortkey float-right text-capitalize"
          >
            {{ item.shortKey }}</span
          >
        </span>
        <span
          v-if="child.item.colortag && item.icon === 'tag'"
          class="text-capitalize"
        >
          <FileListItemColorDot :color="child.item.colortag" />
        </span>
        <Icon
          v-if="item.showArrow"
          name="chevron-right"
          family="far"
          class="dropdown-item--arrow"
        />
      </div>
    </div>
    <ColorTag
      v-if="colorHover"
      v-close-popover
      :child="child"
      :current-color="child.item.colortag"
      :y-position="colortagYPosition"
      @onItemContextClick="onItemContextClick"
    />
    <EditPopover
      v-if="editHover"
      v-close-popover
      :child="child"
      :y-position="colortagYPosition"
      :item="child.item"
      :filtered-context-actions="filteredFileEditActions"
      :is-in-recycle-folder="isInRecycleFolder"
      :is-public-share-view="isPublicShareView"
      @onItemContextClick="onItemContextClick"
    />
    <StartFileWorkflow
      v-if="startWorkflowHover"
      v-close-popover
      :child="child"
      :current-color="child.item.colortag"
      :y-position="colortagYPosition"
      @onItemContextClick="onItemContextClick"
    />
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import WorkflowIcon from 'common/assets/icons/workflow.svg';
import fileActions from '../../constants/fileActions';
import fileEditActions from '../../constants/fileEditActions';
import ColorTag from '../FileList/partials/ColorTag';
import StartFileWorkflow from '../FileList/StartFileWorkflow';
import FileListItemColorDot from './FileListItemColorDot';
import EditPopover from '../FileList/partials/EditPopover.vue';
import selectedFileActions from '../../constants/selectedFileActions';
import { createFocusTrap } from 'focus-trap';
import { getFileType } from 'common/utils/files';

import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    WorkflowIcon,
    ColorTag,
    FileListItemColorDot,
    StartFileWorkflow,
    EditPopover,
  },
  props: {
    selectedFiles: {
      type: Array,
      default: () => [''],
    },
    isInRecycleFolder: {
      type: Boolean,
      default: false,
    },
    isPublicShareView: {
      type: Boolean,
      default: false,
    },
    isLimitedUser: {
      type: Boolean,
      default: false,
    },
    selectedFilesDownloadable: {
      type: Boolean,
      default: true,
    },
    child: {
      type: Object,
      default: () => {},
    },
    open: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fileActions,
      fileEditActions,
      selectedFileActions,
      colorHover: false,
      colortagYPosition: 0,
      startWorkflowHover: false,
      startWorkflowYPosition: 0,
      focusTrap: null,
      editHover: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    metaSets() {
      const mSets = this.$store.state.core.metaSets;
      return typeof mSets === 'object' && mSets !== null ? mSets.write : false;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    isQuickEditEnabled() {
      return this.$store.state.core.customization.enabledQuickEdit;
    },
    editorsClient() {
      return this.$store.state.core.editorsClient;
    },
    contextActions() {
      return this.selectedFiles.length === 1
        ? fileActions
        : selectedFileActions;
    },
    filteredFileEditActions() {
      return this.fileEditActions.filter(
        (item) =>
          !(
            this.child &&
            item.hidden &&
            item.hidden(
              this.selectedFiles.length === 1
                ? {
                    item: this.child.item,
                    isInRecycleFolder: this.isInRecycleFolder,
                    isLimitedUser: this.isLimitedUser,
                    isPublicShareView: this.isPublicShareView,
                    metaSets: this.metaSets,
                    customization: this.customization,
                    systemStatus: this.systemStatus,
                    isLockedByOthers: this.isLockedByOthers(this.child.item),
                    isAuthenticated: this.user.authenticated,
                  }
                : {
                    item: this.child.item,
                    selected: this.selectedFiles.length,
                    isQuickEditEnabled: this.isQuickEditEnabled,
                    isContext: true,
                    downloadable: this.selectedFilesDownloadable,
                  }
            )
          )
      );
    },
    filteredContextActions() {
      return this.contextActions.filter(
        (item) =>
          !(
            this.child &&
            item.hidden &&
            item.hidden(
              this.selectedFiles.length === 1
                ? {
                    item: this.child.item,
                    isInRecycleFolder: this.isInRecycleFolder,
                    isLimitedUser: this.isLimitedUser,
                    isPublicShareView: this.isPublicShareView,
                    metaSets: this.metaSets,
                    customization: this.customization,
                    systemStatus: this.systemStatus,
                    isLockedByOthers: this.isLockedByOthers(this.child.item),
                    isAuthenticated: this.user.authenticated,
                    hasMultipleEditClients:
                      this.filteredFileEditActions.length > 1,
                    hasSearchResults: this.hasSearchResults,
                  }
                : {
                    selected: this.selectedFiles.length,
                    isQuickEditEnabled: this.isQuickEditEnabled,
                    isContext: true,
                    downloadable: this.selectedFilesDownloadable,
                    isPublicShareView: this.isPublicShareView
                  }
            )
          )
      );
    },
    ...mapGetters('core', ['isLockDisabled']),
  },
  watch: {
    open(value) {
      this.handleFocusTrap(value);
      if (value) {
        this.$emit('open');
      } else {
        this.$emit('close');
      }
    },
  },
  methods: {
    isLockedByOthers(item) {
      return item.locked && item.lockuserid !== this.user.profile;
    },
    isImplicitlyLocked(item) {
      return item.implicitlock;
    },
    onItemContextClick(action, data, param) {
      if (
        action != 'metaColor' ||
        (action === 'metaColor' && param !== undefined)
      ) {
        this.$emit('onItemContextClick', action, data, param);
      }
    },
    onMouseEnter(item) {
      const contextMenu = this.$el;
      const hasScroll = contextMenu.scrollHeight > contextMenu.clientHeight;

      if (hasScroll && contextMenu.scrollTop > 0) {
        this.colortagYPosition = contextMenu.scrollTop;
        this.startWorkflowYPosition = contextMenu.scrollTop;
      } else {
        this.colortagYPosition = 0;
        this.startWorkflowYPosition = 0;
      }

      this.colorHover = item.action === 'metaColor';
      this.startWorkflowHover = item.action === 'startWorkflow';
      this.editHover = item.action === 'editOptions';
    },
    handleFocusTrap(isOpen) {
      if (isOpen) {
        setTimeout(() => {
          if (!this.focusTrap) {
            this.focusTrap = createFocusTrap(this.$el, {
              allowOutsideClick: true,
              escapeDeactivates: false,
            });
          } else {
            this.focusTrap.updateContainerElements(this.$el);
          }
          this.focusTrap.activate();
        }, 300);
      } else if (this.focusTrap) {
        this.focusTrap.deactivate();
      }
    },
  },
};
</script>
