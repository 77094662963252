<template>
  <div class="alert-dialog">
    <div class="alert-dialog-content">
      <slot></slot>
      <div v-if="buttons" class="alert-dialog-buttons">
        <button
          v-for="(button, index) of buttons"
          :key="index"
          v-close-popover
          class="btn wide"
          :class="[
            {
              'btn--block': buttons.length > 2,
              [`btn-outline-` + buttonType]: button.outline,
              [`btn-` + buttonType]: button.outline != true,
              [`btn-focus-${index}`]: true,
            },
            button.customClass,
          ]"
          :disabled="button.disabled"
          @click="callback($event, button.callback ? button.callback : null)"
        >
          {{ button.label }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { createFocusTrap } from 'focus-trap';
import { isMobileDevice } from '../utils/responsive.js';

export default {
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    visible: {
      default: false,
      type: Boolean,
    },
    buttonType: {
      default: 'primary',
      type: String,
    },
    disableAutofocus: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      focusTrap: null,
    };
  },
  computed: {
    isMobileDevice,
  },
  watch: {
    visible(isVisible) {
      if (isVisible && !this.isMobileDevice) {
        this.startFocusTrap();
      } else {
        this.stopFocusTrap();
      }
    },
  },
  mounted() {
    if (this.visible) {
      setTimeout(() => {
        this.focusTrap = createFocusTrap(this.$el);
        this.focusTrap.activate();
      }, 200);
    }
  },
  destroyed() {
    this.stopFocusTrap();
  },
  methods: {
    callback: function (event, buttonCallback) {
      event.preventDefault();
      if (buttonCallback) {
        buttonCallback();
      }
    },
    closeAlert() {
      this.visible = false;
    },
    startFocusTrap: function () {
      setTimeout(() => {
        if (!this.focusTrap) {
          // Create a new trap if not present
          const defaultInitialFocus = this.buttons.length
            ? '.alert-dialog .btn-focus-0'
            : undefined;

          const trapConfig = {
            allowOutsideClick: true,
            escapeDeactivates: false,
            initialFocus: this.disableAutofocus
              ? undefined
              : defaultInitialFocus,
          };

          this.focusTrap = createFocusTrap(this.$el, trapConfig);
        } else {
          // Reuse the focustrap if it is available
          this.focusTrap.updateContainerElements(this.$el);
        }
        this.focusTrap.activate();
      }, 200);
    },
    stopFocusTrap() {
      if (this.focusTrap) {
        this.focusTrap.deactivate();
      }
    },
  },
};
</script>
