<template>
  <DialogModal
    :stick-top="true"
    :visible="true"
    :title="$t(`Filecloud Shortcut keys`)"
    :class-name="'shortcut-dialog'"
    :buttons="[
      {
        label: this.$t('All shortcuts'),
        link: true,
        outline: false,
        left: true,
        hasConfirm: false,
        callback: () => goToDocs(),
      },
      {
        label: 'OK',
        outline: true,
        callback: () => {
          this.$emit('close');
        },
      },
    ]"
    @close="$emit('close')"
  >
    <InputBox
      v-model="searchTerm"
      class="search-shortcut"
      :placeholder="searchPlaceholder"
      :aria-label="searchPlaceholder"
      :auto-complete="false"
      :prepends="searchPrepends"
      form-size="sm"
    />
    <div class="shortcut-wrapper">
      <div v-for="(item, id) in filteredList" :key="id" class="shortcut">
        <div class="keys">
          {{ item.key }}
        </div>
        <div class="value">
          {{ item.value }}
        </div>
      </div>
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import InputBox from 'common/components/InputBox';

export default {
  components: {
    DialogModal,
    InputBox,
  },
  data() {
    return {
      searchTerm: '',
      searchPrepends: [{ icon: 'search', iconBold: true }],
      searchPlaceholder: this.$t('Search'),
      docsUrl:
        'https://www.filecloud.com/supportdocs/display/cloud/Keyboard+Shortcuts',
      isOpen: false,
    };
  },
  computed: {
    isMac() {
      return window.navigator.platform === 'MacIntel';
    },
    shortkeys() {
      const altOption = this.isMac ? '⌥' : 'alt';
      const ctrCmd = this.isMac ? '⌘' : 'ctrl';

      return [
        // File Actions
        { key: 'u or insert', value: 'Upload a file' },
        { key: `${ctrCmd} + c`, value: 'Copy selected item' },
        { key: `${ctrCmd} + x`, value: 'Move selected item' },
        { key: 'd', value: 'Download selected item(s)' },
        { key: 'l', value: 'Lock/unlock selected item' },
        { key: 'c', value: 'Add comment to selected item' },
        { key: 'delete', value: 'Delete selected item' },
        { key: 'F2', value: 'Rename selected item' },
        { key: '.', value: 'Share selected item' },
        { key: 'm', value: 'Open selected item menu' },
        {
          key: `${this.isMac ? 'shift' : 'alt'} + n`,
          value: 'Create folder',
        },
        // Navigation
        { key: '↑ or ↓', value: 'Navigate in a file list' },
        { key: 'enter/return', value: 'Preview file or open folder' },
        { key: 'space', value: 'Select current item' },
        { key: 'shift + a', value: 'Select all' },
        { key: 'pagedown', value: 'Next page of files' },
        { key: 'pageup', value: 'Previous page of files' },
        {
          key: `${this.isMac ? '⌥ + shift' : 'alt'} + ↑`,
          value: 'Go one folder up',
        },
        { key: `${ctrCmd} + home`, value: 'Go to top of page' },
        { key: `${altOption} + r`, value: 'Refresh file list' },
        { key: `${altOption} + →`, value: 'Go to next page' },
        { key: `${altOption} + ←`, value: 'Go to previous page' },
        // Others
        { key: '[', value: 'Toggle Sidebar' },
        { key: ']', value: 'Toggle activity bar' },
        { key: 'p', value: 'Toggle file operations dialog' },
        { key: 'v', value: 'Open list/grid view menu' },
        { key: `${ctrCmd} + i`, value: 'Show folder properties' },
        { key: `${altOption} + d`, value: 'Open deleted files page' },
        { key: `${altOption} + f`, value: 'Filter file list' },
        { key: `${altOption} + F1`, value: 'Show shortcuts' },
      ];
    },
    filteredList() {
      const searchTerm = this.searchTerm.toLowerCase();
      return this.shortkeys.filter(
        (item) =>
          item.key.toLowerCase().includes(searchTerm) ||
          item.value.toLowerCase().includes(searchTerm)
      );
    },
  },
  methods: {
    goToDocs() {
      window.open(this.docsUrl, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

.shortcut-dialog .dialog-modal-body {
  padding-bottom: 0;
}

.search-shortcut {
  width: 300px;
}
.shortcut-wrapper {
  height: 500px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.shortcut {
  width: 50%;
  display: flex;
  height: 32px;
  margin: 0 15px;
  .keys {
    width: 150px;
    color: var(--color-primary);
  }
}
</style>
