var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-table",attrs:{"visible":_vm.isOpen,"title":_vm.title,"color":"gray","position":"top","buttons":[
    {
      label: `Create CSV Folder Index`,
      outline: false,
      callback: function () {
        _vm.downloadFolderListing();
      },
    },
    {
      label: `Close`,
      outline: true,
      callback: function () {
        _vm.isOpen = false;
      },
    },
  ]},on:{"close":_vm.closeModal}},[_c('PropertiesTable',{attrs:{"path":_vm.path,"path-name":_vm.pathName,"is-root":_vm.isRoot,"caption":_vm.title},on:{"closeModal":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }