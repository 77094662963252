var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.visible)?_c('div',{staticClass:"dialog-modal-overlay timed-fade",class:[
    { 'dialog-modal-visible': _vm.visible },
    { 'stick-top': _vm.stickTop },
    { 'dialog-modal-tabbed': _vm.tabbed },
    { 'dialog-modal-form': _vm.containForm },
    _vm.size,
    _vm.className,
  ],style:({ zIndex: _vm.zIndex && _vm.zIndex }),on:{"keyup":function($event){return _vm.$emit('keyup', $event)}}},[_c('div',{staticClass:"dialog-modal-outer",class:{
      [`position-top`]: _vm.position == 'top',
      [`color-` + _vm.color]: _vm.color != 'primary',
      [`no-padding`]: _vm.padding === false,
    }},[(_vm.title)?_c('div',{staticClass:"dialog-modal-header",class:_vm.headerClass},[(_vm.isMobile || _vm.isTablet)?_c('div',{staticClass:"mobile-dialog-close",on:{"click":_vm.close}},[_c('Icon',{attrs:{"name":"times","family":"fal"}})],1):_vm._e(),_c('h1',{staticClass:"text-truncate mr-4",attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.isMobile && !_vm.isTablet)?_c('a',{directives:[{name:"shortkey",rawName:"v-shortkey.once",value:(['esc']),expression:"['esc']",modifiers:{"once":true}}],staticClass:"dialog-close",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.close()},"shortkey":function($event){return _vm.close()},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.close()}}},[_c('Icon',{attrs:{"family":"fas","name":"times-circle"}})],1):_vm._e()]):_vm._e(),(_vm.backButton && !_vm.isMobile && !_vm.isTablet)?_c('div',{staticClass:"dialog-modal-back",on:{"click":_vm.backButtonCallback}},[_c('Icon',{attrs:{"name":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e(),_c('div',{class:_vm.bodyClassName === '' ? 'dialog-modal-body' : _vm.bodyClassName},[_vm._t("default")],2),(_vm.buttons)?_c('div',{staticClass:"dialog-modal-footer",class:[
        {
          'd-flex justify-content-end align-items-center':
            !_vm.isMobile && !_vm.isTablet,
        },
        _vm.footerClass,
      ]},[_c('div',{staticClass:"dialog-modal-hint",class:{ 'mr-auto': !_vm.hasButtonsLeft }},[_vm._t("left-button-hint")],2),(_vm.btnWithConfirmLeft)?_c('v-popover',{staticClass:"btn-left",attrs:{"open":_vm.showBtnWithConfirmLeft},on:{"hide":function($event){_vm.showBtnWithConfirmLeft = false}}},[(_vm.btnWithConfirmLeft.link)?_c('a',{staticClass:"btn btn-link",class:{ 'btn-link-danger': _vm.btnWithConfirmLeft.danger },attrs:{"tabindex":"0"},on:{"click":function($event){_vm.showBtnWithConfirmLeft = !_vm.showBtnWithConfirmLeft},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();_vm.showBtnWithConfirmLeft = !_vm.showBtnWithConfirmLeft}}},[_vm._v(" "+_vm._s(_vm.btnWithConfirmLeft.label)+" ")]):_c('button',{staticClass:"btn wide btn-left btn-outline-primary"},[_vm._v(" "+_vm._s(_vm.btnWithConfirmLeft.label)+" ")]),_c('Alert',{attrs:{"slot":"popover","visible":_vm.showBtnWithConfirmLeft,"button-type":"danger","buttons":[
            {
              label: _vm.btnWithConfirmLeft.confirmBtn1,
              callback: _vm.btnWithConfirmLeft.callback,
            },
            {
              label: _vm.btnWithConfirmLeft.confirmBtn2,
              outline: true,
              callback: function () {},
            },
          ]},slot:"popover"},[_vm._v(_vm._s(_vm.btnWithConfirmLeft.confirmLabel)+"?")])],1):_vm._e(),_vm._l((_vm.buttonsPrimary),function(button,index){return (!button.hidden)?_c('button',{key:index,staticClass:"btn wide",class:{
          [`btn-outline-primary`]: button.outline,
          [`btn-danger`]: button.danger,
          [`btn-primary`]:
            button.outline != true &&
            button.link != true &&
            button.danger != true,
          [`btn-left`]: button.left,
          [`btn-link`]: button.link,
          [`btn-focus-${index}`]: true,
        },attrs:{"disabled":button.disabled},on:{"click":function($event){return _vm.callback($event, button.callback ? button.callback : null)}}},[(button.icon)?_c('Icon',{attrs:{"family":"fas","name":button.icon}}):_vm._e(),_vm._v(" "+_vm._s(button.label)+" ")],1):_vm._e()}),(_vm.btnWithConfirmRight)?_c('v-popover',{attrs:{"open":!_vm.disableAlert,"disabled":_vm.disableAlert}},[_c('button',{staticClass:"btn wide",class:{
            [`btn-primary`]:
              _vm.btnWithConfirmRight.outline != true &&
              _vm.btnWithConfirmRight.link != true,
            [`btn-outline-primary`]: _vm.btnWithConfirmRight.outline,
            [`btn-link`]: _vm.btnWithConfirmRight.link,
          },on:{"click":function($event){_vm.disableAlert = false}}},[_vm._v(" "+_vm._s(_vm.btnWithConfirmRight.label)+" ")]),_c('Alert',{attrs:{"slot":"popover","visible":!_vm.disableAlert,"buttons":[
            {
              label: _vm.btnWithConfirmRight.confirmBtn1,
              callback: () => {
                _vm.disableAlert = true;
                this.$emit('userFeedback', 'save');
              },
            },
            {
              label: _vm.btnWithConfirmRight.confirmBtn2,
              outline: true,
              callback: () => {
                _vm.disableAlert = true;
                this.$emit('userFeedback', 'close');
              },
            },
          ]},slot:"popover"},[_vm._v(_vm._s(_vm.btnWithConfirmRight.confirmLabel))])],1):_vm._e()],2):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }