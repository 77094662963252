<template>
  <div
    ref="item"
    v-click-outside="() => (hasFocus = false)"
    class="file-list-item file-list-item--file"
    role="row"
    :data-path="item.path"
    :class="{
      'file-list-item--selected': isSelected,
      'file-list-item--dragging': draggingOver,
      'file-list-item--focused': hasFocus,
      'file-list-item--expanded': expanded,
      'selection-made': hasSelection,
      'file-list-item--deleting': isDeleting,
      'file-list-item--search': hasSearchResults,
      'file-list-item--highlight': hasHighlight,
      'file-list-item--kb-focused': isKbFocused,
      'file-list-item--compact': isCompactMode,
      'no-hover': isPicker,
      'pointer-none': disableHover,
    }"
  >
    <!-- region File Star -->
    <FileItemStarAdd
      :item="item"
      :has-search-results="hasSearchResults"
      :is-picker="isPicker"
      :selection="selection"
      :is-public-share="isPublicShare"
      @onDragEnd="onDragEnd"
      @toggleStarred="toggleStarred"
    />
    <!-- endregion -->

    <!-- region Select Checkbox-->
    <div
      v-if="!isPicker && !isMobile && !isTablet"
      ref="selectCheckbox"
      tabindex="0"
      class="col-checkbox"
      @contextmenu.prevent="contextMenu"
      @click="select"
      @keydown.space.stop.prevent="select"
      @keydown.enter.stop.prevent="previewFile"
    >
      <Checkbox
        :checked="isSelected"
        :label="$t('Press tab for file operations on') + ' ' + item.name"
        tabindex="-1"
        role="cell"
      />
    </div>
    <!-- endregion -->

    <!-- region Icon and File Name -->
    <div
      class="col-title"
      role="gridcell"
      aria-labelledby="genfilename"
      :class="{
        'col-title-wider': isPicker,
        'col-title--mobile': isMobile || isTablet,
        'col-title--highlight': hasHighlight,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      <Drop
        class="col-title-droppable"
        @drop="onDropFile"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @dragover="onDragOver"
      >
        <!-- region Checkbox Mobile -->
        <div
          v-if="(isMobile || isTablet) && isSelected"
          class="col-checkbox"
          @contextmenu.prevent="contextMenu"
          @click="select"
        >
          <Checkbox :checked="isSelected" :label="$t('Select')" />
        </div>
        <!-- endregion -->

        <!-- region File/Directory Icon -->
        <Icon
          v-if="item.type === 'dir' && !isSelected"
          class="file-icon file-icon--folder"
          :subicon="itemIcon(item)"
          family="fas"
          :name="
            isSharedWithMeHome ? 'user' : isBackupFolder ? 'archive' : 'folder'
          "
        />
        <FileIcon
          v-else-if="!isSelected"
          ref="fileIcon"
          :name="item.name"
          :item="item"
          :format="item.format"
          :path="item.path"
          :subicon="itemIcon(item)"
          :size="thumbSize"
        />
        <!-- endregion -->
        <!-- region Renaming Input -->
        <input
          v-if="isRenaming"
          ref="filenameInput"
          v-model="newName"
          :aria-label="$t('New file name')"
          type="text"
          class="form-control rename-input"
          @blur="onRename"
          @keyup.escape="onCancelRename"
          @keyup.enter="onRename"
        />
        <!-- endregion -->

        <!-- region File Details -->
        <div
          ref="titlendetails"
          v-tooltip="isEllipsisActive ? item.name : ''"
          v-a11ybutton="previewFile"
          class="title-n-details"
          :class="{ ['is-renaminig']: isRenaming }"
          :tabindex="isPicker && isKbFocused ? '0' : '-1'"
          role="table"
        >
          <!-- region File Name   -->
          <Themed
            v-if="!isRenaming"
            ref="file-title"
            as="h4"
            role="row"
            text-colorr
            @click.stop="previewFile"
          >
            <!-- File Name -->
            <span role="gridcell" aria-labelledby="genfilename">{{
              item.name
            }}</span>
            <!-- Color tag -->
            <FileListItemColorDot :color="item.colortag" />
            <!-- Size and date Mobile -->
            <small class="d-md-none">
              {{ item.size && item.size.length > 0 ? item.size + ' - ' : '' }}
              {{ mobileDateFormat }}
            </small>
          </Themed>
          <!-- endregion -->

          <!-- region Search Highlight -->
          <div
            v-if="hasHighlight"
            class="file-list-item--highlight pt-4 w-100"
            :class="{
              'text-truncate':
                !hasSearchResults || (hasSearchResults && !isTablet),
            }"
            v-html="item.highlight"
          >
            {{ highlightText }}
          </div>
          <!-- endregion -->

          <!-- region File Path -->
          <div v-if="hasSearchResults">
            <a @click="browse">
              <ol
                class="breadcrumb pb-0"
                :class="hasHighlight ? 'pt-4' : 'pt-3'"
              >
                <li class="breadcrumb-item active fc-explorer--home">
                  <div>
                    <Icon family="fas" name="home" />
                  </div>
                </li>
                <li
                  v-for="(pathElement, index) in separatePaths(item)"
                  :key="pathElement + index"
                  class="breadcrumb-item"
                >
                  <Icon
                    family="fas"
                    name="chevron-right"
                    class="breadcrumb-seperator small-chevron pl-0 pr-0"
                  />
                  <a class="not-link pointer-link pl-0 ml-0">
                    {{ pathElement }}
                  </a>
                </li>
              </ol>
            </a>
          </div>
          <!-- endregion -->

          <!-- region Unable to find it!?!? -->
          <div class="file-list-item-details">
            <span
              v-if="typeof item.size === 'string'"
              role="rowgroup"
              :aria-label="'file size:' + item.size"
              class="file-list-item-details-size"
              >{{ item.size }} ' - '
            </span>
            <span
              v-if="typeof item.modified === 'string'"
              role="rowgroup"
              :aria-label="'Last modified: ' + hijriDate(item)"
              class="file-list-item-details-modified"
            >
            </span>
          </div>
          <!-- endregion -->

          <!-- region Information Icons -->
          <ul
            v-if="
              (isSelected && isMobile) ||
              (isTablet && hasSearchResults && !isTablet)
            "
            class="file-details"
          >
            <li
              v-if="item.canmanageshare"
              v-tooltip="item.canmanageshare ? $t('Managed Share') : ''"
              class="roll-over"
              :class="{ active: item.canmanageshare }"
            >
              <Icon name="share-alt-square" family="fas" />
            </li>
            <li
              v-else
              v-tooltip="$t(getShareInfo())"
              class="roll-over"
              :class="{ active: typeof item.isshared === 'string' }"
            >
              <Icon name="share-alt" family="fas" />
            </li>
            <li
              v-tooltip="
                item.tagged
                  ? $t('Item has') +
                    ' ' +
                    item.tagged +
                    ' ' +
                    $t('metadata sets')
                  : ''
              "
              class="roll-over"
              :class="{ active: item.tagged }"
            >
              <Icon name="tag" family="fas" />
            </li>
            <li
              v-tooltip="
                item.hasnotificationsrule ? $t('Notification rule added') : ''
              "
              class="roll-over"
              :class="{ active: item.hasnotificationsrule }"
            >
              <Icon name="bell" family="fas" />
            </li>
            <li
              v-tooltip="
                item.commented
                  ? $tc(
                      'Item has {count} comment | Item has {count} comments',
                      item.commented
                    )
                  : ''
              "
              class="roll-over"
              :class="{ active: item.commented }"
            >
              <Icon name="comment" family="fas" />
            </li>
            <li
              v-tooltip="lockedRead(item)"
              class="roll-over"
              :class="{ active: item.locked }"
            >
              <Icon name="lock" family="fas" />
            </li>
          </ul>
          <!-- endregion -->
        </div>
        <!-- endregion -->
      </Drop>
    </div>
    <!-- endregion -->

    <!-- region Context Menu -->
    <FileItemOptionsContextMenu
      v-if="hoverMenu"
      :item="item"
      :hasCustomList="hasCustomList"
      :fromHome="fromHome"
      :docPreview="docPreview"
      :isReadLocked="!!isReadLocked"
      :isInRecycleFolder="isInRecycleFolder"
      :isShareDisabled="isShareDisabled"
      :hasDirectLink="hasDirectLink"
      :isPublicShare="isPublicShare"
      :isLockedByOthers="isLockedByOthers"
      :isLockDisabled="isLockDisabled"
      :isWebEditEnabled="isWebEditEnabled"
      :canEditFileType="canEditFileType"
      :isWOPIEditEnabled="isWOPIEditEnabled"
      :desktopEditorClientName="desktopEditorClientName"
      :editorClientLength="editorClientLength"
      :isDeleting="isDeleting"
      :isSharedWithMe="isSharedWithMe"
      :is-compact-mode="isCompactMode"
      :direct-link="directLink"
      :hasSearchResults="hasSearchResults"
      @lockFile="lockFile"
      @previewFile="previewFile"
      @browse="browse"
      @downloadFile="downloadFile"
      @copyFile="copyFile"
      @moveFile="moveFile"
      @toggleMoreOptions="toggleMoreOptions"
      @shareFile="shareFile"
      @triggerAction="triggerAction"
      @changeFocus="changeFocus"
      @runQuickEdit="runQuickEdit"
      @doDeleteFile="doDeleteFile"
      @unlockFile="unlockFile"
      @quickEditFile="quickEditFile"
      @fetchDirectLink="fetchDirectLink"
      @restoreFile="restoreFile"
    />
    <!-- endregion -->

    <!-- region Last Modified Info for Search-->
    <div
      v-if="searchLastModifyInfo && !hasSearchResults"
      role="gridcell"
      :aria-label="'Last modified: ' + hijriDate(item)"
      class="col-info m-text"
      :class="{
        'recent-mod': recentlyModified(item.modified),
        'd-flex': disableHoverContext,
      }"
      @click="expand"
      @contextmenu.prevent="contextMenu"
    >
      <h4>
        <DateDisplay :date="hijriDate(item)" class="d-inline" />

        <span class="recent-dot"></span>
        <small
          v-if="item.lastmodifiedby && typeof item.lastmodifiedby === 'string'"
          class="small-text"
          >{{ $t('by') }}
          {{
            item.lastmodifiedby === user.peerid
              ? $t('you')
              : item.lastmodifiedby
          }}</small
        >
      </h4>
    </div>
    <!-- endregion --->

    <!-- region Last Modify Info -->
    <div
      v-if="
        !hasCustomList &&
        typeof item.modified == 'string' &&
        !isMobile &&
        !hasSearchResults
      "
      class="col-info m-text"
      role="gridcell"
      :aria-label="'Last modified: ' + hijriDate(item)"
      :class="{
        'recent-mod': recentlyModified(item.modified),
        'd-flex': disableHoverContext,
      }"
      @click="expand"
      @contextmenu.prevent="contextMenu"
    >
      <h4>
        <DateDisplay :date="hijriDate(item)" class="d-inline" />
        <span class="recent-dot"></span>
        <small
          v-if="item.lastmodifiedby && typeof item.lastmodifiedby === 'string'"
          class="small-text"
          >{{ $t('by') }}
          {{
            item.lastmodifiedby === user.peerid
              ? $t('you')
              : item.lastmodifiedby
          }}</small
        >
      </h4>
    </div>
    <!-- endregion -->

    <!-- region Size Info -->
    <div
      v-if="sizeInfo"
      class="col-info"
      role="gridcell"
      :aria-label="'file size:' + item.size"
      :class="{
        'd-flex': disableHoverContext,
      }"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      {{ item.size && item.size.length > 0 ? item.size : '' }}
    </div>
    <!-- endregion -->

    <!-- region Custom List or Search Results -->
    <div
      v-if="searchSizeInfo"
      class="col-info"
      role="gridcell"
      :aria-label="'file size:' + item.size"
      @contextmenu.prevent="contextMenu"
      @click="expand"
    >
      {{ item.size && item.size.length > 0 ? item.size : '' }}
    </div>
    <!-- endregion -->

    <!-- region File Info Icons -->
    <FileItemInfoIcons
      :item="item"
      :has-search-results="hasSearchResults"
      :is-picker="isPicker"
      :selection="selection"
      :is-public-share="isPublicShare"
      :get-share-info="$t(getShareInfo())"
      @onDragEnd="onDragEnd"
      @toggleStarred="toggleStarred"
    />
    <!-- endregion -->

    <!-- region Mobile More Options (Menu) -->
    <div
      v-if="(isMobile || isTablet) && !isPicker"
      class="col-more-options--mobile cursor-pointer"
      role="group"
      @click="openMobileMenu"
    >
      <Icon name="ellipsis-v" class="more-options" />
    </div>
    <!-- endregion -->
  </div>
</template>

<script>
import FileItemMixin from './FileItemMixin';
export default {
  mixins: [FileItemMixin],
};
</script>
