<template>
  <div class="col-sm-12 col-lg-4 text-center text-lg-left">
    <a href="#" class="forgot-password link" @click="resetPassword">
      <Icon class="fa--grey d-none d-lg-inline" name="angle-right" />{{
        $t('Forgot Password')
      }}
    </a>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'ForgotPassword',
  components: {
    Icon,
  },
  methods: {
    async resetPassword() {
      this.$emit('resetPassword');
    },
  },
};
</script>
