<template>
  <div>
    <input
      v-if="!readOnly"
      v-model="editing"
      :disabled="metadata.write !== 1"
      type="text"
      class="form-control meta-input form-control-sm mb-2"
      :required="metadata.required === 1"
      @keydown.enter="addTag"
    />
    <span
      v-for="tag in tagToArray"
      :key="tag.idx"
      class="badge badge-secondary"
    >
      {{ tag.text }}
      <button
        v-if="metadata.write === 1 && !readOnly"
        v-tooltip="$t('Remove tag')"
        :aria-label="$t('Remove tag')"
        class="fas fa-times-circle"
        @click="removeTag(tag.idx)"
      />
    </span>
  </div>
</template>

<script>
import dataTypeMixin from './_dataTypeMixin';

export default {
  mixins: [dataTypeMixin],
  data() {
    return {
      editing: '',
    };
  },
  computed: {
    fieldValue() {
      if (typeof this.field.value === 'object') return '';
      return this.field.value;
    },
    tagToArray() {
      if (this.fieldValue) {
        let arr =
          this.field.value && typeof this.field.value === 'string'
            ? this.field.value.split(',')
            : [];

        return arr.map((item, index) => {
          if (item.length > 0) {
            return { text: item, idx: index };
          }
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    field: {
      deep: true,
      handler() {
        this.onFieldChange();
      },
    },
  },
  methods: {
    addTag(event) {
      event.preventDefault();

      let currMetaVal = this.field.value;
      const tags = this.editing
        .split(',')
        .filter((el) => el != '')
        .join(',');

      currMetaVal = currMetaVal.length ? `${currMetaVal},${tags}` : tags;

      this.field.value = currMetaVal;
      this.$emit('input', { ...this.field, value: currMetaVal });
      this.editing = '';
    },
    removeTag(tagidx) {
      let arr = this.field.value.split(',');
      arr.splice(tagidx, 1);
      const str = !arr.join(',').length ? {} : arr.join(',');

      this.field.value = str;
      this.$emit('input', { ...this.field, value: str });
    },
    onFieldChange() {
      const changed = String(this.field.value) != String(this.field.oldValue);
      this.$emit('onFieldChange', {
        id: this.field.attributeid,
        changed,
        name: 'array',
      });
    },
  },
};
</script>
