/**
 * Encode URL in a specific format compatible with FC backend
 * Docconverter relies on this function to send request
 * @param {String} str
 * @returns {String}
 */
export function encodeAll(str) {
  return encodeURIComponent(str)
    .replace(/%20/g, '+')
    .replace(/'/g, '%27')
    .replace(/!/g, '%21')
    .replace(/\*/g, '%2A')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\./g, '%2E')
    .replace(/~/g, '%7E')
    .replace(/-/g, '%2D')
    .replace(/_/g, '%5F');
}

/**
 * Decode URL from FC backend
 * @param {String} str
 * @returns {String}
 */
export function decodeAll(str) {
  return decodeURIComponent(
    str
      .replace(/\+/g, '%20')
      .replace(/%27/g, "'")
      .replace(/%21/g, '!')
      .replace(/%2A/g, '*')
      .replace(/%28/g, '(')
      .replace(/%29/g, ')')
      .replace(/%2E/g, '.')
      .replace(/%7E/g, '~')
      .replace(/%2D/g, '-')
      .replace(/%5F/g, '_')
  );
}

/**
 * Encode URL in a specific format compatible with both FC UI
 * @param {String} str
 * @returns {String}
 */
export function encodeURL(str) {
  return (
    encodeURI(str)
      // , / ? : @ & = + $ # are node encoded by encodeURI
      .replace(/#/g, '%23')
      .replace(/\+/g, '%2B')
  );
}

/**
 * Encode URL in a specific format compatible with both FC UI
 * @see https://stackoverflow.com/questions/7449588/why-does-decodeuricomponent-lock-up-my-browser
 * @param {String} str
 * @returns {String}
 */
export function decodeURL(str) {
  return decodeURI(
    str
      // , / ? : @ & = + $ # are node decoded by decodeURI
      .replace(/%23/g, '#')
      .replace(/%2B/g, '+')
      .replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25') //replace % not followed by 2 hex digits
  );
}

/**
 * Perform navigation to encoded URL
 * Only perform the navigation if it's a different path
 * @param {VueRouter} router - vue-router instance to perform navigation
 * @param {String} path - path to navigate
 * @param {String} prefix - prefix to be added to route
 */
export function navigateToEncodedURL(router, path, prefix) {
  const encodedURL = prefix + encodeURL(path);
  const notEncodedURL = prefix + path;
  const currentPath = router.history.current.path;

  const sameRoute = encodedURL === currentPath || notEncodedURL === currentPath;

  if (!sameRoute) {
    router.push(encodedURL);
  }
}
