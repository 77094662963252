<template>
  <div class="droppable-wrapper droppable-wrapper--empty">
    <div v-if="lists[listName]" class="empty-box">
      <Icon family="fal" :name="lists[listName].icon" />
      <div class="empty-text">
        {{ $t(lists[listName].text) }}
      </div>
    </div>

    <div v-else class="droppable-box empty-box">
      <div class="empty-box">
        <Icon family="fas" :name="emptyFolderMessage.iconName" />
        <div class="empty-text">
          {{ emptyFolderMessage.emptyText }}
        </div>
        <p>{{ emptyFolderMessage.emptyDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'FileListEmpty',
  components: {
    Icon,
  },
  props: {
    listName: {
      type: String,
      default: '',
    },
    folderErrorMessage: {
      type: String,
      default: '',
    },
    folderHasError: {
      type: Boolean,
      default: false,
    },
    isRecycleBin: {
      type: Boolean,
      default: false,
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
    filterValue: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      lists: {
        recentDocuments: {
          icon: 'clock',
          text: 'No recent documents',
        },
        starredDocuments: {
          icon: 'star',
          text: 'No starred documents',
        },
      },
    };
  },
  computed: {
    emptyFolderMessage() {
      if(this.folderHasError) {
        return {
          iconName: 'folder-times',
          emptyText: this.$t("Couldn't get file list."),
          emptyDescription: this.folderErrorMessage
        }
      } else if (this.filterValue) {
        return {
          iconName: 'file-search',
          emptyText: this.$t('No files or folder found') ,
          emptyDescription: this.$t('None of the files matched this search.') 
        }
      } else {
        return {
          iconName: 'folder',
          emptyText: this.$t('This folder is empty'),
          emptyDescription: !this.isRecycleBin && this.canUpload ? this.$t('drop some files to upload') : ""
        }
      }
    }
  },
};
</script>
