export const WOPIClients = {
  ONLYOFFICE: {
    client: 'ONLYOFFICE',
    label: 'OnlyOffice',
    fullLabel: 'Open with OnlyOffice',
    iconClass: 'onlyoffice-icon',
    key: 'onlyoffice',
  },
  CODE: {
    client: 'CODE',
    label: 'Collabora CODE',
    fullLabel: 'Open with Collabora Code',
    iconClass: 'collabora-icon',
    key: 'collabora',
  },
  OFFICEONLINE: {
    client: 'OFFICEONLINE',
    label: 'Office Online',
    fullLabel: 'Open with Office Online',
    iconClass: 'office-icon',
    key: 'office',
  },
  OFFICE: {
    client: 'OFFICE',
    label: 'Office Online',
    fullLabel: 'Open with Office Online',
    iconClass: 'office-icon',
    key: 'office',
  },
};

export const GoogleDocsFormats = {
  excel: {
    client: 'excel',
    label: 'Google Sheets',
    fullLabel: 'Open with Google Sheets',
    iconClass: 'google-sheets-icon',
    iconComponent: 'GoogleSheets',
  },
  word: {
    client: 'word',
    label: 'Google Docs',
    fullLabel: 'Open with Google Docs',
    iconClass: 'google-docs-icon',
    iconComponent: 'GoogleDocs',
  },
  powerpoint: {
    client: 'powerpoint',
    label: 'Google Slides',
    fullLabel: 'Open with Google Slides',
    iconClass: 'google-slides-icon',
    iconComponent: 'GoogleSlides',
  },
};
