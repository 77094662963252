<template>
  <div>
    <VueTypeaheadBootstrap
      id="userSearch"
      ref="userAutocomplete"
      :value="value"
      class="mb-2"
      :data="users"
      :serializer="(item) => item.emailid || item.username"
      :show-all-results="true"
      :placeholder="$t('Search Users...')"
      @input="$emit('input', $event)"
      @hit="addEmail($event)"
    >
      <!--Begin custom suggestion slot-->
      <template slot="suggestion" slot-scope="{ data, htmlText }">
        <div class="d-flex align-items-center">
          <div class="col d-flex justfy-content-center p-0">
            <Avatar :user="data.username" size="xs" />
          </div>

          <div class="col-md-8 text-truncate">
            <span class="user-name" v-html="htmlText" />
            <br />
            <small class="text-muted">{{
              data.displayname === data.username
                ? data.displayname
                : data.displayname + ' (' + data.username + ')'
            }}</small>
          </div>
          <span class="float-right btn btn-sm btn-outline-primary btn-block">
            {{ $t('Add') }}
          </span>
        </div>
      </template>
    </VueTypeaheadBootstrap>
    <button class="d-none" />
  </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Avatar from 'common/components/Avatar';

export default {
  name: 'FcUsersParam',
  components: {
    VueTypeaheadBootstrap,
    Avatar,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    addEmail(event) {
      this.$emit('addEmail', event);
      this.$nextTick(this.focus);
    },
    removeEmail(type, idx, event) {
      this.$emit('removeEmail', type, idx, event);
    },
    focus() {
      this.$refs.userAutocomplete.$refs.input.focus();
    },
  },
};
</script>
