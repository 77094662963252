export const sizes = {
  navbarHeight: 64,
  listThumb: 90,
  gridThumb: 180,
  mediumGridThumb: 280,
  largeGridThumb: 380,
};

export const layoutTypesMap = {
  default: 'list',
  largethumbnails: 'l-grid',
  mediumthumbnails: 'm-grid',
  smallthumbnails: 'grid',
  listview: 'list',
};

export const thumbnailMap = {
  'l-grid': 'largeGridThumb',
  'm-grid': 'mediumGridThumb',
  grid: 'smallGridThumb',
  list: 'listThumb',
};

export const grids = {
  list: {
    name: 'list',
    width: 0,
    height: 0,
  },
  grid: {
    name: 'th',
    width: 200,
    height: 200,
  },
  'm-grid': {
    name: 'grip-horizontal',
    width: 280,
    height: 280,
  },
  'l-grid': {
    name: 'th-large',
    width: 400,
    height: 400,
  },
};
