<template>
  <Drop
    class="zip-viewer-explorer-drop"
    :class="{ 'zip-viewer-explorer-drop--dragging': isDraggingFile }"
    @drop="uploadDroppedItem"
    @dragenter="activateDropZone"
    @dragleave="deactivateDropZone"
  >
    <ZipViewerFileListHeader
      @sort-items="sortItems"
      @filter-items="filterItems"
    />

    <div v-if="isLoading" class="zip-viewer-explorer-body">
      <FileListLoader />
    </div>

    <div
      v-else-if="items.length || newFolderInputVisible"
      class="zip-viewer-explorer-body"
    >
      <ZipViewerFileList
        :items="items"
        :new-folder-input-visible="newFolderInputVisible"
        :can-upload="canUpload"
        :can-download="canDownload"
        @browse="(folderName) => dispatchBrowse({ path: folderName })"
        @create-new-folder="createNewFolder"
        @delete-item="deleteItem"
        @download-item="downloadItem"
        @filter-items="filterItems"
        @preview-item="previewItem"
        @sort-items="sortItems"
      />
    </div>

    <div
      v-else-if="!items.lenght && hasFilter"
      class="zip-viewer-file-list__empty"
    >
      <Icon name="file-search" family="fas" />
      <div class="zip-viewer-file-list__empty-text">
        {{ $t('No files or folder found') }}
      </div>
      <div class="zip-viewer-file-list__empty-text--small">
        {{ $t('None of the files matched this search.') }}
      </div>
    </div>

    <div v-else class="zip-viewer-file-list__empty">
      <Icon name="folder" family="fas" />
      <div class="zip-viewer-file-list__empty-text">
        {{ $t('This folder is empty') }}
      </div>
      <div class="zip-viewer-file-list__empty-text--small">
        {{ $t('drop some files to upload') }}
      </div>
    </div>
    <DialogModal
      v-if="fileReplacementConfirmationDialogVisible"
      class="dialog-modal-overlay dialog-modal-form timed-fade stick-top"
      size="small"
      z-index="1000000"
      :buttons="actionButtons"
      :class="[
        { 'dialog-modal-visible': fileReplacementConfirmationDialogVisible },
      ]"
      :title="$t('zip_preview.file_replacement.modal_title')"
      :visible="fileReplacementConfirmationDialogVisible"
      @close="toggleFileReplacementConfirmationDialog(false)"
      @keyup="$emit('keyup', $event)"
    >
      {{ $t('zip_preview.file_replacement.modal_headline') }}
      <br />
      {{ $t('zip_preview.file_replacement.modal_description') }}
    </DialogModal>
  </Drop>
</template>

<script>
import { Drop } from 'vue-drag-drop';
import { mapState, mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import ZipViewerFileList from './Body/ZipViewerFileList';
import zipViewerMixin from './zipViewerMixin';
import Icon from '../Icon';
import DialogModal from '../DialogModal';
import ZipViewerFileListHeader from './Body/ZipViewerFileListHeader.vue';
import FileListLoader from 'common/components/FileListLoader';

export default {
  name: 'ZipViewerBody',
  components: {
    ZipViewerFileList,
    Drop,
    Icon,
    DialogModal,
    ZipViewerFileListHeader,
    FileListLoader,
  },
  mixins: [zipViewerMixin],
  data() {
    return {
      hasFilter: false,
      isDraggingFile: false,
      dragCounter: 0,
      fileReplacementConfirmationDialogVisible: false,
      itemToReplace: null,
    };
  },
  computed: {
    ...mapState('zipViewer', {
      items: 'items',
      newFolderInputVisible: 'newFolderInputVisible',
    }),
    ...mapGetters('zipViewer', {
      subFolder: 'subFolder',
      canUpload: 'canUpload',
      canDownload: 'canDownload',
    }),
    actionButtons() {
      return [
        {
          label: this.$t('zip_preview.file_replacement.confirm_button'),
          callback: () => {
            this.toggleFileReplacementConfirmationDialog(false);
            this.dispatchUploadItem(this.itemsToUpload);
          },
          focus: true,
          danger: true,
        },
        {
          label: this.$t('zip_preview.file_replacement.cancel_button'),
          callback: () => this.toggleFileReplacementConfirmationDialog(false),
          outline: true,
        },
      ];
    },
    isLoading() {
      return this.$store.state.loading['zipViewer/browse'];
    },
  },
  methods: {
    ...mapActions('zipViewer', {
      dispatchBrowse: 'browse',
      dispatchCreateNewFolder: 'createNewFolder',
      dispatchDeleteItem: 'deleteItem',
      dispatchDownloadItem: 'downloadItem',
      dispatchSortItems: 'sortItems',
      dispatchToggleNewFolderInput: 'toggleNewFolderInput',
      dispatchUploadItem: 'uploadItem',
    }),

    previewItem({ items, index }) {
      this.$zippreview.open(items, index);
    },

    filterItems(filter) {
      this.hasFilter = filter ? true : false;

      this.dispatchBrowse({
        path: this.subFolder,
        filter,
      });
    },

    async deleteItem(item) {
      await this.dispatchDeleteItem({ item });
      await this.dispatchBrowse({ path: this.subFolder });
    },

    async downloadItem(item) {
      await this.dispatchDownloadItem({ item });
    },

    async createNewFolder(name) {
      if (name) {
        await this.dispatchCreateNewFolder(name);
        await this.dispatchBrowse({
          path: this.joinPaths([this.subFolder, name]),
        });
      }
      this.dispatchToggleNewFolderInput(false);
    },

    sortItems(sort) {
      this.dispatchSortItems(sort);
    },

    activateDropZone() {
      this.isDraggingFile = true;
      this.dragCounter++;
    },

    deactivateDropZone() {
      this.dragCounter--;
      if (this.dragCounter === 0) {
        this.isDraggingFile = false;
      }
    },

    toggleFileReplacementConfirmationDialog(show) {
      this.fileReplacementConfirmationDialogVisible = show;
    },

    fileAlreadyExists(name) {
      return this.items.some((item) => item.name === name);
    },

    async uploadDroppedItem(ext, event) {
      this.isDraggingFile = false;
      this.dragCounter = 0;

      const entries = await Promise.all(
        [...event.dataTransfer.items].map(async (item) =>
          this.traverseFileTree(item.webkitGetAsEntry())
        )
      );
      
      let fileAlreadyExists = false;
      for (const item of entries) {
        if (this.fileAlreadyExists(item.file.name)) {
          this.itemsToUpload = entries;
          this.toggleFileReplacementConfirmationDialog(true);
          fileAlreadyExists = true;
          break;
        }
      }
      if (!fileAlreadyExists) {
        await this.dispatchUploadItem(_.flattenDeep(entries));
      }
    },

    traverseFileTree(item, path = '') {
      return new Promise((resolve) => {
        if (item.isFile) {
          item.file((file) => {
            resolve({
              file,
              path: this.joinPaths([path, file.name]),
            });
          });
        } else if (item.isDirectory) {
          const dirReader = item.createReader();
          dirReader.readEntries(async (entries) => {
            const files = await Promise.all(
              entries.map((entry) =>
                this.traverseFileTree(entry, this.joinPaths([path, item.name]))
              )
            );
            resolve(files);
          });
        }
      });
    },
  },
};
</script>
