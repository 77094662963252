import store from '../../../store/index.js';
import router from '@/router.js';
import confetti from 'canvas-confetti';

export default {
  title: 'Dark mode',
  id: 'onboarding.themes.darkmode',
  onTopicStarted() {
    const name = router.history.current.name;

    if (name === 'settings-page') {
      store.commit('onboarding/goToStep', {
        id: 'onboarding.themes.darkmode.theme-selector',
      });
      return true; //exist from current step
    }
  },
  steps: [
    {
      id: 'onboarding.themes.darkmode.chose-operation',
      description: 'Open the user menu',
      shape: 'rectangle',
      element:
        '#navbar > div.navbar.navbar-main.flex-md.m-0.p-0.row.fixed.float-right.width-search.justify-content-end > div.pb-1.text-truncate.text-right.mt3p.pr-3 > div > div > button',
      onStepStart: () => {
        store.commit('onboarding/set', {
          type: 'isExpanded',
          value: false,
        });
      },
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.themes.darkmode.dropdown-selection',
      description: 'Navigate to Settings',
      shape: 'tooltip',
      animation: 0,
      element: () =>
        document.querySelector('div[id*="popover"] i.fa-user-cog')
          ?.parentElement,
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.themes.darkmode.theme-selector',
      shape: 'tooltip',
      animation: 1,
      description: 'Choose a theme',
      element: '.general-settings > .theme-picker .v-select',
      trigger: {
        triggerOn: 'core/set',
        onTrigger: function (payload) {
          const { key, name } = payload;
          return key === 'customization' && name === 'THEME_MODE';
        },
      },
    },
  ],
  onTopicCompleted() {
    store.commit('onboarding/set', {
      type: 'isExpanded',
      status: true,
    });

    confetti({
      particleCount: 100,
      zIndex: 9999999,
      origin: { x: 0.9, y: 1 },
    });
  },
};
