<template>
  <div>
    <div
      class="file-preview-item file-preview-item--default"
      :class="{ 'pb-0': hideDownloadButton }"
    >
      <div class="file-preview-item-info" style="max-width: 100%; width: 100%">
        <iframe
          title="cad preview frame"
          :src="'/core/cadviewer?path=' + encodeAll(file.path)"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileType } from 'common/utils/files';
import { encodeAll } from 'common/utils/URLUtils';

export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    hideDownloadButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fileType() {
      return getFileType(this.file.ext);
    },
  },
  methods: {
    encodeAll,
  },
};
</script>
<style lang="scss" scoped>
iframe {
  height: calc(100vh - 8rem - 60px);
  border-width: 0;
  width: 100%;
}
</style>