<template>
  <div class="querry-container w-100">
    <div v-if="advanced" class="header d-flex justify-content-between">
      <span class="header-label">Query</span>
      <a class="header-editor" @click="showEditor = !showEditor"
        >Text Editor (Advanced)</a
      >
    </div>
    <div v-if="showEditor">
      <textarea
        v-model="expressionInput"
        class="form-control"
        rows="6"
      ></textarea>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary mt-2" @click="emitExpression">
          Save
        </button>
      </div>
    </div>
    <VueQueryBuilder
      v-else
      v-model="query"
      :rule-types="ruleTypes"
      @change="$emit('change')"
    />
  </div>
</template>
<script>
import VueQueryBuilder from './VueQueryBuilder';
import {
  QBRegex,
  specialParsingRules,
  flagRules,
  ruleInType,
} from 'common/utils/queryBuilderRules';
import Vue from 'vue';
import _ from 'lodash';

export default {
  components: {
    VueQueryBuilder,
  },
  props: {
    advanced: {
      type: Boolean,
      default: true,
    },
    ruleTypes: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      flagRules,
      showEditor: false,
      expressionInput: '',
      query: {
        children: [],
      },
      toast: {
        message:
          '<b>' +
          this.$t('Error') +
          '</b><p role="alert">' +
          this.$t(
            'The rule expression cannot be opened in the Expression Builder, please use the text editor to directly edit the rule expression'
          ) +
          '.</p>',
        type: 'error',
      },
    };
  },
  watch: {
    query: {
      deep: true,

      // We have to move our method to a handler field
      handler() {
        this.expressionInput = this.expressionFlattener(this.query.children);
        this.$emit('input', this.expressionInput);
      },
    },
  },
  mounted() {
    Vue.nextTick(() => {
      this.queryCompatybilityCheck();
    });
  },
  methods: {
    // BUILDING STRING EXPRESSION
    expressionStringPartBuilder({ value, operator, negation }) {
      let val = value
        ?.split(',')
        .map((s) => s.trim()) // remove unwanted namespaces
        .map((s) => {
          return this.isNumeric(s) && operator.ruleString.endsWith(')')
            ? s
            : `'${s}'`;
        })
        .join(',');
      let exclamationMark = negation ? '!' : '';
      if (operator.ruleString.endsWith(')')) {
        return (
          exclamationMark +
          operator.ruleString.replace(QBRegex.regex, `(${val})`)
        );
      } else if (ruleInType.includes(operator.ruleString)) {
        // rules with 'in share.allowedGroups'
        return `'${value}' in ${operator.ruleString}`;
      } else if (val?.length > 3) {
        return this.specialParse(operator.ruleString)
          ? `${exclamationMark + operator.ruleString} ${value}` // cause it is special parse and needs to be raw value
          : `${operator.ruleString} ${exclamationMark ? '!=' : '=='} ${val}`;
      } else {
        return exclamationMark + operator.ruleString; // no value to return for special flags like isMasterAdmin
      }
    },
    expressionFlattener(expressions) {
      let ex = '(';
      expressions.map(({ query }, index) => {
        ex += index ? ` ${query.logicalOperator} ` : '';
        if (query.children?.length)
          ex += this.expressionFlattener(query.children);
        ex += !query.children ? this.expressionStringPartBuilder(query) : '';
      });
      return ex + ')';
    },

    // BUILDING OBJECT
    queryCompatybilityCheck() {
      try {
        this.buildQueryObjectExpression();
      } catch (error) {
        if (!_.isEmpty(this.value)) {
          console.log(error);
          this.$toast.open(this.toast);
          this.$emit('close');
          this.query = {};
        }
      }
    },
    buildQueryObjectExpression() {
      this.expressionInput = this.value;
      let expressionHolder = this.value.slice();
      this.query = {
        children: this.splitAndSanitizeEquation(expressionHolder).map(
          (expressionPart) => {
            let type = expressionPart.startsWith('(', 3);
            if (type) return this.buildQueryGroup(expressionPart);
            else return this.buildQueryChild(expressionPart);
          }
        ),
      };
    },
    buildQueryGroup(str) {
      let operator = str.startsWith('&') ? '&&' : '||';
      let group = {
        type: 'query-builder-group',
        logicalOperator: operator,
        query: {
          children: this.splitAndSanitizeEquation(
            str.slice(3, str.length - 1)
          ).map((obj) => {
            let type = obj.startsWith('(', 3);
            if (type) return this.buildQueryGroup(obj);
            else return this.buildQueryChild(obj);
          }),
          logicalOperator: operator,
        },
      };
      return group;
    },

    buildQueryChild(expression) {
      let operator = expression.startsWith('&') ? '&&' : '||';
      let { label, ruleString, type } = this.ruleTypes.operators.find(
        (operator) =>
          operator.ruleString.startsWith(expression.match(QBRegex.labelEx)[1])
      );
      return {
        type: 'query-builder-rule',
        query: {
          logicalOperator: operator,
          operator: {
            label: label,
            ruleString: ruleString,
            type: type,
          },
          negation:
            expression.startsWith('!') ||
            expression.charAt(3) == '!' ||
            expression.includes(' != '),
          operand: type,
          value: this.queryValue(ruleString, expression),
        },
      };
    },
    queryValue(rule, expression) {
      let value;
      // if it is a specialy parse function
      if (this.specialParse(rule))
        value = expression.slice(3).match(QBRegex.specialValueEx)[0].trim();
      // if it is a method
      else if (rule.endsWith(')')) {
        value = expression
          .match(QBRegex.methodValueEx)[0]
          .replaceAll("'", '')
          .replaceAll(')', '')
          .replaceAll('(', '')
          .split(',')
          .toString();
      } else if (!flagRules.includes(rule)) {
        // if it is a equation
        value = expression
          .match(QBRegex.equationValueEx)[1]
          .replaceAll("'", '')
          .split(',')
          .toString();
      }
      return value;
    },
    splitAndSanitizeEquation(equation) {
      if (!equation.startsWith('(')) {
        equation = `(${equation})`;
      }

      let sanitzedEquation = equation
        .replaceAll(QBRegex.removeUnwantedWhitespacesTwoEx, '))')
        .replaceAll(QBRegex.removeUnwantedWhitespacesEx, '((')
        .replaceAll(/\|\|/gi, ';||')
        .replaceAll(/&&/gi, ';&&')
        .slice(1)
        .split(';')
        .filter(Boolean);
      let splitedEquationArr = [];
      let depth = 0;
      sanitzedEquation[0] = '&& ' + sanitzedEquation[0]; // align first element to mach pattern
      sanitzedEquation.forEach((element) => {
        // groups builder function based on number of '(' and ')' in expression
        if (depth > 0) {
          splitedEquationArr[splitedEquationArr.length - 1] += element;
        } else {
          splitedEquationArr.push(element);
        }
        depth +=
          (element.match(/\(/g)?.length || 0) -
          (element.match(/\)/g)?.length || 0);
      });
      return splitedEquationArr;
    },
    // OTHERS
    isNumeric(str) {
      if (typeof str != 'string') return false; // we only process strings!
      return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
      ); // ...and ensure strings of whitespace fail
    },
    specialParse(rule) {
      return (
        specialParsingRules.includes(rule) ||
        rule.startsWith('_var') ||
        rule.startsWith('_cxt')
      );
    },
    emitExpression() {
      this.$emit('input', this.expressionInput);
      this.$emit('change');
      Vue.nextTick(() => {
        this.queryCompatybilityCheck();
      });

      this.showEditor = !this.showEditor;
    },
  },
};
</script>
<style lang="scss" scoped>
.header {
  margin: 6px 0 0;
}
.header-label {
  font-family: Inter;
  font-weight: bold;
  font-size: 14px;

  color: var(--text-mid-dark);
}
.header-editor {
  font-size: 14px;
}
.querry-container {
  width: 100%;
  background: var(--bg-medium);
}
::v-deep.form-control.round-btn {
  font-size: 12px;
  color: var(--bg-light) !important;
  width: 72px;
  padding: 3px 5px 3px 11px;
  background: var(--text-light);
  border-radius: 26px;
  border: 0;
  border-right: 5px solid var(--text-light);
  :focus {
    box-shadow: 0 0 0 0 black !important;
  }
}
::v-deep.card {
  background: var(--bg-light--transparent);
}
::v-deep.btn.btn-primary.round-add-btn {
  font-size: 12px;
  width: 64px;
  padding: 4px 3px 3px;
  background: var(--bg-mid-medium);
  border-radius: 26px;
  color: var(--text-light) !important;
  border: 0px;
  margin: 0 7px 7px;
  &:hover {
    background: var(--fc-blue);
    color: var(--bg-light) !important;
  }
}
</style>
