<template>
  <div class="workflow-sidebar workflow-sidebar--variables">
    <div class="variables__wrapper">
      <div class="variables__header">
        {{ $t('Insert Variables') }}
        <span @click="$emit('closeVariables')">
          <Icon class="close-icon" family="fas" name="times-circle" />
        </span>
      </div>
      <div class="variables__body">
        <nav>
          <div id="nav-tab" class="nav nav-tabs" role="tablist">
            <button
              v-if="!isCondition"
              id="nav-event-tab"
              class="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-event"
              type="button"
              role="tab"
              aria-controls="nav-event"
              aria-selected="false"
              :class="{ active: currentTab === 'event' }"
              @click="currentTab = 'event'"
            >
              {{ $t('Start Event') }}
            </button>
            <button
              v-else
              id="nav-operations-tab"
              class="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-operations"
              type="button"
              role="tab"
              aria-controls="nav-event"
              aria-selected="false"
              :class="{ active: currentTab === 'operations' }"
              @click="currentTab = 'operations'"
            >
              {{ $t('Operations') }}
            </button>
            <button
              id="nav-context-tab"
              class="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-context"
              type="button"
              role="tab"
              aria-controls="nav-context"
              aria-selected="false"
              :class="{ active: currentTab === 'context' }"
              @click="currentTab = 'context'"
            >
              {{ $t('Context') }}
            </button>
            <button
              id="nav-variables-tab"
              class="nav-link"
              data-bs-toggle="tab"
              data-bs-target="#nav-variables"
              type="button"
              role="tab"
              aria-controls="nav-variables"
              aria-selected="true"
              :class="{ active: currentTab === 'variables' }"
              @click="currentTab = 'variables'"
            >
              {{ $t('Workflow') }}
            </button>
          </div>
        </nav>
        <div id="nav-tabContent" class="tab-content">
          <div
            v-if="currentTab === 'variables'"
            id="nav-variables"
            class="tab-pane"
            role="tabpanel"
            aria-labelledby="nav-variables-tab"
            :class="{ active: currentTab === 'variables' }"
          >
            <button
              v-if="!isAddingNewVar && !isEditingVar"
              class="btn btn-outline-primary btn-sm"
              @click="isAddingNewVar = true"
            >
              <Icon family="fas" name="plus-circle" class="mr-1" />
              {{ $t('Add New Variable') }}
            </button>
            <form
              v-if="isAddingNewVar || isEditingVar"
              @submit.prevent
              @keyup.prevent.enter="addVar"
            >
              <span class="form-label">{{
                isAddingNewVar
                  ? $t('Add New Variable')
                  : $t('Edit Variable ') + newVar.name
              }}</span>
              <InputBox
                id="name"
                v-model="newVar.name"
                class="px-0"
                type="text"
                form-size="sm"
                :errors="errors"
                :placeholder="$t('Variable name')"
                :disabled="savingVar"
              />
              <div
                class="form-group"
                :class="{ [`is-invalid`]: errors['type'] }"
              >
                <div class="input-group">
                  <div
                    class="form-control form-control--select form-control-sm"
                  >
                    <select
                      v-model="newVar.type"
                      :disabled="savingVar"
                      @change="typeChanged"
                    >
                      <option value="" disabled selected>
                        {{ $t('Variable type') }}
                      </option>
                      <option
                        v-for="(type, index) in varTypes"
                        :key="index"
                        :value="type"
                      >
                        {{ type }}
                      </option>
                    </select>
                  </div>
                </div>
                <small v-if="errors['type']" class="form-text text-danger">{{
                  errors['type'].message
                }}</small>
              </div>
              <input
                v-model="newVar.value"
                type="text"
                class="form-control form-control-sm"
                :placeholder="$t('Default value')"
                :disabled="savingVar"
                @keydown="checkInt"
              />
              <div class="text-right mt-4">
                <a
                  href="#"
                  class="btn btn-sm btn-link mr-2"
                  @click.prevent="cancelAddVar"
                >
                  {{ $t('Cancel') }}
                </a>
                <button
                  class="btn btn-sm btn-primary"
                  :disabled="savingVar"
                  type="submit"
                  @click="addVar"
                >
                  <Icon
                    v-if="savingVar"
                    name="spinner"
                    animation="spin"
                    family="fal"
                  />
                  {{ $t('Save') }}
                </button>
              </div>
            </form>
            <WorkflowVariablesTable
              class="mt-4"
              :current="'variables'"
              :field-type="fieldType"
              @addVar="addVariableToField"
              @deleteVar="deleteVar"
              @editVar="editVar"
            />
          </div>
          <div
            v-if="!isCondition || currentTab === 'event'"
            id="nav-event"
            class="tab-pane"
            role="tabpanel"
            aria-labelledby="nav-objects-tab"
            :class="{ active: currentTab === 'event' }"
          >
            <WorkflowVariablesTable
              :current="'event'"
              :field-type="fieldType"
              @addVar="addVariableToField"
            />
          </div>
          <div
            v-if="currentTab === 'context'"
            id="nav-context"
            class="tab-pane"
            role="tabpanel"
            aria-labelledby="nav-context-tab"
            :class="{ active: currentTab === 'context' }"
          >
            <WorkflowVariablesTable
              :current="'context'"
              :field-type="fieldType"
              @addVar="addVariableToField"
            />
          </div>
          <div
            v-if="currentTab === 'operations'"
            id="nav-operations"
            class="tab-pane"
            role="tabpanel"
            aria-labelledby="nav-operations-tab"
            :class="{ active: currentTab === 'operations' }"
          >
            <WorkflowOperationsTable @addVar="addVariableToField" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import InputBox from 'common/components/InputBox';
import WorkflowVariablesTable from '@/components/Tables/WorkflowVariablesTable';
import WorkflowOperationsTable from '@/components/Tables/WorkflowOperationsTable';

import { validationSchema } from '@/constants/workflows';
import Validator from 'fastest-validator';

export default {
  name: 'WorkflowVariables',
  components: {
    Icon,
    InputBox,
    WorkflowVariablesTable,
    WorkflowOperationsTable,
  },
  props: {
    fieldType: {
      type: String,
      default: 'string',
    },
    isCondition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: 'event',
      isAddingNewVar: false,
      isEditingVar: false,
      savingVar: false,
      newVar: {
        index: null,
        name: '',
        type: '',
        value: '',
      },
      varTypes: ['string', 'integer', 'bool', 'email'],
      validator: null,
      errors: {},
    };
  },
  created() {
    this.validator = new Validator({
      messages: {
        stringPattern: this.$t('workflow.variables.errors.new_variable_name'),
      },
    });
  },
  mounted() {
    document.body.insertBefore(this.$el, document.body.firstChild);
  },
  destroyed() {
    //remove if still present after destroyed
    if (document.body.contains(this.$el)) {
      document.body.removeChild(this.$el);
    }
  },
  methods: {
    mapErrors(errors) {
      let response = {};
      if (typeof errors !== 'object') return response;
      errors.forEach(({ actual, expected, field, message }) => {
        response[field] = {
          message,
          meta: {
            actual,
            expected,
            field,
          },
        };
      });
      return response;
    },
    cancelAddVar() {
      this.newVar.index = null;
      this.newVar.name = '';
      this.newVar.type = '';
      this.newVar.value = '';
      this.isAddingNewVar = false;
    },
    goToTab(tab) {
      this.currentTab = tab;
    },
    validate() {
      const isValid = this.validator.validate(
        this.newVar,
        validationSchema['new_variable']
      );

      this.errors = this.mapErrors(isValid);

      return isValid === true;
    },
    async addVar() {
      if (this.validate()) {
        this.savingVar = true;
        let nameSlug = this.newVar.name.toLowerCase();
        nameSlug = nameSlug.replace(/ /g, '_');

        const obj = {
          VARIABLE_NAME_PARAMETER: '_var.' + nameSlug,
          DISPLAY_NAME_PARAMETER: this.newVar.name,
          DATA_TYPE_PARAMETER: this.newVar.type,
          VALUE_PARAMETER: this.newVar.value,
        };

        if (this.isAddingNewVar) {
          await this.$store.dispatch('workflows/addWorkflowVariable', obj);
        } else if (this.isEditingVar) {
          await this.$store.dispatch('workflows/updateWorkflowVariable', {
            index: this.newVar.index,
            payload: obj,
          });
        }

        this.newVar.index = null;
        this.newVar.name = '';
        this.newVar.type = '';
        this.newVar.value = '';
        this.isEditingVar = false;
        this.savingVar = false;
      }
    },
    async deleteVar(index) {
      await this.$store.dispatch('workflows/deleteWorkflowVariable', index);
    },
    addVariableToField(variable) {
      this.$emit('addVar', variable);
    },
    typeChanged() {
      this.newVar.value = '';
    },
    checkInt(event) {
      if (this.newVar.type === 'integer') {
        const keyCode = event.keyCode ? event.keyCode : event.which;

        if (
          (keyCode < 48 || keyCode > 57) &&
          (keyCode < 96 || keyCode > 105) &&
          keyCode !== 8 &&
          keyCode !== 46
        ) {
          event.preventDefault();
        }
      }
    },
    editVar(index, variable) {
      this.isAddingNewVar = false;
      this.isEditingVar = true;
      this.newVar.index = index;
      this.newVar.name = variable.DISPLAY_NAME_PARAMETER;
      this.newVar.type = variable.DATA_TYPE_PARAMETER;
      this.newVar.value = variable.VALUE_PARAMETER;
    },
  },
};
</script>
