/**
 * Utility function to prevent destructuring of array
 * to object
 *
 * @param {Array | Object | null} collectionOrObject
 * @returns {Array}
 */
export function getRecords(collectionOrObject) {
  return !!collectionOrObject && collectionOrObject.constructor === Array
    ? collectionOrObject
    : Array.of(collectionOrObject);
}
