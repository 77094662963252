<template>
  <div>
    <VueTypeaheadBootstrap
      id="groupSearch"
      ref="groupAutocomplete"
      :data="groups"
      :value="value"
      class="mb-2"
      :serializer="(item) => item.groupname"
      :show-all-results="true"
      :placeholder="$t('Search Groups...')"
      @input="$emit('input', $event)"
      @hit="addGroup($event)"
    >
      <!--Begin custom suggestion slot-->
      <template slot="suggestion" slot-scope="{ data, htmlText }">
        <div class="d-flex align-items-center px-4 py-2">
          <div class="col d-flex justfy-content-center p-0">
            <Icon family="fas" name="users" />
          </div>

          <div class="col-md-8 text-truncate">
            <span class="user-name">
              {{ data.groupname }}
            </span>
            <br />
            <small class="text-muted">{{ data.membercount }} members</small>
          </div>
          <span class="float-right btn btn-sm btn-outline-primary btn-block">
            {{ $t('Add') }}
          </span>
        </div>
      </template>
    </VueTypeaheadBootstrap>
    <button class="d-none" />
  </div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import Icon from 'common/components/Icon';

export default {
  name: 'FcGroupsParam',
  components: {
    VueTypeaheadBootstrap,
    Icon,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    addGroup(event) {
      this.$emit('addGroup', event);
      this.$nextTick(this.focus);
    },
    removeEmail(type, idx, event) {
      this.$emit('removeEmail', type, idx, event);
    },
    focus() {
      this.$refs.groupAutocomplete.$refs.input.focus();
    },
  },
};
</script>
