<template>
  <DialogModal
    :visible="isOpen"
    :title="$t(`Manage Folder Level Security`)"
    :inner-dialog-class="'folder-security-dialog'"
    :body-class-name="'modal-body-acl'"
    position="top"
    :buttons="buttons"
    :class-name="higherZ"
    @close="closeModal"
  >
    <Loader v-if="loading" :loading="loading" full />
    <div v-else>
      <div
        class="folder-security-dialog pt-0 m-0 h-100 row"
        :class="{ 'container p-4': !isTablet, 'p-2': isTablet }"
      >
        <em
          class="
            inline-icon
            file-icon file-icon--folder
            fas
            fa-folder
            custom-color
          "
        ></em>
        <span class="secured-dir-text pt-1 mt-2">
          <span class="header-dir">{{ $t('Folder: ') }}</span>
          <span class="dir">{{ securedDirectoryPath }}</span>
        </span>
      </div>

      <div class="tab-wrapper col-md-12 m-0 p-0 acl-body">
        <div
          class="tab-header custom-header"
          :class="{ 'pl-4 w-100': !isTablet }"
        >
          <div
            v-a11ybutton="() => setActiveTab('security')"
            class="tab-header-title"
            tabindex="0"
            :class="{ ['active']: activeTab === 'security' }"
            @click="setActiveTab('security')"
          >
            {{ $t('Security') }}
          </div>
          <div
            v-a11ybutton="() => setActiveTab('checkaccess')"
            class="tab-header-title"
            tabindex="0"
            :class="{ ['active']: activeTab === 'checkaccess' }"
            @click="setActiveTab('checkaccess')"
          >
            {{ $t('Check Access') }}
          </div>
        </div>
        <div v-if="activeTab === 'security'" class="tab-content overflow-y">
          <div class="col-md-12 permission-header pl-4">
            <h4 class="pt-2">{{ $t('Permissions') }}</h4>
            <div class="col-md-12 row pt-0 pl-0 mb-1 mt-3">
              <div class="pt-1 pl-3">
                <CheckBox
                  v-model="inheritPermissions"
                  class="pl-4"
                  @input="updateInheritance"
                />
              </div>
              <span class="pt-1 header-dir">
                {{ $t('Inherit Folder Level Security') }}
              </span>
            </div>
          </div>
          <div class="col-md-12 tab-content-body pt-4 mb-4 px-4">
            <div
              class="item-value d-flex col-md-12 h-100 border-1 p-0 m-0 pt-2"
            >
              <securityUsergroup
                :path="securedDirectoryPath"
                :acl-meta-prop="aclMeta"
              />
            </div>
            <div v-if="inheritPermissions" class="item-caption pl-0 py-2 pt-5">
              {{ $t('Inherited Permissions') }}
            </div>
            <div
              v-if="inheritPermissions"
              class="item-value d-flex col-md-12 h-100 border-1 p-0 m-0 pt-2"
            >
              <securityUsergroup
                :path="securedDirectoryPath"
                :inherited="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 'checkaccess'" class="tab-content overflow-y">
        <div class="item-row">
          <div class="item-caption pl-4">
            {{ $t('Check Access') }}
          </div>
          <div class="item-value d-flex">
            <div class="item-value d-flex col-md-12 pt-4 p-2">
              <PermissionCheckAccess :path="securedDirectoryPath" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import CheckBox from 'common/components/Checkbox';
import PermissionCheckAccess from '../Security/PermissionCheckAccess';
import securityUsergroup from './securityUserGroup';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import Loader from 'common/components/Loader';

export default {
  components: {
    DialogModal,
    PermissionCheckAccess,
    CheckBox,
    securityUsergroup,
    Loader,
  },
  mixins: [responsivenessMixin],
  props: {
    securedDirectoryPath: {
      type: String,
      default: '',
    },
    mainScreenView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'security',
      securityData: [],
      groupPermissions: [],
      inheritPermissions: 1,
      inheritedSecurityData: [],
      aclMeta: {},
      loading: true,
      isOpen: false,
      buttons: [
        {
          label: this.$t('OK'),
          callback: this.closeModal,
        },
      ],
    };
  },
  computed: {
    higherZ() {
      return this.mainScreenView === true ? 'higher-z' : '';
    },
  },
  methods: {
    async open() {
      this.isOpen = true;
      await this.loadAclMeta();
    },
    closeModal() {
      this.isOpen = false;
      this.$emit('close');
    },
    async loadAclMeta() {
      this.loading = true;
      let payload = {
        path: this.securedDirectoryPath,
        filter: 'metaonly',
      };

      const metaAclRecord = await this.$store.dispatch(
        'files/getAclForPath',
        payload
      );
      if (metaAclRecord.ok) {
        this.aclMeta = metaAclRecord;
        //inherit must be true by default
        this.inheritPermissions =
          metaAclRecord.data.meta && metaAclRecord.data.meta.inherit === 0
            ? 0
            : 1;
      }

      this.loading = false;
    },
    setActiveTab(tabname) {
      this.activeTab = tabname;
    },
    async updateInheritance() {
      const payload = {
        path: this.securedDirectoryPath,
        inherit: this.inheritPermissions ? '1' : '0',
      };
      await this.$store.dispatch('files/setAclInheritanceForPath', payload);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

.folder-security-dialog {
  background-color: var(--bg-medium);
  i {
    font-size: 27px;
  }
  span {
    .secured-dir-text {
      /* Style for "Folder: ma" */
      color: #4e5d78;
      font-size: 13px;
      font-weight: 500;
      padding-top: 1.5em;
      span {
        color: var(--text-dark-blue);
        font-size: 13px;
      }
    }
  }
}
.header-dir {
  /* Text style for "Folder:" */
  font-weight: 600;
  font-size: 13px;
}
.dir {
  /* Text style for "Folder:" */
  font-weight: 500;
  font-size: 13px;
}
.modal-body-acl {
  padding: 0px;
  margin: 0px;
  width: 100%;
}
.custom-color {
  color: #016aae;
}
.custom-header {
  background-color: var(--bg-medium);
}
.file-icon--folder {
  margin-top: 15px;
}
.tab-content {
  overflow-x: hidden;
}
.permission-header {
  h4 {
    /* Style for "Permission" */
    color: var(--text-dark);
    font-size: 20px;
    font-weight: 500;
  }
  /*  CheckBox {
    span {
       Style for "Inherit Fo" 
      height: 16px;
      color: #465c6a;
      font-size: 13px;
      font-weight: 600;
    }
  }*/
}
.acl-body {
  overflow-x: hidden;
  width: 100%;
}
.overflow-y {
  overflow-y: auto;
}
@media (min-width: 1200px) {
  .tab-content {
    max-height: 400px;
  }
}
.higher-z {
  z-index: 10001;
}
</style>
