<template>
  <div>
    <div class="table-wrapper-border mb-0">
      <table class="table narrow--properties">
        <caption>
          {{
            $t('Metadata filter')
          }}
        </caption>
        <thead>
          <th class="set-header" scope="col">{{ $t('Set') }}</th>
          <th class="attr-header" scope="col">{{ $t('Attribute') }}</th>
          <th class="value-header" scope="col">{{ $t('Value') }}</th>
          <th class="actions-header" scope="col">{{ $t('Actions') }}</th>
        </thead>
        <tbody>
          <tr v-if="!hasConditions" class="smaller-th">
            <td colspan="4">
              <p class="condition--font p-0 m-0">
                {{ $t('No metadata configured for search') }}
              </p>
            </td>
          </tr>
          <tr
            v-for="(condition, index) in conditions"
            :key="condition.attributeid + index"
            class="listing--font"
          >
            <td class="set">{{ condition.set }}</td>
            <td class="attr">{{ condition.attribute }}</td>
            <td class="value"><em class="badge" :style="{'color': condition.value}">{{ condition.value }}</em>
            <td class="actions">
              <a @click="$emit('removeCondition', index)">x</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  props: {
    conditions: {
      required: true,
      default: () => [],
      type: Array,
    },
  },
  data: function () {
    return {
      label: 'Metadata Search Conditions',
    };
  },
  computed: {
    hasConditions() {
      return !_.isEmpty(this.conditions);
    },
  },
};
</script>

<style lang="scss" scoped>
.view {
  width: 100px;
  font-size: 13px;
  font-weight: 400;
  padding-left: 5px;
  text-align: center;
}
.listing--font {
  font-size: 11px;
}
.table-wrapper-border {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  border-top: 0;
}
.narrow--properties {
  text-align: left;
  margin-bottom: 0;
  thead {
    th {
      background-color: #f6f6f6;
      padding: 15px !important;
      font-size: 10px;
    }
    .set-header {
      width: 40%;
    }
    .attr-header {
      width: 20%;
      text-align: center;
    }
    .value-header {
      width: 25%;
      text-align: center;
    }
    .actions-header {
      width: 15%;
      text-align: center;
    }
  }

  tbody {
    font-size: 11px;
    td {
      padding: 15px !important;
      border-bottom: 1px solid var(--border-color) !important;
    }
    .attr,
    .value,
    .actions {
      text-align: center;
    }
  }
}
.smaller-th {
  max-height: 40px;
}
.narrow-th .narrow-th-smaller {
  height: 20px;
}
</style>
