/**
 * Handle response that should be an array and is not due to XML conversions
 *
 * Array: array is returned
 * Object: array with single object
 * undefined: empty array
 *
 * @param raw_items - Array, Object or undefined
 * @returns Array containing the objects from raw_items
 */
export const handleArrayResponse = (raw_items) => {
  if (Array.isArray(raw_items)) {
    return raw_items;
  } else if (raw_items) {
    return [raw_items];
  } else {
    return [];
  }
};

/**
 * Check if object
 * @param {mixed} item
 * @returns
 */
export const isObject = (item) => {
  return typeof item === 'object' && !Array.isArray(item) && item !== null;
};
