<template>
  <div
    class="container p-0 m-0 row bottom-border notification-item"
    tabindex="0"
    :aria-labelledby="'user' + notification.toastrid"
    :aria-describedby="'message' + notification.toastrid"
  >
    <div class="col-12 p-0 m-0 pb-3 pt-2 pl-1 notification-unread">
      <div class="col-11 m-0 p-0 pl-2 float-left">
        <div class="col-12 pt-1 m-0 p-0">
          <div class="col-1 m-0 p-0 float-left">
            <Avatar
              class="comment-profile-image p-0 m-0 mt-1"
              :user="notification.params.FROM_USER_NAME_PARAMETER"
              :label="
                $t(`{userName}'s Avatar`, {
                  userName: notification.params.FROM_DISPLAY_NAME_PARAMETER,
                })
              "
            />
          </div>
          <div class="col-5 m-0 pl-2 float-left">
            <span
              :id="'user' + notification.params.FROM_PARAMETER"
              class="notification-username float-left"
              >{{ notification.params.FROM_DISPLAY_NAME_PARAMETER }}</span
            >
          </div>
          <div class="notification-date col-5 p-0 m-0 float-right">
            <small class="pr-0 float-right">{{
              notification.whents | fromNow
            }}</small>
          </div>
        </div>
        <div class="notification-content col-12 p-0 m-0 pl-4 row">
          <div class="col-10">
            <span
              :id="'message' + notification._id"
              v-tooltip="notification.params.BODY_PARAMETER"
              class="notification-what two-lines-truncated"
              >{{ notification.params.BODY_PARAMETER }}</span
            >

            <span
              v-if="
                notification.params.ATTACHED_OBJECT_TYPE_PARAMETER == 'FILE'
              "
              class="notification-path pt-1"
            >
              <FileIcon :name="fileName" class="fas file-notif-icon" />
              <div class="text-break file-name">
                <a @click="previewFile">{{ fileName }}</a>
              </div>
            </span>
            <span
              v-else-if="
                notification.params.ATTACHED_OBJECT_TYPE_PARAMETER ==
                'SHARE_OBJECT'
              "
              class="notification-path pt-1"
            >
              <div class="text-break file-name ml-0">
                <a @click="previewShare">{{ $t('View Share') }}</a>
              </div>
            </span>
          </div>
          <div class="col-12">
            <button
              class="btn btn-primary btn-action btn-approve"
              :aria-label="$t('Approve')"
              @click="onRespond(true)"
            >
              <Icon family="fas" name="thumbs-up" />
              {{ $t('Approve') }}
            </button>
            <button
              class="btn btn-danger btn-action"
              :aria-label="$t('Reject')"
              @click="onRespond(false)"
            >
              <Icon family="fas" name="thumbs-down" />
              {{ $t('Reject') }}
            </button>
            <button
              class="btn btn-primary btn-action float-right fix-20px"
              :aria-label="$t('Open')"
              @click="goToAction"
            >
              {{ $t('Open') }}
            </button>
          </div>
          <div class="col-12 mt-2">
            <button
              class="btn btn-link float-right fix-20px"
              :aria-label="$t('View All Actions')"
              @click="$router.push('/actions')"
            >
              {{ $t('View All Actions') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Avatar from 'common/components/Avatar';
dayjs.extend(relativeTime);

export default {
  components: {
    Icon,
    FileIcon,
    Avatar,
  },
  filters: {
    fromNow(value) {
      return dayjs.unix(value).fromNow();
    },
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    fileName() {
      return this.notification.params.ATTACHED_OBJECT_PARAMETER.split(
        '/'
      ).pop();
    },
  },
  methods: {
    goToAction() {
      this.$emit('closeNotifications');
      this.$router.push('/actions/' + this.notification._id);
    },
    async onRespond(approve) {
      const result = await this.$store.dispatch('actions/respondAction', {
        action_id: this.notification._id,
        approved: approve,
      });

      if (result.status === 200) {
        this.$toast.open({
          message: `<b>${this.$t('Success')}</b><p role="alert">${
            approve
              ? this.$t('Action successfully Approved')
              : this.$t('Action successfully Rejected')
          }
          </p>`,
          type: 'success',
        });

        await this.$store.dispatch(
          'actions/removeActionFromList',
          this.notification._id
        );
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Failure')}</b><p role="alert">${this.$t(
            result.data.command[0].message
          )}</p>`,
          type: 'error',
        });
      }
    },
    async previewFile() {
      const fileInfoResponse = await this.$store.dispatch(
        'files/getSingleFileInfo',
        this.notification.params.ATTACHED_OBJECT_PARAMETER
      );
      if (
        fileInfoResponse.ok &&
        fileInfoResponse.data &&
        fileInfoResponse.data.entry
      ) {
        this.$preview.open(fileInfoResponse.data.entry);
      }
    },
    async previewShare() {
      this.$emit('closeNotifications');

      this.$store.dispatch('share/openReadonlyShare', {
        path: this.notification.params.ATTACHED_OBJECT_PARAMETER,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.file-notif-icon {
  transform: scale(0.5);
  margin-left: -10px;
}
.bottom-border {
  border-bottom: solid 1px var(--border-color);
  background-color: var(--bg-light);
}
.file-name {
  margin-left: -12px;
}
.dir-icon {
  /* Style for "Path" */
  height: 20px;
  font-size: 13px;
  padding-top: 3px;
  color: var(--text-light);
}
.notification-unread {
  background-color: var(--bg-mid-light);
}
.notification-read {
  background-color: var(--bg-light);
}
.dot {
  color: var(--text-light);
  display: block;
  font-size: 8px;
}
.notification-date {
  /* Style for "Yesterday" */
  height: 24px;
  color: var(--text-light);
  font-size: 11px;
  font-weight: 500;
}
.notification-what {
  font-size: 13px;
  color: var(--text-dark-blue);
  text-align: left;
  font-weight: 500;
}
.notification-filetype {
  font-size: 14px;
}
.notification-username {
  /* Style for "John Updat" */
  color: var(--text-dark-blue);
  font-size: 13px;
  font-weight: 600;
  padding-left: 0px;
  text-align: left;
}
.notification-content {
  font-weight: 500;
  width: 100%;
  text-align: start;
}

.btn-action {
  height: 28px;
  padding: 2px 14px;
  font-size: 11px;
  margin-top: 20px;
  margin-right: 8px;
}

.btn-approve {
  background-color: #58ae01 !important;
  border-color: #58ae01 !important;
  color: #fff;

  &:hover {
    background-color: #51a101 !important;
  }

  &:focus,
  &:active {
    box-shadow: 0 0 0 0.2rem rgba(88, 174, 1, 0.32) !important;
  }
}

.notification-path {
  color: var(--text-light);
  font-size: 11px;
  font-weight: 500;
  display: flex;
  text-align: left;
  align-items: center;
}
.no-notifications {
  color: var(--text-dark-blue);
  text-align: center;
  height: 120px;
}
.bell-container {
  padding-left: 21% !important;
  color: #b8b8b8;
}
.two-lines-truncated {
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.fix-20px {
  margin-right: -20px;
}

.btn-link {
  font-size: 11px;
  padding-right: 0;
}
</style>
