var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"small",attrs:{"visible":_vm.isOpen,"title":_vm.$t('Clear Incomplete Files'),"position":"top","buttons":[
    {
      label: `Remove`,
      outline: false,
      callback: function () {
        _vm.onClear();
      },
    },
    {
      label: `Close`,
      outline: true,
      callback: function () {
        _vm.closeModal();
      },
    },
  ]},on:{"close":_vm.closeModal}},[_c('p',[_vm._v(_vm._s(_vm.$t('Remove all incomplete file uploads?')))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom-start",value:({
      content: _vm.$t(
        'Select check box to remove files that are currently being uploaded'
      ),
      classes: ['clear-incomplete', 'intransit-tooltip'],
    }),expression:"{\n      content: $t(\n        'Select check box to remove files that are currently being uploaded'\n      ),\n      classes: ['clear-incomplete', 'intransit-tooltip'],\n    }",modifiers:{"bottom-start":true}}],staticClass:"checkbox-container"},[_c('Checkbox',{attrs:{"value":_vm.includeInTransit,"name":"includeInTransit","label":_vm.$t('Include in-transit')},on:{"change":function($event){_vm.includeInTransit = !_vm.includeInTransit}}}),_c('label',{staticClass:"ml-2 mb-0 label",attrs:{"for":"includeInTransit"},on:{"click":function($event){_vm.includeInTransit = !_vm.includeInTransit}}},[_vm._v(" "+_vm._s(_vm.$t('Include in-transit'))+" ")])],1),(_vm.isMobile)?_c('div',{staticClass:"in-transit-mobile-description"},[_vm._v(" "+_vm._s(_vm.$t('Select check box to remove files that are currently being uploaded'))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }