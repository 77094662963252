<template>
  <DialogModal
    :class="'dialog-modal-form'"
    :visible="true"
    :title="$t('Confirm')"
    color="grey"
    :size="'small'"
    position="top"
    style="z-index: 9999"
    :buttons="[
      {
        label: `OK`,
        callback: () => this.allow(),
      },
      { label: `Cancel`, outline: true, callback: () => this.cancel() },
    ]"
    @close="cancel()"
  >
    {{
      $t(
        'Do you want to remove share permissions assigned to specific users and groups and allow share access to everyone? '
      )
    }}
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';

export default {
  components: {
    DialogModal,
  },
  methods: {
    allow() {
      this.$emit('allow');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
