import Vue from 'vue';
import { HTTP } from 'common/utils/constants';
import { cloneDeep } from 'lodash';
import { escapeWorkflowParams, unescapeWorkflowParams } from 'common/utils/workflow';

const initialCurrentWorkflow = {
  name: 'New Workflow',
  enabled: true,
  trigger: {},
  steps: [],
  supported_operations: [],
  properties: {
    variables: [],
    context: [],
    objects: {},
  },
  variables: [],
  nodes_flow: [],
};

const removeNodeUtil = function (workflow, nodeUid) {
  const newWorkflow = cloneDeep(workflow);

  // store removed node
  const nodeToRemove = newWorkflow.steps.find((step) => step.uid === nodeUid);

  // store flows that need to be changed
  const flowsToCorrect = newWorkflow.nodes_flow.filter(
    (flow) => flow.to === nodeUid || flow.from === nodeUid
  );

  // remove deleted node
  newWorkflow.steps = newWorkflow.steps.filter((step) => step.uid != nodeUid);

  /**
   * ===========  Rewiring logic ==================
   */
  const flowsFromDeleted = flowsToCorrect.filter(
    (flow) => flow.from === nodeUid
  );
  const flowsToDeleted = flowsToCorrect.filter((flow) => flow.to === nodeUid);

  if (flowsToDeleted.length && !flowsFromDeleted.length) {
    // Has connections to it, but nothing after
    // Nothing to change, already last node, just remove connections

    // remove old connections to/from removed node
    newWorkflow.nodes_flow = newWorkflow.nodes_flow.filter(
      (flow) => flow.to != nodeUid && flow.from != nodeUid
    );
  }

  // ATENTION: Deletion does not support directly removal of decision nodes with children
  // it is important to order deletion in to remove children before deleting decision nodes already empty
  //
  // Here the logic splits, we need to handle decision nodes in a different way
  if (nodeToRemove.is_transition_step) {
    // Delete node that is going to same node (empty decision node)

    // remove old connections to/from removed node
    newWorkflow.nodes_flow = newWorkflow.nodes_flow.filter(
      (flow) => flow.to != nodeUid && flow.from != nodeUid
    );

    if (
      flowsToDeleted.length === 1 &&
      flowsFromDeleted.length === 2 &&
      flowsFromDeleted[0].to == flowsFromDeleted[1].to
    ) {
      // remove old connections to/from removed node
      newWorkflow.nodes_flow = newWorkflow.nodes_flow.filter(
        (flow) => flow.to != nodeUid && flow.from != nodeUid
      );

      // just switch references
      newWorkflow.nodes_flow.push({
        from: flowsToDeleted[0].from,
        to: flowsFromDeleted[0].to,
        transition: flowsToDeleted[0].transition,
      });
    } else if (flowsToDeleted.length && flowsFromDeleted.length) {
      // If it has more connection but WE KNOW that it has already no direct children
      // it means that it is tied to a multi parent node to start another tree
      flowsToDeleted.forEach((flow) => {
        // Adjust references to keep the flow
        newWorkflow.nodes_flow.push({
          from: flow.from,
          to: flowsFromDeleted[0].to,
          transition: flow.transition,
        });
      });
    }
  } else {
    // remove old connections to/from removed node
    newWorkflow.nodes_flow = newWorkflow.nodes_flow.filter(
      (flow) => flow.to != nodeUid && flow.from != nodeUid
    );

    if (flowsToDeleted.length === 1 && flowsFromDeleted.length === 1) {
      // Simple 1 -> node -> 1 connection, just switch the references
      newWorkflow.nodes_flow.push({
        from: flowsToDeleted[0].from,
        to: flowsFromDeleted[0].to,
        transition: flowsToDeleted[0].transition,
      });
    }
    if (flowsToDeleted.length > 1 && flowsFromDeleted.length === 1) {
      //  X -> node -> 1 connection, switch all the references
      flowsToDeleted.forEach((flow) => {
        newWorkflow.nodes_flow.push({
          from: flow.from,
          to: flowsFromDeleted[0].to,
          transition: flow.transition,
        });
      });
    }
  }

  return newWorkflow;
};

export default {
  namespaced: true,
  state: {
    workflows: [],
    currentWorkflow: initialCurrentWorkflow,
    currentInstance: null,
    unsavedNodeChanges: false,
    workflows_count: 0,
    triggers: [],
    steps: [],
    properties: {
      variables: [],
      context: [],
      objects: {},
    },
    variables: [],
    instances: [],
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    merge(state, { key, value }) {
      state[key] = { ...state[key], ...value };
    },
    clearArray(state, { key }) {
      Vue.set(state.currentWorkflow, key, []);
    },
    clearProperties(state) {
      const properties = {
        variables: [],
        context: [],
        objects: {},
      };
      Vue.set(state.currentWorkflow, 'properties', properties);
    },
    updateProperties(state, { payload }) {
      const properties = {
        ...state.currentWorkflow.properties,
        ...payload,
      };

      Vue.set(state.currentWorkflow, 'properties', properties);
    },
    updateOperations(state, payload) {
      Vue.set(state.currentWorkflow, 'supported_operations', payload);
    },
    updateStep(state, { index, value }) {
      const steps = [...state.currentWorkflow.steps];
      steps[index] = value;

      Vue.set(state.currentWorkflow, 'steps', steps);
    },
    setUnsavedChanges: (state, { value }) => {
      Vue.set(state, 'unsavedNodeChanges', value);
    },
    setVariables(state, [payload]) {
      Vue.set(
        state.currentWorkflow,
        'variables',
        payload && payload.length > 0 ? payload : []
      );
    },
    addVariable(state, { payload }) {
      const variables = [...state.currentWorkflow.variables];

      variables.push(payload);

      Vue.set(state.currentWorkflow, 'variables', variables);
    },
    deleteVariable(state, index) {
      const variables = [...state.currentWorkflow.variables];
      variables.splice(index, 1);

      Vue.set(state.currentWorkflow, 'variables', variables);
    },
    updateVariable(state, { index, payload }) {
      const variables = [...state.currentWorkflow.variables];
      variables[index] = payload;

      Vue.set(state.currentWorkflow, 'variables', variables);
    },
  },
  actions: {
    setWorkflowName(context, name) {
      context.commit('merge', {
        key: 'currentWorkflow',
        value: { name: name },
      });
    },
    clearWorkflowsData(context) {
      context.commit('set', {
        key: 'currentWorkflow',
        value: initialCurrentWorkflow,
      });
      context.commit('clearArray', { key: 'steps' });
      context.commit('clearArray', { key: 'nodes_flow' });
      context.commit('clearProperties');
    },
    async saveNodeInfo(context, payload) {
      if (payload.uid === context.state.currentWorkflow.trigger.uid) {
        const trigger = {
          ...context.state.currentWorkflow.trigger,
          ...payload.nodePayload,
        };

        context.commit('merge', {
          key: 'currentWorkflow',
          value: { trigger: trigger },
        });
      } else {
        const stepIndex = context.state.currentWorkflow.steps.findIndex(
          (step) => {
            return step.uid === payload.uid;
          }
        );

        const newStep = {
          ...context.state.currentWorkflow.steps[stepIndex],
          ...payload.nodePayload,
        };

        context.commit('updateStep', {
          index: stepIndex,
          value: newStep,
        });
      }
    },
    async createWorkflow(context, payload) {
      const response = await this.state.core.client.post(
        'core/createautomationworkflow',
        payload,
        {},
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        await context.dispatch('getWorkflows');

        context.commit('set', {
          key: 'currentWorkflow',
          value: response.data.workflow,
        });
      }
      return response;
    },
    async updateWorkflow(context, payload) {
      payload = unescapeWorkflowParams(payload);
      const response = await this.state.core.client.post(
        'core/updateautomationworkflow',
        payload,
        {},
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        await context.dispatch('getWorkflows');

        let workflow = response.data.workflow;
        workflow = escapeWorkflowParams(workflow);

        context.commit('set', {
          key: 'currentWorkflow',
          value: workflow,
        });

        context.commit('setVariables', [workflow.properties.variables]);
      }
      return response;
    },
    async startWorkflow(context, payload) {
      return this.state.core.client.post(
        'core/startautomationworkflow',
        payload,
        {},
        HTTP.USE_JSON
      );
    },
    async getWorkflows(context, payload = {}) {
      let workflows = [];

      const response = await this.state.core.client.get(
        'core/getautomationworkflows',
        payload,
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        if (!Array.isArray(response.data.workflow)) {
          workflows.push(response.data['workflow']);
        } else {
          workflows = response.data.workflow;
        }

        context.commit('set', { key: 'workflows', value: workflows });
        context.commit('set', {
          key: 'workflows_count',
          value: response.data.meta.total,
        });
      }
      return response;
    },
    async getWorkflowsResponse(context, params = {}) {
      return this.state.core.client.get(
        'core/getautomationworkflows',
        params,
        HTTP.USE_JSON
      );
    },
    async getWorkflow(context, id) {
      let workflow = {};

      const response = await this.state.core.client.get(
        'core/getautomationworkflow',
        id,
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        workflow = response.data.workflow;
        workflow = escapeWorkflowParams(workflow);
        context.commit('set', {
          key: 'currentWorkflow',
          value: workflow,
        });
        context.commit('setVariables', [workflow.properties.variables]);
      }
      return response;
    },
    async deleteWorkflow(context, id) {
      const response = await this.state.core.client.post(
        'core/deleteautomationworkflow',
        { id }
      );

      if (response.status === 200) {
        await context.dispatch('getWorkflows');
      }

      return response;
    },
    async toggleWorkflow(context, payload) {
      const response = await this.state.core.client.post(
        'core/enableautomationworkflow',
        payload,
        {}
      );

      if (response.status === 200) {
        await context.dispatch('getWorkflows');
      }

      return response;
    },
    async getWorkflowRunningInstances(context, payload) {
      const response = await this.state.core.client.get(
        'core/getautomationworkflowinstances',
        {
          workflowid: payload.id,
          state: 'Running',
          filter: payload.filter,
          start: payload.start,
          limit: payload.limit,
          sortfield: payload.sortfield,
          sortdir: payload.sortdir,
        },
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        context.commit('set', {
          key: 'runningInstances',
          value: response.data['workflow_instance'] || [],
        });

        context.commit('set', {
          key: 'runningInstancesTotal',
          value: response.data.meta.total,
        });
      }
      return response;
    },
    async getWorkflowCompletedInstances(context, payload) {
      const response = await this.state.core.client.get(
        'core/getautomationworkflowinstances',
        {
          workflowid: payload.id,
          state: 'Completed',
          filter: payload.filter,
          start: payload.start,
          limit: payload.limit,
          sortfield: payload.sortfield,
          sortdir: payload.sortdir,
        },
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        context.commit('set', {
          key: 'completedInstances',
          value: response.data['workflow_instance'] || [],
        });
        context.commit('set', {
          key: 'completedInstancesTotal',
          value: response.data.meta.total,
        });
      }
      return response;
    },
    async getWorkflowInstance(context, payload) {
      const response = await this.state.core.client.get(
        'core/getautomationworkflowinstances',
        {
          workflowid: payload.workflowid,
          workflowinstanceid: payload.instanceid,
        },
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        context.commit('set', {
          key: 'currentInstance',
          value: response.data['workflow_instance'][0],
        });
      }
      return response;
    },
    async getWorkflowSteps(context) {
      let steps = [];

      const triggerType = context.state.currentWorkflow.trigger.type;
      const response = await this.state.core.client.get(
        'core/getautomationworkflowsteps',
        { triggertype: triggerType },
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        const { workflow_step } = response.data;
        steps = workflow_step;
        context.commit('set', { key: 'steps', value: steps });
      }

      return steps;
    },
    async cancelWorkflowInstance(context, id) {
      return this.state.core.client.post(
        'core/cancelautomationworkflowinstance',
        { id },
        {}
      );
    },
    saveTriggerSupportedOperations(context, operations) {
      context.commit('updateOperations', operations);
    },
    saveTriggerProperties(context, payload) {
      context.commit('updateProperties', { payload });
    },
    async getWorkflowTriggers(context) {
      let triggers = [];

      const response = await this.state.core.client.get(
        'core/getautomationworkflowtriggers',
        {},
        HTTP.USE_JSON
      );
      if (response.status === 200) {
        const { workflow_trigger } = response.data;
        triggers = workflow_trigger;
        context.commit('set', { key: 'triggers', value: triggers });
      }

      return triggers;
    },
    async addWorkflowVariable(context, payload) {
      context.commit('addVariable', { payload: payload });
    },
    async deleteWorkflowVariable(context, index) {
      context.commit('deleteVariable', index);
    },
    async updateWorkflowVariable(context, index, payload) {
      context.commit('updateVariable', index, payload);
    },
    async getVariableByVarName(context, varname) {
      let variableName = varname.toString();
      let found = undefined;

      found = context.state.currentWorkflow?.variables?.find(
        (variable) => variable.VARIABLE_NAME_PARAMETER === variableName
      );

      if (!found) {
        found = context.state.currentWorkflow.properties.context.find(
          (variable) => variable.VARIABLE_NAME_PARAMETER === variableName
        );
      }

      let storeObjects = context.state.currentWorkflow.properties.objects;
      let objects = [];

      for (let key of Object.keys(storeObjects)) {
        storeObjects[key].forEach((item) => {
          objects.push(item);
        });
      }

      if (!found) {
        found = objects.find(
          (variable) => variable.VARIABLE_NAME_PARAMETER === variableName
        );
      }

      return found;
    },
    async getInstanceActivity(context, instanceid) {
      return this.state.core.client.get(
        'core/getautomationworkflowinstanceactivity',
        {
          workflowinstanceid: instanceid,
        },
        HTTP.USE_JSON
      );
    },
    /**
     * Remove nodes from currentWorkflow state
     *
     * @param {*} context - context
     * @param {[String]} nodeUids - Array of uids to be removed, provided by the workflow
     */
    removeNode(context, nodeUids) {
      let newWorkflow = cloneDeep(context.state.currentWorkflow, true);

      // First remove action nodes, so there will be only decision node to be deleted later
      const actionNodesToDelete = newWorkflow.steps
        .filter(
          (step) => nodeUids.includes(step.uid) && !step.is_transition_step
        )
        .map((node) => node.uid);

      actionNodesToDelete.forEach((uid) => {
        newWorkflow = removeNodeUtil(newWorkflow, uid);
      });

      /**
       * Return an ordered array of decision steps to be deleted
       * @param {Object} steps - steps from a workflow
       * @returns {[String]} Array of steps to beremoved
       */
      const getDecisionStepsToDelete = (steps) => {
        return steps
          .filter((step) => nodeUids.includes(step.uid))
          .filter((step) => {
            const connectionsFromNode = newWorkflow.nodes_flow.filter(
              (flow) => flow.from === step.uid
            );

            // nodes without connections or with both connections to same node (empty condition)
            return (
              !connectionsFromNode.length ||
              (connectionsFromNode.length === 2 &&
                connectionsFromNode[0].to === connectionsFromNode[1].to)
            );
          })
          .map((node) => node.uid);
      };

      let decisionNodesToDelete = getDecisionStepsToDelete(newWorkflow.steps);

      // Loop while there is some node to delete
      while (decisionNodesToDelete.length) {
        newWorkflow = removeNodeUtil(newWorkflow, decisionNodesToDelete[0]);
        // every deletion analyse again the order and nodes to be deleted
        decisionNodesToDelete = getDecisionStepsToDelete(newWorkflow.steps);
      }

      // commit new processed workflow object
      context.commit('set', { key: 'currentWorkflow', value: newWorkflow });
    },

    async getWorkflowUserSharePermissions(context, workflowId) {
      return this.state.core.client.get(
        'core/getusersharepermissionsautomationworkflow',
        {
          id: workflowId,
        },
        HTTP.USE_JSON
      );
    },

    async addWorkflowUserToShare(context, payload) {
      return this.state.core.client.post(
        'core/addusershareautomationworkflow',
        payload,
        {}
      );
    },

    async removeWorkflowUserToShare(context, payload) {
      return this.state.core.client.post(
        'core/removeusershareautomationworkflow',
        payload,
        {}
      );
    },

    async updateWorkflowUserSharePermission(context, payload) {
      return this.state.core.client.post(
        'core/setusersharepermissionsautomationworkflow',
        payload,
        {}
      );
    },

    async getWorkflowGroupSharePermissions(context, workflowId) {
      return this.state.core.client.get(
        'core/getgroupsharepermissionsautomationworkflow',
        {
          id: workflowId,
        },
        HTTP.USE_JSON
      );
    },

    async addWorkflowGroupToShare(context, payload) {
      return this.state.core.client.post(
        'core/addgroupshareautomationworkflow',
        payload,
        {}
      );
    },

    async removeWorkflowGroupToShare(context, payload) {
      return this.state.core.client.post(
        'core/removegroupshareautomationworkflow',
        payload,
        {}
      );
    },

    async updateWorkflowGroupSharePermission(context, payload) {
      return this.state.core.client.post(
        'core/setgroupsharepermissionsautomationworkflow',
        payload,
        {}
      );
    },
  },
};
