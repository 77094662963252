import Vue from 'vue';
import FileCloud from 'common/filecloud/client';
import { getParams } from 'common/utils/files';
import * as microsoftTeams from '@microsoft/teams-js';

import { loadLanguageAsync } from '../../i18n.js';
import { systemStatus } from 'common/filecloud/properties/systemStatus';
import { customization } from 'common/filecloud/properties/customization';
import { WOPIClients, GoogleDocsFormats } from '../../constants/WOPI.js';
import {
  themes,
  setColor,
  setTheme,
  loadCss,
  preventClickOnPdfTrial,
} from 'common/utils/themes';
import dayjs from 'dayjs';
import { getRecords } from 'common/utils/arrayUtils';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    client: new FileCloud('user'),
    ready: false,
    usageSummary: {},
    connectedDevices: [],
    toasts: {},
    toastsUnread: 0,
    consentData: 0,
    navigationSidebar: {
      open: true,
    },
    toastsLastLoaded: '',
    tosAccepted: 0,
    pathRules: [],
    pathRulesMeta: {},
    useRtl: false,
    effectiveUserPolicy: {},
    usingAdBlocker: false,
    editorsClient: {},
    fullSystemStatus: {},
    isMobile: false,
    isTablet: false,
    isSmallScreen: false,
    windowHeight: window.innerHeight,
    customization: {
      DEFAULT_SORT_BY: 'name',
      DEFAULT_SORT_DIR: 1,
    },
    systemstatus: {
      LIMITEDUSER: true,
    },
    getSystemStatus: {},
    customHeaderFooter: {
      header: {},
      footer: {},
    },
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    setCustomization(state, { key, value }) {
      Vue.set(state.customization, key, value);
    },
    merge(state, { key, value }) {
      state[key] = { ...state[key], ...value };
    },
    toggleNavigationSidebar: (state) => {
      state.navigationSidebar.open = !state.navigationSidebar.open;
    },
    setCalendar: (state, calendar) => {
      state.fullSystemStatus.calendartype = calendar;
    },
    setDateFormat: (state, format) => {
      state.fullSystemStatus.dateformat = format;
    },
    setTimeFormat: (state, format) => {
      state.fullSystemStatus.timeformat = format;
    },
  },
  actions: {
    async getEffectivePolicyForUser(context) {
      const username = this.state.auth.user.peerid;
      const response = await context.state.client.post(
        'core/getpolicyforuser',
        {
          username: username,
        }
      );

      if (response.ok) {
        await context.commit('set', {
          key: 'effectiveUserPolicy',
          value: response.data,
        });
      }
    },
    async init(context) {
      // authenticate
      await this.dispatch('auth/authenticate');

      // init workers
      this.dispatch('workers/init');
      localStorage.removeItem('logincurrentLang');
      await context.dispatch('getSystemStatus');
      await context.dispatch('getCustomizations');

      // set ready state
      context.commit('set', { key: 'ready', value: true });
    },

    async login(context, payload) {
      const response = await context.state.client.post(
        'core/loginguest',
        payload
      );

      // if success login, run authentication process
      if (response.ok) {
        await context.dispatch('authenticate');
      }

      return response;
    },

    async resetPassword(context, payload) {
      const response = await context.state.client.post(
        'core/verifyresetpassword',
        payload
      );
      return response;
    },
    async initResetPassword(context, payload) {
      const response = await context.state.client.get(
        'core/resetpassword',
        payload
      );
      return response;
    },

    async forceResetPassword(context, payload) {
      const response = await context.state.client.post(
        'core/updatepassword',
        payload
      );
      return response;
    },

    async updatePhoneNumber(context, payload) {
      const response = await context.state.client.post(
        'core/updatephonenumber',
        payload
      );
      return response;
    },

    async translations(context, lang) {
      let response = {};
      if (!lang) {
        response = await context.state.client.post(
          'core/getuitranslations?tag=core'
        );
      } else {
        response = await context.state.client.post(
          'core/getuitranslations?lang=' + lang
        );
      }
      // if success in translation, store info
      if (response.ok) {
        const dictionary = {};
        const json = response.data.translation;
        for (var keyJson in json) {
          dictionary[json[keyJson].key] = json[keyJson].value;
        }
        localStorage.setItem('currentLang', lang);
        localStorage.setItem('translations', JSON.stringify(dictionary));
        context.commit('set', { key: 'translations', value: dictionary });
        return dictionary;
      }

      return response;
    },

    isMobile({ commit }, mobile) {
      commit('set', { key: 'isMobile', value: mobile });
    },
    isTablet({ commit }, tablet) {
      commit('set', { key: 'isTablet', value: tablet });
    },
    isSmallScreen({ commit }, screen) {
      commit('set', { key: 'isSmallScreen', value: screen });
    },
    setWindowHeight({ commit }, height) {
      commit('set', { key: 'windowHeight', value: height });
    },

    async getTOS(context) {
      const response = await context.state.client.getPlain('core/gettos');

      if (response.ok) {
        await context.commit('set', {
          key: 'consentData',
          value: response.data,
        });

        return response.data;
      }
    },

    async getUserTOS(context) {
      const response = await context.state.client.get('core/getuserconsent');

      if (response.ok) {
        await context.commit('set', {
          key: 'consentData',
          value: response.data,
        });

        return response.data;
      }
    },

    async getAnonTOS(context) {
      const response = await context.state.client.get(
        'core/getanonymoususerconsent'
      );

      if (response.ok) {
        await context.commit('set', {
          key: 'consentData',
          value: response.data,
        });

        return response.data;
      }
    },

    async acceptTOS(context) {
      const response = await context.state.client.getPlain(
        'core/privacyconsentaccept'
      );
      context.state.tosAccepted = 1;
      const { msteams } = getParams();
      try {
        if (msteams) {
          microsoftTeams.initialize();
          const result = await this.dispatch('auth/msteamsToken');
          microsoftTeams.authentication.notifySuccess(result.data.token);
        }
      } catch(error) {
          // Continue if failure happens due to third party msteams script
        return response; 
      }
      return response;
    },

    async getLanguageList(context) {
      const response = await context.state.client.get('core/getlanguagelist');
      // if success in translation, store info
      let currentLang = localStorage.getItem('currentLang');
      if (response.data) {
        const languages = response.data.language;
        if (localStorage.getItem('logincurrentLang') != 1) {
          const localLang = languages.find((o) => o.current === 1);
          localStorage.removeItem('translations'); // to remove
          const languageToSet = currentLang || localLang.name || 'english';
          loadLanguageAsync(languageToSet);
          const langUpdate = languages.find((o) => o.name === languageToSet);
          langUpdate.currentLang = 1;
        } else {
          context.dispatch('changeLanguage', {
            lang: localStorage.getItem('currentLang'),
          });
        }
        context.commit('set', { key: 'languages', value: languages });
      }
    },
    async changeLanguage(context, lang) {
      const response = await context.state.client.get(
        'core/changelanguage',
        lang
      );
      return response;
    },

    async getSystemStatus({ commit, dispatch, state }) {
      const { ok, data } = await state.client.get(
        'core/getsystemstatus?nousage=1&uiinfo=1'
      );

      // switch to the old UI
      if (
        process.env.NODE_ENV !== 'development' &&
        data.status &&
        data.status.uiversion === 1
      ) {
        window.location.reload();
      }

      // if success in translation, store info
      if (ok) {
        commit('set', {
          key: 'systemstatus',
          value: new systemStatus(data.status),
        });
        commit('set', {
          key: 'fullSystemStatus',
          value: data.status,
        });
        localStorage.setItem('appversion', data.status.appversion);
        dispatch('getLanguageList');
      }
      if (!!data.status?.customheaderfooter) {
        dispatch('getCustomHeaderAndFooter');
      }
    },

    async getCustomHeaderAndFooter({ state, commit, getters }) {
      const { ok, data } = await state.client.get('core/getcustomheaderfooter');
      if (ok) {
        commit('set', {
          key: 'customHeaderFooter',
          value: data.custom,
        });
        const doc = document.body.classList;
        if (getters.hasCustomFooter) doc.add('fc-footer');
        if (getters.hasCustomHeader) doc.add('fc-header');
      }
    },

    async getDate(context) {
      const response = await context.state.client.get(
        'core/getdatetimeformats',
        { type: 'date' }
      );

      if (response.data) {
        const dateFormats = response.data.format;
        context.commit('set', { key: 'dateFormats', value: dateFormats });
      }
    },
    async getTime(context) {
      const response = await context.state.client.get(
        'core/getdatetimeformats',
        { type: 'time' }
      );

      if (response.data) {
        const timeFormats = response.data.format;
        context.commit('set', { key: 'timeFormats', value: timeFormats });
      }
    },

    async changeDate(context, format) {
      const response = await context.state.client.get(
        'core/changedateformat',
        format
      );
      return response;
    },

    async changeTime(context, format) {
      const response = await context.state.client.get(
        'core/changetimeformat',
        format
      );
      return response;
    },
    async changeCalendar(context, calendar) {
      const response = await context.state.client.post(
        'core/changecalendartype',
        calendar
      );
      return response;
    },

    async emailSubscribe(context) {
      return await context.state.client.post('core/subscribecurrentuser');
    },

    async emailUnSubscribe(context) {
      return await context.state.client.post('core/unsubscribecurrentuser');
    },

    async getPathRules(context, params) {
      const response = await context.state.client.post(
        'core/notificationsgetpathrulesforuser',
        params
      );
      if (response.data) {
        let { rule, meta } = response.data;
        if (!meta.total) {
          context.commit('set', { key: 'pathRules', value: [] });
          return;
        }
        if (!Array.isArray(rule)) {
          rule = [rule];
        }
        context.commit('set', { key: 'pathRules', value: rule });
        context.commit('set', { key: 'pathRulesMeta', value: meta });
      }
    },
    async getRulesForPath(context, params) {
      const response = await context.state.client.post(
        'core/notificationsgetruleforpath',
        params
      );
      if (response.data) {
        let rules = response.data;
        context.commit('set', { key: 'filePathRules', value: rules });
      }
    },
    async deleteRulesForPath(context, params) {
      await context.state.client.post(
        'core/notificationsdeleteruleforpath',
        params
      );
    },

    async getUserSettings(context) {
      const response = await context.state.client.post(
        'core/notificationsgetusersettings'
      );
      if (response.data) {
        const notifySettings = response.data.notificationsusersettings;
        context.commit('set', { key: 'notifySettings', value: notifySettings });
      }
    },

    async updateProfilePictureStatus(context, value) {
      const payload = {
        hasprofileimage: value,
      };

      context.commit('merge', {
        key: 'fullSystemStatus',
        value: payload,
      });
    },

    async updateProfilePicture(context, file) {
      return await context.state.client.postMultipart(
        'core/updateprofileimage',
        { file }
      );
    },
    async removeProfilePicture(context) {
      return await context.state.client.post('core/updateprofileimage', {
        remove: 1,
      });
    },

    async updateUserSettings(context, payload) {
      let response = await this.state.core.client.post(
        'core/notificationssaveusersettings',
        payload
      );
      return response;
    },

    async updateFileSettings(context, payload) {
      let response = await this.state.core.client.post(
        'core/notificationssavepathrule',
        payload
      );
      return response;
    },

    async getColorTags(context) {
      let colorTags = {};
      let meta = {};

      const response = await context.state.client.get('core/getcolortags');
      if (response.ok) {
        colorTags = { ...response.data.color };
        meta = { ...response.data.meta };
        context.commit('set', {
          key: 'colortags',
          value: colorTags,
        });
        context.commit('set', {
          key: 'metaSets',
          value: meta,
        });
      }
    },

    async getCustomizations(context) {
      let customizationData = {};

      const response = await context.state.client.get(
        'core/getcustomizationdata'
      );

      if (response.ok) {
        customizationData = { ...response.data.customdata };

        const { user } = this.state.auth;

        // if user is logged in, fetch it's own custom settings
        if (user.authenticated) {
          const userResponse = await context.state.client.get(
            'core/getusercustomizationdata'
          );

          if (userResponse.ok) {
            customizationData = {
              ...customizationData,
              ...userResponse.data.customdata,
              userCustomizationLoaded: true,
            };

            if (userResponse.data.customdata.HIGH_CONTRAST_MODE) {
              document.body.classList.add('high-contrast');
              document.body.classList.add('fc-dark');
            }
            if (userResponse.data.customdata.SIDEPANEL_LEFT_COLLAPSE) {
              await context.commit('set', {
                key: 'navigationSidebar',
                value: { open: false },
              });
            }
            // to remove in ver 21 ===============
            if (localStorage) {
              if (localStorage.getItem('theme-color')) {
                const color = localStorage.getItem('theme-color');
                await context.dispatch('setCustomizations', {
                  name: 'THEME_COLOR',
                  value: color,
                });
                localStorage.removeItem('theme-color');
              }
              if (localStorage.getItem('theme')) {
                const theme = localStorage.getItem('theme');
                await context.dispatch('setCustomizations', {
                  name: 'THEME_MODE',
                  value: theme === 'dark',
                });
                localStorage.removeItem('theme');
              }
            }
            // to remove in ver 21 ===============
          }
        }

        setColor(_.get(customizationData, 'THEME_COLOR', '#076795'));
        setTheme(_.get(customizationData, 'THEME_MODE', 0));

        //inject customer CSS file
        let customCssFile = customizationData.CUSTOMCSSFILE;
        if (customCssFile && typeof customCssFile == 'string') {
          loadCss('/custom/css/' + customCssFile);
        }
        //inject additional css entries
        loadCss('/core/getcssentries');
        preventClickOnPdfTrial(
          customizationData?.HOSTEDCLOUD == 1 &&
            customizationData?.TRIALMODE == 1
        );
        context.commit('set', {
          key: 'customization',
          value: customization(customizationData),
        });

        // init onboarding
        this.dispatch('onboarding/init');
        context.dispatch('getEditors');
      }
    },

    setHighContrast(context, enabled) {
      const customizationFromState = context.state.customization;

      let customizationData = { ...customizationFromState };
      customizationData.HIGH_CONTRAST_MODE = enabled;

      context.commit('set', {
        key: 'customization',
        value: customizationData,
      });

      if (enabled) {
        document.body.classList.add('high-contrast');
        document.body.classList.add('fc-dark');
      } else {
        document.body.classList.remove('high-contrast');
        document.body.classList.remove('fc-dark');
      }

      context.dispatch('setCustomizations', {
        name: 'HIGH_CONTRAST_MODE',
        value: enabled,
      });
    },

    async setCustomizations(context, { name, value, local, remoteOnly }) {
      const customizationFromState = context.state.customization;

      // set local only
      if (local) {
        let customizationData = { ...customizationFromState };
        customizationData[name] = value;

        context.commit('set', {
          key: 'customization',
          value: customizationData,
          name,
        });

        return;
      }

      const response = await context.state.client.post('core/setconfigitem', {
        name,
        value: typeof value === 'object' ? JSON.stringify(value) : value,
      });

      if (response.ok) {
        let customizationData = { ...customizationFromState };
        customizationData[name] = value;

        if (!remoteOnly) {
          context.commit('set', {
            key: 'customization',
            value: customizationData,
            name,
          });
        }
      }
      return response;
    },

    async getUserCustomizations(context) {
      const response = await context.state.client.get(
        'core/getusercustomizationdata'
      );

      if (response.ok) {
        context.commit('set', {
          key: 'userCustomization',
          value: response.data.customdata,
        });
      }
    },

    async beginProfileCreation(context) {
      const response = await context.state.client.post(
        'core/beginprofilecreation'
      );

      // TODO: handle response conditionally
      return response;
    },

    async newAccount(context, payload) {
      const response = await context.state.client.post(
        'core/createprofile',
        payload
      );

      // TODO: handle response conditionally
      return response;
    },

    async unlinkMsTeams(context) {
      const response = await context.state.client.post(
        'core/msteamsunlinkprofile'
      );

      return response;
    },

    async getProfileSummary(context) {
      // #todo: check for new API

      // Get disk usage, files count
      const responseUsage = await context.state.client.get(
        'core/getdiskusagedetails',
        { level: 2 }
      );
      context.commit('set', {
        key: 'usageSummary',
        value: Object.assign(
          context.state.usageSummary,
          responseUsage.data.usage
        ),
      });
    },

    async getEditors(context, payload) {
      // fetch to not allow axios built-in XSRF protection
      fetch('http://127.0.0.1:34320/v1/fileassociations', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((data) => data.json())
        .then((data) => {
          let result = data.response;
          if (result && !_.isEmpty(result.fileassociations)) {
            context.commit('set', {
              key: 'editorsClient',
              value: result.fileassociations,
            });
          }
        })
        .catch((err) => {
          console.log('Edit in desktop app not running!');
        });
    },

    async getToasts(context, payload) {
      const response = await this.state.core.client.get(
        'core/gettoasts',
        payload
      );

      if (response.ok) {
        context.commit('set', {
          key: 'toasts',
          value: response.data,
        });

        const loadedToasts = response.data;
        const toasts = loadedToasts;

        if (typeof toasts === 'undefined') {
          await context.commit('set', {
            key: 'toastsUnread',
            value: 0,
          });
        } else {
          const toastRecords = getRecords(toasts.toast);
          let unreadCount = 0;

          toastRecords.forEach(function (elem) {
            unreadCount += (elem && elem.alreadyread) === 0 ? 1 : 0;
          });

          await context.commit('set', {
            key: 'toastsUnread',
            value: unreadCount,
          });
        }

        await context.commit('set', {
          key: 'toastsLastLoaded',
          value: dayjs().toISOString(),
        });
      }
    },

    async ackToasts(context, payload) {
      await this.state.core.client.post('core/acktoasts', payload);
    },

    async getNotice(context, payload) {
      return await this.state.core.client.post('core/getrssfeed', payload);
    },

    async getUserDeviceInfo(context, rmcid) {
      const response = await this.state.core.client.post('core/getrmcclient', {
        remote_client_id: rmcid,
      });

      return response.data.rmc_client;
    },

    async getUserDevices(context, { userid, start = 0, end = 10 }) {
      const isLimitedUser = this.state.core.systemstatus.LIMITEDUSER;
      if (isLimitedUser) return;
      const response = await this.state.core.client.post('core/getrmcclients', {
        start: start,
        end: end,
        userid: userid,
      });

      await context.commit('set', {
        key: 'connectedDevices',
        value: response.data,
      });

      let arr = [];

      if (response.data.rmc_client) {
        if (!Array.isArray(response.data.rmc_client)) {
          arr.push(response.data.rmc_client);
        } else {
          arr = response.data.rmc_client;
        }
      }

      return arr;
    },

    async approveDevice(context, rmcid) {
      const response = await this.state.core.client.post(
        'core/approvedeviceaccess',
        {
          remote_client_id: rmcid,
        }
      );
      return response.data.message;
    },

    async removeDevice(context, rmcid) {
      await this.state.core.client.post('core/deletedevice', {
        remote_client_id: rmcid,
      });
    },

    setUseRtl(context, useRtl) {
      context.commit('set', {
        key: 'useRtl',
        value: useRtl,
      });
    },

    setUsingAdBlocker(context) {
      context.commit('set', {
        key: 'usingAdBlocker',
        value: true,
      });
    },
  },
  getters: {
    isLockDisabled: (state) => {
      return !!state.systemstatus.DISABLELOCK;
    },
    isWebEditEnabled: (state) => {
      return !!state.customization.ENABLEWEBEDIT;
    },
    isWOPIEditEnabled: (state) => {
      return !!state.customization.ENABLEWOPIEDIT;
    },
    WOPIClient: (state) => {
      return WOPIClients[state.customization.WOPICLIENT];
    },
    allowQuickEditAll: (state) => {
      return !!state.customization.ALLOWQUICKEDITALL;
    },
    getGoogleDocFormatInfo: () => (format) => {
      return GoogleDocsFormats[format];
    },
    isGoogleDocsEnabled: (state) => {
      return state.customization.ENABLEGOOGLEDOCS;
    },
    getEditorClientName: (state) => (file) => {
      const clientName = state.editorsClient[file.ext];
      return clientName && clientName !== 'Pick an App'
        ? `Desktop (${clientName})`
        : 'Desktop';
    },
    isLimitedUser: (state) => {
      return !!state.systemstatus.LIMITEDUSER;
    },
    getCalendar: (state) => {
      return state.fullSystemStatus?.calendartype;
    },
    getCurrentDateFormat: (state) => {
      return (
        state.fullSystemStatus?.dateformat ||
        state.dateFormats.find((dateFormat) => !!dateFormat.current).definition
      );
    },
    getDateFormatList: (state, getters) => {
      let dateFormats = state.dateFormats;
      if (dateFormats) {
        dateFormats = dateFormats.filter((dateFormat) =>
          dateFormat.calendar.includes(getters.getCalendar)
        );
        return dateFormats.map(({ definition, sample }) => {
          return {
            dateFormat: definition,
            label: `${definition.toLowerCase()} (${sample})`,
          };
        });
      }
      return [];
    },
    getCurrentTimeFormat: (state) => {
      return (
        state.fullSystemStatus?.timeformat ||
        state.timeFormats.find((timeFormat) => !!timeFormat.current).definition
      );
    },
    getTimeFormatList: (state, getters) => {
      let timeFormats = state.timeFormats;
      if (timeFormats) {
        timeFormats = timeFormats.filter((timeFormat) =>
          timeFormat.calendar.includes(getters.getCalendar)
        );
        return timeFormats.map(({ definition, sample }) => {
          return {
            timeFormat: definition,
            label: `${definition} (${sample})`,
          };
        });
      }
      return [];
    },
    isShowMoreLoginOptionsEnabled: (state) => {
      return state.customization?.SHOWMORE == 1;
    },
    maxDrmShareFileSize: (state) => state.fullSystemStatus?.drmviewermaxfilesizemb || 20,
    isDrmWebViewerEnabled: (state) => state.fullSystemStatus?.drmwebviewer || false,
    startSearchInCurrentlocation: ({ fullSystemStatus }) => {
      return (
        !!fullSystemStatus?.startsearchesoncurrentnavigationlocation || false
      );
    },
    isCommunityEdition: (state) => {
      return state.customization?.isCommunityEdition() == 1;
    },
    getZipFolderPasswordSession: (state) => {
      return state.fullSystemStatus?.zipfolderpasswordsession;
    },
    getResumableUploadExpiry: (state) => {
      return state.fullSystemStatus?.resumableuploadexpiry;
    },
    isHostedCloud: ({ customization }) => !!customization?.HOSTEDCLOUD || false,
    isTrialMode: ({ customization }) => !!customization?.TRIALMODE || false,
    hasCustomFooter: ({ customHeaderFooter }) =>
      !_.isEmpty(customHeaderFooter.header),
    hasCustomHeader: ({ customHeaderFooter }) =>
      !_.isEmpty(customHeaderFooter?.footer),
    defaultSortDir: (state) =>
      state?.customization?.DEFAULT_SORT_DIR == -1 ? -1 : 1,
  },
};
