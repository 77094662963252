<template>
  <div
    class="d-flex align-items-center justify-content-start info-box px-0"
    :class="{
      [type]: true,
      vertical: vertical,
    }"
  >
    <div class="icon-space col-xm-1 px-0">
      <Icon
        class="fa-info-box"
        family="fas"
        :name="iconType ? iconType : _iconType"
      />
    </div>
    <div class="content-space col-xm-11 px-2">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import Icon from '../components/Icon';
export default {
  components: {
    Icon,
  },
  props: {
    type: {
      type: String,
      default: 'alert',
      validator: function (value) {
        return ['info', 'alert', 'warning'].indexOf(value) !== -1;
      },
    },
    iconType: {
      //allow overwrite default icons
      type: String,
      default: '',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _iconType() {
      const iconType = {
        info: 'info-circle',
        alert: 'exclamation-triangle',
        warning: 'exclamation-circle',
      };
      return iconType[this.type] || 'exclamation-circle';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../scss/_colors.scss';

.info-box {
  border-radius: 4px;
  padding: 10px !important;
  &.alert,
  &.warning {
    background-color: rgba($alert-red, 0.1);
    color: $alert-red;
  }
  &.info {
    padding: 10px !important;
    background-color: var(--bg-read-only);
    color: var(--text-medium);
  }
  &.vertical {
    flex-direction: column;
    .icon-space {
      padding-bottom: 0.5rem;
    }
    padding-bottom: 0.5rem;
  }

  .icon-space {
    font-size: 24px;
  }
  .content-space {
    font-size: 0.813rem;
  }
}
</style>
