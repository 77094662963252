<template>
  <div class="max-height">
    <div v-if="!loading" class="metadata-search col-md-12 p-0 m-0">
      <div class="row font-size-top p-0 pb-4 pr-0 mr-0">
        <div class="col-md-6 pl-3 pr-2 p-0 select-metadata-set">
          <label class="select-label" for="selectedMetadataSet">{{
            $t('Metadata Set')
          }}</label>
          <div class="form-control form-control--select form-control-sm">
            <select
              id="selectedMetadataSet"
              ref="selectedMetadataSet"
              v-model="selectedMetadataSet"
              :disabled="!hasMetadataSetAvailable"
            >
              <option :value="{}" selected disabled hidden>
                {{
                  hasMetadataSetAvailable
                    ? $t('Please select')
                    : $t('No metadata available')
                }}
              </option>
              <option
                v-for="set in availableSetNames"
                :key="set.id"
                :value="set"
              >
                {{ set.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-6 p-0 pr-0 mr-0 pl-2 select-atribute">
          <label class="select-label" for="selectedAttribute">{{
            $t('Attribute')
          }}</label>
          <div class="form-control form-control--select form-control-sm">
            <select
              id="selectedMetadataSet"
              v-model="selectedAttribute"
              :disabled="!selectedMetadataSet.name"
            >
              <option :value="{}" selected disabled hidden>
                {{ $t('Please select') }}
              </option>
              <option
                v-for="set in availableAttributes"
                :key="set.id"
                :value="set"
              >
                {{ set.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row pb-4">
        <div class="col-md-12">
          <MetadataConditionInput
            :key="updated"
            :condition="currentCondition"
            :current-set="selectedMetadataSet"
            @addMetadataCondition="addMetadataCondition"
            @anyKeyChecked="anyKeyChecked"
            @clearErrors="clearErrors()"
          />
          <div
            v-if="conditionError !== null"
            class="mt-2 text-left text-danger"
          >
            {{ conditionError }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <MetadataConditionListing
            :conditions="conditions"
            @removeCondition="removeMetadataCondition"
          />
        </div>
      </div>
    </div>

    <Loader v-if="loading" :loading="loading" full />
  </div>
</template>

<script>
import MetadataConditionInput from './MetadataConditionInput';
import MetadataConditionListing from './MetadataConditionListing';
import Loader from 'common/components/Loader';
import { setFocus } from 'common/utils/a11yUtils';
import { getRecords } from 'common/utils/arrayUtils';
import _ from 'lodash';

export default {
  components: {
    MetadataConditionInput,
    MetadataConditionListing,
    Loader,
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      availableAttributes: [],
      selectedAttribute: {},
      searchConditions: {},
      currentCondition: {},
      currentConditionName: '',
      selectedMetadataSetName: '',
      selectedMetadataSet: {},
      conditions: [],
      conditionError: null,
      updated: 0,
      useAnyColor: false,
    };
  },
  computed: {
    metadataSets: function () {
      return getRecords(this.$store.state.files.availableMetadataSets)[0];
    },
    metadataSetCount: function () {
      return this.metadataSets.meta.total;
    },
    hasMetadataSetAvailable() {
      return this.metadataSetCount > 0;
    },
    availableSetNames: function () {
      if (
        this.metadataSetCount > 0 &&
        typeof this.$store.state.files.availableMetadataSets !== 'undefined'
      ) {
        const availableMetadataSets = getRecords(
          this.$store.state.files.availableMetadataSets
        )[0];

        return availableMetadataSets.metadataset.map((set) => ({
          id: set.id,
          name: set.name,
        }));
      } else {
        return [];
      }
    },
  },
  watch: {
    selectedMetadataSet: function (vnew) {
      this.selectedMetadataSet = vnew;
      this.selectedAttribute = {};
      if (this.conditionError) this.conditionError = null;
      this.onLoadAttributesForSet(vnew);
    },
    selectedAttribute: function (vnew) {
      if (this.conditionError) this.conditionError = null;
      this.onLoadAttributeForEditing(vnew);
    },
  },
  mounted() {
    setFocus(this.$refs.selectedMetadataSet);
  },
  methods: {
    tagToArray(setidx, fieldidx) {
      if (this.currentMetadata[setidx].attributes[fieldidx].value.length) {
        let arr =
          this.currentMetadata[setidx].attributes[fieldidx].value.split(', ');
        let outArr = [];

        arr.forEach((item, index) => {
          outArr.push({
            text: item,
            idx: index,
          });
        });

        return outArr;
      } else {
        return;
      }
    },
    anyKeyChecked(context) {
      this.useAnyColor = context;
      this.conditionError = '';
      this.$emit('applyAnyColorConditions', this.useAnyColor);
    },
    addMetadataCondition(context) {
      if (!_.isEmpty(this.currentCondition) && !_.isEmpty(context.value)) {
        if (
          this.useAnyColor ||
          !this.conditions.some(
            (condition) => condition.attributeid === context.attributeid
          )
        ) {
          context.index = this.conditions.length;
          this.conditions.push(context);
          this.$emit('applyMetadataConditions', this.conditions);
        } else {
          if (!this.useAnyColor && context.name == 'Color') {
            this.conditionError = this.$t(
              "Select 'Any' option to search for files with at least one of the following color tags."
            );
            return;
          }
          this.conditionError = this.$t(
            'The condition for selected attribute is already defined'
          );
        }
      }
    },
    removeMetadataCondition(index) {
      this.conditions.splice(index, 1);
      this.conditions = [...this.conditions];
    },
    getMetadataSetAttributes(inputset) {
      const fields = [
        'attributeid',
        'name',
        'description',
        'value',
        'type',
        'required',
        'disabled',
        'enumvalues',
      ];

      let attributes = [];

      for (var b = 0; b < inputset.attributes_total; b++) {
        attributes[b] = {};

        for (var c = 0; c < fields.length; c++) {
          const field = fields[c];
          attributes[b][field] = inputset[`attribute${b}_${field}`];
          attributes[b].index = b;

          if (attributes[b].type === 7) {
            attributes[b].editing = '';
            attributes[b].updated = 0;
          }

          // Enum values
          if (attributes[b].type === 6) {
            attributes[b].enumvalues = '';
            for (
              var d = 0;
              d < inputset[`attribute${b}_predefinedvalues_total`];
              d++
            ) {
              attributes[b].enumvalues +=
                inputset[`attribute${b}_predefinedvalue${d}`] + ',';
            }
            // chop off that last ,
            attributes[b].enumvalues = attributes[b].enumvalues.slice(0, -1);
          }
        }
      }

      return attributes;
    },
    selectSet(setName) {
      return this.metadataSets.metadataset.find(function (element) {
        return element.name === setName;
      });
    },
    async onLoadAttributesForSet(set) {
      this.selectedMetadataSetName = set.name;
      this.selectedMetadataSet = set;

      const selectedSet = this.selectSet(set.name);

      this.availableAttributes = this.getMetadataSetAttributes(selectedSet);

      this.currentConditionName = '';
      this.currentCondition = {};
    },
    async onLoadAttributeForEditing(attribute) {
      this.currentConditionName = attribute.name;
      this.currentCondition = this.selectedAttribute;
      this.updated++;
    },
    clearErrors() {
      this.conditionError = null;
    },
  },
  async onDropAction() {},
};
</script>

<style lang="scss" scoped>
.metadata-search {
  overflow-y: none;
}
.font-size-top {
  font-size: 14px;
  font-weight: 400;
}
select:required:invalid {
  color: gray;
}
option[value=''][disabled] {
  display: none;
}
option {
  color: black;
}
.max-height {
  max-height: 500px;
}

.select-metadata-set,
.select-atribute {
  .select-label {
    color: var(--text-mid-dark);
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    height: 20px;
    text-align: left;
    width: 100%;
  }
}

.select-atribute {
  @media screen and (max-width: 767px) {
    padding-left: 1rem !important;
    margin-top: 13px;
    padding-right: 0.5rem !important;
  }
}
</style>
