var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"transactions-list-item",class:{
    [`transaction-list-item--preparing`]: _vm.transaction.status === 'preparing',
  }},[_c('div',{staticClass:"transaction-icon"},[_c('Icon',{attrs:{"family":"fas","name":"folder-plus"}}),(
        _vm.transaction.status !== 'processing' &&
        _vm.transaction.status !== 'preparing'
      )?_c('div',{staticClass:"transaction-icon-status",class:{
        [`transaction-icon-status--error`]: _vm.transaction.status === 'failed',
        [`transaction-icon-status--cancelled`]:
          _vm.transaction.status === 'cancelled',
      }},[(_vm.transaction.status === 'failed')?_c('Icon',{attrs:{"family":"fas","name":"times"}}):(_vm.transaction.status === 'cancelled')?_c('Icon',{attrs:{"family":"fas","name":"ban"}}):_c('Icon',{attrs:{"family":"fas","name":"check"}})],1):_vm._e()],1),_c('div',{staticClass:"transaction-info"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltipContent }),expression:"{ content: tooltipContent }"}],staticClass:"transaction-info-title"},[_vm._v(" "+_vm._s(_vm.currentPath !== _vm.transaction.dirpath ? `${_vm.transaction.dirpath}/` : '')+_vm._s(_vm.transaction.name)+" "),_c('small',[_vm._v(_vm._s(_vm.transaction.entries)+" items")])]),(_vm.transaction.status === 'preparing')?_c('span',{staticClass:"description text-truncate"},[_vm._v(" "+_vm._s(_vm.$tc( '{count} item inside this folder will be processed soon | {count} items inside this folder will be processed', _vm.transaction.entries ))+" ")]):_vm._e()]),_c('div',{staticClass:"transaction-actions"},[(
        _vm.transaction.status !== 'cancelled' &&
        _vm.transaction.status !== 'failed' &&
        _vm.transaction.status !== 'completed'
      )?_c('button',{staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Cancel operation')},on:{"click":_vm.cancel}},[_c('Icon',{attrs:{"family":"fas","name":"ban"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"ban"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }