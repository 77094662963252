<template>
  <div class="markdown-content" ref="compiledMarkdownContent" v-html="compiledMarkdown"></div>
</template>

<script>
import { marked } from 'marked';
import { applyWikiImages } from 'common/utils/files';

export default {
  props: {
    previewContent: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '/',
    }
  },
  data() {
    return {
      content: this.previewContent,
    };
  },
  computed: {
    compiledMarkdown() {
      return applyWikiImages(marked(this.previewContent, { sanitize: true }), this.path);
    },
  },
};
</script>
