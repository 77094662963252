import router from '../../../router.js';
import store from '../../../store/index.js';
import confetti from 'canvas-confetti';

export default {
  title: 'Uploading a File',
  id: 'onboarding.file-basics.file-upload',
  steps: [
    {
      id: 'onboarding.file-basics.folder-creation.navigate-my-files',
      description: 'Navigate to "My Files"',
      shape: 'circle',
      element:
        '#folderSummary > div.folder-list.folder-list--spaced.row > div:nth-child(1) > a',

      onStepStart: () => {
        const name = router.history.current.name;
        if (name === 'files-sub') {
          store.dispatch('onboarding/nextStep');
        } else if (name !== 'files') {
          //page that has left sidebar
          store.commit('onboarding/updateStep', {
            id: 'onboarding.file-basics.folder-creation.navigate-my-files',
            element:
              '.sidebar-sticky > .scrollable-area > .page_wrap:first-child .droppable > .tree-menu',
            shape: 'rectangle',
          });
        }
      },
      trigger: {
        triggerOn: 'files/set',
        onTrigger: function (payload) {
          const { path } = payload;
          const user = store.state.auth.user;

          return path === `/${user.peerid}`;
        },
      },
    },
    {
      id: 'onboarding.file-basics.file-upload.chose-operation',
      description: 'Choose the "Upload File" operation',
      shape: 'rectangle',
      element: '#fcExplorerHeader > .nav-body > .explorer-cta .trigger > .btn',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.file-basics.file-upload.dropdown-selection',
      shape: 'tooltip',
      animation: 0,
      element: 'div > div.dropdown-item.dropdown-item-focus-0',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.file-basics.file-upload.upload-file',
      shape: 'tooltip',
      element: null,
      trigger: {
        triggerOn: 'files/addTransaction',
        onTrigger: function (payload) {
          return payload.type === 'upload';
        },
      },
      onStepCompleted() {
        store.commit('files/setTransactionsBar', {
          key: 'visible',
          value: 'true',
        });
        store.commit('files/setTransactionsBar', {
          key: 'expanded',
          value: 'true',
        });
      },
    },
    // {
    //   description: 'Expand the File Operations',
    //   shape: 'circle',
    //   // animation: 0,
    //   element:
    //     '.transactions-container > .transactions-wrapper > .transactions-header.transactions-header--minified .transactions-header-actions .btn-minimize',
    //   trigger: {
    //     triggerOn: 'files/setTransactionsBar',
    //     onTrigger: function (payload) {
    //       const { key, value } = payload;

    //       return key === "expanded" && value === true;
    //     },
    //   },
    // },
  ],
  onTopicCompleted() {
    store.commit('onboarding/set', {
      type: 'isExpanded',
      status: true,
    });

    confetti({
      particleCount: 100,
      zIndex: 9999999,
      origin: { x: 0.9, y: 1 },
    });
  },
};
