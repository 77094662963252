const isMac = window.navigator.platform === 'MacIntel';
const ctrlCmd = isMac ? 'meta' : 'ctrl';

export const globalShortcuts = {
  'toggle-sidebar': ['['],
  'toggle-progress': ['p'],
  'toggle-shortcut-help': ['alt', 'f1'],
  'history-next': ['alt', 'arrowright'],
  'history-before': ['alt', 'arrowleft'],
};

export const headerShortcuts = {
  'upload-file': ['u'],
  // option + r on Mac
  'refresh-page': isMac ? ['alt', '®'] : ['alt', 'r'],
  'upload-file-insert': ['insert'],
  // shift + n on Mac
  'create-folder': isMac ? ['shift', 'n'] : ['alt', 'n'],
};

export const navigationShortcuts = {
  'navigate-up': ['arrowup'],
  'navigate-down': ['arrowdown'],
  'page-down': ['pagedown'],
  'page-up': ['pageup'],
  'page-home': [ctrlCmd, 'home'],
  'folder-up': isMac ? ['alt', 'shift', 'arrowup'] : ['alt', 'arrowup'],
  'select-all': ['shift', 'a'],
  'rename-item': ['f2'],
  'download-item': ['d'],
  'delete-item': ['del'],
  'share-item': ['.'],
  'open-menu': ['m'],
  'toggle-lock': ['l'],
  'add-comment': ['c'],
  'copy-item': [ctrlCmd, 'c'],
  'move-item': [ctrlCmd, 'x'],
  'folder-properties': [ctrlCmd, 'i'],
  // option + d on Mac
  'open-deleted': isMac ? ['alt', '∂'] : ['alt', 'd'],
  // option + f on Mac
  'filter-file': isMac ? ['alt', 'ƒ'] : ['alt', 'f'],
};
