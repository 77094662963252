import Alert from 'common/components/Alert';
import FileIcon from 'common/components/FileIcon';
import Icon from 'common/components/Icon';
import Themed from '../components/Themed';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { Drag } from 'vue-drag-drop';

export default {
  components: {
    Drag,
    FileIcon,
    Icon,
    Themed,
    Alert,
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: Boolean,
    // currently selected items
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    hasCustomList: {
      type: Boolean,
      default: false,
    },
    hasSelection: {
      type: Boolean,
      default: false,
    },
    isSharedByMe: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragActive: false,
      details: false,
      hoverTimeout: null,
      isRenaming: false,
      newName: '',
      isDeleting: false,
    };
  },
  computed: {
    ...mapGetters('files', ['getIsGrid']),
    user() {
      return this.$store.state.auth.user;
    },
    isSelected() {
      return _.findIndex(this.selection, { id: this.item.id }) > -1;
    },
    itemsSelected() {
      return this.selection ? this.selection.length : 0;
    },
    thumb() {
      return '';
    },
  },
  watch: {
    item(newItem, oldItem) {
      if (this.isDeleting && newItem.name !== oldItem.name) {
        this.isDeleting = false;
      }
    },
  },
  mounted() {
    this.$store.commit('files/toggleSidebar', false);
  },
  methods: {
    triggerAction(action) {
      this[action]();
    },
    browse() {
      this.$emit('browsed', this.item);
    },
  },
};
