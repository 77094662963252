import Vue from 'vue';
import dayjs from 'dayjs';
import qs from 'query-string';
import _ from 'lodash';

import { v4 as uuid } from 'uuid';
import { priorityQueue, queue } from 'async-es';
import { getRecords } from 'common/utils/arrayUtils';
import { grids } from 'constants/sizes';
import { handleArrayResponse } from '../../utils/dataUtils';
import router from '../../router';

import {
  getFileFromEntry,
  getEntriesFromFolder,
  sanitizeFileName,
  getFileType,
  getTimeout,
  getFileNameFromPath,
} from 'common/utils/files';

import FileChunks from 'common/utils/fileChunks';

import { sleep } from 'common/utils/asyncUtils';

import {
  itemsPerPage,
  chunkSize,
  transactionStatusPriority,
} from '../../constants/listing.js';
import { MAX_SEARCH_ENTRIES } from '../../constants/searchResults';

import { officeExt, previewExt } from 'common/utils/fileExtensions';

import serverURL from 'common/filecloud/properties/serverURL';
import { encodeAll } from 'common/utils/URLUtils';

import { i18n } from '@/i18n';

export default {
  namespaced: true,
  state: {
    paths: {},
    renamed: {},
    transactions: [],
    queue: priorityQueue(async (task, callback) => {
      await task();
      callback(true);
    }, 1),
    // queue for download, move, copy, delete operations
    othersQueue: queue(async (task, callback) => {
      await task();
      callback(true);
    }, 1),
    sidebar: {
      current: 'activity',
      open: true,
      path: null,
      name: null,
      selected: [],
      previewContent: '',
      lastSelected: null,
    },
    showMobileOverlay: false,
    mobileMenu: {
      open: false,
      isRoot: false,
      favorite: false,
      item: [],
      actions: [],
      target: {},
      component: {},
    },
    mobileNewFileMenu: {
      open: false,
      actions: [],
      target: {},
      component: {},
    },
    showMobileSearchbar: false,
    transactionsBar: {
      currentSection: 'upload',
      visible: false,
      expanded: false,
      autoToggle: true, // if user have not interacted, automatically toggle
    },
    sort: {
      field: 'name',
      type: 1,
    },
    selectedItem: { path: 'empty' },
    allFiles: [],
    showItemDetailsSidebar: false,
    loadedCommentsForSelectedItem: [],
    loadedActivitiesForSelectedItem: [],
    loadedRetentionPoliciesForSelectedItem: [],
    shareInfoOfFile: [],
    managedShareInfo: [],
    permissionOfFile: [],
    versionsOfFile: [],
    sidebarKey: 0,
    starredFiles: [],
    recentFiles: [],
    notificationInfoOfFile: [],
    searchResultsMeta: {},
    searchResultsEntry: [],
    searchQuery: [],
    searchLastBrowsed: {},
    searchId: '',
    sharedByMe: [],
    lockedFiles: [],
    aclFiles: [],
    sharePageActive: false,
    isDraggingFile: false,
    availableMetadataSets: [],
    drmExportResponseProperties: {},
    drmExportResponseDialogOpen: false,
    drmListFilterParams: [],
    drmListModalOpen: false,
    lastUploadTime: 0,
    lastUploadedFile: {},
    layoutType: '',
    searchCancelled: null,
    searchInProgress: false,
    isRedirectedToSerachPage: false,
    deleteConfirmationModal: {
      show: false,
      buttons: [],
    },
    zipFolderPasswordMap: {},
    zipPreviewOpen: false,
    currentFile: {},
  },
  mutations: {
    set: (state, { path, meta, files }) => {
      Vue.set(state.paths, path, {
        meta,
        files,
      });
    },
    dropFiles: (state, { path }) => {
      let paths = { ...state.paths };
      delete paths[path];

      Vue.set(state, 'paths', paths);
    },

    setRoot(state, { key, value }) {
      Vue.set(state, key, value);
    },
    add: (state, payload) => {
      const files = state.paths[payload.dirpath]
        ? state.paths[payload.dirpath].files
        : [];

      const file = _.find(files, { name: payload.name });

      if (files && !file) {
        state.paths[payload.dirpath].files = _.sortBy(
          [...files, payload],
          ['type', (item) => item[state.sort.field].toLowerCase()],
          ['asc', state.sort.type === 1 ? 'asc' : 'desc']
        );
      }
    },

    // update single file
    update: (state, payload) => {
      const { id, dirpath } = payload;

      if (state.paths[dirpath] && state.paths[dirpath].files[id]) {
        delete payload.id;
        delete payload.dirpath;

        Object.keys(payload).forEach((key) => {
          Vue.set(state.paths[dirpath].files[id], key, payload[key]);
        });
      }
    },

    addToStore: (state, { key, value }) => {
      Vue.set(state, key, value);
    },

    addSearchMeta: (state, payload) => {
      state.searchResultsMeta = payload;
    },

    addSearchResults: (state, payload) => {
      state.searchResultsEntry = payload;
    },

    pushToStore: (state, { key, value }) => {
      state[key].push(value);
    },

    updateStore: (state, { key, value, id }) => {
      value = value.filter((v) => v.id !== id);
      Vue.set(state, key, value);
    },

    // transactions
    addTransaction: (state, payload) => {
      if (!state.transactionsBar.visible) {
        state.transactionsBar.visible = true;
        state.transactionsBar.expanded = false;
      }

      state.transactionsBar.currentSection = payload.group;

      state.transactions.push({
        ...payload,
        status_priority: transactionStatusPriority[payload.status],
        added: dayjs().unix(),
      });
    },

    removeTransaction: (state, id) => {
      const index = _.findIndex(state.transactions, { id });
      Vue.delete(state.transactions, index);
    },

    setTransaction: (state, payload) => {
      const index = _.findIndex(state.transactions, { id: payload.id });

      if (index >= 0) {
        delete payload.id;

        const newTransaction = { ...state.transactions[index], ...payload };

        Vue.set(state.transactions, index, newTransaction);

        if (payload.status) {
          Vue.set(
            state.transactions[index],
            'status_priority',
            transactionStatusPriority[payload.status]
          );
        }
      }
    },

    filterShares: (state, item) => {
      state.sharedByMe = state.sharedByMe.filter(
        (share) => share.shareid !== item.shareid
      );
    },

    showMobileOverlay: (state) => {
      state.showMobileOverlay = true;
    },

    hideMobileOverlay: (state) => {
      state.showMobileOverlay = false;
    },

    showMobileSearch: (state) => {
      state.showMobileSearchbar = true;
    },

    hideMobileSearch: (state) => {
      state.showMobileSearchbar = false;
    },

    // sidebar
    toggleSidebar: (state, open) => {
      let futureState = typeof open === 'boolean' ? open : !state.sidebar.open;
      state.sidebar.open = futureState;
    },
    setSidebar: (state, { key, value }) => {
      Vue.set(state.sidebar, key, value);
    },
    setTransactionsBar: (state, { key, value }) => {
      // disable auto toggle
      state.transactionsBar.autoToggle = false;

      Vue.set(state.transactionsBar, key, value);
    },
    // mobile menu
    toggleMobileMenu: (state, open) => {
      let futureState =
        typeof open === 'boolean' ? open : !state.mobileMenu.open;
      state.mobileMenu.open = futureState;
    },
    setMobileMenu: (state, { key, value }) => {
      Vue.set(state.mobileMenu, key, value);
    },

    //New file menu
    toggleNewFileMenu: (state, open) => {
      let futureState =
        typeof open === 'boolean' ? open : !state.mobileNewFileMenu.open;
      state.mobileNewFileMenu.open = futureState;
    },
    setNewFileMenu: (state, { key, value }) => {
      Vue.set(state.mobileNewFileMenu, key, value);
    },

    addToPermissionOfFile(state, { path, data }) {
      const newPermissions = { ...state.permissionOfFile, [path]: data };
      Vue.set(state, 'permissionOfFile', newPermissions);
    },
    removePermissionOfFile(state, path) {
      const newPermissions = { ...state.permissionOfFile };
      delete newPermissions[path];
      Vue.set(state, 'permissionOfFile', newPermissions);
    },
    setZipPreviewOpen(state, status) {
      state.zipPreviewOpen = status;
    },
    addZipFolderPassword(state, { path, password }) {
      state.zipFolderPasswordMap[path] = password;
    },
    resetCurrentFile(state) {
      const item = {
        name: getFileNameFromPath(state.filesMeta?.realpath),
        type: 'dir',
        path: state.filesMeta?.realpath,
        cansetacls: state.filesMeta?.cansetacls,
      };
      Vue.set(state, 'currentFile', item);
    },
  },
  actions: {
    async exportfilelistcsv(context, path) {
      const url = `${serverURL.domainURL}/core/exportfilelistcsv?path=${path}`;
      window.open(url);
    },
    setLayoutType({ commit }, type) {
      commit('setRoot', {
        key: 'layoutType',
        value: type,
      });
    },
    async getDirectLink(context, payload) {
      let response = await this.state.core.client.getUnencoded(
        'core/getsharedlink',
        payload
      );

      if (response.ok) {
        response.data = handleArrayResponse(response.data)[0];
        if (response.data.message.slice(-1) === '/') {
          response.data.message = response.data.message.substring(
            0,
            response.data.message.length - 1
          );
        }
      }

      return response;
    },
    async getEffectivePolicy(context, payload) {
      // Payload: path: $path, filter: $filter
      const response = await this.state.core.client.get('core/getacl', payload);
      return response;
    },
    async getAllAcls(context, payload) {
      // Payload: path: $path, filter: $filter
      const response = await this.state.core.client.post(
        'core/getacls',
        payload
      );
      if (response.ok) {
        let aclFiles = response.data.acl || [];

        if (aclFiles && !Array.isArray(aclFiles)) {
          aclFiles = [aclFiles];
        }

        aclFiles = aclFiles.map((file, id) => {
          file.id = id;
          return file;
        });
        context.commit('addToStore', {
          key: 'aclFiles',
          value: aclFiles,
        });
        return response;
      }
    },
    async getAclForPath(context, payload) {
      // Payload: path: $path, filter: $filter
      const response = await this.state.core.client.post(
        'core/getacl',
        payload
      );
      return response;
    },
    async setAclInheritanceForPath(context, payload) {
      // Payload: path: $path, inherit: $filter
      const response = await this.state.core.client.post(
        'core/setaclinheritance',
        payload
      );
      return response;
    },
    async addAclEntryForPath(context, payload) {
      // Payload: path: $path, type: $type, value: $value, perm: $perm, flag: $flag
      const response = await this.state.core.client.post(
        'core/addaclentry',
        payload
      );
      return response;
    },
    async deleteAclForPath(context, payload) {
      // Payload: path: $path, type: $type, value: $value, perm: $perm, flag: $flag
      const response = await this.state.core.client.post(
        'core/deleteacl',
        payload
      );
      return response;
    },
    async deleteAclEntryForPath(context, payload) {
      // Payload: path: $path, type: $type, value: $value, perm: $perm, flag: $flag
      const response = await this.state.core.client.post(
        'core/deleteaclentry',
        payload
      );
      return response;
    },
    async getEffectiveAclEntryForPath(context, payload) {
      // Payload: path: $path,
      const response = await this.state.core.client.post(
        'core/geteffectiveacl',
        payload
      );
      return response;
    },
    async searchTermSearch(context, payload) {
      // Payload: path: $path,
      const response = await this.state.core.client.post(
        'core/searchtermsearch',
        payload
      );
      return response;
    },
    async loadLastQuery(context, payload) {
      return context.state.searchQuery.searchstring;
    },
    async downloadSearchResults(context, payload) {
      const url = `${serverURL.domainURL}/core/downloadsearchresults?searchresultid=${payload}`;
      window.open(url);
    },
    async search({ commit, state, dispatch }, payload) {
      await dispatch('checkIfSearchIsRunning');

      let response = await this.state.core.client.post(
        'core/dosearch',
        payload
      );

      commit('addToStore', {
        key: 'searchId',
        value: response.data.meta.id,
      });

      if (typeof payload.refresh !== 'undefined' && payload.refresh === 1) {
        payload.refresh = 0;
      }
      if (response.ok) {
        commit('addToStore', {
          key: 'searchQuery',
          value: payload,
        });
        sessionStorage.setItem('lastSearch', JSON.stringify(payload));
      }

      while (
        response.ok &&
        !['COMPLETE', 'ERROR', 'CANCELLED'].includes(response.data.meta.status)
      ) {
        if (state.searchCancelled === true) {
          return commit('addToStore', {
            key: 'searchCancelled',
            value: false,
          });
        }

        await getTimeout(2500);

        response = await this.state.core.client.post('core/dosearch', payload);
        if (response.data.entry?.length > 1) {
          dispatch('addRestultsToStore', response.data);
          dispatch('redirectToSearchPageOnce');
        }
      }

      if (response.data.meta.status == 'COMPLETE' && response.ok) {
        dispatch('finishSearchAndClean', response.data);
        state.searchInProgress = false;
      }

      if (response.ok) {
        dispatch('redirectToSearchPage');
        state.searchInProgress = false;
        return;
      }
    },

    addRestultsToStore({ commit, state }, payload) {
      commit('addSearchMeta', payload.meta);
      if (
        state.searchResultsEntry.length < MAX_SEARCH_ENTRIES &&
        handleArrayResponse(payload?.entry).length > 0
      ) {
        commit('addSearchResults', handleArrayResponse(payload.entry));
      }
    },

    finishSearchAndClean({ commit, state }, payload) {
      commit('addSearchMeta', payload.meta);
      if (state.searchResultsEntry.length < MAX_SEARCH_ENTRIES)
        commit('addSearchResults', handleArrayResponse(payload.entry));
    },

    async checkIfSearchIsRunning({ commit, state, dispatch }) {
      if (state.searchInProgress) await dispatch('cancelSearch');
      commit('addToStore', {
        key: 'searchInProgress',
        value: true,
      });
      commit('addToStore', {
        key: 'isRedirectedToSerachPage',
        value: false,
      });
      commit('addToStore', {
        key: 'searchCancelled',
        value: false,
      });
      // clean Results
      commit('addSearchResults', []);
      commit('addSearchMeta', {total: 0});
    },

    redirectToSearchPage({ state }) {
      if (
        !state.isSingleFileViewSearch &&
        router.currentRoute.path !== '/srch-tabl.'
      )
        router.push('/srch-tabl.');
    },

    redirectToSearchPageOnce({ state, dispatch, commit }) {
      if (!state.isRedirectedToSerachPage) {
        commit('addToStore', {
          key: 'isRedirectedToSerachPage',
          value: true,
        });
        dispatch('redirectToSearchPage');
      }
    },

    async cancelSearch({ commit, state }) {
      commit('addToStore', {
        key: 'searchCancelled',
        value: true,
      });
      state.searchInProgress = false;
      const response = await this.state.core.client.post('core/cancelsearch', {
        searchid: state.searchId,
      });

      // clear last search
      commit('addToStore', {
        key: 'searchId',
        value: null,
      });
      await getTimeout(2500);
      return response.ok;
    },
    async getMentionables(context, payload) {
      const response = await this.state.core.client.get(
        'core/searchmentionables',
        payload
      );
      return response;
    },
    async getCommentsForComponent(context) {
      if (!context.state.currentFile) return;

      const itemPath = context.state.currentFile.path;

      // Return if root of shared or external
      if (itemPath === '/SHARED' || itemPath === '/EXTERNAL') return;

      const response = await this.dispatch('files/getComments', itemPath);

      if (response.ok) {
        const responseData = response.data;

        context.commit('addToStore', {
          key: 'loadedCommentsForSelectedItem',
          value: responseData,
        });
      } else {
        context.commit('addToStore', {
          key: 'loadedCommentsForSelectedItem',
          value: [],
        });
      }
    },
    async getGlobalActivities(context) {
      const response = await this.dispatch('files/globalActivityStream');

      if (response.ok) {
        let responseData = response.data;

        context.commit('addToStore', {
          key: 'loadedGlobalActivities',
          value: responseData,
        });
      } else {
        context.commit('addToStore', {
          key: 'loadedGlobalActivities',
          value: [],
        });
      }
    },
    async getActivities(context) {
      if (!context.state.currentFile) return;

      const item = context.state.currentFile;

      const response = await this.dispatch('files/activityStream', item);

      if (response && response.ok) {
        let responseData = response.data;

        context.commit('addToStore', {
          key: 'loadedActivitiessForSelectedItem',
          value: responseData,
        });
      } else {
        context.commit('addToStore', {
          key: 'loadedActivitiessForSelectedItem',
          value: [],
        });
      }
    },
    async getRetentionPoliciesForObject(context) {
      let responseData = [];
      if (!context.state.currentFile) return;
      const itemPath = context.state.currentFile.path;
      const response = await this.state.core.client.post(
        'core/getretentionpoliciesforfileobject',
        {
          realpath: itemPath,
        }
      );
      if (response.ok && response.data.assignedpolicy) {
        responseData = response.data.assignedpolicy;
        if (!Array.isArray(responseData)) {
          responseData = [responseData];
        }
      }
      context.commit('addToStore', {
        key: 'loadedRetentionPoliciesForSelectedItem',
        value: getRecords(responseData),
      });
    },
    async setSelectedItem(context, item) {
      context.state.selectedItem = item;
    },
    async getComments(context, fullpath) {
      const response = await this.state.core.client.post(
        'core/getcommentsforitem',
        { fullpath: fullpath }
      );
      return response;
    },
    async removeComment(context, payload) {
      await this.state.core.client.post('core/removecommentforitem', {
        fullpath: payload.fullpath,
        id: payload.id,
      });
      await this.dispatch('files/getCommentsForComponent');
    },
    async editComment(context, payload) {
      const response = this.state.core.client.post(
        'core/updatecommentforitem',
        { fullpath: payload.fullpath, id: payload.id, text: payload.text }
      );
      return response;
    },
    async addComment(context, itemCommentPayload) {
      const result = await this.state.core.client.post(
        'core/addcommentforitem',
        itemCommentPayload
      );
      return result;
    },
    async setDraggingFile(context, value) {
      await context.commit('addToStore', {
        key: 'isDraggingFile',
        value: value,
      });
    },
    showItemDetailsSidebar(context, item) {
      // rerender
      context.state.sidebarKey += 1;
      context.state.selectedItem = item;
      context.state.showItemDetailsSidebar = true;
    },
    async hideItemDetailsSidebar(context) {
      context.state.showItemDetailsSidebar = false;
    },
    async list(context, { path, sort, filter }) {
      const { DEFAULT_SORT_BY } = this.state.core.customization;
      const DEFAULT_SORT_DIR = this.getters['core/defaultSortDir'];

      const params = {
        path,
        start: 0,
        limit: itemsPerPage * 2,
        sendcommentinfo: 1,
        sendmetadatasetinfo: 1,
        search: filter && filter !== '' ? filter : null,
        sortby: sort && sort.field ? sort.field : DEFAULT_SORT_BY,
        sortdir:
          sort && sort.type === 1
            ? 1
            : sort && typeof sort.type === 'number'
            ? -1
            : DEFAULT_SORT_DIR,
        sendfavinfo: 1,
        sendaboutinfo: 1,
      };

      const response = await this.state.core.client.post(
        'core/getfilelist',
        params
      );

      if (
        response.data.meta &&
        response.data.meta.result === 1 &&
        _.isEmpty(response.data.meta.message)
      ) {
        let { meta, entry } = response.data;
        context.commit('addToStore', { key: 'filesMeta', value: meta });
        await this.dispatch('workers/call', {
          type: 'files/list',
          data: { meta, entry, params },
        });
        return response.ok;
      } else {
        return response.data.meta ? response.data.meta.message : response;
      }
    },

    async loadSection({ state }, { path, section, index, sort, filter }) {
      // if loading by index, get target section from index
      if (index) {
        section = Math.ceil(index / itemsPerPage);
      }

      const { loadedSections, total } = state.paths[path].meta;

      const sectionsToLoad = [];

      // check if previous section is loaded
      if (section - 1 >= 0 && loadedSections.indexOf(section - 1) === -1)
        sectionsToLoad.push(section - 1);

      // check if current section is loaded
      if (loadedSections.indexOf(section) === -1) sectionsToLoad.push(section);

      // check if next section is loaded
      if (
        section + 1 < Math.ceil(total / itemsPerPage) &&
        loadedSections.indexOf(section + 1) === -1
      )
        sectionsToLoad.push(section + 1);

      const params = {
        path,
        start: sectionsToLoad[0] * itemsPerPage,
        limit: sectionsToLoad.length * itemsPerPage,
        sendcommentinfo: 1,
        sendmetadatasetinfo: 1,
        search: filter && filter !== '' ? filter : null,
        sortby: sort && sort.field ? sort.field : 'name',
        sortdir: sort && sort.type === 1 ? 1 : -1,
      };

      const { ok, data } = await this.state.core.client.post(
        'core/getfilelist',
        params
      );

      if (ok) {
        const { meta, entry } = data;

        await this.dispatch('workers/call', {
          type: 'files/loadSection',
          data: { meta, entry, params, sections: sectionsToLoad },
        });
      } else {
        // TODO: handle list fail
      }
    },

    async loadSearchSection(context, { path, section, index }) {
      // if loading by index, get target section from index
      if (index) {
        section = Math.ceil(index / itemsPerPage);
      }

      const { loadedSections, total } = context.state.paths[path].meta;

      const sectionsToLoad = [];

      // check if previous section is loaded
      if (section - 1 >= 0 && loadedSections.indexOf(section - 1) === -1)
        sectionsToLoad.push(section - 1);

      // check if current section is loaded
      if (loadedSections.indexOf(section) === -1) sectionsToLoad.push(section);

      // check if next section is loaded
      if (
        section + 1 < Math.ceil(total / itemsPerPage) &&
        loadedSections.indexOf(section + 1) === -1
      )
        sectionsToLoad.push(section + 1);

      const params = {
        searchstring: this.$store.state.files.searchQuery.searchTerm,
        searchloc: this.$store.state.files.searchQuery.searchLoc,
        minsize: 0,
        maxsize: 0,
        modifiedstart: this.$store.state.files.searchQuery.selectedDateStart,
        modifiedend: this.$store.state.files.searchQuery.selectedDateStop,
        searchscope: this.$store.state.files.searchQuery.searchScope,
        searchresulttype: this.$store.state.files.searchQuery.searchResultType,
        searchattr_total: 0,
        sortby: 'DATE',
        sortdirection: -1,
        start: sectionsToLoad[0] * itemsPerPage,
        size: sectionsToLoad.length * itemsPerPage,
        maxsearchentries: this.$store.state.files.searchQuery.maxSearchEntries,
      };

      // const response = await this.$store.dispatch('files/search', params);

      // if (response.ok) {
      //   let { meta, entry } = response.data;
      //   await this.dispatch('workers/call', {
      //     type: 'files/loadSearchSection',
      //     data: { meta, entry, params, sections: sectionsToLoad },
      //   });
      // } else {
      //   // TODO: handle list fail
      // }
    },
    async allFiles(context, location) {
      let params = {
        path: location ? location : '',
        start: 0,
        limit: 50,
        sendcommentinfo: 0,
        sendmetadatasetinfo: 0,
        filter: location !== '' ? 'directory' : '',
        sortby: 'name',
        sortdir: 1,
      };

      const response = await this.state.core.client.post(
        'core/getfilelist',
        params
      );

      if (response.ok) {
        if (location == '') {
          let allFilesList = response.data.entry;
          allFilesList =
            !!allFilesList && allFilesList.constructor === Array
              ? allFilesList
              : Array.of(allFilesList);

          context.commit('addToStore', {
            key: 'allFiles',
            value: allFilesList,
          });
        }
      }

      return response;
    },

    async favoritesInNamedList(context, name = 'recent') {
      const showTag = this.state.core.metaSets?.write
        ? '&showcolortag=' + this.state.core.metaSets?.write
        : '';
      let response = await this.state.core.client.get(
        'core/getfavoritesinnamedlist?name=' + name + showTag
      );
      let result = response.data.entry;

      if (result && !Array.isArray(result)) {
        result = [];
        result.push(response.data.entry);
      }

      if (result) {
        result = result.map((item, index) => {
          item.id = index;
          return item;
        });
      }
      if (response.ok) {
        let key = name === 'recent' ? 'recentFiles' : 'starredFiles';
        context.commit('addToStore', { key, value: result });
        return response;
      }
    },

    async allFavoriteLists() {
      const response = await this.state.core.client.get(
        'core/getallfavoritelists'
      );
      return response;
    },

    async setfavorite(context, payload) {
      const response = await this.state.core.client.get(
        'core/setfavorite',
        payload
      );
      return response;
    },

    async unsetfavorite(context, payload) {
      const response = await this.state.core.client.get(
        'core/unsetfavorite',
        payload
      );
      return response;
    },

    async shares(context, payload) {
      let params = {
        start: payload?.start ? payload.start : 0,
        //100 is the max number of items returning in the API, even if we use 0, so we are using 100 as default to clarity
        limit: payload?.limit ? payload.limit : 100,
      };

      const response = await this.state.core.client.get(
        'app/websharepro/getshares',
        params
      );
      if (response.ok && response.data.share) {
        let shares = response.data.share;
        if (!Array.isArray(shares)) {
          shares = [shares];
        }
        shares = shares.map((share, id) => {
          share.id = id;
          share.type = share.isdir ? 'folder' : 'file';
          share.name = share.sharedobjname;
          share.filepath = share.sharelocation;
          return share;
        });
        context.commit('addToStore', {
          key: 'sharedByMe',
          value: shares,
        });
        return response;
      } else if (response.ok && response.data.meta?.total === 0) {
        context.commit('addToStore', {
          key: 'sharedByMe',
          value: [],
        });
        return response;
      }
    },

    async getsharesfilter({ commit }, payload) {
      const { ok, data } = await this.state.core.client.get(
        'app/websharepro/getsharesfilter',
        payload
      );
      if (ok && data.share) {
        const shares = handleArrayResponse(data.share).map((share, id) => {
          share.id = id;
          share.type = share.isdir ? 'folder' : 'file';
          share.name = share.sharedobjname;
          share.filepath = share.sharelocation;
          return share;
        });
        commit('addToStore', {
          key: 'sharedByMe',
          value: shares,
        });
      }
    },

    async managedShares(context) {
      const response = await this.state.core.client.get(
        'app/websharepro/getmanageablesharesall'
      );
      if (response.ok) {
        let shares = response.data.share;
        if (shares && !Array.isArray(shares)) {
          shares = [shares];
        }
        if (shares) {
          shares = shares.map((share, id) => {
            share.id = id;
            share.type = share.isdir ? 'folder' : 'file';
            share.name = share.sharedobjname;
            return share;
          });
        }
        context.commit('addToStore', {
          key: 'sharedByMe',
          value: shares,
        });
        return response;
      }
    },

    async lockedFiles(context, params) {
      const response = await this.state.core.client.post(
        'core/getalllocks',
        params
      );
      if (response.ok) {
        let lockedFiles = response.data.lock || [];

        if (lockedFiles && !Array.isArray(lockedFiles)) {
          lockedFiles = [lockedFiles];
        }

        // if (!lockedFiles) return [];

        lockedFiles = lockedFiles.map((file, id) => {
          file.id = id;
          return file;
        });
        context.commit('addToStore', {
          key: 'lockedFiles',
          value: lockedFiles,
        });
        return response;
      }
    },
    async lockFile(context, params) {
      const response = await this.state.core.client.post('core/lock', params);
      return response;
    },

    async unlock(context, params) {
      const response = await this.state.core.client.post('core/unlock', params);
      const isLimitedUser = this.state.core.systemstatus.LIMITEDUSER;
      if (!isLimitedUser) context.dispatch('lockedFiles');
      return response;
    },
    async getFileLockInfo(context, params) {
      const response = await this.state.core.client.post(
        'core/getfilelockinfo',
        params
      );
      if (response.ok) {
        return response.data.filelockinfo;
      }
      return response;
    },
    //**** Start: DRM access files related calls
    async exportAsDRMFilesTX(context, params) {
      let items = params.items;
      let paths = items[0].path;
      for (var i = 1; i < items.length; i++) {
        paths += ',' + items[i].path;
      }
      const transaction = {
        id: uuid(),
        type: 'drmExport',
        params: paths,
        items: params.items,
        name: items[0].dirpath,
        group: 'others',
        status: 'preparing',
      };

      // add transaction
      context.commit('addTransaction', transaction);

      context.state.othersQueue.push(async () => {
        // watch to check if it's cancelled
        const index = _.findIndex(context.state.transactions, {
          id: transaction.id,
          status: 'preparing',
        });

        // check if transaction is still active
        if (index === -1) return;

        context.commit('setTransaction', {
          id: transaction.id,
          status: 'processing',
        });

        const response = await this.state.core.client.postBlob(
          'core/exportdrmfiles',
          {
            paths: paths,
          }
        );

        if (response.ok) {
          context.commit('setTransaction', {
            id: transaction.id,
            status: 'completed',
          });

          let saveAsName = response.headers.drm_document_container_name;
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', saveAsName);
          document.body.appendChild(link);
          link.click();
          link.remove();

          //Show export success response
          let responseProperties = response.headers;
          context.commit('setRoot', {
            key: 'drmExportResponseDialogOpen',
            value: true,
          });
          context.commit('setRoot', {
            key: 'drmExportResponseProperties',
            value: responseProperties,
          });
        } else {
          // set transaction as failed
          context.commit('setTransaction', {
            id: transaction.id,
            status: 'failed',
            message: response.error,
          });

          // expand bar
          context.commit('setTransactionsBar', {
            key: 'expanded',
            value: true,
          });
        }
      });
    },
    async closeDRMResponseDialog(context) {
      // close the dialog and reset the state props
      context.commit('setRoot', {
        key: 'drmExportResponseDialogOpen',
        value: false,
      });
      context.commit('setRoot', {
        key: 'drmExportResponseProperties',
        value: {},
      });
    },
    async getDRMFilesList(context, params) {
      if (params == null) {
        params = [];
      }
      context.commit('setRoot', {
        key: 'drmListFilterParams',
        value: params,
      });
      const response = await this.state.core.client.post(
        'core/getdrmlist',
        params
      );
      if (response.ok) {
        let drmAccessFiles = response.data.drm;

        if (drmAccessFiles && !Array.isArray(drmAccessFiles)) {
          drmAccessFiles = [drmAccessFiles];
        }

        if (drmAccessFiles) {
          drmAccessFiles = drmAccessFiles.map((file, id) => {
            file.id = id;
            return file;
          });
        } else {
          drmAccessFiles = [];
        }
        context.commit('addToStore', {
          key: 'userDRMAccessFiles',
          value: drmAccessFiles,
        });
        return response;
      }
    },
    openDRMListModal(context) {
      context.commit('setRoot', {
        key: 'drmListModalOpen',
        value: true,
      });
    },
    async closeDRMFileListDialog(context) {
      // close the dialog and reset the state props
      context.commit('setRoot', {
        key: 'drmListModalOpen',
        value: false,
      });
      context.commit('setRoot', { key: 'drmListFilterParams', value: [] });
    },
    async configureDRMAccess(context, params) {
      const response = await this.state.core.client.post(
        'core/configuredrmaccess',
        params
      );
      //send any filter params in context, so that UI doesnt end up showing full list
      context.dispatch('getDRMFilesList', context.state.drmListFilterParams);
      return response;
    },
    async revokeDRMAccess(context, params) {
      const response = await this.state.core.client.post(
        'core/revokedrmaccess',
        params
      );
      context.dispatch('getDRMFilesList');
      return response;
    },
    //**** End: DRM access files related calls
    async retryTransaction({ state, dispatch }, { id }) {
      // watch to check if it's cancelled
      const index = _.findIndex(state.transactions, { id: id });
      const { dirpath, uploadpath, entry, type } = state.transactions[index];
      switch (type) {
        case 'upload': {
          dispatch('upload', {
            path: dirpath + (uploadpath ? uploadpath : ''),
            items: [entry],
            transactionId: id,
          });
          break;
        }
      }
    },

    async undoTransaction(context, { id }) {
      // watch to check if it's cancelled
      const index = _.findIndex(context.state.transactions, { id: id });
      const transaction = context.state.transactions[index];

      switch (transaction.type) {
        case 'rename': {
          context.dispatch('renameFile', {
            id: transaction.fileId,
            path: transaction.dirpath,
            name: transaction.to,
            newname: transaction.from,
            undo: true,
          });
          break;
        }
      }

      context.commit('removeTransaction', id);
    },

    async bulk(context, { items, path, type, onTick }) {
      let inMemoryItems = context.getters.getFilesInPath(path);
      let itemsToProcess = [];
      let transaction = null;

      const operationQueue = queue(async (task, callback) => {
        await task();
        callback(true);
      }, 1);

      operationQueue.pause();

      if (type === 'download') {
        transaction = {
          id: uuid(),
          type: 'download',
          path: path,
          name: path,
          items,
          loadedItems: 0,
          group: 'download',
          status: 'preparing',
        };

        // add transaction
        context.commit('addTransaction', transaction);
      }

      let i = 0;

      while (i < items.length) {
        const operationTypes = ['removeShare', 'move', 'copy', 'delete'];
        const item = operationTypes.includes(type)
          ? items[i]
          : inMemoryItems[items[i].id];

        if (!item.name) {
          await context.dispatch('loadSection', { index: item.id, path: path });
          inMemoryItems = context.getters.getFilesInPath(path);
        } else {
          itemsToProcess.push(item);
          if (onTick) onTick(item, operationQueue);
          i++;
        }

        if (transaction) {
          // update
          context.commit('setTransaction', {
            id: transaction.id,
            loadedItems: i,
          });
        }
      }

      if (transaction) {
        // update
        context.commit('setTransaction', {
          id: transaction.id,
          status: 'processing',
        });
      }

      switch (type) {
        case 'download':
          await context.dispatch('downloadMultiple', {
            files: itemsToProcess,
            path,
          });

          context.commit('setTransaction', {
            id: transaction.id,
            status: 'completed',
          });

          break;
        default:
      }

      // return the queue
      return operationQueue;
    },

    // cancel transactions based on criteria
    cancelTransaction(context, { params, message }) {
      // watch to check if it's cancelled
      context.commit('setTransaction', {
        id: params.id,
        status: 'failed',
        message,
      });
    },

    // upload multiple items
    async upload(
      { commit, rootGetters, state, getters, dispatch },
      { path, uploadpath, items, transactionId, parentTransaction }
    ) {
      const fileChunks = new FileChunks();
      const resumableUploadExpiry =
        rootGetters['core/getResumableUploadExpiry'];
      fileChunks.cleanupExpired(resumableUploadExpiry || 1);

      if (parentTransaction) {
        // watch to check if it's cancelled
        const index = _.findIndex(state.transactions, {
          id: parentTransaction,
          status: 'preparing',
        });

        if (index === -1) {
          return;
        }

        commit('removeTransaction', parentTransaction);
      }

      let files = [];
      let folders = [];

      // go on each item
      for (let i = 0; i < items.length; i++) {
        const entry =
          items[i] instanceof DataTransferItem
            ? items[i].webkitGetAsEntry()
            : items[i];

        if (entry.isFile || entry instanceof File) {
          files.push(entry);
        } else {
          folders.push(entry);
        }
      }

      // map files
      let filesToUpload = [];
      for (let i = 0; i < files.length; i++) {
        const file =
          files[i] instanceof File
            ? files[i]
            : await getFileFromEntry(files[i]);

        // add or find running transaction
        let transaction = {};
        if (!transactionId) {
          transaction = {
            id: uuid(),
            type: 'upload',
            name: file.name,
            path: `${path}/${file.name}`,
            fullpath: `${path}/${file.name}`,
            params: {
              item: {
                id: 0,
                dirpath: `${path}/`,
                format: getFileType(file.name),
                type: 'file',
              },
            },
            entry: files[i],
            dirpath: path,
            targetPath: path,
            uploadpath: uploadpath,
            progress: 0,
            sentSize: 0,
            size: file.size,
            group: 'upload',
            status: 'preparing',
            message: file.name + ' ' + i18n.t('Uploaded successfully'),
          };

          // add transaction
          commit('addTransaction', transaction);
        } else {
          const index = _.findIndex(state.transactions, {
            id: transactionId,
          });
          transaction = state.transactions[index];

          // reset
          commit('setTransaction', {
            id: transaction.id,
            progress: 0,
            sentSize: 0,
            status: 'preparing',
            added: dayjs().unix(),
          });
        }

        filesToUpload[i] = {
          data: file,
          transaction,
        };
      }
      const fileChunkSize =
        this.state.core.fullSystemStatus['uploadchunksize'] || chunkSize;
      const bytesChunkSize = 1000 * 1000 * fileChunkSize;

      // upload files
      for (let i = 0; i < filesToUpload.length; i++) {
        state.queue.push(async () => {
          const file = filesToUpload[i];

          // fileId used to search for the last uploaded chunk
          const fileId = fileChunks.generateId(path, file);
          let lastUploadedChunk = fileChunks.getLastUploadedChunk(fileId);

          // watch to check if it's cancelled
          const index = _.findIndex(state.transactions, {
            id: file.transaction.id,
            status: 'preparing',
          });

          // check if transaction is still active
          if (index >= 0) {
            try {
              // calculate chunks
              const totalChunks = Math.ceil(
                file.data.size / bytesChunkSize,
                bytesChunkSize
              );

              let isCancelled = false;

              // add transaction
              commit('setTransaction', {
                id: file.transaction.id,
                status: 'processing',
              });

              // go for each chunk
              for (
                let currentChunk = lastUploadedChunk
                  ? parseInt(lastUploadedChunk) + 1
                  : 0;
                (currentChunk < totalChunks && !isCancelled) ||
                (currentChunk === 0 && totalChunks === 0 && !isCancelled);
                currentChunk++
              ) {
                const offset = currentChunk * bytesChunkSize;
                const currentFilePart = file.data.slice(
                  offset,
                  offset + bytesChunkSize
                );

                const complete =
                  currentChunk === totalChunks - 1 ||
                  (currentChunk === 0 && totalChunks === 0)
                    ? 1
                    : 0;

                let retry = true;
                let showNetworkErrorMessage = true;
                let retryInterval = 5000;
                let networkError = false;
                let offlineCallback = null;
                while (retry) {
                  // If there was a network error, we need to check if the user connection is back,
                  // directly calling 'postMultipart' will eventually trigger the 'onUploadProgress'
                  // callback, with unpredictable 'loaded' values which will cause the progress bar to be
                  // calculated incorrectly.
                  if (!showNetworkErrorMessage) {
                    const online = navigator.onLine;
                    if (
                      !online &&
                      !getters.transactionIsCancelled(file.transaction.id)
                    ) {
                      // Not internet connect and not cancelled
                      await sleep(retryInterval);
                      continue;
                    } else if (
                      // cancelled
                      getters.transactionIsCancelled(file.transaction.id)
                    ) {
                      retry = false;
                    } else {
                      // If connected then wait a few seconds to make sure the
                      // connection is stable before proceeding
                      // useful when the user is switching between wi-fi
                      // and mobile data or if the user is behind a proxy or a VPN.
                      await sleep(8000);
                    }
                  }

                  const uploadRequest =
                    await this.state.core.client.postMultipart(
                      'upload',
                      {
                        filedata: new File([currentFilePart], file.data.name, {
                          type: file.data.type,
                        }),
                      },
                      {
                        appname: 'explorer',
                        filesize: file.data.size,
                        path: path,
                        uploadpath: uploadpath ? uploadpath : '',
                        offset: offset,
                        date: new Date(file.data.lastModified).toISOString(),
                        filename: file.data.name,
                        complete,
                      },
                      (e, source) => {
                        showNetworkErrorMessage = true;
                        commit('setTransaction', {
                          id: file.transaction.id,
                          sentSize: e.loaded + currentChunk * bytesChunkSize,
                          cancel: source.cancel,
                          networkError: false,
                        });

                        // Abort the upload if the user manually decide to cancel it.
                        if (
                          getters.transactionIsCancelled(file.transaction.id)
                        ) {
                          isCancelled = true;
                          source.cancel();
                        }

                        // Abort the upload if the user is offline.
                        if (!offlineCallback) {
                          offlineCallback = () => {
                            networkError = true;
                            source.cancel();
                          };
                          window.onoffline = offlineCallback;
                        }
                        if (!navigator.onLine) {
                          offlineCallback();
                        }
                      }
                    );

                  window.onoffline = null;
                  offlineCallback = null;

                  // Also check if the transaction was cancelled at the end of each request.
                  if (getters.transactionIsCancelled(file.transaction.id)) {
                    isCancelled = true;
                    retry = false;
                    commit('setTransaction', {
                      id: file.transaction.id,
                      networkError: false,
                    });
                  }

                  // save last successful uploaded chunk
                  if (uploadRequest.ok) {
                    retry = false; // upload successful, no need to retry
                    showNetworkErrorMessage = true;
                    if (complete) {
                      // if the last chunk is complete, remove the fileId.
                      fileChunks.delete(fileId);
                      filesToUpload[i].completed = true;
                    } else {
                      // set the current chunk as last uploaded chunk
                      fileChunks.setLastUploadedChunk(fileId, currentChunk);
                    }
                  } else if (uploadRequest.data.code === 'NETWORK_ERROR') {
                    networkError = true;
                  }

                  if (!uploadRequest.ok && !isCancelled) {
                    // If it's not a network error
                    if (!networkError) {
                      // delete all file progress.
                      fileChunks.delete(fileId);
                      throw new Error(uploadRequest.data.message);
                    } else {
                      // Alert user of network error.
                      if (showNetworkErrorMessage) {
                        let message = `<b>${i18n.t('Network Error')}</b>`;
                        if (!window.navigator.onLine) {
                          // no internet connection
                          message += `
                            <p role="alert">
                              ${i18n.t('No internet connection')}
                            </p>
                          `;
                        } else {
                          message += `
                            <p role="alert">
                              ${i18n.t(uploadRequest.data.message)}
                            </p>
                          `;
                        }
                        Vue.$toast.open({
                          message,
                          type: 'error',
                        });

                        commit('setTransaction', {
                          id: file.transaction.id,
                          networkError: true,
                        });
                        showNetworkErrorMessage = false;
                      }
                      await sleep(retryInterval);
                    }
                  }
                }
              }

              if (!isCancelled) {
                const params = {
                  path,
                  start: 0,
                  limit: 1,
                  sendcommentinfo: 1,
                  sendmetadatasetinfo: 1,
                  search: file.data.name,
                  sortby: 'name',
                  sortdir: 1,
                  sendfavinfo: 1,
                };

                let retry = true;
                let showNetworkErrorMessage = true;
                let itemData;
                while (retry) {
                  const response = await this.state.core.client.post(
                    'core/getfilelist',
                    params
                  );
                  if (
                    response?.ok === false &&
                    response.data?.message === 'Network Error'
                  ) {
                    if (showNetworkErrorMessage) {
                      let message = `<b>${i18n.t('Network Error')}</b>`;
                      if (!window.navigator.onLine) {
                        // no internet connection
                        message += `
                            <p role="alert">
                              ${i18n.t('No internet connection')}
                            </p>
                          `;
                      } else {
                        message += `
                            <p role="alert">
                              ${i18n.t(response.message)}
                            </p>
                          `;
                      }
                      Vue.$toast.open({
                        message,
                        type: 'error',
                      });
                      showNetworkErrorMessage = false;
                    }
                    // retry every 5 seconds
                    await sleep(5000);
                    retry = true;
                  } else {
                    itemData = response.data;
                    retry = false;
                  }
                }

                // complete transaction
                commit('setTransaction', {
                  id: file.transaction.id,
                  status: 'completed',
                  item: itemData && itemData.entry ? itemData.entry : null,
                });

                // Clear up selected items
                dispatch('setSelectedItem', []);

                // refresh files list
                if (path == state.sidebar.path) dispatch('list', { path });

                //refresh navigation bar
                if (path == state.sidebar.path)
                  commit('setRoot', {
                    key: 'updateFile',
                    value: {
                      dirpath: path,
                      name: file.data.name,
                      activity: 'uploaded',
                    },
                  });

                //update upload complete time
                commit('setRoot', {
                  key: 'lastUploadTime',
                  value: new Date().getTime(),
                });

                commit('setRoot', {
                  key: 'lastUploadedFile',
                  value: itemData?.entry ? itemData.entry : null,
                });
              }
            } catch (err) {
              // add transaction
              commit('setTransaction', {
                id: file.transaction.id,
                status: 'failed',
                message: err.message,
              });

              // expand bar
              commit('setTransactionsBar', {
                key: 'expanded',
                value: true,
              });

              dispatch('cancelTransaction', {
                params: {
                  group: 'upload',
                  status: 'preparing',
                  id: file.transaction.id,
                },
                message: err.message,
              });

              //update upload complete time
              commit('setRoot', {
                key: 'lastUploadTime',
                value: new Date().getTime(),
              });
              // refresh files list
              if (path == state.sidebar.path) dispatch('list', { path });
            }
          }
        }, 0);
      }

      // queue folders
      for (let i = 0; i < folders.length; i++) {
        const folder = folders[i];

        const entries = await getEntriesFromFolder(folder);

        folders[i].entries = entries;

        if (entries.length) {
          const transaction = {
            id: uuid(),
            type: 'folder',
            name: folder.name,
            path: `${path}/${folder.name}`,
            entry: folder,
            entries: entries.length,
            dirpath: path,
            folder: true,
            group: 'upload',
            status: 'preparing',
          };

          // add transaction
          commit('addTransaction', transaction);

          state.queue.push(async () => {
            const folderToUpload = folders[i];
            await dispatch('upload', {
              path: `${path}`,
              uploadpath: folderToUpload.fullPath,
              parentTransaction: transaction.id,
              items: folderToUpload.entries,
            });
          }, 1);
        } else {
          // If no content in the folder, create it
          const payload = {
            path: path,
            subpath: folder.fullPath,
          };

          const createFolderRequest = await this.state.core.client.post(
            'core/createfolder',
            payload
          );

          if (createFolderRequest.ok) {
            // refresh files list if it is the last of a path
            if (folders.length === 1 && path == state.sidebar.path) {
              dispatch('list', { path });
            }
          } else {
            throw new Error(createFolderRequest.data.message);
          }
        }
      }

      return null;
    },

    async webEditorSave(context, { file, path }) {
      return await this.state.core.client.postMultipart(
        'upload',
        {
          filedata: new File([file], sanitizeFileName(file.name), {
            type: file.type,
          }),
        },
        {
          appname: 'explorer',
          filesize: file.size,
          path: path,
          uploadpath: '',
          date: new Date(file.lastModified).toISOString(),
          filename: file.name,
          complete: 1,
        }
      );
    },

    async downloadAllVersions(context, { name, dirpath }) {
      const { ok, data } = await this.state.core.client.get(
        'core/downloadallversions',
        {
          filepath: dirpath,
          filename: name,
          checkonly: 1,
        }
      );
      if (!ok) return { status: false, message: data.message };

      const response = await this.state.core.client.getBlob(
        'core/downloadallversions',
        {
          filepath: dirpath,
          filename: name,
        }
      );

      let link = document.createElement('a');
      const url = window.URL.createObjectURL(new Blob([response.data]));
      link.href = url;

      let fileName = name.split('.');
      fileName = fileName[0];

      link.setAttribute('download', fileName + '.zip');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    async downloadVersioned(context, item) {
      const url = `${
        serverURL.domainURL
      }/core/downloadversionedfile?${qs.stringify({
        filepath: item.dirpath,
        filename: item.filename,
        fileid: item.fileid,
        disposition: 'attachment',
      })}`;

      const link = document.createElement('a');
      link.href = url;

      let response = await this.state.core.client.get(
        'core/downloadversionedfile',
        {
          filepath: item.dirpath,
          filename: item.filename,
          fileid: item.fileid,
          disposition: 'attachment',
          checkonly: 1,
        }
      );

      if (!response.ok) {
        return { status: false, message: response };
      }

      link.setAttribute('download', item.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    async download(context, item) {
      if (item.type === 'dir') {
        return await context.dispatch('downloadMultiple', {
          items: [item],
          path: item.dirpath.replace(/\/$/, ''),
        });
      } else {
        const checkUrl = await context.dispatch('downloadUrl', {
          isCheck: true,
          ...item,
        });
        const url = await context.dispatch('downloadUrl', item);

        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 2 && this.status == 200) {
            xhttp.abort();
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', item.name);
            document.body.appendChild(link);
            link.click();
            link.remove();
          } else if (this.readyState == 4 && this.status >= 400) {
            let response = xhttp.responseText.substring(
              xhttp.responseText.lastIndexOf('<message>'),
              xhttp.responseText.lastIndexOf('</message>')
            );
            Vue.$toast.open({
              message: `<b>${'File download is blocked'}</b> <p role="alert">${
                response || xhttp.responseText
              }</p>`,
              type: 'warning',
            });
            return { status: false, message: xhttp.responseText };
          }
        };
        xhttp.open('GET', checkUrl + '&checkonly=1', true);
        xhttp.send();
      }
    },

    // downloads file
    async downloadMultipleWithStatus(context, { items, path }) {
      let inMemoryItems = context.getters.getFilesInPath(path);

      const transaction = {
        id: uuid(),
        type: 'downloadMultiple',
        path: path,
        name: path,
        items,
        loadedItems: 0,
        loadedSize: 0,
        speed: 0,
        group: 'download',
        status: 'preparing',
      };

      // add transaction
      context.commit('addTransaction', transaction);

      let request = {
        count: items.length,
        filepath: `${path}/`,
      };

      let i = 0;

      while (i < items.length) {
        const item =
          inMemoryItems[items[i].id] === undefined || items[i].fromCustomList
            ? items[i]
            : inMemoryItems[items[i].id];

        if (!item.name) {
          await context.dispatch('loadSection', { index: item.id, path: path });
          inMemoryItems = context.getters.getFilesInPath(path);
        } else {
          request[`fn${i + 1}`] = item.name; // set file name in request body
          i++;
        }

        // update
        context.commit('setTransaction', {
          id: transaction.id,
          loadedItems: i,
        });
      }

      // update
      context.commit('setTransaction', {
        id: transaction.id,
        status: 'processing',
      });

      let startTime = new Date().getTime();
      let downloaded = 0;

      const response = await this.state.core.client.postBlob(
        'core/downloadfilemulti',
        request,
        (e, source) => {
          downloaded = e.loaded;

          var now = new Date().getTime();

          const speed = downloaded / (now - startTime);

          // update
          context.commit('setTransaction', {
            id: transaction.id,
            loadedSize: e.loaded,
            speed,
          });

          // watch to check if it's cancelled
          const index = _.findIndex(context.state.transactions, {
            id: transaction.id,
          });
          const currentTransaction = context.state.transactions[index];

          if (
            !currentTransaction ||
            currentTransaction.status === 'cancelled'
          ) {
            source.cancel();
          }
        }
      );

      if (response.ok) {
        const url = window.URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = url;

        const folderName = path.substr(path.lastIndexOf('/') + 1);

        link.setAttribute(
          'download',
          `${items.length === 1 ? items[0].name : folderName}.zip`
        );

        document.body.appendChild(link);
        link.click();
        link.remove();

        // update
        context.commit('setTransaction', {
          id: transaction.id,
          status: 'completed',
        });
      } else {
        // update
        context.commit('setTransaction', {
          id: transaction.id,
          status: 'failed',
          message: response.error,
        });

        // expand bar
        context.commit('setTransactionsBar', {
          key: 'expanded',
          value: true,
        });
      }
    },

    // downloads multiple files
    async downloadMultiple(context, { items, path }) {
      let inMemoryItems = context.getters.getFilesInPath(path);
      let multiFileForm = document.createElement('form');
      multiFileForm.name = 'multiFileForm';
      multiFileForm.method = 'POST';
      multiFileForm.action = serverURL.domainURL + '/core/downloadfilemulti';
      let request = document.createElement('input');
      request.type = 'hidden';
      request.name = 'count';
      request.value = items.length + '';
      multiFileForm.appendChild(request);
      request = document.createElement('input');
      request.type = 'hidden';
      request.name = 'filepath';
      request.value = `${path}/`;
      multiFileForm.appendChild(request);

      let i = 0;

      while (i < items.length) {
        // When downloading from outside parent filelist, such as Search results, the inMemoryItems is empty.
        const item =
          inMemoryItems[items[i].id] === undefined || items[i].fromCustomList
            ? items[i]
            : inMemoryItems[items[i].id];

        if (!item.name) {
          await context.dispatch('loadSection', { index: item.id, path: path });
          inMemoryItems = context.getters.getFilesInPath(path);
        } else {
          request = document.createElement('input');
          request.type = 'hidden';
          request.name = `fn${i + 1}`;
          request.value = item.name;
          multiFileForm.appendChild(request);
          i++;
        }
      }
      document.body.appendChild(multiFileForm);
      return context.dispatch('dlpPreCheck', multiFileForm);
    },

    dlpPreCheck(context, form) {
      return new Promise(function (resolve, reject) {
        const xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function () {
          if (this.readyState == 2 && this.status == 200) {
            xhttp.abort();
            form.removeChild(document.getElementById('checkonlyDownloadmulti'));
            form.submit();
            resolve({ ok: true });
          } else if (this.readyState == 4 && this.status >= 400) {
            let response = xhttp.responseText.substring(
              xhttp.responseText.lastIndexOf('<message>'),
              xhttp.responseText.lastIndexOf('</message>')
            );
            Vue.$toast.open({
              message: `<b>${'Download is blocked'}</b> <p role="alert">${
                response || xhttp.responseText
              }</p>`,
              type: 'warning',
            });
            reject({
              ok: false,
              message: {
                data: {
                  message: xhttp.response,
                },
              },
            });
          }
        };
        let request = document.createElement('input');
        request.type = 'hidden';
        request.id = 'checkonlyDownloadmulti';
        request.name = 'checkonly';
        request.value = 1;
        form.appendChild(request);
        xhttp.open(form.method, form.action);
        const FD = new FormData(form);
        try {
          xhttp.send(FD);
        } catch (error) {
          return;
        }
      });
    },

    // return file blob url
    async blobUrl(context, file) {
      const response = await this.state.core.client.getBlob(
        'core/downloadfile',
        {
          filepath: file.path,
          filename: file.name,
          disposition: 'attachment',
          preview: 1,
        }
      );
      return window.URL.createObjectURL(response.data);
    },

    // return file blob url
    async blobUrlMulti(context, file) {
      const response = await this.state.core.client.postBlob(
        'core/downloadfilemulti',
        {
          filepath: file.dirpath,
          count: 1,
          fn1: file.name,
        }
      );

      return window.URL.createObjectURL(response);
    },

    // return file blob
    async blob(context, file) {
      const request = {
        filepath: file.path,
        filename: file.name,
        disposition: 'attachment',
        redirect: 0,
        preview: 1,
      };

      const response = await this.state.core.client.getBlob(
        file.public ? 'app/websharepro/share' : 'core/downloadfile',
        file.public ? { path: file.public_path, preview: 1 } : request
      );

      return response;
    },

    async blobUrlWithId(context, file) {
      const response = await this.state.core.client.getBlob(
        'core/downloadfile',
        {
          filepath: file.path,
          filename: file.name,
          fileid: file.fileid,
          disposition: 'attachment',
        }
      );
      return window.URL.createObjectURL(response.data);
    },

    async plain(context, file) {
      const response = await this.state.core.client.getPlain(
        'core/downloadfile',
        {
          filepath: file.path,
          filename: file.name,
          disposition: 'attachment',
        }
      );
      return response;
    },

    async wiki(context, file) {
      const response = await this.state.core.client.getPlain(
        `core/wiki.get/${file.path}`,
        {
          redirect: 0,
        }
      );
      return response;
    },

    async createFolder(context, payload) {
      const response = await this.state.core.client.post(
        'core/createfolder',
        payload
      );
      if (response.ok) {
        context.commit('setRoot', {
          key: 'updateFile',
          value: {
            dirpath: payload.path,
            name: payload.name,
            activity: 'added',
          },
        });
      }
      return response;
    },

    async createFile(context, { path, name, mime }) {
      const file = new File(
        [mime === 'text/plain' ? 'This is a new file' : ''],
        name,
        {
          type: mime,
        }
      );

      return await this.state.core.client.postMultipart(
        'upload',
        {
          filedata: file,
        },
        {
          appname: 'explorer',
          filesize: file.size,
          date: new Date().toISOString(),
          path: path,
          uploadpath: '',
          offset: 0,
          filename: name,
          complete: 1,
        },
        () => {}
      );
    },

    async globalActivityStream() {
      const response = await this.state.core.client.get(
        'core/getnotificationstream'
      );
      if (response.ok) {
        return response;
      } else {
        // TODO: handle activity stream fail
      }
    },

    async activityStream(context, item) {
      let params = {};
      if (item.type == 'dir') {
        params.parent = item.path;
      } else {
        params.fullpath = item.path;
      }
      const response = await this.state.core.client.post(
        'core/getactivitystream',
        params
      );
      if (response.ok) {
        return response;
      }
    },

    async copyFile(
      context,
      { item, to, transactionQueue, copytoname, transactionId }
    ) {
      const fromPath = item.dirpath.replace(/\/$/, '');
      const toPath = to.replace(/\/$/, '');

      let transaction = {};
      if (!transactionId) {
        transaction = {
          id: uuid(),
          type: 'copy',
          isFolder: item.type === 'dir',
          dirpath: fromPath,
          targetPath: toPath,
          fullpath: `${toPath}/${item.name}`,
          to: toPath,
          params: { item, to, transactionQueue, copytoname, transactionId },
          name: item.name,
          group: 'others',
          status: 'preparing',
        };

        // add transaction
        context.commit('addTransaction', transaction);
      } else {
        const index = _.findIndex(context.state.transactions, {
          id: transactionId,
        });

        transaction = context.state.transactions[index];

        // reset
        context.commit('setTransaction', {
          id: transaction.id,
          name: copytoname ? copytoname : item.name,
          fullpath: `${toPath}/${copytoname ? copytoname : item.name}`,
          message: null,
          params: { item, to, transactionQueue, copytoname, transactionId },
          status: 'preparing',
        });
      }

      (transactionQueue ? transactionQueue : context.state.othersQueue).push(
        async () => {
          // watch to check if it's cancelled
          const index = _.findIndex(context.state.transactions, {
            id: transaction.id,
            status: 'preparing',
          });

          // check if transaction is still active
          if (index === -1) return;

          context.commit('setTransaction', {
            id: transaction.id,
            status: 'processing',
          });

          const response = await this.state.core.client.post(
            'app/explorer/copyfile',
            {
              path: fromPath,
              name: item.name,
              copyto: toPath,
              copytoname,
            }
          );

          if (response.ok) {
            // get actual file data
            const params = {
              path: toPath,
              start: 0,
              limit: 1,
              sendcommentinfo: 1,
              sendmetadatasetinfo: 1,
              search: copytoname,
              sortby: 'name',
              sortdir: 1,
              sendfavinfo: 1,
            };

            const { data: itemData } = await this.state.core.client.post(
              'core/getfilelist',
              params
            );

            context.commit('setTransaction', {
              id: transaction.id,
              status: 'completed',
              item: itemData && itemData.entry ? itemData.entry : null,
            });
            context.commit('setRoot', {
              key: 'updateFile',
              value: { dirpath: toPath, name: item.name, activity: 'added' },
            });
            // refresh files list path
            context.dispatch('list', { path: toPath });
          } else {
            // set transaction as failed
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'failed',
              message: response.error,
            });

            // expand bar
            context.commit('setTransactionsBar', {
              key: 'expanded',
              value: true,
            });
          }
        }
      );
    },

    async moveFile(
      context,
      { item, to, transactionQueue, overwrite, transactionId }
    ) {
      const fromPath = item.dirpath.replace(/\/$/, '');
      const toPath = to.replace(/\/$/, '');

      let transaction = {};
      if (!transactionId) {
        transaction = {
          id: uuid(),
          type: 'move',
          isFolder: item.type === 'dir',
          params: { item, to, transactionQueue, overwrite, transactionId },
          dirpath: fromPath,
          targetPath: toPath,
          fullpath: `${toPath}/${item.name}`,
          to: toPath,
          name: item.name,
          group: 'others',
          status: 'preparing',
        };

        // add transaction
        context.commit('addTransaction', transaction);
      } else {
        const index = _.findIndex(context.state.transactions, {
          id: transactionId,
        });
        transaction = context.state.transactions[index];

        // reset
        context.commit('setTransaction', {
          id: transaction.id,
          message: null,
          params: { item, to, transactionQueue, overwrite, transactionId },
          status: 'preparing',
        });
      }

      (transactionQueue ? transactionQueue : context.state.othersQueue).push(
        async () => {
          // watch to check if it's cancelled
          const index = _.findIndex(context.state.transactions, {
            id: transaction.id,
            status: 'preparing',
          });

          // check if transaction is still active
          if (index === -1) return;

          context.commit('setTransaction', {
            id: transaction.id,
            status: 'processing',
          });

          const response = await this.state.core.client.post(
            'app/explorer/renameormove',
            {
              toname: `${toPath}/${item.name}`,
              fromname: `${fromPath}/${item.name}`,
              overwrite,
            }
          );

          if (response.ok) {
            // get actual file data
            const params = {
              path: toPath,
              start: 0,
              limit: 1,
              sendcommentinfo: 0,
              sendmetadatasetinfo: 0,
              search: item.name,
              sortby: 'name',
              sortdir: 1,
              sendfavinfo: 1,
            };

            const { data: itemData } = await this.state.core.client.post(
              'core/getfilelist',
              params
            );

            context.commit('setTransaction', {
              id: transaction.id,
              status: 'completed',
              item: itemData && itemData.entry ? itemData.entry : null,
            });
            // refresh files list for from and to path
            context.dispatch('list', { path: fromPath });
            context.dispatch('list', { path: toPath });
            //refresh navigation bar
            context.commit('setRoot', {
              key: 'updateFile',
              value: { dirpath: toPath, name: item.name, activity: 'added' },
            });
          } else {
            // set transaction as failed
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'failed',
              message: response.error,
            });

            // expand bar
            context.commit('setTransactionsBar', {
              key: 'expanded',
              value: true,
            });
          }
        }
      );
    },

    async deleteFile(context, { item, transactionQueue, callback }) {
      const path = item.dirpath.replace(/\/$/, '');

      const transaction = {
        id: uuid(),
        type: 'delete',
        dirpath: path,
        params: { item, transactionQueue },
        name: item.name,
        group: 'others',
        status: 'preparing',
      };

      // add transaction
      context.commit('addTransaction', transaction);

      (transactionQueue ? transactionQueue : context.state.othersQueue).push(
        async () => {
          // watch to check if it's cancelled
          const index = _.findIndex(context.state.transactions, {
            id: transaction.id,
            status: 'preparing',
          });

          // check if transaction is still active
          if (index === -1) return;

          context.commit('setTransaction', {
            id: transaction.id,
            status: 'processing',
          });

          const response = await this.state.core.client.post(
            'app/explorer/deletefile',
            {
              path: item.dirpath,
              name: item.name,
            }
          );

          if (response.ok) {
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'completed',
            });

            //refresh navigation bar
            context.commit('setRoot', {
              key: 'updateFile',
              value: { dirpath: path, name: item.name, activity: 'deleted' },
            });
          } else {
            // set transaction as failed
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'failed',
              message: response.error,
            });

            // expand bar
            context.commit('setTransactionsBar', {
              key: 'expanded',
              value: true,
            });
          }

          if (callback) callback(response);
        }
      );
    },

    async removeShare(context, { item, transactionQueue }) {
      const transaction = {
        id: uuid(),
        type: 'RemoveShare',
        params: { item, transactionQueue },
        name: item.name,
        group: 'others',
        status: 'preparing',
      };

      // add transaction
      context.commit('addTransaction', transaction);

      (transactionQueue ? transactionQueue : context.state.othersQueue).push(
        async () => {
          // watch to check if it's cancelled
          const index = _.findIndex(context.state.transactions, {
            id: transaction.id,
            status: 'preparing',
          });

          // check if transaction is still active
          if (index === -1) return;

          context.commit('setTransaction', {
            id: transaction.id,
            status: 'processing',
          });

          const response = await this.state.core.client.post(
            'app/websharepro/deleteshare',
            {
              shareid: item.shareid,
            }
          );

          if (response.ok) {
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'completed',
            });
            context.commit('filterShares', item);
          } else {
            // set transaction as failed
            context.commit('setTransaction', {
              id: transaction.id,
              status: 'failed',
              message: response.error,
            });
          }
        }
      );
    },

    async renameFile(context, payload) {
      const path = payload.path.replace(/\/$/, '');

      // update in memory
      if (!payload.fromHome) {
        await this.dispatch('workers/call', {
          type: 'files/update',
          data: {
            dirpath: path, // remove trailing slash
            id: payload.id,
            name: payload.newname,
            path: `${path}/${payload.newname}`,
            fullfilename: `${path}/${payload.newname}`,
          },
        });
      }
      const transaction = {
        id: uuid(),
        type: 'rename',
        fileId: payload.id,
        from: payload.name,
        to: payload.newname,
        name: payload.newname,
        dirpath: path,
        undo: payload.undo,
        group: 'others',
        status: 'processing',
      };

      // add transaction
      context.commit('addTransaction', transaction);

      const response = await this.state.core.client.post(
        'app/explorer/renamefile',
        payload
      );

      if (response.ok) {
        // get actual file data
        const params = {
          path,
          start: 0,
          limit: 1,
          sendcommentinfo: 1,
          sendmetadatasetinfo: 1,
          search: payload.newname,
          sortby: 'name',
          sortdir: 1,
          sendfavinfo: 1,
        };

        payload.item.name = payload.newname;

        context.commit('setTransaction', {
          id: transaction.id,
          status: 'completed',
          item: payload.item,
        });

        let syncPayload = {
          name: payload.name,
          newName: payload.newname,
          dirpath: path,
          oldName: payload.name,
          activity: 'renamed',
        };

        context.commit('setRoot', { key: 'updateFile', value: syncPayload });
        context.commit('setRoot', { key: 'renamed', value: syncPayload });

        // update in memory
        if (!payload.fromHome) {
          await this.dispatch('workers/call', {
            type: 'files/update',
            data: {
              dirpath: path, // remove trailing slash
              id: payload.id,
              modifiedepochutc: dayjs().unix(),
            },
          });
        }
      } else {
        // update in memory
        if (!payload.fromHome) {
          await this.dispatch('workers/call', {
            type: 'files/update',
            data: {
              dirpath: path, // remove trailing slash
              id: payload.id,
              name: payload.name,
              fullfilename: `${path}/${payload.name}`,
              path: `${path}/${payload.name}`,
              modifiedepochutc: dayjs().unix(),
            },
          });
        }
        // set transaction as failed
        context.commit('setTransaction', {
          id: transaction.id,
          status: 'failed',
          message: response.error,
        });

        // expand bar
        context.commit('setTransactionsBar', {
          key: 'expanded',
          value: true,
        });
      }

      return response;
    },

    async versions(context, payload) {
      if (!payload.file) return;
      let tryCounter = 5;
      let params = {
        filepath: payload.file.dirpath,
        filename: payload.file.name,
        checksum: payload.checksum ? 1 : 0,
      };
      let response;
      let isLackOfChecksum = false;
      const getVersion = async () => {
        tryCounter -= 1;
        response = await this.state.core.client.post(
          'core/getversions',
          params
        );
        if (response.ok) {
          if (!payload.checksum || tryCounter < 0) return response;
          isLackOfChecksum = handleArrayResponse(response.data.version).some(
            ({ checksum }) => typeof checksum === 'object'
          );
          if (isLackOfChecksum) {
            return getVersion();
          } else {
            return response;
          }
        }
      };
      return getVersion();
    },

    async makeVersionLive(context, file) {
      let params = {
        filepath: file.dirpath,
        filename: file.filename,
        fileid: file.fileid,
      };

      const response = await this.state.core.client.post(
        'core/makeversionlive',
        params
      );
      return response;
    },

    async deleteVersion(context, file) {
      let params = {
        filepath: file.dirpath,
        filename: file.name,
        fileid: file.fileid,
      };

      const response = await this.state.core.client.post(
        'core/deleteversion',
        params
      );
      return response;
    },

    async deleteAllVersions() {
      const response = await this.state.core.client.post(
        'app/explorer/deleteallversions'
      );
      return response;
    },

    async downloadFile(context, file) {
      const response = await this.state.core.client.get(
        `core/downloadfile?${qs.stringify({
          filepath: file.path,
          filename: file.name,
          disposition: 'attachment',
        })}`
      );
      return response;
    },

    async permissionInfoForFile(context, path) {
      if (!context.state.currentFile) return;

      const itemPath = path || context.state.currentFile.path;

      if (context.state.permissionOfFile[itemPath]) return;

      // Write empty object to avoid double call while waiting for response

      await context.commit('addToPermissionOfFile', {
        path: itemPath,
        data: {},
      });

      // Get permission data from api
      const response = await this.dispatch('files/getPermissionInfo', itemPath);
      if (response.ok) {
        let responseData = response.data;

        await context.commit('addToPermissionOfFile', {
          path: itemPath,
          data: responseData,
        });
      } else {
        // If something went wrong, remove empty obj to set free to try again
        await context.commit('removePermissionOfFile', itemPath);
      }
    },

    async getPermissionInfo(context, fullpath) {
      const response = await this.state.core.client.post(
        'core/getaccessdetailsforpath',
        { fullpath: fullpath }
      );
      return response;
    },

    async shareInfoForFile(context) {
      if (!context.state.currentFile) return;

      const itemPath = context.state.currentFile.path;
      let responseData;
      if (this.state.auth.userlevel !== 'LIMITED') {
        const response = await this.dispatch('files/getShareInfo', itemPath);

        if (response.ok) {
          responseData = response.data;

          context.commit('addToStore', {
            key: 'shareInfoOfFile',
            value: responseData,
          });
        } else {
          try {
            context.commit('addToStore', {
              key: 'shareInfoOfFile',
              value: [],
            });
          } catch (e) {
            console.log('Unable to refresh shares');
          }
        }
      }
    },

    async getShareInfo(context, fullpath) {
      if (this.state.auth.userlevel !== 'LIMITED') {
        return await this.state.core.client.post('core/getshareforpath', {
          path: fullpath,
        });
      }
    },

    async getShareInfoByShareid(context, payload) {
      if (this.state.auth.userlevel !== 'LIMITED') {
        return await this.state.core.client.post('core/getshareforpath', {
          path: payload.path,
          shareid: payload.shareid,
        });
      }
    },

    async getManageableShares(context, path) {
      const response = await this.state.core.client.post(
        'core/getmanageablesharesforpath',
        { path, showchildpaths: 0 }
      );
      if (response.ok && response.data.share) {
        let res = response.data.share;
        if (!Array.isArray(res)) res = [res];
        context.commit('addToStore', {
          key: 'managedShareInfo',
          value: res,
        });
      }
    },

    async notificationInfoForFile(context) {
      if (!context.state.currentFile) return;

      const itemPath = context.state.currentFile.path;

      const response = await this.dispatch(
        'files/getNotificationInfo',
        itemPath
      );
      if (response.ok) {
        const responseData = response.data.effectiverule;
        //notification data for the grid
        let notification = [
          { name: 'Upload', value: responseData['upload'] },
          { name: 'Download', value: responseData['download'] },
          { name: 'Share', value: responseData['share'] },
          { name: 'Delete', value: responseData['delete'] },
          { name: 'Rename', value: responseData['rename'] },
          { name: 'Update', value: responseData['update'] },
          { name: 'Preview', value: responseData['preview'] },
          { name: 'Lock / Unlock', value: responseData['lock'] },
          {
            name: 'Self Notifications',
            value: responseData['enableSelfNotifications'],
          },
          {
            name: 'DisableAll',
            value: responseData['disableAllNotifications'],
          },
        ];
        context.commit('addToStore', {
          key: 'notificationInfoOfFile',
          value: notification,
        });
      } else {
        context.commit('addToStore', {
          key: 'notificationInfoOfFile',
          value: [],
        });
      }
    },

    async getNotificationInfo(context, fullpath) {
      const response = await this.state.core.client.post(
        'core/notificationsgeteffectiveruleforuserpath',
        { path: fullpath }
      );
      return response;
    },

    async versionsForFile(context) {
      const payload = {
        file: context.state.currentFile,
        checksum: 0,
      };
      const response = await this.dispatch('files/versions', payload);
      if (response && response.ok) {
        let responseData = response.data;

        context.commit('addToStore', {
          key: 'versionsOfFile',
          value: responseData,
        });
      } else {
        context.commit('addToStore', {
          key: 'versionsOfFile',
          value: [],
        });
      }
    },

    async getGeoIPInfo(context, IP) {
      const response = await this.state.core.client.post(
        'core/getauditgeoipdata',
        { ipaddress: IP }
      );
      return response;
    },

    async getSingleFileInfo(context, path) {
      const response = await this.state.core.client.post('core/fileinfo', {
        file: path,
      });
      return response;
    },

    async emptyRecycleBin() {
      const response = await this.state.core.client.post(
        'app/explorer/emptyrecyclebin'
      );
      return response;
    },

    async deletePartialUploads(context, includeInTransit) {
      const payload = includeInTransit ? { ignorets: 1 } : undefined;
      const response = await this.state.core.client.post(
        'app/explorer/deletepartialuploads',
        payload
      );
      return response;
    },

    async getFolderProperties(context, path) {
      const response = await this.state.core.client.post(
        'app/explorer/getfolderproperties',
        {
          path: path.path,
        }
      );
      return response;
    },

    async getMetadataValues(context, path) {
      const response = await this.state.core.client.post(
        'core/getmetadatavalues',
        {
          fullpath: path,
          excludehidden: 1,
        }
      );

      let arr = [];

      if (!Array.isArray(response.data.metadatasetvalue)) {
        arr.push(response.data.metadatasetvalue);
      } else {
        arr = response.data.metadatasetvalue;
      }

      return arr;
    },

    async getMetadataDefaultValues(context, setid) {
      const response = await this.state.core.client.get(
        'core/getdefaultmetadatavalues',
        {
          setid: setid,
        }
      );

      let arr = [];

      if (!Array.isArray(response.data.metadatasetvalue)) {
        arr.push(response.data.metadatasetvalue);
      } else {
        arr = response.data.metadatasetvalue;
      }

      return arr;
    },

    async saveMetadataAttr(context, payload) {
      const response = await this.state.core.client.post(
        'core/saveattributevalues',
        {
          ...payload,
        }
      );
      return response;
    },

    async saveMultifileMetadataAttr(context, payload) {
      const response = await this.state.core.client.post(
        'core/saveattributevaluesmultiple',
        {
          ...payload,
        }
      );
      return response;
    },

    async getMetadataSets(context, { path, multiplefiles }) {
      const response = await this.state.core.client.post(
        'core/getavailablemetadatasets',
        {
          fullpath: path,
          toassign: 1,
          showassigned: multiplefiles ? 1 : 0,
        }
      );
      return response;
    },

    async removeMetadataSet(context, { path, setid }) {
      const response = await this.state.core.client.post(
        'core/removesetfromfileobject',
        {
          fullpath: path,
          setid: setid,
        }
      );
      return response;
    },

    async getMetaDataSetsForSearch(context, path) {
      const response = await this.state.core.client.post(
        'core/getmetadatasetsforsearch',
        {
          fullpath: path,
        }
      );

      if (response.ok) {
        // if metadataset is not an array
        if (
          response.data.metadataset &&
          !Array.isArray(response.data.metadataset)
        ) {
          response.data.metadataset = [response.data.metadataset];
        }

        this.state.files.availableMetadataSets = getRecords(response.data);
      }
    },

    async addSetToFileObj(context, { path, setid }) {
      return await this.state.core.client.post('core/addsettofileobject', {
        fullpath: path,
        setid: setid,
      });
    },

    async addSetToMultipleFiles(context, { setid, parentpath, filenames }) {
      return await this.state.core.client.post('core/addsettomultiplefiles', {
        parentpath: parentpath,
        setid: setid,
        filenames: filenames,
      });
    },

    // return file url
    async url(context, file) {
      if (!file.format) {
        file.format = getFileType(file.name);
      }
      const pubShare =
        this.getters['core/isHostedCloud'] && !this.getters['core/isTrialMode'];
      let src = '';

      if (file.format === 'img') {
        src = `${serverURL.domainURL}/core/getfsslideimage?${qs.stringify({
          name: file.path,
          time: Date.now(),
          checkonly: file.dlpCheck ? 1 : 0,
        })}`;
      } else if (file.format === 'pdf') {
        src = `${serverURL.domainURL}/core/docconvert?${qs.stringify({
          name: file.path,
          filename: file.name,
          disposition: 'attachment',
          preview: 1,
          checkonly: file.dlpCheck ? 1 : 0,
        })}`;
      } else if (file.public && pubShare) {
        return `${serverURL.domainURL}/pubshare/share?${qs.stringify({
          path: file.public_path,
          redirect: file.noRedirect ? 0 : 1,
          preview: 1,
          checkonly: file.dlpCheck ? 1 : 0,
        })}`;
      } else if (file.public) {
        return `${serverURL.domainURL}/app/websharepro/share?${qs.stringify({
          path: file.public_path,
          redirect: file.noRedirect ? 0 : 1,
          preview: 1,
          checkonly: file.dlpCheck ? 1 : 0,
        })}`;
      } else {
        if (!file.candownload) {
          if (officeExt.includes(file.ext) || previewExt.includes(file.ext)) {
            return await this.dispatch('files/getDocConvertPreviewURL', file);
          }
        }

        src = `${serverURL.domainURL}/core/downloadfile?${qs.stringify({
          filepath: file.path,
          filename: file.name,
          disposition: 'attachment',
          redirect: file.noRedirect ? 0 : 1,
          preview: 1,
          checkonly: file.dlpCheck ? 1 : 0,
        })}`;
      }
      return src;
    },

    // return file url for downlad action
    async downloadUrl(context, file) {
      const download = `${serverURL.domainURL}/core/downloadfile?${qs.stringify(
        {
          filepath: file.path,
          filename: file.name,
          disposition: 'attachment',
          redirect: file.noRedirect ? 0 : 1,
        }
      )}`;
      const pubShare =
        this.getters['core/isHostedCloud'] && !this.getters['core/isTrialMode'];
      if (file.isCheck) return download;
      if (file.public && pubShare) {
        return `${serverURL.domainURL}/pubshare/share?${qs.stringify({
          path: file.public_path,
          filename: file.name,
          disposition: 'attachment',
          redirect: 0,
        })}`;
      } else if (file.public) {
        return `${serverURL.domainURL}/app/websharepro/share?${qs.stringify({
          path: file.public_path,
          filename: file.name,
          disposition: 'attachment',
          redirect: 0,
        })}`;
      } else {
        if (file.format === 'pdf' || file.ext === 'pdf') {
          return `${serverURL.domainURL}/core/docconvert?${qs.stringify({
            name: file.path,
            filename: file.name,
            disposition: 'attachment',
            redirect: 0,
          })}`;
        }
        return download;
      }
    },

    // return file url
    wopiEdit(context, file) {
      let request = {
        path: file.path,
      };

      if (file.is_new) {
        request.mode = 'newfile';
      }
      if (file.googleEdit) {
        request.googledocs = 1;
      }

      window.open(
        `${serverURL.domainURL}/core/getwopiediturl?${qs.stringify(request)}`,
        '_blank'
      );
    },

    getWopiPreview(context, file) {
      return `${serverURL.domainURL}/core/getwopiviewurl?${qs.stringify({
        path: file.path,
      })}`;
    },

    getDocConvertPreviewURL(context, file) {
      let filepath = file.fullfilename ? file.fullfilename : file.filepath;
      let requestParam = encodeAll('?name=' + encodeAll(filepath));
      return `${serverURL.domainURL}/ui/pdfjs/web/viewer.html?file=${serverURL.domainURL}/core/docconvert${requestParam}`;
    },

    async downloadPDF(context, file) {
      const filepath = file.fullfilename ? file.fullfilename : file.filepath;
      const blobURL = `core/docconvert?name=${encodeAll(filepath)}`;
      const response = await this.state.core.client.getBlob(blobURL);
      const urlData = window.URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = urlData;
      link.setAttribute('download', file.name + '.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    },

    combinePDF(context, { readonly, files }) {
      const filenames = files.reduce((prev, curr, index) => {
        return prev + `&fn${index + 1}=${encodeAll(curr.name)}`;
      }, '');

      //note: this function will not work in dev mode
      var url = '/ui/pdfjs/web/viewer.html?file=/core/combinepdf';
      url += encodeAll(
        '?time=' +
          Date.now() +
          '&count=' +
          files.length +
          '&filepath=' +
          encodeAll(files[0].dirpath)
      );

      url += encodeAll(filenames);

      window.open(
        url,
        readonly ? '_blank' : 'readonly',
        'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,copyhistory=yes,resizable=yes'
      );
    },
    rotateImage(context, payload) {
      return this.state.core.client.post('core/rotatefsimage', payload);
    },
    openDeleteConfirmationModal({ commit, dispatch }, buttons) {
      const params = {
        show: true,
        buttons: [
          ...buttons,
          {
            label: 'Close',
            outline: true,
            callback: () => {
              dispatch('closeDeleteConfirmationModal');
            },
          },
        ],
      };
      commit('addToStore', {
        key: 'deleteConfirmationModal',
        value: params,
      });
    },
    closeDeleteConfirmationModal({ commit }) {
      commit('addToStore', {
        key: 'deleteConfirmationModal',
        value: {
          show: false,
          buttons: null,
        },
      });
    },
    async createZipFile(context, { path, name, password }) {
      return this.state.core.client.post('core/ziparchivecreate', {
        path,
        name,
        password,
      });
    },
    setZipPreviewOpen({ commit }, status) {
      commit('setZipPreviewOpen', status);
    },
    addZipFolderPassword({ commit }, { path, password }) {
      commit('addZipFolderPassword', { path, password });
    },
  },
  getters: {
    getFilesInPath: (state) => (path) => {
      return state.paths[path] && state.paths[path].files
        ? state.paths[path].files
        : [];
    },
    selectedFiles: ({ sidebar }) => sidebar?.selected || [],
    getFilesGridInPath: (state) => (path, size) => {
      const files =
        state.paths[path] && state.paths[path].files
          ? state.paths[path].files
          : [];

      return _.chunk(files, size).map((chunk, index) => {
        return {
          id: index,
          chunk,
        };
      });
    },

    getTransactions: (state) => {
      const transactions = _.orderBy(
        state.transactions,
        ['status_priority', 'priority', 'added'],
        ['desc', 'desc', 'desc']
      );
      return _.groupBy(transactions, 'group');
    },

    getRunningTransactions: (state) => {
      const transactions = _.orderBy(
        state.transactions,
        ['status_priority', 'priority'],
        ['desc', 'desc']
      );
      const runningTransactions = _.filter(transactions, function (item) {
        return item.status === 'preparing' || item.status === 'processing';
      });
      const groupedTransactions = _.groupBy(runningTransactions, 'group');
      return {
        upload: groupedTransactions.upload ? groupedTransactions.upload : [],
        download: groupedTransactions.download
          ? groupedTransactions.download
          : [],
        others: groupedTransactions.others ? groupedTransactions.others : [],
        total: runningTransactions,
      };
    },

    getFileThumb: (state) => (path, modified, size) => {
      return `${serverURL.domainURL}/core/getfsthumbimage?${qs.stringify({
        name: path,
        width: size,
        height: size,
        cache: modified,
      })}`;
    },

    getMobileMenuState: (state) => () => {
      return state.mobileMenu;
    },

    getNewFileMenuState: (state) => () => {
      return state.mobileNewFileMenu;
    },

    getSidebarState: (state) => () => {
      return state.sidebar;
    },
    getPathMeta: (state) => (path) => {
      return state.paths[path] && state.paths[path].meta
        ? state.paths[path].meta
        : null;
    },
    selectedFullpath: function () {
      return this.$store.state.files.selectedItem.path
        ? this.$store.state.files.currentFile.path
        : '';
    },
    sidebarOpen: function () {
      return this.$store.state.files.showItemDetailsSidebar;
    },
    getSelectedFileComments: function () {
      return this.state.files.loadedCommentsForSelectedItem;
    },
    getLayout: (state) => {
      return grids[state.layoutType];
    },
    getIsGrid: (state) => {
      return state.layoutType.includes('grid');
    },
    searchResultsList: ({ searchResultsEntry }) => {
      const entries = getRecords(searchResultsEntry);
      return entries.map(function (element, count) {
        let obj = Object.assign({}, element);
        obj.id = ++count;
        return obj;
      });
    },
    transactionIsCancelled: (state) => (transactionId) => {
      const transactionIndex = _.findIndex(state.transactions, {
        id: transactionId,
      });
      const transaction = state.transactions[transactionIndex];
      return (
        !transaction ||
        transaction.id !== transactionId ||
        transaction.status === 'cancelled'
      );
    },
  },
};
