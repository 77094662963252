<template>
  <div>
    <Icon
      v-if="isFolder"
      name="folder"
      :aria-label="$t('folder')"
      class="inline-icon menufile-icon menufile-icon--folder"
      family="fas"
    />
    <FileIcon v-else :name="item.name" :aria-label="item.name" />
    <div class="menufile-details">
      <h3 class="name">{{ item.name }}</h3>
      <div class="details">
        <template v-if="!isFolder">
          {{ humanFileSize(item.fullsize) }} -
        </template>
        <DateDisplay :date="item.timestamp" />
      </div>
    </div>
    <div class="right-buttons text-right pr-1">
      <button class="btn btn--close" @click="$emit('close')">
        <Icon name="times" />
      </button>
    </div>
  </div>
</template>

<script>
import Icon from '../../Icon';
import FileIcon from '../../FileIcon';
import zipViewerMixin from '../zipViewerMixin';
import DateDisplay from "../../DateDisplay";

export default {
  name: 'ZipViewerMobileActionsListHeader',
  components: { Icon, FileIcon, DateDisplay },
  mixins: [zipViewerMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isFolder() {
      return this.item && this.item.type === 'dir';
    },
  },
};
</script>
