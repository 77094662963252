import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    miniAdminModal: {
      tab: 'general',
      show: false,
    },
    addUserModal: {
      show: false,
    },
    stats: {
      license: {},
      users: { users: [] },
      storage: {},
      teamfolders: {},
      shares: {},
    },
    resetPasswordModal: {
      username: undefined,
      show: false,
    },
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    merge(state, { key, value }) {
      state[key] = { ...state[key], ...value };
    },
    openMiniAdminModal(state, { visible, tab = 'general' }) {
      state.miniAdminModal.show = visible;
      state.miniAdminModal.tab = tab;
    },
    openAddUserModal(state, setVisible) {
      state.addUserModal.show = setVisible;
    },
    openResetPasswordModal(state, { visible, username = undefined }) {
      state.resetPasswordModal.show = visible;
      state.resetPasswordModal.username = username;
    },
    setCurrentTab(state, tab) {
      state.miniAdminModal.tab = tab;
    },
  },
  actions: {
    async showMiniAdminModal(context, payload) {
      await context.commit('openMiniAdminModal', payload);
    },
    async showAddUserModal(context, payload) {
      await context.commit('openAddUserModal', payload);
    },
    async showResetPasswordModal(context, payload) {
      await context.commit('openResetPasswordModal', payload);
    },
    async setCurrentTab(context, payload) {
      await context.commit('setCurrentTab', payload);
    },
    async getStats(context) {
      const response = await this.state.core.client.get('miniadmin/stats');

      if (response.ok) {
        context.commit('set', { key: 'stats', value: response.data });
      }

      return response;
    },
    async refreshStats(context) {
      await this.state.core.client.post('miniadmin/refreshstats');
      await context.dispatch('getStats');
    },
    async getUsers(context) {
      const response = await this.state.core.client.get('miniadmin/search');

      if (response.ok) {
        let users = Array.isArray(response.data.user)
          ? response.data.user
          : [response.data.user];
        context.commit('set', { key: 'users', value: users });
      }

      return response;
    },
    async inviteUser(context, payload) {
      const response = await this.state.core.client.post(
        'miniadmin/miniinvite',
        payload
      );

      if (response.ok) {
        await context.dispatch('showAddUserModal', false);
        await context.dispatch('getUsers');
      }

      return response;
    },
    async resetPassword(context, payload) {
      const response = await this.state.core.client.post(
        'miniadmin/setuserpassword',
        payload
      );

      if (response.ok) {
        await context.dispatch('showResetPasswordModal', { visible: false });
      }

      return response;
    },

    async changeUserRole(context, payload) {
      const response = await this.state.core.client.post(
        'miniadmin/updateuser',
        payload
      );

      if (response.ok) {
        await context.dispatch('getUsers');
      }

      return response;
    },
    async getCustomization(context) {
      const response = await this.state.core.client.post(
        'miniadmin/getconfigsettingxml',
        {},
        { param: 'CUSTOMIZATION_CUSTOM' }
      );

      if (response.ok) {
        context.commit('set', {
          key: 'customization',
          value: response.data.customdata,
        });
      }

      return response;
    },

    async setCustomization(context, payload) {
      let output =
        '<?xml version="1.0" encoding="UTF-8" ?><customizations><customdata>';

      const customization = context.state.customization;

      // generate xml entries
      Object.keys(customization).forEach((key) => {
        const item =
          typeof customization[key] !== 'object' ? customization[key] : '';
        output += `<${key}>${
          typeof payload[key] !== 'undefined' ? payload[key] : item
        }</${key}>`;
      });

      output += '</customdata></customizations>';

      const response = await this.state.core.client.postXML(
        'miniadmin/setconfigsettingxml',
        output,
        { param: 'CUSTOMIZATION_CUSTOM' }
      );

      if (response.ok) {
        await context.dispatch('getCustomization');
        await this.dispatch('core/getCustomizations');
      }

      return response;
    },
    async setCustomizationFile(context, { file, scope }) {
      return await this.state.core.client.postMultipart(
        'miniadmin/setconfigsettinglogo',
        { file: file },
        { param: `CUSTOMIZATION_LOGO_${scope}` }
      );
    },
  },
};
