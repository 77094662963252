<template>
  <div>
    <div class="form-control form-control--select">
      <select
        v-model="field.value"
        class="meta-input"
        :disabled="metadata.write !== 1 || readOnly"
        :required="metadata.required === 1"
        @change="onFieldChange"
      >
        <option v-for="set in enumToObj" :key="set.value" :value="set.value">
          {{ set.text }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import dataTypeMixin from './_dataTypeMixin';
export default {
  mixins: [dataTypeMixin],
  computed: {
    enumToObj() {
      if (this.field.enumvalues.length) {
        let arr = this.field.enumvalues.split(',');

        return arr.map((item, index) => {
          if (item.length > 0) {
            return { text: item, idx: index };
          }
        });
      } else {
        return [];
      }
    },
  },
};
</script>
