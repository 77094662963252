<template>
  <div
    class="file-preview-item file-preview-item--txt"
    :class="{ ['file-preview-item--loaded']: !isLoading }"
  >
    <Loader v-if="isLoading" />
    <div v-else class="txt-wrapper">
      <pre class="m-0">{{ content }}</pre>
    </div>
  </div>
</template>

<script>
import Loader from 'common/assets/loader.svg';
import { readTextFromBlobAsync } from 'common/utils/files';
import { mapActions } from 'vuex';

export default {
  components: {
    Loader,
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: true,
      blob: null,
      content: '',
    };
  },
  watch: {
    file: function () {
      this.loadBlob();
    },
  },
  mounted() {
    this.loadBlob();
  },
  methods: {
    ...mapActions('zipViewer', {
      getBlob: 'blob',
    }),
    async loadBlob() {
      this.isLoading = true;

      const request = await this.getBlob(this.file);
      if (request.ok) {
        this.content = await readTextFromBlobAsync(request.data);
      } else {
        this.$emit('fallback', {
          view: 'DefaultView',
          error:
            request.error ||
            this.$t('zip_preview.nested_preview.errors.unexpected_error'),
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
