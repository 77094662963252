<template>
  <div class="zip-viewer-file-list__actions">
    <!-- region Action Icons -->
    <div class="zip-viewer-file-list__actions-icons">
      <!--@ Preview/Open Icon -->
      <span
        v-if="isFolder"
        v-tooltip="$t('file_list.item_action.open')"
        class="zip-viewer-file-list__actions-icon"
        @click="$emit('browse')"
      >
        <Icon
          name="eye"
          :aria-label="$t('file_list.item_action.open')"
          family="fal"
        />
      </span>
      <span
        v-else
        v-tooltip="$t('file_list.item_action.preview')"
        class="zip-viewer-file-list__actions-icon"
        @click="$emit('preview-item')"
      >
        <Icon
          name="eye"
          :aria-label="$t('file_list.item_action.preview')"
          family="fal"
        />
      </span>

      <!--@ Download Icon -->
      <span
        v-tooltip="$t('file_list.item_action.download')"
        class="zip-viewer-file-list__actions-icon"
        :class="{
          'zip-viewer-file-list__actions-icon--disabled':
            !canDownload || isFolder,
        }"
        @click="downloadItem"
      >
        <Icon
          name="download"
          :aria-label="$t('file_list.item_action.download')"
          family="fal"
        />
      </span>

      <!--@ Delete Icon -->
      <VPopover
        offset="0"
        class="sample"
        container="body"
        style="display: inline-block"
        :disabled="!canUpload"
        @show="$emit('toggle-context-menu', true)"
        @hide="$emit('toggle-context-menu', false)"
      >
        <span
          v-tooltip="$t('file_list.item_action.delete')"
          class="zip-viewer-file-list__actions-icon"
          :class="{
            'zip-viewer-file-list__actions-icon--disabled': !canUpload,
          }"
        >
          <Icon
            name="trash-alt"
            :aria-label="$t('file_list.item_action.delete')"
            family="fal"
          />
        </span>
        <div slot="popover">
          <Alert
            button-type="danger"
            :buttons="[
              {
                label: $t('file_list.item_action.confirm_button'),
                callback: () => $emit('delete-item'),
                focus: true,
              },
              {
                label: $t('file_list.item_action.cancel_button'),
                outline: true,
              },
            ]"
            :disable-autofocus="true"
          >
            <div class="text-nowrap d-flex justify-center">
              {{ $t('file_list.item_action.delete_headline') }} <br />
              {{ $t('file_list.item_action.delete_description') }}
            </div>
          </Alert>
        </div>
      </VPopover>
    </div>
    <!-- endregion -->

    <!-- region Dropdown Actions -->
    <div class="zip-viewer-file-list__actions-dropdown">
      <VPopover
        offset="0"
        popover-class="dropdown"
        class="sample"
        container="body"
        @show="$emit('toggle-context-menu', true)"
        @hide="$emit('toggle-context-menu', false)"
      >
        <span
          v-tooltip="$t('file_list.item_action.more')"
          class="zip-viewer-file-list__actions-icon"
        >
          <Icon
            name="ellipsis-h"
            :aria-label="$t('file_list.item_action.more')"
            family="fal"
          />
        </span>
        <div slot="popover">
          <div>
            <!--@ Preview Dropdown Action -->
            <div
              v-close-popover
              class="dropdown-item"
              @click="$emit('preview-item')"
            >
              <Icon
                name="eye"
                :aria-label="$t('file_list.item_action.preview')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.item_action.preview') }}
                <span class="shortkey float-right text-capitalize">
                  Enter
                </span>
              </span>
            </div>

            <!--@ Download Dropdown Action -->
            <div
              v-close-popover
              class="dropdown-item"
              :class="{ 'dropdown-item--disabled': !canDownload || isFolder }"
              @click="downloadItem"
            >
              <Icon
                name="download"
                :aria-label="$t('file_list.item_action.download')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.item_action.download') }}
                <span class="shortkey float-right text-capitalize"> D </span>
              </span>
            </div>

            <!--@ Delete Dropdown Action -->
            <div
              v-close-popover
              class="dropdown-item"
              :class="{
                'dropdown-item--disabled': !canUpload,
              }"
              @click="toggleConfirmationDialog(true)"
            >
              <Icon
                name="trash-alt"
                :aria-label="$t('file_list.item_action.delete')"
                family="fal"
              />
              <span class="popover-text">
                {{ $t('file_list.item_action.delete') }}
                <span class="shortkey float-right text-capitalize"> Del </span>
              </span>
            </div>
          </div>
        </div>
      </VPopover>
    </div>
    <!-- endregion -->

    <!-- region Delete Confirmation Dialog -->
    <DialogModal
      v-if="confirmationDialogVisible"
      class="dialog-modal-overlay dialog-modal-form timed-fade stick-top"
      size="small"
      z-index="1000000"
      :buttons="actionButtons"
      :class="[{ 'dialog-modal-visible': confirmationDialogVisible }]"
      :title="$t('file_list.item_action.delete_modal_title')"
      :visible="confirmationDialogVisible"
      @close="toggleConfirmationDialog(false)"
      @keyup="$emit('keyup', $event)"
    >
      {{ $t('file_list.item_action.delete_modal_headline') }} <br />
      {{ $t('file_list.item_action.delete_modal_description') }}
    </DialogModal>
    <!-- endregion -->
  </div>
</template>

<script>
import Icon from '../../Icon';
import Alert from '../../Alert';
import DialogModal from '../../DialogModal';
import { VPopover } from 'v-tooltip';

export default {
  name: 'ZipViewerFileListActions',
  components: {
    Icon,
    VPopover,
    Alert,
    DialogModal,
  },
  props: {
    isFolder: {
      type: Boolean,
      default: false,
    },
    canUpload: {
      type: Boolean,
      default: false,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmationDialogVisible: false,
    };
  },
  computed: {
    actionButtons() {
      return [
        {
          label: this.$t('file_list.item_action.delete_modal_confirm_button'),
          callback: () => {
            this.toggleConfirmationDialog(false);
            this.$emit('delete-item');
          },
          focus: true,
          danger: true,
        },
        {
          label: this.$t('file_list.item_action.delete_modal_cancel_button'),
          callback: () => this.toggleConfirmationDialog(false),
          outline: true,
        },
      ];
    },
  },
  methods: {
    toggleConfirmationDialog(show) {
      if (this.canUpload) {
        this.confirmationDialogVisible = show;
      }
    },
    downloadItem() {
      if (this.canDownload && !this.isFolder) {
        this.$emit('download-item');
      }
    },
  },
};
</script>
