<template>
  <div class="file-list-loader">
    <ContentLoader
      v-if="grid"
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
      :height="170"
      :width="170"
      :speed="1"
    >
      <rect x="9" y="5" rx="2" ry="2" width="10" height="10" />
      <rect x="6.5" y="16" rx="1" ry="1" :width="15" height="2" />
    </ContentLoader>
    <ContentLoader
      v-else
      primary-color="#E1E4E8"
      secondary-color="#F1F2F4"
      :height="33"
      :width="1000"
      :speed="1"
    >
      <rect x="56" y="2" rx="4" ry="4" width="30" height="30" />
      <rect
        x="100"
        y="12"
        rx="6"
        ry="6"
        :width="random(150, 300)"
        height="12"
      />
    </ContentLoader>
  </div>
</template>
<script>
import { ContentLoader } from 'vue-content-loader';
import { isMobileDevice } from '../utils/responsive.js';
import _ from 'lodash';

export default {
  components: {
    ContentLoader,
  },
  props: {
    grid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileDevice,
  },
  methods: {
    random: _.random,
  },
};
</script>
