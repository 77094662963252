var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isPicker)?_c('div',{staticClass:"col-properties col-info",class:{
    'col-properties--mobile': _vm.isMobile || _vm.isTablet,
    'd-none': _vm.hasCustomList && !_vm.isMobile && !_vm.isTablet,
    'd-block': _vm.hasCustomList && _vm.hasSearchResults,
  },on:{"contextmenu":function($event){$event.preventDefault();return _vm.contextMenu.apply(null, arguments)},"click":_vm.expand}},[(_vm.canShowProps(_vm.item))?_c('ul',{staticClass:"properties-row",attrs:{"role":"cell"}},[_c('li',{staticClass:"main-icon"},[_c('Icon',{attrs:{"name":"info-circle","family":"fas"}})],1),(_vm.lockedInfo(_vm.item))?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.lockedRead(_vm.item)),expression:"lockedRead(item)"}],staticClass:"main-icon active"},[_c('Icon',{attrs:{"name":"lock","family":"fas"}})],1):_vm._e(),(_vm.item.canmanageshare)?_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.item.canmanageshare ? _vm.$t('Managed Share') : ''),expression:"item.canmanageshare ? $t('Managed Share') : ''"}],staticClass:"roll-over",class:{ active: _vm.item.canmanageshare }},[_c('Icon',{attrs:{"name":"share-alt-square","family":"fas"}})],1):_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t(_vm.getShareInfo)),expression:"$t(getShareInfo)"}],staticClass:"roll-over",class:{ active: typeof _vm.item.isshared === 'string' }},[_c('Icon',{attrs:{"name":"share-alt","family":"fas"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tagged),expression:"tagged"}],staticClass:"roll-over",class:{ active: _vm.item.tagged }},[_c('Icon',{attrs:{"name":"tag","family":"fas"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
        _vm.item.hasnotificationsrule ? _vm.$t('Notification rule added') : ''
      ),expression:"\n        item.hasnotificationsrule ? $t('Notification rule added') : ''\n      "}],staticClass:"roll-over",class:{ active: _vm.item.hasnotificationsrule }},[_c('Icon',{attrs:{"name":"bell","family":"fas"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.comment),expression:"comment"}],staticClass:"roll-over",class:{ active: _vm.item.commented }},[_c('Icon',{attrs:{"name":"comment","family":"fas"}})],1),_c('li',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.lockedRead(_vm.item)),expression:"lockedRead(item)"}],staticClass:"roll-over",class:{ active: _vm.item.locked }},[_c('Icon',{attrs:{"name":"lock","family":"fas"}})],1)]):(_vm.isMobile || (_vm.isTablet && !_vm.isPicker))?_c('div',{staticClass:"file-details cursor-pointer",on:{"click":function($event){return _vm.$emit('openSidebarForFile')}}},[_c('Icon',{attrs:{"name":"info-circle","family":"fas"}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }