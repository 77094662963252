/**
 * Method to check email domain
 * @param {type} email
 * @returns {String|Boolean}
 */
export function getEmailDomain(email) {
    if (!email)
        return false;
    if (email.indexOf("@") !== -1)
        return email.substring(email.indexOf("@") + 1);
    else
        return "";
}
/**
 * check if email is valid for SSO
 * @param {type} email
 * @returns {Boolean}
 */
export function isValidEmail(email) {
    if (!email)
        return false;
    //Ref : https://stackoverflow.com/a/9204568
   var re = /\S+@\S+\.\S+/;
   return re.test(email);
}
/**
 * check if email is present in domain list
 * @param {type} email
 * @param {type} domainsallowed
 * @returns {unresolved}
 */
export function isSamlSSODomain(email, domainsallowed) {
    let domainlist = domainsallowed.toLowerCase().split(",");
    return domainlist.includes(email);
}

