<template>
  <div v-if="users && users.length > 0" class="users-chips">
    <div class="users-icons avatar-list">
      <div
        v-for="user in users.length === 3
          ? users.slice(0, 3)
          : users.slice(0, 2)"
        :key="`${user.username}-user-chip`"
        class="avatar-wrapper"
      >
        <Avatar :cache="0" :user="user.username" size="small" />
      </div>
      <div v-if="users.length > 3" class="avatar-wrapper count">
        <div class="avatar">+{{ users.length - 2 }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'common/components/Avatar.vue';

export default {
  components: {
    Avatar,
  },
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.users-chips {
  display: flex;
  align-items: center;
  width: fit-content;

  .users-icons {
    min-height: 25px;
    min-width: 25px;
    display: flex;

    .avatar-wrapper {
      height: 25px;
      width: 25px;
      min-height: 25px;
      min-width: 25px;
      margin-left: -11px;

      &:first-child {
        margin-left: 0;
      }

      .avatar {
        transform: scale(0.5);
        transform-origin: top left;
        border: solid 1px var(--bg-light);
      }

      &.count .avatar {
        height: 100%;
        width: 100%;
        transform: none;
        padding: 3px;
        font-size: 11px;
        color: var(--color-primary--foreground);
        background: var(--color-primary);
        border: 1px solid var(--border-color);
      }
    }
  }
}
</style>
