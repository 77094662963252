<template>
  <div>
    <section>
      <Loader v-if="isLoading" loading full />

      <div class="d-flex flex-column flex-md-row">
        <div
          v-if="stats.license"
          id="license-section"
          class="d-flex flex-column col col-md-5 p-0"
        >
          <h4 class="text-dark">{{ $t(isTrial ? 'Trial' : 'Active Site') }}</h4>

          <div class="d-flex flex-row mt-3 pr-md-4">
            <div class="col-4 col-md-5 p-0 progress-circular-wrapper">
              <ProgressCircular
                :value="usedSeats"
                :show-percentage="false"
                :title="usedSeats.toString()"
                :total-value="seats"
                :size="90"
              />
              <p
                v-html="
                  $t('<b>{usedSeats} / {totalSeats}</b> seats used', {
                    usedSeats: usedSeats || 0,
                    totalSeats: seats || 0,
                  })
                "
              ></p>
            </div>

            <div
              class="
                d-flex
                flex-column
                align-items-center
                col
                p-0
                pl-md-2
                justify-content-center
              "
            >
              <h5
                v-if="isTrial"
                class="text-light"
                v-html="
                  $tc(
                    '<b>{count} day left</b> in trial | <b>{count} days left</b> in trial',
                    stats.license.daysremaining
                  )
                "
              />
              <h5
                v-else
                class="text-light"
                v-html="
                  $tc(
                    '<b>{count} day left</b> for site expiry | <b>{count} days left</b> for site expiry',
                    stats.license.daysremaining
                  )
                "
              />
              <button class="btn btn-primary" @click="openURI(buyNowURI)">
                {{ $t(isTrial ? 'Buy Now' : 'Renew Site') }}
              </button>
              <span class="text-light">{{ $t('or') }}</span>
              <a href="https://www.filecloud.com/contactus/" target="_blank">{{
                $t('Contact Sales')
              }}</a>
            </div>
          </div>
        </div>

        <div
          id="storage-section"
          class="d-flex flex-column col p-0 mt-3 mt-md-0 pl-md-4"
        >
          <div class="d-flex align-items-center">
            <h4 class="text-dark">{{ $t('Storage') }}</h4>
            <Icon
              v-tooltip="
                $t('This is a cached value and will get updated periodically')
              "
              name="info-circle"
              family="fas"
              class="ml-2 mb-2 cursor-pointer small text-primary"
            />
            <button
              class="btn mx-3 shadow-none ml-auto text-light"
              @click="refreshStats"
            >
              <Icon family="far" name="redo" />
              <span class="ml-2">{{ $t('Refresh') }}</span>
            </button>
          </div>

          <div class="d-flex flex-row align-items-center mt-3">
            <div class="col-4 col-md-3 p-0 progress-circular-wrapper">
              <div v-if="unlimitedStorage" class="d-flex align-items-center">
                <h3 class="m-0 mr-2">{{ $t('Unlimited') }}</h3>
              </div>
              <ProgressCircular
                v-else
                color="var(--text-medium)"
                :value="unlimitedStorage ? 0 : totalUesedSpace || 0"
                :total-value="unlimitedStorage ? 1 : totalFreeSpace || 100"
                :size="90"
              />
              <p>
                {{
                  $t('{totalsizeused} / {totalsize}', {
                    totalsize: unlimitedStorage ? 'Unlimited' : totalSize || 0,
                    totalsizeused: totalsizeused || 0,
                  })
                }}
              </p>
            </div>

            <div class="d-flex flex-column align-items-center col col-md-7 p-0">
              <h5
                class="text-light"
                v-html="
                  $t('<b>{totalsizefree}</b> space available', {
                    totalsizefree: unlimitedStorage ? 'Unlimited' : totalSize,
                  })
                "
              ></h5>
              <h5
                class="text-light"
                v-html="
                  $tc(
                    'no files|<b>{amount}</b> file|<b>{amount}</b> files',
                    stats.storage.totalmanagedfiles || 0,
                    { amount: formattedManagedFiles }
                  )
                "
              ></h5>
              <button
                v-if="isCloud"
                class="btn btn-primary"
                @click="openURI(`${buyNowURI}?action=storage`)"
              >
                {{ $t('Add More Storage') }}
              </button>
              <v-popover>
                <a class="mt-1">{{ $t('View Detailed Storage') }}</a>
                <template slot="popover">
                  <div>
                    <table>
                      <tr>
                        <td class="pr-4 px-2 py-1 text-left">
                          {{ $t('Storage Usage:') }}
                          <Icon
                            v-tooltip="
                              $t(
                                'This is a cached value and will get updated periodically'
                              )
                            "
                            name="info-circle"
                            family="fas"
                            class="cursor-pointer small text-primary"
                          />
                        </td>
                        <td class="px-2">
                          {{ bytesToSize(totalUesedSpace) }} /
                          {{ unlimitedStorage ? $t('Unlimited') : totalSize }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-4 px-2 py-1 text-left">
                          {{ $t('Free Space:') }}
                          <Icon
                            v-if="!unlimitedStorage"
                            v-tooltip="
                              $t(
                                'This is a cached value and will get updated periodically'
                              )
                            "
                            name="info-circle"
                            family="fas"
                            class="cursor-pointer small text-primary"
                          />
                        </td>
                        <td class="px-2">
                          {{
                            unlimitedStorage
                              ? $t('Unlimited')
                              : bytesToSize(totalFreeSpace)
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-4 px-2 py-1 text-left">
                          {{ $t('Files:') }}
                        </td>
                        <td class="px-2">
                          {{ formattedManagedFiles }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-4 px-2 py-1 text-left">
                          {{ $t('Thumbnails:') }}
                        </td>
                        <td class="px-2">
                          {{ formattedThumbnailManagedFiles }}
                        </td>
                      </tr>
                      <tr>
                        <td class="pr-4 px-2 py-1 text-left">
                          {{ $t('File Versions:') }}
                        </td>
                        <td class="px-2">
                          {{ formattedVersionManagedFiles }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </template>
              </v-popover>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="d-flex flex-row p-0">
        <div class="d-flex flex-column col col-md-6 p-0 pr-md-4">
          <h4 v-if="canReadUser" class="text-dark">{{ $t('Users') }}</h4>

          <div
            v-if="canReadUser"
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              mt-3
              section-divisor
            "
          >
            <div class="user__info">
              <div class="users-wrapper" @click="$emit('switchTab', 'users')">
                <UsersChips :users="stats.users.users" />
              </div>

              <div class="d-flex flex-column ml-3">
                <h5>
                  {{
                    $tc(
                      'no users|{count} user|{count} users',
                      stats.users.totalusers || 0
                    )
                  }}
                </h5>
                <span class="text-light">{{
                  $tc('{count} remaining', remainingSeats)
                }}</span>
              </div>
            </div>

            <div class="user__controls">
              <button
                v-if="canInviteUser"
                class="btn d-flex align-items-center border"
                @click="openAddUserModal"
              >
                <Icon family="fas" name="plus" />
                <span>{{ $t('Add New User') }}</span>
              </button>
            </div>
          </div>

          <h4 class="mt-3 text-dark">{{ $t('Team folders') }}</h4>

          <div
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              mt-2
              section-divisor
            "
          >
            <div class="user__info">
              <div class="position-relative">
                <Icon family="fas" name="folder" />
                <Icon family="fas" name="users" />
              </div>

              <div class="d-flex flex-column ml-3">
                <h5>
                  {{ $t(stats.teamfolders.enabled ? 'Enabled' : 'Disabled') }}
                </h5>
                <span
                  v-if="stats.teamfolders.enabled"
                  class="text-light"
                  v-html="
                    $tc(
                      'no team folder|<b>{count}</b> team folder|<b> {count} </b> team folders',
                      stats.teamfolders.totalfolders || 0
                    )
                  "
                />
              </div>
            </div>

            <div class="user__controls">
              <button
                class="btn d-flex align-items-center border"
                @click="openURI('/ui/admin/index.html#team.')"
              >
                <Icon family="fas" name="cogs" />
                <span>{{ $t('Manage') }}</span>
              </button>
            </div>
          </div>

          <h4 class="mt-3 text-dark">{{ $t('Shares') }}</h4>

          <div
            class="
              d-flex
              flex-row
              align-items-center
              justify-content-between
              mt-2
            "
          >
            <div class="user__info">
              <Icon family="fas" name="share-alt" />

              <span class="text-light ml-3">{{
                $tc(
                  'no shares|{count} share|{count} shares',
                  stats.shares.totalshares || 0
                )
              }}</span>
            </div>

            <div class="user__controls">
              <button
                class="btn d-flex align-items-center border"
                @click="openURI('/ui/admin/index.html#shrs.')"
              >
                <Icon family="fas" name="cogs" />
                <span>{{ $t('Manage') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="useful__resources">
      <div class="d-flex flex-row">
        <div class="d-flex flex-column col-12 p-0">
          <div class="d-flex flex-wrap">
            <div class="d-flex flex-column col-12 col-md-5 p-0 pr-md-4">
              <h4 class="text-dark">{{ $t('Get started') }}</h4>

              <div class="get__started d-flex flex-row align-items-center mt-3">
                <img alt="" src="../../assets/tour-bg.png" />

                <div class="d-flex flex-column align-items-center px-3">
                  <h4>{{ $t('Learn the FileCloud basics') }}</h4>
                  <a
                    target="_blank"
                    href="https://www.filecloud.com/supportdocs/display/cloud/FileCloud+Online+Quick+Start+Guide"
                    >{{ $t("Let's Go!") }}</a
                  >
                </div>
              </div>
            </div>

            <div class="d-flex flex-column col mt-4 mt-md-0 pl-0 pl-md-4">
              <h4 class="text-dark">{{ $t('Manage') }}</h4>

              <div class="d-flex flex-row mt-3">
                <ul>
                  <li>
                    <a target="_blank" href="/ui/admin2/index.html#grup."
                      >{{ $t('Groups') }}
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="/ui/admin/index.html#sets.">{{
                      $t('Policies')
                    }}</a>
                  </li>
                  <li>
                    <a target="_blank" href="/ui/admin/index.html#retd.">{{
                      $t('Governance')
                    }}</a>
                  </li>
                  <li>
                    <a target="_blank" href="/ui/admin2/index.html#compl.">{{
                      $t('Compliance')
                    }}</a>
                  </li>
                  <li>
                    <a target="_blank" href="/ui/admin/index.html#wflw.">{{
                      $t('Workflows')
                    }}</a>
                  </li>

                  <li>
                    <a target="_blank" href="/ui/admin/index.html#dash.">{{
                      $t('View All')
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="d-flex flex-column col mt-4 mt-md-0 pr-0">
              <h4 class="text-dark">{{ $t('Learn more') }}</h4>

              <div class="d-flex flex-row mt-3">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.filecloud.com/filecloud-request-free-demo/"
                      >{{ $t('Schedule a free demo') }}
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.filecloud.com/customer-stories/"
                      >{{ $t('Case Studies') }}</a
                    >
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.filecloud.com/FileCloud%20Server%20DataSheet.pdf"
                      >{{ $t('Data Sheet / FAQ') }}</a
                    >
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.filecloud.com/supportdocs/"
                      >{{ $t('Documentation') }}</a
                    >
                  </li>
                  <li>
                    <a target="_blank" href="https://ideas.filecloud.com/">{{
                      $t('Suggest an idea')
                    }}</a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.filecloud.com/supportdocs/display/cloud/FileCloud+Developer+Guide"
                      >{{ $t('Developer API') }}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ProgressCircular from 'common/components/ProgressCircular';
import Loader from 'common/components/Loader';
import Icon from 'common/components/Icon';
import UsersChips from '@/components/UsersChips';

import { bytesToSize } from 'common/utils/files';

export default {
  name: 'General',
  components: { UsersChips, Icon, ProgressCircular, Loader },
  computed: {
    unlimitedStorage() {
      return (
        this.stats.storage.maxsitestorage === 0 &&
        this.stats.storage.totalquotalimited === 0
      );
    },
    totalsizefreeraw() {
      return this.stats?.storage
        ? this.stats.storage.maxsitestorage * 1024 * 1024 * 1024 -
            this.totalsizeusedraw
        : 0;
    },
    totalFreeSpace() {
      return this.stats?.storage ? this.stats.storage.totalsizefreeraw : 0;
    },
    totalSize() {
      return this.stats?.storage ? this.stats.storage.totalsize : 0;
    },
    totalUesedSpace() {
      return this.stats?.storage ? this.stats.storage.totalsizeusedraw : 0;
    },
    totalsizeraw() {
      return this.stats.storage.maxsitestorage * 1024 * 1024 * 1024;
    },
    totalsize() {
      return this.bytesToSize(this.totalsizeraw);
    },
    totalsizeusedraw() {
      return this.stats.storage.totalsizeusedraw;
    },
    totalsizeused() {
      return this.bytesToSize(this.totalsizeusedraw);
    },
    isLoading() {
      return (
        this.$store.state.loading['miniAdmin/getStats'] ||
        this.$store.state.loading['miniAdmin/refreshStats']
      );
    },
    stats() {
      return this.$store.state.miniAdmin.stats;
    },
    permissions() {
      return this.stats?.userpermissions
        ? this.stats.userpermissions.permissions
        : [];
    },
    canInviteUser() {
      return this.permissions.indexOf('users.create') > -1;
    },
    canReadUser() {
      return this.permissions.indexOf('users.read') > -1;
    },
    isLicenceExpired() {
      return this.stats && this.stats.license
        ? this.stats.license.daysremaining > 0
          ? false
          : true
        : false;
    },
    seats() {
      return this.stats.license.accounts;
    },
    usedSeats() {
      return this.stats.license.usedaccounts || 0;
    },
    remainingSeats() {
      return this.seats - this.usedSeats || 0;
    },
    licenseTotalDuration() {
      const diffTime =
        new Date(this.stats.license.expirydate) -
        new Date(this.stats.license.issuedate);

      return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    },
    isTrial() {
      return !!this.$store.state.core.customization['TRIALMODE'];
    },
    isCloud() {
      return !!this.$store.state.core.customization['HOSTEDCLOUD'];
    },
    isCommunityEdition() {
      return this.$store.state.core.customization?.isCommunityEdition();
    },
    buyNowURI() {
      let page = '';

      if (this.isCloud || !this.isTrial) page = window.location.hostname;
      else if (this.isCommunityEdition) page = 'community';
      else page = 'server';

      return `https://portal.getfilecloud.com/ui/user/index.html#/sites${
        this.isTrial ? '/trial' : ''
      }/${page}`;
    },
    formattedManagedFiles() {
      return new Intl.NumberFormat('en-US').format(
        this.stats.storage.totalmanagedfiles || 0
      );
    },
    formattedThumbnailManagedFiles() {
      return new Intl.NumberFormat('en-US').format(
        this.stats.storage.totalthumbnailsmanagedfiles || 0
      );
    },
    formattedVersionManagedFiles() {
      return new Intl.NumberFormat('en-US').format(
        this.stats.storage.totalversionmanagedfiles || 0
      );
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch('miniAdmin/getStats');
    },
    async refreshStats() {
      await this.$store.dispatch('miniAdmin/refreshStats');
    },
    openAddUserModal() {
      this.$store.dispatch('miniAdmin/showAddUserModal', true);
    },
    openURI(uri) {
      window.open(uri, '_blank');
    },
    bytesToSize,
  },
};
</script>

<style lang="scss" scoped>
section {
  margin: 0 18px;
  padding-bottom: 28px;
  border-bottom: 1px solid var(--border-color);

  .text-light {
    color: var(--text-light) !important;
  }

  .btn-circle {
    color: var(--text-light);
    font-size: 0.8rem;
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  + section {
    padding-top: 28px;
  }

  a,
  span {
    font-size: 12px;
  }

  h4 {
    font-weight: 500;
    font-size: 14.4px;
  }

  h5 {
    font-size: 12px;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-primary);
    line-height: 90px;
  }

  button {
    padding-top: 0;
    padding-bottom: 0;
    max-width: 100%;
    width: 143px;
    font-weight: 600;
    font-size: 13px;
    height: 24px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      height: 18px;
    }
  }

  div.users__container {
    + .users__container {
      margin-top: 8px;
    }

    h4 {
      margin: 0;
    }

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }

  div.user__info {
    display: flex;
    flex-direction: row;
    align-items: center;

    h5 {
      margin: 0;
      font-weight: bold;
      font-size: 14px;
    }

    span {
      font-size: 12px;
    }

    i {
      color: var(--color-primary);
      font-size: 28px;

      + i {
        position: absolute;
        color: #ffffff;
        font-size: 12px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  div.section-divisor {
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 18px;
  }

  div.user__controls {
    margin-left: 8px;

    button {
      width: fit-content;
      height: auto;
      padding: 0;

      &.border {
        padding: 7px 14px;
        border: 1px solid var(--border-color) !important;
      }

      i {
        font-size: 10px;
        margin-right: 6px;
        color: var(--text-light) !important;
      }

      span {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-primary);
      }
    }
  }

  &.useful__resources {
    h2 {
      font-size: 24px;
    }

    .get__started {
      height: 127px;
      border: 1px solid var(--border-color);
      border-radius: 4px;

      img {
        max-width: 50%;
        width: 127px;
        height: auto;
      }

      h4 {
        line-height: 18px;
        text-align: center;
        font-weight: bold;
        font-size: 12px;
      }
    }

    @media screen and (max-width: 768px) {
      ul li {
        line-height: 12px;
        padding: 12px 0;
      }
    }
  }

  .users-wrapper {
    cursor: pointer;
  }
}
</style>
