import router from '../../../router.js';
import store from '../../../store/index.js';
import confetti from 'canvas-confetti';

export default {
  title: 'Sharing an Item',
  isCompleted: false,
  id: 'onboarding.file-basics.file-share',
  steps: [
    {
      id: 'onboarding.file-basics.file-share.navigate-my-files',
      description: 'Navigate to "My Files"',
      shape: 'circle',
      element:
        '#folderSummary > div.folder-list.folder-list--spaced.row > div:nth-child(1) > a',

      onStepStart: () => {
        const name = router.history.current.name;
        if (name === 'files-sub') {
          store.dispatch('onboarding/nextStep');
        } else if (name !== 'files') {
          //page that has left sidebar
          store.commit('onboarding/updateStep', {
            id: 'onboarding.file-basics.file-share.navigate-my-files',
            element:
              '.sidebar-sticky > .scrollable-area > .page_wrap:first-child .droppable > .tree-menu',
            shape: 'rectangle',
          });
        }
      },
      trigger: {
        triggerOn: 'files/set',
        onTrigger: function (payload) {
          const { path } = payload;
          const user = store.state.auth.user;

          return path === `/${user.peerid}`;
        },
      },
    },
    {
      id: 'onboarding.file-basics.file-share.hover-file',
      description: 'Hover over the file to share',
      shape: 'rectangle',
      element: 'div.file-list-item.file-list-item--file',
      trigger: {
        triggerOn: 'mouseover',
        onTrigger: function () {
          return true;
        },
      },
    },
    {
      id: 'onboarding.file-basics.file-share.share-buttom',
      description: 'Click on Share button',
      shape: 'tooltip',
      element: 'button[aria-label="Share [.]"]',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.file-basics.file-share.copy-to-clipboard',
      description: 'You can copy the file url and share with your team',
      shape: 'square',
      element: 'button.btn.btn-primary.has-tooltip',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
  ],
  onTopicCompleted() {
    store.commit('onboarding/set', {
      type: 'isExpanded',
      status: true,
    });

    confetti({
      particleCount: 100,
      zIndex: 9999999,
      origin: { x: 0.9, y: 1 },
    });
  },
};
