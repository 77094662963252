export default [
  {
    name: 'Open All Actions',
    icon: 'exclamation-square',
    action: 'manageActions',
    seperator: true,
    counter: 0,
  },
  {
    name: 'Manage',
    isTitle: true,
    hidden: ({ isLimitedUser }) => isLimitedUser,
  },
  {
    name: 'Locks',
    icon: 'lock',
    action: 'toggleLockedFilesModal',
    hidden: ({ isMobileDevice, isLockDisabled, isLimitedUser }) =>
      isMobileDevice || isLimitedUser || isLockDisabled,
  },
  {
    name: 'User Groups',
    icon: 'users',
    action: 'toggleGroupManageModal',
    hidden: ({ isGroupAdmin, isMobileDevice, isLimitedUser }) =>
      !isGroupAdmin || isMobileDevice || isLimitedUser,
  },
  {
    name: 'Secure Docs',
    icon: 'shield-check',
    action: 'showDRMAccessFilesModal',
    hidden: ({ isDRMExportHidden, isMobileDevice, isLimitedUser }) =>
      isMobileDevice || isDRMExportHidden || isLimitedUser,
    disabled: ({ isDRMExportDisabled }) => isDRMExportDisabled,
  },
  {
    name: 'Folder Permissions',
    icon: 'folder-open',
    action: 'showFolderPermissionsModal',
    seperator: true,
    hidden: ({ isMobileDevice, isLimitedUser }) =>
      isMobileDevice || isLimitedUser,
  },
  //version populated in  navbar.vue file
  {
    name: '',
    icon: '',
    action: 'version',
  },
  {
    name: 'Help & Support',
    icon: 'headset',
    action: 'openSupportPage',
    hidden: ({ isNoTechSupport }) => isNoTechSupport,
  },
  {
    name: 'Get Started',
    icon: 'info-circle',
    action: 'openTour',
    hidden: ({ isOnboardingActive }) => !isOnboardingActive,
  },
  {
    name: 'Keyboard Shortcuts',
    icon: 'keyboard',
    action: 'toggleShowShortcutsModal',
    seperator: true,
    hidden: ({ isMobileDevice }) => isMobileDevice,
  },
  {
    name: 'Switch to Admin Portal',
    icon: 'user-shield',
    action: 'switchToAdminPortal',
    hidden: ({ systemStatus }) =>
      !systemStatus.SWITCHTOADMIN || systemStatus.SWITCHTOADMIN === 0,
  },
  {
    name: 'Settings',
    icon: 'user-cog',
    action: 'showSettings',
    seperator: true,
  },
  {
    name: 'Sign Out',
    icon: 'sign-out-alt',
    action: 'logout',
  },
];
