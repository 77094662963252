var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{attrs:{"visible":true,"color":"gray","title":_vm.$t('Alert'),"position":"top","size":'xsmall',"buttons":[
    {
      label: `${_vm.$t('Share')}`,
      callback: function () {
        _vm.share();
      },
    },
    {
      label: `${_vm.$t('Cancel')}`,
      outline: true,
      callback: function () {
        _vm.closeModal();
      },
    },
  ]},on:{"close":_vm.closeModal}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to share a root folder?'))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }