import UploadTransaction from '../components/Transactions/Upload';
import FolderTransaction from '../components/Transactions/Folder';
import RenameTransaction from '../components/Transactions/Rename';
import DeleteTransaction from '../components/Transactions/Delete';
import RemoveShareTransaction from '../components/Transactions/RemoveShare';
import MoveTransaction from '../components/Transactions/Move';
import CopyTransaction from '../components/Transactions/Copy';
import DownloadMultipleTransaction from '../components/Transactions/DownloadMultiple';
import DownloadTransaction from '../components/Transactions/Download';
import DrmExportTransaction from '../components/Transactions/DrmExport';

import Alert from 'common/components/Alert';
import Icon from 'common/components/Icon';
import { getFileType, canEditFileType } from 'common/utils/files';
import { navigateToEncodedURL } from 'common/utils/URLUtils';
import responsivenessMixin from '@/mixins/responsivenessMixin';

// Virtual Scroller
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import { RecycleScroller } from 'vue-virtual-scroller';

import _ from 'lodash';
import Fuse from 'fuse.js';
import { mapGetters } from 'vuex';

export default {
  mixins: [responsivenessMixin],
  components: {
    RecycleScroller,
    Icon,
    UploadTransaction,
    RenameTransaction,
    DeleteTransaction,
    RemoveShareTransaction,
    FolderTransaction,
    MoveTransaction,
    CopyTransaction,
    DownloadMultipleTransaction,
    DownloadTransaction,
    DrmExportTransaction,
    Alert,
  },
  data() {
    return {
      filter: 'all',
      query: '',
      transactionUpload: {
        type: 'upload',
        name: 'Upload Queue',
        path: '',
        fullpath: '',
        params: {
          item: {
            id: 0,
            dirpath: '',
            format: 'dir',
            type: 'dir',
          },
        },
        entry: {},
        dirpath: '',
        targetPath: '',
        uploadpath: '',
        progress: 0,
        sentSize: 0,
        size: '',
        priority: 10,
        group: 'upload',
        status: 'preparing',
      },
      transactions: [],
      runningTransactions: { upload: [], total: [], others: [] },
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    bar() {
      return this.$store.state.files.transactionsBar;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    transactionsFromStore() {
      return this.$store.getters['files/getTransactions'];
    },
    runningTransactionsFromStore() {
      return this.$store.getters['files/getRunningTransactions'];
    },
    canEdit() {
      return this.$store.state.core.systemstatus.ENABLEWEBEDIT;
    },
    isQuickEditEnabled() {
      return this.$store.state.core.customization.enabledQuickEdit;
    },
  },
  methods: {
    switchSection(type) {
      this.$store.commit('files/setTransactionsBar', {
        key: 'currentSection',
        value: type,
      });
      this.setTransactions();
      // reset filter and forcesetTransactions
      this.filter = 'all';
    },
    toggle(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: false,
      });
      this.$store.commit('files/setTransactionsBar', {
        key: 'visible',
        value: !this.bar.visible,
      });
    },
    toggleMinification(e, openOnly) {
      e.preventDefault();
      e.stopPropagation();

      if (openOnly && this.bar.expanded) return;

      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: !this.bar.expanded,
      });
    },
    cancelAllTransactions() {
      this.runningTransactions.upload.forEach((transaction) => {
        this.$store.commit('files/setTransaction', {
          id: transaction.id,
          status: 'cancelled',
        });
      });
    },
    browse(route) {
      navigateToEncodedURL(this.$router, route, '/expl-tabl.');
    },
    preview(transaction) {
      this.$preview.open([transaction.item], 0);
    },
    isOffice(fileName) {
      const format = getFileType(fileName);
      const officeFileTypes = ['word', 'powerpoint', 'excel'];
      return officeFileTypes.indexOf(format) > -1;
    },
    isEditable(fileName) {
      const format = getFileType(fileName);
      return format === 'txt';
    },
    quickEditFile(item) {
      this.$router.push({
        name: 'webeditor',
        query: { filepath: item.path },
      });
    },
    quickEditOffice(item) {
      this.$store.dispatch('files/wopiEdit', item);
    },
    async doDeleteFile({ id, item }) {
      await this.$store.dispatch('files/deleteFile', { item });
      this.$store.commit('files/removeTransaction', id);
    },
    async shareItem(file) {
      const response = await this.$store.dispatch('share/openShare', file);
      if (response.status === false) {
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Error') +
            '</b><p role="alert">' +
            this.$t(response.message) +
            '</p>',
          type: 'warning',
        });
      }
    },
    canEditFileType,
    openMobileMenu(item) {
      let isInRecycleFolder = this.$route.fullPath.indexOf('recyclebin') > -1;
      let limitedUser = this.isLimitedUser;
      let systemStatus = this.$store.state.core.systemstatus;
      let customization = this.$store.state.core.customization;

      this.$store.commit('files/setMobileMenu', {
        key: 'open',
        value: true,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'item',
        value: item,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'component',
        value: this,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'actions',
        value: this.fileActions,
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'target',
        value: {
          item,
          isInRecycleFolder,
          isLimitedUser: limitedUser,
          isPublicShareView: false,
          systemStatus: systemStatus,
          customization,
        },
      });
      this.$store.commit('files/setMobileMenu', {
        key: 'isRoot',
        value: false,
      });
    },
    camelCase: _.camelCase,
    setTransactions() {
      // also set running transactions on clone to avoid wrong numbers
      this.runningTransactions = this.runningTransactionsFromStore;

      let transactions = _.cloneDeep(
        this.$store.getters['files/getTransactions']
      );

      Object.keys(transactions).forEach((type) => {
        transactions[`${type}Total`] = transactions[type].length;
      });

      if (!transactions[this.bar.currentSection]) return transactions;

      if (this.filter === 'processing') transactions = this.runningTransactions;

      if (this.filter === 'failed')
        transactions[this.bar.currentSection] = _.filter(
          transactions[this.bar.currentSection],
          function (item) {
            return item.status === 'failed';
          }
        );

      if (this.query !== '') {
        const fuse = new Fuse(transactions[this.bar.currentSection], {
          threshold: 0.2,
          keys: ['name', 'fullpath', 'path'],
        });

        transactions[this.bar.currentSection] = fuse
          .search(this.query)
          .map(({ item }) => item);
      }

      this.transactions = transactions;
    },
    throttledSetTransactions: _.throttle(function () {
      this.setTransactions();
    }, 1000),
    throttledSetTransactionsForQuery: _.throttle(function () {
      this.setTransactions();
    }, 300),
  },
  watch: {
    transactionsFromStore() {
      this.throttledSetTransactions();
    },
    runningTransactionsFromStore() {
      this.throttledSetTransactions();
    },
    query() {
      this.throttledSetTransactionsForQuery();
    },
    filter() {
      this.throttledSetTransactionsForQuery();
    },
  },
};
