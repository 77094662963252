var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"mini-admin-add-new-user-modal",attrs:{"stick-top":true,"visible":_vm.visible,"title":_vm.$t('Add new user'),"contain-form":true,"buttons":[
    {
      label: _vm.$t('Close'),
      outline: true,
      callback: function () {
        _vm.close();
      },
    },
    {
      label: _vm.$t('Add User'),
      callback: function () {
        _vm.inviteUser();
      },
    },
  ]},on:{"close":_vm.close,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"escape",undefined,$event.key,undefined))return null;return _vm.close.apply(null, arguments)}}},[_c('Loader',{attrs:{"loading":_vm.isLoading,"full":""}}),_c('form',[_c('div',{staticClass:"invite-user"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.errorMessage != '')?_c('InfoBox',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1),_c('InputBox',{attrs:{"id":"username","label":_vm.$t('Username'),"placeholder":_vm.$t('Type an username'),"errors":_vm.errors,"focus":""},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}}),_c('InputBox',{attrs:{"id":"email","label":_vm.$t('Email'),"placeholder":_vm.$t('Type an email'),"errors":_vm.errors},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }