<template>
  <div
    v-if="visible"
    class="dialog-modal-overlay timed-fade"
    :class="[
      { 'dialog-modal-visible': visible },
      { 'stick-top': stickTop },
      { 'dialog-modal-tabbed': tabbed },
      { 'dialog-modal-form': containForm },
      size,
      className,
    ]"
    :style="{ zIndex: zIndex && zIndex }"
    @keyup="$emit('keyup', $event)"
  >
    <div
      class="dialog-modal-outer"
      :class="{
        [`position-top`]: position == 'top',
        [`color-` + color]: color != 'primary',
        [`no-padding`]: padding === false,
      }"
    >
      <div v-if="title" class="dialog-modal-header" :class="headerClass">
        <div
          v-if="isMobile || isTablet"
          class="mobile-dialog-close"
          @click="close"
        >
          <Icon name="times" family="fal" />
        </div>
        <h1 tabindex="0" class="text-truncate mr-4">
          {{ title }}
        </h1>
        <a
          v-if="!isMobile && !isTablet"
          v-shortkey.once="['esc']"
          tabindex="0"
          class="dialog-close"
          @click="close()"
          @shortkey="close()"
          @keydown.enter="close()"
        >
          <Icon family="fas" name="times-circle" />
        </a>
      </div>
      <div
        v-if="backButton && !isMobile && !isTablet"
        class="dialog-modal-back"
        @click="backButtonCallback"
      >
        <Icon name="arrow-left" />
        <span>Back</span>
      </div>
      <div :class="bodyClassName === '' ? 'dialog-modal-body' : bodyClassName">
        <slot></slot>
      </div>
      <div
        v-if="buttons"
        class="dialog-modal-footer"
        :class="[
          {
            'd-flex justify-content-end align-items-center':
              !isMobile && !isTablet,
          },
          footerClass,
        ]"
      >
        <div class="dialog-modal-hint" :class="{ 'mr-auto': !hasButtonsLeft }">
          <slot name="left-button-hint"></slot>
        </div>
        <v-popover
          v-if="btnWithConfirmLeft"
          class="btn-left"
          :open="showBtnWithConfirmLeft"
          @hide="showBtnWithConfirmLeft = false"
        >
          <a
            v-if="btnWithConfirmLeft.link"
            class="btn btn-link"
            :class="{ 'btn-link-danger': btnWithConfirmLeft.danger }"
            tabindex="0"
            @click="showBtnWithConfirmLeft = !showBtnWithConfirmLeft"
            @keydown.space.prevent="
              showBtnWithConfirmLeft = !showBtnWithConfirmLeft
            "
          >
            {{ btnWithConfirmLeft.label }}
          </a>
          <button v-else class="btn wide btn-left btn-outline-primary">
            {{ btnWithConfirmLeft.label }}
          </button>
          <Alert
            slot="popover"
            :visible="showBtnWithConfirmLeft"
            button-type="danger"
            :buttons="[
              {
                label: btnWithConfirmLeft.confirmBtn1,
                callback: btnWithConfirmLeft.callback,
              },
              {
                label: btnWithConfirmLeft.confirmBtn2,
                outline: true,
                callback: function () {},
              },
            ]"
            >{{ btnWithConfirmLeft.confirmLabel }}?</Alert
          >
        </v-popover>
        <button
          v-for="(button, index) of buttonsPrimary"
          v-if="!button.hidden"
          :key="index"
          class="btn wide"
          :disabled="button.disabled"
          :class="{
            [`btn-outline-primary`]: button.outline,
            [`btn-danger`]: button.danger,
            [`btn-primary`]:
              button.outline != true &&
              button.link != true &&
              button.danger != true,
            [`btn-left`]: button.left,
            [`btn-link`]: button.link,
            [`btn-focus-${index}`]: true,
          }"
          @click="callback($event, button.callback ? button.callback : null)"
        >
          <Icon v-if="button.icon" family="fas" :name="button.icon" />
          {{ button.label }}
        </button>
        <v-popover
          v-if="btnWithConfirmRight"
          :open="!disableAlert"
          :disabled="disableAlert"
        >
          <button
            class="btn wide"
            :class="{
              [`btn-primary`]:
                btnWithConfirmRight.outline != true &&
                btnWithConfirmRight.link != true,
              [`btn-outline-primary`]: btnWithConfirmRight.outline,
              [`btn-link`]: btnWithConfirmRight.link,
            }"
            @click="disableAlert = false"
          >
            {{ btnWithConfirmRight.label }}
          </button>
          <Alert
            slot="popover"
            :visible="!disableAlert"
            :buttons="[
              {
                label: btnWithConfirmRight.confirmBtn1,
                callback: () => {
                  disableAlert = true;
                  this.$emit('userFeedback', 'save');
                },
              },
              {
                label: btnWithConfirmRight.confirmBtn2,
                outline: true,
                callback: () => {
                  disableAlert = true;
                  this.$emit('userFeedback', 'close');
                },
              },
            ]"
            >{{ btnWithConfirmRight.confirmLabel }}</Alert
          >
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Icon from './Icon';
import Alert from 'common/components/Alert';
import { isMobileDevice, isTabletDevice } from '../utils/responsive.js';
import { createFocusTrap } from 'focus-trap';

export default {
  components: {
    Icon,
    Alert,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    color: {
      default: 'primary',
      type: String,
    },
    position: {
      default: 'middle',
      type: String,
    },
    buttons: {
      default: () => [],
      type: Array,
    },
    visible: {
      default: false,
      type: Boolean,
    },
    padding: {
      default: true,
      type: Boolean,
    },
    stickTop: {
      default: true,
      type: Boolean,
    },
    size: {
      default: 'regular',
      type: String,
    },
    className: {
      default: '',
      type: String,
    },
    footerClass: {
      default: '',
      type: String,
    },
    bodyClassName: {
      default: '',
      type: String,
    },
    backButton: {
      default: false,
      type: Boolean,
      required: false,
    },
    backButtonCallback: {
      default: function () {},
      type: Function,
      required: false,
    },
    tabbed: {
      default: false,
      type: Boolean,
      required: false,
    },
    containForm: {
      default: false,
      type: Boolean,
      required: false,
    },
    checkFunction: {
      default: function () {},
      type: Function,
    },
    headerClass: {
      default: '',
      type: String,
      required: false,
    },
    disableTabLock: {
      default: false,
      type: Boolean,
      required: false,
    },
    initialFocusElement: {
      default: undefined,
      type: String || Element,
      required: false,
    },
    zIndex: {
      default: null,
      type: String,
      required: false,
    },
  },
  data() {
    return {
      focusTrap: null,
      disableAlert: true,
      showBtnWithConfirmLeft: false,
      isMobile: false,
      isTablet: false,
    };
  },
  computed: {
    buttonsPrimary() {
      return this.buttons.filter((btn) => !btn.hasConfirm);
    },
    btnWithConfirmLeft() {
      return this.buttons.filter((btn) => btn.hasConfirm && btn.left)[0];
    },
    btnWithConfirmRight() {
      return this.buttons.filter((btn) => btn.hasConfirm && btn.right)[0];
    },
    cannotInitFocus() {
      return this.disableTabLock || this.isMobile || this.isTablet;
    },
    hasButtonsLeft() {
      return this.buttons.some((btn) => btn.left);
    },
    isMobileDevice,
    isTabletDevice,
  },
  watch: {
    visible: function (isVisible) {
      document.body.style.overflowY = isVisible ? 'hidden' : 'auto';
      if (
        isVisible &&
        !this.disableTabLock &&
        !this.isMobile &&
        !this.isTablet
      ) {
        this.startFocusTrap();
      } else {
        this.stopFocusTrap();
      }
    },
  },
  mounted() {
    document.body.insertBefore(this.$el, document.body.firstChild);
    if (this.visible) {
      this.startFocusTrap();
      document.body.style.overflowY = 'hidden';
    }
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  destroyed() {
    this.stopFocusTrap();
    //remove if still present after destroyed
    if (document.body.contains(this.$el)) {
      document.body.removeChild(this.$el);
    }
    document.body.style.overflowY = 'auto';
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.isMobile = this.isMobileDevice || window.innerWidth <= 767;
      this.isTablet =
        this.isTabletDevice ||
        (window.innerWidth < 1200 && window.innerWidth > 767);
    },
    backBtnCallback: function (callback) {
      callback();
    },
    callback: function (event, buttonCallback) {
      event.preventDefault();
      if (buttonCallback) {
        buttonCallback();
      }
    },
    close: function () {
      this.disableAlert = true;
      document.getElementsByTagName('body')[0].style.overflowY = 'auto';
      this.$emit('close', true);
    },
    startFocusTrap: function () {
      if (this.cannotInitFocus) return;
      // wait for next DOM update cycle to avoid empty $el
      Vue.nextTick(() => {
        if (!this.focusTrap) {
          // Create a new trap if not present
          const defaultInitialFocus = this.buttonsPrimary.length
            ? '.dialog-modal-header h1'
            : undefined;

          const trapConfig = {
            allowOutsideClick: true,
            escapeDeactivates: false,
            initialFocus: this.initialFocusElement
              ? this.initialFocusElement
              : defaultInitialFocus,
          };
          this.focusTrap = createFocusTrap(this.$el, trapConfig);
        } else {
          // Reuse the focustrap if it is available
          this.focusTrap.updateContainerElements(this.$el);
        }
        this.focusTrap.activate();
      });
    },
    stopFocusTrap: function () {
      if (this.focusTrap) {
        this.focusTrap.deactivate();
      }
    },
    pauseFocusTrap: function () {
      this.focusTrap.pause();
    },
    resumeFocusTrap: function () {
      this.focusTrap.unpause();
    },
  },
};
</script>
