export default [
  {
    name: 'Upload File',
    icon: 'upload',
    emit: 'upload-file',
    disabled: () => false,
    hidden: () => false,
  },
  {
    name: 'Upload Folder',
    icon: 'folder-open',
    emit: 'upload-folder',
    disabled: () => false,
    hidden: () => false,
  },
  {
    name: 'New Folder',
    icon: 'folder-plus',
    emit: 'toggle-new-folder-input',
    disabled: () => false,
    hidden: () => false,
  },
];
