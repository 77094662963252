<template>
  <li class="transactions-list-item">
    <div class="transaction-icon">
      <Icon family="fas" name="long-arrow-right" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon
          v-if="transaction.status === 'failed'"
          family="fas"
          name="times"
        />
        <Icon
          v-else-if="transaction.status === 'cancelled'"
          family="fas"
          name="ban"
        />
        <Icon v-else family="fas" name="check" />
      </div>
    </div>
    <div class="transaction-info">
      <span class="transaction-info-title" v-tooltip="{ content: tooltipContent }">
        {{ currentPath !== transaction.dirpath ? `${transaction.dirpath}/` : ''
        }}{{ transaction.name }}
        <small v-if="transaction.status === 'processing'" role="status">{{
          transaction.undo
            ? $t('Undoing Move')
            : $t('Moving to') + ' ' + transaction.to
        }}</small>
        <small v-else></small>
      </span>
      <span
        v-if="transaction.status === 'preparing'"
        class="description"
        role="status"
      >
        {{ $t('Preparing to move') }}
      </span>
      <div v-else-if="transaction.status === 'processing'" class="progress">
        <div
          class="progress-bar w-10 indeterminate"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Moving files...')"
        ></div>
      </div>
      <span v-else-if="transaction.message" class="description text-truncate" role="status">{{
        transaction.message
      }}</span>
      <span
        v-else-if="transaction.status === 'cancelled'"
        class="description"
        role="alert"
      >
        {{ $t('You have cancelled this operation') }}
      </span>
      <span v-else class="description text-truncate" role="alert">
        {{ $t('Successfully moved to') + ' ' + transaction.to }}
      </span>
    </div>
    <div v-if="transaction.status === 'completed'" class="transaction-actions">
      <slot></slot>
    </div>
    <div v-else class="transaction-actions">
      <button
        v-if="
          transaction.status !== 'cancelled' &&
          transaction.status !== 'failed' &&
          transaction.status !== 'completed'
        "
        class="btn btn-icon"
        :aria-label="$t('Cancel moving files')"
        @click="cancel"
      >
        <Icon family="fas" name="ban" />
        <Icon class="icon-overlay" family="fas" name="ban" />
      </button>

      <button
        v-if="transaction.status === 'failed'"
        class="btn btn-sm btn-primary"
        :aria-label="$t('Overwrite files files')"
        @click="overwrite"
      >
        {{ $t('Overwrite') }}
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  methods: {
    cancel() {
      this.$store.commit('files/setTransaction', {
        id: this.transaction.id,
        status: 'cancelled',
      });
    },

    overwrite() {
      this.$store.dispatch('files/moveFile', {
        ...this.transaction.params,
        transactionId: this.transaction.id,
        overwrite: 1,
      });
    },
  },
  computed: {
    tooltipContent() {
      if (this.currentPath !== this.transaction.dirpath) {
        return `${this.transaction.dirpath}/`;
      } else {
        return `${this.transaction.name}`;
      }
    },
  },
};
</script>
