var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{staticClass:"dialog-modal-table animate-timed-fade locked-files-wrapper",attrs:{"stick-top":true,"visible":true,"title":_vm.$t(`Locked Files and Folders`),"buttons":[
    {
      label: 'OK',
      outline: true,
      callback: () => _vm.onClose(),
    },
  ]},on:{"close":function($event){return _vm.onClose()}}},[_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}),_c('div',[_c('div',{staticClass:"table-wrapper-border"},[_c('table',{staticClass:"table table--properties"},[_c('caption',[_vm._v(" "+_vm._s(_vm.$t('Locked Files and Folders'))+" ")]),_c('thead',[_c('th',{attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(_vm.$t('Path'))+" "),_c('span',{staticClass:"filter"},[_c('InputBox',{staticClass:"filter-items ml-3 mb-0",attrs:{"id":'filter-files',"placeholder":_vm.$t('Filter Items'),"prepends":[{ icon: 'filter' }],"form-size":"sm"},model:{value:(_vm.filterValue),callback:function ($$v) {_vm.filterValue=$$v},expression:"filterValue"}})],1)]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Read Permitted')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Expiration')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Actions')))])]),_c('tbody',[(!_vm.loading && _vm.paginatedLockedFiles.length === 0)?_c('tr',{staticClass:"border-0"},[_c('td',{staticClass:"centered p-5",attrs:{"colspan":"4"}},[_c('EmptyListingInfo',{attrs:{"icon-name":"lock","text":"No Locked Files"}})],1)]):_vm._e(),_vm._l((_vm.paginatedLockedFiles),function(file){return _c('LockedFileItem',{key:file.lockrid,attrs:{"file":file}})})],2)])]),(_vm.totalItems / _vm.itemsPerPage > 1)?_c('Paginator',{attrs:{"current-page":_vm.currentPage,"items-per-page":_vm.itemsPerPage,"total-items":_vm.totalItems},on:{"onPageChange":_vm.onPageChage}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }