<template>
  <div class="security-tab w-100">
    <Loader v-if="loading" :loading="loading" full />
    <div v-else>
      <Header :path="selectedPath" />
      <div class="col-md-12 p-0 m-0 row pt-3 permissions-text">
        <p>
          {{ $t('Manage folder level security for the above folder.') }}
        </p>
        <p>
          {{
            $t(
              'Note that folder level security settings are applicable only when folder is shared.'
            )
          }}
        </p>
      </div>
      <div v-if="showAclMeta" class="list-group mt-0 pt-0">
        <ul v-if="metaReady" class="acl-meta pt-4">
          <li class="list-group-item-acl security-item">
            {{ $t('Inherit permissions') + ' ' }}
            <span class="float-right">{{
              metaRecord.inherit === 1 ? $t('Yes') : $t('No')
            }}</span>
          </li>
          <li class="list-group-item-acl security-item">
            {{ $t('User permissions granted') + ' ' }}
            <span class="float-right">{{ metaRecord.usercount }}</span>
          </li>
          <li class="list-group-item-acl security-item">
            {{ $t('Group permissions granted') + ' ' }}
            <span class="float-right">{{ metaRecord.groupcount }}</span>
          </li>
        </ul>
      </div>
      <div class="col-md-12 mt-3 button-cta">
        <button
          class="btn btn-primary btn-sm small-font wide center pl-4 pr-4"
          @click="openSecuritySettings"
        >
          {{ $t('Manage Security') }}
        </button>
      </div>
      <FolderSecurity
        ref="folderSecurityModal"
        :secured-directory-path="selectedPath"
        @close="loadAclMeta"
      />
    </div>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import FolderSecurity from '@/components/Security/FolderSecurity';
import Header from './Header';

export default {
  components: {
    FolderSecurity,
    Loader,
    Header,
  },
  props: {},
  data() {
    return {
      aclMeta: {},
      showAclMeta: false,
      loading: true,
      metaReady: false,
    };
  },
  computed: {
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    metaRecord() {
      const aclMetaRecord = this.aclMeta.meta;
      if (typeof this.aclMeta.meta === 'undefined') {
        return {
          inherit: 1,
          usercount: 'N/A',
          groupcount: 'N/A',
        };
      }
      return aclMetaRecord;
    },
    selectedPath() {
      return this.currentFile.path;
    },
  },
  mounted() {
    this.loadAclMeta();
  },
  methods: {
    openSecuritySettings() {
      this.$refs.folderSecurityModal.open();
    },
    async loadAclMeta() {
      this.metaReady = false;
      this.loading = true;
      let response = await this.$store.dispatch('files/getAclForPath', {
        path: this.selectedPath,
        filter: 'metaonly',
      });

      if (response.ok) {
        const aclMetaRecord = response.data;
        this.aclMeta = aclMetaRecord;
        this.showAclMeta = true;
      }

      this.loading = false;
      this.metaReady = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.file-title {
  height: 17px;
  font-weight: 400;
  font-size: 14px;
  padding-bottom: 2rem;
  margin-right: 1.5rem;
}
.selected-path {
  font-weight: 600;
}
.acl-meta {
  width: 100%;
  border-radius: 5px;
  padding: 50px 15px 15px 0;
}
.security-item {
  font-size: 13px;
  font-weight: 500;
}
.small-font {
  font-size: 13px;
  font-weight: 400;
}
.list-group-item-acl {
  font-size: 0.8rem;
  font-weight: 600;
  list-style-type: none;
  border-bottom: 1px solid var(--border-color);
  padding-top: 8px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
  color: var(--text-mid-dark);
}
.button-cta {
  text-align: center;
}
.permissions-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--text-medium);
}
.light-blue {
  color: rgb(0, 122, 204);
}
</style>
