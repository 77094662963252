<template>
  <div>
    <div v-for="action in actions" :key="action.name">
      <div
        v-if="!action.hidden(params)"
        v-show="!action.disabled(params)"
        class="menu-item"
        @click="emitAction(action.emit, params)"
      >
        <Icon :name="action.icon" family="fal" />
        <span class="title">{{ $t(action.name) }}</span>
      </div>
    </div>
    <!-- region Delete Confirmation Dialog -->
    <DialogModal
      v-if="confirmationDialogVisible"
      class="dialog-modal-overlay dialog-modal-form timed-fade stick-top"
      size="small"
      z-index="1000000"
      :buttons="actionButtons"
      :class="[{ 'dialog-modal-visible': confirmationDialogVisible }]"
      :title="$t('Delete')"
      :visible="confirmationDialogVisible"
      @close="confirmationDialogVisible = false"
      @keyup="$emit('keyup', $event)"
    >
      {{ $t('file_list.item_action.delete_headline') }} <br />
      {{ $t('file_list.item_action.delete_description') }}
    </DialogModal>
    <!-- endregion -->
  </div>
</template>

<script>
import Icon from '../Icon';
import DialogModal from '../DialogModal';

export default {
  name: 'ZipViewerMobileMenuActionList',
  components: { Icon, DialogModal },
  props: {
    actions: {
      type: Array,
      required: true,
    },
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      confirmationDialogVisible: false,
    };
  },
  computed: {
    actionButtons() {
      return [
        {
          label: this.$t('delete'),
          callback: () => {
            this.confirmationDialogVisible = false;
            this.$emit('trigger-action', 'delete-item', this.params);
            this.$emit('close');
          },
          focus: true,
          danger: true,
        },
        {
          label: this.$t('cancel'),
          callback: () => {
            this.confirmationDialogVisible = false;
            this.$emit('close');
          },
          outline: true,
        },
      ];
    },
  },
  methods: {
    emitAction(action, params) {
      if (action === 'delete-item') {
        this.confirmationDialogVisible = true;
      } else {
        this.$emit('trigger-action', action, params);
        this.$emit('close');
      }
    },
  },
};
</script>
