<template>
  <select
    :value="value"
    class="form-control round-btn mb-2"
    @input="save($event.target.value)"
  >
    <option
      v-for="option in labels.matchTypes"
      :key="option.label"
      :value="option.id"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'LogicalOperator',
  props: ['value', 'labels'],
  methods: {
    save(value) {
      this.$emit('input', value);
      Vue.nextTick(() => {
        this.$emit('change');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin-left: 10px;
}
</style>
