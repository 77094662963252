let schemas = {};

// login
schemas['auth/login'] = {
  userid: {
    type: 'string',
    empty: false,
  },
  password: {
    type: 'string',
    empty: false,
  },
};

schemas['core/loginguest'] = {
  userid: {
    type: 'string',
    empty: false,
    optional: true,
  },
  password: {
    type: 'string',
    empty: false,
    optional: true,
  },
};

// Reset password
schemas['core/verifyresetpassword'] = {
  password: {
    type: 'string',
    empty: false,
  },
  cpassword: {
    type: 'string',
    empty: false,
  },
};

// force reset password
schemas['core/updatepassword'] = {
  oldpassword: {
    type: 'string',
    empty: false,
  },
  cpassword: {
    type: 'string',
    empty: false,
  },
  newpassword: {
    type: 'string',
    empty: false,
  },
};

// login
schemas['core/2falogin'] = {
  userid: {
    type: 'string',
    empty: false,
  },
  code: {
    type: 'string',
    empty: false,
  },
  token: {
    type: 'string',
    empty: false,
  },
};

schemas['core/set2fasmsnumber'] = {
  userid: {
    type: 'string',
    empty: false,
  },
  token: {
    type: 'string',
    empty: false,
  },
  phonenumber: {
    type: 'string',
    empty: false,
  },
};

// new account
schemas['core/createprofile'] = {
  email: {
    type: 'email',
    empty: false,
  },
  agreed: {
    type: 'equal',
    value: true,
  },
  profile: {
    type: 'string',
    empty: false,
  },
  password: {
    type: 'string',
    empty: false,
  },
  savepassword: {
    type: 'number',
  },
  savesecretans: {
    type: 'number',
  },
  token: {
    type: 'string',
    empty: true,
  },
};

schemas['core/updatephonenumber'] = {
  phonenumber: {
    min: 5,
    max: 18,
    type: 'string',
    field: 'phone',
  },
};

schemas['app/websharepro/sendsharetoemail'] = {
  toemailid: {
    type: 'string',
    empty: false,
  },
  message: {
    type: 'string',
    empty: false,
  },
};

schemas['core/geteffectiveacl'] = {
  emailid: {
    type: 'email',
    empty: false,
  },
};

schemas['miniadmin/miniinvite'] = {
  email: {
    type: 'email',
    empty: false,
  },
  username: {
    type: 'string',
    empty: false,
  },
};

schemas['miniadmin/setuserpassword'] = {
  password: {
    type: 'string',
    empty: false,
  },
  passwordconfirm: {
    type: 'string',
    empty: false,
  },
};

export default schemas;
