import store from '../../../store/index.js';
import router from '@/router.js';

export default {
  title: 'Explore the Mini Admin',
  id: 'onboarding.miniadmin.general',
  steps: [
    {
      id: 'onboarding.miniadmin.open-miniadmin',
      description: 'Click here to open the Mini Admin',
      shape: 'rectangle',
      element:
        '.sidebar .mini-admin-link',
      onStepStart: () => {
        store.commit('onboarding/set', {
          type: 'isExpanded',
          value: false,
        });
      },
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.miniadmin.license',
      description: 'You can check your current license info from here (click over to go next)',
      shape: 'rectangle',
      element:
        '.left-tabbed-modal #license-section',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.miniadmin.storage',
      description: 'As well as your storage details (click over to go next)',
      shape: 'rectangle',
      element:
        '.left-tabbed-modal #storage-section',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      },
    },
    {
      id: 'onboarding.miniadmin.menu',
      description: 'You can also navigate to other sections, go and explore!',
      shape: 'rectangle',
      element:
        '.left-tabbed-modal #miniadmin-navigation',
      trigger: {
        triggerOn: 'click',
        onTrigger: () => true,
      }
    }
  ],
};
