<template>
  <div class="file-preview-item file-preview-item--zip-pdf">
    <Loader v-if="isLoading || isPrinting" />
    <div v-show="!isPrinting || !isLoading">
      <vue-pdf-app :pdf.sync="blob" :style="width.frame" :config="config">
      </vue-pdf-app>
    </div>

    <portal to="nested-file-list-header--pdf">
      <NavbarIcon
        icon="print"
        :tooltip="$t('zip_preview.nested_preview.header.print')"
        :disabled="!canDownload || isLoading || isPrinting"
        @click="onPrint"
      />
    </portal>
  </div>
</template>

<script>
import Loader from 'common/assets/loader.svg';
import NavbarIcon from '../NavbarIcon.vue';

import printJS from 'print-js';
import { mapActions } from 'vuex';

export default {
  components: {
    Loader,
    NavbarIcon,
    'vue-pdf-app': () => ({
      component: new Promise((res) => {
        return setTimeout(
          () => res(import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app')),
          1000
        );
      }),
    }),
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    pdfExpanded: {
      type: Boolean,
      default: false,
    },
    zoom: {
      type: Number,
      default: 0,
    },
    navPanel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Object,
      default: () => ({
        frame: { height: '100%', width: '100vw' },
      }),
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isPrinting: false,
      blob: null,
      loadingTask: null,
      url: '',
      config: {
        toolbar: {
          toolbarViewerLeft: this.navPanel,
          toolbarViewerRight: { print: this.canDownload }, // hide print if no download option
        },
      },
    };
  },
  watch: {
    file: function () {
      this.loadBlob();
    },
  },
  mounted() {
    this.loadBlob();
  },
  methods: {
    ...mapActions('zipViewer', {
      getBlob: 'blob',
    }),
    async loadBlob() {
      this.isLoading = true;
      const request = await this.getBlob(this.file);
      if (request.ok) {
        this.blob = URL.createObjectURL(request.data);
      } else {
        this.$emit('fallback', {
          view: 'DefaultView',
          error:
            request.error ||
            this.$t('zip_preview.nested_preview.errors.unexpected_error'),
        });
      }
      this.isLoading = false;
    },
    async onPrint() {
      this.isPrinting = true;
      printJS({
        printable: this.url,
        type: 'pdf',
        onLoadingEnd: () => {
          this.isPrinting = false;
        },
      });
    },
  },
};
</script>
