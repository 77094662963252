<template>
  <div class="activity d-flex justify-content-between">
    <div class="d-flex">
      <Avatar :src="avatarUrl" :size="`xs`" />
      <div class="details">
        <div class="user-name">
          {{ record.who }}
        </div>
        <div class="user-activity">
          <Action :activity="record" />
          <strong> {{ record.name }}</strong> {{ $t('from') }} {{ record.ip }}
          <br />
          {{ record.path }} {{ emailTo }}
        </div>
        <div class="user-timestamp">
          {{ record.when }}, {{ $t('via') }} {{ $t(record.how) }}
        </div>
      </div>
    </div>

    <v-popover class="activity-item-details">
      <div class="btn" aria-hidden="true" @click="loadMap()">
        <Icon name="info-circle" family="fas" class="tooltip-target" />
      </div>
      <template slot="popover" class="p-3">
        <MapView
          ref="mapComponent"
          :activity="activity"
          :action-code="actions"
          :relative-date="record.when"
        />
      </template>
    </v-popover>
  </div>
</template>

<script>
import Avatar from 'common/components/Avatar';
import Icon from 'common/components/Icon';
import serverURL from 'common/filecloud/properties/serverURL';
import Action from '../Partials/ShareActions';
import MapView from '../FileSidebar/MapView';

import actions from '../../constants/shareHistoryActions';

export default {
  components: {
    Avatar,
    Action,
    MapView,
    Icon,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      actions,
    };
  },
  computed: {
    avatarUrl() {
      return `${serverURL.domainURL}/core/getprofileimage`;
    },
    emailTo() {
      return this.record.actioncode === 14
        ? `${this.$t('to')} ${this.record.shareemailto}`
        : '';
    },
    activity() {
      return {
        ...this.record,
        who: this.record.who,
        how: this.record.how,
        ip: this.record.ip,
        whents: this.record.when,
        checksum: this.record.checksum,
      };
    },
  },
  methods: {
    loadMap() {
      this.$refs.mapComponent.drawMap();
    },
  },
};
</script>
