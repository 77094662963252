<template>
  <ol class="breadcrumb">
    <li class="breadcrumb-item">
      <a href="#">
        <div class="breadcrumb-text" @click="$emit('browse', '')">
          {{ root }}
        </div>
      </a>
    </li>
    <template v-if="path">
      <li
        v-for="item in splitPath(path)"
        :key="item.fullPath"
        class="breadcrumb-item active"
      >
        <Icon
          name="fas fa-chevron-right"
          family="fas"
          class="breadcrumb-seperator"
        />
        <a href="#">
          <div class="breadcrumb-text" @click="$emit('browse', item.fullPath)">
            {{ item.path }}
          </div>
        </a>
      </li>
    </template>
  </ol>
</template>

<script>
import ZipViewerMixin from '../zipViewerMixin';
import Icon from '../../Icon';
export default {
  name: 'ZipViewerBreadcrumb',
  components: {
    Icon,
  },
  mixins: [ZipViewerMixin],
  props: {
    root: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
  },
};
</script>
