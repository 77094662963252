<template>
  <!-- adding stars-->
  <div
    class="col-pre-icons d-none d-xl-flex"
    :class="{
      'pre-icons-search': hasSearchResults,
    }"
  >
    <Drag
      v-if="!isPicker"
      :effect-allowed="['move']"
      :transfer-data="selection.length > 0 ? selection : item"
      class="pre-icon drag-indication"
      @contextmenu.prevent="contextMenu"
      @dragstart="onDragStart"
      @dragend="$emit('onDragEnd')"
    >
      <Icon name="grip-vertical" family="fas" />
      <template slot="image">
        <div class="file-list-item-transfer">
          <span v-if="selection.length < 2"
            >{{ $t('Move') }}
            <strong class="item-name">{{ item.name }}</strong></span
          >
          <span v-else>
            {{ $t('Move') }} <strong>{{ selection.length }}</strong>
            {{ $t('files') }}
          </span>
        </div>
      </template>
    </Drag>
    <span v-if="item.isStarring" class="pre-icon spinner-border" />
    <div
      v-else-if="showStar"
      v-a11ybutton="() => $emit('toggleStarred', item)"
      class="pre-icon starred-indication"
      :class="{ starred: typeof item.favoritelistid === 'string' }"
      @contextmenu.prevent="contextMenu"
      @click="$emit('toggleStarred', item)"
    >
      <Icon name="star" :family="starType" />
    </div>
  </div>
  <!-- /adding stars-->
</template>
<script>
import Icon from 'common/components/Icon';
import { Drag } from 'vue-drag-drop';
import { mapGetters } from 'vuex';

export default {
  components: {
    Drag,
    Icon,
  },
  mixins: [],
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isPicker: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isPublicShare: {
      type: Boolean,
      default: false,
    },
    hasSearchResults: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    showStar() {
      return (
        !this.isPicker &&
        this.item.canfavorite &&
        !this.isPublicShare &&
        !this.isLimitedUser
      );
    },
    starType() {
      return typeof this.item.favoritelistid === 'string' ? 'fas' : 'fal';
    },
  },
  methods: {
    contextMenu(e) {
      this.hasFocus = true;
      this.$emit('context-menu', { e, entry: this });
    },
    onDragStart() {
      this.$store.dispatch('files/setDraggingFile', true);
    },
  },
};
</script>
