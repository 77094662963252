<template>
  <DialogModal
    class="animate-timed-fade logout-modal"
    :stick-top="true"
    :visible="isOpen"
    :padding="false"
    size="xsmall"
    :title="$t('logout.modal.title')"
    :buttons="[
      {
        label: $t('logout.modal.confirm_button'),
        callback: () => logout(),
      },
      {
        label: $t('logout.modal.cancel_button'),
        outline: true,
        callback: () => onClose(),
      },
    ]"
    @close="onClose()"
  >
    <Loader v-if="isLoading" full loading />

    <h6>
      {{ $t('logout.modal.description') }}
    </h6>
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal.vue';
import Loader from 'common/components/Loader.vue';

export default {
  name: 'LogoutModal',
  components: { DialogModal, Loader },
  computed: {
    isOpen() {
      return this.$store.state.auth.showLogoutModal;
    },
    isLoading() {
      return this.$store.state.loading['auth/logout'];
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.onClose();
    },
    onClose() {
      this.$store.dispatch('auth/setShowLogoutModal', false);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.logout-modal {
  h6 {
    margin-bottom: 0;
    padding: 36px 50px;
  }
}
</style>
