<template>
  <DialogModal
    :stick-top="true"
    :visible="isOpen"
    :title="$t(`Send File for Approval`)"
    :class-name="'send-for-approval small'"
    :buttons="[
      {
        label: 'Cancel',
        outline: true,
        callback: () => {
          closeModal();
        },
      },
      {
        label: this.$t('OK'),
        right: true,
        hasConfirm: false,
        disabled: canSubmit,
        callback: () => onSave(),
      },
    ]"
    @close="closeModal"
  >
    <div
      class="mb-1 form-group"
      :class="{ 'is-invalid': fieldErrors['email_or_group'] }"
    >
      <label class="form-label mb-0">
        {{ $t('workflow.node_params.email_approvals_title') }}
        <span class="text-danger">*</span>
      </label>
      <small class="d-block mb-2">{{
        $t('workflow.node_params.email_approvals_description')
      }}</small>
      <div class="p-4 border rounded">
        <label class="form-label pt-0 mb-0">
          <Icon family="fas" name="user" />
          {{ $t('workflow.node_params.email_approvals_users') }}
        </label>
        <small class="d-block mb-2">{{
          $t('workflow.node_params.email_approvals_user_description')
        }}</small>
        <FcUsersParam
          id="userSearch"
          ref="userAutocomplete"
          v-model="query"
          :users="filteredUsers"
          @addEmail="getInvites($event)"
        />
        <ApproversList :value="usersToSend">
          <template v-slot:default="{ data, index }">
            <span>{{ data.displayname || data.username }}</span>
            <button
              v-tooltip="$t('Remove')"
              :aria-label="$t('Remove')"
              class="fas fa-times-circle"
              @click.prevent="removeUser(index)"
            />
          </template>
        </ApproversList>

        <label class="form-label mb-0 pt-4">
          <Icon family="fas" name="users" />
          {{ $t('workflow.node_params.email_approvals_groups') }}
        </label>
        <small class="d-block mb-2">{{
          $t('workflow.node_params.email_approvals_groups_description')
        }}</small>
        <FcGroupsParam
          id="userSearch"
          v-model="groupsQuery"
          :groups="filteredGroups"
          @addGroup="addGroup($event)"
        />
        <ApproversList :value="groupsToSend">
          <template v-slot:default="{ data, index }">
            <span>{{ data.groupname }}</span>
            <button
              v-tooltip="$t('Remove')"
              :aria-label="$t('Remove')"
              class="fas fa-times-circle"
              @click.prevent="removeGroup(index)"
            />
          </template>
        </ApproversList>
      </div>
    </div>

    <div class="mb-1 form-group mt-3">
      <label class="form-label mb-1">
        {{ $t('Message') }}
        <span class="text-danger">*</span>
      </label>
      <textarea
        v-model="message"
        class="col-md-12 mb-3 form-control comment-input"
        rows="4"
      />
    </div>

    <div class="mb-1 form-group mt-3">
      <label class="form-label mb-1">
        {{ $t('Attachments') }}
      </label>
      <br />
      <div v-if="item" class="files" @click="previewFile">
        <FileIcon :name="item.path" class="fas file-notif-icon" />
        <div class="file-data">
          <span class="name">{{ item.name }}</span>
          <span class="size">{{ item.size }}</span>
        </div>
      </div>
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import Avatar from 'common/components/Avatar';
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import responsivenessMixin from '@/mixins/responsivenessMixin';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import FcUsersParam from 'common/components/Workflow/ParamType/FcUsers';
import FcGroupsParam from 'common/components/Workflow/ParamType/FcGroups';
import ApproversList from 'common/components/Workflow/ApproversList';

import { debounce } from 'underscore';
import { isValidEmail } from 'common/utils/forminput';
import { mapState } from 'vuex';

export default {
  components: {
    DialogModal,
    Avatar,
    Icon,
    FileIcon,
    FcUsersParam,
    FcGroupsParam,
    ApproversList,
  },
  mixins: [responsivenessMixin],
  data() {
    return {
      isOpen: false,
      item: null,
      query: '',
      groupsQuery: '',
      defautlMessage: this.$t('Please, approve this file.'),
      message: this.$t('Please, approve this file.'),
      fieldErrors: {},
      selectedUser: {},
      usersToSend: [],
      groupsToSend: [],
      users: [],
      groups: [],
    };
  },
  computed: {
    ...mapState('core', ['systemstatus']),
    filteredUsers() {
      return this.users.filter(
        (user) =>
          !this.usersToSend.some(
            (userToSend) => userToSend.username === user.username
          )
      );
    },
    filteredGroups() {
      return this.groups.filter(
        (group) =>
          !this.groupsToSend.some(
            (groupToSend) => groupToSend.groupname === group.groupname
          ) && group.membercount > 0
      );
    },
    canSubmit() {
      return false;
      // return this.usersToSend.length || this.groupsToSend.length;
    },
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - debounce the search to avoid hitting the API limits
    query: debounce(function (newQuery) {
      if (newQuery) {
        this.searchUsers(newQuery);
      }
    }, 150),
    groupsQuery: debounce(function (newQuery) {
      if (newQuery) {
        this.searchGroups(newQuery);
      }
    }, 150),
  },
  methods: {
    async searchGroups(query) {
      const response = await this.$store.dispatch('share/searchGroups', {
        filter: query,
      });

      let groups = response.data.group;
      if (groups && groups.length == undefined) {
        groups = [groups];
      }

      this.groups = !groups ? [] : groups;
    },
    async searchUsers(newQuery) {
      if (
        (this.systemstatus.EXACT_EMAIL_SEARCH_IMPLICIT_INVITE ||
          this.systemstatus.EXACT_EMAIL_SEARCH_EXPLICIT_INVITE) &&
        !isValidEmail(newQuery)
      ) {
        return;
      } else {
        const response = await this.$store.dispatch('share/searchUsers', {
          filter: newQuery,
        });

        let users = response.data.profile;
        if (users && users.length == undefined) {
          users = [users];
        }
        if (!users) {
          users = [];
        }
        users = users.map((user) => {
          if (typeof user.displayname !== 'string')
            user.displayname = user.username;
          return user;
        });
        this.users = users;
      }
    },
    getInvites(selected) {
      this.usersToSend.push(selected);
      this.query = '';
      this.$delete(this.fieldErrors, 'email_or_group');
    },
    addGroup(group) {
      this.groupsToSend.push(group);
      this.groupsQuery = '';
      this.$delete(this.fieldErrors, 'email_or_group');
    },
    removeUser(userIndex) {
      this.usersToSend.splice(userIndex, 1);
    },
    removeGroup(groupIndex) {
      this.groupsToSend.splice(groupIndex, 1);
    },
    previewFile() {
      this.$preview.open(this.item);
    },
    async onSave() {
      this.fieldErrors = {};
      if (!this.usersToSend.length && !this.groupsToSend.length) {
        this.$set(this.fieldErrors, 'email_or_group', true);
        return;
      }

      const payload = {
        path: this.item.path,
        users: this.usersToSend.map((user) => user.emailid).join(','),
        groups: this.groupsToSend.map((group) => group.groupname).join(','),
        message: this.message,
      };

      const response = await this.$store.dispatch(
        'actions/sendFileForApproval',
        payload
      );

      let toast = {};
      if (response.ok) {
        toast = {
          message: `<b> ${this.$t('Success')}</b>
              <p role="alert">${this.$t(
                'File sent for approval successfully.'
              )}</p>`,
          type: 'success',
        };
      } else {
        toast = {
          message: `<b> ${this.$t('Error')}</b>
              <p role="alert">${response.message}</p>`,
          type: 'error',
        };
      }
      this.$toast.open(toast);
      this.closeModal();
    },
    open(item) {
      this.item = item;
      this.isOpen = true;
      setTimeout(() => {
        this.$refs.userAutocomplete.focus();
      }, 300);
    },
    closeModal() {
      this.isOpen = false;
      this.query = '';
      this.groupsQuery = '';
      this.message = this.defautlMessage;
      this.usersToSend = [];
      this.groupsToSend = [];
      this.fieldErrors = {};
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';

.shortcut-dialog .dialog-modal-body {
  padding-bottom: 0;
}
.files {
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  .file-data {
    display: inline-flex;
    flex-direction: column;
    .size {
      font-size: 11px;
      color: #8a94a6;
    }
  }
}
</style>
