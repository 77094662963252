<template>
  <div class="container-fluid d-flex flex-column-reverse flex-md-row p-0">
    
    <Loader loading full v-if="isLoading" />
    
    <div
      v-if="!canReadCustomization && !isLoading"
      class="droppable-wrapper droppable-wrapper--empty"
    >
      <div class="empty-box">
        <Icon family="fas" name="exclamation-square" />
        <div class="empty-text" v-html="$t(`You do not have permission to read the customization data,<br /> please ask the administrator to grant you access.`)">
        </div>
      </div>
    </div>
    
    <form
      class="d-flex flex-column col col-md-6 mt-3 mt-md-0"
      @submit="onSubmitForm"
      v-if="customization && canUpdateCustomization"
    >
      <div class="row no-gutters">
        <InputBox
          id="website-name"
          v-model="form.websiteName"
          :label="$t('Website Name')"
          :errors="errors"
          type="text"
          auto-select
        />
      </div>
      <div class="row no-gutters">
        <div class="form-group d-flex flex-column col-sm">
          <label for="website-default-theme">{{ $t('Default Theme') }}</label>
          <select
            id="website-default-theme"
            v-model="form.websiteDefaultTheme"
            class="custom-select"
          >
            <option
              v-for="(theme, index) in themes"
              :key="index"
              :value="index"
            >
              {{ $t(theme.name) }}
            </option>
          </select>
        </div>
      </div>

      <div class="row no-gutters">
        <InputBox
          id="company-logo"
          ref="logoInput"
          :label="$t('Company Logo')"
          :description="$t('PNG, recommended 200x50 pixels')"
          accept="image/png"
          type="file"
          :errors="errors"
          optional
          @input="onChangeLogo"
        />
      </div>

      <div class="row no-gutters">
        <InputBox
          id="background-image"
          ref="backgroundInput"
          :label="$t('Background Image')"
          :description="$t('PNG / JPG')"
          accept="image/png, image/jpeg"
          type="file"
          :errors="errors"
          optional
          @input="onChangeBackground"
        />
      </div>

      <div class="row no-gutters mb-4">
        <div class="form-group col-sm">
          <label>{{$t('Branding Color')}}</label>
          <Slider v-model="form.currentColor" />
        </div>
      </div>

      <div
        class="
          row
          no-gutters
          flex-row-reverse
          d-sm-flex
          justify-content-between
          align-items-center
        "
      >
        <div class="col-lg-12 text-right">
          <button type="submit" class="btn btn-primary">
            {{ $t('Save') }}
          </button>
        </div>
      </div>
    </form>
    
    <div class="d-flex justify-content-center col ml-md-4" v-if="customization && src.logo">
      <div class="mini-site-wrapper" :class="{
        ['col-6']: !canUpdateCustomization,
        ['mx-auto']: !canUpdateCustomization
        }">
        <div ref="minisite" class="mini-site" :class="{ [themes[form.websiteDefaultTheme].value]: true }">
          <div class="mini-site-header">
            <div v-if="form.websiteName" class="page-title">
              {{ form.websiteName }}
            </div>
          </div>
          <div class="mini-site-body">
            <div class="logo">
              <img
                v-if="
                  form.logo
                "
                :src="src.logo"
                alt=""
              />
              <img
                v-else-if="
                  (form.websiteDefaultTheme === 0 || form.websiteDefaultTheme === 5)
                "
                :src="src.logo"
                alt=""
              />
              <img
                v-else-if="src.darkLogo"
                :src="src.darkLogo"
                alt=""
              />
            </div>
            <div class="page-form"></div>
            <div class="page-btn"></div>
          </div>
          <div class="mini-site-bg">
            <img
              v-if="src.background"
              :src="src.background"
              class="login__main-bg main-bg"
            />
            <img
              v-else
              src="../../assets/aurora.jpg"
              class="login__main-bg main-bg"
            />
          </div>
        </div>
        <div class="site-mode">
          <span>{{ $t('Your Site Preview') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from 'common/components/InputBox.vue';
import Icon from 'common/components/Icon.vue';
import Loader from 'common/components/Loader.vue';
import serverURL from 'common/filecloud/properties/serverURL';

import { Slider } from 'vue-color';

import { parseFileThumb } from 'common/utils/files';
import { themes, setColor } from 'common/utils/themes';

import Logo from '../../assets/logo.svg';

export default {
  name: 'Customization',
  components: {
    Icon,
    InputBox,
    Slider,
    Logo,
    Loader
  },
  data() {
    return {
      form: {
        logo: null,
        background: null,
        websiteName: '',
        websiteDefaultTheme: 0,
        currentColor: '',
      },
      src: {
        logo: null,
        darkLogo: null,
        background:
          this.$store.state.core.customization.LOGINBG && typeof this.$store.state.core.customization.LOGINBG === 'string' ? serverURL.domainURL + this.$store.state.core.customization.LOGINBG : null,
      },
      isDarkMode: false,
      errors: {}
    };
  },
  computed: {
    themes() {
      return themes;
    },
    isLoading() {
      return this.$store.state.loading['miniAdmin/getCustomization'] ||
      this.$store.state.loading['miniAdmin/setCustomization'] ||
      this.$store.state.loading['miniAdmin/setCustomizationFile'] ||
      this.$store.state.loading['core/getCustomizations'];
    },
    customization() {
      return this.$store.state.miniAdmin.customization;
    },
    stats() {
      return this.$store.state.miniAdmin.stats;
    },
    permissions() {
      return this.stats?.userpermissions 
        ? this.stats.userpermissions.permissions
        : [];
    },
    canReadCustomization() {
      return this.permissions.indexOf('customization.read') > -1;
    },
    canUpdateCustomization() {
      return this.permissions.indexOf('customization.update') > -1;
    }
  },
  watch: {
    customization(customization) {      
      
      this.form.websiteName = customization.WINDOWTITLE;
      this.form.websiteDefaultTheme = customization.THEME_MODE;
      this.form.currentColor = typeof customization.THEME_COLOR === 'string' ? customization.THEME_COLOR : customization.THEME_COLOR[0];
      
      this.src.logo = serverURL.domainURL + customization.loginlogo.replace('#', '&');
      this.src.darkLogo = serverURL.domainURL + customization.maindarklogo.replace('#', '&');
      
    },
    form: {
      deep: true,
      handler(form) {        
        setColor((form.currentColor.hex) ? form.currentColor.hex : form.currentColor, this.$refs.minisite);
      }
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (
      this.$store.state.core.customization.THEME_COLOR !== this.currentColor
    ) {
      setColor(this.$store.state.core.customization.THEME_COLOR);
    }
  },
  methods: {
    async init() {
      if(this.canReadCustomization) {
        await this.$store.dispatch('miniAdmin/getCustomization');
      }
    },
    async onSubmitForm(e) {
      e.preventDefault();
      e.stopPropagation();
      
      let isLogoChanged = false;
      let isLogoChangedDark = false;
      let isBgChanged = false;
            
      if(this.form.logo) {
        
        isLogoChanged = true;
        isLogoChangedDark = !(this.form.websiteDefaultTheme === 0 || this.form.websiteDefaultTheme === 5);
        
        const logoResponse = await this.$store.dispatch('miniAdmin/setCustomizationFile', {
          file: this.form.logo,
          scope: (this.form.websiteDefaultTheme === 0 || this.form.websiteDefaultTheme === 5) ? 'login' : 'maindark'  // upload to dark or light depending on the user theme
        });
        
        if(logoResponse.data && logoResponse.data.indexOf('OK') === -1 && logoResponse.data.indexOf('uploaded') === -1) {
          this.errors = {
            ['company-logo']: {
              message: logoResponse.data
            }
          }
          return;
        }

      }
      
      if(this.form.background) {
        
        isBgChanged = true;
        
        const bgResponse = await this.$store.dispatch('miniAdmin/setCustomizationFile', {
          file: this.form.background,
          scope: 'loginbg'
        });
        
        if(logoResponse.data && logoResponse.data.indexOf('OK') === -1 && logoResponse.data.indexOf('uploaded') === -1) {
          this.errors = {
            ['background-image']: {
              message: bgResponse.data
            }
          }
          return;
        }

      }
      
      await this.$store.dispatch('miniAdmin/setCustomization', {
        WINDOWTITLE: this.form.websiteName,
        THEME_MODE: this.form.websiteDefaultTheme,
        THEME_COLOR: (this.form.currentColor.hex) ? this.form.currentColor.hex : this.form.currentColor,
        loginbg: (isBgChanged) ? `/core/getcustomimage?type=loginbg#time=${new Date().getTime()}` : undefined,
        loginlogo: (isLogoChanged && !isLogoChangedDark) ? `/core/getcustomimage?type=login#time=${new Date().getTime()}` : undefined,
        maindarklogo: (isLogoChanged && isLogoChangedDark) ? `/core/getcustomimage?type=maindark#time=${new Date().getTime()}` : undefined
      });
      
      this.$nextTick(() => {
        this.form.logo = null;
        this.form.background = null;
        this.$refs.logoInput.onCancelUpload();
        this.$refs.backgroundInput.onCancelUpload();
      })

      return;
    },
    async onChangeLogo(data) {
            
      if (data) {
        this.src.logo = await parseFileThumb(data[0]);
        this.form.logo = data[0];
      } else {
        this.src.logo = serverURL.domainURL + this.customization.loginlogo;
        this.form.logo = null;
      }
      /*
      await this.$store.dispatch('miniAdmin/setCustomizationFile', {
        file: data[0],
        scope: 'login'
      });
      */
    },
    async onChangeBackground(data) {
      if (data) {
        this.src.background = await parseFileThumb(data[0]);
        this.form.background = data[0];
      } else {
        this.src.background = this.$store.state.core.customization.LOGINBG && typeof this.$store.state.core.customization.LOGINBG === 'string' ? serverURL.domainURL + this.$store.state.core.customization.LOGINBG : null,
        this.form.background = null;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
select {
  color: var(--text-medium);
  background-color: var(--bg-light);
  border-color: var(--border-color-light) !important;

  &:focus {
    border-color: var(--color-primary) !important;
    box-shadow: 0 0 0 0.2rem var(--color-primary--shadow) !important;
  }
}

.vc-slider {
  width: 100%;
  max-width: 100% !important;
  .vc-hue--horizontal {
    border-radius: 100px;
  }
  .vc-slider-swatches {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .vc-slider-swatch {
    margin: 0 !important;
    max-width: 19%;
  }
  .vc-slider-swatch-picker {
    border-radius: 100px !important;
    position: relative;

    &.vc-slider-swatch-picker--active {
      transform: scaleY(1.4) !important;
      border-radius: 100px !important;
    }
  }
}

.mini-site-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .mini-site {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-height: 256px;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
    background: var(--bg-light);
    user-select: none;
    flex-wrap: wrap;
    transition: all 0.5s ease;
    margin-bottom: 1rem;
    * {
      transition: all 0.25s ease;
    }
    &-header {
      width: 100%;
      background: var(--bg-medium);
      padding: 0.5rem 1rem;
      border-radius: 0.3rem 0.3rem 0 0;
      height: 35px;
      .page-title {
        font-size: 0.8rem;
        font-weight: 600;
        color: var(--text-dark);
        text-align: center;
      }
      .address-bar {
        background: var(--bg-light);
        color: var(--text-medium);
        font-size: 0.7rem;
        padding: 0.2rem 1rem;
        border-radius: 100px;
        border: 1px solid var(--border-color);
      }
    }
    &-body {
      border-radius: 0.3rem 0 0 0.3rem;
      height: calc(100% - 35px);
      min-height: 0;
      width: 50%;
      padding: 2rem 0;
      .logo {
        position: relative;
        margin: 0 auto;
        width: 100px;
        height: 25px;
        overflow: hidden;
        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
        svg {
          transform: scale(0.4);
          transform-origin: left top;
        }
      }
      .page-form {
        position: relative;
        width: 100%;
        height: 0.6rem;
        margin-top: 2.5rem;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        &:before,
        &:after {
          display: inline-block;
          content: '';
          width: 48%;
          height: 100%;
          background: var(--border-color);
          border-radius: 0.1rem;
        }
      }
      .page-btn {
        position: relative;
        width: 100%;
        height: 0.6rem;
        margin-top: 0.5rem;
        padding: 0 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        &:after {
          display: inline-block;
          content: '';
          width: 30%;
          height: 100%;
          background: var(--color-primary);
          border-radius: 0.1rem;
        }
      }
    }
    &-bg {
      height: calc(100% - 35px);
      width: 50%;
      overflow: hidden;
      border-radius: 0 0rem 0.3rem 0;
      img {
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  .site-mode {
    width: 100%;
    bottom: -2rem;
    font-size: 0.8rem;
    text-align: center;
    color: var(--text-light);
    font-weight: 600;
    a {
      color: var(--text-muted);
      &.active {
        color: var(--text-dark);
      }
    }
    .switch-toggle {
      margin: 0 0.8rem;
    }
  }
}
</style>
