export default [
  {
    text: 'Upload',
    subtext: 'When a file or folder is added',
    model: 'upload',
  },
  {
    text: 'Update',
    subtext: 'When a file is modified',
    model: 'update',
  },
  {
    text: 'Download',
    subtext: 'When a file or folder is downloaded',
    model: 'download',
  },
  {
    text: 'Preview',
    subtext: 'When a file is viewed in the browser or in the mobile app',
    model: 'preview',
  },
  {
    text: 'Share',
    subtext: 'When a file or folder is shared with someone',
    model: 'share',
  },
  {
    text: 'Lock/Unlock',
    subtext: 'When a file or folder is locked or unlocked',
    model: 'lock',
  },
  {
    text: 'Delete',
    subtext: 'When a file or folder is deleted',
    model: 'delete',
  },
  {
    text: 'Self Notifications',
    subtext: 'When an action is performed by me',
    model: 'enableSelfNotifications',
  },
  {
    text: 'Rename',
    subtext: 'When a file or folder is renamed',
    model: 'rename',
  },
];
