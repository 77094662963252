<template>
  <div class="security-row row col-md-12">
    <div class="name col-md-6">{{ name }}</div>

    <Checkbox
      v-model="permissions.R"
      class="checkbox col-md-1"
      @input="emitPermissions"
    />
    <Checkbox
      v-model="permissions.W"
      class="checkbox col-md-1"
      @input="emitPermissions"
    />
    <Checkbox
      v-model="permissions.D"
      class="checkbox col-md-1"
      @input="emitPermissions"
    />
    <Checkbox
      v-model="permissions.S"
      class="checkbox col-md-1"
      @input="emitPermissions"
    />
    <Checkbox
      v-model="permissions.M"
      class="checkbox col-md-1"
      @input="emitPermissions"
    />
    <a class="col-md-1" @click="emitRemove"><Icon name="times" /></a>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import Checkbox from 'common/components/Checkbox';

export default {
  components: {
    Icon,
    Checkbox,
  },
  props: {
    aclData: {
      default: [],
    },
    type: {
      type: String,
      default: 'users',
    },
    path: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permissions: {
        R: false,
        W: false,
        D: false,
        S: false,
        M: false,
      },
    };
  },
  computed: {
    name() {
      if (this.type === 'user') {
        return this.aclData.user;
      } else {
        return this.aclData.groupname;
      }
    },
    aclId() {
      if (this.type === 'user') {
        return this.aclData.user;
      } else {
        return this.aclData.groupid;
      }
    },
  },
  mounted() {
    const perms = this.aclData.perm;

    if (perms.includes('R')) this.permissions.R = true;
    if (perms.includes('W')) this.permissions.W = true;
    if (perms.includes('D')) this.permissions.D = true;
    if (perms.includes('S')) this.permissions.S = true;
    if (perms.includes('M')) this.permissions.M = true;
  },
  methods: {
    emitPermissions: function () {
      const perms =
        (this.permissions.R ? 'R' : '') +
        (this.permissions.W ? 'W' : '') +
        (this.permissions.D ? 'D' : '') +
        (this.permissions.S ? 'S' : '') +
        (this.permissions.M ? 'M' : '');

      this.$emit('emitPermissions', {
        permissions: perms,
        value: this.aclId,
        path: this.path,
        type: this.aclData.type,
      });
    },
    emitRemove: function () {
      this.$emit('removeAcl', {
        path: this.path,
        value: this.name,
        type: this.aclData.type,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.security-row {
  height: 38px;
  margin-top:10px;
  text-align: center;
  vertical-align: middle;

  .name {
    font-size: 13px;
    text-align: left;
    padding-left:29px;
  }
  .checkbox {
    text-align: center;
    vertical-align: middle;
  }
}
</style>
