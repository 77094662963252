<template>
  <transition
    enter-active-class="animate__animated animate__slideInLeft"
    leave-active-class="animate__animated animate__fadeOutLeft"
  >
    <div v-show="openned" class="mobile-navigation">
      <div class="mobile-navigation__container">
        <div class="container__wrapper">
          <div class="header">
            <Avatar
              size="small"
              :label="$t(`{userName}'s Avatar`, { userName: user.displayname })"
            />
            <span class="user-name">
              {{ user.displayname }}
            </span>
          </div>
          <div class="body">
            <ul class="nav-items">
              <li
                v-for="item in navItemsFiltered"
                :key="item.id"
                class="item cursor-pointer"
                :class="{
                  'item--selected':
                    $route.name === item.routeName ||
                    (item.action === goToRecyclebin && isInRecycleFolder),
                }"
                @click="goToRoute(item.routeName, item.action)"
              >
                <div class="item__icon">
                  <Icon :name="item.icon" family="fas" />
                </div>
                <div class="item__text">
                  {{ item.text }}
                </div>
              </li>
            </ul>
          </div>
          <div class="footer">
            <Loader v-if="!isReady" :loading="!isReady" full />
            <div v-else-if="!isLimitedUser" class="info-text">
              {{ $tc('{count} file | {count} files', usageSummary.totalfiles) }}
              {{ usageSummary.totalfiles }} {{ $t('files') }}
              <br />
              {{
                $tc('{out} out of {total}', usageSummary.storageusage, {
                  out: usageSummary.storageusage,
                  total: usageSummary.sizelimit,
                })
              }}
              <br />
              {{ $tc('{count} share | {count} shares', shareSummary.length) }},
              {{ $tc('{count} expired', expiredShares.length) }}
            </div>
            <div class="sign-out cursor-pointer" @click="logout">
              <Icon name="sign-out" family="fas" />
              {{ $t('Logout') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Icon from 'common/components/Icon';
import Avatar from 'common/components/Avatar';
import Loader from 'common/components/Loader';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    Avatar,
    Loader,
  },
  data() {
    return {
      openned: false,
      isReady: false,
      navItems: [
        {
          id: 0,
          icon: 'home',
          text: this.$t('Dashboard'),
          routeName: 'files',
          hidden: false,
        },
        {
          id: 1,
          icon: 'clock',
          text: this.$t('Recent'),
          routeName: '',
          hidden: true,
        },
        {
          id: 2,
          icon: 'star',
          text: this.$t('Starred'),
          routeName: '',
          hidden: true,
        },
        {
          id: 3,
          icon: 'share-alt',
          text: this.$t('Shared by Me'),
          action: 'goToShared',
          routeName: 'shared-files',
          hidden: false,
        },
        {
          id: 4,
          icon: 'trash',
          text: this.$t('Deleted Files'),
          action: 'goToRecyclebin',
          routeName: '',
          hidden:
            this.$store.state.auth.msteams ||
            this.$store.state.files.filesMeta?.showrecyclebin === 0 ||
            this.$store.state.core.fullSystemStatus?.disablemyfiles === 1,
        },
        {
          id: 5,
          icon: 'user-crown',
          text: this.$t('Admin'),
          action: 'openAdminModal',
          routeName: '',
          hidden: this.$store.state.core.systemstatus?.MINIADMINENABLED !== 1,
        },
        {
          id: 6,
          icon: 'cog',
          text: this.$t('Settings'),
          action: 'goToSettings',
          routeName: '',
          hidden: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    user() {
      return this.$store.state.auth.user;
    },
    usageSummary() {
      return this.$store.state.core.usageSummary;
    },
    shareSummary() {
      return this.$store.state.files.sharedByMe || [];
    },
    expiredShares() {
      return this.shareSummary.length
        ? this.shareSummary.filter((share) => !share.isvalid)
        : [];
    },
    isInRecycleFolder() {
      return this.$route.fullPath.indexOf('recyclebin') > -1;
    },
    navItemsFiltered() {
      return this.navItems.filter((item) => {
        return !item.hidden;
      });
    },
  },
  mounted() {
    this.getProfileSummary();
    this.$root.$on('overlayClicked', () => this.close);
  },
  methods: {
    open() {
      this.openned = true;

      this.$store.commit('files/showMobileOverlay');
    },
    async getProfileSummary() {
      this.isReady = false;
      await this.$store.dispatch('auth/readyState');
      await this.$store.dispatch('files/shares');
      if (!this.isLimitedUser) {
        await this.$store.dispatch('core/getProfileSummary', this.user);
        await this.$store.dispatch('core/getUserDevices', {
          userid: this.user.profile,
        });
      } else {
        this.handleLimitedMenuOptions();
      }
      this.isReady = true;
    },
    handleLimitedMenuOptions() {
      /*
          Hide menu options for limited users:
          1 - Recent
          2 - Starred
          3 - Shared by Me
          4 - Deleted Files
          5 - Admin
          6 - Settings
         */
      this.navItems[2].hidden = true;
      this.navItems[3].hidden = true;
      this.navItems[5].hidden = true;
    },
    goToRecyclebin() {
      this.$root.$emit('browseToRecycleBin');
      this.close();
    },
    openAdminModal() {
      this.$store.dispatch('miniAdmin/showMiniAdminModal', { visible: true });
      this.close();
    },
    close() {
      this.openned = false;
      this.$store.commit('files/hideMobileOverlay');
    },
    goToSettings() {
      this.$router.push('/sets-gnrl.');
      this.close();
    },
    goToRoute(route, action) {
      if (route && route !== this.$route.name) {
        this.$router.push({ name: route });
        this.close();
      } else {
        this[action]();
      }
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
    },
  },
};
</script>
