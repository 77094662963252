<template>
  <li
    class="transactions-list-item"
    :class="{
      [`transaction-list-item--preparing`]: transaction.status === 'preparing',
    }"
  >
    <div class="transaction-icon">
      <Icon family="fas" name="folder-plus" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon
          v-if="transaction.status === 'failed'"
          family="fas"
          name="times"
        />
        <Icon
          v-else-if="transaction.status === 'cancelled'"
          family="fas"
          name="ban"
        />
        <Icon v-else family="fas" name="check" />
      </div>
    </div>
    <div class="transaction-info">
      <span class="transaction-info-title" v-tooltip="{ content: tooltipContent }">
        {{ currentPath !== transaction.dirpath ? `${transaction.dirpath}/` : ''
        }}{{ transaction.name }}
        <small>{{ transaction.entries }} items</small>
      </span>
      <span v-if="transaction.status === 'preparing'" class="description text-truncate">
        {{
          $tc(
            '{count} item inside this folder will be processed soon | {count} items inside this folder will be processed',
            transaction.entries
          )
        }}
      </span>
    </div>
    <div class="transaction-actions">
      <button
        v-if="
          transaction.status !== 'cancelled' &&
          transaction.status !== 'failed' &&
          transaction.status !== 'completed'
        "
        class="btn btn-icon"
        :aria-label="$t('Cancel operation')"
        @click="cancel"
      >
        <Icon family="fas" name="ban" />
        <Icon class="icon-overlay" family="fas" name="ban" />
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';
import { bytesToSize } from 'common/utils/files';

export default {
  components: {
    Icon,
  },
  props: {
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    progress() {
      const progress = Math.ceil(
        (this.transaction.sentSize * 100) / this.transaction.size
      );
      return progress >= 100 ? 100 : progress;
    },
    tooltipContent() {
      if (this.currentPath !== this.transaction.dirpath) {
        return `${this.transaction.dirpath}/`;
      } else {
        return `${this.transaction.name}`;
      }
    },
  },
  methods: {
    cancel() {
      this.$store.commit('files/setTransaction', {
        id: this.transaction.id,
        status: 'cancelled',
      });
    },
    retry() {
      this.$store.dispatch('files/retryTransaction', {
        id: this.transaction.id,
      });
    },
    bytesToSize,
  },
};
</script>
