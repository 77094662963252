<template>
  <div v-if="color" class="file-colortag" :style="`background: ${color}`" />
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
.file-colortag {
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 7px;
  position: absolute;
  right: 0;
  top: 2px;
}
.dropdown-item .file-colortag {
  position: static;
  margin-bottom: -2px;
}
</style>
