var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{attrs:{"visible":_vm.isOpen,"title":_vm.isFile
      ? _vm.$t('share.modal.rename.file_title', { name: _vm.item.name })
      : _vm.$t('share.modal.rename.folder_title', { name: _vm.item.name }),"color":"gray","stick-top":false,"buttons":[
    {
      label: _vm.$t('general.Ok'),
      callback: function () {
        _vm.closeModal();
      },
    },
    {
      label: _vm.$t('general.Cancel'),
      outline: true,
      callback: function () {
        _vm.isOpen = false;
      },
    },
  ]},on:{"close":function($event){_vm.isOpen = false}}},[_c('div',[(_vm.isFile)?_c('h4',[_vm._v(_vm._s(_vm.$t('share.modal.rename.warning_file_title')))]):_c('h4',[_vm._v(_vm._s(_vm.$t('share.modal.rename.warning_folder_title')))]),_vm._v(" "+_vm._s(_vm.isFile ? _vm.$t('share.modal.rename.warning_file_description') : _vm.$t('share.modal.rename.warning_folder_description'))+" "),_c('p',{staticClass:"d-flex mt-3"},[_c('Checkbox',{staticClass:"mr-2",attrs:{"label":_vm.isFile
            ? _vm.$t('share.modal.rename.match_file_name_checkbox_label')
            : _vm.$t('share.modal.rename.match_folder_name_checkbox_label')},model:{value:(_vm.renameShare),callback:function ($$v) {_vm.renameShare=$$v},expression:"renameShare"}}),_vm._v(" "+_vm._s(_vm.isFile ? _vm.$t('share.modal.rename.match_file_name_checkbox_label') : _vm.$t('share.modal.rename.match_folder_name_checkbox_label'))+" ")],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }