export const routeNames = {
  auth: {
    label: 'Log In',
    compName: 'LoginForm',
  },
  login: {
    label: 'Log In',
    compName: 'LoginForm',
  },
  logintwofa: {
    label: '2FA Authentication',
    compName: 'LoginTwoFaForm',
  },
  newAccount: {
    label: 'New Account',
    compName: 'NewAccountForm',
  },
  forgotPassword: {
    label: 'Forgot Password',
    compName: 'ForgotPassword',
  },
  resetPassword: {
    label: 'Reset your password',
    compName: 'ResetPassword',
  },
  forceResetPassword: {
    label: 'Change Password',
    compName: 'ForceResetPassword',
  },
  ssoDirect: {
    label: 'Single Sign On',
    compName: 'SSODirectForm',
  },
  twofausersetup: {
    label: 'Two-Factor Authentication Setup',
    compName: 'AuthSetupForm',
  },
  securedshare: {
    label: 'Password Protected Share',
    compName: 'PasswordProtectedShare',
  },
  tos: {
    label: 'Terms of Service',
    compName: 'TermsOfService',
  },
  twofausersetupsms: {
    label: 'Two-Factor Authentication Setup',
    compName: 'TwoFASetupSMS',
  },
  twofausersetupsmsverification: {
    label: 'Two-Factor Authentication Setup',
    compName: 'TwoFASetupSMSVerification',
  },
  twofausersetuptotp: {
    label: 'Two-Factor Authentication Setup',
    compName: 'TwoFASetupTOTP',
  },
  twofausersetupduo: {
    label: 'Two-Factor Authentication Setup',
    compName: 'TwoFASetupDUO',
  },
};

export default routeNames;
