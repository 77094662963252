<template>
  <div
    v-a11ybutton="toggleNavigationBar"
    class="d-none d-md-block sidebar"
    :style="styleObject"
    @click.stop.prevent="toggleNavigationBar"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
  >
    <nav class="sidebar-sticky">
      <ul class="nav flex-column mb-2 pl-1">
        <li class="nav-item">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('All Files')"
              name="folder"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
        <li v-if="!isLimitedUser" class="nav-item">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('Recent Documents')"
              name="clock"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
        <li v-if="!isLimitedUser" class="nav-item">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('Starred')"
              name="star"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
        <li v-if="!isLimitedUser" class="nav-item">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('Shared by Me')"
              name="share-alt"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
        <li
          v-if="systemStatus.AUTOMATION_WORKFLOWS && !isLimitedUser"
          class="nav-item"
        >
          <a class="nav-link" tabindex="0">
            <WorkflowIcon
              v-tooltip.right="$t('Workflows')"
              class="collapse-icon"
            />
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('File Operations')"
              name="file-alt"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
      </ul>
      <ul
        class="nav flex-column fixed-bottom pl-1 minimized-bottom-items"
        style="height: 134px"
      >
        <li v-if="customization.DISABLENEWSFEED !== 1">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('Notice')"
              name="sticky-note"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
        <li v-if="showRecyclebin">
          <a class="nav-link" tabindex="0">
            <Icon
              v-tooltip.right="$t('Deleted Files')"
              name="trash-alt"
              family="fas"
              class="collapse-icon pr-1"
            />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import WorkflowIcon from 'common/assets/icons/workflow.svg';
import { mapGetters } from 'vuex';

export default {
  components: {
    Icon,
    WorkflowIcon,
  },
  data() {
    return {
      hoverState: false,
    };
  },
  computed: {
    ...mapGetters('core', ['isLimitedUser']),
    styleObject() {
      if (!this.hoverState) {
        return {
          width: '60px',
          zIndex: '70',
        };
      } else {
        return {
          width: '60px',
          boxShadow: '0 0 5px rgba(33,33,33,.2)',
          cursor: 'pointer',
          zIndex: '100',
        };
      }
    },
    navigationSidebar() {
      return this.$store.state.core.navigationSidebar;
    },
    operationsBar() {
      return this.$store.state.files.transactionsBar;
    },
    showRecyclebin() {
      return (
        (this.$store.state.files.filesMeta?.showrecyclebin === 1 ||
          this.$store.state.files.filesMeta?.showrecyclebin === undefined) &&
        this.fullSystemStatus['disablemyfiles'] === 0
      );
    },
    fullSystemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    customization() {
      return this.$store.state.core.customization;
    },
    systemStatus() {
      return this.$store.state.core.systemstatus;
    },
  },
  methods: {
    toggleNavigationBar() {
      this.$store.dispatch('core/setCustomizations', {
        name: 'SIDEPANEL_LEFT_COLLAPSE',
        value: this.navigationSidebar.open ? 1 : 0,
      });
      this.$store.commit('core/toggleNavigationSidebar');
    },
    toggleTransactions() {
      this.$store.commit('files/setTransactionsBar', {
        key: 'expanded',
        value: !this.operationsBar.visible,
      });
      this.$store.commit('files/setTransactionsBar', {
        key: 'visible',
        value: !this.operationsBar.visible,
      });
    },
    updateHoverState(isHover) {
      this.hoverState = isHover;
    },
  },
};
</script>

<style lang="scss" scoped>
.page_wrap {
  z-index: 1;
  margin: 0;
}
#bottom_fade {
  //bottom: 1rem;
  width: auto;
  height: 1rem;
  background-image: linear-gradient(
    180deg,
    rgba(248, 248, 248, 0) 0%,
    var(--bg-medium) 100%
  );
}
.collapse-icon {
  color: var(--text-light) !important;
  &:hover {
    color: var(--text-dark) !important;
  }
}
.collapse-sidebar {
  left: 99%;
  border-radius: 0 0 6px;
}
.minimized-bottom-items {
  max-width: 4%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 35px;
}
.item-menu-text {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: var(--icons-color);
}
.menu-icon {
  font-size: 1rem;
  min-width: 1.5rem;
  color: var(--icons-color);
}
.community-footer {
  padding-bottom: 8rem !important;
}
.community-scroller {
  max-height: calc(100vh - 28rem) !important;
}
</style>
