var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Themed',{attrs:{"background-color":"","text-color":""}},[_c('div',{staticClass:"flex-table user-group-table"},[_c('permissionTableHeading',{attrs:{"label":_vm.label,"is-share-context":_vm.isShareContext,"is-security-context":_vm.isSecurityContext}}),(!_vm.items || _vm.items.length == 0)?_c('div',{staticClass:"py-2 text-center"},[_vm._v(" "+_vm._s(_vm.$t('No entries'))+" ")]):_vm._e(),_vm._l((_vm.items),function(user,index){return _c('div',{key:user.name,staticClass:"row-content"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(user.name || user.groupname),expression:"user.name || user.groupname"}],staticClass:"name text-truncate"},[_vm._v(" "+_vm._s(user.name || user.groupname)+" ")]),(_vm.isShareContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.view &&
          _vm.disabledObject.view.disabled &&
          _vm.$t(_vm.disabledObject.view.label || '')
        ),expression:"\n          disabledObject.view &&\n          disabledObject.view.disabled &&\n          $t(disabledObject.view.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('View'),"checked":user.read,"disabled":_vm.readonly || (_vm.disabledObject.view && _vm.disabledObject.view.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'view', index)}}})],1):_vm._e(),(_vm.isSecurityContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.read &&
          _vm.disabledObject.read.disabled &&
          _vm.$t(_vm.disabledObject.read.label || '')
        ),expression:"\n          disabledObject.read &&\n          disabledObject.read.disabled &&\n          $t(disabledObject.read.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Read'),"checked":user.read,"disabled":_vm.readonly || (_vm.disabledObject.read && _vm.disabledObject.read.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'read', index)}}})],1):_vm._e(),(_vm.isSecurityContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.write &&
          _vm.disabledObject.write.disabled &&
          _vm.$t(_vm.disabledObject.write.label || '')
        ),expression:"\n          disabledObject.write &&\n          disabledObject.write.disabled &&\n          $t(disabledObject.write.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Write'),"checked":user.write,"disabled":_vm.readonly ||
            (_vm.disabledObject.write && _vm.disabledObject.write.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'write', index)}}})],1):_vm._e(),(_vm.isShareContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.download &&
          _vm.disabledObject.download.disabled &&
          _vm.$t(_vm.disabledObject.download.label || '')
        ),expression:"\n          disabledObject.download &&\n          disabledObject.download.disabled &&\n          $t(disabledObject.download.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Download'),"checked":user.download,"disabled":_vm.readonly ||
            (_vm.disabledObject.download && _vm.disabledObject.download.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'download', index)}}})],1):_vm._e(),(_vm.isShareContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.upload &&
          _vm.disabledObject.upload.disabled &&
          _vm.$t(_vm.disabledObject.upload.label || '')
        ),expression:"\n          disabledObject.upload &&\n          disabledObject.upload.disabled &&\n          $t(disabledObject.upload.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Upload'),"checked":user.write,"disabled":_vm.readonly ||
            (_vm.disabledObject.upload && _vm.disabledObject.upload.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'write', index)}}})],1):_vm._e(),(_vm.isShareContext || _vm.isSecurityContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          ((_vm.disabledObject.share && _vm.disabledObject.share.disabled) ||
            user.status == 3) &&
          _vm.$t(_vm.disabledObject.share.label || '')
        ),expression:"\n          ((disabledObject.share && disabledObject.share.disabled) ||\n            user.status == 3) &&\n          $t(disabledObject.share.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Share'),"checked":user.share,"disabled":(_vm.disabledObject.share && _vm.disabledObject.share.disabled) ||
            user.status == 3 ||
            _vm.readonly},on:{"input":function($event){return _vm.updatePermission(user, 'share', index)}}})],1):_vm._e(),(_vm.isShareContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          ((_vm.disabledObject.sync && _vm.disabledObject.sync.disabled) ||
            user.status == 3) &&
          _vm.$t(
            _vm.disabledObject.sync.label ||
              'Permission not allowed for external user'
          )
        ),expression:"\n          ((disabledObject.sync && disabledObject.sync.disabled) ||\n            user.status == 3) &&\n          $t(\n            disabledObject.sync.label ||\n              'Permission not allowed for external user'\n          )\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Sync'),"checked":user.sync,"disabled":(_vm.disabledObject.sync && _vm.disabledObject.sync.disabled) ||
            user.status == 3 ||
            _vm.readonly},on:{"input":function($event){return _vm.updatePermission(user, 'sync', index)}}})],1):_vm._e(),(_vm.isShareContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.delete &&
          _vm.disabledObject.delete.disabled &&
          _vm.$t(_vm.disabledObject.delete.label || '')
        ),expression:"\n          disabledObject.delete &&\n          disabledObject.delete.disabled &&\n          $t(disabledObject.delete.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Delete'),"checked":_vm.context.isSingleFileShare
              ? false
              : user.write && !user.disallowdelete,"disabled":_vm.readonly ||
            (_vm.disabledObject.delete && _vm.disabledObject.delete.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'disallowdelete', index)}}})],1):_vm._e(),(_vm.isSecurityContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.delete &&
          _vm.disabledObject.delete.disabled &&
          _vm.$t(_vm.disabledObject.delete.label || '')
        ),expression:"\n          disabledObject.delete &&\n          disabledObject.delete.disabled &&\n          $t(disabledObject.delete.label || '')\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Delete'),"checked":user.write && !user.disallowdelete,"disabled":_vm.readonly ||
            (_vm.disabledObject.delete && _vm.disabledObject.delete.disabled)},on:{"input":function($event){return _vm.updatePermission(user, 'disallowdelete', index)}}})],1):_vm._e(),(_vm.isShareContext || _vm.isSecurityContext)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          ((_vm.disabledObject.manage && _vm.disabledObject.manage.disabled) ||
            user.status == 3) &&
          _vm.$t(
            _vm.disabledObject.manage.label ||
              'Permission not allowed for external user'
          )
        ),expression:"\n          ((disabledObject.manage && disabledObject.manage.disabled) ||\n            user.status == 3) &&\n          $t(\n            disabledObject.manage.label ||\n              'Permission not allowed for external user'\n          )\n        "}]},[_c('Checkbox',{attrs:{"aria-label":_vm.$t('Manage'),"checked":user.allowmanage,"disabled":(_vm.disabledObject.manage && _vm.disabledObject.manage.disabled) ||
            user.status == 3 ||
            _vm.readonly},on:{"input":function($event){return _vm.updatePermission(user, 'allowmanage', index)}}})],1):_vm._e(),(!_vm.readonly)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
          _vm.disabledObject.remove &&
          _vm.disabledObject.remove.disabled &&
          _vm.$t(_vm.disabledObject.remove.label || '')
        ),expression:"\n          disabledObject.remove &&\n          disabledObject.remove.disabled &&\n          $t(disabledObject.remove.label || '')\n        "}],staticClass:"remove-row"},[(_vm.disabledObject.remove && _vm.disabledObject.remove.disabled)?_c('a',{staticClass:"btn btn-sm btn-icon fa fa-times px-1 p-0",attrs:{"aria-label":_vm.$t('Remove')}}):_c('v-popover',[_c('a',{staticClass:"btn btn-sm btn-icon fa fa-times px-1 p-0",attrs:{"aria-label":_vm.$t('Remove')}}),_c('Alert',{attrs:{"slot":"popover","button-type":"danger","buttons":[
              {
                label: _vm.$t('OK'),
                callback: () =>
                  _vm.disabledObject.remove && _vm.disabledObject.remove.disabled
                    ? () => {}
                    : _vm.updatePermission(user, 'delete', index),
              },
              {
                label: _vm.$t('Cancel'),
                outline: true,
                callback: () => {},
              },
            ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to delete the Permissions ?'))+" ")])])],1)],1):_vm._e()])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }