var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DialogModal',{attrs:{"visible":true,"color":"gray","title":_vm.$t('Notification Settings for') + ' ' + _vm.path,"position":"top","buttons":[
      {
        label: `${_vm.$t('Save')}`,
        callback: function () {
          _vm.save();
        },
      },
      {
        label: `${_vm.$t('Cancel')}`,
        outline: true,
        callback: function () {
          _vm.closeModal();
        },
      },
    ]},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"settings-page"},[_c('NotificationSets',{ref:"notifications",attrs:{"title":'Use default notification settings',"path":_vm.path},on:{"closeModal":_vm.closeModal}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }