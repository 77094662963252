<template>
  <div class="activity-sidebar-body-content">
    <Loader full :loading="loading" />
    <div class="markdown-content" v-html="content"></div>
  </div>
</template>

<script>
import { marked } from 'marked';
import Loader from 'common/components/Loader';
import { applyWikiImages } from 'common/utils/files';

export default {
  props: {
    previewContent: {
      type: String,
      default: '',
    },
    path: String,
  },
  computed: {
    meta() {
      return this.$store.getters['files/getPathMeta'](this.path);
    },
    compiledMarkdown() {

      const renderer = new marked.Renderer();

      renderer.link = function(href, title, text) {
        var link = marked.Renderer.prototype.link.apply(this, arguments);
        return link.replace("<a","<a target='_blank'");
      };

      marked.setOptions({
        renderer: renderer
      });

      return marked(this.content, { sanitize: true });
    },
  },
  async mounted() {
    this.loading = true;

    const response = await this.$store.dispatch('files/wiki', {
      path: `${this.path}/${this.meta?.aboutfile}`,
      name: this.meta?.aboutfile,
    });

    this.loading = false;

    if (response.ok) {

      const body = response.data.match(/<body[^>]*>[\s\S]*<\/body>/gi);

      if(body[0]) {
        this.content = applyWikiImages(body[0], this.path)
      }

    }

  },
  data() {
    return {
      content: '',
      loading: false,
    };
  },
  components: {
    Loader,
  },
};
</script>
