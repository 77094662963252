<template>
  <div class="file-preview-portal file-preview-portal--zip-viewer">
    <component
      :is="currentView"
      v-if="file"
      :key="file.name"
      :file="file"
      :can-download="canDownload"
      @fallback="onFallback"
      @download="$emit('download')"
    />
  </div>
</template>

<script>
import DefaultView from './Preview/DefaultView';
import Office from './Preview/Office';
import Img from './Preview/Img';
import Pdf from './Preview/Pdf';
import Txt from './Preview/Txt';
import { getFileType, getViewerForZipNestedPreview } from 'common/utils/files';

export default {
  components: {
    DefaultView,
    Office,
    Img,
    Pdf,
    Txt,
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    enablejspreview: {
      type: Boolean,
      default: true,
    },
    canDownload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fallback: '',
      error: '',
    };
  },
  computed: {
    currentView() {
      if (!this.file) return;

      if (this.fallback) return this.fallback;

      const isJSPreviewEnabled = this.enablejspreview;
      if (!isJSPreviewEnabled) return 'DefaultView';

      const fileType = getFileType(this.file.name);
      return getViewerForZipNestedPreview(fileType);
    },
  },
  watch: {
    file() {
      this.reset();
    },
  },
  methods: {
    onFallback(fallback) {
      if (typeof fallback == 'string') {
        this.fallback = fallback;
      } else {
        this.fallback = fallback?.view || 'DefaultView';
        this.error = fallback?.error || 'Unexpected error';
      }
    },
    reset() {
      this.fallback = '';
      this.error = '';
    },
  },
};
</script>
