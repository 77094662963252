<template>
  <div class="metadata-tab w-100">
    <Loader v-if="loading" :loading="loading" full />
    <form v-else @submit.prevent>
      <Header v-if="!hideHeader" :path="path" />
      <div v-if="!readOnly" class="form-group first">
        <label for="selectField">{{ $t('Add Metadata') }}</label>
        <div v-if="metadataSets.length > 0" class="d-flex">
          <div
            class="form-control form-control--select add-metadata-wrapper col-9"
          >
            <select
              :key="updated"
              v-model="setSelected"
              class="add-metadata-selector"
            >
              <option
                v-for="(set, index) in metadataSets"
                :key="set.id"
                :value="set.id"
                :selected="index === 0"
              >
                {{ set.name }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <button class="btn btn-primary" type="button" @click="addMetadata">
              {{ $t('Add') }}
            </button>
          </div>
        </div>
        <span v-else class="set-empty">
          {{ $t('No metadata available to add to item') }}
        </span>
      </div>
      <div
        v-if="currentMetadata.length > 0"
        :key="updated"
        class="collapsable"
        :class="{ 'collapsable--plain': hideHeader }"
      >
        <MetadataSet
          v-for="(metadataSet, metadataSetIndex) in currentMetadata"
          :key="`${metadataSet.id}-set`"
          :metadata="metadataSet"
          :read-only="readOnly"
          :fullpath="fullpath"
          :hide-header="hideHeader"
          @input="(e) => onMetadataSetChange(e, metadataSetIndex)"
          @saveAttributes="() => saveAttributes(metadataSetIndex)"
          @deleteMetadata="() => deleteMetadata(metadataSet)"
        />
      </div>
      <div v-else-if="hideHeader">
        <div class="text-center mb-4">
          <small>{{ $t('No metadata available') }}</small>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Loader from 'common/components/Loader';
import Header from './Header';
import _ from 'lodash';
import dayjs from 'dayjs';
import MetadataSet from './Metadata/MetadataSet.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    Loader,
    Header,
    MetadataSet,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    lastSelected: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: '',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullpath: '',
      setSelected: '',
      metadataSets: [],
      currentMetadata: [],
      updated: 0,
      toastSaveSuccess: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p role="alert">' +
          this.$t('Metadata saved successfully.') +
          '</p>',
        type: 'success',
      },
    };
  },
  computed: {
    ...mapGetters('files', ['getSidebarState']),
    loading() {
      return (
        this.$store.state.loading['files/getMetadataSets'] ||
        this.$store.state.loading['files/getMetadataValues'] ||
        this.$store.state.loading['files/getMetadataDefaultValues'] ||
        this.$store.state.loading['files/addSetToFileObj'] ||
        this.$store.state.loading['files/addSetToMultipleFiles'] ||
        this.$store.state.loading['files/saveMetadataAttr'] ||
        this.$store.state.loading['files/saveMultifileMetadataAttr'] ||
        this.$store.state.loading['files/removeMetadataSet']
      );
    },
    currentFile() {
      return this.$store.state.files.currentFile || {};
    },
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
    serverDateFormat() {
      return 'YYYY-MM-DD HH:mm:ss';
    },
    parentPath() {
      return this.currentFile.dirpath;
    },
    selectedFiles() {
      return this.$store.getters['files/getSidebarState']().selected.length;
    },
    selectedFilesNames() {
      const selected = this.$store.getters['files/getSidebarState']().selected;

      let fileNames = '';

      selected.forEach((item) => {
        fileNames = fileNames.length ? `${fileNames},${item.name}` : item.name;
      });

      return fileNames;
    },
    firstSelectedPath() {
      return this.getSidebarState()?.selected[0]?.path || '';
    },
    selectedArray() {
      return this.getSidebarState()?.selected;
    },
    lastUploadedTime() {
      return this.$store.state.files.lastUploadTime;
    },
    lastUploadedFile() {
      return this.$store.state.files.lastUploadedFile;
    },
  },
  watch: {
    selectedFiles() {
      this.getMetadataSets();
    },
    lastUploadedTime() {
      if (this.lastUploadedFile.path === this.currentFile.path) {
        this.getMetadataSets();
      }
    },
  },
  mounted() {
    this.fullpath = this.currentFile.path;
    this.getMetadataSets();
  },
  methods: {
    onMetadataSetChange(metadataSet, metadataIdx) {
      const currentMetadata = _.cloneDeep(this.currentMetadata);
      currentMetadata.splice(metadataIdx, 1, metadataSet);
      this.currentMetadata = currentMetadata;
    },
    resetState() {
      this.metadataSets = [];
      this.$set(this, 'currentMetadata', []);
      this.setSelected = '';
      this.fullpath = this.firstSelectedPath;
      this.updated = 0;
    },
    async getMetadataSets() {
      this.resetState();
      if (this.selectedFiles <= 1) {
        await this.proccessMetadataValues();
      }
      await this.proccessMetadataSets();
    },
    async proccessMetadataSets() {
      if (this.selectedFiles > 1) {
        const isNoWriteAccess = this.selectedArray.some(
          ({ canupload }) => canupload == 0
        );
        if (isNoWriteAccess) return this.metadataSets = [];
      }

      this.metadataSets = await this.$store.dispatch('files/getMetadataSets', {
        path: this.fullpath,
        multiplefiles: this.selectedFiles > 1,
      });

      const sets = this.metadataSets.data.metadataset;

      if (
        (sets instanceof Object && !Array.isArray(sets)) ||
        sets === undefined
      ) {
        this.metadataSets = [];

        if (sets instanceof Object) {
          this.metadataSets.push(sets);
        }
      } else if (Array.isArray(sets)) {
        this.metadataSets = sets;
      }

      if (this.metadataSets.length > 0) {
        this.setSelected = this.metadataSets[0].id;
      }
    },
    async proccessMetadataValues(setid = null) {
      let currentMetadata = await this.$store.dispatch(
        !setid ? 'files/getMetadataValues' : 'files/getMetadataDefaultValues',
        !setid ? this.fullpath : setid
      );

      if (currentMetadata[0] === undefined) {
        currentMetadata = [];
      }

      if (currentMetadata.length > 0) {
        currentMetadata = currentMetadata.map((set) => {
          set.expanded = false;
          set.changed = false;

          const fields = [
            'attributeid',
            'name',
            'description',
            'value',
            'datatype',
            'required',
            'disabled',
            'enumvalues',
          ];

          set.attributes = [];

          for (let b = 0; b < set.attributes_total; b++) {
            set.attributes[b] = {};

            for (let c = 0; c < fields.length; c++) {
              const field = fields[c];
              set.attributes[b][field] = set[`attribute${b}_${field}`];
              set.attributes[b].index = b;
              set.attributes[b].oldValue = set[`attribute${b}_value`];

              if (set.attributes[b].datatype === 7) {
                set.attributes[b].editing = '';
                set.attributes[b].updated = 0;
              }
            }
          }

          return set;
        });

        for (let i = 0; i < currentMetadata.length; i++) {
          for (let j = 0; j < currentMetadata[i].attributes.length; j++) {
            let value = currentMetadata[i].attributes[j].value;
            let datatype = this.getFieldDatatypeText(
              currentMetadata[i].attributes[j].datatype
            );

            if (
              typeof currentMetadata[i].attributes[j].description !== 'string'
            ) {
              currentMetadata[i].attributes[j].description = '';
            }

            if (datatype === 'date') {
              const now = dayjs(new Date()).format(this.serverDateFormat);
              currentMetadata[i].attributes[j].oldValue =
                typeof value === 'string'
                  ? dayjs(value).format(this.serverDateFormat)
                  : null;
              currentMetadata[i].attributes[j].value =
                typeof value === 'string'
                  ? dayjs(value).format(this.serverDateFormat)
                  : null;
            } else if (datatype === 'enum') {
              let enumValues =
                currentMetadata[i].attributes[j].enumvalues.split(',');

              const isValidValue = enumValues.some(
                (enumItem) => enumItem == value
              );

              currentMetadata[i].attributes[j].value = isValidValue
                ? value
                : enumValues[0];
              currentMetadata[i].attributes[j].oldValue = isValidValue
                ? value
                : enumValues[0];
            } else if (datatype === 'array') {
              if (typeof currentMetadata[i].attributes[j].value === 'object') {
                currentMetadata[i].attributes[j].value = '';
                currentMetadata[i].attributes[j].oldValue = '';
              } else {
                let value = String(currentMetadata[i].attributes[j].value);
                value.replace(/,( )?N\/A/gm, ''); //remove N/A element

                currentMetadata[i].attributes[j].value = value;
                currentMetadata[i].attributes[j].oldValue = value;
              }
            } else {
              if (_.isEmpty(value) && typeof value === 'object') {
                currentMetadata[i].attributes[j].value = 'N/A';
                currentMetadata[i].attributes[j].oldValue = 'N/A';
              }
            }
          }
        }

        this.$set(this, 'currentMetadata', currentMetadata);
      }
    },
    async addMetadata() {
      if (this.selectedFiles <= 1) {
        const response = await this.$store.dispatch('files/addSetToFileObj', {
          path: this.fullpath,
          setid: this.setSelected,
        });
        if (response.ok) {
          await this.getMetadataSets();
        } else {
          this.$toast.open({
            message: `<b>${this.$t('Error')}</b><p role="alert">${
              response.data.message
            }</p>`,
            type: 'error',
          });
        }
      } else {
        const response = await this.$store.dispatch(
          'files/addSetToMultipleFiles',
          {
            setid: this.setSelected,
            parentpath: this.parentPath,
            filenames: this.selectedFilesNames,
          }
        );

        if (response.ok) {
          await this.proccessMetadataValues(this.setSelected);
        } else {
          this.$toast.open({
            message: `<b>${this.$t('Error')}</b><p role="alert">${
              response.data.message
            }</p>`,
            type: 'error',
          });
        }
      }
      this.currentMetadata[this.currentMetadata.length - 1].expanded = true;
      this.updated++;
      this.$nextTick(() => {
        let firstInput = document.querySelectorAll('.meta-input')[0];
        firstInput.focus();
      });
    },
    dateChange(field, value) {
      // field.value = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    },
    fieldChanged(setidx) {
      this.currentMetadata[setidx].changed = false;
      for (let i = 0; i < this.currentMetadata[setidx].attributes.length; i++) {
        let value = this.currentMetadata[setidx].attributes[i].value;
        let oldValue = this.currentMetadata[setidx].attributes[i].oldValue;
        let datatype = this.getFieldDatatypeText(
          this.currentMetadata[setidx].attributes[i].datatype
        );
        if (datatype === 'date') {
          value = dayjs(value).format('X');
          oldValue = dayjs(oldValue).format('X');
        }
        if (value != oldValue) {
          this.currentMetadata[setidx].changed = true;
          break;
        }
      }
      this.updated++;
    },
    getValidValuePayload(value, datatype) {
      if (datatype === 'date') {
        const date = dayjs(value, this.serverDateFormat);
        return date.isValid() ? date.format(this.serverDateFormat) : '';
      }
      if (value === 'N/A' || (typeof value === 'object' && _.isEmpty(value)))
        return '';
      return value;
    },
    async saveAttributes(setidx) {
      let payload = {};

      if (this.selectedFiles <= 1) {
        payload = {
          fullpath: this.fullpath,
          setid: this.currentMetadata[setidx].id,
        };
      } else {
        payload = {
          setid: this.currentMetadata[setidx].id,
          parentpath: this.parentPath,
          filenames: this.selectedFilesNames,
        };
      }

      const metadataSet = this.currentMetadata[setidx];
      payload.attributes_total = metadataSet.attributes_total;

      metadataSet.attributes.forEach((attribute, i) => {
        let datatype = this.getFieldDatatypeText(attribute.datatype);
        let attributeid = attribute.attributeid;

        payload[`attribute${i}_attributeid`] = attributeid;
        payload[`attribute${i}_value`] = this.getValidValuePayload(
          attribute.value,
          datatype
        );
      });

      let response = await this.$store.dispatch(
        this.selectedFiles <= 1
          ? 'files/saveMetadataAttr'
          : 'files/saveMultifileMetadataAttr',
        payload
      );
      if (response.ok) {
        this.$toast.open(this.toastSaveSuccess);
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${
            response.data.message
          }</p>`,
          type: 'error',
        });
      }
      this.getMetadataSets();
    },
    getFieldDatatypeText(type) {
      const dataTypes = {
        1: 'text',
        2: 'int',
        3: 'decimal',
        4: 'boolean',
        5: 'date',
        6: 'enum',
        7: 'array',
        8: 'array-objects',
        9: 'kvp',
      };
      return dataTypes[type] || '';
    },
    getMetadataSetType(metadataset) {
      const metadataSetTypes = {
        1: 'built_in',
        2: 'default',
        3: 'custom',
      };
      return metadataSetTypes[metadataset] || '';
    },
    async deleteMetadata(metadata) {
      let res = await this.$store.dispatch('files/removeMetadataSet', {
        path: this.fullpath,
        setid: metadata.id,
      });
      if (res.ok) {
        this.$toast.open({
          message:
            '<b>' +
            this.$t('Success') +
            '</b><p role="alert">' +
            this.$t('Metadata set removed successfully!') +
            '</p>',
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b><p role="alert">${res.error}</p>`,
          type: 'error',
        });
      }
      await this.getMetadataSets();
      this.updated++;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'common/scss/_colors.scss';
.metadata-tab {
  .form-group.first {
    padding: 16px 0 0 0;

    .set-empty {
      font-size: 13px;
      display: block;
      color: var(--text-light);
    }

    label {
      font-size: 14px;
    }
  }

  button.btn {
    min-width: unset;
  }
}
.fas {
  color: var(--text-dark);
}

.fa-caret-up {
  vertical-align: middle;
}
.fa-caret-down {
  vertical-align: middle;
}

.mx-datepicker::v-deep {
  .mx-input:disabled,
  .mx-input.disabled {
    color: #666;
  }
}
</style>
