<template>
  <div class="left-tabbed-modal__mini-admin__users">
    <portal to="modal-subheader">
      <div
        class="
          users__portal-container
          flex-column flex-md-row
          align-items-md-center
        "
      >
        <div class="d-flex align-items-center col-12 col-md-5 p-0">
          <InputBox
            v-model="searchString"
            :placeholder="$t('Filter users...')"
            :prepends="[{ icon: 'filter' }]"
            :appends="[{ icon: 'times', action: () => (searchString = '') }]"
          />
        </div>

        <div
          class="
            d-flex
            align-items-center
            justify-content-between
            ml-md-4
            col
            p-0
            mt-3 mt-md-0
          "
        >
          <v-popover
            class="users__sort mr-3"
            :open="sortPopover === true"
            @hide="sortPopover = false"
          >
            <button type="button">
              <Icon family="far" name="sort-amount-down" />
              <span v-html="$t('Sort by <b>{sort}</b>', { sort: sortBy.label })"></span>
              <Icon family="fas" name="chevron-down" />
            </button>

            <template #popover>
              <ul class="left-tabbed-modal__mini-admin__users__sort-options">
                <li v-for="(sort, sortIndex) in sortOptions" :key="sortIndex">
                  <button v-close-popover class="btn" @click="sortBy = sort">
                    <Icon
                      :class="{ ['active']: sort.label === sortBy.label }"
                      family="fal"
                      name="check"
                    />
                    <span>{{ $t(sort.label) }}</span>
                  </button>
                </li>
              </ul>
            </template>
          </v-popover>

          <button class="btn btn-primary" @click="openAddUserModal" v-if="canInviteUser">
            <Icon family="fas" name="plus" />
            <span>{{ $t('Add User') }}</span>
          </button>
        </div>
      </div>
    </portal>

    <div class="users__users-wrapper">
      <Loader v-if="isLoading" loading full />
      
      <MiniAdminResetPassword
        :visible="isResetPasswordModalOpen"
        @close="() => showResetPasswordModal(false)"
      />
      
      <div
        v-if="!canReadUser && !isLoading"
        class="droppable-wrapper droppable-wrapper--empty"
      >
        <div class="empty-box">
          <Icon family="fas" name="exclamation-square" />
          <div class="empty-text" v-html="$t(`You do not have permission to read the user list,<br /> please ask the administrator to grant you access.`)">
          </div>
        </div>
      </div>
      <div
        v-for="(user, userIndex) in filteredUsers"
        :key="userIndex"
        class="users__user-container"
        v-else
      >
        <div class="avatar">
          <Avatar :user="user.username" />
        </div>

        <div class="user-info d-flex flex-column mx-2">
          <h5>{{ user.displayname }}</h5>
          <span>{{ user.email }}</span>
        </div>

        <div class="role-button" v-if="canUpdateUser">
          <v-popover
            :open="userRolePopover[userIndex] === true"
            :disabled="user.username === currentUser.peerid"
            @hide="userRolePopover[userIndex] = false"
          >
            <button
              :disabled="user.username === currentUser.peerid"
              class="btn"
            >
              <span>{{ $t(getUserRole(user).name) }}</span>
              <Icon family="fas" name="chevron-down" />
            </button>

            <template #popover>
              <ul class="left-tabbed-modal__mini-admin__users__user-roles">
                <li
                  v-for="(role, roleIndex) in userRoles.filter(
                    (roleFilter) =>
                      roleFilter.show &&
                      roleFilter.requirements.status !== getUserRole(user).requirements.status
                  )"
                  :key="roleIndex"
                >
                  <button
                    v-close-popover
                    class="btn"
                    @click="() => changeUserRole(user, role.requirements)"
                  >
                    <span>{{ $t(role.name) }}</span>
                  </button>
                </li>
              </ul>
            </template>
          </v-popover>
        </div>

        <div class="storage-usage mx-2">
          <span>{{ user.size }}</span>
        </div>

        <v-popover
          v-if="canUpdateUser"
          :open="moreOptionsPopover[userIndex] === true"
          class="more-options"
          @hide="moreOptionsPopover[userIndex] = false"
        >
          <button class="btn">
            <Icon family="far" name="ellipsis-h" />
          </button>

          <template #popover>
            <ul class="left-tabbed-modal__mini-admin__users__user-options">
              <li
                v-for="(option, optionIndex) in userOptions"
                :key="optionIndex"
              >
                <button
                  v-close-popover
                  class="btn"
                  @click="() => option.action(user)"
                >
                  <Icon :family="option.icon.family" :name="option.icon.name" />
                  <span>{{ $t(option.label) }}</span>
                </button>
              </li>
            </ul>
          </template>
        </v-popover>
      </div>
    </div>
  </div>
</template>

<script>
import InputBox from 'common/components/InputBox';
import Icon from 'common/components/Icon';
import Loader from 'common/components/Loader';
import Avatar from 'common/components/Avatar';
import MiniAdminResetPassword from '../Modals/MiniAdminResetPassword.vue';
import _ from 'lodash';
import Fuse from 'fuse.js';

import { userRoles, getUserRole } from '../../constants/miniAdmin';

export default {
  name: 'Users',
  components: { Avatar, Icon, Loader, InputBox, MiniAdminResetPassword },
  data() {
    return {
      userRolePopover: {},
      moreOptionsPopover: {},
      sortPopover: false,
      sortBy: { label: 'name', values: ['displayname'], type: ['asc'] },
      searchString: '',
      sortOptions: [
        { label: 'name', values: ['displayname'], type: ['asc'] },
        {
          label: 'role',
          values: ['adminstatus', 'status'],
          type: ['asc'],
        },
        { label: 'name desc', values: ['displayname'], type: ['desc'] },
        {
          label: 'role desc',
          values: ['adminstatus', 'status'],
          type: ['desc'],
        },
      ],
      userOptions: [
        {
          label: 'Reset Password',
          icon: {
            family: 'fal',
            name: 'lock-open',
          },
          action: (user) => {
            this.showResetPasswordModal(true, user);
          },
        },
        {
          label: 'Manage User',
          icon: {
            family: 'fal',
            name: 'user',
          },
          action: (user) => {
            this.openURI(`/ui/admin/index.html#user.${user.username}`)
          },
        },
      ],
    };
  },
  computed: {
    userRoles() {
      return userRoles;
    },
    getUserRole() {
      return getUserRole;
    },
    isResetPasswordModalOpen() {
      return this.$store.state.miniAdmin.resetPasswordModal.show;
    },
    isLoading() {
      return (
        this.$store.state.loading['miniAdmin/getUsers'] ||
        this.$store.state.loading['miniAdmin/changeUserRole']
      );
    },
    users() {
      return new Fuse(this.$store.state.miniAdmin.users, {
        threshold: 0.2,
        keys: ['displayname', 'email', 'username'],
      });
    },
    filteredUsers() {
      return this.searchString.trim()
        ? this.sortResults(
            this.users.search(this.searchString.trim()).map((user) => user.item)
          )
        : this.sortResults(this.$store.state.miniAdmin.users);
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    stats() {
      return this.$store.state.miniAdmin.stats;
    },
    permissions() {
      return this.stats?.userpermissions 
        ? this.stats.userpermissions.permissions
        : [];
    },
    canInviteUser() {
      return this.permissions.indexOf('users.create') > -1;
    },
    canUpdateUser() {
      return this.permissions.indexOf('users.update') > -1;
    },
    canReadUser() {
      return this.permissions.indexOf('users.read') > -1;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if(this.canReadUser) {
        await this.$store.dispatch('miniAdmin/getUsers');
      }
    },
    changeUserRole(user, requirements) {
      this.$store.dispatch('miniAdmin/changeUserRole', {
        profile: user.username,
        email: user.email,
        ...requirements,
      });
    },
    sortResults(list) {
      return list ? _.orderBy(list, this.sortBy.values, this.sortBy.type) : [];
    },
    openURI(uri) {
      window.open(uri, '_blank');
    },
    openAddUserModal() {
      this.$store.dispatch('miniAdmin/showAddUserModal', true);
    },
    showResetPasswordModal(visible, user) {
      this.$store.dispatch('miniAdmin/showResetPasswordModal', {
        visible: visible,
        username: user ? user.username : undefined,
      });
    },
  },
};
</script>

<style lang="scss">
.users__portal-container {
  display: flex;

  .form-group {
    padding: 0;
    margin: 0;
  }

  .input-group {
    width: 100%;
    height: 35px;
  }

  .input-group,
  input {
    width: 100%;
    height: 100%;
    border-radius: 100px !important;
  }

  .users__sort {
    display: flex;
    align-items: center;

    button {
      width: fit-content;
      display: flex;
      align-items: center;
      background: none;
      outline: none;
      border: none;
    }

    i {
      font-size: 12px;
      color: var(--text-muted) !important;
    }

    span {
      color: var(--text-medium);
      margin: 0 12px;
      font-size: 12px;
    }
  }

  button {
    font-size: 13px;
    width: 121px;
    height: 31px;

    i {
      margin-right: 10px;
      color: var(--color-primary--foreground) !important;
      opacity: 0.7;
    }
  }
}
</style>

<style lang="scss">
.left-tabbed-modal__mini-admin__users {
  .users__users-wrapper {
    display: flex;
    flex-direction: column;

    .users__user-container {
      display: flex;
      flex-direction: row;

      + .users__user-container {
        margin-top: 18px;
      }

      .avatar,
      img {
        width: 36px !important;
        height: 36px !important;
      }

      div.user-info {
        width: 200px;
        min-width: 0;

        h5 {
          margin: 0;
          font-size: 14px;
          font-weight: 700;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span {
          color: var(--text-light);
          font-size: 12px;
          font-weight: 500;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      div.role-button {
        display: flex;
        align-items: center;
        width: 120px;
        min-width:20%;
        justify-content:center;

        button {
          display: flex;
          align-items: center;
          border-radius: 4px;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          background: var(--bg-mid-light);
          min-height: 19px;

          span {
            font-size: 10px;
            margin-right: 4px;
            font-weight: 600;
            color: var(--text-medium) !important;
            text-transform: uppercase;
          }

          i {
            font-size: 10px;
            color: var(--text-muted) !important;
          }
        }
      }

      div.storage-usage {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;

        span {
          font-size: 12px;
          font-weight: 500;
        }
      }

      div.more-options {
        display: flex;
        flex: 1 1 0;
        align-items: center;
        justify-content: flex-end;

        button {
          border: none;
          background: none;

          i {
            color: var(--text-light) !important;
          }
        }
      }
    }
  }
}

ul.left-tabbed-modal__mini-admin__users__user-options {
  list-style: none;
  padding: 10px 0;
  margin: 0;

  li {
    &:hover {
      background: var(--bg-medium);
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      i {
        font-size: 13px;
        color: var(--text-light) !important;
        width: 16px;
        margin-right: 12px;
      }

      span {
        font-size: 13px;
        color: var(--text-dark) !important;
        font-weight: 500;
      }
    }
  }
}

ul.left-tabbed-modal__mini-admin__users__user-roles {
  list-style: none;
  min-width: 100%;
  padding: 10px 0;
  margin: 0;

  li {
    &:hover {
      background: var(--bg-medium);
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-right: 28px;
      padding-left: 28px;

      span {
        font-size: 10px;
        color: var(--text-dark) !important;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
}

ul.left-tabbed-modal__mini-admin__users__sort-options {
  list-style: none;
  min-width: 100%;
  padding: 10px 0;
  margin: 0;

  li {
    &:hover {
      background: var(--bg-medium);
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding-right: 28px;
      padding-left: 28px;

      i {
        font-size: 12px;
        color: var(--text-dark) !important;
        opacity: 0;
        margin-right: 12px;

        &.active {
          opacity: 1;
        }
      }

      span {
        font-size: 12px;
        color: var(--text-dark) !important;
        font-weight: 500;
      }
    }
  }
}
</style>
