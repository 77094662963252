<template>
  <div class="pop-bg">
    <h6 class="popover-title">
      {{ $t('Add additional info to this user')
      }}<Icon v-close-popover name="times" class="text-white float-right" />
    </h6>

    <div class="popover-content">
      <div class="form-group">
        <label for="namedisplay">Name</label>
        <input
          id="namedisplay"
          v-model="user.displayname"
          type="text"
          class="form-control form-control-sm"
          placeholder="Enter Name"
          autocomplete="no"
          :class="{ 'is-invalid': user.nameRequired == 1 && !user.displayname }"
          :disabled="!user.new"
          @blur="handleName"
        />
        <div class="invalid-feedback">{{ $t('Display name is required') }}</div>
      </div>
      <div class="form-group">
        <label for="email">Email</label>
        <input
          id="email"
          v-model="user.emailid"
          type="email"
          class="form-control form-control-sm"
          placeholder="Enter Email"
          autocomplete="no"
          :disabled="!user.new"
        />
      </div>
      <div v-if="user.new" class="form-group">
        <label v-if="showPhoneInput" for="phone">Phone</label>
        <PhoneInputBox
          v-if="showPhoneInput"
          v-model="phone"
          id="phone"
          ref="phoneInput"
          :disabled="!user.new"
          :validCharactersOnly="true"
          @blur="handleNumber"
          @keypress="checkInt"
          placeholder="Enter Number"
          :class="{
            'is-invalid':
              (user.phoneRequired == 1 && !user.phone) ||
              (user.phone && user.phone.length < 5),
          }"
        />

        <div class="invalid-feedback">{{ feedbackText }}</div>
      </div>
    </div>
    <div class="popover-footer">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        :disabled="!user.new"
        @click="saveUser()"
      >
        {{ $t('Save') }}</button
      >&nbsp;&nbsp;
      <button
        v-close-popover
        type="button"
        class="btn btn-sm btn-outline-primary"
      >
        {{ $t('Cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import _ from 'lodash';
import PhoneInputBox from 'common/components/PhoneInputBox';

export default {
  components: { Icon, PhoneInputBox },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      result: [],
      phone: '',
    };
  },
  computed: {
    showPhoneInput() {
      return this.$store.state.core.fullSystemStatus.smsforshareinvite === 1;
    },
    feedbackText() {
      let info = this.$store.state.core.customization.getPhoneNumberHint();
      if (_.isString(info)) {
        return info;
      }

      return this.$t('A valid phone number is required');
    },
  },
  methods: {
    saveUser() {
      this.handleName();
      this.handleNumber();
      const countryCode = this.$refs.phoneInput?.activeCountry.dialCode;
      this.user.phone = !!this.phone ? countryCode + this.phone : this.phone;
      this.$emit('addToShareList', this.user.index);
    },
    handleName() {
      if (this.user.displayname) {
        this.user.nameRequired = 0;
      }
    },
    handleNumber() {
      if (this.user.phone) {
        this.user.phoneRequired = 0;
      }
    },
    checkInt(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;

      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pop-bg {
  box-shadow: 0 0 60px var(--shadow-color);
  background-color: var(--bg-light);
}
.popover-title {
  width: 304px;
  height: 26px;
  background-color: #455b69;
  color: white;
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  padding: 6px 10px;
}
.popover-content {
  text-align: left;
  padding: 0 25px;
  color: var(--text-dark-blue);
  font-size: 12px;
  /*overflow-y : scroll;*/
  /*height: 200px;*/
}
.popover-footer {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: var(--bg-light);
  border-bottom: 1px solid var(--border-color);
  border-radius: 0 0 3px 3px;
  text-align: right;
}
</style>
