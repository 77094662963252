var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"enter-active-class":"animate__animated animate__slideInUp","leave-active-class":"animate__animated animate__slideOutDown"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobileMenu.open),expression:"mobileMenu.open"}],staticClass:"mobile-menu",class:{ 'mobile-menu--active': _vm.mobileMenu.open }},[(_vm.loading)?_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}):_c('div',[(_vm.selectedFile !== null)?_c('div',{staticClass:"header"},[(_vm.selectedFile[0].type === 'dir' && _vm.selectedFile.length <= 1)?_c('Icon',{staticClass:"menufile-icon menufile-icon--folder",attrs:{"family":"fas","name":"folder"}}):(
            _vm.selectedFile[0].type !== 'dir' && _vm.selectedFile.length <= 1
          )?_c('FileIcon',{staticClass:"menufile-icon",attrs:{"name":_vm.selectedFile[0].name,"item":_vm.selectedFile[0],"format":_vm.selectedFile[0].format,"path":_vm.selectedFile[0].path}}):_c('FileIcon',{staticClass:"menufile-icon",attrs:{"name":'multifiles',"format":'multifiles'}}),_c('div',{staticClass:"menufile-details"},[_c('h3',{staticClass:"name",class:{ 'name--multifiles': _vm.selectedFile.length > 1 }},[_vm._v(" "+_vm._s(_vm.selectedFile.length <= 1 ? _vm.selectedFile[0].name : _vm.$tc('{count} files selected', _vm.selectedFile.length))+" ")]),(_vm.selectedFile.length <= 1)?_c('div',{staticClass:"details"},[(!_vm.selectedFile[0].shareid)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedFile[0].type === 'dir' ? '' : _vm.selectedFile[0].size + ' - ')+" "+_vm._s(_vm.hasModificationDate ? _vm.$t('Modified ') + _vm.selectedFile[0].modified : 'Unmodified')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.selectedFile[0].lastaccess)+" "+_vm._s(_vm.selectedFile[0].recentactivitycount > 0 ? _vm.$tc( '{count} recent activity | {count} recents activities', _vm.selectedFile[0].recentactivitycount ) : _vm.$t('no recent activity'))+" ")])]):_vm._e()]),_c('div',{staticClass:"right-buttons"},[(_vm.selectedFile.length === 1)?_c('button',{staticClass:"btn btn--info",on:{"click":function($event){return _vm.toggleStarred(_vm.selectedFile[0])}}},[_c('Icon',{attrs:{"name":"star","family":_vm.mobileMenu.favorite ? 'fas' : 'fal'}})],1):_vm._e(),_c('button',{staticClass:"btn btn--info",on:{"click":_vm.toggleSidebar}},[_c('Icon',{attrs:{"name":"info-circle","family":"fas"}})],1),_c('button',{staticClass:"btn btn--close",on:{"click":_vm.close}},[_c('Icon',{attrs:{"name":"times"}})],1)])],1):_vm._e(),_c('div',{ref:"mobileMenuBody",staticClass:"body"},_vm._l((_vm.mobileMenu.actions),function(item,id){return (
            !(
              (_vm.mobileMenu.target &&
                item.disabled &&
                item.disabled(
                  _vm.selectedFile.length === 1
                    ? {
                        item: _vm.mobileMenu.item,
                        selected: _vm.mobileMenu.item,
                        meta: _vm.mobileMenu.target.meta,
                        isInRecycleFolder: _vm.isInRecycleFolder,
                        isLimitedUser: _vm.isLimitedUser,
                        isPublicShareView: _vm.isPublicShareView,
                        customization: _vm.customization,
                        systemStatus: _vm.systemStatus,
                        isLockedByOthers: _vm.isLockedByOthers(_vm.mobileMenu.item),
                        isImplicitlyLocked: _vm.isImplicitlyLocked(
                          _vm.mobileMenu.item
                        ),
                        isAuthenticated: _vm.user.authenticated,
                        isDRMExportEnabled:
                          _vm.fullSystemStatus['isdrmenabled'] === 1,
                      }
                    : {
                        item: _vm.selectedFile,
                        selected: _vm.mobileMenu.item,
                        isQuickEditEnabled: _vm.isQuickEditEnabled,
                        isContext: true,
                        downloadable: true,
                        isDRMExportEnabled:
                          _vm.fullSystemStatus['isdrmenabled'] === 1,
                      }
                )) ||
              (item.hidden &&
                item.hidden(
                  _vm.selectedFile.length === 1
                    ? {
                        item: _vm.mobileMenu.item,
                        selected: _vm.mobileMenu.item,
                        meta: _vm.mobileMenu.target.meta,
                        isInRecycleFolder: _vm.isInRecycleFolder,
                        isLimitedUser: _vm.isLimitedUser,
                        isPublicShareView: _vm.isPublicShareView,
                        metaSets: _vm.metaSets,
                        customization: _vm.customization,
                        systemStatus: _vm.systemStatus,
                        isLockedByOthers: _vm.isLockedByOthers(_vm.mobileMenu.item),
                        isImplicitlyLocked: _vm.isImplicitlyLocked(
                          _vm.mobileMenu.item
                        ),
                        isAuthenticated: _vm.user.authenticated,
                        msteams: _vm.msTeams,
                        hasSearchResults: _vm.mobileMenu.target.hasSearchResults,
                      }
                    : {
                        item: _vm.selectedFile,
                        selected: _vm.mobileMenu.item,
                        meta: _vm.mobileMenu.target.meta,
                        isQuickEditEnabled: _vm.isQuickEditEnabled,
                        isContext: true,
                        downloadable: true,
                        msteams: _vm.msTeams,
                      }
                ))
            )
          )?_c('div',{key:id,staticClass:"menu-item",class:{
            'menu-item--separator': item.seperator,
            'menu-item--collapsable': item.color,
            'menu-item--collapsable-openned': _vm.isChoosingColor && item.color,
          },attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.triggerAction(item.action, _vm.selectedFile)}}},[_c('div',{staticClass:"icon"},[(item.icon)?_c('Icon',{attrs:{"name":item.icon,"family":item.iconFamily || 'fal'}}):_vm._e()],1),_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t( typeof item.name === 'string' ? item.name : item.name(_vm.mobileMenu.target) ))+" ")]),(item.color)?_c('div',{staticClass:"collapse-toggle"},[_c('Icon',{attrs:{"name":"chevron-down"}})],1):_vm._e(),_c('div',{staticClass:"collapse-content"},[_c('ColorTag',{attrs:{"child":_vm.mobileMenu.component,"current-color":_vm.mobileMenu.item.colortag},on:{"onItemContextClick":_vm.triggerAction}})],1)]):_vm._e()}),0)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }