export const officeExt = [
  'docx',
  'pptx',
  'xlsx',
  'doc',
  'ppt',
  'xls',
  'xlsm',
  'odt',
  'odg',
  'ods',
  'csv',
  'odp',
];

export const previewExt = ['txt', 'pdf', 'log', 'md'];

export const PREVIEWABLEDOC_CLOUD = [
  'ai',
  'pdf',
  'txt',
  'doc',
  'docx',
  'pdf',
  'ppt',
  'pptx',
  'xls',
  'xlsx',
  'xlsm',
  'odt',
  'ods',
  'odg',
  'odp',
];
