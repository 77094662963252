var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Themed',{attrs:{"background-color":"","text-color":""}},[_c('div',{staticClass:"container mb-5 comment p-0"},[(_vm.fullPath !== '')?_c('div',{staticClass:"col-md-12 p-0 m-0 mt-4"},[_c('h5',{staticClass:"comment-header",staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.headerText))+" ")])]):_vm._e(),(_vm.fullPath !== '')?_c('div',[_c('Mentionable',{staticClass:"col-md-12 m-0 p-0",class:'test',attrs:{"keys":['@'],"items":_vm.mentions,"offset":"6","show-default-avatar":true},on:{"open":function($event){return _vm.getMentionables($event)},"search":function($event){return _vm.getMentionables($event)}},scopedSlots:_vm._u([{key:"no-result",fn:function(){return [_c('div',{staticClass:"dim"},[_vm._v(" "+_vm._s(_vm.loading ? _vm.$t('Loading...') : _vm.$t('No results'))+" ")])]},proxy:true},{key:"item-@",fn:function({ item }){return [_c('div',{staticClass:"mention"},[_c('Avatar',{staticClass:"avatar",attrs:{"user":item.name}}),_c('span',{staticClass:"username"},[_vm._v(" @"+_vm._s(item.name)+" ")]),(typeof item.displayname === 'string')?_c('span',{staticClass:"displayname"},[_vm._v(" "+_vm._s(item.displayname)+" ")]):_vm._e()],1)]}}],null,false,230936916)},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.text),expression:"text"}],ref:"mentionableTextarea",staticClass:"col-md-12 mb-2 form-control comment-input",class:{ ['is-invalid']: _vm.isOverflowingCharLimit },attrs:{"rows":"3","placeholder":_vm.$t(
              _vm.readonly
                ? 'Comment addition not allowed for read only share!'
                : 'What\'s on your mind?'
            ),"aria-label":_vm.$t('What\'s on your mind?'),"disabled":_vm.readonly},domProps:{"value":(_vm.text)},on:{"keypress":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.addComment.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;if($event.ctrlKey||$event.altKey||$event.metaKey)return null;return _vm.addNewLine.apply(null, arguments)}],"input":function($event){if($event.target.composing)return;_vm.text=$event.target.value}}}),_vm._v(" "),_c('small',{staticClass:"input-length-counter",class:{
            ['input-length-counter--invalid']: _vm.isOverflowingCharLimit,
          }},[_vm._v(_vm._s(_vm.text.length)+"/"+_vm._s(_vm.charLimit))])]),_c('div',{staticClass:"col-md-12 m-0 p-0"},[_c('button',{staticClass:"float-left btn btn-primary btn-sm px-4 mt-2",attrs:{"disabled":!_vm.canSubmit},on:{"click":_vm.addComment}},[_vm._v(" "+_vm._s(_vm.$t(_vm.submitMessage))+" ")]),(_vm.editMode)?_c('a',{staticClass:"mt-1 float-right btn btn-sm btn-link",on:{"click":_vm.disableEditMode}},[_vm._v(" "+_vm._s(_vm.$t('Cancel'))+" ")]):_vm._e()])],1):(_vm.shareId !== '')?_c('div'):_c('div',[_c('p',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t('Please select an item to add comments'))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }