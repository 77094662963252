<template>
  <DialogModal
    :stick-top="true"
    :visible="isOpen"
    :title="$t(`Share Options`)"
    :class-name="'shareoptions-dialog small'"
    :buttons="buttonArrayModified"
    @close="this.closeDialog"
  >
    <Loader :loading="loading" full />
    <div class="d-flex justify-content-between bottom-options">
      <div class="d-flex justify-content-left">
        <Checkbox
          :checked="isDisableFileChangeNotifications"
          :aria-label="$t('Disable File Change Email Notifications')"
          @input="updateNotificationFilterForSharePath"
        />
        <div class="px-sm-1" />
        <span class="terms-agree">
          <a @click="updateNotificationFilterForSharePath()"
            >{{ $t('Disable File Change Email Notifications') }}
          </a>
        </span>
      </div>
    </div>
  </DialogModal>
</template>
<script>
import DialogModal from 'common/components/DialogModal';
import Checkbox from 'common/components/Checkbox';
import Loader from 'common/components/Loader';

export default {
  components: {
    DialogModal,
    Checkbox,
    Loader,
  },
  data() {
    return {
      loading: false,
      isOpen: false,
      isDisableFileChangeNotifications: false,
      isLeaveShare: false,
      file: {},
    };
  },
  computed: {
    buttonArray() {
      return [
        {
          label: this.$t('Leave Share'),
          link: true,
          left: true,
          leaveShareBtn: true,
          callback: () => {
            this.leaveShare();
          },
          hasConfirm: true,
          confirmLabel: this.$t('Are you sure you want to leave the share'),
          confirmBtn1: this.$t('Yes'),
          confirmBtn2: this.$t('No'),
        },
        {
          label: 'OK',
          outline: true,
          callback: () => {
            this.closeDialog();
          },
        },
      ];
    },
    buttonArrayModified() {
      if (!this.isLeaveShare) {
        return this.buttonArray.filter((btn) => !btn.leaveShareBtn);
      }
      return this.buttonArray;
    },
  },
  methods: {
    async open(item, showLeaveReshare) {
      this.loading = true;
      this.isOpen = true;
      this.isLeaveShare = showLeaveReshare;
      this.file = item;
      const response = await this.$store.dispatch(
        'share/isNotificationFilterSetForSharePath',
        { path: this.file.path }
      );
      if (response.ok) {
        this.isDisableFileChangeNotifications = true;
      }
      this.loading = false;
    },
    async updateNotificationFilterForSharePath() {
      this.loading = true;
      var response;
      if (this.isDisableFileChangeNotifications) {
        response = await this.$store.dispatch(
          'share/removeNotificationFilterForSharePath',
          { path: this.file.path }
        );
      } else {
        response = await this.$store.dispatch(
          'share/addNotificationFilterForSharePath',
          { path: this.file.path }
        );
      }
      if (response.ok) {
        this.isDisableFileChangeNotifications = !this
          .isDisableFileChangeNotifications;
      }
      this.loading = false;
    },
    async leaveShare() {
      const response = await this.$store.dispatch('share/leaveShare', {
        path: this.file.path,
      });
      if (response.ok) {
        this.$toast.open({
          message: `<b> ${this.$t('Success')}</b>
            <p role="alert">${this.$t(
              'You have been removed from this shared item'
            )}</p>`,
          type: 'success',
        });
      } else {
        this.$toast.open({
          message: `<b>${this.$t('Error')}</b>
            <p role="alert">${response.error}</p>`,
          type: 'error',
        });
      }
      this.$emit('refreshFolder');
      this.closeDialog();
    },
    closeDialog() {
      this.isOpen = false;
    },
  },
};
</script>
