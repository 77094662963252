var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"transactions-list-item"},[_c('div',{staticClass:"transaction-icon"},[_c('Icon',{attrs:{"family":"fas","name":"trash-alt"}}),(
        _vm.transaction.status !== 'processing' &&
        _vm.transaction.status !== 'preparing'
      )?_c('div',{staticClass:"transaction-icon-status",class:{
        [`transaction-icon-status--error`]: _vm.transaction.status === 'failed',
        [`transaction-icon-status--cancelled`]:
          _vm.transaction.status === 'cancelled',
      }},[(_vm.transaction.status === 'failed')?_c('Icon',{attrs:{"family":"fas","name":"times"}}):(_vm.transaction.status === 'cancelled')?_c('Icon',{attrs:{"family":"fas","name":"ban"}}):_c('Icon',{attrs:{"family":"fas","name":"check"}})],1):_vm._e()],1),_c('div',{staticClass:"transaction-info"},[_c('span',{staticClass:"transaction-info-title"},[_vm._v(" "+_vm._s(_vm.transaction.name)+" "),(_vm.transaction.status === 'processing')?_c('small',{attrs:{"role":"status"}},[_vm._v(_vm._s(_vm.$t(_vm.transaction.undo ? 'Undoing Remove' : 'Removing')))]):_c('small')]),(_vm.transaction.status === 'preparing')?_c('span',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('Preparing to Remove Share'))+" ")]):(_vm.transaction.status === 'processing')?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress-bar w-10 indeterminate",attrs:{"role":"progressbar","aria-valuenow":"75","aria-valuemin":"0","aria-valuemax":"100","aria-valuetext":_vm.$t('Removing share...')}})]):(_vm.transaction.message)?_c('span',{staticClass:"description",attrs:{"role":"status"}},[_vm._v(_vm._s(_vm.transaction.message))]):(_vm.transaction.status === 'cancelled')?_c('span',{staticClass:"description",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('You have cancelled this operation'))+" ")]):_c('span',{staticClass:"description",attrs:{"role":"alert"}},[_vm._v(_vm._s(_vm.$t('Share Successfully Removed')))])]),_c('div',{staticClass:"transaction-actions"},[(_vm.transaction.status === 'completed' && !_vm.transaction.undo && false)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ delay: 1000, content: 'Undo operation' }),expression:"{ delay: 1000, content: 'Undo operation' }"}],staticClass:"btn btn-icon",attrs:{"aria-label":_vm.$t('Undo operation')},on:{"click":_vm.undo}},[_c('Icon',{attrs:{"family":"fas","name":"undo"}}),_c('Icon',{staticClass:"icon-overlay",attrs:{"family":"fas","name":"undo"}})],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }