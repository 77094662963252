<template>
  <div class="item-icon">
    <Icon family="fas" :name="iconName" />
  </div>
</template>
<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    iconName: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.item-icon {
  position: absolute;
  right: 14px;
  bottom: -1px;
  color: #465c6a;
}
</style>
