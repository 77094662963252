var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('Loader',{attrs:{"loading":_vm.loading,"full":""}}):_vm._e(),_c('table',{staticClass:"table table--properties"},[_c('caption',[_vm._v(" "+_vm._s(_vm.caption)+" ")]),_c('thead',[_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Entry')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('File Size')))]),_c('th',{attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('File Count')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Folder Count')))]),_c('th',{staticClass:"centered",attrs:{"scope":"col"}},[_vm._v(_vm._s(_vm.$t('Actions')))])]),_c('tbody',[_c('tr',[_c('th',{staticClass:"title",attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t('Live')))]),_c('td',[_vm._v(" "+_vm._s(_vm.$tc('{count} File | {count} Files', _vm.currentData.livefilessizeonly))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc( '{count} Thumb | {count} Thumbs', _vm.currentData.livethumbssizeonly ))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc('{count} Total', _vm.currentData.livesize))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$tc('{count} File | {count} Files', _vm.currentData.livefilesonly))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc('{count} Thumb | {count} Thumbs', _vm.currentData.livethumbsonly))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$tc('{count} Total', _vm.currentData.livefiles))+" ")]),_c('td',{staticClass:"centered"},[_vm._v(_vm._s(_vm.currentData.livefolders))]),_c('td')]),_c('tr',[_c('th',{staticClass:"title",attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t('Previous Versions')))]),_c('td',[_vm._v(_vm._s(_vm.currentData.versionedsize))]),_c('td',[_vm._v(_vm._s(_vm.currentData.versionedfiles))]),_c('td',{staticClass:"centered"},[_vm._v("-")]),_c('td',{staticClass:"centered"},[(_vm.currentData.versionedfiles > 0)?_c('v-popover',{staticClass:"action-btn"},[_c('button',[_c('Icon',{attrs:{"name":"times","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","button-type":"danger","buttons":[
                {
                  label: `Clear`,
                  callback: function () {
                    _vm.clearVersionedFiles();
                  },
                },
                { label: `Cancel`, outline: true, callback: function () {} },
              ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(`Are you sure you want to clear the previous versions?`))+" ")])])],1):_vm._e()],1)]),_c('tr',[_c('th',{staticClass:"title",attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(_vm.$t('Partial Incomplete Uploads'))+" ")]),_c('td',[_vm._v(_vm._s(_vm.currentData.incompletesize))]),_c('td',[_vm._v(_vm._s(_vm.currentData.incompletefiles))]),_c('td',{staticClass:"centered"},[_vm._v("-")]),_c('td')]),(_vm.isRoot)?_c('tr',[_c('th',{staticClass:"title",attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t('Deleted Files')))]),_c('td',[_vm._v(_vm._s(_vm.currentData.recyclesize))]),_c('td',[_vm._v(_vm._s(_vm.currentData.recyclefiles))]),_c('td',{staticClass:"centered"},[_vm._v(_vm._s(_vm.currentData.recyclefolders))]),_c('td',{staticClass:"centered"},[_c('button',{staticClass:"action-btn",attrs:{"href":"#"},on:{"click":_vm.goToRecyclebin}},[_c('Icon',{attrs:{"name":"eye","family":"fas"}})],1),(_vm.currentData.recyclefiles > 0)?_c('v-popover',{staticClass:"action-btn",class:{ disabled: _vm.systemStatus.clearrecyclebin === 0 }},[_c('button',[_c('Icon',{attrs:{"name":"times","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","button-type":"danger","buttons":[
                {
                  label: `Clear`,
                  callback: function () {
                    _vm.clearRecyclebin();
                  },
                },
                { label: `Cancel`, outline: true, callback: function () {} },
              ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t(`Are you sure you want to clear the deleted files?`))+" ")])])],1):_vm._e()],1)]):_vm._e(),_c('tr',[_c('th',{staticClass:"title",attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.$t('Total')))]),_c('td',[_vm._v(_vm._s(_vm.currentData.totalsize))]),_c('td',[_vm._v(_vm._s(_vm.currentData.totalfiles))]),_c('td',{staticClass:"centered"},[_vm._v(_vm._s(_vm.currentData.totalfolders))]),_c('td')])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }