<template>
  <div class="file-preview-item file-preview-item--default">
    <div class="file-preview-item-info">
      <Icon
        v-if="file.type === 'dir' || file.type === 'folder'"
        name="folder"
        family="fas"
        class="file-icon"
      />

      <FileIcon v-else :name="file.name" :item="file" />
      <h4>{{ file.name }}</h4>
      <p v-if="file.size && file.type !== 'dir' && file.type !== 'folder'">
        {{ file.size }}
      </p>
      <a
        v-if="downloadAllowed"
        class="btn btn-primary"
        :class="{ 'mt-4': !file.size }"
        tabindex="0"
        @click="$emit('download')"
      >
        <Icon family="fas" name="download" class="mr-2" />
        {{ $t('zip_preview.nested_preview.download') }}
      </a>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
export default {
  components: {
    Icon,
    FileIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    downloadAllowed() {
      return (
        this.file.type !== 'dir' &&
        this.file.type !== 'folder' &&
        this.canDownload
      );
    },
  },
};
</script>
