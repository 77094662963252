var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.fileType === 'video')?_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:({ toggle: ['space'], mute: ['m'] }),expression:"{ toggle: ['space'], mute: ['m'] }"}],staticClass:"file-preview-item file-preview-item--video",class:{ ['file-preview-item--loaded']: !_vm.isLoading },on:{"shortkey":_vm.onShortcut}},[(_vm.isLoading)?_c('Loader'):_c('video',{ref:"video",attrs:{"oncontextmenu":_vm.file.candownload ? '' : 'return false',"preload":"metadata","controls":"","src":_vm.blob}})],1):_vm._e(),_c('div',{staticClass:"file-preview-item file-preview-item--default mt-5",class:{ 'pb-0': _vm.hideDownloadButton }},[_c('div',{staticClass:"file-preview-item-info"},[(_vm.file.type === 'dir' || _vm.file.type === 'folder')?_c('Icon',{staticClass:"file-icon",attrs:{"name":"folder","family":"fas"}}):(_vm.fileType !== 'video')?_c('FileIcon',{attrs:{"format":_vm.file.format,"item":_vm.file}}):_vm._e(),_c('h4',[_vm._v(_vm._s(_vm.file.name))]),(_vm.file.size && _vm.file.type !== 'dir' && _vm.file.type !== 'folder')?_c('p',[_vm._v(" "+_vm._s(_vm.file.size)+" ")]):_vm._e(),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.disableDownloadText),expression:"disableDownloadText"}]},[(_vm.fileType === 'video' && _vm.file.type !== 'folder')?_c('a',{staticClass:"btn btn-primary mr-1",class:{
            'mt-4': !_vm.file.size,
            disabled: _vm.disableDownload || !_vm.file.candownload,
          },on:{"click":_vm.toggle}},[_c('Icon',{attrs:{"family":"fas","name":"play"}}),_vm._v(" Play")],1):_vm._e(),(
            _vm.file.type !== 'dir' &&
            _vm.file.type !== 'folder' &&
            !_vm.hideDownloadButton
          )?_c('a',{staticClass:"btn btn-primary",class:{
            'mt-4': !_vm.file.size,
            disabled: _vm.disableDownload || !_vm.file.candownload,
          },on:{"click":function($event){return _vm.$emit('download')}}},[_c('Icon',{attrs:{"family":"fas","name":"download"}}),_vm._v(" Download File")],1):_vm._e()])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }