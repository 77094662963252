<template>
  <div class="zip-viewer-file-list">
    <div class="zip-viewer-file-list__body">
      <ZipViewerFileListItem
        v-if="newFolderInputVisible"
        :is-new-folder="true"
        :item="null"
        @create-new-folder="(name) => $emit('create-new-folder', name)"
      />
      <RecycleScroller
        v-slot="{ item, index }"
        :items="items"
        key-field="fullentrypath"
        :item-size="60"
        page-mode
      >
        <ZipViewerFileListItem
          :item="item"
          :can-download="canDownload"
          :can-upload="canUpload"
          @browse="(path) => $emit('browse', path)"
          @delete-item="(item) => $emit('delete-item', item)"
          @download-item="(item) => $emit('download-item', item)"
          @preview-item="(item) => $emit('preview-item', { items, index })"
          @toggle-mobile-menu="(item) => toggleMobileMenu(item, index)"
        />
      </RecycleScroller>
    </div>
    <!-- region Mobile -->
    <ZipViewerMobileMenu :open="mobileMenuVisible" @close="toggleMobileMenu">
      <template #header>
        <ZipViewerMobileActionsListHeader
          v-if="mobileMenuSelectedItem"
          :item="mobileMenuSelectedItem"
          @close="toggleMobileMenu"
        />
      </template>
      <template #body>
        <ZipViewerMobileMenuActionList
          v-if="mobileMenuSelectedItem"
          :actions="fileActions"
          :params="{
            item: mobileMenuSelectedItem,
            canDownload,
            canUpload,
          }"
          @trigger-action="triggerAction"
          @close="toggleMobileMenu"
        />
      </template>
    </ZipViewerMobileMenu>
    <!-- endregion -->
  </div>
</template>

<script>
import fileActions from '../Constants/fileActions';
import ZipViewerFileListItem from './ZipViewerFileListItem';
import ZipViewerMobileMenu from '../ZipViewerMobileMenu';
import ZipViewerMobileActionsListHeader from './ZipViewerMobileActionsListHeader';
import ZipViewerMobileMenuActionList from '../ZipViewerMobileMenuActionList';
import { RecycleScroller } from 'vue-virtual-scroller';

export default {
  name: 'ZipViewerFileList',
  components: {
    ZipViewerFileListItem,
    ZipViewerMobileMenu,
    ZipViewerMobileActionsListHeader,
    ZipViewerMobileMenuActionList,
    RecycleScroller,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    newFolderInputVisible: {
      type: Boolean,
      required: true,
    },
    canUpload: {
      type: Boolean,
      required: true,
    },
    canDownload: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      mobileMenuVisible: false,
      mobileMenuSelectedItem: null,
      mobileMenuSelectedItemIndex: null,
      fileActions,
    };
  },
  methods: {
    toggleMobileMenu(item, index) {
      this.mobileMenuVisible = !this.mobileMenuVisible;
      this.mobileMenuSelectedItem = item;
      this.mobileMenuSelectedItemIndex = index;
    },
    triggerAction(action, params) {
      let param;
      if (action === 'browse') {
        param = params.item.name;
      } else if (action === 'preview-item') {
        param = { items: this.items, index: this.mobileMenuSelectedItemIndex };
      } else {
        param = params.item;
      }
      this.$emit(action, param);
    },
  },
};
</script>
