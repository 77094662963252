<template>
  <div>
    <DatePicker
      v-model="field.value"
      :custom-input="field.attributeid"
      date-utc
      server-format-type
      type="date"
      :disabled="metadata.write !== 1 || readOnly"
      no-min-date
      @change="
        (e) => $emit('input', { ...field, value: e == 'Invalid Date' ? '' : e })
      "
    />
  </div>
</template>

<script>
import DatePicker from 'common/components/NewDatePicker';
import dataTypeMixin from './_dataTypeMixin';

export default {
  components: {
    DatePicker,
  },
  mixins: [dataTypeMixin],
  computed: {
    systemStatus() {
      return this.$store.state.core.fullSystemStatus;
    },
  },
  watch: {
    field: {
      deep: true,
      immediate: true,
      handler() {
        this.onFieldChange();
      },
    },
  },
  methods: {
    onFieldChange() {
      let changed = String(this.field.value) != String(this.field.oldValue);
      this.$emit('onFieldChange', {
        id: this.field.attributeid,
        changed,
        name: 'date',
      });
    },
  },
};
</script>
