<template>
  <a
    v-tooltip="{
      delay: { show: 1000, hide: 0 },
      offset: -5,
      content: tooltip,
    }"
    v-a11ybutton="onClick"
    tabindex="0"
    class="navbar-icon"
    :class="{ disabled }"
    @click="onClick"
  >
    <Icon family="fas" :name="icon" />
  </a>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: true,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
