<template>
  <DialogModal
    :visible="isOpen"
    :padding="false"
    class="version-modal"
    size="big"
    :title="$t('Previous Versions')"
    :buttons="[
      {
        label: $t('Download All Versions'),
        callback: downloadAllVersions,
      },
    ]"
    color="gray"
    position="top"
    @close="closeModal"
  >
    <FileVersionsTable :item="file" :versions-file="versionsFile" />
  </DialogModal>
</template>

<script>
import DialogModal from 'common/components/DialogModal';
import FileVersionsTable from '../Tables/FileVersionsTable';
import { mapActions } from 'vuex';

export default {
  components: {
    FileVersionsTable,
    DialogModal,
  },
  data() {
    return {
      versionsFile: {},
      isOpen: false,
      file: {},
    };
  },
  methods: {
    ...mapActions({
      downloadVersions: 'files/downloadAllVersions',
    }),
    async open(item) {
      this.isOpen = true;
      this.file = item;
    },
    async downloadAllVersions() {
      const { status, message } = await this.downloadVersions(this.file);
      if (status == false) this.openErrorToast(message);
    },
    openErrorToast(msg) {
      this.$toast.open({
        message:
          '<b>' + this.$t('Error') + '</b><p role="alert">' + msg + '</p>',
        type: 'error',
      });
    },
    closeModal() {
      this.isOpen = false;
    },
  },
};
</script>
