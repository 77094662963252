/**
 * Rteurns if a date is in last 24 hours
 * @param {type} date
 * @returns {Boolean}
 */
export function last24hours(modDate) {
    let date = new Date(modDate);
    let timeStamp = Math.round(new Date().getTime() / 1000);
    let timeStampYesterday = timeStamp - (24 * 3600);
    return date >= new Date(timeStampYesterday * 1000).getTime();
}