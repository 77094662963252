<template>
  <div class="loader" v-bind:class="{ [`loader--full`]: full, [`active`]: loading }">
    <div class="loader-content">
      <LoaderIllustration />
      <p v-if="title" aria-hidden="true">{{title}}</p>
    </div>
  </div>
</template>
<script>

import LoaderIllustration from '../assets/loader.svg';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    full: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
  },
  components: {
    LoaderIllustration
  }
}
</script>
