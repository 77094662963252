export default {
  methods: {
    lockedRead({ type, lockuserid, locked, lockreadlock }) {
      if (locked) {
        if (type == 'dir') {
          return lockreadlock
            ? this.$t('file_list.lock.folder_locked_read_blocked', {
                user: lockuserid,
              })
            : this.$t('file_list.lock.folder_locked_read_allowed', {
                user: lockuserid,
              });
        } else {
          return lockreadlock
            ? this.$t('file_list.lock.file_locked_read_blocked', {
                user: lockuserid,
              })
            : this.$t('file_list.lock.file_locked_read_allowed', {
                user: lockuserid,
              });
        }
      } else return '';
    },
  },
};
