<template>
  <div class="filter-input">
    <InputBox
      v-model="filterTerm"
      class="filter-input-box"
      :placeholder="placeholder"
      :aria-label="placeholder"
      :auto-complete="false"
      :prepends="prepends"
      form-size="sm"
      @input="onInputChange"
    />
  </div>
</template>

<script>
import InputBox from 'common/components/InputBox';

import debounce from 'lodash/debounce';

export default {
  components: {
    InputBox,
  },
  props: {
    icon: { type: String, default: 'search' },
    placeholder: { type: String, default: 'search' },
  },
  data() {
    return {
      prepends: [
        {
          icon: this.icon,
          iconBold: false,
        },
      ],
      filterTerm: '',
    };
  },
  methods: {
    onInputChange: debounce(function () {
      this.$emit('onFilter', this.filterTerm);
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.filter-input {
  flex: 1;

  &-box {
    margin: 0;
    padding: 0;

    ::v-deep .input-group {
      border-radius: 15px;

      input {
        margin-right: 15px;
      }
    }
  }
}
</style>
