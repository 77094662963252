<template>
  <div class="sub-option">
    <span v-if="!isMobile && !isTablet">{{ $t('Allow') }}</span>
    <select v-model="content" @change="onPermissionChange">
      <option
        v-for="(permission, i) in filtredPermissions"
        :key="i"
        :value="permission"
      >
        {{ $t(permission.label) }}
      </option>
    </select>
    <button
      v-if="content.label == 'Upload Only' && uploadForm"
      v-tooltip.right="$t('Upload Form')"
      class="btn shadow-none btn-outline-secondary btn-sm ml-4"
      @click="$emit('showUploadFormDialog')"
    >
      <Icon name="file-import" family="fas" />
    </button>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';
const filePermissions = ['View + Download', 'View Only'];
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Icon,
  },
  mixins: [responsivenessMixin],
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      },
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    uploadForm: {
      type: Boolean,
      default: false,
    },
    isDir: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: this.value,
    };
  },
  computed: {
    filtredPermissions() {
      return this.isDir
        ? this.permissions
        : this.permissions.filter(({ label }) =>
            filePermissions.includes(label)
          );
    },
  },
  methods: {
    onPermissionChange() {
      this.$emit('input', this.content);
    },
  },
};
</script>
