var render = function render(){var _vm=this,_c=_vm._self._c;return _c('VPopover',{ref:"popover",class:'sample',attrs:{"popover-class":_vm.popoverClass,"container":_vm.container,"placement":_vm.placement,"offset":_vm.offset,"open":_vm.open},on:{"show":() => {
      this.isOpen = true;
      _vm.$emit('show', true);
    },"hide":() => {
      this.isOpen = false;
      _vm.$emit('hide', true);
    }},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.handleKeydownOpen.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeydownOpen.apply(null, arguments)}]}},[_vm._t("default",function(){return [_c('div',{ref:"trigger"},[_c('Icon',{class:_vm.triggerBtnClass,attrs:{"name":_vm.triggerBtn,"family":_vm.triggerIconFamily,"aria-label":_vm.labelAria,"tabindex":"0","role":"button"},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;return _vm.handleKeydownOpen.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeydownOpen.apply(null, arguments)}],"focus":function($event){return _vm.$emit('focus')},"blur":function($event){return _vm.$emit('blur')}}})],1)]}),_c('template',{slot:"popover"},[_c('div',{ref:"dropdown",staticClass:"dropdown-wrapper"},[_vm._l((_vm.listItems),function(item,index){return _c('div',[(item.isTitle === true)?_c('div',{staticClass:"dropdown-item-title"},[_vm._v(" "+_vm._s(item.name)+" ")]):(
            !((_vm.target && item.hidden && item.hidden(_vm.target)) || item.isTitle)
          )?_c('div',{key:index,staticClass:"dropdown-item",class:{
            'has-seperator':
              item.seperator === true ||
              (item.conditionalSeperator &&
                item.conditionalSeperator(_vm.target)),
            'dropdown-item--disabled':
              _vm.target && item.disabled && item.disabled(_vm.target),
            [`dropdown-item-focus-${index}`]: true,
            [item.id]: item.id,
          },attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.triggerAction(item.action, item[_vm.paramName])},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.triggerAction(item.action, item[_vm.paramName])},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.triggerAction(item.action, item[_vm.paramName])}]}},[(item.icon)?_c('Icon',{attrs:{"name":item.icon,"family":item.iconFamily || 'fal'}}):_vm._e(),_c('span',{staticClass:"popover-text"},[_vm._v(_vm._s(_vm.$t( typeof item.name === 'string' ? item.name : item.name(_vm.target) ))+" "),(item.shortKey)?_c('span',{staticClass:"shortkey float-right text-capitalize"},[_vm._v(" "+_vm._s(item.shortKey))]):_vm._e()]),(item.counter)?_c('div',{staticClass:"dropdown-item__counter badge badge-primary"},[_vm._v(" "+_vm._s(item.counter)+" ")]):_vm._e()],1):_vm._e()])}),_vm._t("after")],2)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }