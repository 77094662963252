const ctrlCmd = window.navigator.platform === 'MacIntel' ? 'cmd' : 'ctrl';

import {
  getFileType,
  isOffice,
  canEditFileType,
  getParams,
  isExtensionBlackListed
} from 'common/utils/files';

import { WOPIClients, GoogleDocsFormats } from './WOPI';

import _ from 'lodash';

import { PREVIEWABLEDOC_CLOUD } from 'common/utils/fileExtensions';

export default [
  {
    name: ({ item }) =>
      item.type === 'dir' || item.ext === 'zip' ? 'Open' : 'Preview',
    icon: 'eye',
    action: 'previewFile',
    shortKey: 'enter',
    listOnly: false,
    disabled: ({ customization, item }) =>
      (!customization.ENABLEDOCPREVIEW &&
        PREVIEWABLEDOC_CLOUD.includes(item.ext)),
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Download',
    icon: 'download',
    action: 'downloadFile',
    shortKey: 'd',
    listOnly: false,
    disabled: ({ item, customization, isReadLocked }) =>
      item.lockreadlock ||
      isReadLocked ||
      !(item.candownload === 1) ||
      (item.type === 'dir' && customization.DISABLEFOLDERDOWNLOAD === 1),
  },
  {
    name: 'Open With',
    icon: 'edit',
    action: 'editOptions',
    shortKey: '',
    listOnly: false,
    showArrow: true,
    disabled: () => {
      return false;
    },
    hidden: ({ hasMultipleEditClients }) => {
      return !hasMultipleEditClients;
    },
  },
  {
    name: ({ customization }) =>
      WOPIClients[customization.WOPICLIENT].fullLabel,
    icon: 'edit',
    action: 'quickEditWopi',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      isInRecycleFolder,
      isPublicShareView,
      customization,
      systemStatus,
      isLockedByOthers,
      isImplicitlyLocked,
    }) => {
      const WOPIClient = WOPIClients[customization.WOPICLIENT];

      return !(
        !isInRecycleFolder &&
        customization.ENABLEWOPIEDIT &&
        systemStatus.ENABLEWEBEDIT &&
        !(isLockedByOthers && !isImplicitlyLocked) &&
        item.candownload &&
        item.canupload &&
        WOPIClient &&
        canEditFileType(item.ext, WOPIClient.key)
      );
    },
    hidden: ({ item, customization, msteams, hasMultipleEditClients }) => {
      const WOPIClient = WOPIClients[customization.WOPICLIENT];

      return (
        customization.isCommunityEdition() ||
        hasMultipleEditClients ||
        !WOPIClient ||
        !canEditFileType(item.ext, WOPIClient.key) ||
        !customization.ENABLEWOPIEDIT ||
        msteams
      );
    },
  },
  {
    name: 'Edit Online',
    icon: 'edit',
    action: 'quickEditFile',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      customization,
      systemStatus,
      isInRecycleFolder,
      isPublicShareView,
      isLockedByOthers,
      isImplicitlyLocked,
    }) => {
      return !(
        !isInRecycleFolder &&
        customization.ENABLEWOPIEDIT &&
        systemStatus.ENABLEWEBEDIT &&
        !(isLockedByOthers && !isImplicitlyLocked) &&
        item.candownload &&
        item.canupload &&
        canEditFileType(item.ext, 'web')
      );
    },
    hidden: ({ item, customization, msteams, hasMultipleEditClients }) =>
      customization.isCommunityEdition() ||
      hasMultipleEditClients ||
      !canEditFileType(item.ext, 'web') ||
      !customization.ENABLEWOPIEDIT ||
      msteams,
  },
  {
    name: ({ item }) => GoogleDocsFormats[getFileType(item.ext)].fullLabel,
    icon: 'edit',
    action: 'openInGoogleDocs',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      isInRecycleFolder,
      isPublicShareView,
      systemStatus,
      customization,
      isLockedByOthers,
    }) =>
      item.type === 'dir' ||
      isLockedByOthers ||
      !customization.ENABLEWOPIEDIT ||
      item.candownload !== 1 ||
      item.canupload !== 1 ||
      isPublicShareView ||
      isInRecycleFolder,
    hidden: ({
      item,
      systemStatus,
      customization,
      msteams,
      hasMultipleEditClients,
    }) =>
      customization.isCommunityEdition() ||
      hasMultipleEditClients ||
      !customization.ENABLEWOPIEDIT ||
      customization.ENABLEGOOGLEDOCS !== 1 ||
      !canEditFileType(item.ext, 'google') ||
      msteams,
  },
  {
    name: ({ item, editor }) =>
      editor && editor !== 'Pick an app'
        ? 'Open in {editor}'
        : 'Open in Desktop',
    icon: 'edit',
    action: 'runQuickEdit',
    shortKey: '',
    listOnly: false,
    disabled: ({ item, isInRecycleFolder, isLockedByOthers, customization }) =>
      item.type === 'dir' ||
      isLockedByOthers ||
      !(item.candownload === 1) ||
      !item.showquickedit ||
      isInRecycleFolder ||
      !!(customization.ALLOWQUICKEDITALL && isExtensionBlackListed(item.ext)),
    hidden: ({ customization, msteams, hasMultipleEditClients, item }) => {
      return (
        customization.isCommunityEdition() ||
        hasMultipleEditClients ||
        customization.ENABLEWEBEDIT !== 1 ||
        item.type !== 'file' ||
        !canEditFileType(
          item.ext,
          'desktop',
          customization.ALLOWQUICKEDITALL
        ) ||
        msteams
      );
    },
  },
  {
    name: 'Edit Online',
    icon: 'edit',
    disabled: () => true,
    hidden: ({ customization }) => !customization.isCommunityEdition(),
  },
  {
    name: 'Rename',
    icon: 'money-check-edit',
    action: 'onClickRename',
    shortKey: 'f2',
    listOnly: false,
    disabled: ({ item, isLockedByOthers, hasSearchResults }) =>
      isLockedByOthers || item.canrename != 1 || hasSearchResults,
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Share',
    icon: 'share-alt',
    action: 'shareFile',
    shortKey: '.',
    listOnly: false,
    disabled: ({ item, isLimitedUser }) => !item.isshareable || isLimitedUser,
    hidden: ({ systemStatus }) => systemStatus.SHAREMODE === 3,
  },
  {
    name: ({ msteams }) => (msteams ? 'Post Direct Link' : 'Copy Direct Link'),
    icon: 'link',
    action: 'copyDirectLink',
    shortKey: '',
    listOnly: false,
    disabled: ({
      item,
      isPublicShareView,
      shouldHideShareDirectLinkOption,
    }) => {
      let { secure } = getParams();
      // disabled when password protected share is used
      return (
        isPublicShareView ||
        !(
          item.fullfilename.startsWith('/EXTERNAL') ||
          item.fullfilename.startsWith('/SHARED') ||
          (!_.isEmpty(secure) && secure === 0) ||
          shouldHideShareDirectLinkOption
        )
      );
    },
  },
  {
    name: 'Previous Versions',
    icon: 'arrow-alt-square-left',
    action: 'versions',
    listOnly: false,
    disabled: ({ item, isLimitedUser }) =>
      item.isroot ||
      item.type === 'dir' ||
      !item.showprev ||
      isLimitedUser,
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Restore',
    icon: 'trash-undo',
    action: 'restoreFile',
    hidden: ({ isInRecycleFolder, msteams }) => {
      return !isInRecycleFolder || msteams;
    },
  },
  {
    name: ({ isInRecycleFolder }) =>
      isInRecycleFolder ? 'Permanently Delete' : 'Delete',
    icon: 'trash-alt',
    action: 'deleteFile',
    shortKey: 'del',
    seperator: false,
    listOnly: false,
    disabled: ({ item, isInRecycleFolder, isLockedByOthers }) =>
      isLockedByOthers ||
      item.isroot ||
      (!item.canupload && !isInRecycleFolder),
    hidden: ({ msteams, systemStatus, isInRecycleFolder }) => {
      return msteams || (systemStatus.CLEARRECYCLEBIN && isInRecycleFolder);
    },
  },
  {
    name: 'Lock',
    icon: 'lock',
    action: 'lockFile',
    shortKey: '',
    seperator: true,
    listOnly: false,
    disabled: ({
      item,
      isPublicShareView,
      isLimitedUser,
      isLockedByOthers,
      isLockDisabled,
    }) =>
      isLockedByOthers ||
      (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
      isPublicShareView ||
      isLimitedUser ||
      !item.showlockunlock ||
      isLockDisabled,
    hidden: ({ item, msteams }) => {
      return item.locked || msteams;
    },
  },
  {
    name: 'Unlock',
    icon: 'lock-open',
    action: 'unlockFile',
    shortKey: '',
    seperator: true,
    listOnly: false,
    disabled: ({ item, isPublicShareView, isLimitedUser, isLockedByOthers }) =>
      isLockedByOthers ||
      (item.isroot && !item.dirpath.startsWith('/SHARED/')) ||
      isPublicShareView ||
      isLimitedUser ||
      !item.showlockunlock,
    hidden: ({ item, msteams }) => {
      return !item.locked || msteams;
    },
  },
  //more option icons
  {
    name: 'Comment',
    icon: 'comment-alt',
    action: 'commentFile',
    shortKey: 'c',
    listOnly: true,
    disabled: ({ isPublicShareView, item, isLimitedUser }) =>
      isPublicShareView || !item.canupload || isLimitedUser,
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Copy',
    icon: 'copy',
    action: 'copyFile',
    shortKey: `${ctrlCmd} + c`,
    listOnly: false,
    disabled: ({ item, isLockedByOthers, isAuthenticated }) =>
      isLockedByOthers ||
      (item.isroot && item.type === 'dir') ||
      !(item.candownload === 1) ||
      !isAuthenticated,
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Move',
    icon: 'long-arrow-right',
    action: 'moveFile',
    shortKey: `${ctrlCmd} + x`,
    listOnly: false,
    disabled: ({ item, isInRecycleFolder, isLockedByOthers }) =>
      isLockedByOthers ||
      ((item.isroot || !item.canupload || !item.canrename) &&
        !isInRecycleFolder),
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Download as PDF',
    icon: 'file-pdf',
    action: 'downloadPDF',
    listOnly: false,
    disabled: ({ item, isReadLocked }) =>
      item.lockreadlock ||
      isReadLocked ||
      item.candownload !== 1 ||
      !PREVIEWABLEDOC_CLOUD.includes(item.ext),
    hidden: ({ item }) => item.type === 'dir',
  },
  {
    name: 'Notifications',
    icon: 'bell',
    action: 'notifications',
    shortKey: '',
    listOnly: false,
    disabled: ({ item, isPublicShareView }) => {
      return isPublicShareView;
    },
    hidden: ({ msteams }) => {
      return msteams;
    },
  },
  {
    name: 'Export Secure Doc',
    icon: 'shield-check',
    action: 'requestForDRMFile',
    listOnly: true,
    hidden: ({ customization, msteams }) => {
      return customization.HIDESECUREDOCS || msteams;
    },
    disabled: ({
      item,
      isPublicShareView,
      isDRMExportEnabled,
      isLimitedUser,
    }) => {
      const format = getFileType(item.name);

      if (!isDRMExportEnabled) return true;

      // disable if it's not office or pdf or img
      if (!isOffice(format) && format !== 'pdf' && format !== 'img')
        return true;

      //excel is not supported either
      if (format === 'excel') return true;

      return (
        item.isroot ||
        !(item.candownload === 1) ||
        isPublicShareView ||
        isLimitedUser
      );
    },
  },
  {
    name: 'Apply Color Tag',
    icon: 'tag',
    color: true,
    action: 'metaColor',
    listOnly: true,
    seperator: true,
    showArrow: true,
    hidden: ({ metaSets, isPublicShareView, msteams }) => {
      return (isPublicShareView ? true : !metaSets) || msteams;
    },
  },
  {
    name: 'Send For Approval',
    icon: 'paper-plane',
    action: 'sendForApproval',
    listOnly: true,
    hidden: ({ item, msteams, isPublicShareView }) =>
      item.type === 'dir' || msteams || isPublicShareView,
  },
  {
    name: 'Start Workflow',
    icon: 'sitemap',
    action: 'startWorkflow',
    listOnly: true,
    showArrow: true,
    hidden: ({ systemStatus, item, isLimitedUser, msteams }) => {
      return (
        !systemStatus.AUTOMATION_WORKFLOWS ||
        item.type === 'dir' ||
        isLimitedUser ||
        msteams
      );
    },
  },
];
