<template>
  <div
    v-if="isOpen"
    v-shortkey.once="['esc']"
    :class="[
      { 'dialog-modal-visible': true },
      { 'stick-top': false },
      { 'dialog-modal-tabbed': false },
      { 'dialog-modal-form': false },
    ]"
    :name="name"
    class="dialog-modal-overlay left-tabbed-modal timed-fade"
    @shortkey="close()"
  >
    <div class="dialog-modal-outer" @click="handleClickOutsiteMobileMenu">
      <div v-if="!isSmallScreen" class="modal-tabs">
        <slot name="tabs"></slot>
      </div>
      <div class="modal-body-wrapper">
        <div class="modal-header">
          <div class="title-wrapper">
            <button
              v-if="isSmallScreen"
              class="bars-menu"
              @click.stop="() => toggleMobileMenu(!isMobileMenuOpen)"
            >
              <Icon family="fas" name="bars" />
            </button>
            <slot v-if="$slots['title']" name="title"></slot>
            <h4 else>{{ title }}</h4>
          </div>
          <ul class="modal-nav">
            <li>
              <a class="btn btn-icon" @click="close">
                <Icon family="far" name="times" />
              </a>
            </li>
          </ul>
        </div>
        <div class="modal-subheader">
          <slot name="subheader" />
        </div>

        <div class="mobile-body-wrapper">
          <div
            v-if="isSmallScreen"
            class="modal-tabs"
            :class="{ ['open']: isMobileMenuOpen }"
            @click.stop
          >
            <slot name="tabs"></slot>
          </div>

          <div
            :class="{
              [`no-padding`]: theme === `noPadding`,
              ['body-blocked']: isMobileMenuOpen,
            }"
            class="modal-body"
          >
            <slot name="body"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isMobileMenuOpen: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    full: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isSmallScreen: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    close: function () {
      this.$emit('close', null);
    },
    opened: function () {
      this.$emit('opened', null);
    },
    toggleMobileMenu: function (status) {
      this.$emit('toggleMobileMenu', status);
    },
    beforeOpen: function (e) {
      this.$emit('before-open', e);
    },
    beforeClose: function (e) {
      this.$emit('before-close', e);
    },
    handleWindowResize() {
      this.isSmallScreen = window.innerWidth <= 992;
    },
    handleClickOutsiteMobileMenu() {
      this.isMobileMenuOpen && this.toggleMobileMenu(false);
    },
  },
};
</script>
