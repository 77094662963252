<template>
  <div>
    <input
      v-model="field.value"
      :disabled="metadata.write !== 1 || readOnly"
      type="number"
      class="form-control meta-input form-control-sm mb-2"
      :required="metadata.required === 1"
      @keypress="checkInt"
      @input="onFieldChange"
    />
  </div>
</template>

<script>
import dataTypeMixin from './_dataTypeMixin';

export default {
  mixins: [dataTypeMixin],
  methods: {
    checkInt(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;

      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
  },
};
</script>
