<template>
  <div>
    <a
      v-if="canPrint"
      v-tooltip="{ delay: 1000, offset: -5, content: 'Print' }"
      class="navbar-icon"
      @click="$emit('print')"
    >
      <Icon family="fas" name="print" />
    </a>
  </div>
</template>

<script>
import Icon from '../../Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
    canPrint: {
      type: Number,
      default: 1,
    },
    currentZoom: {
      type: Number,
      default: 0,
    },
    ready: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
