<template>
  <label
    class="switch-toggle"
    :class="{ disabled: disabled, readonly: readonly }"
  >
    <input
      :id="id"
      :name="name"
      :aria-label="label"
      :tabindex="tabindex"
      type="checkbox"
      :checked="value || checked"
      @input="onInput"
      @change="onChange"
    />
    <span class="slider"></span>
  </label>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Boolean, Number],
      default: false,
    },
    name: { type: String, default: '' },
    label: { type: String, default: '' },
    id: {
      type: String,
      default: '',
    },
    tabindex: {
      type: String,
      default: '0',
    },
  },
  methods: {
    onInput(e) {
      this.$emit('input', e.target.checked);
    },
    onChange(e) {
      this.$emit('change', e.target.checked);
    },
  },
};
</script>
