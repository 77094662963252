<template>
  <div>
    <div class="d-flex" v-if="!msTeams">
      <div class="d-flex flex-column justify-content-center align-items-end">
        <div v-if="isPicked" class="preview-file-name">
          {{ defaultfile }}
        </div>
        <a class="preview-change-link" @click="select">{{ $t(preview) }}</a>
      </div>
      <div
        class="d-flex ml-3 justify-content-center align-items-center share-icon-container"
        @click="select"
      >
        <FileIcon :no-file="!isPicked" :name="name" :format="fileFormat" />
      </div>
    </div>
    <FilePicker
      ref="filePickerModal"
      :folder-picker="false"
      :accept="allowedPreviewFileExtensions"
    />
  </div>
</template>

<script>
import FilePicker from '../Modals/FilePicker';
import FileIcon from 'common/components/FileIcon';
import { getFileType } from 'common/utils/files';
import { PREVIEWABLEDOC_CLOUD } from 'common/utils/fileExtensions';

export default {
  components: {
    FilePicker,
    FileIcon,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    allowedPreviewFileExtensions() {
      return PREVIEWABLEDOC_CLOUD;
    },
    isPicked() {
      return Object.keys(this.defaultfile).length >= 1;
    },
    fileFormat() {
      return this.isPicked ? getFileType(this.defaultfile) : '';
    },
    defaultfile() {
      return this.$store.state.share.shareProperties.defaultfile || '';
    },
    preview() {
      return this.isPicked ? 'Change Preview' : 'Select Preview';
    },
    name() {
      return this.isPicked ? this.defaultfile : '';
    },
    msTeams() {
      return this.$store.state.auth.msteams;
    },
  },
  methods: {
    select() {
      this.$refs.filePickerModal.select(this.path, async (filePath) => {
        await this.$store.dispatch('share/setUpdateSharePropertiesValue', {
          defaultfile: filePath,
        });
        await this.$store.dispatch('share/updateShare', true);
        this.$store.dispatch('share/resetUpdateSharePropertiesValue');
      });
    },
  },
};
</script>

<style lang="scss">
.share-icon-container {
  border: 1px solid var(--border-color);
  border-radius: 3px;
  padding: 9px 0px 8px 8px;
  cursor: pointer;
}
.share-icon-container .file-icon .file-format--multifiles {
  margin: 10px auto;
  padding-right: 5px;
}
.preview-file-name {
  font-size: 12px;
  font-weight: 500;
}
.preview-change-link {
  font-size: 10px;
  font-weight: 700;
}
.dialog-modal-filepicker {
  z-index: 10000;
}
</style>
