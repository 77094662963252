<template>
  <li
    class="transactions-list-item"
    :class="{
      [`transaction-list-item--preparing`]: transaction.status === 'preparing',
    }"
  >
    <div class="transaction-icon">
      <FileIcon :name="transaction.name" />
      <div
        v-if="
          transaction.status !== 'processing' &&
          transaction.status !== 'preparing'
        "
        class="transaction-icon-status"
        :class="{
          [`transaction-icon-status--error`]: transaction.status === 'failed',
          [`transaction-icon-status--cancelled`]:
            transaction.status === 'cancelled',
        }"
      >
        <Icon family="fas" :name="transState" />
      </div>
    </div>
    <div class="transaction-info">
      <span
        class="transaction-info-title"
        :class="{ 'text-truncate': isMobile }"
        v-tooltip="{ content: tooltipContent }"
      >
        {{
          currentPath !== transaction.dirpath && longView
            ? `${transaction.dirpath}/`
            : ''
        }}
        <br v-if="currentPath !== transaction.dirpath" class="d-lg-none" />
        {{
          transaction.uploadpath
            ? `${transaction.uploadpath.replace(/^\/|\/$/g, '')}/`
            : ''
        }}{{ transaction.name }}
      </span>
      <span
        v-if="transaction.status === 'preparing'"
        class="description"
        role="status"
      >
        {{ $t('Preparing to upload') }}</span
      >
      <div v-else-if="transaction.status === 'processing'" class="progress">
        <div
          class="progress-bar"
          :class="{
            'progress-bar--network-error': transaction.networkError,
          }"
          :style="{ width: `${progress}%` }"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          :aria-valuetext="$t('Uploading...')"
        ></div>
      </div>
      <span
        v-else-if="transaction.message && transaction.status !== 'cancelled'"
        class="description text-truncate"
        role="status"
      >
        {{ transaction.message }}
      </span>
      <span v-else class="description" role="status">
        {{ $t('File upload cancelled') }}
      </span>
    </div>
    <div class="pl-4">
       <small
          v-if="
            transaction.status === 'processing' &&
            !isMobile &&
            !transaction.networkError
          "
          class="floated text-truncate"
          >{{ bytesToSize(transaction.sentSize) }} /
          {{ bytesToSize(transaction.size) }} ({{ progress }}%)</small
        >
        <small
          v-else-if="transaction.networkError && !isMobile"
          class="floated text-truncate"
        >
          {{ $t('No Network Connection') }}
        </small>
              <small
          v-else-if="!isMobile"
          :class="{
            floated: !isMobile,
            blocked: isMobile,
            ['not-processing']: !isMobile,
          }"
          >{{ bytesToSize(transaction.size) }}</small
        >
      </div>
    <div
      v-if="transaction.status === 'completed'"
      class="transaction-actions"
      role="alert"
    >
      <slot></slot>
    </div>
    <div v-else class="transaction-actions">
      <button
        v-if="
          transaction.status === 'cancelled' || transaction.status === 'failed'
        "
        class="btn btn-icon"
        :aria-label="$t('Retry upload')"
        @click="retry"
      >
        <Icon family="fas" name="redo" />
        <Icon class="icon-overlay" family="fas" name="redo" />
      </button>
      <button
        v-else
        class="btn btn-icon"
        :aria-label="$t('Cancel upload')"
        @click="cancel"
      >
        <Icon family="fas" name="ban" />
        <Icon class="icon-overlay" family="fas" name="ban" />
      </button>
    </div>
  </li>
</template>
<script>
import Icon from 'common/components/Icon';
import FileIcon from 'common/components/FileIcon';
import { bytesToSize } from 'common/utils/files';
import responsivenessMixin from '@/mixins/responsivenessMixin';

export default {
  components: {
    Icon,
    FileIcon,
  },
  mixins: [responsivenessMixin],
  props: {
    longView: {
      type: Boolean,
      default: true,
    },
    transaction: {
      type: Object,
      default: () => {},
    },
    currentPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusMap: {
        failed: 'times',
        cancelled: 'ban',
        rest: 'check',
      },
    };
  },
  computed: {
    transState() {
      return this.transaction.status === 'failed' ||
        this.transaction.status === 'cancelled'
        ? this.statusMap[this.transaction.status]
        : this.statusMap.rest;
    },
    progress() {
      const progress = Math.ceil(
        (this.transaction.sentSize * 100) / this.transaction.size
      );
      return progress >= 100 ? 100 : progress;
    },
    tooltipContent() {
      if (this.currentPath !== this.transaction.dirpath && this.longView) {
        return `${this.transaction.dirpath}/${this.transaction.name}`;
      } else if(this.transaction.uploadpath) {
        return `${this.transaction.uploadpath.replace(/^\/|\/$/g, '')}/`
      } else {
        return `${this.transaction.name}`;
      }
    },
  },
  methods: {
    cancel() {
      this.$store.commit('files/setTransaction', {
        id: this.transaction.id,
        status: 'cancelled',
      });
    },
    retry() {
      if (this.transaction?.callbacks?.retry) {
        this.transaction.callbacks.retry();
      } else {
        this.$store.dispatch('files/retryTransaction', {
          id: this.transaction.id,
        });
      }
    },
    bytesToSize,
  },
};
</script>
