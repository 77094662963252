<template>
  <div class="p-5">
    <div>
      <Icon class="no-list-icon" :name="iconName" family="fal" />
    </div>
    <div class="empty-text pt-2">
      {{ $t(text) }}
    </div>
  </div>
</template>
<script>
import Icon from 'common/components/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.no-list-icon {
  font-size: 4rem;
  margin-bottom: 15px px;
  color: var(--text-ultra-light);
}
.empty-text {
  color: var(--text-light);
  font-size: 0.938rem;
  margin: 0.1rem 0;
}
</style>
