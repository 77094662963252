import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import { sectionsToLoad } from '../../utils/drm';

import { DrmMetaItemsPerPage } from '../../constants/drm';
// This store should be unmounted after closing the DRM preview, and mounted again for a new DRM.

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    themeScheme: 'default',
    drmPages: {},
    drmThumbs: {},
    documentMeta: {},
    loadedSections: {},
    secureView: true,
  },
  mutations: {
    set(state, { key, value }) {
      Vue.set(state, key, value);
    },
    setThumb(state, { page, thumb }) {
      Vue.set(state.drmThumbs, page, thumb);
    },
    setSecure(state, payload) {
      Vue.set(state, 'secureView', payload);
    },
    setDocumentMeta(state, meta) {
      const { firstPage, lastPage, totalPageCount, pages, init } = meta;
      if (init) {
        let effectivePages = Array(totalPageCount).fill({});
        effectivePages = effectivePages.map((el, index) => {
          return {
            page: index + 1,
          };
        });

        effectivePages.splice(firstPage - 1, pages.length, ...pages);

        Vue.set(state, 'documentMeta', {
          firstPage,
          lastPage,
          totalPageCount,
          pages: effectivePages,
        });
      } else {
        let effectivePages = _.cloneDeep(state.documentMeta.pages);
        effectivePages.splice(firstPage - 1, pages.length, ...pages);
        Vue.set(state, 'documentMeta', {
          firstPage,
          lastPage,
          totalPageCount,
          pages: effectivePages,
        });
      }
      const loadedSections = sectionsToLoad(
        firstPage,
        lastPage,
        DrmMetaItemsPerPage
      );
      const sections = Array(loadedSections.end - loadedSections.start + 1)
        .fill(loadedSections.start)
        .map((el, i) => el + i);
      sections.forEach((section) => {
        Vue.set(state.loadedSections, section, true);
      });
    },
    setInitPage(state, page) {
      Vue.set(state.drmPages, page, {
        ready: false,
        progress: 0,
        completedSegments: [],
      });
    },
    setPageStatus(state, payload) {
      const updatedPage = {
        ...state.drmPages[payload.page],
        ...payload,
      };

      Vue.set(state.drmPages, payload.page, updatedPage);
    },
  },
  actions: {
    setThumb(context, payload) {
      context.commit('setThumb', payload);
    },
    setSecureView({ commit }, payload) {
      commit('setSecure', payload);
    },
    setInitPage(context, page) {
      context.commit('setInitPage', page);
    },
    setDocumentMeta(context, meta) {
      context.commit('setDocumentMeta', meta);
    },
    setPageStatus(
      context,
      { page, progress, total, status, completedSegments }
    ) {
      context.commit('setPageStatus', {
        page,
        ...(status === 'result' && { ready: true }),
        ...(status === 'result' && { progress: 1 }),
        ...(status === 'progress' &&
          progress &&
          total && { progress: progress / total }),
        ...(status === 'working' && completedSegments && { completedSegments }),
      });
    },
  },
  getters: {
    getThumbForPage: (state) => (page) => {
      return state.drmThumbs[page] || '';
    },
    secureView: ({ secureView }) => secureView,
    completedSegmentsByPage: (state) => (page) => {
      const segments = state.drmPages[page]?.completedSegments || [];
      return segments.reduce((acc, section) => {
        const key = `${section['row']}-${section['col']}`;
        return {
          ...acc,
          [key]: true,
        };
      }, {});
    },
    isPageReady: (state) => (page) => {
      return state.drmPages[page]?.ready || false;
    },
    getDocumentMeta: (state) => {
      return state.documentMeta;
    },
    getLoadedSections: (state) => {
      return state.loadedSections;
    },
  },
};
