var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',{staticClass:"col-title drm-files file-path"},[_vm._v(" "+_vm._s(_vm.item.path)+" ")]),_c('td',{staticClass:"m-text centered"},[_c('span',{staticClass:"pr-1"},[_vm._v(_vm._s(_vm.item.usercount)+" "+_vm._s(_vm.$t('Users')))]),_c('span',[_vm._v(_vm._s(_vm.item.groupcount)+" "+_vm._s(_vm.$t('Groups')))])]),_c('td',{staticClass:"m-text centered thin"},[_vm._v(" "+_vm._s(_vm.item.inherit ? _vm.$t('Yes') : _vm.$t('No'))+" ")]),_c('td',{staticClass:"d-flex m-text justify-content-center"},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Edit Permission')),expression:"$t('Edit Permission')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Edit Permission'),"list-only":""},on:{"click":_vm.editPermissions}},[_c('Icon',{attrs:{"name":"edit","family":"fas"}})],1),_c('v-popover',{on:{"update:open":(isOpen) => {
          _vm.showRemoveAlert = isOpen;
        }}},[_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Remove Permission')),expression:"$t('Remove Permission')"}],staticClass:"btn btn-sm btn-icon",attrs:{"aria-label":_vm.$t('Remove Permission'),"list-only":""}},[_c('Icon',{attrs:{"name":"times","family":"fas"}})],1),_c('Alert',{attrs:{"slot":"popover","visible":_vm.showRemoveAlert,"button-type":"danger","buttons":[
          {
            label: `Remove`,
            callback: function () {
              _vm.revokePermission(_vm.item._id);
            },
          },
          { label: `Cancel`, outline: true, callback: function () {} },
        ]},slot:"popover"},[_c('span',{attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t('Are you sure you want to delete the Permissions?'))+" "),_c('em',[_vm._v(_vm._s(_vm.item.path))]),_vm._v(" ? ")])])],1)],1),_c('FolderSecurity',{ref:"folderSecurityModal",attrs:{"secured-directory-path":_vm.item.path,"main-screen-view":true},on:{"close":function($event){return _vm.$emit('close')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }