<template>
  <div class="rule-actions form-inline">
    <div class="form-group">
      <div style="position: relative; align-self: start">
        <button
          type="button"
          class="btn btn-primary btn-sm mt-2 round-add-btn"
          @click="showButtons"
        >
          <Icon name="plus-circle" family="fas" /> ADD
        </button>
        <transition name="fade-up">
          <div
            v-if="addButtons"
            v-click-outside="() => (addButtons = false)"
            class="buttons-container p-3"
          >
            <div class="mb-2">
              <span
                class="p-1"
                type="button"
                @click="
                  addRule();
                  showButtons();
                "
              >
                <Icon name="traffic-light-go" family="fal" />
                {{ labels.addRule }}
              </span>
            </div>
            <div
              class="pointer"
              v-tooltip="{
                content: !depth
                  ? $t(`You can create up to 4 levels of groups`)
                  : '',
              }"
            >
              <span
                :class="{ disabled: !depth }"
                class="p-1"
                type="button"
                @click="
                  addGroup();
                  showButtons();
                "
              >
                <Icon name="layer-group" family="fal" />
                {{ labels.addGroup }}
              </span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'common/components/Icon';

export default {
  name: 'AddRuleOrGroup',
  components: {
    Icon,
  },
  props: ['value', 'rules', 'labels', 'depth'],
  data() {
    return {
      addButtons: false,
      chosenRule: '',
    };
  },
  methods: {
    showButtons() {
      this.chosenRule = this.value;
      this.addButtons = !this.addButtons;
    },
    addRule() {
      this.$emit('input', this.chosenRule);
      this.$emit('addRule');
    },
    addGroup() {
      this.$emit('addGroup');
    },
  },
};
</script>
<style lang="scss" scoped>
.rule-actions * {
  font-size: 13px;
}
.buttons-container {
  width: 180px;
  span {
    color: var(--text-medium);

    width: 100%;
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
  ::v-deep.inline-icon {
    color: var(--text-medium);
    margin-right: 10px;
  }
}
.pointer {
  cursor: pointer;
}
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
