<template>
  <div
    v-shortkey="{ inKey: ['z'], in: ['+'], outKey: ['x'] }"
    class="file-preview-item file-preview-item--picture"
    @shortkey="onShortcut"
  >
    <Loader v-if="isLoading" />
    <v-zoomer
      v-else
      ref="image"
      :min-scale="0.5"
      :max-scale="5"
      :style="{
        width: '100%',
        height: '100%',
        'min-height': '0',
      }"
      @update:zoomed="onZoomed"
    >
      <template>
        <img
          :alt="file.name"
          :src="blob"
          :style="{ transform: `rotate(${rotation}deg)` }"
          style="object-fit: contain; width: 100%; height: 100%"
          @error="onError"
        />
      </template>
    </v-zoomer>

    <portal to="nested-file-list-header--img">
      <NavbarIcon
        icon="redo-alt"
        :tooltip="$t('zip_preview.nested_preview.header.rotate_right')"
        :disabled="isLoading"
        @click="onRotate(90)"
      />
      <NavbarIcon
        icon="undo-alt"
        :tooltip="$t('zip_preview.nested_preview.header.rotate_left')"
        :disabled="isLoading"
        @click="onRotate(-90)"
      />
    </portal>
  </div>
</template>

<script>
import Loader from 'common/assets/loader.svg';
import NavbarIcon from '../NavbarIcon.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Loader,
    NavbarIcon,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    canDownload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      blob: null,
      rotation: 0,
    };
  },
  watch: {
    file: function () {
      this.loadBlob();
    },
  },
  mounted() {
    this.loadBlob();
  },
  methods: {
    ...mapActions('zipViewer', {
      getBlob: 'blob',
    }),
    async loadBlob() {
      this.isLoading = true;
      const request = await this.getBlob(this.file);
      if (request.ok) {
        this.blob = URL.createObjectURL(request.data);
      } else {
        this.$emit('fallback', {
          view: 'DefaultView',
          error: request.error || 'Unexpected error',
        });
      }
      this.isLoading = false;
    },
    zoomIn() {
      this.$refs.image.zoomIn();
    },
    zoomOut() {
      this.$refs.image.zoomOut();
    },
    onZoomed() {
      if (this.$refs.image.scale < 1) {
        this.$refs.image.translateX = 0;
        this.$refs.image.translateY = 0;
      }
    },
    onShortcut(e) {
      switch (e.srcKey) {
        case 'in':
        case 'inKey':
          this.zoomIn();
          break;
        case 'out':
        case 'outKey':
          this.zoomOut();
          break;
        default:
      }
    },
    onRotate(deg) {
      this.rotation += deg;
    },
    onError() {
      this.$emit('fallback', 'DefaultView');
    },
  },
};
</script>
