import FilePreview from '../FilePreview';
import ZipFilePreview from './zipplugin.js';
import VueZoomer from 'vue-zoomer';
import VueToast from 'vue-toast-notification';
import { VTooltip } from 'v-tooltip';
import { isMobileDevice } from '../../utils/responsive';

const Plugin = {
  install(Vue, options = {}) {
    // register images zoom lib
    Vue.use(VueZoomer);
    Vue.use(ZipFilePreview, {
      i18n: options.i18n ? options.i18n : null,
      store: options.store ? options.store : null,
      router: options.router ? options.router : null,
    });
    Vue.use(VueToast, {
      position: isMobileDevice() ? 'bottom' : 'top-right',
      duration: 5000,
      dismissible: true,
      queue: false,
    });
    this.event = new Vue();

    Vue.prototype.$preview = {
      get subscription() {
        return Plugin.event;
      },

      // trigger preview
      open(items, index, shift) {
        Plugin.event.$emit('preview', items, index, shift);
      },
    };

    // create div in body
    const div = document.createElement('div');
    document.body.appendChild(div);

    // mount component
    new Vue({
      //parent: root,
      i18n: options.i18n ? options.i18n : null,
      store: options.store ? options.store : null,
      router: options.router ? options.router : null,
      render: (h) => h(FilePreview),
    }).$mount(div);
    Vue.use(VTooltip);
  },
};

export default Plugin;
