<template>
  <div
    class="zip-viewer-explorer"
    :class="{
      'zip-viewer-explorer--loading': isLoading,
      'zip-viewer-explorer--prompt': !items || items.length === 0,
      'zip-viewer-explorer--public-share': isPublicShareView,
    }"
  >
    <LoadingScreen v-if="isLoading" />
    <div
      v-else-if="!isLoading && !showPasswordPrompt"
      class="fc-explorer full-file-list colored-border my-files"
      style="background-color: white"
    >
      <ZipViewerHeader />
      <ZipViewerBody />
    </div>
    <ZipViewerPasswordPrompt
      v-else
      :open="showPasswordPrompt"
      :error.sync="passwordPromptError"
      @onClose="closePasswordPrompt"
      @onOpen="openZipFile"
    />
  </div>
</template>

<script>
import ZipViewerHeader from '../ZipViewer/ZipViewerHeader';
import ZipViewerBody from '../ZipViewer/ZipViewerBody';
import ZipViewerPasswordPrompt from '../ZipViewer/ZipViewerPasswordPrompt';
import { mapActions, mapState, mapGetters } from 'vuex';
import LoadingScreen from './Loading';

export default {
  name: 'ZipView',
  components: {
    ZipViewerPasswordPrompt,
    ZipViewerHeader,
    ZipViewerBody,
    LoadingScreen,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    isPublicShareView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPasswordPrompt: false,
      isLoading: true,
      passwordPromptError: {},
      transactionContainerOriginalState: null,
    };
  },
  beforeDestroy() {
    this.transactionContainerOriginalState = null;
  },
  computed: {
    ...mapState('zipViewer', ['items', 'isEncrypted']),
    ...mapState('files', ['zipFolderPasswordMap']),
    ...mapGetters('zipViewer', ['isTransactionContainerVisible']),
    currentPath() {
      return this.file.path.toString();
    },
  },
  async mounted() {
    document.querySelector('body').style.overflowY = 'hidden';
    try {
      this.setZipPreviewOpen(true);
      this.initFileOperations({
        preview: this.$zippreview,
        toast: () => {
          return this.$toast;
        },
      });
      this.transactionContainerOriginalState =
        this.isTransactionContainerVisible;
      this.toggleTransactionContainer(false);
      const zipInfo = await this.fetchZipFileInfo(this.file);
      this.setZipFileInfo({
        file: this.file,
        info: zipInfo,
      });
      const password =
        this.zipFolderPasswordMap[this.file.fullfilename] ?? null;
      if (zipInfo.isencrypted && !password) {
        this.toggleTransactionContainer(false);
        this.showPasswordPrompt = true;
        this.isLoading = false;
      } else {
        await this.openZipFile(password);
      }
    } catch (e) {
      const apiErrors = [
        'ERR_ZIP_FILE_SIZE_EXCEEDED',
        'ERR_INVALID_STORAGE',
        'ERR_ZIP_FOLDER_POLICY_DISABLED',
        'ERR_INVALID_ZIP_FILE',
        'ERR_FAILED_TO_DOWNLOAD_FILE',
      ];
      if (e.code && apiErrors.includes(e.code)) {
        this.$toast.info(`
          <b>${this.$t('zip_preview.errors.title')}</b>
          <p>${e.message}</p>
        `);
      } else {
        this.$toast.error(`
          <b>${this.$t('zip_preview.errors.title')}</b>
          <p>${e.message}</p>
        `);
      }
      this.$emit('fallback', 'DefaultView');
    }
  },
  destroyed() {
    document.querySelector('body').style.overflowY = 'auto';
    this.setZipPreviewOpen(false);
    this.resetState();
  },
  methods: {
    ...mapActions('zipViewer', [
      'browse',
      'fetchZipFileInfo',
      'initFileOperations',
      'loadZipFileInfo',
      'resetState',
      'setPassword',
      'setZipFileInfo',
      'setZipPreviewOpen',
      'toggleTransactionContainer',
    ]),
    closePasswordPrompt() {
      this.showPasswordPrompt = false;
      this.$emit('close');
    },
    async openZipFile(password) {
      const path = this.file.fullfilename;
      try {
        if (password) {
          this.setPassword({ path, password });
        }
        await this.browse();
        this.showPasswordPrompt = false;
        this.isLoading = false;
        this.$nextTick(() => {
          if (this.transactionContainerOriginalState) {
            this.toggleTransactionContainer(true);
          }
          document.querySelector('body').style.overflowY = 'hidden';
        });
      } catch (e) {
        if (e.code && e.code === 'ERR_INVALID_PASSWORD') {
          if (this.showPasswordPrompt) {
            this.passwordPromptError = { password: { message: e.message } };
          }
          this.showPasswordPrompt = true;
          this.isLoading = false;
          this.setPassword({ path, password: null });
        }
      }
    },
  },
};
</script>
