<template>
  <DialogModal
    :stick-top="true"
    :visible="true"
    :title="$t(`DRM Document Container`)"
    :size="'small'"
    :class-name="'share-dialog'"
    :buttons="buttonArrayModified"
    @close="closeDialog"
  >
    <Loader :loading="loading" full />

    <div class="new-share-created">
      {{ $t('Your DRM Document Container has been created and downloaded!') }}
    </div>
    <transition name="fade">
      <div v-if="drmExportResponseProperties" class="shareDialogWrapper">
        <div class="caption">
          {{ $t('User Access Key to view the DRM Document') }}
        </div>

        <div class="shareLink-details d-flex">
          <div ref="drmKey" class="share-link">
            {{ drmExportResponseProperties.drm_user_access_key }}
          </div>
          <div class="shareLink-actions">
            <button
              v-tooltip="$t('Copy Link to clipboard')"
              class="btn btn-primary"
              @click="copyToClipBoard('key')"
            >
              <Icon name="clipboard-list" family="fas" />
            </button>
          </div>
        </div>

        <!-- Export response notes -->
        <div class="caption">
          {{ $t('Copy Message To Recipient') }}
        </div>
        <div class="shareLink-details d-flex">

          <div ref="drmNote" class="share-link" v-html="$t('To view this secure document, please do the following:<br /><br />1. Download and install the Secure Document Viewer on your desktop from : <br /> {viewerUrl} <br /><br />2. Open the document using the viewer and when prompted use the supplied access key<br /><i>---------------------------------------</i><br />{accessKey}<br /><i>---------------------------------------</i><br />',
            {
              viewerUrl: drmExportResponseProperties.drm_viewer_url,
              accessKey: drmExportResponseProperties.drm_user_access_key
            }
          )">
          </div>
          
          <div class="shareLink-actions">
            <button
              v-tooltip="'Copy Link to clipboard'"
              class="btn btn-primary"
              @click="copyToClipBoard('note')"
            >
              <Icon name="clipboard-list" family="fas" />
            </button>
            <button
              v-tooltip="'Click to download DRM Viewer'"
              class="btn btn-primary"
              @click="openLink"
            >
              <Icon name="external-link-alt" family="fas" />
            </button>
          </div>
        </div>
      </div>

      <!-- Export response more options -->
    </transition>
  </DialogModal>
</template>
<script>
import Loader from 'common/components/Loader';
import DialogModal from 'common/components/DialogModal';
import Icon from 'common/components/Icon';

export default {
  components: {
    Loader,
    DialogModal,
    Icon,
  },
  props: {},
  data() {
    return {
      buttonArray: [
        {
          label: this.$t('More Options'),
          outline: true,
          hideduringShowOptions: true,
          callback: () => this.triggerMoreOptions(),
        },
        {
          label: this.$t('OK'),
          right: true,
          callback: () => this.closeDialog(),
        },
      ],
      toastClipBoard: {
        message:
          '<b>' +
          this.$t('Success') +
          '</b><p>' +
          this.$t('Copied successfully.') +
          '</p>',
        type: 'success',
      },
      showAdvanced: false,
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading['core/exportdrmfiles'];
    },
    drmExportResponseProperties() {
      return this.$store.state.files.drmExportResponseProperties;
    },
    buttonArrayModified() {
      return this.buttonArray;
    },
  },
  methods: {
    async triggerMoreOptions() {
      let recid = this.drmExportResponseProperties.drm_recid;
      await this.$store.dispatch('files/getDRMFilesList', { drmid: recid });
      this.$store.dispatch('files/openDRMListModal');
      this.$emit('openDRMModal', true);
    },
    copyToClipBoard(type) {
      let url = type == 'key' ? this.$refs.drmKey : this.$refs.drmNote;
      let range = document.createRange();
      range.selectNode(url);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      this.$toast.open(this.toastClipBoard);
    },
    openLink() {
      let url = this.drmExportResponseProperties.drm_viewer_url;
      window.open(url, '_blank');
    },
    closeDialog() {
      this.$emit('close');
      this.$store.dispatch('files/closeDRMResponseDialog');
    },
  },
};
</script>

<style>
.share-link {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
