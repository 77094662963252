var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.query.logicalOperator && _vm.index != 0)?_c('LogicalOperator',{attrs:{"labels":_vm.labels},on:{"imput":_vm.save,"change":function($event){return _vm.$emit('change')}},model:{value:(_vm.query.logicalOperator),callback:function ($$v) {_vm.$set(_vm.query, "logicalOperator", $$v)},expression:"query.logicalOperator"}}):_vm._e(),_c('div',{staticClass:"vqb-rule card",class:{ 'not-editing': !_vm.editing },on:{"mouseover":function($event){_vm.hover = true},"mouseleave":function($event){_vm.hover = false}}},[(!_vm.editing)?_c('QueryDisplay',{staticClass:"expression-container",attrs:{"query":_vm.query},on:{"editing":_vm.edit}}):_c('div',{staticClass:"form-inline"},[_c('v-select',{staticClass:"w-100",attrs:{"label":"label","options":_vm.filtredOperands},model:{value:(_vm.query.operand),callback:function ($$v) {_vm.$set(_vm.query, "operand", $$v)},expression:"query.operand"}}),_c('div',{staticClass:"mt-2 w-100 d-flex flex-wrap align-items-center"},[_c('v-select',{staticClass:"operator-select",attrs:{"label":"label","options":_vm.filtredOperators},model:{value:(_vm.query.operator),callback:function ($$v) {_vm.$set(_vm.query, "operator", $$v)},expression:"query.operator"}}),(
            !_vm.specialParsingRules.includes(_vm.query.operator.ruleString) &&
            !_vm.ruleInType.includes(_vm.query.operator.ruleString)
          )?_c('div',{staticClass:"mt-2 d-flex"},[_c('span',{staticClass:"ml-2 mr-1",class:{ positive: !_vm.query.negation }},[_vm._v(_vm._s(_vm.$t('True'))+" ")]),_c('SwitchToggle',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: 'Negate expression',
            }),expression:"{\n              content: 'Negate expression',\n            }"}],staticClass:"logical mr-2",staticStyle:{"padding":"0"},model:{value:(_vm.query.negation),callback:function ($$v) {_vm.$set(_vm.query, "negation", $$v)},expression:"query.negation"}}),_c('span',{class:{ negative: _vm.query.negation }},[_vm._v(_vm._s(_vm.$t('False')))])],1):_vm._e(),(_vm.isFlagRule)?_c('span',{staticClass:"text-muted mt-1"},[(_vm.query.operator.description)?_c('i',[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t(_vm.query.operator.description)),expression:"$t(query.operator.description)"}],staticClass:"ml-2 cursor-pointer",attrs:{"name":"info-circle","family":"fal"}})],1):_vm._e()]):_vm._e()],1),(!_vm.isFlagRule)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query.value),expression:"query.value"}],staticClass:"form-control mt-2 rule-select",attrs:{"type":"text","placeholder":_vm.query.operator.example
            ? _vm.query.operator.example
            : _vm.labels.textInputPlaceholder},domProps:{"value":(_vm.query.value)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.query, "value", $event.target.value)}}}):_vm._e(),(_vm.query.operator.example && !_vm.isFlagRule)?_c('span',{staticClass:"text-muted mt-1"},[_vm._v(" "+_vm._s(`Example: ${_vm.query.operator.example}`)+" "),_c('i',{on:{"click":_vm.copyCode}},[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Copy')),expression:"$t('Copy')"}],staticClass:"ml-2 cursor-pointer",attrs:{"name":"copy","family":"fal"}})],1),(_vm.query.operator.description)?_c('i',[_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t(_vm.query.operator.description)),expression:"$t(query.operator.description)"}],staticClass:"ml-2 cursor-pointer",attrs:{"name":"info-circle","family":"fal"}})],1):_vm._e()]):_vm._e(),_c('BottomButtons',{on:{"editing":_vm.save,"cancel":_vm.cancel}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.hover),expression:"hover"}],staticClass:"trash-button",on:{"click":_vm.remove}},[_c('Icon',{attrs:{"name":"trash-alt","family":"fas"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }