var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-circular",attrs:{"id":_vm.id}},[_c('div',{staticClass:"progress-bar-graph"},[_c('svg',{style:({ width: _vm.size, height: _vm.size })},[_c('circle',{style:({
          stroke: 'var(--bg-mid-medium)',
        }),attrs:{"cx":_vm.size / 2.5,"cy":_vm.size / 2.55,"r":_vm.size / 2.55}}),_c('circle',{style:({
          'stroke-dashoffset': `calc(${_vm.dashArrayLength} - (${_vm.dashArrayLength} * ${_vm.percentualValue} / 100))`,
          'stroke-dasharray': _vm.dashArrayLength,
          stroke: _vm.color,
        }),attrs:{"cx":_vm.size / 2.5,"cy":_vm.size / 2.55,"r":_vm.size / 2.55}})])]),_c('div',{staticClass:"progress-text"},[(!_vm.showPercentage && _vm.title)?_c('h3',{style:({ color: _vm.color })},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.showPercentage)?_c('h3',{style:({ color: _vm.color })},[_vm._v(_vm._s(_vm.percentualValue)+"%")]):_vm._e(),(_vm.text)?_c('h4',[_vm._v(_vm._s(_vm.text))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }