var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container p-0 m-0 row bottom-border notification-item",attrs:{"tabindex":"0","aria-labelledby":'user' + _vm.notification.toastrid,"aria-describedby":'message' + _vm.notification.toastrid}},[_c('div',{staticClass:"col-12 p-0 m-0 pb-3 pt-2 pl-1 notification-unread"},[_c('div',{staticClass:"col-11 m-0 p-0 pl-2 float-left"},[_c('div',{staticClass:"col-12 pt-1 m-0 p-0"},[_c('div',{staticClass:"col-1 m-0 p-0 float-left"},[_c('Avatar',{staticClass:"comment-profile-image p-0 m-0 mt-1",attrs:{"user":_vm.notification.params.FROM_USER_NAME_PARAMETER,"label":_vm.$t(`{userName}'s Avatar`, {
                userName: _vm.notification.params.FROM_DISPLAY_NAME_PARAMETER,
              })}})],1),_c('div',{staticClass:"col-5 m-0 pl-2 float-left"},[_c('span',{staticClass:"notification-username float-left",attrs:{"id":'user' + _vm.notification.params.FROM_PARAMETER}},[_vm._v(_vm._s(_vm.notification.params.FROM_DISPLAY_NAME_PARAMETER))])]),_c('div',{staticClass:"notification-date col-5 p-0 m-0 float-right"},[_c('small',{staticClass:"pr-0 float-right"},[_vm._v(_vm._s(_vm._f("fromNow")(_vm.notification.whents)))])])]),_c('div',{staticClass:"notification-content col-12 p-0 m-0 pl-4 row"},[_c('div',{staticClass:"col-10"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.notification.params.BODY_PARAMETER),expression:"notification.params.BODY_PARAMETER"}],staticClass:"notification-what two-lines-truncated",attrs:{"id":'message' + _vm.notification._id}},[_vm._v(_vm._s(_vm.notification.params.BODY_PARAMETER))]),(
              _vm.notification.params.ATTACHED_OBJECT_TYPE_PARAMETER == 'FILE'
            )?_c('span',{staticClass:"notification-path pt-1"},[_c('FileIcon',{staticClass:"fas file-notif-icon",attrs:{"name":_vm.fileName}}),_c('div',{staticClass:"text-break file-name"},[_c('a',{on:{"click":_vm.previewFile}},[_vm._v(_vm._s(_vm.fileName))])])],1):(
              _vm.notification.params.ATTACHED_OBJECT_TYPE_PARAMETER ==
              'SHARE_OBJECT'
            )?_c('span',{staticClass:"notification-path pt-1"},[_c('div',{staticClass:"text-break file-name ml-0"},[_c('a',{on:{"click":_vm.previewShare}},[_vm._v(_vm._s(_vm.$t('View Share')))])])]):_vm._e()]),_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-primary btn-action btn-approve",attrs:{"aria-label":_vm.$t('Approve')},on:{"click":function($event){return _vm.onRespond(true)}}},[_c('Icon',{attrs:{"family":"fas","name":"thumbs-up"}}),_vm._v(" "+_vm._s(_vm.$t('Approve'))+" ")],1),_c('button',{staticClass:"btn btn-danger btn-action",attrs:{"aria-label":_vm.$t('Reject')},on:{"click":function($event){return _vm.onRespond(false)}}},[_c('Icon',{attrs:{"family":"fas","name":"thumbs-down"}}),_vm._v(" "+_vm._s(_vm.$t('Reject'))+" ")],1),_c('button',{staticClass:"btn btn-primary btn-action float-right fix-20px",attrs:{"aria-label":_vm.$t('Open')},on:{"click":_vm.goToAction}},[_vm._v(" "+_vm._s(_vm.$t('Open'))+" ")])]),_c('div',{staticClass:"col-12 mt-2"},[_c('button',{staticClass:"btn btn-link float-right fix-20px",attrs:{"aria-label":_vm.$t('View All Actions')},on:{"click":function($event){return _vm.$router.push('/actions')}}},[_vm._v(" "+_vm._s(_vm.$t('View All Actions'))+" ")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }