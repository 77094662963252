import { v4 as uuid } from 'uuid';
import _ from 'lodash';
import priorityQueue from 'async/priorityQueue';
import * as helpers from './zipViewerHelpers';
import { i18n } from '@/i18n.js';

export default class FileOperations {
  constructor() {
    this.UPLOAD_FILE_ENDPOINT = 'core/ziparchiveaddfile';
    this.DOWNLOAD_FILE_ENDPOINT = 'core/ziparchivedownloadfile';
    this.CREATE_FOLDER_ENDPOINT = 'core/ziparchivecreateemptyfolder';
    this.DELETE_ENDPOINT = 'core/ziparchivedeleteentry';
    this.LOAD_ZIP_FILE_INFO_ENDPOINT = 'core/ziparchivegetzipinfo';
    this.DEFAULT_CHUNK_SIZE = 1000 * 1000 * 20; // 20MB
    this._context = null;
    this._preview = null;
    this._toast = null;
    this._operationsQueue = priorityQueue(async (task, callback) => {
      await task();
      callback(true);
    }, 1);
  }

  /* region Getters & Setters */

  set context(context) {
    this._context = context;
  }

  set preview(preview) {
    this._preview = preview;
  }

  get preview() {
    return this._preview;
  }

  set toast(toast) {
    this._toast = toast;
  }

  get toast() {
    return this._toast;
  }

  /* endregion */

  /* region Utilities */

  async post(endpoint, params) {
    const response = await this._context.rootState.core.client.post(
      endpoint,
      params
    );
    if (response.ok === false) {
      throw new Error(response.data.message);
    }
    return response;
  }

  async postMultipart(endpoint, data, queryParams, onProgress) {
    const config = {};
    config.zipViewer = true;
    config.headers = {
      'X-Requested-With': 'XMLHttpRequest',
    };
    const response = await this._context.rootState.core.client.postMultipart(
      endpoint,
      data,
      queryParams,
      onProgress,
      '',
      config
    );
    if (response.ok === false) {
      throw new Error(response.data.message);
    }
    return response;
  }

  createError(code, message) {
    const error = new Error(message);
    error.code = code;
    return error;
  }

  updateTransaction(transaction, data) {
    this._context.commit(
      'files/setTransaction',
      {
        id: transaction.id,
        ...data,
      },
      { root: true }
    );
  }

  transactionIsCancelled(transaction) {
    const { rootState } = this._context;
    const index = _.findIndex(this._context.rootState.files.transactions, {
      id: transaction.id,
    });
    // transaction is not found or is cancelled
    const filesTransaction = rootState.files.transactions[index];
    return !filesTransaction || filesTransaction.status === 'cancelled';
  }

  /* endregion */

  /* region Zip Info */

  async fetchZipFileInfo(file) {
    try {
      const zipInfo = await this.post(this.LOAD_ZIP_FILE_INFO_ENDPOINT, {
        path: file.path,
      });
      return zipInfo.data.entry;
    } catch (e) {
      const errorMap = {
        // Thrown when the zip file exceeds the max size configuration limit.
        'CANNOT OPEN ZIP ARCHIVE, FILE SIZE EXCEEDS ALLOWED MAX FILE SIZE.': {
          code: 'ERR_ZIP_FILE_SIZE_EXCEEDED',
          message: i18n.t('zip_preview.errors.err_zip_file_size_exceeded'),
        },
        // Thrown when file is located outside managed store.
        'ZIP FOLDER CAN BE USED ONLY WITH MANAGED STORAGE.': {
          code: 'ERR_INVALID_STORAGE',
          message: i18n.t('zip_preview.errors.err_invalid_storage'),
        },
        // Thrown when zip folder policy is disabled.
        'ZIP FOLDER POLICY IS DISABLED.': {
          code: 'ERR_ZIP_FOLDER_POLICY_DISABLED',
          message: i18n.t('zip_preview.errors.err_zip_folder_policy_disabled'),
        },
        // Thrown when the file is not a zip file.
        'CANNOT OPEN ZIP ARCHIVE, NOT A ZIP FILE.': {
          code: 'ERR_INVALID_ZIP_FILE',
          message: i18n.t('zip_preview.errors.err_not_a_zip_file'),
        },
        // Thrown when something has failed, for example zip folder was deleted and does not exist anymore.
        'CANNOT OPEN ZIP ARCHIVE, FAILED TO DOWNLOAD FILE.': {
          code: 'ERR_FAILED_TO_DOWNLOAD_FILE',
          message: i18n.t('zip_preview.errors.err_file_download_failed'),
        },
      };
      const error = errorMap[e.message.toUpperCase()] ?? null;
      if (error) {
        throw this.createError(error.code, error.message);
      } else {
        throw this.createError('ERR_UNKNOWN_EXCEPTION', e.message);
      }
    }
  }

  /* endregion */

  /* region Browse */

  async browse(path, filter) {
    const { state, getters, commit } = this._context;
    const currentPath = getters.rootFilePath + '/';
    try {
      const response = await this.post('core/ziparchivelist', {
        path: currentPath,
        subfolder: path,
        filter,
        password: state.password,
      });
      const meta = helpers.prepareMeta(response.data.meta);
      const items = helpers.prepareItems(response.data.entry);
      commit('setMeta', meta);
      commit('setItems', items);
    } catch (e) {
      // Only way to check if password is invalid is to check the error message.
      if (e.message.toUpperCase() === 'INVALID ZIP ARCHIVE PASSWORD.') {
        throw this.createError(
          'ERR_INVALID_PASSWORD',
          i18n.t('zip_preview.errors.err_invalid_password')
        );
      }
    }
  }

  /* endregion */

  /* region Upload */

  async uploadItems(entries) {
    const items = this.normalizeItems(entries);
    const uploads = this.createUploads(items);
    this.startUploadQueue(uploads);
  }

  normalizeItems(entries) {
    if (entries instanceof FileList) {
      let items = [];
      for (let entry of entries) {
        items.push({
          file: entry,
          path: entry.webkitRelativePath,
        });
      }
      return items;
    }
    return entries;
  }

  createUploads(items) {
    const uploads = [];
    const { commit } = this._context;
    const context = this.createUploadContext();
    items.forEach((item) => {
      let transaction = this.createUploadTransaction(item.file);
      transaction.callbacks = {
        retry: () => {
          this.updateTransaction(transaction, {
            status: 'preparing',
          });
          this._operationsQueue.push(() =>
            this.processUpload({
              file: item.file,
              transaction,
            })
          );
        },
      };
      uploads.push({
        item,
        transaction,
        context,
      });
      commit('files/addTransaction', transaction, {
        root: true,
      });
    });
    return uploads;
  }

  createUploadContext() {
    const { state, getters } = this._context;
    return {
      rootFilePath: getters.rootFilePath,
      password: state.password,
      currentPath: getters.currentPath,
      subFolder: getters.subFolder,
    };
  }

  createUploadTransaction(file) {
    const path = this._context.getters.currentPath;
    return {
      id: uuid(),
      type: 'upload',
      name: file.name,
      params: {
        item: {
          id: 0,
          dirpath: `${path}/`,
          format: 'zip',
          type: 'file',
        },
      },
      entry: file,
      dirpath: _.trimEnd(path, '/'),
      progress: 0,
      sentSize: 0,
      size: file.size,
      priority: 10,
      group: 'upload',
      status: 'preparing',
      message: file.name + ' uploaded successfully',
    };
  }

  startUploadQueue(uploads) {
    for (const upload of uploads) {
      this._operationsQueue.push(() => this.processUpload(upload));
    }
  }

  async processUpload(upload) {
    const {
      transaction,
      item: { file },
    } = upload;

    // If transaction has been cancelled before upload starts, do not proceed.
    if (this.transactionIsCancelled(transaction)) return;
    this.updateTransaction(transaction, {
      status: 'processing',
    });

    let currentChunk = 0;
    const chunksCount = this.calculateChunks(file);
    while (this.shouldProceedUpload(transaction, currentChunk, chunksCount)) {
      const ok = await this.uploadChunk(upload, currentChunk, chunksCount);
      if (!ok) break;
      currentChunk++;
    }
  }

  async uploadChunk(upload, currentChunk, chunksCount) {
    const { getters, dispatch } = this._context;
    const {
      item: { file, path },
      transaction,
      context: { password, subFolder, rootFilePath },
    } = upload;

    const offset = currentChunk * this.DEFAULT_CHUNK_SIZE;
    const complete = currentChunk === chunksCount - 1 ? 1 : 0;
    const fileChunk = this.sliceFile(file, offset);
    let isCancelled = false;
    let uploadPath = path.substring(0, path.lastIndexOf('/'));
    uploadPath = helpers.joinPaths([subFolder, uploadPath]);

    const data = {
      complete,
      file: fileChunk,
      filesize: file.size,
      name: file.name,
      offset,
      password: password ?? '',
      path: rootFilePath,
      subfolder: uploadPath,
    };

    const callback = (e, source) => {
      isCancelled = this.onUploadProgress(e, source, transaction, currentChunk);
    };

    try {
      await this.postMultipart(this.UPLOAD_FILE_ENDPOINT, data, null, callback);
      if (complete) {
        await dispatch('browse', { path: getters.subFolder });
        this.updateTransaction(transaction, {
          status: 'completed',
          actions: this.createUploadActions(file, uploadPath),
        });
      }
      return true;
    } catch (error) {
      if (error.message.toUpperCase().includes('SIZE EXCEEDS')) {
        this.toast().open({
          message:
            '<b>' +
            i18n.tc('Error') +
            '</b><p role="alert">' +
            i18n.tc('zip_preview.errors.err_zip_file_size_upload_exceeded') +
            '</p>',
          type: 'error',
        });
      }
      if (!isCancelled) {
        const errorMessage = new DOMParser()
                      .parseFromString(error.message, "application/xml")
                      .querySelector("message")
                      .textContent;
        this.updateTransaction(transaction, {
          status: 'failed',
          message: i18n.t(errorMessage),
        });
        return false;
      }
    }
  }

  createUploadActions(file, path) {
    const {
      getters: { canUpload, canDownload },
      dispatch,
    } = this._context;
    const actions = [];
    const item = {
      name: file.name,
      type: 'file',
      fullsize: file.size,
    };

    actions.push({
      name: 'preview',
      label: 'Preview',
      icon: 'eye',
      callback: () => {
        this.preview.open(item, 0);
      },
    });
    if (canDownload) {
      actions.push({
        name: 'download',
        label: 'Download',
        icon: 'download',
        callback: () => {
          dispatch('downloadItem', { item, path });
        },
      });
    }
    if (canUpload) {
      actions.push({
        name: 'delete',
        label: 'Delete',
        icon: 'trash',
        callback: async () => {
          const subFolder = this._context.getters.subFolder;
          await dispatch('deleteItem', { item, path });
          if (path === subFolder) {
            await dispatch('browse', { path: subFolder });
          }
        },
      });
    }
    return actions;
  }

  sliceFile(file, offset) {
    const fileSlice = file.slice(offset, offset + this.DEFAULT_CHUNK_SIZE);
    return new File([fileSlice], file.name, {
      type: file.type,
    });
  }

  onUploadProgress(e, source, transaction, currentChunk) {
    // update sent size
    this.updateTransaction(transaction, {
      sentSize: e.loaded + currentChunk * this.DEFAULT_CHUNK_SIZE,
      cancel: source.cancel,
    });
    // check if transaction is cancelled by file operation panel
    if (this.transactionIsCancelled(transaction)) {
      source.cancel(); // stop axios request
      return true;
    }
    return false;
  }

  shouldProceedUpload(transaction, currentChunk, chunksCount) {
    const isCancelled = this.transactionIsCancelled(transaction);
    return (
      (currentChunk < chunksCount && !isCancelled) ||
      (currentChunk === 0 && chunksCount === 0 && !isCancelled)
    );
  }

  calculateChunks(file) {
    return Math.ceil(file.size / this.DEFAULT_CHUNK_SIZE);
  }

  /* endregion */

  /* region Download */

  async downloadItem(file, path = null) {
    const {
      state,
      getters: { rootFilePath, subFolder },
      rootState,
      commit,
    } = this._context;
    path = path ?? subFolder;
    const transaction = this.createDownloadTransaction(path, file);
    commit('files/addTransaction', transaction, { root: true });
    const startTime = Date.now();

    try {
      const callback = (e, source) => {
        this.updateDownloadProgress(e, source, transaction, startTime);
      };
      const response = await rootState.core.client.postBlob(
        this.DOWNLOAD_FILE_ENDPOINT,
        {
          path: rootFilePath + '/',
          name: _.trim(path + '/' + file.name, '/'),
          password: state.password ?? '',
        },
        callback
      );
      this.updateTransaction(transaction, {
        status: 'completed',
      });
      const blob = await response.data;
      this.createDownloadLink(file.name, blob);
    } catch (e) {
      this.updateTransaction(transaction, {
        status: 'failed',
        message: 'Download failed',
      });
    }
  }

  createDownloadTransaction(path, item) {
    return {
      id: uuid(),
      type: 'download',
      path: path,
      name: path,
      item: item,
      loadedSize: 0,
      totalSize: item.fullsize,
      speed: 0,
      priority: 10,
      group: 'download',
      status: 'processing',
    };
  }

  updateDownloadProgress(e, source, transaction, startTime) {
    const {
      rootState: {
        files: { transactions },
      },
    } = this._context;

    this.updateTransaction(transaction, {
      loadedSize: e.loaded,
      speed: e.loaded / (new Date().getTime() - startTime),
    });
    const index = _.findIndex(transactions, {
      id: transaction.id,
    });
    const currentTransaction = transactions[index];
    if (currentTransaction.status === 'cancelled') {
      source.cancel();
    }
  }

  createDownloadLink(fileName, blob) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  /* endregion */

  /* region Delete Item */

  async deleteItem(file, path = null) {
    const {
      state: { password },
      getters: { subFolder, rootFilePath },
      commit,
    } = this._context;
    path = path ?? subFolder;

    const transaction = this.createDeleteTransaction(file, path);
    commit('files/addTransaction', transaction, { root: true });

    let name;
    if (path === '') {
      name = file.name;
    } else {
      name = helpers.joinPaths([path, file.name]);
    }
    name += file.type === 'dir' ? '/' : '';

    try {
      await this.post(this.DELETE_ENDPOINT, {
        path: rootFilePath,
        name,
        password,
      });
      this.updateTransaction(transaction, {
        status: 'completed',
      });
    } catch (error) {
      this.updateTransaction(transaction, {
        status: 'failed',
        message: 'Delete failed',
      });
    }
  }

  createDeleteTransaction(item, path) {
    return {
      id: uuid(),
      type: 'delete',
      dirpath: _.trim(path, '/'),
      params: { item },
      name: item.name,
      group: 'others',
      status: 'processing',
    };
  }

  /* endregion */

  /* region Create Folder */

  async createFolder(folderName) {
    const {
      state,
      getters: { rootFilePath, subFolder },
    } = this._context;

    try {
      await this.post(this.CREATE_FOLDER_ENDPOINT, {
        path: rootFilePath + '/',
        subfolder: subFolder,
        name: folderName,
        password: state.password,
      });
    } catch (error) {
      console.error('Unable to create folder');
    }
  }

  /* endregion */
}
